import * as React from 'react';

import { BodySBold, BodyS, TitleH4 } from 'styles/v3/variables';
import { ModalHeaderProps } from './types';

import * as S from './styles';

const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  subtitleCategory,
  subtitleValue,
}) => {
  return (
    <S.Container>
      <TitleH4 $isUpper={false} $color='brandPrimary'>
        {title}
      </TitleH4>
      <S.Subtitle>
        <BodyS>{subtitleCategory}</BodyS>
        <BodySBold>{subtitleValue}</BodySBold>
      </S.Subtitle>
    </S.Container>
  );
};

export default ModalHeader;
