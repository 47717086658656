import * as React from 'react';
import styled from 'styled-components';
import { color } from '../../../styles/styleVariables';

interface ILoadingSpinnerProps {}

const LoadingSpinner: React.FunctionComponent<ILoadingSpinnerProps> = (
  props
) => {
  return <Spinner />;
};

const Spinner = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  &:after {
    content: ' ';
    display: block;
    width: 20px;
    height: 20px;
    margin: 10px;
    border-radius: 50%;
    border: 3px solid ${color.greyDark};
    border-color: ${color.greyDark} transparent ${color.greyDark} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default LoadingSpinner;
