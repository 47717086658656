import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const DivisionCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${toRem(16)};

  border: 1px solid ${COLORS.grey800};
  border-radius: ${toRem(12)};
`;

export const DivisionStripColor = styled.div<{ colorStripe: string }>`
  width: ${toRem(9)};
  align-self: stretch;
  margin-right: ${toRem(16)};

  border-radius: ${toRem(21)};

  background-color: ${({ colorStripe }) => colorStripe};
`;

export const DivisionColorInformation = styled.div`
  display: flex;
`;

export const DivisionsContentInformation = styled.div`
  display: flex;
  flex-direction: column;

  width: ${toRem(188)};
`;

export const DivisionInformationTeams = styled.div<{
  divisionStatus: 'FULL' | 'LOCKED' | 'OPEN';
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${toRem(12)};

  border-radius: ${toRem(8)};

  background-color: ${({ divisionStatus }) =>
    divisionStatus === 'OPEN' || divisionStatus === 'LOCKED'
      ? COLORS.grey800
      : COLORS.greenFull};
`;
