import styled from 'styled-components';

import { Pagination } from 'antd';

export const TablePagination = styled(Pagination)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 16px;
`;
