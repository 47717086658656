import { Reducer } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { GamePeriod } from '../../models/GameEvent/GameEventModel';
import { MatchDetailsActionTypes, MatchDetailsState } from './types';

export const initialState: MatchDetailsState = {
  loading: false,
  errors: '',
  preferences: {
    swapped: false,
    timing: {
      timerStart: false,
      initialTime: 0,
      timerInSeconds: 0,
      timerInSecondsTotal: 0,
      period: GamePeriod.FIRST_HALF,
      editOpen: false,
      editPeriod: GamePeriod.FIRST_HALF,
    },
  },
  data: {
    match: undefined,
    matchEventsFailed: [],
  },
};

const reducer: Reducer<MatchDetailsState> = (state = initialState, action) => {
  switch (action.type) {
    case MatchDetailsActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case MatchDetailsActionTypes.FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: '',
        data: {
          ...state.data,
          match: action.payload,
        },
      };
    }

    case MatchDetailsActionTypes.ADD_GAME_EVENT_REQUEST: {
      return { ...state, loading: true };
    }

    case MatchDetailsActionTypes.ADD_GAME_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          match: {
            ...state.data.match,
            result: action.payload.result,
            matchEvents: [
              ...state.data.match!.matchEvents,
              {
                id: action.payload.id,
                action: action.payload.action,
                matchId: action.payload.matchId,
                period: action.payload.period,
                rosterId: action.payload.rosterId,
                time: action.payload.time,
                userId: action.payload.user.id,
              },
            ],
          },
        },
      };
    }

    case MatchDetailsActionTypes.ADD_GAME_EVENT_ERROR: {
      return {
        ...state,
        loading: false,
        errors: 'Ops. Fail to connect with ther server.',
        data: {
          ...state.data,
          matchEventsFailed: [
            ...(state.data.matchEventsFailed || []),
            {
              id: uuidv4(),
              action: action.payload.action,
              matchId: action.payload.matchId,
              period: action.payload.period,
              rosterId: action.payload.rosterId,
              time: action.payload.time,
              userId: action.payload.userId,
              sendFailed: true,
            },
          ],
        },
      };
    }

    case MatchDetailsActionTypes.RESEND_GAME_EVENT_REQUEST: {
      return { ...state, loading: true };
    }

    case MatchDetailsActionTypes.RESEND_GAME_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          matchEventsFailed: state.data.matchEventsFailed?.filter(
            (item) => item.id !== action.payload.localId
          ),
          match: {
            ...state.data.match,
            result: action.payload.result,
            matchEvents: [
              ...state.data.match!.matchEvents,
              {
                id: action.payload.id,
                action: action.payload.action,
                matchId: action.payload.matchId,
                period: action.payload.period,
                rosterId: action.payload.rosterId,
                time: action.payload.time,
                userId: action.payload.user.id,
              },
            ],
          },
        },
      };
    }

    case MatchDetailsActionTypes.RESEND_GAME_EVENT_ERROR: {
      return { ...state, loading: false };
    }

    case MatchDetailsActionTypes.REMOVE_GAME_EVENT_FROM_FAILED: {
      return {
        ...state,
        loading: false,
        errors: 'Ops. Fail to connect with the server.',
        data: {
          ...state.data,
          matchEventsFailed: state.data.matchEventsFailed?.slice(0, -1),
        },
      };
    }

    case MatchDetailsActionTypes.REMOVE_GAME_EVENT_REQUEST: {
      return { ...state, loading: true };
    }

    case MatchDetailsActionTypes.REMOVE_GAME_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: {
          match: {
            ...state.data.match,
            result: action.payload.result,
            matchEvents: state.data.match?.matchEvents.filter(
              (item) => item.id !== action.payload.id
            ),
          },
        },
      };
    }

    case MatchDetailsActionTypes.REMOVE_GAME_EVENT_ERROR: {
      return {
        ...state,
        errors: 'Ops. Fail to connect with the server.',
        loading: false,
        data: {
          ...state.data,
          // Remove event in main list
          match: {
            ...state.data.match!,
            matchEvents:
              state.data.match?.matchEvents.filter(
                (item) => item.id !== action.payload
              ) || [],
          },
          // Add flag to mark event as failed (Not in sync with server)
          matchEventsRemoveFailed: [
            ...(state.data.matchEventsRemoveFailed || []),
            action.payload,
          ],
        },
      };
    }

    case MatchDetailsActionTypes.RESEND_REMOVE_GAME_EVENT_REQUEST: {
      return { ...state, loading: true };
    }

    case MatchDetailsActionTypes.RESEND_REMOVE_GAME_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: {
          match: {
            ...state.data.match,
            result: action.payload.result,
            matchEvents: state.data.match?.matchEvents.filter(
              (item) => item.id !== action.payload.id
            ),
          },
          // When Resend SUCCESS we need remove it from failed list
          matchEventsRemoveFailed:
            state.data.matchEventsRemoveFailed?.filter(
              (item) => item !== action.payload.id
            ) || [],
        },
      };
    }

    case MatchDetailsActionTypes.RESEND_REMOVE_GAME_EVENT_ERROR: {
      return { ...state, loading: false };
    }

    case MatchDetailsActionTypes.UPDATE_GAME_PREFERENCES: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.payload,
        },
      };
    }

    case MatchDetailsActionTypes.UPDATE_MATCH_REPORT: {
      return {
        ...state,
        data: {
          ...state.data,
          matchReport: action.payload,
        },
      };
    }

    case MatchDetailsActionTypes.RESET_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export { reducer as matchDetailsReducer };
