import { useQuery } from '@tanstack/react-query';
import UserType from 'models/User/UserTypeModel';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import ClubService from 'services/v3/Clubs/ClubService';

export function useGetMyClubsByUserType(type?: UserType | string) {
  const { id: accountId, types } = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );
  const { data, isLoading, error } = useQuery(
    ['get-my-clubs-by-user-type', type, accountId, types],
    async () => {
      if (type) {
        return await ClubService.getMyClubsByType(type);
      }
      return { data: null };
    }
  );

  return {
    ...data,
    data: data?.data,
    isLoading,
    error,
  };
}
