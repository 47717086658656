import { Reducer } from 'redux';
import { ClubActionTypes, ClubState } from './types';

export const initialState: ClubState = {
  loading: false,
  data: {
    clubs: [],
    primaryTeam: undefined,
    teams: [],
  },
};

const reducer: Reducer<ClubState> = (state = initialState, action) => {
  switch (action.type) {
    case ClubActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case ClubActionTypes.FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          clubs: action.payload,
        },
      };
    }
    case ClubActionTypes.FETCH_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as clubReducer };
