import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { BodyL } from 'styles/v3/variables';
import ConvertUtil from 'util/ConvertUtil';
import * as S from './styles';

type AvatarWithNameProps = {
  name?: string;
  photo?: string;
};

export const AvatarWithName = ({ name, photo }: AvatarWithNameProps) => {
  return (
    <S.Container>
      <Avatar
        size={213}
        shape='square'
        icon={<UserOutlined />}
        src={ConvertUtil.getMinioUrl(photo)}
      />
      <BodyL $color='grey50'>{name}</BodyL>
    </S.Container>
  );
};
