import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div<{
  $size: 'small' | 'large';
}>`
  height: 100%;
  & > .simple-card-wrapper {
    border-radius: ${(props) =>
      props.$size === 'large' ? toRem(24) : toRem(12)};
    padding: ${(props) => (props.$size === 'large' ? toRem(30) : toRem(24))};
  }
`;
