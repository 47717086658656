import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { message as MessageSnackbar } from 'antd';
import moment from 'moment';

import { BodyM, BodyXL } from 'styles/v3/variables';

import InputText from 'components/v3/Forms/InputText/InputText';
import InputPhone from 'components/v3/Forms/InputPhone/InputPhone';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import InputDate from 'components/v3/Forms/InputDate/InputDate';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import { notification } from 'components/v3/Notification/notification';


import { ApplicationState } from 'redux/store';
import { PatchBaseUser } from 'redux/v3/baseUsers/types';
import { baseUserUpdateData } from 'redux/v3/users/actions';


import { Gender } from 'admin/models/Enums';

import { RequestStatus } from 'models/Request/RequestModel';
import UserType from 'models/User/UserTypeModel';
import DateUtil from 'util/DateUtil';
import { useGetProfiles } from 'hooks/v3/users/useGetProfiles/useGetProfiles';
import Documents from './components/Documents/Documents';
import * as S from './Template/styles';
import UserService from "../../../../services/v3/User/UserService";

type Fields = {
    firstName: { value: string; error: boolean; required: boolean };
    middleName: { value: string; error: boolean; required: boolean };
    lastName: { value: string; error: boolean; required: boolean };
    suffix: { value: string; error: boolean; required: boolean };
    selectEmail: { value: string; error: boolean; required: boolean };
    selectPhone: { value: string; error: boolean; required: boolean };
    birthDate: {
        value: any | moment.Moment;
        error: boolean;
        required: boolean;
    };
    gender: { value: string; error: boolean; required: boolean };
    newEmail?: { value: string; error: boolean; required: boolean };
    newPhone?: { value: string; error: boolean; required: boolean };
};

const MySettings = () => {
    const dispatch = useDispatch();

    useGetProfiles();

    const formRef = React.useRef<HTMLFormElement>(null);

    const userData = useSelector((state: ApplicationState) => state.currentUser.data);

    const isBaseUser = React.useMemo(() => {
        if (!userData) return false
        return Array.isArray(userData.types)
    }, [userData]);

    const userId: string = userData?.id != null ? userData.id : '';


    const isStaff = React.useMemo(() => {
        return userData?.types?.includes(UserType.STAFF) || userData?.type === UserType.STAFF;
    }, [userData])

    const blockEditBasicInfo = React.useMemo(() => {
        return !isStaff && userData?.ageVerified !== RequestStatus.DECLINED
    }, [userData])

    const stateFields = React.useMemo<Fields>(() => ({
        firstName: {
            value: userData?.firstName ?? '',
            error: false,
            required: true,
        },
        middleName: { value: userData?.middleName ?? '', error: false, required: false },
        lastName: { value: userData?.lastName ?? '', error: false, required: true },
        suffix: { value: userData?.suffix ?? '', error: false, required: false },
        selectEmail: {
            value: userData?.email ?? userData?.accountEmail ?? '',
            error: false,
            required: true,
        },
        selectPhone: {
            value: userData?.phoneNumber ?? '',
            error: false,
            required: true,
        },
        birthDate: {
            value: userData?.birthDate ? DateUtil.dateTimeToDateString(String(userData.birthDate).replace('[UTC]', '')) : '',
            error: false,
            required: true,
        },
        gender: { value: userData?.gender ?? '', error: false, required: true },
    }), [userData] )

    const emailOptions: OptionsType[] = [
        {
            label: stateFields.selectEmail.value,
            value: stateFields.selectEmail.value,
        },
        {
            label: 'New Email',
            value: 'new-email',
        },
    ];

    const phoneOptions = React.useMemo<OptionsType[]>(() => (
        [
            {
                label: stateFields.selectPhone.value,
                value: stateFields.selectPhone.value,
            },
            {
                label: 'New Phone',
                value: 'new-phone',
            }
        ]
    ), [stateFields])

    const genderOptions: OptionsType[] = [
        {
            label: 'Male',
            value: Gender.MALE,
        },
        {
            label: 'Female',
            value: Gender.FEMALE,
        },
    ];

    const [fields, setFields] = React.useState<Fields>(stateFields);
    const [validating, setValidating] = React.useState<boolean>(false);
    const [isNewEmail, setIsNewEmail] = React.useState<boolean>(false);
    const [isNewPhone, setIsNewPhone] = React.useState<boolean>(false);


    React.useEffect(() => {
        setFields(stateFields);
    }, [stateFields])

    const setLabelToEmail = emailOptions.find(
        (e) => e.value === stateFields.selectEmail.value
    );
    // const setLabelToPhone = phoneOptions.find(
    //     (p) => p.value === stateFields.selectPhone.value
    // );

    const setLabelToPhone = React.useMemo(() => {
        return phoneOptions.find(
            (p) => p.value === stateFields.selectPhone.value
        );
    }, [phoneOptions, stateFields])
    const setLabelToGender = genderOptions.find(
        (g) => g.value === stateFields.gender.value
    );

    const handleChangeSelect = (field: keyof Fields, e: any) => {
        setFields({
            ...fields,
            [field]: {
                ...fields[field],
                value: e.value,
                error: false,
            },
        });
        setValidating(false);
    };
    const handleInputClear = (field: keyof Fields) => {
        if (field === 'newEmail') {
            setIsNewEmail(false);
        }
        setFields({
            ...fields,
            [field]: { ...fields[field], value: '' },
        });
        setValidating(false);
    };
    const handleChangeInputValue = (
        e: React.ChangeEvent<HTMLInputElement>,
        field: keyof Fields
    ) => {
        const { name, value } = e.target;

        if (name === 'newEmail' || name === 'newPhone') {
            if (value === '') {
                if (name === 'newEmail') {
                    setIsNewEmail(false);
                } else {
                    setIsNewPhone(false);
                }
            }
        }
        setFields({
            ...fields,
            [name]: {
                required: stateFields[field]?.required ?? true,
                error: false,
                value,
            },
        });
        setValidating(false);
    };

    const validate = () => {
        setValidating(true);
        for (const [key, values] of Object.entries(fields)) {
            if (values?.value === '' && values?.required) {
                setFields((newFields) => ({
                    ...newFields,
                    [key]: { value: values.value, error: true },
                }));
            }
        }
    };
    const saveUser = async () => {
        try {
            if (formRef.current) {
                const formData: FormData = new FormData(formRef.current);

                const payload: PatchBaseUser = {
                    firstName: blockEditBasicInfo ? userData?.firstName ?? '' : formData.get('firstName') as string,
                    middleName: blockEditBasicInfo ? userData?.middleName ?? '' : formData.get('middleName') as string,
                    lastName: blockEditBasicInfo ? userData?.lastName ?? '' : formData.get('lastName') as string,
                    suffix: blockEditBasicInfo ? userData?.suffix ?? '' :  formData.get('suffix') as string,
                    email: (isNewEmail
                        ? formData.get('newEmail')
                        : formData.get('selectEmail')) as string,
                    phoneNumber: (isNewPhone
                        ? formData.get('newPhone')
                        : formData.get('selectPhone')) as string,
                    birthDate: blockEditBasicInfo ? String(stateFields.birthDate.value) : formData.get('birthDate') as string,
                    gender: blockEditBasicInfo ? userData?.gender as Gender ?? 'MALE' as Gender :  String(formData.get('gender')) as Gender,
                };

                UserService.updateUser(
                    userId,
                    payload,
                    isBaseUser
                ).then(res => {

                    if( res.httpCode === 200){
                        notification.success({
                            duration: 3,
                            message: 'Profile was updated',
                        })
                        dispatch(baseUserUpdateData({ ...payload, id: userId }));
                    } else {
                        notification.error({
                            message: 'Error updating profile',
                            description:
                                'Please try again',
                        });
                    }


                }).catch(error => {


                    notification.error({
                        message: 'Error updating profile',
                        description:
                             'Error on updating profile, please try again',
                    });
                }).finally(() => {
                    setValidating(false);
                })
            } else {
                setValidating(false);
            }
        } catch (error) {
            setValidating(false);
            if (error instanceof Error) {
                MessageSnackbar.error({ content: error.message, duration: 5 });
            }
        }
    };

    React.useEffect(() => {
        if (validating) {
            const getFields = Object.values(fields);
            const hasError = getFields.find((i) => i?.error);

            if (!hasError) {
                saveUser();
            } else {
                setValidating(false);
            }
        }

        return () => {
            setValidating(false);
        };
    }, [validating, fields]);


    React.useEffect(() => {
        if (fields.selectEmail.value === 'new-email') {
            setIsNewEmail(true);
        } else {
            setIsNewEmail(false);
        }
    }, [fields.selectEmail.value]);
    React.useEffect(() => {
        if (fields.selectPhone.value === 'new-phone') {
            setIsNewPhone(true);
        } else {
            setIsNewPhone(false);
        }
    }, [fields.selectPhone.value]);
    React.useEffect(() => {
        if (isNewEmail) {
            setFields({
                ...fields,
                newEmail: { value: '', error: false, required: true },
                selectEmail: { ...fields.selectEmail, required: false },
            });
        } else {
            setFields({
                ...fields,
                newEmail: { value: '', error: false, required: false },
                selectEmail: {
                    ...fields.selectEmail,
                    value: stateFields.selectEmail.value,
                    required: true,
                },
            });
        }
    }, [isNewEmail]);
    React.useEffect(() => {
        if (isNewPhone) {
            setFields({
                ...fields,
                newPhone: { value: '', error: false, required: true },
                selectPhone: { ...fields.selectPhone, required: false },
            });
        } else {
            setFields({
                ...fields,
                newPhone: { value: '', error: false, required: false },
                selectPhone: {
                    ...fields.selectPhone,
                    value: stateFields.selectPhone.value,
                    required: true,
                },
            });
        }
    }, [isNewPhone]);

    return (

            <S.UserForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
                <fieldset>
                    <legend>
                        <BodyXL $color='brandPrimary' $isUpper>
                            User Info
                        </BodyXL>
                    {blockEditBasicInfo && <BodyM $color='grey600'> * Some fields are blocked because the proof of age is not Declined</BodyM> }
                    </legend>

                    <InputText
                        id='firstName'
                        placeholder='First Name'
                        label='First Name'
                        disabled={blockEditBasicInfo}
                        required
                        key={`firstName_${userData.firstName}`}
                        defaultValue={userData?.firstName || ''}
                        onClearInput={(id) => handleInputClear(id)}
                        error={fields.firstName.error}
                        onChange={(e) => handleChangeInputValue(e, 'firstName')}
                        className='user-info-input'
                    />


                <InputText
                    id='middleName'
                    placeholder='Middle Name (Optional)'
                    label='Middle Name (Optional)'
                    disabled={blockEditBasicInfo}

                    key={`middleName_${userData?.middleName}`}
                    defaultValue={userData?.middleName || ''}
                    onClearInput={(id) => handleInputClear(id)}
                    onChange={(e) => handleChangeInputValue(e, 'middleName')}
                    className='user-info-input'
                />

                    <InputText
                        id='lastName'
                        placeholder='Last Name'
                        label='Last Name'
                        required
                    disabled={blockEditBasicInfo}

                    key={`lastName_${userData?.lastName}`}

                    defaultValue={userData?.lastName || ''}
                        onClearInput={(id) => handleInputClear(id)}
                        error={fields.lastName.error}
                        onChange={(e) => handleChangeInputValue(e, 'lastName')}
                        className='user-info-input'
                    />
                    <InputText
                        id='suffix'
                        placeholder='Suffix (Optional)'
                        label='Suffix (Optional)'
                    disabled={blockEditBasicInfo}
                    key={`suffix_${userData?.suffix}`}
                    defaultValue={userData?.suffix || ''}
                        onClearInput={(id) => handleInputClear(id)}
                        onChange={(e) => handleChangeInputValue(e, 'suffix')}
                        className='user-info-input'
                    />
                </fieldset>

                <S.FormFieldset>
                    {isNewEmail ? (
                        <InputText
                            id='newEmail'
                            placeholder='New Email'
                            label='New Email'
                            required
                            onClearInput={(id) => handleInputClear(id)}
                            error={fields.newEmail?.error}
                            onChange={(e) => handleChangeInputValue(e, 'newEmail')}
                            className='user-info-input'
                        />
                    ) : (
                        <Select
                            name='selectEmail'
                            placeholder='Select Email'
                            required
                            options={emailOptions}
                            defaultValue={setLabelToEmail}
                            onChange={(e) => handleChangeSelect('selectEmail', e)}
                            error={fields.selectEmail.error}
                            className='user-info-input'
                        />
                    )}
                    {isNewPhone ? (
                        <InputPhone
                            id='newPhone'
                            placeholder='New Phone'
                            label='New Phone'
                            required

                            error={fields.newPhone?.error}
                            onChange={(e) => handleChangeInputValue(e, 'newPhone')}
                            className='user-info-input'
                        />
                    ) : (
                        <Select

                            name='selectPhone'
                            placeholder='Select Telephone'
                            required
                            options={phoneOptions}
                            key={`phoneNumber_${userData?.phoneNumber}`}

                            defaultValue={setLabelToPhone}
                            onChange={(e) => handleChangeSelect('selectPhone', e)}
                            error={fields.selectPhone.error}
                            className='user-info-input'
                        />
                    )}
                </S.FormFieldset>
                <S.FormFieldset>
                    <InputDate
                        id='birthDate'
                        placeholder='Birthdate'
                        label='Birthdate'
                        disabled={blockEditBasicInfo}
                        key={`birthDate_${userData?.birthDate}`}
                        defaultValue={userData?.birthDate ? DateUtil.dateTimeToDateString(String(userData.birthDate).replace('[UTC]', '')) : ''}
                        onChange={(e) => handleChangeInputValue(e, 'birthDate')}
                        required
                        error={fields.birthDate.error}
                        className='user-info-input'
                    />
                    <Select
                        name='gender'
                        placeholder='Gender'
                        required
                        isDisabled={blockEditBasicInfo}
                        error={fields.gender.error}
                        options={genderOptions}
                        defaultValue={setLabelToGender}
                        onChange={(e) => handleChangeSelect('gender', e)}
                        className='user-info-input'
                    />
                </S.FormFieldset>

                <Documents />

                <FilledButtonWithIcon onClick={validate} disabled={false}>
                    {validating ? 'Save' : 'Save'}
                </FilledButtonWithIcon>
            </S.UserForm>
    );
};

export default withRouter(MySettings);
