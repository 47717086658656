const playerSpecialties = new Map([
  [
    'Goalkeeper',
    [
      'PK Saver',
      '1v1 Saver',
      '5th Player',
      'Distance Shooter',
      'Acrobatic',
      'Super flex',
    ],
  ],
  [
    'Fixo',
    [
      'Playmaker',
      'Ball winner',
      'Distance Shooter',
      'Tactician',
      'Physical',
      'Technical',
    ],
  ],
  [
    'Ala/Fixo',
    [
      'Dribbler',
      'Playmaker',
      'Distance Shooter',
      'Tactician',
      'Physical',
      'Technical',
    ],
  ],
  [
    'Ala',
    [
      'Dribbler',
      'Playmaker',
      'Distance Shooter',
      'Tactician',
      'Physical',
      'Technical',
    ],
  ],
  [
    'Ala/Pivot',
    [
      'Dribbler',
      'Complete Finisher',
      'Back Post Finisher',
      'Tactician',
      'Physical',
      'Technical',
    ],
  ],
  [
    'Pivot',
    [
      'Poacher',
      'Target Player',
      'Complete Finisher',
      'Tactician',
      'Physical',
      'Technical',
    ],
  ],
]);

export default playerSpecialties;
