import React, { useMemo, useState } from 'react';
import { BodyL, BodyS, COLORS } from 'styles/v3/variables';
import { Dot } from '@icon-park/react';
import { Pools } from 'admin/models/AgeDivision';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import EventService from 'services/v3/Event/EventService';
import { AgeRulesEnums, AgeRulesEnumsToLabel } from "pages/V3/setupEvent/Divisions/constants";
import { ActionMeta, MultiValue, SingleValue } from 'react-select';
import { GenderCategory } from 'services/v3/Match/enums';
import {
  Container,
  ContainerSpace,
  DivisionInformation,
  DivisionText,
  DivisionTextBox,
  DivisionTextWrapper,
  TagColor,
} from './styles';
import { allowedOtherGenders, gendersLabel } from './config';

interface EventDivisionProps {
  eventId: string;
  id: string;
  name: string;
  gender: GenderCategory;
  totalYears: number;
  firstYear: number;
  allowMixedTeams: boolean;
  bracketType: string;
  pools: Pools[];
  format: string;
  color: string;
  years: number[];
  coedRules: string;
  rule:
    | AgeRulesEnums.EQUAL
    | AgeRulesEnums.YOUNGER
    | AgeRulesEnums.YOUNGER_WITH_MORE_ONE_YEAR
    | AgeRulesEnums.YOUNGER_WITH_MORE_TWO_YEAR;
}

export const formatOptions: OptionsType[] = [
  {
    value: 'Groups & Playoffs',
    label: 'Groups & Playoffs',
  },
];

export const DivisionSetFormat = ({
  id,
  name,
  gender,
  totalYears,
  firstYear,
  allowMixedTeams,
  bracketType,
  pools,
  format,
  color,
  rule,
  years,
  coedRules,
  eventId
}: EventDivisionProps) => {
  const [selectedFormat, setSelectedFormat] = useState(format);

  const getRuleAllowed = useMemo(() => {
    if (allowMixedTeams && gender !== GenderCategory.COED) {
      return allowedOtherGenders[gender];
    }

    return coedRules;
  }, [allowMixedTeams, gender, coedRules]);

  const getYearRule = useMemo(() => {
    return AgeRulesEnumsToLabel[rule].replace('Year', firstYear.toString());
  }, [rule, firstYear]);

  const handleFormatChange = (
    newValue: MultiValue<OptionsType> | SingleValue<OptionsType>,
    actionMeta: ActionMeta<OptionsType>
  ) => {
    if (!newValue) {
      return;
    }

    const selectedOption = newValue as OptionsType;
    setSelectedFormat(String(selectedOption.value));
    EventService.updateDivision(eventId, {
      pools,
      id,
      years,
      gender,
      rule,
      format: String(selectedOption.value),
    });
  };

  return (
    <>
      <Container>
        <ContainerSpace>
          <TagColor style={{ backgroundColor: color }} />
          <DivisionInformation>
            <DivisionText>
              <BodyL>{name}</BodyL>
              <DivisionTextWrapper>
                <DivisionTextBox>
                  <BodyS>{gendersLabel[gender]}</BodyS>
                </DivisionTextBox>
                <DivisionTextBox>
                  <Dot fill={COLORS.grey800} />
                  <BodyS>{getYearRule}</BodyS>
                </DivisionTextBox>
                <DivisionTextBox>
                  {getRuleAllowed && <Dot fill={COLORS.grey800} />}
                  <BodyS>{getRuleAllowed}</BodyS>
                </DivisionTextBox>
              </DivisionTextWrapper>
            </DivisionText>
            <Select
              name='format'
              placeholder='Select Format'
              defaultValue={formatOptions.find(
                (option) => option.value === format
              )}
              options={formatOptions}
              value={formatOptions.find(
                (option) => option.value === selectedFormat
              )}
              onChange={handleFormatChange}
            />
          </DivisionInformation>
        </ContainerSpace>
      </Container>
    </>
  );
};
