import React, { FC, useMemo } from 'react';
import { BodyLBold, BodyM, BodyS, BodyXL } from 'styles/v3/variables';
import { ScheduleMatch } from 'models/Schedule/ScheduleModel';
import ConvertUtil from 'util/ConvertUtil';
import DateUtil from 'util/DateUtil';
import type from 'assets/imgs/icons/publish-type.svg';
import logoAlt from 'assets/imgs/Vault_logo_grey.svg';
import dates from 'assets/imgs/icons/publish-calendar.svg';
import venue from 'assets/imgs/icons/publish-venue.svg';

import { useParams } from 'react-router-dom';
import { timezones } from 'util/timezones';
import useGetVenues from 'hooks/v3/event/useGetVenues/useGetVenues';
import {
  StyledBodyS,
  StyledCloseSmall,
  StyledContainer,
  StyledGameInfoItemWrapper,
  StyledModal,
  StyledModalBody,
  StyledModalHeader,
  StyledTextWrapper,
  TeamItemEventInfo,
  TeamLogo,
  TeamName,
  TeamWrapperGameInfo,
} from './style';

type IProps = {
  day: string | Date,
  game: ScheduleMatch,
  setOpened: (val: boolean) => void,
  setOpenedScheduleView: (val: boolean) => void,
};

const GameDetailsModal: FC<IProps> = ({
  day,
  game,
  setOpened,
  setOpenedScheduleView,
}) => {
  const params: { eventId: string } = useParams();
  const { data: venueData } = useGetVenues(params.eventId);

  const handleClose = () => {
    setOpened(false);
    setOpenedScheduleView(true);
  };

  // ENG-594 temporary show only timezone for the 1st venue
  const venueTimezone = useMemo(() => {
    return timezones.find(
      (timezone) =>
        venueData && timezone.value === (venueData[0]?.timezone ?? 'UTC')
    );
  }, [venueData]);

  return (
    <StyledModal height={470}>
      <StyledModalHeader>
        <BodyXL $color='white'>Schedule Game</BodyXL>
        <StyledCloseSmall size='36px' onClick={handleClose} />
      </StyledModalHeader>
      <StyledModalBody>
        <BodyLBold>
          Time configured in the {venueTimezone?.locations} timezone
        </BodyLBold>
        <StyledContainer>
          <StyledGameInfoItemWrapper>
            <img src={type} alt='Type' width={50} height={50} />
            <StyledTextWrapper>
              <BodyLBold>Type</BodyLBold>
              <StyledBodyS $color='white'>
                {game.type === 'POOL_GAMES' ? 'Group Stage' : 'Bracket Stage'}
              </StyledBodyS>
            </StyledTextWrapper>
          </StyledGameInfoItemWrapper>
          <StyledGameInfoItemWrapper>
            <StyledTextWrapper>
              <BodyLBold>Referees</BodyLBold>
              <BodyS $color='grey600'>Coming soon</BodyS>
            </StyledTextWrapper>
          </StyledGameInfoItemWrapper>
          <StyledGameInfoItemWrapper>
            <img src={dates} alt='Type' width={50} height={50} />
            <StyledTextWrapper>
              <BodyLBold>Dates</BodyLBold>
              <StyledBodyS $color='white'>
                <BodyS $color='grey600' style={{ textTransform: 'uppercase' }}>
                  Starts
                </BodyS>{' '}
                {DateUtil.formatDate(new Date(day))}{' '}
                {`${new Date(game?.startTime ?? '').toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                })}`}
                <br />
                <BodyS $color='grey600' style={{ textTransform: 'uppercase' }}>
                  Finishes
                </BodyS>{' '}
                {DateUtil.formatDate(new Date(day))}{' '}
                {`${new Date(game?.endTime ?? '').toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                })}`}
              </StyledBodyS>
            </StyledTextWrapper>
          </StyledGameInfoItemWrapper>

          <StyledGameInfoItemWrapper>
            <img src={venue} alt='Type' width={50} height={50} />
            <StyledTextWrapper>
              <BodyLBold>Venues</BodyLBold>
              <StyledBodyS $color='grey600'>{game.venueName}</StyledBodyS>
              <BodyS $color='white'>{game.courtName ?? 'No defined'}</BodyS>
            </StyledTextWrapper>
          </StyledGameInfoItemWrapper>
          <StyledGameInfoItemWrapper
            style={{ flexDirection: 'column', rowGap: 10 }}
          >
            <BodyLBold>Teams</BodyLBold>
            <TeamWrapperGameInfo>
              <TeamItemEventInfo>
                <TeamName>{game.opponent1?.clubName ?? 'Team 1'}</TeamName>
                <TeamLogo>
                  <img
                    src={
                      game?.opponent1?.clubLogo
                        ? ConvertUtil.getMinioUrl(game.opponent1?.clubLogo)
                        : logoAlt
                    }
                    alt='club logo'
                  />
                </TeamLogo>
              </TeamItemEventInfo>
              <BodyM>VS</BodyM>
              <TeamItemEventInfo>
                <TeamName>{game.opponent2?.clubName ?? 'Team 1'}</TeamName>
                <TeamLogo>
                  <img
                    src={
                      game?.opponent2?.clubLogo
                        ? ConvertUtil.getMinioUrl(game.opponent2?.clubLogo)
                        : logoAlt
                    }
                    alt='club logo'
                  />
                </TeamLogo>
              </TeamItemEventInfo>
            </TeamWrapperGameInfo>
          </StyledGameInfoItemWrapper>
        </StyledContainer>
      </StyledModalBody>
    </StyledModal>
  );
};

export default GameDetailsModal;
