import React from 'react'
import * as S from './styles'
import { BodyProps } from './types'

const Body: React.FC<BodyProps> = ({ children }) => {
  return (
    <S.Wrapper>
      <S.Container>{children}</S.Container>
    </S.Wrapper>
  )
}

export default Body
