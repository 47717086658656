import React, { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import UserType from 'models/User/UserTypeModel';
import { User } from 'models/User/UserModel';
import { Gender, GenderCategory } from 'admin/models/Enums';
import RosterService from 'services/v3/Rosters/RosterService';

import { useProfile } from 'hooks/v3/profile/useProfile';
import { useGetRosterByIdDetailed } from 'hooks/v3/clubs/useGetRosterByIdDetailed/useGetRosterByIdDetailed';
import { useGetClubMembersPool } from 'hooks/v3/clubs/useGetClubMembersPool/useGetClubMembersPool';
import { useUpdateRosterName } from 'hooks/v3/rosters/useUpdateRosterName/useUpdateRosterName';
import { useSubmitRoster } from 'hooks/v3/rosters/useSubmitRoster/useSubmitRoster';
import { useUpdateEditedRosterStatus } from 'hooks/v3/rosters/useUpdateEditedRosterStatus/useUpdateEditedRosterStatus';

import { notification } from 'components/v3/Notification/notification';
import RightDrawer from 'components/v3/RightDrawer';
import { useUpgradeMembersNotification } from 'components/v3/Buttons/UpgradeMembershipButton/hook/useUpgradeMembersNotification';

import { RosterStatus } from 'services/v3/Rosters/enums';
import useChangeRosterLockState from 'hooks/v3/rosters/useChangeRosterLockState/useChangeRosterLockState';
import { HeaderRosterEdit, ListOfUser } from './components';
import ModalHeader from './components/Modal/ModalHeader/ModalHeader';
import ModalBody from './components/Modal/ModalBody/ModalBody';
import ModalBodyMemberInfo from './components/Modal/ModalBodyMemberInfo';
import { RequiredPointsModal } from './components/RequiredPointsModal/RequiredPointsModal';

import { validateUser, validateRoster } from './utils/validateRoster';
import { UserWithJersey, IUserRosterError, IRosterErrors } from './types';
import { AGE_RULES_VALUES } from './configs';


const RosterEditPage: FC = () => {
  const params: { rosterId: string } = useParams();

  const queryClient = useQueryClient();
  const history = useHistory();

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [savedUsers, setSavedUsers] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const [userSelected, setUserSelected] = useState<User | false>(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalType, setModalType] = useState<UserType>();
  const [visibleRequiredPointsModal, setVisibleRequiredPointsModal] = useState(false);

  const [rosterUserErrors, setRosterUserErrors] = useState<IUserRosterError[]>([]);
  const [rosterErrors, setRosterErrors] = useState<IRosterErrors>({
    coachesLimit: false,
    playersLimit: false,
  });

  const { currentUser: { accountId } } = useProfile()
  const { data: rosterDetailed, refetch: fetchRosterDetails } = useGetRosterByIdDetailed(params.rosterId);
  const { data: members } = useGetClubMembersPool(rosterDetailed?.club?.id);
  const { mutateAsync: updateRosterName } = useUpdateRosterName();
  const { mutateAsync: submitRoster } = useSubmitRoster();
  const { mutateAsync: updateLockedStateRoset } = useChangeRosterLockState(params.rosterId);
  const { mutateAsync: updateEditedRosterStatus } = useUpdateEditedRosterStatus();

  useUpgradeMembersNotification([
    ['get-club-members-pool', rosterDetailed?.club?.id, accountId]
  ]);

  const isSubmittedStatus = useMemo(() => {
    return rosterDetailed?.status === RosterStatus.Submitted;
  }, [rosterDetailed]);

  const handleGoBack = async () => {
    const id = rosterDetailed?.club?.id ?? '';
    history.push(`/v3/clubs/${id}/dashboard`);
  };

  const handleOpenModal = (headerModal: UserType) => {
    setModalTitle(
      headerModal === UserType.COACH
        ? `Add ${headerModal}es`
        : `Add ${headerModal}s`
    );

    setModalType(headerModal);
    setIsOpen(true);
  };

  const handleOpenUserInfoModal = (user: User) => {
    setIsUserInfoOpen(true);
    setUserSelected(user);
  };

  const handleCloseModal = () => {
    setModalTitle('');
    setIsOpen(false);
    setUserSelected(false);
  };

  const handleSaveNewUser = async () => {
    const failedUsers : string[] = []

    if (selectedUsers.length > 0) {
      for await (const userId of selectedUsers) {
        try {
          await RosterService.addPlayer(params.rosterId, userId);

          setSavedUsers((oldState) => [...oldState, userId]);

          setSelectedUsers((oldState) => [
            ...oldState.filter((curId) => curId !== userId),
          ]);

          queryClient.invalidateQueries(['/get-roster-detailed-id', params.rosterId])

          if (isSubmittedStatus && rosterDetailed?.id) {
            updateEditedRosterStatus(rosterDetailed.id);
          }

        } catch (e) {
          const error = e as any;

          notification.error({
            message: error?.message || 'Error adding roster',
          });

          failedUsers.push(userId)
        }
      }

      if(failedUsers.length){
        setSelectedUsers((oldState) => [
          ...oldState.filter((userId) => !failedUsers.includes(userId)),
        ]);
      }

      handleCloseModal();
    }
  };

  const handleRemoveUser = async () => {
    if (userSelected) {
      try {
        await RosterService.removePlayer(params.rosterId, userSelected.id);

        setSavedUsers((oldState) => [
          ...oldState.filter((userId) => userId !== userSelected.id),
        ]);

        setSelectedUsers((oldState) => [
          ...oldState.filter((userId) => userId !== userSelected.id),
        ]);

        queryClient.invalidateQueries(['/get-roster-detailed-id', params.rosterId])

        if (isSubmittedStatus && rosterDetailed?.id) {
          updateEditedRosterStatus(rosterDetailed.id);
        }
      } catch (e) {
        console.error(e);
      }

      handleCloseModal();
    }
  };

  const handleUpdateRosterName = useCallback(
    (rosterId: string, name: string) => {
      updateRosterName({ rosterId, name }).then(() => {
        fetchRosterDetails();
      });
    },
    [updateRosterName, fetchRosterDetails]
  );

  const handleSubmitRoster = useCallback(() => {
    const userErrors = validateUser(rosterDetailed, members);
    const rosterErrors = validateRoster(rosterDetailed);

    if (!userErrors.length && !rosterErrors.coachesLimit && !rosterErrors.playersLimit && rosterDetailed?.id) {
      submitRoster(rosterDetailed.id).then(() => {
        updateLockedStateRoset(true);
      });
    } else {
      notification.error({
        message: 'Roster is not valid',
        description: 'Please review your roster setup',
      })
    }

    setRosterUserErrors(userErrors);
    setRosterErrors(rosterErrors);
  }, [rosterDetailed, members]);

  const handleShowRequiredPointsModal = useCallback(() => {
    setVisibleRequiredPointsModal(true);
  }, []);

  const handleCloseRequiredPointsModal = useCallback(() => {
    setVisibleRequiredPointsModal(false);
  }, []);

  const coachesData = useMemo(() => {
    if (!members?.length) return [];

    return members.filter((member) => member.type === UserType.COACH);
  }, [handleSaveNewUser, handleCloseModal]);

  const coachesSelected = useMemo(() => {
    if (!members?.length) return [];

    return members.filter(
      (member) => member.type === UserType.COACH && savedUsers.includes(member.id)
    );
  }, [handleSaveNewUser, handleCloseModal]);

  const playersData = useMemo(() => {
    if (rosterDetailed?.ageDivision && members) {
      const { gender } = rosterDetailed.ageDivision;
      const ageOffset = AGE_RULES_VALUES[rosterDetailed.ageDivision.rule];

      const filteredPlayers = members.filter((member) => {
        const allowedGirlsPlaysWithBoys = rosterDetailed.ageDivision?.allowGirlBoysOnAnotherDivision;
        const birthYear = new Date(member.birthDate as string).getFullYear();
        const ageDivisionRule = Number(rosterDetailed.ageDivision?.years.join('/') ?? 0);
        const ageAllowed = ageDivisionRule + ageOffset;

        const allowedByAgeRule = ageDivisionRule <= birthYear && birthYear <= ageAllowed;
        const allowedGender = gender === GenderCategory.COED || (gender === member.gender || 
          (gender === GenderCategory.MALE && allowedGirlsPlaysWithBoys));

        return member.type === UserType.PLAYER && allowedGender && allowedByAgeRule;
      });

      return filteredPlayers;
    }

    return [];
  }, [members, rosterDetailed, handleSaveNewUser, handleCloseModal]);

  const playersWithOtherGender = useMemo(() => {
    return (
      modalType === UserType.PLAYER &&
      members?.some(
        (member) =>
          member?.type === UserType.PLAYER && member?.gender === Gender.OTHER
      )
    );
  }, [members, modalType]);

  const playersSelected = useMemo <UserWithJersey[]>(() => {
    if (!members?.length) return [];

    return members.filter(
      (member) => member.type === UserType.PLAYER && savedUsers.includes(member.id)
    ).map( member => ({
      ...member,
      jersey: rosterDetailed?.players.find(p => p.id === member.id)?.number,
    }));
  }, [handleSaveNewUser, handleCloseModal]);

  useEffect(() => {
    const selectedPlayers = rosterDetailed?.players.map((player) => player.id);
    const selectedCoaches = rosterDetailed?.coaches.map((player) => player.id);

    if (selectedPlayers && selectedPlayers?.length > 0) {
      setSavedUsers((oldSelected) => [...oldSelected, ...selectedPlayers]);
    }

    if (selectedCoaches && selectedCoaches?.length > 0) {
      setSavedUsers((oldSelected) => [...oldSelected, ...selectedCoaches]);
    }
  }, [members]);

  return (
    <>
      {rosterDetailed?.ageDivision && (
        <HeaderRosterEdit
          rosterId={params.rosterId || null}
          name={rosterDetailed?.name ?? ''}
          eventName={rosterDetailed?.event?.name ?? ''}
          eventLogo={rosterDetailed?.event?.logo ?? ''}
          division={rosterDetailed.ageDivision}
          isLockedRoster={
            rosterDetailed.locked || rosterDetailed.ageDivision.lockDivision
          }
          locksIn={rosterDetailed?.event?.data.generalRules?.rosterLockDate}
          onGoBack={handleGoBack}
          onUpdateRosterName={handleUpdateRosterName}
          onSubmitRoster={handleSubmitRoster}
          onViewRequiredPoints={handleShowRequiredPointsModal}
        />
      )}
      {rosterDetailed?.coaches && (
        <ListOfUser
          typeOfUser='Coaches'
          coachesList={coachesSelected}
          isLockedRoster={
            rosterDetailed.locked || rosterDetailed.ageDivision?.lockDivision
          }
          hasLimitsError={rosterErrors.coachesLimit}
          validationMin={rosterDetailed.event?.minimumCoachesNumber ?? 0}
          validationMax={rosterDetailed.event?.maximumCoachesNumber ?? 0}
          rosterUserErrors={rosterUserErrors}
          rosterStatus={rosterDetailed.status}
          handleOpenModal={handleOpenModal}
          handleOpenUserInfoModal={handleOpenUserInfoModal}
        />
      )}
      {rosterDetailed?.players && (
        <ListOfUser
          typeOfUser='Players'
          rosterId={params.rosterId || null}
          playersList={playersSelected}
          isLockedRoster={
            rosterDetailed.locked || rosterDetailed.ageDivision?.lockDivision
          }
          hasLimitsError={rosterErrors.playersLimit}
          validationMin={rosterDetailed.event?.minimumPlayersNumber ?? 0}
          validationMax={rosterDetailed.event?.maximumPlayersNumber ?? 0}
          rosterUserErrors={rosterUserErrors}
          rosterStatus={rosterDetailed.status}
          handleOpenModal={handleOpenModal}
          handleOpenUserInfoModal={handleOpenUserInfoModal}
        />
      )}
      {isOpen && modalType && (
        <RightDrawer
          isOpen={isOpen}
          handleCloseModal={handleCloseModal}
          headerComponent={<ModalHeader title={modalTitle} />}
        >
          <ModalBody
            userType={modalType}
            playersWithOtherGender={!!playersWithOtherGender}
            members={modalType === 'COACH' ? coachesData : playersData}
            selectedUsers={selectedUsers}
            savedUsers={savedUsers}
            handleSelectedUsers={setSelectedUsers}
            handleUpdateUsers={handleSaveNewUser}
          />
        </RightDrawer>
      )}
      {isUserInfoOpen && userSelected && (
        <RightDrawer
          isOpen={isUserInfoOpen}
          handleCloseModal={handleCloseModal}
        >
          <ModalBodyMemberInfo
            member={userSelected}
            rosterDetailed={rosterDetailed}
            isLockedRoster={
              rosterDetailed?.locked ||
              rosterDetailed?.ageDivision?.lockDivision
            }
            handleRemoveUser={handleRemoveUser}
          />
        </RightDrawer>
      )}
      <RequiredPointsModal
        visible={visibleRequiredPointsModal}
        minPlayers={rosterDetailed?.event?.minimumPlayersNumber ?? 0}
        maxPlayers={rosterDetailed?.event?.maximumPlayersNumber ?? 0}
        minCoaches={rosterDetailed?.event?.minimumCoachesNumber ?? 0}
        maxCoaches={rosterDetailed?.event?.maximumCoachesNumber ?? 0}
        onClose={handleCloseRequiredPointsModal}
      />
    </>
  );
};

export default RosterEditPage;
