import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const EventAgeDivisionForm: React.FunctionComponent<any> = (props) => {
  const { indexComponent, removeItem, defaultValue } = props;
  const { register, control } = useFormContext();
  const [yearCategories, setYearCategories] = useState<any[]>([]);

  const [selectedYear, setSelectedYear] = React.useState(defaultValue?.years);
  const [selectedRule, setSelectedRule] = React.useState(defaultValue?.rule);
  const [selectedGender, setSelectedGender] = React.useState(
    defaultValue?.gender
  );

  const handleSelectChangeYear = (event: any) => {
    setSelectedYear(event.currentTarget.value);
  };

  const handleSelectChangeGender = (event: any) => {
    setSelectedGender(event.currentTarget.value);
  };

  const handleSelectChangeRule = (event: any) => {
    setSelectedRule(event.currentTarget.value);
  };

  const removeAndRefresh = async () => {
    removeItem(
      getSelectedIds().filter((item: string) => item !== selectedYear)
    );
  };

  const getSelectedIds = () => {
    return control.getValues()?.ageDivisions?.map((item: any) => {
      return item.years;
    });
  };

  useEffect(() => {
    mountYearCategories();
  }, []);

  function mountYearCategories() {
    const year = moment().year();
    const years = [];
    for (let i = year - 5; i >= year - 20; i -= 1) {
      years.push({
        label: i,
        value: i,
      });
    }
    years.push({
      label: 'Adult',
      value: 1900,
    });
    setYearCategories(years);
  }

  return (
    <>
      <div className='form-group' style={{ width: '32%' }}>
        {props.selectedVenues}
        <label>Age Category</label>
        <select
          name={`ageDivisions[${indexComponent}].years`}
          ref={register({ required: true })}
          onChange={handleSelectChangeYear}
          value={selectedYear || '-1'}
        >
          <option disabled value='-1'>
            Select...
          </option>

          {yearCategories.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>

      <div className='form-group' style={{ width: '32%' }}>
        <label>Gender</label>
        <select
          name={`ageDivisions[${indexComponent}].gender`}
          ref={register({ required: true })}
          onChange={handleSelectChangeGender}
          value={selectedGender || '-1'}
        >
          <option disabled value='-1'>
            Select...
          </option>

          <option value='MALE'>Boys</option>
          <option value='FEMALE'>Girls</option>
          <option value='COED'>Coed</option>
        </select>
      </div>

      <div className='form-group' style={{ width: '32%' }}>
        <label>Age rule</label>
        <select
          name={`ageDivisions[${indexComponent}].rule`}
          ref={register({ required: true })}
          onChange={handleSelectChangeRule}
          value={selectedRule || '-1'}
        >
          <option disabled value='-1'>
            Select...
          </option>

          <option value='YOUNGER'>+ Younger</option>
          <option value='EQUAL'>Equal</option>
          <option value='EQUAL+1'>Equal + 1 year</option>
          <option value='EQUAL+2'>Equal + 2 years</option>
        </select>
      </div>

      <div className='form-group' style={{ width: '4%' }}>
        <Button
          type='link'
          size='small'
          icon={<DeleteOutlined />}
          onClick={removeAndRefresh}
        />
      </div>
    </>
  );
};

export default EventAgeDivisionForm;
