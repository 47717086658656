import * as React from 'react';
import styled from 'styled-components';
import loadingGif from '../../../assets/imgs/UnitedFutsal_Loading.gif';

const Loading: React.FunctionComponent = (props) => {
  return (
    <Wrapper>
      <div className='logo'>
        <img src={loadingGif} alt='' />
        <div style={{ textAlign: 'center' }}>Loading...</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .logo {
    img {
      opacity: 0.15;
    }
  }
`;
export default Loading;
