import React, { useMemo } from 'react';
import VaultImg from 'assets/imgs/icons/vault-logo.svg';
import VaultImgGrey from 'assets/imgs/icons/vault-logo-grey.svg';
import { BodyS, BodyXS } from 'styles/v3/variables';
import { MembershipFieldProps } from './types';
import * as S from './styles';

export const Membership = ({
  membership,
  size = 'xs',
}: MembershipFieldProps) => {
  const isOfficial = membership === 'OFFICIAL';
  const membershipName = useMemo(() => {
    switch (membership) {
      case 'OFFICIAL':
        return 'Official Member';
      case 'CCS':
        return 'CCS Member';
      default:
        return 'Community Member';
    }
  }, [membership]);
  const TextContainer = size === 'xs' ? BodyXS : BodyS;
  return (
    <S.Container>
      <S.Image src={isOfficial ? VaultImg : VaultImgGrey} />
      <TextContainer>{membershipName}</TextContainer>
    </S.Container>
  );
};
