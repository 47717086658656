import * as React from 'react';
import styled from 'styled-components';
import { EventListView, EventView } from '../../../admin/models/event/Event';
import { color, font } from '../../../styles/styleVariables';
import ConvertUtil from '../../../util/ConvertUtil';

interface IEventCardSelectionProps {
  event?: EventListView | EventView;
}

const EventCardSelection: React.FunctionComponent<IEventCardSelectionProps> = (
  props
) => {
  const { event } = props;
  return (
    <CardEvent>
      <div className='image'>
        <img alt='' src={`${ConvertUtil.getMinioUrl(event?.logo)}`} />
      </div>
      <div className='text'>
        <div className='title'>{event?.name}</div>

        {event?.venues && event?.venues[0]?.address && (
          <div className='location'>{`${event?.venues[0]?.name} - ${event?.venues[0]?.address.city}, ${event?.venues[0]?.address.country}`}</div>
        )}
      </div>
      <div className='date'>{event?.startDate}</div>
      <div className='date'>{event?.endDate}</div>
      <div
        className='chevron'
        style={{ position: 'absolute', right: '35px', top: 'calc(50% - 5px)' }}
      >
        <span className='icon-block sm i-icon-chevron-right' />
      </div>
    </CardEvent>
  );
};

const CardEvent = styled.div`
  padding: 10px 20px;
  border-radius: 20px;
  background: ${color.greyLight};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 16px;
  transition: background-color 0.5s;
  position: relative;
  &:hover {
    box-shadow: 0px 0 0px 1px ${color.orange};
    .image {
      img {
        transform: scale(1.1);
      }
    }
  }
  .image {
    width: 80px;
    height: 80px;
    padding-right: 25px;
    display: flex;
    align-items: center;
    img {
      transition: all 0.5s;
      width: 100%;
    }
  }
  .text {
    width: 30%;
    min-width: 120px;
    margin-right: 10px;
    .title {
      font-family: ${font.druk};
    }
    .location {
      font-weight: 600;
      margin: 10px 0;
      color: ${color.orange};
    }
    .description {
      padding-top: 10px;
      text-align: justify;
      font-size: 12px;
      color: ${color.silverLight};
    }
  }
  .date {
    width: 10%;
    min-width: 80px;
    margin-right: 10px;
  }
  .chevron {
    margin-left: auto;
  }
  /* @media (max-width: 1400px) {
    flex-wrap: wrap;
    .image {
      width: 75%;
      margin: 0 auto;
      margin-bottom: 20px;
      padding: 0;
    }
    .text {
      width: 100%;
    }
  } */
`;

export default EventCardSelection;
