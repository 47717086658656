import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button, message, Popconfirm, Space, Table } from 'antd';
import { Key, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageContainer from '../../../components/base/PageContainer/PageContainer';
import GroupBox from '../../../components/uielements/GroupBox/GroupBox';
import BaseEventServices from '../../../services/BaseEvent/BaseEventServices';
import PageResponse from '../../../services/util/PageResponse';
import ServiceResponse from '../../../services/util/ServiceResponse';
import { BaseEventView } from '../../models/event/BaseEvent';

const BaseEventListPage: React.FunctionComponent = () => {
  const history = useHistory();

  const initialState = {
    data: [] as BaseEventView[],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      pageSizeOptions: ['10', '30', '50', '100'],
    },
    loading: false,
  };

  const [state, setState] = useState(initialState);
  const [urlParams, setUrlParams] = useState<URLSearchParams>(
    new URLSearchParams({
      size: '10',
      page: '0',
    })
  );

  useEffect(() => {
    handleTableChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    findAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  const handleTableChange = (
    pagination?: TablePaginationConfig,
    filters?: Record<string, (Key | boolean)[] | null>,
    sorter?: any
  ) => {
    const urlParamsTmp = new URLSearchParams();
    urlParamsTmp.set(
      'page',
      pagination?.current ? (pagination.current - 1).toString() : '0'
    );
    urlParamsTmp.set(
      'size',
      pagination?.pageSize ? pagination.pageSize.toString() : '10'
    );
    urlParamsTmp.set('sort', sorter?.field ? sorter.field.toString() : '_id');
    urlParamsTmp.set(
      'direction',
      sorter?.order && sorter.order === 'ascend' ? 'Ascending' : 'Descending'
    );
    setUrlParams(urlParamsTmp);
  };

  const findAll = async () => {
    setState({ ...state, loading: true });

    await BaseEventServices.list().then(
      (response: ServiceResponse<PageResponse<BaseEventView>>) => {
        if (response.success) {
          setState({
            ...state,
            loading: false,
            data: response.data.content,
            pagination: {
              ...state.pagination,
              current: response.data.page + 1,
              pageSize: response.data.pageSize,
              total: response.data.total,
            },
          });
        } else {
          setState({ ...state, loading: false });
          message.error({ content: response.message, duration: 5 });
        }
      }
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Multiplier',
      dataIndex: 'multiplier',
      key: 'multiplier',
      sorter: true,
    },
    {
      title: 'Action',
      key: 'action',
      // eslint-disable-next-line react/display-name
      render: (text: string, record: any, index: number) => (
        <Space size='middle'>
          <Button
            shape='circle'
            icon={<EditOutlined />}
            onClick={() =>
              history.push(`/admin-panel/base-events/create/${record.id}`)
            }
          />

          <Popconfirm
            title='Are you sure delete this base event?'
            onConfirm={() => onDelete(record)}
            okText='Yes'
            cancelText='No'
          >
            <Button shape='circle' icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onDelete = async (event: any) => {
    // await VenueServices.delete(event.id).then((response) => {
    //   if (!response.success) {
    //     message.error({ content: response.message, duration: 5 });
    //     return;
    //   }
    //   findAll();
    //   message.success({ content: 'Event deleted', duration: 3 });
    // });
  };

  return (
    <PageContainer title='Base events'>
      <Button
        type='link'
        icon={<PlusCircleOutlined />}
        onClick={() => history.push('/admin-panel/base-events/create')}
      >
        Create
      </Button>

      <GroupBox title='List'>
        <Table
          rowKey='id'
          dataSource={state.data}
          loading={state.loading}
          pagination={state.pagination}
          columns={columns}
          onChange={handleTableChange}
        />
      </GroupBox>
    </PageContainer>
  );
};

export default BaseEventListPage;
