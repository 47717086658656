import { EventInvitesStatus } from 'models/v3/EventInvite/EventInvitesModel';

import { OptionsType } from 'components/v3/Forms/Select/Select';

export const STATUS_OPTIONS: OptionsType[] = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Invited',
    value: EventInvitesStatus.INVITED,
  },
  {
    label: 'Denied',
    value: EventInvitesStatus.DENIED,
  },
  {
    label: 'Accepted',
    value: EventInvitesStatus.ACCEPTED,
  },
];
