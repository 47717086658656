import { Col, Row } from 'antd';
import * as React from 'react';
import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../components/uielements/Button/Button';
import ActionXP from './ActionXp';
import EventMultiplier from './EventMultiplier';
import Levels from './Levels';

interface IXPRatingProps {}

const XPRating: React.FunctionComponent<IXPRatingProps> = (props) => {
  const location = useLocation();

  return (
    <div style={{ padding: '20px 20px' }}>
      <h2>XP Ratings</h2>
      <Row>
        <Col md={24} lg={6} style={{ paddingRight: '30px' }}>
          <MenuContainer>
            <NavLink to='/xp-ratings/' exact activeClassName='active'>
              <Button>Event Multiplier</Button>
            </NavLink>
            <NavLink to='/xp-ratings/action-xp' activeClassName='active'>
              <Button>Action XP</Button>
            </NavLink>
            <NavLink to='/xp-ratings/levels' activeClassName='active'>
              <Button>Levels</Button>
            </NavLink>
          </MenuContainer>
        </Col>

        <Col md={24} lg={18} style={{ padding: '10px' }}>
          {location.pathname === '/xp-ratings/' && <EventMultiplier />}
          {location.pathname === '/xp-ratings/action-xp' && <ActionXP />}
          {location.pathname === '/xp-ratings/levels' && <Levels />}
        </Col>
      </Row>
    </div>
  );
};

const MenuContainer = styled.div`
  @media (max-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    //justify-content: space-around;
  }
`;

export default XPRating;
