import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { EventManagerDashboard } from 'components/v3/EventManagerDashboard/EventManagerDashboard';

import { RedirectionName } from './enums/redirection-name.enum';
import { Invites, Format, Teams, Sales, Referees, Games } from './components';
import ScheduleCalendar from './components/ScheduleCalendar/ScheduleCalendar';
import { TABS_KEYS } from './tab-keys';

const EventManager = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const history = useHistory();
  const location = useLocation();

  const [previousRoute, setPreviousRoute] = useState<Maybe<RedirectionName>>(
    null
  );

  const params = new URLSearchParams(location.search);

  const activeKey = params.get('tab') ?? TABS_KEYS.INVITES;

  const handleTabChange = useCallback(
    (key: string) => {
      if (previousRoute) {
        history.push(`${location.pathname}?tab=${key}&prev=${previousRoute}`);
      } else {
        history.push(`${location.pathname}?tab=${key}`);
      }
    },
    [history, location.pathname, previousRoute]
  );

  useEffect(() => {
    const prevParam = params.get('prev');

    if (prevParam) {
      setPreviousRoute(prevParam as RedirectionName);
    }
  }, []);

  return (
    <EventManagerDashboard
      activeKey={activeKey}
      onChange={handleTabChange}
      tabs={[
        {
          title: 'Invites',
          key: TABS_KEYS.INVITES,
          children: <Invites id={eventId} />,
        },
        {
          title: 'Sales',
          children: <Sales />,
          key: TABS_KEYS.SALES,
        },
        {
          title: 'Teams',
          children: <Teams />,
          key: TABS_KEYS.TEAMS,
        },
        {
          title: 'Referees',
          children: <Referees />,
          key: TABS_KEYS.REFEREES,
        },
        {
          title: 'Format',
          children: <Format id={eventId} />,
          key: TABS_KEYS.FORMAT,
        },
        {
          title: 'Schedule',
          children: <ScheduleCalendar />,
          key: TABS_KEYS.SCHEDULES,
        },
        {
          title: 'Games',
          children: <Games />,
          key: TABS_KEYS.GAMES,
        },
      ]}
    />
  );
};

export default EventManager;
