import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

import { COLORS } from 'styles/v3/variables';
import { ClubInvitesStatus } from 'models/v3/ClubInvite/ClubInvitesModel';

const getStatusColor = (status: ClubInvitesStatus) => {
  switch (status) {
    case 'ACCEPTED':
      return COLORS.brandPrimary;
    case 'DENIED':
      return COLORS.supportError;
    case 'INVITED':
      return COLORS.supportNeutral;
    default:
      return COLORS.grey500;
  }
};

export const Container =
  styled.div <
  { $status: ClubInvitesStatus } >
  `
  display: flex;
  padding: ${toRem(4)} ${toRem(16)};
  justify-content: center;
  align-items: center;
  gap: ${toRem(4)};
  text-transform: capitalize;

  border-radius: ${toRem(33)};
  border: ${toRem(1)} solid;
  border-color: ${({ $status }) => getStatusColor($status)};
`;
