import { PatchEventProps } from 'services/v3/B2bEvent/types';
import { DeepPartial } from 'util/types';
import { NewAgeDivision } from 'admin/models/AgeDivision';
import { EventData } from 'admin/models/event/Event';

export enum B2bSetupEventActionTypes {
  FETCH_EVENTS_REQUEST = '@@b2bSetupEvent/FETCH_EVENTS_REQUEST',
  FETCH_EVENTS_SUCCESS = '@@b2bSetupEvent/FETCH_EVENTS_SUCCESS',
  FETCH_EVENTS_ERROR = '@@b2bSetupEvent/FETCH_EVENTS_ERROR',

  FETCH_EVENT_REQUEST = '@@b2bSetupEvent/FETCH_EVENT_REQUEST',
  FETCH_EVENT_SUCCESS = '@@b2bSetupEvent/FETCH_EVENT_SUCCESS',
  FETCH_EVENT_ERROR = '@@b2bSetupEvent/FETCH_EVENT_ERROR',

  PATCH_EVENT_REQUEST = '@@b2bSetupEvent/PATCH_EVENT_REQUEST',
  PATCH_EVENT_SUCCESS = '@@b2bSetupEvent/PATCH_EVENT_SUCCESS',
  PATCH_EVENT_ERROR = '@@b2bSetupEvent/PATCH_EVENT_ERROR',
}
export interface B2bSetupEventState {
  readonly data: B2bEventsList;
  readonly eventData: DeepPartial<PatchEventProps>;
  readonly loading: boolean;
  readonly saving: boolean;
  readonly error: any;
}

export interface B2bEventsList {
  content: B2bEventItem[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  page: number;
  pageSize: number;
  total: number;
}

export interface B2bEventItem {
  description: string;
  ageDivisions: NewAgeDivision[];
  draft: boolean;
  edition: string;
  endDate: Date;
  eventType: 'TOURNAMENT' | 'LEAGUE';
  id: string;
  logo: string;
  membershipIds: string[];
  name: string;
  startDate: Date;
  venues: [];
  data?: EventData;
}
