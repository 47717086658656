import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import players from '../../assets/imgs/players.png';
import { color } from '../../styles/styleVariables';
import Button from '../uielements/Button/Button';

interface INewSeasonAndSafesportBox {
  onClose?: () => void;
}

const SEASON_YEAR = 2024;

const NewSeasonAndSafesportBox: React.FunctionComponent<INewSeasonAndSafesportBox> = (
  props
) => {
  const { onClose } = props;
  return (
    <BoxWrapper data-testid='join-club-box'>
      <div className='image'>
        <img
          style={{
            position: 'absolute',
            top: '-80px',
            left: '-125px',
          }}
          src={players}
          alt=''
        />
      </div>
      <div className='text'>
        <h2 className='text-gradient'>SafeSport Certificate Update</h2>
        <p>
          As of September 1st, all XP from the previous season have been reset
          for the start of the new season which will run until August 31,{' '}
          {SEASON_YEAR}
          . Don’t worry though, all your career accomplishments have been saved
          and are viewable at any time in your dashboard.
          <br />
          In addition, if you are a coach or referee, your SafeSport Trained
          Certificate might be expired.
          <br />
          It is necessary to resend the certificate that must be valid the
          entire season until August 30th, {SEASON_YEAR}
          <br />
          Please access{' '}
          <Link style={{ fontWeight: 'bold' }} to='/my-settings/'>
            My Settings
          </Link>{' '}
          Page and upload it for this new season.
        </p>
        <Link to='/my-settings/'>
          <Button onClick={onClose}>Upload SafeSport</Button>
        </Link>
      </div>
    </BoxWrapper>
  );
};

const BoxWrapper = styled.div`
  border-radius: 10px;
  max-width: 600px;
  min-height: 267px;
  background-color: ${color.greyLight};
  padding: 30px;
  display: flex;
  box-shadow: 0 0 11px 0px #00000082;
  z-index: 10;
  .image {
    width: 40%;
    position: relative;
    @media (max-width: 600px) {
      display: none;
    }
  }
  .text {
    width: 50%;
    /* text-align: center; */
    text-align: left;
    min-width: 300px;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

export default NewSeasonAndSafesportBox;
