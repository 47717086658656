import { Reducer } from 'redux';
import { MemberpoolRosterActionTypes, MemberpoolRosterState } from './types';

export const initialState: MemberpoolRosterState = {
  data: { players: [], coaches: [] },
  loading: false,
};

const reducer: Reducer<MemberpoolRosterState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case MemberpoolRosterActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }

    case MemberpoolRosterActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, errors: '', data: action.payload };
    }

    case MemberpoolRosterActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }

    default: {
      return state;
    }
  }
};

export { reducer as memberpoolRosterReducer };
