import * as React from 'react';
import styled from 'styled-components';
import loadingGif from '../../../assets/imgs/UnitedFutsal_Loading.gif';

const LoadingIcon: React.FunctionComponent<{
  small?: boolean;
  text?: String;
}> = (props) => {
  const { small, text } = props;
  return (
    <Wrapper>
      <div className={`logo ${small ? 'small' : ''}`}>
        <img src={loadingGif} alt='' />
        {!small && <div>{`${text || 'Loading...'}`}</div>}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  .logo {
    text-align: center;
    img {
      width: 60px;
      opacity: 0.15;
    }
    &.small {
      img {
        width: 30px;
      }
    }
  }
`;
export default LoadingIcon;
