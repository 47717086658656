import * as React from 'react';
import Modal, { Styles, Props } from 'react-modal';
import { Close } from '@icon-park/react';

import { BodyM } from 'styles/v3/variables';

import * as S from './styles';

export interface UploadModalProps extends Props {
  isOpen: boolean;
  title?: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  footerInfo?: React.ReactNode;
  handleCloseModal: () => void;
}

const UploadModal: React.FC<UploadModalProps> = ({
  isOpen,
  title,
  children,
  footer,
  footerInfo,
  handleCloseModal,
  ...props
}) => {
  return isOpen ? (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
      style={S.ModalStyle as Styles}
      {...props}
    >
      <S.ModalHeader>
        {title && <BodyM>{title}</BodyM>}
        <Close size={16} onClick={handleCloseModal} />
      </S.ModalHeader>
      <S.ModalBody>{children}</S.ModalBody>
      {footer && <S.ModalFooter>{footer}</S.ModalFooter>}
      {footerInfo && <S.ModalFooterInfo>{footerInfo}</S.ModalFooterInfo>}
    </Modal>
  ) : null;
};

export default UploadModal;
