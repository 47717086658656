import React, { FC, MouseEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Down, Up } from '@icon-park/react';
import { UnmountClosed } from 'react-collapse';

import { B2bEventStatus } from 'models/v3/Event/Event';

import { NewAgeDivision } from 'admin/models/AgeDivision';
import { RedirectionName } from 'pages/V3/EventManager/enums/redirection-name.enum';
import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';
import DivisionCard from 'components/v3/Cards/DivisionCard';

import ConvertUtil from 'util/ConvertUtil';

import { BodyS, TitleH4 } from 'styles/v3/variables';

import ClubPlaceholder from 'assets/imgs/club/club_placeholder.png';

import { RoundedCardProps } from '../RoundedCard/RoundedCard';

import {
  EventCardWrapper,
  EventContentWrapper,
  EventIcon,
  EventContent,
  EventDateOccurence,
  EventDates,
  IsTodayEvent,
  ApplicationsResumeBtn,
  EventBtnActions,
  EventStatus,
  EventDivisionsContainer,
  EventDivisionsWrapper,
} from './styles';

interface IEventCardProps extends RoundedCardProps {
  title: string;
  description: string;
  divisions?: NewAgeDivision[];
  eventStatus: B2bEventStatus;
  id: string;
  link: string;
  logo?: string;
  startDate: Date;
  finalDate: Date;
  actionText?: string;
}

const EventCard: FC<IEventCardProps> = ({
  title,
  description,
  logo,
  eventStatus,
  link,
  startDate,
  finalDate,
  divisions = null,
  actionText = 'Manage',
}) => {
  const history = useHistory();

  const [collapse, setCollapse] = useState<boolean>(false);

  const startAt = moment(startDate).format('MMM DD yy');
  const endAt = moment(finalDate).format('MMM DD yy');

  const startEvent = new Date(startDate).getTime();
  const today = new Date().getTime();
  const finalEvent = new Date(finalDate).getTime();

  const isHappening = today >= startEvent && today <= finalEvent;

  const handleCollapse = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    setCollapse((prev) => !prev);
  }, []);

  const handleActionClick = useCallback(() => {
    if (eventStatus === 'published') {
      history.push(`${link}?tab=invites&prev=${RedirectionName.CreatedByMe}`);
    } else {
      history.push(link);
    }
  }, [link, eventStatus]);

  return (
    <EventCardWrapper isHappening={isHappening} eventStatus={eventStatus}>
      <EventContentWrapper>
        <EventIcon>
          {logo ? (
            <img src={ConvertUtil.getMinioUrl(logo)} alt='Event Logo' />
          ) : (
            <img src={ClubPlaceholder} alt='Event Placeholder' />
          )}
        </EventIcon>
        <EventContent>
          <EventDates>
            {isHappening && <IsTodayEvent>Today</IsTodayEvent>}
            <EventDateOccurence>
              {startAt} - {endAt}
            </EventDateOccurence>
          </EventDates>
          <EventStatus eventStatus={eventStatus}>{eventStatus}</EventStatus>
          <TitleH4>{title}</TitleH4>
          <BodyS $color='grey300'>{description}</BodyS>
        </EventContent>
        <EventBtnActions>
          <OutlinedButtonWithIcon
            color='dark-white'
            isBold={false}
            isUpper
            onClick={handleActionClick}
          >
            {actionText}
          </OutlinedButtonWithIcon>
        </EventBtnActions>
      </EventContentWrapper>
      {divisions && (
        <>
          <UnmountClosed isOpened={collapse}>
            <EventDivisionsWrapper>
              <EventDivisionsContainer>
                {divisions.map((division) => (
                  <DivisionCard
                    key={`division_${division.id}`}
                    division={division}
                  />
                ))}
              </EventDivisionsContainer>
            </EventDivisionsWrapper>
          </UnmountClosed>
          <ApplicationsResumeBtn onClick={handleCollapse}>
            Applications Resume{' '}
            {collapse ? <Up size={24} /> : <Down size={24} />}
          </ApplicationsResumeBtn>
        </>
      )}
    </EventCardWrapper>
  );
};

export default EventCard;
