import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import RosterService from '../../services/Rosters/RosterService';
import {
  memberpoolRosterFetchError,
  memberpoolRosterFetchSuccess,
} from './actions';
import { MemberpoolRosterActionTypes } from './types';

function* handleFetch(action: any): any {
  try {
    // Execute
    const [players, coaches] = yield all([
      call(RosterService.getRosterMemberpool, action.payload, 'players'),
      call(RosterService.getRosterMemberpool, action.payload, 'coaches'),
    ]);
    if (!players.success && !coaches.success) {
      yield put(memberpoolRosterFetchError(players.error));
    } else {
      yield put(
        memberpoolRosterFetchSuccess({
          players: players.data,
          coaches: coaches.data,
        })
      );
    }
  } catch (err) {
    // console.log(err);
  }
}

function* watchFetchRequest() {
  yield takeEvery(MemberpoolRosterActionTypes.FETCH_REQUEST, handleFetch);
}

function* memberpoolRosterSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default memberpoolRosterSaga;
