import React from 'react';

import { BodyM, BodyMBold } from 'styles/v3/variables';
import { NumberToUSDollar } from 'util/CurrencyUtil';
import { DivisionSummaryProps } from './types';
import * as S from './styles';

export const DivisionSummary = ({
  total = 0,
  discount = 0,
  subtotal = 0,
}: DivisionSummaryProps) => {
  return (
    <S.Container>
      <S.Column>
        <BodyM>Summary Purchase</BodyM>
        {discount && <BodyM $color='supportNeutral'>Coupon discount</BodyM>}
        <BodyM $color='brandPrimary'>Total</BodyM>
      </S.Column>
      <S.Column>
        <BodyMBold>{NumberToUSDollar.format(subtotal / 100)}</BodyMBold>
        {discount && (
          <BodyMBold $color='supportNeutral'>
            -{NumberToUSDollar.format(discount / 100)}
          </BodyMBold>
        )}
        <BodyMBold $color='brandPrimary'>
          {NumberToUSDollar.format(total / 100)}
        </BodyMBold>
      </S.Column>
    </S.Container>
  );
};
