import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(10)};
`;

export const UserImage = styled.div`
  width: ${toRem(100)};
  height: ${toRem(100)};
  border-radius: ${toRem(100)};

  margin: 0;
  overflow: hidden;

  background-color: ${COLORS.grey50};

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(100)};
    height: ${toRem(100)};
  }
`;

export const UserMemberType = styled.div`
  display: flex;
  gap: ${toRem(10)};
`;
