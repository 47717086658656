import { action } from 'typesafe-actions';
import { GameEventModel } from '../../models/GameEvent/GameEventModel';
import {
  MatchDetailsModel,
  GamePreferences,
} from '../../models/Match/MatchModel';
import { MatchDetailsActionTypes } from './types';

export const matchDetailsFetchRequest = (matchId: string) =>
  action(MatchDetailsActionTypes.FETCH_REQUEST, matchId);

export const matchDetailsFetchSuccess = (data: MatchDetailsModel[]) =>
  action(MatchDetailsActionTypes.FETCH_SUCCESS, data);

export const matchDetailsFetchError = (message: string) =>
  action(MatchDetailsActionTypes.FETCH_ERROR, message);

// Game Event Requests
export const matchDetailsAddGameEventRequest = (data: GameEventModel) =>
  action(MatchDetailsActionTypes.ADD_GAME_EVENT_REQUEST, data);

export const matchDetailsAddGameEventSuccess = (data: MatchDetailsModel) =>
  action(MatchDetailsActionTypes.ADD_GAME_EVENT_SUCCESS, data);

export const matchDetailsAddGameEventError = (data: GameEventModel) =>
  action(MatchDetailsActionTypes.ADD_GAME_EVENT_ERROR, data);

// === Actions for resend failed events
export const matchDetailsResendGameEventRequest = (data: GameEventModel) =>
  action(MatchDetailsActionTypes.RESEND_GAME_EVENT_REQUEST, data);

export const matchDetailsResendGameEventSuccess = (data: MatchDetailsModel) =>
  action(MatchDetailsActionTypes.RESEND_GAME_EVENT_SUCCESS, data);

export const matchDetailsResendGameEventError = () =>
  action(MatchDetailsActionTypes.RESEND_GAME_EVENT_ERROR);
// ---

// === Game Event Remove Requests
export const matchDetailsRemoveGameEventRequest = (gameEventId: string) =>
  action(MatchDetailsActionTypes.REMOVE_GAME_EVENT_REQUEST, gameEventId);

export const matchDetailsRemoveGameEventSuccess = (data: MatchDetailsModel) =>
  action(MatchDetailsActionTypes.REMOVE_GAME_EVENT_SUCCESS, data);

export const matchDetailsRemoveGameEventError = (eventId: string) =>
  action(MatchDetailsActionTypes.REMOVE_GAME_EVENT_ERROR, eventId);

export const matchDetailsRemoveLastGameEventFailed = () =>
  action(MatchDetailsActionTypes.REMOVE_GAME_EVENT_FROM_FAILED);
// ---

// === Actions for resend failed events
export const matchDetailsResendRemoveGameEventRequest = (gameEventId: string) =>
  action(MatchDetailsActionTypes.RESEND_REMOVE_GAME_EVENT_REQUEST, gameEventId);

export const matchDetailsResendRemoveGameEventSuccess = (
  data: MatchDetailsModel
) => action(MatchDetailsActionTypes.RESEND_REMOVE_GAME_EVENT_SUCCESS, data);

export const matchDetailsResendRemoveGameEventError = () =>
  action(MatchDetailsActionTypes.RESEND_REMOVE_GAME_EVENT_ERROR);
// ---

export const matchDetailsUpdate = (match: MatchDetailsModel) =>
  action(MatchDetailsActionTypes.UPDATE_MATCH_DETAILS, match);

export const matchDetailsUpdateSuccess = (match: MatchDetailsModel) =>
  action(MatchDetailsActionTypes.UPDATE_MATCH_DETAILS_SUCCESS, match);

export const matchDetailsUpdateGamePreferences = (
  preferences: GamePreferences
) => action(MatchDetailsActionTypes.UPDATE_GAME_PREFERENCES, preferences);

export const matchDetailsUpdateReport = (report: string) =>
  action(MatchDetailsActionTypes.UPDATE_MATCH_REPORT, report);

export const matchDetailsResetData = () =>
  action(MatchDetailsActionTypes.RESET_DATA);
