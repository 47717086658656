import * as React from 'react';
import styled from 'styled-components';
import { color } from '../../../styles/styleVariables';
import './chip.scss';

const Chip: React.FunctionComponent<React.HTMLProps<HTMLSpanElement>> = (
  props
) => {
  return (
    <span className='chip' {...props}>
      {props.children}
    </span>
  );
};

export default Chip;
