import * as React from 'react';
import { ColorsInterface, COLORS } from 'styles/v3/variables';

interface LogoProps {
  minimal?: boolean;
  color?: keyof ColorsInterface;
  width?: number | string;
  height?: number | string;
}

const Logo: React.FC<LogoProps> = ({
  height,
  width,
  color,
  minimal = false,
}) => {
  const propColor = (color: keyof ColorsInterface | undefined) =>
    color ? COLORS[color] : COLORS.white;

  const svgWidth = minimal ? width ?? '30' : width ?? '141';
  const svgHeight = minimal ? height ?? '31' : height ?? '30';

  return minimal ? (
    <svg
      fill='none'
      width={svgWidth}
      height={svgHeight}
      viewBox='0 0 30 31'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.20251 0.432617H0.00369926V0.582282C0.00369926 1.36789 0.00277577 2.15505 0.00185168 2.94272C4.55256e-07 4.51962 -0.00185077 6.09859 0.00369926 7.67136C0.00428676 7.81311 0.00412774 7.89557 0.00393364 7.99622L0.00369926 8.20216V8.58515V8.91981C0.00369926 9.47106 0.439234 9.70996 0.922853 9.97025L20.1039 18.9678L20.251 19.0329L20.2463 19.0347L29.9973 23.6092V22.899V15.7105C29.9973 14.8515 29.4906 14.2231 28.7111 13.8225C28.1074 13.5128 27.4945 13.2257 26.8818 12.9387L26.5463 12.7813C23.9109 11.5444 21.2735 10.3119 18.6363 9.07958C17.6823 8.63378 16.7283 8.18799 15.7745 7.74201C15.7504 7.73086 15.7264 7.71784 15.7051 7.69553L0.226552 0.432617H0.20251ZM24.3482 7.67973H29.9944L29.9965 7.63626C29.9979 7.60969 29.9991 7.58594 29.9991 7.5626L29.9988 6.08808C29.9984 4.85984 29.998 3.6316 30 2.40336C30.0009 1.66433 29.7892 0.956908 29.104 0.662227C28.8062 0.532084 28.4585 0.432617 27.962 0.432617H27.7132H8.89923L24.3482 7.67973ZM15.0921 21.4156C15.1319 21.4342 15.1652 21.4398 15.2003 21.4351L25.1492 26.0384L17.6156 29.5476L17.169 29.7475C17.169 29.7475 16.8842 29.8758 16.8241 29.9018C16.636 29.9834 16.5738 30.017 16.5123 30.0502C16.4609 30.0778 16.41 30.1053 16.2868 30.1602C15.9752 30.3341 15.6183 30.4326 15.2373 30.4326C14.8655 30.4326 14.516 30.3378 14.2099 30.1723H14.2016L1.07178 24.0872C0.395819 23.7154 0.0509056 23.1948 0.0370351 22.3089C0.0361104 22.242 0.0370351 14.4427 0.0370351 14.4427L0.209954 14.5227C0.209954 14.5227 10.1357 19.1028 15.0921 21.4156Z'
        fill={propColor(color)}
      />
    </svg>
  ) : (
    <svg
      fill='none'
      viewBox='0 0 141 30'
      width={svgWidth}
      height={svgHeight}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.20251 0H0.00369926V0.149665C0.00369926 0.935275 0.00277577 1.72243 0.00185168 2.51011C4.55256e-07 4.08701 -0.00185077 5.66598 0.00369926 7.23875C0.00428676 7.38049 0.00412774 7.46295 0.00393364 7.5636L0.00369926 7.76954V8.15254V8.48719C0.00369926 9.03844 0.439234 9.27734 0.922853 9.53763L20.1039 18.5352L20.251 18.6002L20.2463 18.6021L29.9973 23.1766V22.4664V15.2779C29.9973 14.4189 29.4906 13.7905 28.7111 13.3899C28.1074 13.0802 27.4945 12.793 26.8818 12.5061L26.5463 12.3487C23.9109 11.1118 21.2735 9.87931 18.6363 8.64696C17.6823 8.20116 16.7283 7.75538 15.7745 7.30939C15.7504 7.29824 15.7264 7.28523 15.7051 7.26292L0.226552 0H0.20251ZM24.3482 7.24711H29.9944L29.9965 7.20364C29.9979 7.17707 29.9991 7.15333 29.9991 7.12998L29.9988 5.65546C29.9984 4.42722 29.998 3.19898 30 1.97074C30.0009 1.23171 29.7892 0.524291 29.104 0.22961C28.8062 0.0994665 28.4585 0 27.962 0H27.7132H8.89923L24.3482 7.24711ZM15.0921 20.983C15.1319 21.0016 15.1652 21.0071 15.2003 21.0025L25.1492 25.6058L17.6156 29.115L17.169 29.3149C17.169 29.3149 16.8842 29.4432 16.8241 29.4692C16.636 29.5508 16.5738 29.5843 16.5123 29.6175C16.4609 29.6452 16.41 29.6727 16.2868 29.7276C15.9752 29.9015 15.6183 30 15.2373 30C14.8655 30 14.516 29.9052 14.2099 29.7397H14.2016L1.07178 23.6546C0.395819 23.2828 0.0509056 22.7622 0.0370351 21.8763C0.0361104 21.8094 0.0370351 14.0101 0.0370351 14.0101L0.209954 14.09C0.209954 14.09 10.1357 18.6701 15.0921 20.983Z'
        fill={propColor(color)}
      />
      <path
        d='M52.5022 21.6607H48.0219L40.9988 0.0617106H45.4791L50.2621 15.7672L55.0754 0.0617106H59.5254L52.5022 21.6607Z'
        fill={propColor(color)}
      />
      <path
        d='M68.6736 0H73.1539L80.1771 21.6607H75.727L74.4253 17.3717H67.4022L66.1307 21.6607H61.6505L68.6736 0ZM70.8532 5.7083L68.5828 13.3297H73.1842L70.8532 5.7083Z'
        fill={propColor(color)}
      />
      <path
        d='M102.736 16.9398C102.736 19.4699 100.556 21.8458 96.742 21.8458H93.3818C89.4161 21.8458 87.1154 19.4699 87.1154 16.9398V0.030855H91.5957V15.9524C91.5957 17.1866 92.5039 17.8037 93.6542 17.8037H96.1971C97.3474 17.8037 98.2859 17.1866 98.2859 15.9524V0.030855H102.736V16.9398Z'
        fill={propColor(color)}
      />
      <path
        d='M123.911 17.6186V21.6607H111.197V0.030855H115.677V17.6186H123.911Z'
        fill={propColor(color)}
      />
      <path
        d='M136.367 21.6607H131.917V4.07295H126.831V0.030855H140.999V4.07295H136.367V21.6607Z'
        fill={propColor(color)}
      />
      <path
        d='M47.9988 29.9656V25.9572H49.5524C50.1739 25.9572 50.6089 26.3752 50.6089 26.862V27.1884C50.6089 27.4518 50.4598 27.6923 50.2112 27.8469C50.6338 27.9843 50.901 28.3279 50.901 28.7001V28.9921C50.901 29.5189 50.466 29.9656 49.8383 29.9656H47.9988ZM49.7327 28.0931H48.4711V29.5762H49.7886C50.1428 29.5762 50.4287 29.3414 50.4287 29.0093V28.6829C50.4287 28.3393 50.1242 28.0931 49.7327 28.0931ZM49.5027 26.3466H48.4711V27.7037H49.5027C49.857 27.7037 50.1428 27.4804 50.1428 27.1712V26.8791C50.1428 26.5699 49.857 26.3466 49.5027 26.3466Z'
        fill={propColor(color)}
      />
      <path
        d='M55.5377 29.9656H55.0716V28.3164L53.6236 25.9515H54.1146L55.3016 27.9213L56.4389 25.9515H56.9174L55.5377 28.3164V29.9656Z'
        fill={propColor(color)}
      />
      <path
        d='M67.4089 29.1238C67.4089 29.5934 66.9739 29.9999 66.3462 29.9999H65.5445C64.9044 29.9999 64.4569 29.5934 64.4569 29.1238V25.9515H64.9293V29.0437C64.9293 29.3758 65.2151 29.6106 65.5631 29.6106H66.2965C66.6507 29.6106 66.9366 29.3758 66.9366 29.0437V25.9515H67.4089V29.1238Z'
        fill={propColor(color)}
      />
      <path
        d='M71.345 29.9656H70.8727V25.9515H71.3575L73.3834 29.1983V25.9515H73.8495V29.9656H73.3648L71.345 26.736V29.9656Z'
        fill={propColor(color)}
      />
      <path
        d='M77.8267 29.9656H77.3544V25.9515H77.8267V29.9656Z'
        fill={propColor(color)}
      />
      <path
        d='M82.5662 29.9656H82.1002V26.3409H80.9132V25.9515H83.6849V26.3409H82.5662V29.9656Z'
        fill={propColor(color)}
      />
      <path
        d='M89.3091 29.5762V29.9656H86.7673V25.9515H89.247V26.3409H87.2396V27.6923H89.0916V28.0816H87.2396V29.5762H89.3091Z'
        fill={propColor(color)}
      />
      <path
        d='M92.7392 29.9656V25.9572H94.6533C95.281 25.9572 95.716 26.3638 95.716 26.8333V29.0895C95.716 29.559 95.281 29.9656 94.6533 29.9656H92.7392ZM94.6098 26.3466H93.2115V29.5762H94.6098C94.964 29.5762 95.2499 29.3414 95.2499 29.0093V26.9135C95.2499 26.5814 94.964 26.3466 94.6098 26.3466Z'
        fill={propColor(color)}
      />
      <path
        d='M104.002 29.9656H103.53V25.9515H106.047V26.3409H104.002V27.7896H105.935V28.179H104.002V29.9656Z'
        fill={propColor(color)}
      />
      <path
        d='M112.301 29.1238C112.301 29.5934 111.866 29.9999 111.238 29.9999H110.437C109.797 29.9999 109.349 29.5934 109.349 29.1238V25.9515H109.821V29.0437C109.821 29.3758 110.107 29.6106 110.455 29.6106H111.189C111.543 29.6106 111.829 29.3758 111.829 29.0437V25.9515H112.301V29.1238Z'
        fill={propColor(color)}
      />
      <path
        d='M117.002 29.9656H116.535V26.3409H115.348V25.9515H118.12V26.3409H117.002V29.9656Z'
        fill={propColor(color)}
      />
      <path
        d='M120.998 28.8089H121.47V29.0437C121.47 29.3758 121.8 29.6106 122.222 29.6106H122.726C123.161 29.6106 123.503 29.4216 123.503 29.078V28.8318C123.503 28.2592 121.141 28.1446 121.141 27.0738V26.7073C121.141 26.2951 121.557 25.9229 122.16 25.9229H122.726C123.391 25.9229 123.844 26.3122 123.844 26.7646V27.1483H123.378V26.8448C123.378 26.5356 123.068 26.3122 122.682 26.3122H122.229C121.887 26.3122 121.613 26.5069 121.613 26.7875V27.0452C121.613 27.7667 123.969 27.8755 123.969 28.7688V29.1582C123.969 29.6621 123.478 29.9999 122.776 29.9999H122.198C121.489 29.9999 120.998 29.5934 120.998 29.1238V28.8089Z'
        fill={propColor(color)}
      />
      <path
        d='M128.484 25.9343H128.95L130.472 29.9656H129.994L129.559 28.7745H127.875L127.44 29.9656H126.961L128.484 25.9343ZM128.714 26.4726L128.018 28.3851H129.416L128.714 26.4726Z'
        fill={propColor(color)}
      />
      <path
        d='M135.999 29.5762V29.9656H133.668V25.9515H134.141V29.5762H135.999Z'
        fill={propColor(color)}
      />
    </svg>
  );
};

export default Logo;
