import { Reducer } from 'redux';
import { produce } from 'immer';

import { BaseUsersState, BaseUsersActionTypes } from './types';

export const initialState: BaseUsersState = {
  data: [],
  userId: '',
};

const reducer: Reducer<BaseUsersState> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case BaseUsersActionTypes.BASE_USERS_SET_DATA: {
        draft.data = action.payload;
        break;
      }
      case BaseUsersActionTypes.BASE_USERS_SET_USER: {
        draft.data = [...draft.data, action.payload];
        break;
      }
      case BaseUsersActionTypes.BASE_USERS_SET_USER_ID: {
        draft.userId = action.payload;
        break;
      }
      case BaseUsersActionTypes.BASE_USERS_UPDATE_DATA: {
        const updateUser = draft.data.map((user) => {
          if (user.id === action.payload.id) {
            return { ...user, ...action.payload };
          }
          return { ...user };
        });
        draft.data = updateUser;
        break;
      }
      default:
        break;
    }
  });

export { reducer as baseUsersReducer };
