import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;

  .i-icon svg {
    width: 20px;
    height: 20px;
    display: flex;
    &::before {
      display: none;
    }

    path {
      stroke: ${COLORS.grey400};
    }
  }
`;
