import { useQuery } from '@tanstack/react-query';
import VenueServices from 'services/Venue/VenueApi';

const useGetVenues = (eventId: string) => {
  const { data, isLoading, refetch, error } = useQuery(
    ['/get-all-venues', eventId],
    () => VenueServices.listEventVenues(eventId).then((res) => res.data)
  );

  return {
    data,
    error,
    refetch,
    isLoading,
  };
};

export default useGetVenues;
