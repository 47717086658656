import { action } from 'typesafe-actions';
import { Membership, MembershipsActionTypes } from './types';

export const membershipsFetchRequest = () =>
  action(MembershipsActionTypes.FETCH_REQUEST);

export const membershipsFetchSuccess = (data: Membership[]) =>
  action(MembershipsActionTypes.FETCH_SUCCESS, data);

export const membershipsFetchError = (message: string) =>
  action(MembershipsActionTypes.FETCH_ERROR, message);
