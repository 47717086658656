import { Tooltip } from 'antd';
import message from 'antd/lib/message';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import LightBox from '../../../../../../components/base/LightBox/LightBox';
import { GamePeriod } from '../../../../../../models/GameEvent/GameEventModel';
import {
  MatchStatus,
  MatchUtil,
} from '../../../../../../models/Match/MatchModel';
import {
  matchDetailsFetchRequest,
  matchDetailsUpdateGamePreferences,
  matchDetailsUpdateReport,
} from '../../../../../../redux/matchDetails/actions';
import { ApplicationState } from '../../../../../../redux/store';
import MatchService from '../../../../../../services/Match/MatchService';
import { color, font } from '../../../../../../styles/styleVariables';
import GameReportModalEdit from '../../../GameReportModalEdit';
import FinishGameModal from '../FinishGameModal/FinishGameModal';
import PenaltyResult from '../PenaltyResult/PenaltyResult';
import TimerClock from './TimerClock';

interface ITimerProps {
  gameDuration: number;
  editFinishedGame?: boolean;
}

const Timer: React.FunctionComponent<ITimerProps> = (props) => {
  const { gameDuration, editFinishedGame } = props;

  const dispatch = useDispatch();
  const matchDetails = useSelector(
    (state: ApplicationState) => state.matchDetails
  );
  const [editMatchTime, setEditMatchTime] = useState({
    open: false,
    period: GamePeriod.FIRST_HALF,
  });

  const [confirmFinishGameBox, setConfirmFinishGameBox] = useState(false);
  const [gameReportOpen, setGameReportOpen] = useState(false);

  const refMinutes = useRef<HTMLSelectElement>(null);
  const refSeconds = useRef<HTMLSelectElement>(null);
  useEffect(() => {
    if (matchDetails) {
      setEditMatchTime({
        ...editMatchTime,
        open: false,
        period: matchDetails.preferences.timing.period,
      });
    }
  }, [matchDetails.preferences.timing.period]);

  const startTimer = () => {
    if (matchDetails.data.match?.status === MatchStatus.READY) {
      const matchUpdate = MatchUtil.MatchDetailsToMatch(
        matchDetails.data.match
      );
      MatchService.update({
        matchId: matchDetails.data.match?.matchId!,
        body: {
          ...matchUpdate,
          startedTime: new Date(),
          status: MatchStatus.IN_PROGRESS,
        },
      }).then((res) => {
        if (res.success) {
          dispatch(matchDetailsFetchRequest(matchDetails.data.match?.matchId!));
          dispatch(
            matchDetailsUpdateGamePreferences({
              timing: {
                ...matchDetails.preferences.timing!,
                initialTime: new Date().getTime(),
                timerInSeconds: 0,
                timerStart: true,
              },
            })
          );
        } else {
          message.error(`Error:${res.httpCode}`);
        }
      });
    } else {
      dispatch(
        matchDetailsUpdateGamePreferences({
          timing: {
            ...matchDetails.preferences.timing!,
            initialTime: new Date().getTime(),
            timerInSeconds: 0,
            timerStart: true,
          },
        })
      );
    }
  };

  const stopTimer = () => {
    dispatch(
      matchDetailsUpdateGamePreferences({
        timing: {
          ...matchDetails.preferences.timing!,
          timerInSeconds: 0,
          timerInSecondsTotal:
            matchDetails.preferences.timing!.timerInSecondsTotal +
            matchDetails.preferences.timing!.timerInSeconds,
          timerStart: false,
        },
      })
    );
  };

  const confirmEditTime = () => {
    dispatch(
      matchDetailsUpdateGamePreferences({
        timing: {
          ...matchDetails.preferences.timing,
          period: editMatchTime.period,
          timerStart: false,
          timerInSeconds: 0,
          timerInSecondsTotal:
            gameDuration -
            (Number(refMinutes.current?.value) * 60 +
              Number(refSeconds.current?.value)),
          editOpen: false,
          editPeriod: editMatchTime.period,
        },
      })
    );
  };

  const secToTime = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    return {
      hours: hours.toString().padStart(2, '0'),
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0'),
    };
  };

  return (
    <div>
      <TimeWrapper>
        <TimeBox>
          <>
            {matchDetails.data.match?.status === MatchStatus.FINISHED && (
              <div
                className='text-center'
                style={{
                  fontWeight: 600,
                  fontSize: '16px',
                  margin: '10px',
                }}
              >
                Game Finished
              </div>
            )}
            {(matchDetails.data.match?.status !== MatchStatus.FINISHED ||
              editFinishedGame) && (
              /*
              Edit Timer Box
              */
              <>
                {matchDetails.preferences.timing.editOpen && (
                  <div className='time-edit'>
                    <div className='edit-period'>
                      <div
                        onClick={() =>
                          setEditMatchTime({
                            ...editMatchTime,
                            period: GamePeriod.FIRST_HALF,
                          })
                        }
                        className={`item ${
                          editMatchTime.period === GamePeriod.FIRST_HALF &&
                          'active'
                        }`}
                      >
                        1st Half
                      </div>
                      <div
                        onClick={() =>
                          setEditMatchTime({
                            ...editMatchTime,
                            period: GamePeriod.SECOND_HALF,
                          })
                        }
                        className={`item ${
                          editMatchTime.period === GamePeriod.SECOND_HALF &&
                          'active'
                        }`}
                      >
                        2nd Half
                      </div>
                      <div
                        onClick={() =>
                          setEditMatchTime({
                            ...editMatchTime,
                            period: GamePeriod.FIRST_EXTRA_TIME,
                          })
                        }
                        className={`item ${
                          editMatchTime.period ===
                            GamePeriod.FIRST_EXTRA_TIME && 'active'
                        }`}
                      >
                        1st Extra Time
                      </div>
                      <div
                        onClick={() =>
                          setEditMatchTime({
                            ...editMatchTime,
                            period: GamePeriod.SECOND_EXTRA_TIME,
                          })
                        }
                        className={`item ${
                          editMatchTime.period ===
                            GamePeriod.SECOND_EXTRA_TIME && 'active'
                        }`}
                      >
                        2nd Extra Time
                      </div>
                      <div
                        onClick={() =>
                          setEditMatchTime({
                            ...editMatchTime,
                            period: GamePeriod.PENALTY_SHOOTOUT,
                          })
                        }
                        className={`item ${
                          editMatchTime.period ===
                            GamePeriod.PENALTY_SHOOTOUT && 'active'
                        }`}
                      >
                        Penalty Shootout
                      </div>
                      {matchDetails.data.match?.status ===
                      MatchStatus.IN_PROGRESS ? (
                        <div
                          onClick={() => {
                            setConfirmFinishGameBox(true);
                          }}
                          className={`item`}
                        >
                          Finish Game
                        </div>
                      ) : (
                        <Tooltip
                          placement='topLeft'
                          title='You must START the game before finishing it'
                        >
                          <div
                            className={`item`}
                            style={{
                              opacity: 0.3,
                            }}
                          >
                            Finish Game
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    <div
                      className='form-group'
                      style={{
                        alignItems: 'center',
                        fontSize: '48px',
                        fontWeight: 600,
                      }}
                    >
                      <div
                        style={{
                          margin: '0 auto',
                          display: 'flex',
                          maxWidth: '320px',
                        }}
                      >
                        <select
                          ref={refMinutes}
                          style={{
                            maxWidth: '150px',
                            fontSize: '48px',
                            fontWeight: 600,
                          }}
                          defaultValue={
                            secToTime(
                              gameDuration -
                                matchDetails.preferences.timing.timerInSeconds -
                                matchDetails.preferences.timing
                                  .timerInSecondsTotal
                            ).minutes
                          }
                        >
                          {[...Array(21)].map((x, i) => (
                            <option value={i.toString().padStart(2, '0')}>
                              {i.toString().padStart(2, '0')}
                            </option>
                          ))}
                        </select>
                        <span style={{ margin: '5px' }}>:</span>
                        <select
                          ref={refSeconds}
                          style={{
                            maxWidth: '150px',
                            fontSize: '48px',
                            fontWeight: 600,
                          }}
                          defaultValue={
                            secToTime(
                              gameDuration -
                                Math.round(
                                  matchDetails.preferences.timing!
                                    .timerInSeconds
                                ) -
                                Math.round(
                                  matchDetails.preferences.timing!
                                    .timerInSecondsTotal
                                )
                            ).seconds
                          }
                        >
                          {[...Array(60)].map((x, i) => (
                            <option value={i.toString().padStart(2, '0')}>
                              {i.toString().padStart(2, '0')}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                {/* Timer Box */}
                {!matchDetails.preferences.timing.editOpen && (
                  <div style={{ position: 'relative' }}>
                    <div
                      className='report-button'
                      onClick={() => {
                        setGameReportOpen(true);
                      }}
                    >
                      Report
                    </div>
                    {matchDetails.preferences.timing.period !==
                      GamePeriod.PENALTY_SHOOTOUT && (
                      <TimerClock
                        period={matchDetails.preferences.timing.period}
                        gameDuration={gameDuration}
                        timerInSeconds={
                          matchDetails.preferences.timing.timerInSeconds
                        }
                        timerInSecondsTotal={
                          matchDetails.preferences.timing.timerInSecondsTotal
                        }
                      />
                    )}

                    {matchDetails.preferences.timing.period ===
                      GamePeriod.PENALTY_SHOOTOUT && <PenaltyResult />}
                  </div>
                )}
                <div className='actions'>
                  {!matchDetails.preferences.timing.editOpen && (
                    <>
                      {!matchDetails.preferences.timing.timerStart &&
                        matchDetails.preferences.timing.period !==
                          GamePeriod.PENALTY_SHOOTOUT && (
                          <div className='bt start' onClick={startTimer}>
                            Start
                          </div>
                        )}

                      {!matchDetails.preferences.timing.timerStart &&
                        matchDetails.preferences.timing.period ===
                          GamePeriod.PENALTY_SHOOTOUT && (
                          <div
                            className='bt'
                            onClick={() => setConfirmFinishGameBox(true)}
                          >
                            Finish Game
                          </div>
                        )}

                      {matchDetails.preferences.timing!.timerStart && (
                        <div className='bt stop' onClick={stopTimer}>
                          Pause
                        </div>
                      )}

                      <div
                        className='bt'
                        onClick={() =>
                          dispatch(
                            matchDetailsUpdateGamePreferences({
                              timing: {
                                ...matchDetails.preferences.timing!,
                                editOpen: true,
                              },
                            })
                          )
                        }
                      >
                        Edit Time
                      </div>
                    </>
                  )}
                  {matchDetails.preferences.timing.editOpen && (
                    <>
                      <div
                        className='bt'
                        onClick={() =>
                          dispatch(
                            matchDetailsUpdateGamePreferences({
                              timing: {
                                ...matchDetails.preferences.timing!,
                                editOpen: false,
                              },
                            })
                          )
                        }
                      >
                        Cancel
                      </div>
                      <div
                        className='bt'
                        onClick={confirmEditTime}
                        style={{ backgroundColor: '#fd502f' }}
                      >
                        Confirm
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        </TimeBox>
      </TimeWrapper>

      <GameReportModalEdit
        gameReportOpen={gameReportOpen}
        closeGameReport={() => setGameReportOpen(false)}
      />
      <FinishGameModal
        confirmFinishGameBox={confirmFinishGameBox}
        closeModal={() => setConfirmFinishGameBox(false)}
      />
    </div>
  );
};

const TimeWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
`;
const TimeBox = styled.div`
  background-color: ${color.greyLight};
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  border: 1px solid ${color.orange};
  @media (max-width: 420px) {
    width: 100%;
    order: 2;
  }
  .report-button {
    position: absolute;
    right: 5px;
    border: 1px solid ${color.greyText};
    border-radius: 5px;
    font-weight: 600;
    font-size: 10px;
    padding: 5px 10px;
    cursor: pointer;
  }
  .timer {
    font-size: 48px;
    font-weight: 600;
    padding: 0 10px;
    text-align: center;
  }
  .actions {
    display: flex;
    .bt {
      width: 50%;
      padding: 10px;
      border-radius: 5px;
      text-align: center;
      background-color: ${color.silverLight};
      margin-right: 5px;
      color: ${color.greyDark};
      font-family: ${font.druk};
      text-transform: uppercase;
      font-size: 10px;
      &.disabled {
        opacity: 0.3;
      }
      &.start {
        background-color: #2d8d00;
        color: #fff;
      }
      &.stop {
        background-color: #8d0000;
        color: #fff;
      }
    }
  }
  .time-edit {
    .edit-period {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .item {
        font-weight: 600;
        font-size: 10px;
        margin: 3px;
        padding: 5px 10px;
        background-color: ${color.silverLight};
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        &.active {
          background-color: ${color.orange};
        }
      }
    }
  }
`;

export default Timer;
