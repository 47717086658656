import * as React from 'react';

import { BodyLBold, BodyS, BodySBold } from 'styles/v3/variables';
import moment from 'moment';
import { PurchasedByProps } from './types';

import * as S from './styles';

const PurchasedBy: React.FC<PurchasedByProps> = ({ order }) => {
  const soldAt = moment(order?.createdAt ?? '').format('MMM DD yy, hh:mm A');

  return (
    <S.Container>
      <BodyLBold>Purchased by</BodyLBold>
      <S.SoldWrapper>
        <BodyS>Sold at</BodyS>
        <BodySBold>{soldAt}</BodySBold>
      </S.SoldWrapper>
    </S.Container>
  );
};

export default PurchasedBy;
