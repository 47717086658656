import { B2bEventInfo, B2bEventProduct } from './types';

export const B2bEventsData: Array<B2bEventProduct> = [
  {
    id: 'basic_tournament',
    title: 'Basic Tournament',
    description: 'Short event with maximum 7 days',
    price: 500,
    type: 'B2B_EVENT_TOURNAMENT',
    image: '',
  },
  {
    id: 'basic_league',
    title: 'Basic League',
    description: 'Recurrent event with up to 12 weeks',
    price: 500,
    type: 'B2B_EVENT_LEAGUE',
    image: '',
  },
];

export const B2bBoostersData = [
  {
    id: 'sales',
    name: 'Sales',
    description:
      "Reach more teams and be featured in United Futsal's Winter Newsletter",
  },
  {
    id: 'digital_marketing',
    name: 'Digital Marketing',
    description:
      'Enhance your events online presence with custom logos, graphics, and more',
  },
  {
    id: 'physical_marketing',
    name: 'Physical Marketing',
    description:
      "Bring your event's identity to life with banners, signage, posters, and more",
  },
  {
    id: 'technical-management',
    name: 'Technical / Management',
    description:
      'Focus on what truly matters. Let us handle the details, while you enjoy the success of a truly remarkable event',
  },
  {
    id: 'media',
    name: 'Media',
    description: 'Make your event last forever with more than just memories',
  },
];

export const B2bEventInfoData: B2bEventInfo = {
  logoImg: '',
  name: '',
  location: '',
  startDate: new Date(),
  endDate: new Date(new Date().setDate(new Date().getDate() + 5)), // today plus 5 days
  description: '',
  timeSlots: '',
  courtCount: 1,
};
