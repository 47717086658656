import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import icon_foul from '../../../../../assets/imgs/icons/icon_gameEvent_foul.svg';
import icon_goal from '../../../../../assets/imgs/icons/icon_gameEvent_goal.svg';
import icon_red_card from '../../../../../assets/imgs/icons/icon_gameEvent_red.svg';
import icon_yellow_card from '../../../../../assets/imgs/icons/icon_gameEvent_yellow.svg';
import icon_second_yellow_card from '../../../../../assets/imgs/icons/icon_gameEvent_second_yellow.svg';
import ConfirmBox from '../../../../../components/base/ConfirmBox/ConfirmBox';
import {
  GameAction,
  GameEventModel,
} from '../../../../../models/GameEvent/GameEventModel';
import { Coach, Player } from '../../../../../models/Match/MatchModel';
import { color } from '../../../../../styles/styleVariables';
import ConvertUtil from '../../../../../util/ConvertUtil';

interface IGameFactItemProps {
  swapped: boolean;
  matchEvent: GameEventModel;
  player?: Player;
  coach?: Coach;
  homeTeamId?: string;
  awayTeamId?: string;
  onDelete: (itemId: string) => void;
  secondYellow?: boolean;
}

const GameFactItem: React.FunctionComponent<IGameFactItemProps> = (props) => {
  const {
    swapped,
    matchEvent,
    player,
    coach,
    homeTeamId,
    awayTeamId,
    onDelete,
    secondYellow,
  } = props;

  const [confirmRemoveGameEvent, setConfirmRemoveGameEvent] = useState(false);

  const getIconType = (action: GameAction | undefined) => {
    switch (action) {
      case GameAction.GOAL:
        return 'goal';
        break;
      case GameAction.FOUL:
        return 'foul';
        break;
      case !secondYellow && GameAction.YELLOW_CARD:
        return 'yellow';
        break;
      case secondYellow && GameAction.YELLOW_CARD:
        return 'second-yellow';
        break;
      case GameAction.RED_CARD:
        return 'red';
        break;
      case GameAction.OWN_GOAL:
        return 'goal';
        break;
      case GameAction.PK_GOAL:
        return 'goal';
        break;
      case GameAction.PK_MISS:
        return 'goal';
        break;

      default:
        break;
    }
  };

  return (
    <>
      <EventItem
        className={`${swapped ? 'order-swapped' : ''} ${
          matchEvent.action === GameAction.FOUL ? 'foul' : ''
        }`}
      >
        <div className={`team-a ${matchEvent.sendFailed ? 'not-sent' : ''}`}>
          {matchEvent.rosterId === homeTeamId && (
            <>
              <div className='data'>
                <div className='text-grey-light'>
                  {`${matchEvent.time.substring(3)} ${matchEvent.action}`}
                </div>
                <div className='name bold'>
                  <div className='number'>
                    <div>{player?.number || 'C'}</div>
                  </div>

                  {player && `${player.firstName} ${player.lastName}`}
                  {coach && `${coach.firstName} ${coach.lastName}`}
                </div>
              </div>
              <BtIcon
                onClick={() => setConfirmRemoveGameEvent(true)}
                className='bt-delete'
              >
                <span
                  className='icon i-icon-trash-white clear'
                  style={{ margin: 0 }}
                />
              </BtIcon>

              <div
                className='avatar'
                style={{
                  backgroundImage: `url(${ConvertUtil.getMinioUrl(
                    player ? player.photo : coach?.photo
                  )}`,
                }}
              >
                <div className={`icon ${getIconType(matchEvent.action)}`} />
              </div>
            </>
          )}
        </div>
        <div className='line' />
        <div className='team-b'>
          {matchEvent.rosterId === awayTeamId && (
            <>
              <div className='data'>
                <div className='text-grey-light'>
                  {`${matchEvent.time.substring(3)} ${matchEvent.action}`}
                </div>
                <div className='name bold'>
                  <div className='number'>
                    <div>{player?.number || 'C'}</div>
                  </div>
                  {player && `${player.firstName} ${player.lastName}`}
                  {coach && `${coach.firstName} ${coach.lastName}`}
                </div>
              </div>
              <BtIcon
                onClick={() => setConfirmRemoveGameEvent(true)}
                className='bt-delete'
              >
                <span
                  className='icon i-icon-trash-white clear'
                  style={{ margin: 0 }}
                />
              </BtIcon>
              <div
                className='avatar'
                style={{
                  backgroundImage: `url(${ConvertUtil.getMinioUrl(
                    player ? player.photo : coach?.photo
                  )}`,
                }}
              >
                <div className={`icon ${getIconType(matchEvent.action)}`} />
              </div>
            </>
          )}
        </div>
      </EventItem>

      <ConfirmBox
        isOpen={confirmRemoveGameEvent}
        onClose={() => setConfirmRemoveGameEvent(false)}
        onConfirm={() => {
          onDelete(matchEvent.id!);
          setConfirmRemoveGameEvent(false);
        }}
        onCancel={() => setConfirmRemoveGameEvent(false)}
        title='Are you sure you want remove this event?'
        labelConfirm='Yes'
        labelCancel='Cancel'
      />
    </>
  );
};

const BtIcon = styled.div`
  position: relative;
  background: ${color.greyDark};
  border-radius: 45px;
  padding: 5px 11px;
  margin-right: 10px;
  text-align: center;
`;
const EventItem = styled.div`
  display: flex;
  .team-a,
  .team-b {
    padding: 20px 10px;
    width: 50%;
    display: flex;
    align-items: center;
    &.not-sent {
      opacity: 0.3;
    }
    .data {
      width: calc(100% - 40px);
      .event {
      }
      .name {
        display: flex;
        .number {
          background-color: #fff;
          color: ${color.greyLight};
          margin-right: 10px;
          text-align: center;
          font-size: 10px;
          min-width: 25px;
          height: 25px;
          width: 25px;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .player-name {
          width: calc(100% - 30px);
        }
      }
    }
    .avatar {
      width: 40px;
      min-width: 40px;
      height: 40px;
      background-color: #fff;
      border-radius: 20px;
      background-size: cover;
      background-position: center;
      position: relative;
      .icon {
        width: 20px;
        height: 20px;
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        &.goal {
          background-image: url(${icon_goal});
        }
        &.foul {
          background-image: url(${icon_foul});
        }
        &.yellow {
          background-image: url(${icon_yellow_card});
        }
        &.second-yellow {
          background-image: url(${icon_second_yellow_card});
        }
        &.red {
          background-image: url(${icon_red_card});
        }
      }
    }
  }
  .team-a {
    order: 1;
  }
  .team-b {
    order: 3;
  }
  .team-a .bt-delete {
    order: 0;
    padding: 5px;
    span::before {
      top: 5px;
    }
  }

  .team-a .avatar {
    margin-left: auto;
    order: 1;
    .icon {
      top: -5px;
      left: -10px;
    }
  }
  .team-a .data {
    order: 2;
    text-align: left;
    padding-left: 20px;
  }

  .team-b .bt-delete {
    order: 3;
    margin-right: 0;
    margin-left: 10px;
    padding: 5px;
    span::before {
      top: 5px;
    }
  }
  .team-b .avatar {
    order: 2;
    margin-right: auto;
    .icon {
      top: -5px;
      right: -10px;
    }
  }
  .team-b .data {
    order: 1;
    text-align: right;
    padding-right: 20px;
    .name {
      justify-content: flex-end;
    }
  }
  .line {
    width: 1px;
    background-color: #ccc;
    order: 2;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #fff;
      border-radius: 10px;
      top: 40px;
      left: -5px;
    }
  }

  // Reorder if swapped position
  &.order-swapped .team-a {
    order: 3;
    .bt-delete {
      order: 3;
      margin-right: 0;
      margin-left: 10px;
      padding: 5px;
      span::before {
        top: 5px;
      }
    }
    .data {
      order: 1;
      text-align: right;
      padding-left: inherit;
      padding-right: 20px;
      .name {
        justify-content: flex-end;
      }
    }
    .avatar {
      .icon {
        top: -5px;
        left: inherit;
        right: -10px;
      }
    }
  }
  &.order-swapped .team-b {
    order: 1;
    .bt-delete {
      order: 0;
      margin-right: 10px;
      margin-left: 0;
      padding: 5px;
      span::before {
        top: 5px;
      }
    }
    .data {
      order: 2;
      text-align: left;
      padding-right: inherit;
      padding-left: 20px;
      .name {
        justify-content: flex-start;
      }
    }
    .avatar {
      order: 1;
      .icon {
        top: -5px;
        right: inherit;
        left: -10px;
      }
    }
  }
  &.foul {
    .avatar {
      display: none;
    }
    .data .name {
      display: none;
    }
  }
`;

export default GameFactItem;
