import { Col, Row, Select } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Steps from '../../components/Steps/Steps';
import { countries, states } from '../../models/LocaleList';
import { newUserUpdateBasicInfo2 } from '../../redux/newUser/actions';
import { NewUser } from '../../redux/newUser/types';
import { ApplicationState } from '../../redux/store';
import { UserType } from '../../redux/user/types';
import {
  ErrorValidationBox,
  NextButtonsWrapper,
  TopHeader,
} from './WizardComponents.style';

const { Option } = Select;
const WizardMajority: React.FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control, trigger } = useForm<
    NewUser
  >();
  const { newUser } = useSelector(
    (state: ApplicationState) => state.newUser
  ).data;
  const [showSecondGuardian, setShowSecondGuardian] = useState(false);
  const [fillFirstGuardianError, setFillFirstGuardianError] = useState(false);

  const fixAutocomplete = () => {
    document.querySelectorAll('.ant-select-selector input').forEach((e) => {
      e.setAttribute('autocomplete', 'stopAutocomplete');
    });
  };

  function isMinor(): boolean {
    if (newUser?.birthDate !== undefined) {
      const date: moment.Moment = moment(
        moment().subtract(18, 'years').format('YYYY-MM-DD')
      );
      const birth: moment.Moment = moment(
        moment(newUser.birthDate).format('YYYY-MM-DD')
      );

      return birth.isAfter(date);
    }
    return false;
  }

  function onSubmit(data: NewUser) {
    dispatch(newUserUpdateBasicInfo2(data));
    history.push('/wizard/photo');
  }

  function toggleSecondGuardianForm() {
    if (Object.keys(errors).length !== 0) {
      trigger();
      setFillFirstGuardianError(true);
    } else {
      setFillFirstGuardianError(false);
      setShowSecondGuardian(!showSecondGuardian);
    }
  }

  React.useEffect(() => {
    fixAutocomplete();
  }, []);
  return (
    <>
      {
        // User Older 18
        !isMinor() && (
          <div>
            <TopHeader>
              <h5 style={{ textTransform: 'uppercase' }}>
                COMPLETE YOUR HOUSEHOLD USER CREATION TO JOIN THE UNITED FUTSAL
                COMMUNITY!
              </h5>
              <h1>THIS USER IS 18 YEARS OR OLDER</h1>
            </TopHeader>
            <Row justify='center'>
              <Col xxl={18} lg={18} md={24} className='column-wizard'>
                <Steps activeStep={1} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row style={{ maxWidth: '1000px', margin: '0 auto' }}>
                    <Col md={12} sm={24} className='column-form'>
                      <div>
                        <div className='form-group'>
                          <label htmlFor='address.line1'>
                            Address line 1
                            <div>
                              <span className='icon icon-house' />
                              <input
                                id='address.line1'
                                name='address.line1'
                                ref={register({ required: true })}
                                className='icon'
                                type='text'
                                defaultValue={newUser?.address?.line1}
                                autoComplete='stopAutocomplete'
                              />
                              <ErrorValidationBox>
                                {errors.address?.line1 && 'Address is required'}
                              </ErrorValidationBox>
                            </div>
                          </label>
                        </div>

                        <div className='form-group'>
                          <label htmlFor='address.line2'>
                            Address line 2 (Optional)
                            <div>
                              <span className='icon icon-house' />
                              <input
                                id='address.line2'
                                name='address.line2'
                                ref={register}
                                className='icon'
                                type='text'
                                defaultValue={newUser?.address?.line2}
                                autoComplete='stopAutocomplete'
                              />
                            </div>
                          </label>
                        </div>

                        <div className='form-group'>
                          <label htmlFor='address.country'>
                            Country
                            <div>
                              <span className='icon icon-flag' />
                              <Controller
                                as={
                                  <Select
                                    className='icon'
                                    placeholder='Country'
                                    showSearch
                                    optionFilterProp='data-label'
                                  >
                                    {countries.map((item) => {
                                      return (
                                        <Option
                                          key={`${item.name}_${item.code}`}
                                          value={item.code}
                                          data-label={item.name}
                                        >
                                          {item.name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                }
                                id='address.country'
                                name='address.country'
                                rules={{ required: true }}
                                control={control}
                                defaultValue={newUser?.address?.country}
                              />
                              <ErrorValidationBox>
                                {errors.address?.country &&
                                  'Country is required'}
                              </ErrorValidationBox>
                            </div>
                          </label>
                        </div>

                        <div className='form-group'>
                          <label htmlFor='address.state'>
                            State
                            <div>
                              <span className='icon icon-state' />
                              <Controller
                                as={
                                  <Select
                                    placeholder='State'
                                    showSearch
                                    optionFilterProp='data-label'
                                  >
                                    {states.map((item) => {
                                      return (
                                        <Option
                                          value={item.code}
                                          key={`${item.name}_${item.code}`}
                                          data-label={item.name}
                                        >
                                          {item.name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                }
                                id='address.state'
                                name='address.state'
                                className='icon'
                                rules={{ required: true }}
                                control={control}
                                defaultValue={newUser?.address?.state}
                              />
                              <ErrorValidationBox>
                                {errors.address?.state && 'State is required'}
                              </ErrorValidationBox>
                            </div>
                          </label>
                        </div>
                      </div>
                    </Col>
                    <Col md={12} sm={24} className='column-form'>
                      <div>
                        <div className='form-group'>
                          <label htmlFor='address.city'>
                            City
                            <div>
                              <span className='icon icon-city' />
                              <input
                                id='address.city'
                                name='address.city'
                                ref={register({ required: true })}
                                className='icon'
                                type='text'
                                defaultValue={newUser?.address?.city}
                                autoComplete='stopAutocomplete'
                              />
                              <ErrorValidationBox>
                                {errors.address?.city && 'City is required'}
                              </ErrorValidationBox>
                            </div>
                          </label>
                        </div>

                        <div className='form-group'>
                          <label htmlFor='address.zipCode'>
                            Zipcode
                            <div>
                              <span className='icon icon-zip' />
                              <input
                                id='address.zipCode'
                                name='address.zipCode'
                                ref={register({ required: true })}
                                className='icon'
                                type='text'
                                defaultValue={newUser?.address?.zipCode}
                                autoComplete='stopAutocomplete'
                              />
                              <ErrorValidationBox>
                                {errors.address?.zipCode &&
                                  'Zipcode is required'}
                              </ErrorValidationBox>
                            </div>
                          </label>
                        </div>

                        <div className='form-group'>
                          <label htmlFor='phoneNumber'>
                            Phone Number
                            <div>
                              <span className='icon icon-phone' />
                              <input
                                id='phoneNumber'
                                name='phoneNumber'
                                ref={register({ required: true })}
                                className='icon'
                                type='text'
                                defaultValue={newUser?.phoneNumber}
                                autoComplete='stopAutocomplete'
                              />
                              <ErrorValidationBox>
                                {errors.phoneNumber &&
                                  'Phone Number is required'}
                              </ErrorValidationBox>
                            </div>
                          </label>
                        </div>

                        <div className='form-group'>
                          <label htmlFor='email'>
                            Email (Optional)
                            <div>
                              <span className='icon icon-email' />
                              <input
                                id='email'
                                name='email'
                                ref={register}
                                className='icon'
                                type='text'
                                defaultValue={newUser?.email}
                                autoComplete='stopAutocomplete'
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>

            <NextButtonsWrapper>
              <NextButtonsWrapper>
                <Link to='/wizard/info'>
                  <div className='link'> ◄ PREVIOUS STEP</div>
                </Link>

                <div
                  className='link'
                  onClick={handleSubmit(onSubmit)}
                  onKeyDown={handleSubmit(onSubmit)}
                  role='button'
                  aria-label='Next Step'
                  tabIndex={0}
                >
                  NEXT STEP ►
                </div>
              </NextButtonsWrapper>
            </NextButtonsWrapper>
          </div>
        )
      }

      {
        /* User Under 18, and not CLUB DIRECTOR */
        isMinor() && newUser?.type !== UserType.CLUB_DIRECTOR && (
          <div>
            <TopHeader>
              <h5 style={{ textTransform: 'uppercase' }}>
                COMPLETE YOUR HOUSEHOLD USER CREATION TO JOIN THE UNITED FUTSAL
                COMMUNITY!
              </h5>
              <h1>THIS USER IS YOUNGER THAN 18 YEARS</h1>
            </TopHeader>

            <Row justify='center' style={{ minHeight: '80vh' }}>
              <Col xxl={18} lg={18} md={24} className='column-wizard'>
                <div>
                  <Steps activeStep={1} />
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row
                      className={`${showSecondGuardian ? 'd-hide' : ''}`}
                      style={{ maxWidth: '1000px', margin: '0 auto' }}
                    >
                      <Col md={12} sm={24} className='column-form'>
                        <div>
                          <div className='form-group'>
                            <label htmlFor='guardian1.firstName'>
                              Parent/Guardian First Name
                              <div>
                                <span className='icon icon-user' />
                                <input
                                  id='guardian1.firstName'
                                  name='guardian1.firstName'
                                  ref={register({ required: true })}
                                  defaultValue={newUser?.guardian1?.firstName}
                                  className='icon'
                                  type='text'
                                  autoComplete='stopAutocomplete'
                                />
                                <ErrorValidationBox>
                                  {errors.guardian1?.firstName &&
                                    'Parent/Guardian First Name is required'}
                                </ErrorValidationBox>
                              </div>
                            </label>
                          </div>

                          <div className='form-group'>
                            <label htmlFor='guardian1.middleName'>
                              Parent/Guardian Middle Name (Optional)
                              <div>
                                <span className='icon icon-user' />
                                <input
                                  id='guardian1.middleName'
                                  name='guardian1.middleName'
                                  ref={register}
                                  defaultValue={newUser?.guardian1?.middleName}
                                  className='icon'
                                  type='text'
                                  autoComplete='stopAutocomplete'
                                />
                              </div>
                            </label>
                          </div>

                          <div className='form-group'>
                            <label htmlFor='guardian1.lastName'>
                              Parent/Guardian Last Name
                              <div>
                                <span className='icon icon-user' />
                                <input
                                  id='guardian1.lastName'
                                  name='guardian1.lastName'
                                  ref={register({ required: true })}
                                  defaultValue={newUser?.guardian1?.lastName}
                                  className='icon'
                                  type='text'
                                  autoComplete='stopAutocomplete'
                                />
                                <ErrorValidationBox>
                                  {errors.guardian1?.lastName &&
                                    'Parent/Guardian Last Name is required'}
                                </ErrorValidationBox>
                              </div>
                            </label>
                          </div>

                          <div className='form-group'>
                            <label htmlFor='guardian1.suffix'>
                              Parent/Guardian Suffix (optional)
                              <div>
                                <span className='icon icon-user' />
                                <input
                                  id='guardian1.suffix'
                                  name='guardian1.suffix'
                                  ref={register}
                                  defaultValue={newUser?.guardian1?.suffix}
                                  className='icon'
                                  type='text'
                                  autoComplete='stopAutocomplete'
                                />
                              </div>
                            </label>
                          </div>

                          <div className='form-group'>
                            <label htmlFor='guardian1.phoneNumber'>
                              Parent/Guardian Phone Number
                              <div>
                                <span className='icon icon-phone' />
                                <input
                                  id='guardian1.phoneNumber'
                                  name='guardian1.phoneNumber'
                                  ref={register({ required: true })}
                                  defaultValue={newUser?.guardian1?.phoneNumber}
                                  className='icon'
                                  type='text'
                                  autoComplete='stopAutocomplete'
                                />
                                <ErrorValidationBox>
                                  {errors.guardian1?.phoneNumber &&
                                    'Parent/Guardian Phone Number is required'}
                                </ErrorValidationBox>
                              </div>
                            </label>
                          </div>

                          <div className='form-group'>
                            <label htmlFor='guardian1.email'>
                              Parent/Guardian Email
                              <div>
                                <span className='icon icon-email' />
                                <input
                                  id='guardian1.email'
                                  name='guardian1.email'
                                  ref={register({ required: true })}
                                  defaultValue={newUser?.guardian1?.email}
                                  className='icon'
                                  type='text'
                                  autoComplete='stopAutocomplete'
                                />
                                <ErrorValidationBox>
                                  {errors.guardian1?.email &&
                                    'Parent/Guardian Email is required'}
                                </ErrorValidationBox>
                              </div>
                            </label>
                          </div>
                        </div>
                      </Col>
                      <Col md={12} sm={24} className='column-form'>
                        <div>
                          <div className='form-group'>
                            <label htmlFor='guardian1.address.line1'>
                              Address Line 1
                              <div>
                                <span className='icon icon-house' />
                                <input
                                  id='guardian1.address.line1'
                                  name='guardian1.address.line1'
                                  ref={register({ required: true })}
                                  defaultValue={
                                    newUser?.guardian1?.address?.line1
                                  }
                                  className='icon'
                                  type='text'
                                  autoComplete='stopAutocomplete'
                                />
                                <ErrorValidationBox>
                                  {errors.guardian1?.address?.line1 &&
                                    'Parent/Guardian Address is required'}
                                </ErrorValidationBox>
                              </div>
                            </label>
                          </div>

                          <div className='form-group'>
                            <label htmlFor='guardian1.address.line2'>
                              Address Line 2 (Optional)
                              <div>
                                <span className='icon icon-house' />
                                <input
                                  id='guardian1.address.line2'
                                  name='guardian1.address.line2'
                                  ref={register}
                                  defaultValue={
                                    newUser?.guardian1?.address?.line2
                                  }
                                  className='icon'
                                  type='text'
                                  autoComplete='stopAutocomplete'
                                />
                              </div>
                            </label>
                          </div>

                          <div className='form-group'>
                            <div className='label'>Country</div>
                            <div>
                              <span className='icon icon-flag' />
                              <Controller
                                as={
                                  <Select
                                    placeholder='Country'
                                    showSearch
                                    optionFilterProp='data-label'
                                  >
                                    {countries.map((item) => {
                                      return (
                                        <Option
                                          value={item.code}
                                          key={`${item.name}_${item.code}`}
                                          data-label={item.name}
                                        >
                                          {item.name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                }
                                name='guardian1.address.country'
                                rules={{ required: true }}
                                control={control}
                                defaultValue={
                                  newUser?.guardian1?.address?.country
                                }
                                className='icon'
                              />
                              <ErrorValidationBox>
                                {errors.guardian1?.address?.country &&
                                  'Parent/Guardian Country is required'}
                              </ErrorValidationBox>
                            </div>
                          </div>

                          <div className='form-group'>
                            <div className='label'>State</div>
                            <div>
                              <span className='icon icon-state' />
                              <Controller
                                as={
                                  <Select
                                    placeholder='State'
                                    showSearch
                                    optionFilterProp='data-label'
                                  >
                                    {states.map((item) => {
                                      return (
                                        <Option
                                          value={item.code}
                                          key={`${item.name}_${item.code}`}
                                          data-label={item.name}
                                        >
                                          {item.name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                }
                                name='guardian1.address.state'
                                rules={{ required: true }}
                                control={control}
                                defaultValue={
                                  newUser?.guardian1?.address?.state
                                }
                                className='icon'
                              />
                              <ErrorValidationBox>
                                {errors.guardian1?.address?.state &&
                                  'Parent/Guardian State is required'}
                              </ErrorValidationBox>
                            </div>
                          </div>

                          <div className='form-group'>
                            <label htmlFor='guardian1.address.city'>
                              City
                              <div>
                                <span className='icon icon-city' />
                                <input
                                  id='guardian1.address.city'
                                  name='guardian1.address.city'
                                  ref={register({ required: true })}
                                  defaultValue={
                                    newUser?.guardian1?.address?.city
                                  }
                                  type='text'
                                  className='icon'
                                  autoComplete='stopAutocomplete'
                                />
                                <ErrorValidationBox>
                                  {errors.guardian1?.address?.city &&
                                    'Parent/Guardian City is required'}
                                </ErrorValidationBox>
                              </div>
                            </label>
                          </div>

                          <div className='form-group'>
                            <label htmlFor='guardian1.address.zipCode'>
                              Zipcode
                              <div>
                                <span className='icon icon-zip' />
                                <input
                                  id='guardian1.address.zipCode'
                                  name='guardian1.address.zipCode'
                                  ref={register({ required: true })}
                                  defaultValue={
                                    newUser?.guardian1?.address?.zipCode
                                  }
                                  type='text'
                                  className='icon'
                                  autoComplete='stopAutocomplete'
                                />
                                <ErrorValidationBox>
                                  {errors.guardian1?.address?.zipCode &&
                                    'Parent/Guardian Zipcode is required'}
                                </ErrorValidationBox>
                              </div>
                            </label>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Second Guardian Form */}
                    {showSecondGuardian && (
                      <Row
                        style={{ maxWidth: '1000px', margin: '0 auto' }}
                        className={`animate__animated animate__fadeInUp ${
                          !showSecondGuardian ? 'd-hide' : ''
                        }`}
                      >
                        <Col md={12} sm={24} className='column-form'>
                          <div>
                            <div className='form-group'>
                              <label htmlFor='guardian1.firstName'>
                                Parent/Guardian2 First Name
                                <div>
                                  <span className='icon icon-user' />
                                  <input
                                    id='guardian1.firstName'
                                    name='guardian1.firstName'
                                    ref={register({ required: true })}
                                    defaultValue={newUser?.guardian2?.firstName}
                                    className='icon'
                                    type='text'
                                    autoComplete='stopAutocomplete'
                                  />
                                  <ErrorValidationBox>
                                    {errors.guardian2?.firstName &&
                                      'Parent/Guardian First Name is required'}
                                  </ErrorValidationBox>
                                </div>
                              </label>
                            </div>

                            <div className='form-group'>
                              <label htmlFor='guardian2.middleName'>
                                Parent/Guardian Middle Name (Optional)
                                <div>
                                  <span className='icon icon-user' />
                                  <input
                                    id='guardian2.middleName'
                                    name='guardian2.middleName'
                                    ref={register}
                                    defaultValue={
                                      newUser?.guardian2?.middleName
                                    }
                                    className='icon'
                                    type='text'
                                    autoComplete='stopAutocomplete'
                                  />
                                </div>
                              </label>
                            </div>

                            <div className='form-group'>
                              <label htmlFor='guardian2.lastName'>
                                Parent/Guardian Last Name
                                <div>
                                  <span className='icon icon-user' />
                                  <input
                                    id='guardian2.lastName'
                                    name='guardian2.lastName'
                                    ref={register({ required: true })}
                                    defaultValue={newUser?.guardian2?.lastName}
                                    className='icon'
                                    type='text'
                                    autoComplete='stopAutocomplete'
                                  />
                                  <ErrorValidationBox>
                                    {errors.guardian2?.lastName &&
                                      'Parent/Guardian Last Name is required'}
                                  </ErrorValidationBox>
                                </div>
                              </label>
                            </div>

                            <div className='form-group'>
                              <label htmlFor='guardian2.suffix'>
                                Parent/Guardian Suffix (optional)
                                <div>
                                  <span className='icon icon-user' />
                                  <input
                                    id='guardian2.suffix'
                                    name='guardian2.suffix'
                                    ref={register}
                                    defaultValue={newUser?.guardian2?.suffix}
                                    className='icon'
                                    type='text'
                                    autoComplete='stopAutocomplete'
                                  />
                                </div>
                              </label>
                            </div>

                            <div className='form-group'>
                              <label htmlFor='guardian2.phoneNumber'>
                                Parent/Guardian Phone Number
                                <div>
                                  <span className='icon icon-phone' />
                                  <input
                                    id='guardian2.phoneNumber'
                                    name='guardian2.phoneNumber'
                                    ref={register({ required: true })}
                                    defaultValue={
                                      newUser?.guardian2?.phoneNumber
                                    }
                                    className='icon'
                                    type='text'
                                    autoComplete='stopAutocomplete'
                                  />
                                  <ErrorValidationBox>
                                    {errors.guardian2?.phoneNumber &&
                                      'Parent/Guardian Phone Number is required'}
                                  </ErrorValidationBox>
                                </div>
                              </label>
                            </div>

                            <div className='form-group'>
                              <label htmlFor='guardian2.email'>
                                Parent/Guardian Email
                                <div>
                                  <span className='icon icon-email' />
                                  <input
                                    id='guardian2.email'
                                    name='guardian2.email'
                                    ref={register({ required: true })}
                                    defaultValue={newUser?.guardian2?.email}
                                    className='icon'
                                    type='text'
                                    autoComplete='stopAutocomplete'
                                  />
                                  <ErrorValidationBox>
                                    {errors.guardian2?.email &&
                                      'Parent/Guardian Email is required'}
                                  </ErrorValidationBox>
                                </div>
                              </label>
                            </div>
                          </div>
                        </Col>
                        <Col md={12} sm={24} className='column-form'>
                          <div>
                            <div className='form-group'>
                              <label htmlFor='guardian2.address.line1'>
                                Address Line 1
                                <div>
                                  <span className='icon icon-house' />
                                  <input
                                    id='guardian2.address.line1'
                                    name='guardian2.address.line1'
                                    ref={register({ required: true })}
                                    defaultValue={
                                      newUser?.guardian2?.address?.line1
                                    }
                                    className='icon'
                                    type='text'
                                    autoComplete='stopAutocomplete'
                                  />
                                  <ErrorValidationBox>
                                    {errors.guardian2?.address?.line1 &&
                                      'Parent/Guardian Address is required'}
                                  </ErrorValidationBox>
                                </div>
                              </label>
                            </div>

                            <div className='form-group'>
                              <label htmlFor='guardian2.address.line2'>
                                Address Line 2 (Optional)
                                <div>
                                  <span className='icon icon-house' />
                                  <input
                                    id='guardian2.address.line2'
                                    name='guardian2.address.line2'
                                    ref={register}
                                    defaultValue={
                                      newUser?.guardian2?.address?.line2
                                    }
                                    className='icon'
                                    type='text'
                                    autoComplete='stopAutocomplete'
                                  />
                                </div>
                              </label>
                            </div>

                            <div className='form-group'>
                              <div className='label'>Country</div>
                              <div>
                                <span className='icon icon-flag' />
                                <Controller
                                  as={
                                    <Select
                                      placeholder='Country'
                                      showSearch
                                      optionFilterProp='data-label'
                                    >
                                      {countries.map((item) => {
                                        return (
                                          <Option
                                            key={item.code}
                                            value={item.code}
                                            data-label={item.name}
                                          >
                                            {item.name}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  }
                                  name='guardian2.address.country'
                                  rules={{ required: true }}
                                  control={control}
                                  defaultValue={
                                    newUser?.guardian2?.address?.country
                                  }
                                  className='icon'
                                />
                                <ErrorValidationBox>
                                  {errors.guardian2?.address?.country &&
                                    'Parent/Guardian Country is required'}
                                </ErrorValidationBox>
                              </div>
                            </div>

                            <div className='form-group'>
                              <div className='label'>State</div>
                              <div>
                                <span className='icon icon-state' />
                                <Controller
                                  as={
                                    <Select
                                      placeholder='State'
                                      showSearch
                                      optionFilterProp='data-label'
                                    >
                                      {states.map((item) => {
                                        return (
                                          <Option
                                            key={item.code}
                                            value={item.code}
                                            data-label={item.name}
                                          >
                                            {item.name}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  }
                                  name='guardian2.address.state'
                                  rules={{ required: true }}
                                  control={control}
                                  defaultValue={
                                    newUser?.guardian2?.address?.state
                                  }
                                  className='icon'
                                />
                                <ErrorValidationBox>
                                  {errors.guardian2?.address?.state &&
                                    'Parent/Guardian State is required'}
                                </ErrorValidationBox>
                              </div>
                            </div>

                            <div className='form-group'>
                              <label htmlFor='guardian2.address.city'>
                                City
                                <div>
                                  <span className='icon icon-city' />
                                  <input
                                    id='guardian2.address.city'
                                    name='guardian2.address.city'
                                    ref={register({ required: true })}
                                    defaultValue={
                                      newUser?.guardian2?.address?.city
                                    }
                                    type='text'
                                    className='icon'
                                    autoComplete='stopAutocomplete'
                                  />
                                  <ErrorValidationBox>
                                    {errors.guardian2?.address?.city &&
                                      'Parent/Guardian City is required'}
                                  </ErrorValidationBox>
                                </div>
                              </label>
                            </div>

                            <div className='form-group'>
                              <label htmlFor='guardian2.address.zipCode'>
                                Zipcode
                                <div>
                                  <span className='icon icon-zip' />
                                  <input
                                    id='guardian2.address.zipCode'
                                    name='guardian2.address.zipCode'
                                    ref={register({ required: true })}
                                    defaultValue={
                                      newUser?.guardian2?.address?.zipCode
                                    }
                                    type='text'
                                    className='icon'
                                    autoComplete='stopAutocomplete'
                                  />
                                  <ErrorValidationBox>
                                    {errors.guardian2?.address?.zipCode &&
                                      'Parent/Guardian Zipcode is required'}
                                  </ErrorValidationBox>
                                </div>
                              </label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <ErrorValidationBox>
                      {fillFirstGuardianError &&
                        'You need to fill all required fields for the First Legal Guardian'}
                    </ErrorValidationBox>
                  </form>
                  <div style={{ maxWidth: '500px', margin: '0 auto' }} />

                  <NextButtonsWrapper data-testid='wizard-nav-wrapper'>
                    <Link to='/wizard/info'>
                      <div className='link'> ◄ PREVIOUS STEP</div>
                    </Link>

                    {!showSecondGuardian && (
                      <div
                        className='link'
                        onClick={() => toggleSecondGuardianForm()}
                        onKeyDown={() => toggleSecondGuardianForm()}
                        role='button'
                        aria-label='Add Legal Guardian'
                        tabIndex={0}
                      >
                        ADD LEGAL GUARDIAN{' '}
                        <span style={{ fontSize: '10px' }}>✚</span>
                      </div>
                    )}
                    {showSecondGuardian && (
                      <div
                        className='link'
                        onClick={() => setShowSecondGuardian(false)}
                        onKeyDown={() => setShowSecondGuardian(false)}
                        role='button'
                        aria-label='Back'
                        tabIndex={0}
                      >
                        BACK TO FIRST GUARDIAN INFO
                      </div>
                    )}

                    <div
                      className='link'
                      onClick={handleSubmit(onSubmit)}
                      onKeyDown={handleSubmit(onSubmit)}
                      role='button'
                      aria-label='Next Step'
                      tabIndex={0}
                    >
                      {' '}
                      NEXT STEP ►
                    </div>
                  </NextButtonsWrapper>
                </div>
              </Col>
            </Row>
          </div>
        )
      }

      {
        // User Older 18
        isMinor() && newUser?.type === UserType.CLUB_DIRECTOR && (
          <div>
            <TopHeader>
              <h5 style={{ textTransform: 'uppercase' }}>
                COMPLETE YOUR HOUSEHOLD USER CREATION TO JOIN THE UNITED FUTSAL
                COMMUNITY!
              </h5>
              <h1>THIS USER IS YOUNGER THAN 18 YEARS</h1>
            </TopHeader>
            <Row justify='center'>
              <Col xxl={18} lg={18} md={24} className='column-wizard'>
                <div style={{ textAlign: 'center' }}>
                  <h3>Club Directors must be 18 years or older</h3>
                </div>
              </Col>
            </Row>

            <NextButtonsWrapper>
              <NextButtonsWrapper>
                <Link to='/wizard/info'>
                  <div className='link'> ◄ PREVIOUS STEP</div>
                </Link>
              </NextButtonsWrapper>
            </NextButtonsWrapper>
          </div>
        )
      }
    </>
  );
};

export default WizardMajority;
