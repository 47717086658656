import React from 'react';
import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';
import { CheckboxFieldProps } from './types';

import * as S from './styles';

export const Checkbox = ({
  id,
  disabled,
  selected,
  toggle,
}: CheckboxFieldProps) => {
  return (
    <S.Container
      title={
        disabled
          ? 'Already an Official Member or Permission does not require an Official Membership'
          : 'Select'
      }
    >
      <InputCheckbox
        disabled={disabled}
        id={`checkbox-${id}`}
        name='selectedItem'
        onChange={() => toggle()}
        checked={selected}
      />
    </S.Container>
  );
};
