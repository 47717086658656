import { produce } from 'immer';
import { Reducer } from 'redux';
import { setSelectedAccountId, setSelectedAccountType } from 'util/UsersUtil';
import { CurrentUserActionTypes, CurrentUserState } from './types';

export const initialState: CurrentUserState = {
  data: {},
};

const reducer: Reducer<CurrentUserState> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CurrentUserActionTypes.SET_CURRENT_USER: {
        draft.data = action.payload;

        if (action.payload?.id) {
          const { types, id } = action.payload;

          const accountType = Array.isArray(types) ? 'BaseUser' : 'User';

          setSelectedAccountType(accountType);
          setSelectedAccountId(id);
        }

        break;
      }
      case CurrentUserActionTypes.RESET_CURRENT_USER: {
        draft.data = {};

        break;
      }

      default:
        break;
    }
  });

export { reducer as currentUserReducer };
