import * as React from 'react';

import { BodyL, BodyXL, BodyXLBold } from 'styles/v3/variables';
import RoundedCard, { RoundedCardProps } from '../RoundedCard/RoundedCard';
import * as S from './styles';

interface StripeCardProps extends Omit<RoundedCardProps, 'orientation'> {
  title: string;
  subtitle?: string;
  description?: string;
  stripeColor: string;
  hasChildren?: boolean;
  width?: string;
}

const StripeCard: React.FC<StripeCardProps> = ({
  title,
  subtitle,
  description,
  stripeColor,
  size,
  width,
  children,
  hasChildren,
  ...props
}) => {
  return (
    <S.StripeCardWrapper $width={width} className='card-detail'>
      <RoundedCard {...props} size={size} orientation='row'>
        <S.Stripe $color={stripeColor} />
        <S.InfoContainer>
          <BodyXLBold>{title}</BodyXLBold>
          {!!subtitle && <BodyL>{subtitle}</BodyL>}
          {!!description && <BodyXL $color='grey500'>{description}</BodyXL>}
        </S.InfoContainer>

        {hasChildren && <S.ChildrenWrapper>{children}</S.ChildrenWrapper>}
      </RoundedCard>
    </S.StripeCardWrapper>
  );
};

export default StripeCard;
