import { Button, Col, Form, Input, message, Row, Select, Skeleton } from 'antd';
import {
  useForm,
  FormProvider,
  useFormContext,
  Controller,
} from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import UserInputSearch from '../../../components/base/form/UserInputSearch/UserInputSearch';
import PageContainer from '../../../components/base/PageContainer/PageContainer';
import GroupBox from '../../../components/uielements/GroupBox/GroupBox';
import SingleUpload from '../../../components/Upload/SingleUpload';
import { countries, states } from '../../../models/LocaleList';
import { UserFilter } from '../../../models/User/UserFilter';
import UserType from '../../../models/User/UserTypeModel';
import ClubService from '../../../services/Club/ClubService';
import { DOWNLOAD_STORAGE_ENDPOINT } from '../../../services/config.api';
import { UploadFileResponse } from '../../../services/upload/UploadService';
import UserServices from '../../../services/User/UserApi';
import { ClubView } from '../../models/club/Club';
import { ErrorValidationBox } from '../../../pages/Wizard/WizardComponents.style';
import FormUtil from '../../../util/FormUtil';

const ClubCreatePage: React.FunctionComponent = () => {
  const formHook = useForm();

  const [form] = Form.useForm();
  const history = useHistory();
  const routeParams: any = useParams();

  const [loading, setLoading] = useState(false);
  const [formTitle, setFormTitle] = useState('Create');
  const [loadedObj, setLoadedObj] = useState<ClubView>();

  useEffect(() => {
    loadDirectors();
    find(routeParams.id);
  }, [routeParams.id]);

  async function find(id: string) {
    if (id) {
      setLoading(true);
      setFormTitle('Update clubs');

      await ClubService.find(id)
        .then((response) => {
          if (!response.success) {
            message.error({ content: response.message, duration: 5 });
            return;
          }

          setLoadedObj(response.data);
        })
        .finally(() => {
          setLoading(false);
          FormUtil.fixAutocomplete();
        });
    }
  }

  const onFinish = async (values: any) => {
    const key = 'request';
    message.loading({ content: 'Loading...', key });
    if (loadedObj && loadedObj.id) {
      ClubService.update(loadedObj.id, values).then((response) => {
        if (!response.success) {
          message.error({ content: response.message, key, duration: 5 });
          return;
        }
        message.success({ content: 'Club updated', key, duration: 3 });
        history.push('/admin-panel/clubs');
      });
    } else {
      ClubService.create(values).then((response) => {
        if (!response.success) {
          message.error({ content: response.message, key, duration: 5 });
          return;
        }
        message.success({ content: 'Club created', key, duration: 3 });
        history.push('/admin-panel/clubs');
      });
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const onLogoUpload = (event: UploadFileResponse) => {
    formHook.setValue('logo', event.id);
  };

  const onIconUpload = (event: UploadFileResponse) => {
    formHook.setValue('icon', event.id);
  };

  async function loadDirectors() {
    const filter: UserFilter = { typeList: [UserType.CLUB_DIRECTOR] };
    UserServices.search(new URLSearchParams(), filter).then((response) => {
      if (!response.success) {
        message.error({ content: response.message, duration: 5 });
        return;
      }

      const options = [];
      for (const item of response.data.content) {
        const { id, firstName, lastName, photo } = item;
        options.push({
          key: id,
          label: `${firstName} ${lastName}`,
          photo,
        });
      }
    });
  }

  return (
    <PageContainer title='Club'>
      <GroupBox title={formTitle}>
        <Skeleton loading={loading} active title>
          <FormProvider {...formHook}>
            <form onSubmit={formHook.handleSubmit(onFinish)}>
              <div className='form-group'>
                <label>
                  Name *
                  <input
                    type='text'
                    ref={formHook.register({ required: true })}
                    name='name'
                    defaultValue={loadedObj?.name}
                  />
                </label>
                <ErrorValidationBox>
                  {formHook.errors.name && 'Club Name is required'}
                </ErrorValidationBox>
              </div>
              <div className='form-group'>
                <label>
                  Search for the Club Director
                  <UserInputSearch
                    name='directorIds'
                    filterTypes={[UserType.CLUB_DIRECTOR]}
                    defaultValue={loadedObj?.directorIds}
                    multiple
                  />
                </label>
              </div>

              <Row gutter={16}>
                <Col span={12}>
                  <div className='form-group'>
                    <label>
                      Country *
                      <Controller
                        ref={formHook.register()}
                        rules={{ required: true }}
                        name='country'
                        control={formHook.control}
                        defaultValue={loadedObj?.country}
                        as={
                          <Select
                            placeholder='...'
                            showSearch
                            optionFilterProp='data-label'
                          >
                            {countries.map((item) => (
                              <Select.Option
                                key={item.code}
                                value={item.code}
                                data-label={item.name}
                              >
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                        }
                      />
                    </label>
                    <ErrorValidationBox>
                      {formHook.errors.country && 'Country is required'}
                    </ErrorValidationBox>
                  </div>
                </Col>
                <Col span={12}>
                  <div className='form-group'>
                    <label>
                      State *
                      <Controller
                        ref={formHook.register()}
                        rules={{ required: true }}
                        name='state'
                        defaultValue={loadedObj?.state}
                        as={
                          <Select
                            placeholder='...'
                            showSearch
                            optionFilterProp='data-label'
                          >
                            {states.map((item) => (
                              <Select.Option
                                key={item.code}
                                value={item.code}
                                data-label={item.name}
                              >
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                        }
                      />
                    </label>
                    <ErrorValidationBox>
                      {formHook.errors.state && 'State is required'}
                    </ErrorValidationBox>
                  </div>
                </Col>
              </Row>

              <div className='form-group'>
                <label>
                  City *
                  <input
                    type='text'
                    ref={formHook.register({ required: true })}
                    name='city'
                    defaultValue={loadedObj?.city}
                  />
                </label>
                <ErrorValidationBox>
                  {formHook.errors.city && 'City is required'}
                </ErrorValidationBox>
              </div>

              <Row gutter={32}>
                <Col>
                  <div className='form-group'>
                    <div className='label'>Club's Logo</div>
                    <Controller
                      ref={formHook.register()}
                      name='logo'
                      defaultValue={loadedObj?.logo}
                      as={
                        <SingleUpload
                          value={loadedObj?.logo}
                          prefix='club'
                          onUpload={onLogoUpload}
                        />
                      }
                    />
                  </div>
                </Col>
                <Col>
                  <div className='form-group'>
                    <div className='label'>Club's Icon</div>
                    <Controller
                      ref={formHook.register()}
                      name='icon'
                      defaultValue={loadedObj?.icon}
                      as={
                        <SingleUpload
                          value={loadedObj?.icon}
                          prefix='club'
                          validExtensions={['svg', 'png']}
                          onUpload={onIconUpload}
                        />
                      }
                    />
                  </div>
                </Col>
              </Row>

              <div className='form-group'>
                <Form.Item>
                  <Button type='primary' htmlType='submit'>
                    Save
                  </Button>
                  <Button htmlType='button' onClick={onReset}>
                    Reset
                  </Button>
                </Form.Item>
              </div>
              {/* </Form> */}
            </form>
          </FormProvider>
        </Skeleton>
      </GroupBox>
    </PageContainer>
  );
};

export default ClubCreatePage;
