import React, { FC } from 'react';
import { Dropdown, Menu } from 'antd';
import { BodyM } from 'styles/v3/variables';
import { More } from '@icon-park/react';
import { useInviteByEvent } from 'hooks/v3/event/useInviteByEvent/useInviteByEvent';
import { useParams } from 'react-router-dom';
import { EventInvitesTypes } from 'models/v3/EventInvite/EventInvitesModel';

interface IProps {
  itemId: string;
}

export const ActionMenu: FC<IProps> = ({ itemId }) => {
  const params: { eventId: string } = useParams();
  const { mutateAsync } = useInviteByEvent();

  const handleReSendInvite = async () => {
    await mutateAsync({
      eventId: params.eventId,
      receiversIds: [itemId],
      type: EventInvitesTypes.EVENT_TO_CLUB,
    });
  };

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item onClick={handleReSendInvite}>
            <BodyM $color='grey200'>Re-send invite</BodyM>
          </Menu.Item>
        </Menu>
      }
    >
      <More size={32} />
    </Dropdown>
  );
};
