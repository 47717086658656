import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  margin-top: ${toRem(14)};
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: ${toRem(8)};

  .title {
    color: ${COLORS.grey50};
  }
`;

export const ClubsList = styled.div`
  display: grid;
  grid-template-areas: 1fr;
  row-gap: ${toRem(10)};
`;

export const CardClub = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.grey900};
  padding: ${toRem(8)} 1rem;
  border-radius: ${toRem(12)};

  p {
    color: ${COLORS.grey200};
  }
`;

export const ImgWrapper = styled.div`
  width: ${toRem(40)};
  height: ${toRem(40)};
  overflow: hidden;
  border-radius: 50%;
  background-color: ${COLORS.white};
  text-align: center;
  margin-right: ${toRem(8)};

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`;
