import { AxiosRequestConfig } from 'axios';
import { User } from '../../models/User/UserModel';
import { ConfigApi } from '../config.api';
import APIServices from '../util/ApiServices';
import ServiceResponse from '../util/ServiceResponse';

class DocumentsVerificationService {
  public static async approve(
    id: string,
    docType: 'photo' | 'age' | 'documents',
    isBaseUser?: boolean,
  ): Promise<ServiceResponse<User>> {
    const params = new URLSearchParams();

    if (typeof isBaseUser === 'boolean') {
      params.set('baseUser', isBaseUser.toString());
    }

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/verifications/${docType}/${id}/approval`,
      method: 'GET',
      params,
    };
    return APIServices.request<User>(axiosConfig);
  }

  public static async decline(
    id: string,
    docType: 'photo' | 'age' | 'documents',
    feedback: string,
    isBaseUser?: boolean,
  ): Promise<ServiceResponse<User>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/verifications/${docType}/${id}/decline`,
      method: 'POST',
      data: {
        feedback,
        baseUser: isBaseUser,
      },
    };
    return APIServices.request<User>(axiosConfig);
  }
}

export default DocumentsVerificationService;
