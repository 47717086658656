import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: min-content;

  gap: 14px;

  span {
    border-radius: 12px;
  }
`;
