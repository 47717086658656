import * as React from 'react';

const ReleaseTerms: React.FC = () => {
  return (
    <div className='terms-text'>
      <p>
        <span>
          IMPORTANT! THESE TERMS AND CONDITIONS ARE A BINDING LEGAL AGREEMENT
          (this &ldquo;Agreement&rdquo;). PLEASE READ THESE TERMS AND CONDITIONS
          CAREFULLY BEFORE ACCEPTING THEM.&nbsp;
        </span>
      </p>

      <p>
        <span>
          Along with this Agreement, please read and accept our Privacy Policy{' '}
        </span>
        <span>(click here to Read &amp; Accept)</span>
        <span> before you submit any personal information to us.&nbsp;</span>
      </p>

      <p>
        <span>
          United Futsal&reg; event activities (hereinafter "Activities") are
          available only to individuals who can form legally binding contracts.
          You can accept this Agreement for yourself if you are 18 or older (if
          the age of majority is older than 18 in your jurisdiction), otherwise
          a parent or legal guardian is required to accept this Agreement on
          your behalf. By clicking to accept this Agreement, you represent to
          A2Sports, LLC, which does business as United Futsal Association and
          United Futsal&reg;, that you are 18 or older, or the parent or legal
          guardian of the minor on whose behalf you accept these terms. If more
          than one member of a family will participate in the Activities, each
          member of the family is required to accept this Agreement. Upon
          clicking to accept this Agreement, it becomes a binding legal contract
          between you and A2Sports, LLC.
        </span>
      </p>

      <p>
        <strong>Publicity Release</strong>
      </p>

      <p>
        <span>
          In consideration for, and as a condition to, participate as a player,
          coach, club manager or referee at United Futsal event activities, you
          on behalf of yourself (or your minor child if you are a parent or
          legal guardian), your or your child&rsquo;s personal representatives,
          assigns, heirs, next of kin, estate, executors, successors and
          assigns:
        </span>
      </p>

      <ul>
        <li>
          <span>
            REPRESENT TO US that any photo you provide to us was taken by you or
            by another family member and you have a right to provide it to us
            and will indemnify us and our advertisers and sponsors (hereinafter
            our &amp;ldquo;designees&amp;rdquo;) from any third party claims our
            use of the photo as allowed under the next paragraph below is
            unauthorized by the photographer or owner of the copyright in the
            photo.
          </span>
        </li>
      </ul>

      <ul>
        <li>
          <span>
            AGREE that any photo you supply to us to be able to identify you as
            a player or coach, any avatar you created in connection with
            participating in United Futsal activities, or any image, likeness,
            or video (including your voice) captured by us of you at United
            Futsal activities (collectively, &ldquo;content&rdquo;) may be used
            by us or our designees, along with the name of the person in the
            photo or video in any media now known or later developed, in
            perpetuity, for any of our business and marketing purposes,
            including without limitation, to create derivative works from such
            content. These uses include, but are not limited to illustrations,
            bulletins, exhibitions, videotapes, reprints, reproductions,
            publications, advertisements, and any promotional, marketing, or
            educational materials that may feature such content alone or with
            other materials. You will not own the collective copyright in any
            such works.
          </span>
        </li>
      </ul>

      <ul>
        <li>
          <span>
            ACKNOWLEDGE that you and/or the subject of the photo or video will
            not receive any compensation for the use of the photo or video, and
            do not have a right to review or approve our uses of it, and hereby
            release us and our designees, agents and assigns from any and all
            claims which arise out of or are in any way connected with such use
            as permitted by this release.
          </span>
        </li>
      </ul>

      <p>
        <strong>Liability Release</strong>
      </p>

      <p>
        <span>
          In consideration for, and as a condition to, participate as a player,
          coach, club manager or referee in the Activities, you on behalf of
          yourself (or your minor child if you are a parent or legal guardian),
          your or your child&rsquo;s personal representatives, assigns, heirs,
          next of kin, estate, executors, successors and assigns:
        </span>
      </p>

      <ol>
        <li>
          <span>
            ACKNOWLEDGE, agree, and represent that you (meaning the person
            accepting this Agreement) understand that the term
            &ldquo;Activities&rdquo; as applied in this Agreement is broad in
            its scope and shall extend to any activities which a participant in
            United Futsal engages in or in connection with any organized,
            operated, sanctioned, or sponsored United Futsal match, tournament,
            league, Academy, training program, camp, team tryout, player
            evaluation, or coaching and/or referee clinic, individually or
            collectively, together with any travel, lodging, and other
            non-futsal activities a participant engages in from the time the
            participant leaves his or her home until the participant returns to
            his or her home after participating in any United Futsal event.
          </span>
        </li>

        <li>
          <span>
            ACKNOWLEDGE, agree, and represent that you understand the nature of
            Activities and that you on behalf of yourself (or your minor child
            if you are a parent or legal guardian), are qualified to participate
            in the Activities, are in good health, and in proper physical
            condition to participate in such Activities. You further agree and
            warrant that if at any time you believe conditions around the
            Activities to be unsafe given your health or physical condition, you
            will immediately discontinue further participation in the
            Activities.
          </span>
        </li>

        <li>
          <span>
            FULLY UNDERSTAND THAT: (a) the ACTIVITIES INVOLVE RISKS AND DANGERS
            OF SERIOUS BODILY INJURY, INCLUDING PERMANENT DISABILITY, PARALYSIS,
            AND DEATH (&ldquo;RISKS&rdquo;); (b) these Risks and dangers may be
            caused by your own actions or inactions (as a participant in the
            Activities), the actions or inactions of others participating in the
            Activities, the conditions under which the Activities take place, or
            the negligence of A2Sports, LLC which does business as United Futsal
            Association and United Futsal&reg;, their respective owners,
            administrators, directors, employees, agents, officers, members and
            volunteers, member clubs and the member clubs&rsquo; coaches, other
            participants, any sponsors, advertisers, and, if applicable, owner
            and lessors of premises on which the Activities take place
            (hereinafter the &ldquo;Released Parties&rdquo;); (c) there may be
            OTHER RISK OF SOCIAL AND ECONOMIC LOSSES either not known to you or
            not readily foreseeable at this time; and you FULLY ACCEPT AND
            ASSUME ALL SUCH RISKS AND ALL RESPONSIBILITY FOR LOSSES, COSTS, AND
            DAMAGES you incur as a result of my own participation in the
            Activities.
          </span>
        </li>

        <li>
          <span>
            ACKNOWLEDGE, agree, and represent that you understand the hazards of
            the novel coronavirus (&ldquo;COVID-19&rdquo;) and are familiar with
            the current guidelines of the Centers for Disease Control and
            Prevention (&ldquo;CDC&rdquo;) and those of the state and local
            governments in the locale where you engage in the Activities
            regarding prevention of COVID-19. You acknowledge and understand
            that the circumstances surrounding COVID-19 are changing from day to
            day and that, accordingly, the CDC and applicable government
            guidelines are regularly modified and updated and you accept full
            responsibility for familiarizing yourself with, and abiding by, the
            most recent updates.
          </span>
        </li>

        <li>
          <span>
            ACKNOWLEDGE, agree, and represent that you understand the risks
            associated with participating in the Activities in light of COVID-19
            and the other Risks described above, and you hereby willingly choose
            to participate in the Activities voluntarily and knowingly assuming
            all such Risks as well as the Risks of any other illness or injury.
          </span>
        </li>

        <li>
          <span>
            ACKNOWLEDGE that United Futsal&reg;, in its sole discretion, has
            established and may further establish and/or modify certain
            guidelines and protocols for conduct of persons at its facilities
            and may open, close, or reopen its facilities at any time and from
            time to time as a result of COVID-19. You also acknowledge that your
            conduct is also subject to all federal, state, and local guidelines
            and requirements and agree that you will follow all such guidelines
            and requirements. You understand and agree that United Futsal is
            under no obligation to notify you of the establishment or
            modification of any of its guidelines and protocols or of any
            governmental guidelines or requirements relating to COVID-19. You
            fully understand and appreciate both the known and potential dangers
            of the Activities and that despite the reasonable efforts of the
            Released Parties to mitigate such dangers, your exposure to COVID-19
            could result in quarantine requirements, serious illness,
            disability, and/or death.
          </span>
        </li>

        <li>
          <span>
            HEREBY RELEASE, WAIVE, DISCHARGE, AND COVENANT NOT TO SUE THE
            RELEASED PARTIES FROM ALL LIABILITY, CLAIMS, DEMANDS, LOSSES, OR
            DAMAGES ON MY ACCOUNT CAUSED OR ALLEGED TO BE CAUSED IN WHOLE OR IN
            PART BY THE NEGLIGENCE OF THE RELEASED PARTIES OR OTHERWISE,
            INCLUDING NEGLIGENT RESCUE OPERATIONS AND YOU FURTHER AGREE that if,
            despite this AGREEMENT you, or anyone on your behalf, makes a claim
            against any of the Released Parties, you WILL INDEMNIFY, DEFEND, AND
            HOLD HARMLESS EACH OF THE RELEASED PARTIES from any litigation
            expenses, attorney fees, loss, liability, damage, or cost which may
            incur as the result of such claim.
          </span>
        </li>

        <li>
          <span>
            HEREBY KNOWINGLY AND VOLUNTARILY WAIVE any right to a jury trial of
            any dispute arising in connection with this Agreement. You
            acknowledge that this waiver was freely given by you and is a
            material inducement for the Released Parties to permit you to engage
            in the Activities.
          </span>
        </li>
      </ol>
      <p>
        IN CLICKING TO ACCEPT THIS AGREEMENT, YOU REPRESENT THAT (A) YOU HAVE
        READ THIS AGREEMENT, FULLY UNDERSTAND ITS TERMS, UNDERSTAND THAT YOU
        HAVE GIVEN UP SUBSTANTIAL RIGHTS BY ACCEPTING IT; (B) HAVE ENTERED INTO
        THE AGREEMENT FREELY AND WITHOUT INDUCEMENT OR ASSURANCE OF ANY NATURE
        AND INTEND IT TO BE A COMPLETE AND UNCONDITIONAL RELEASE OF PUBLICITY
        AND A COMPLETE AND UNCONDITIONAL RELEASE ALL LIABILITY TO THE GREATEST
        EXTENT ALLOWED BY LAW; AND (C) AGREE THAT IF ANY PORTION OF THIS
        AGREEMENT IS HELD TO BE INVALID THE BALANCE, NOTWITHSTANDING, SHALL
        CONTINUE IN FULL FORCE AND EFFECT.
      </p>
      <p>
        This AGREEMENT and the provisions contained herein shall be construed,
        interpreted, and controlled according to the laws of the State of North
        Carolina without giving effect to any of its conflicts of laws
        principles.
      </p>
    </div>
  );
};

export default ReleaseTerms;
