import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import ClubService from '../../../services/Club/ClubService';
import { color, font } from '../../../styles/styleVariables';
import ConvertUtil from '../../../util/ConvertUtil';
import ConfirmBox from '../../base/ConfirmBox/ConfirmBox';

interface IJoinRequestRowProps {
  active?: boolean;
  user: any;
}

const JoinRequestRow: React.FunctionComponent<IJoinRequestRowProps> = (
  props
) => {
  const { user, active } = props;
  const [modalStates, setModalStates] = useState({
    acceptOpen: false,
    rejectOpen: false,
  });
  const [hide, setHide] = useState(false);

  const approveUser = () => {
    setModalStates({ ...modalStates, acceptOpen: true });
  };
  const rejectUser = () => {
    setModalStates({ ...modalStates, rejectOpen: true });
  };
  const confirmAcceptance = () => {
    ClubService.acceptRequest(user.id).then((res) => {
      if (res.success) {
        setHide(true);
      }
    });
    setModalStates({ ...modalStates, acceptOpen: false });
  };
  const cancelAcceptance = () => {
    setModalStates({ ...modalStates, acceptOpen: false });
  };

  const confirmRejection = () => {
    ClubService.rejectRequest(user.id).then((res) => {
      if (res.success) {
        setHide(true);
      }
    });
    setModalStates({ ...modalStates, rejectOpen: false });
  };
  const cancelRejection = () => {
    setModalStates({ ...modalStates, rejectOpen: false });
  };
  return (
    <WrapperBox active={active} className={hide ? 'hide' : ''}>
      <div className='photo'>
        <div
          className='content'
          style={{
            backgroundImage: `url(${
              user.user.avatar
                ? ConvertUtil.getMinioUrl(user.user.avatar)
                : ConvertUtil.getMinioUrl(user.user.photo)
            })`,
          }}
        />
      </div>
      <div className='name'>
        <div>{user.user.firstName}</div>
        <div>{user.user.lastName}</div>
      </div>
      <div className='info1'>
        <div>{user.user.gender}</div>
        <div>{user.user.birthDate}</div>
      </div>
      <div className='info2'>
        <div>{user.user.membershipName}</div>
        <div>{user.user.type}</div>
      </div>
      <div className='actions'>
        <div
          className='bt i-icon-check'
          onClick={approveUser}
          onKeyDown={approveUser}
          role='button'
          aria-label='Approve User'
          tabIndex={0}
        />
        <div
          className='bt i-icon-trash'
          onClick={rejectUser}
          onKeyDown={rejectUser}
          role='button'
          aria-label='Reject User'
          tabIndex={0}
        />
      </div>

      <ConfirmBox
        isOpen={modalStates.acceptOpen}
        onClose={() => setModalStates({ ...modalStates, acceptOpen: false })}
        onConfirm={confirmAcceptance}
        onCancel={cancelAcceptance}
        title='Confirm the acceptance of this user?'
        labelConfirm='Yes'
        labelCancel='Cancel'
      />
      <ConfirmBox
        isOpen={modalStates.rejectOpen}
        onClose={() => setModalStates({ ...modalStates, rejectOpen: false })}
        onConfirm={confirmRejection}
        onCancel={cancelRejection}
        title='Confirm the rejection of this user?'
        labelConfirm='Yes'
        labelCancel='Cancel'
      />
    </WrapperBox>
  );
};

const WrapperBox = styled.div<{ active?: boolean }>`
  padding: 15px 20px;
  border-radius: 10px;
  background-color: ${(props) => (props.active ? color.orange : color.silver)};
  display: flex;
  font-family: ${font.druk};
  align-items: center;
  margin: 10px 0;

  &.hide {
    display: none;
  }
  .photo {
    width: 10%;
    .content {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      background-color: #fff;
      background-position: center;
      background-size: 80%;
      background-repeat: no-repeat;
    }
  }
  .name,
  .info1,
  .info2 {
    width: 25%;
  }
  .actions {
    display: flex;
    text-align: center;
    margin-left: auto;
    .bt {
      width: 30px;
      height: 30px;
      background: #fff;
      border-radius: 20px;
      margin: 0 5px;
      cursor: pointer;
      position: relative;
      &::before {
        position: absolute;
        width: 15px;
        top: 6px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
      &:hover {
        background: ${color.silverLight};
      }
    }
  }
`;

export default JoinRequestRow;
