import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  GameAction,
  GameEventModel,
} from '../../../../../../models/GameEvent/GameEventModel';
import { ApplicationState } from '../../../../../../redux/store';
import { color } from '../../../../../../styles/styleVariables';

interface IPenaltyResult {
  noTitle?: boolean;
  small?: boolean;
}

const PenaltyResult: React.FunctionComponent<IPenaltyResult> = (props) => {
  const { noTitle, small } = props;
  const matchDetails = useSelector(
    (state: ApplicationState) => state.matchDetails
  );

  // Return all game events including failed persisted ones
  const getGameEvents = () => {
    return [
      ...(matchDetails.data.match?.matchEvents || []),
      ...(matchDetails.data.matchEventsFailed || []),
    ];
  };

  const getPlayer = (userId: string) => {
    if (matchDetails.data.match) {
      if (
        matchDetails.data.match.homeTeam.players.find((e) => e.id === userId)
      ) {
        return matchDetails.data.match.homeTeam.players.find(
          (e) => e.id === userId
        );
      }
      if (
        matchDetails.data.match.awayTeam.players.find((e) => e.id === userId)
      ) {
        return matchDetails.data.match.awayTeam.players.find(
          (e) => e.id === userId
        );
      }
    }
  };

  const getPkEvents = () => {
    return {
      homeTeam: getGameEvents().filter(
        (event) =>
          event.rosterId === matchDetails.data.match?.homeTeam.id &&
          (event.action === GameAction.PK_GOAL ||
            event.action === GameAction.PK_MISS)
      ),
      awayTeam: getGameEvents().filter(
        (event) =>
          event.rosterId === matchDetails.data.match?.awayTeam.id &&
          (event.action === GameAction.PK_GOAL ||
            event.action === GameAction.PK_MISS)
      ),
    };
  };

  return (
    <>
      {!noTitle && <Title>Penalty Shootout</Title>}
      <PenaltyResultWrapper
        className={matchDetails.preferences.swapped ? 'swapped' : ''}
      >
        <div className='team-a'>
          <div className={`penalties ${small ? 'sm' : ''}`}>
            {/* Concat Array to display at least 5 empty circles */}
            {[
              ...getPkEvents().homeTeam,
              ...new Array(
                5 - getPkEvents().homeTeam.length >= 0
                  ? 5 - getPkEvents().homeTeam.length
                  : 0
              ),
            ].map((event: GameEventModel) => (
              <div
                className={`penalty ${
                  event?.action === GameAction.PK_GOAL
                    ? 'goal'
                    : event?.action === GameAction.PK_MISS
                    ? 'miss'
                    : ''
                }`}
              >
                {getPlayer(event?.userId)?.number}
              </div>
            ))}
          </div>
          <div className='counter'>
            {
              getPkEvents().homeTeam.filter(
                (event) => event.action === GameAction.PK_GOAL
              ).length
            }
          </div>
        </div>
        <div className='divisor'>x</div>
        <div className='team-b'>
          <div className='counter-away'>
            {
              getPkEvents().awayTeam.filter(
                (event) => event.action === GameAction.PK_GOAL
              ).length
            }
          </div>
          <div className={`penalties ${small ? 'sm' : ''}`}>
            {/* Concat Array to display at least 5 empty circles */}
            {[
              ...getPkEvents().awayTeam,
              ...new Array(
                5 - getPkEvents().awayTeam.length >= 0
                  ? 5 - getPkEvents().awayTeam.length
                  : 0
              ),
            ].map((event) => (
              <div
                className={`penalty ${
                  event?.action === GameAction.PK_GOAL
                    ? 'goal'
                    : event?.action === GameAction.PK_MISS
                    ? 'miss'
                    : ''
                }`}
              >
                {getPlayer(event?.userId)?.number}
              </div>
            ))}
          </div>
        </div>
      </PenaltyResultWrapper>
    </>
  );
};
const Title = styled.h3`
  text-align: center;
  text-transform: uppercase;
  color: ${color.orange};
`;
const PenaltyResultWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .team-a,
  .team-b {
    width: 49%;
    padding: 20px;
    display: flex;
    align-items: center;
    .counter {
      font-size: 26px;
      font-weight: 600;
      margin-left: auto;
    }
    .counter-away {
      font-size: 26px;
      font-weight: 600;
      margin-right: auto;
    }
    .penalties {
      display: flex;
      flex-wrap: wrap;

      .penalty {
        width: 25px;
        height: 24px;
        margin: 10px;
        border: ${color.greyText} solid 1px;
        border-radius: 20px;
        padding: 3px;
        text-align: center;
        font-size: 10px;
        font-weight: 600;

        @media (max-width: 700px) {
          width: 20px;
          height: 20px;
          margin: 8px;
          padding: 0;
          font-size: 9px;
        }

        &.goal {
          border: #07d374 solid 3px;
        }
        &.miss {
          border: #d30707 solid 3px;
        }
      }
      &.sm {
        .penalty {
          width: 20px;
          height: 20px;
          font-size: 9px;
          margin: 8px;
          padding: 0;
        }
      }
    }
  }
  &.swapped {
    .team-a {
      order: 3;
    }
    .divisor {
      order: 2;
    }
    .team-b {
      order: 1;
    }
    .counter-away {
      margin-right: inherit;
      margin-left: auto;
      order: 2;
    }
    .counter {
      margin-left: inherit;
      margin-right: auto;
      order: -1;
    }
  }
`;

export default PenaltyResult;
