import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  gap: ${toRem(128)};
`;
