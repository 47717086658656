import React from 'react';
import { useHistory } from 'react-router-dom';
import { HeadsetOne, SoccerOne } from '@icon-park/react';

import useScreenType from 'hooks/useScreenType';

import ConvertUtil from 'util/ConvertUtil';

import { SimplesBreadCrumbWithIcon } from 'components/v3/SimpleBreadCrumbWithIcon';

import { BodyM, COLORS, TitleH4 } from 'styles/v3/variables';

import * as S from './styles';

interface RosterDivisionProps {
  id: string;
  eventLogo: string;
  eventName: string;
  rosterName: string;
  isRosterLocked: boolean;
  totalOfCoaches: number;
  totalOfPlayers: number;
}

export const RosterDivision = ({
  id,
  eventLogo,
  eventName,
  rosterName,
  isRosterLocked,
  totalOfCoaches,
  totalOfPlayers,
}: RosterDivisionProps) => {
  const { isMobile } = useScreenType();
  const history = useHistory();

  const handleEditRoster = async () => {
    history.push(`/v3/roster/${id}/edit`);
  };

  return (
    <S.Container>
      <S.ContainerInformationDivision>
        <S.AvatarEventInformation>
          <S.EventImage>
            <img src={ConvertUtil.getMinioUrl(eventLogo)} alt='Event Logo' />
          </S.EventImage>
          <BodyM>{eventName}</BodyM>
        </S.AvatarEventInformation>
        <TitleH4 style={{ lineHeight: isMobile ? `30px` : 0 }}>
          {rosterName}
        </TitleH4>
        <SimplesBreadCrumbWithIcon
          icon={<HeadsetOne size={20} />}
          textInformation={`${totalOfCoaches} coaches`}
          bgColor={totalOfCoaches === 0 ? `grey800` : `brandSecondary`}
          textColor='white'
        />
        <SimplesBreadCrumbWithIcon
          icon={
            <SoccerOne
              fill={totalOfPlayers === 0 ? COLORS.white : COLORS.grey950}
              size={20}
            />
          }
          textInformation={`${totalOfPlayers} players`}
          bgColor={totalOfPlayers === 0 ? 'grey800' : 'brandPrimary'}
          textColor={totalOfPlayers === 0 ? 'white' : 'grey950'}
        />
      </S.ContainerInformationDivision>
      <S.ActionButton isUpper isBold={false} onClick={handleEditRoster}>
        {isRosterLocked ? 'View Roster' : 'Edit Roster'}
      </S.ActionButton>
    </S.Container>
  );
};
