import { Col, Row } from 'antd';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import TypeUserSelection from '../../components/TypeUserSelection/TypeUserSelection';
import { ApplicationState } from '../../redux/store';
import { NextButtonsWrapper, TopHeader } from './WizardComponents.style';

const WizardChooseType: React.FunctionComponent<RouteComponentProps> = (
  props
) => {
  const { data } = useSelector((state: ApplicationState) => state.newUser);

  return (
    <div>
      <TopHeader>
        <h5>
          COMPLETE YOUR HOUSEHOLD USER CREATION TO JOIN THE UNITED FUTSAL
          COMMUNITY!
        </h5>
        <h1>ADD A USER TO YOUR ACCOUNT</h1>
      </TopHeader>
      <Row>
        <Col
          xxl={18}
          lg={18}
          md={24}
          className='column-wizard'
          style={{ maxWidth: '1440px', width: '100%' }}
        >
          <TypeUserSelection />

          <NextButtonsWrapper data-testid='wizard-nav-wrapper'>
            {data.newUser?.type && (
              <Link to='/wizard/info'>
                <div style={{ margin: '0 10px', fontWeight: 500 }}>
                  NEXT STEP ►
                </div>
              </Link>
            )}
          </NextButtonsWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(WizardChooseType);
