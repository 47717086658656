import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    text-align: start;
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  max-width: ${toRem(384)};
`;

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: ${toRem(18)};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-bottom: ${toRem(16)};
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(8)};
`;
