import React from 'react';

import ConvertUtil from 'util/ConvertUtil';
import { BodyL } from 'styles/v3/variables';
import { ClubIconProps } from './types';
import * as S from './styles';

export const ClubIcon = ({ image, name, selected, onClick }: ClubIconProps) => {
  return (
    <S.Container onClick={onClick} $selected={selected}>
      <S.Image $selected={selected} $image={ConvertUtil.getMinioUrl(image)} />
      <BodyL
        $fontWeight={selected ? '700' : '500'}
        $color={selected ? 'brandPrimary' : 'white'}
      >
        {name}
      </BodyL>
    </S.Container>
  );
};
