import * as React from 'react';
import { memo } from 'react';
import InfoProfileItemWrapper from './InfoProfileItem.style';

interface InfoProfileItemProps {
  title: string;
  primaryText: string;
  mainText: string;
  secondaryText?: string[];
}

const InfoProfileItem: React.FunctionComponent<InfoProfileItemProps> = (
  props
) => {
  const { title, primaryText, mainText, secondaryText } = props;
  return (
    <InfoProfileItemWrapper>
      <div className='title'>
        <div>{title}</div>
      </div>
      <div className='info-container'>
        <div className='info-primary'>{primaryText}</div>
        <div className='info-main'>{mainText}</div>
        <div className='info-secondary'>
          <span>{secondaryText?.[0] ? secondaryText?.[0] : ''}</span>{' '}
          {secondaryText?.[1] ? secondaryText?.[1] : ''}
        </div>
      </div>
    </InfoProfileItemWrapper>
  );
};

export default memo(InfoProfileItem);
