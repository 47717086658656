import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: flex;
  padding: 24px 30px;
  max-height: 10vh;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;

  > h4 {
    margin: 0;
  }
`;

export const CloseButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: ${COLORS.grey800};

  > span {
    height: 24px;
  }
`;
