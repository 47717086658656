import { useQuery } from '@tanstack/react-query';
import UserService from 'services/v3/User/UserService';

export function useGetUserInfo(userId?: string) {
  const { data, isLoading, error } = useQuery(
    ['user-info', userId],
    async () => await UserService.getUserInfo(userId)
  );

  return {
    httpCode: data?.httpCode,
    ...data,
    data: data?.data ?? undefined,
    isLoading,
    error,
  };
}
