import React, { memo, FC, ChangeEvent, useState, useCallback } from 'react';

import { IRosterUser } from 'services/v3/Match/interfaces';

import InputText from 'components/v3/Forms/InputText/InputText';

import { BodyXL } from 'styles/v3/variables';

import * as S from './styles';

interface IJerseyNumberModalProps {
  visible: boolean;
  players?: IRosterUser[];
  defaultNumber?: number;
  onClose: () => void;
  onSubmit: (number: number) => void;
}

export const JerseyNumberModal: FC<IJerseyNumberModalProps> = memo(
  ({ visible, players, defaultNumber = null, onClose, onSubmit }) => {
    const [jerseyNumber, setJerseyNumber] = useState<Maybe<number>>(null);
    const [error, setError] = useState('');

    const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;

      if (value.length) {
        const matchedPlayer = players?.find(
          (player) => player.number === Number(value)
        );

        if (matchedPlayer) {
          setError('Jersey number is already taken');
        } else {
          setError('');
        }
      }

      if (/^\d*$/.test(value)) {
        setJerseyNumber(value.length ? Number(value) : null);
      }
    };

    const handleSubmitForm = useCallback(() => {
      if (jerseyNumber !== null) {
        onSubmit(jerseyNumber);
      }

      setJerseyNumber(null);
      setError('');
    }, [jerseyNumber]);

    const handleClose = useCallback(() => {
      setError('');
      onClose();
    }, [onClose]);

    return (
      <S.StyledModal
        centered
        visible={visible}
        footer={false}
        closable={false}
        onCancel={handleClose}
      >
        <S.Header>
          <BodyXL $color='grey50'>New Jersey Number</BodyXL>
        </S.Header>
        <S.Container>
          <InputText
            id='number'
            value={jerseyNumber ?? ''}
            error={!!error.length}
            errorMessage={error}
            onChange={handleChangeInput}
            placeholder='Type jersey number'
            autoComplete='off'
          />
          <S.ButtonsWrapper>
            <S.CloseOutlinedButton isUpper onClick={handleClose}>
              Cancel
            </S.CloseOutlinedButton>
            <S.ConfirmFilledButton
              isUpper
              onClick={handleSubmitForm}
              $disabled={jerseyNumber === null || !!error.length}
            >
              Save
            </S.ConfirmFilledButton>
          </S.ButtonsWrapper>
        </S.Container>
      </S.StyledModal>
    );
  }
);

JerseyNumberModal.displayName = 'JerseyNumberModal';
