import * as React from 'react';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import iconTShirt from '../../../../../../assets/imgs/icons/icon-t-shirt-.svg';

import { color } from '../../../../../../styles/styleVariables';
import LoadingIcon from '../../../../../../components/base/LoadingIcon/LoadingIcon';
import { MatchDetailsState } from '../../../../../../redux/matchDetails/types';
import { GameEventModel } from '../../../../../../models/GameEvent/GameEventModel';
import ConvertUtil from '../../../../../../util/ConvertUtil';
import {
  matchDetailsRemoveGameEventRequest,
  matchDetailsRemoveLastGameEventFailed,
} from '../../../../../../redux/matchDetails/actions';

interface ILastEventsProps {
  matchDetails: MatchDetailsState;
  gameEvents: GameEventModel[];
}

const LastEvents: React.FunctionComponent<ILastEventsProps> = (props) => {
  const { matchDetails, gameEvents } = props;

  const dispatch = useDispatch();

  const getPlayer = (userId: string) => {
    if (matchDetails.data.match) {
      if (
        matchDetails.data.match.homeTeam.players.find((e) => e.id === userId)
      ) {
        return matchDetails.data.match.homeTeam.players.find(
          (e) => e.id === userId
        );
      }
      if (
        matchDetails.data.match.awayTeam.players.find((e) => e.id === userId)
      ) {
        return matchDetails.data.match.awayTeam.players.find(
          (e) => e.id === userId
        );
      }
    }
  };

  const undoLastEvent = (gameEventId: string) => {
    if (
      matchDetails.data.match?.matchEvents.find(
        (item) => item.id === gameEventId
      )
    ) {
      // If gameEventId is in match list of events, delete from that list
      dispatch(matchDetailsRemoveGameEventRequest(gameEventId));
    } else {
      // else, delete from the failed (offline) list
      dispatch(matchDetailsRemoveLastGameEventFailed());
    }
  };

  return (
    <LastEventBox>
      {matchDetails.loading && <LoadingIcon small />}

      {!matchDetails.loading && (
        <>
          <div style={{ marginBottom: '10px', opacity: 0.5 }}>Last event</div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            <>
              {gameEvents[gameEvents.length - 1].rosterId ===
                matchDetails.data.match?.homeTeam.id && (
                <div className='club-logo'>
                  <img
                    src={ConvertUtil.getMinioUrl(
                      matchDetails.data.match?.homeTeam.logo
                    )}
                  />
                </div>
              )}

              {gameEvents[gameEvents.length - 1].rosterId ===
                matchDetails.data.match?.awayTeam.id && (
                <div className='club-logo'>
                  <img
                    src={ConvertUtil.getMinioUrl(
                      matchDetails.data.match?.awayTeam.logo
                    )}
                  />
                </div>
              )}

              <div className='bold' style={{ marginRight: '10px' }}>
                {gameEvents[gameEvents.length - 1].action}
              </div>
              <div className='shirt-number' style={{ marginRight: '10px' }}>
                {getPlayer(gameEvents[gameEvents.length - 1].userId)?.number ||
                  'C'}
              </div>
              <div>
                {/* {lastEvent[lastEvent.length - 1].user.firstName}{' '}
        {lastEvent[lastEvent.length - 1].user.lastName} */}
              </div>
              <div style={{ marginRight: '10px' }}>
                {gameEvents[gameEvents.length - 1].time.substring(3)}
              </div>

              <div
                className='bt-undo'
                onClick={() =>
                  undoLastEvent(gameEvents[gameEvents.length - 1].id!)
                }
              >
                Undo
              </div>
            </>
          </div>
        </>
      )}
    </LastEventBox>
  );
};

const LastEventBox = styled.div`
  margin: 10px 0;
  background-color: ${color.greyDark};
  border-radius: 10px;
  padding: 10px;
  border: solid 1px rgba(204, 204, 204, 0.2);
  .club-logo {
    margin-right: 10px;
    padding: 3px;
    height: 30px;
    min-width: 30px;
    width: 30px;
    border-radius: 20px;
    overflow: hidden;
    background-color: #fff;
    img {
      width: 100%;
    }
  }
  .shirt-number {
    background-image: url(${iconTShirt});
    background-size: 100%;
    min-width: 32px;
    color: ${color.greyLight};
    height: 35px;
    text-align: center;
    line-height: 37px;
    margin-top: -5px;
    font-weight: 700;
    background-repeat: no-repeat;
  }
  .bt-undo {
    background-color: ${color.silverLight};
    width: 80px;
    min-width: 80px;
    height: 40px;
    border-radius: 5px;
    margin-left: auto;
    padding: 8px 10px;
    line-height: 26px;
    font-weight: 600;
    text-align: right;
    color: ${color.greyDark};
    background-size: 20%;
    background-repeat: no-repeat;
    background-position-y: 12px;
    background-position-x: 10px;
    font-size: 12px;
    background-image: url('https://www.flaticon.com/svg/static/icons/svg/0/340.svg');
    img {
      width: 100%;
    }
  }
`;

export default LastEvents;
