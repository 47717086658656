import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.grey50};
`;

export const SoldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${toRem(8)};
  color: ${COLORS.grey500};
`;
