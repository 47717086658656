import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';
import CheckIconBlack from 'assets/imgs/icons/check-black.svg';
import CheckIconWhite from 'assets/imgs/icons/check-white.svg';

export const Container = styled.div`
  height: auto;
`;

export const Item = styled.div`
  position: relative;
`;

export const CheckboxButtonLabel = styled.label<{
  $theme: 'primary' | 'light';
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  border: 2px solid
    ${(props) =>
      props.$theme === 'primary' ? COLORS.white : COLORS.brandPrimary};
`;

export const CheckboxButton = styled.input<{ $theme: 'primary' | 'light' }>`
  position: relative;
  cursor: pointer;
  opacity: 0;
  display: block;
  z-index: 1;
  width: 25px;
  height: 25px;

  ${(props) => props.disabled && 'cursor: not-allowed;'}

  &:hover ~ ${CheckboxButtonLabel} {
    background: ${(props) =>
      props.$theme === 'primary' ? COLORS.brandPrimary : COLORS.white};
    border-color: ${(props) =>
      props.$theme === 'primary' ? 'transparent' : COLORS.white};

    &::after {
      content: '';
      background: url(${(props) =>
          props.$theme === 'primary' ? CheckIconBlack : CheckIconWhite})
        center center no-repeat;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &:checked + ${Item} {
    background: ${(props) =>
      props.$theme === 'primary' ? COLORS.brandPrimary : COLORS.white};
    border-color: ${(props) =>
      props.$theme === 'primary' ? 'transparent' : COLORS.white};
  }

  &:checked + ${CheckboxButtonLabel} {
    background: ${(props) =>
      props.$theme === 'primary' ? COLORS.brandPrimary : COLORS.white};
    border-color: ${(props) =>
      props.$theme === 'primary' ? 'transparent' : COLORS.white};

    &::after {
      content: '';
      background: url(${(props) =>
          props.$theme === 'primary' ? CheckIconBlack : CheckIconWhite})
        center center no-repeat;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
`;
