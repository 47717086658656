import React, { useMemo } from 'react';
import { Down } from '@icon-park/react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from 'redux/store';
import { UserType } from 'redux/user/types';
import { setSignupClub } from 'redux/v3/signupEvent/actions';

import { useGetMyClubsByUserType } from 'hooks/v3/clubs/useGetMyClubsByUserType/useGetMyClubsByUserType';

import ConvertUtil from 'util/ConvertUtil';

import { useLocation } from 'react-router-dom';
import * as S from './styles';

type UserRole = string | UserType.CLUB_DIRECTOR | UserType.TEAM_MANAGER;

export const ClubSelect = () => {
  const { clubId } = useSelector(
    (state: ApplicationState) => state.signupEvent.data
  );
  const location = useLocation();
  const dispatch = useDispatch();

  const userRole: UserRole = useMemo(() => {
    let role: UserRole = UserType.CLUB_DIRECTOR;
    if (
      location.pathname?.includes('checkout-as-club-director-or-team-manager')
    ) {
      role = `${UserType.CLUB_DIRECTOR},${UserType.TEAM_MANAGER}`;
    }
    if (location.pathname?.includes('checkout-as-team-manager')) {
      role = UserType.TEAM_MANAGER;
    }
    return role;
  }, [location.pathname]);

  const { data: myClubs } = useGetMyClubsByUserType(userRole);

  const handleSelectClub = (id: string) => {
    dispatch(setSignupClub(id));
  };

  return (
    <S.Container>
      <S.Select
        bordered={false}
        suffixIcon={<Down size={24} />}
        key={clubId}
        defaultValue={clubId}
        onChange={(e) => handleSelectClub(e.toString())}
      >
        {myClubs?.map((club) => (
          <S.Option key={club.id} value={club.id}>
            <S.OptionWrapper>
              <S.Image $src={ConvertUtil.getMinioUrl(club.logo)} />
              <S.LabelOption>{club.name}</S.LabelOption>
            </S.OptionWrapper>
          </S.Option>
        ))}
      </S.Select>
    </S.Container>
  );
};
