import { Reducer } from 'redux';
import { MembershipsActionTypes, MembershipsState } from './types';

export const initialState: MembershipsState = {
  data: [],
  loading: false,
};

const reducer: Reducer<MembershipsState> = (state = initialState, action) => {
  switch (action.type) {
    case MembershipsActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case MembershipsActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }
    case MembershipsActionTypes.FETCH_ERROR: {
      return { ...state, loading: false };
    }
    default: {
      return state;
    }
  }
};

export { reducer as membershipsReducer };
