import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.grey500};
`;

export const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${toRem(8)};
`;
