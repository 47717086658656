import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import B2bEventService from 'services/v3/B2bEvent/B2bEventService';
import {
  b2bSetupEventFetchEventError,
  b2bSetupEventFetchEventSuccess,
} from './actions';
import { B2bSetupEventActionTypes } from './types';

function* handleFetch(arg?: any): any {
  const eventId = arg?.payload;

  try {
    const res = yield call(B2bEventService.getEvent, eventId);
    if (!res.success) {
      yield put(b2bSetupEventFetchEventError(res.error));
    } else {
      yield put(b2bSetupEventFetchEventSuccess(res.data));
    }
  } catch (err) {
    yield put(b2bSetupEventFetchEventError('An unknown error occurred.'));
  }
}

function* watchFetchRequest() {
  yield takeEvery(B2bSetupEventActionTypes.FETCH_EVENT_REQUEST, handleFetch);
}

function* b2bSetupEventSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default b2bSetupEventSaga;
