import styled from 'styled-components';

import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 16px 0 16px 0;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${toRem(32)};

  .vault-select__control {
    min-width: 140px;
    cursor: pointer;
  }

  .vault-select__option,
  .vault-select__single-value {
    text-transform: uppercase;
  }
`;
