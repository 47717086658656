import * as React from 'react';
import Modal from 'react-modal';

import RightDrawerHeader from './components/Header';
import RightDrawerBody from './components/Body';

import { RightDrawerProps } from './types';

import * as S from './styles';

const RightDrawer: React.FC<RightDrawerProps> = ({
  headerComponent,
  children,
  isOpen,
  handleCloseModal,
}) => {
  return (
    <S.ModalWrapper>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={handleCloseModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0 ,0, 0.4)',
            display: 'flex',
            flexDirection: 'row-reverse',
          },
          content: {
            background: 'none',
            maxWidth: S.DRAWER_WIDTH,
            width: '100%',
            position: 'static',
            borderRadius: '0',
            borderWidth: 0,
            padding: '0',
            margin: '0',
          },
        }}
      >
        <S.ModalContentWrapper className='right-drawer'>
          <RightDrawerHeader
            headerComponent={headerComponent}
            handleCloseModal={handleCloseModal}
          />
          <RightDrawerBody>{children}</RightDrawerBody>
        </S.ModalContentWrapper>
      </Modal>
    </S.ModalWrapper>
  );
};

export default RightDrawer;
