import React, { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';

import { ApplicationState } from 'redux/store';

import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import { useGetRostersByClubId } from 'hooks/v3/clubs/useGetRostersByClubId/useGetRostersByClubId';

import { Pagination } from 'antd';
import { BodyL } from 'styles/v3/variables';
import { MultiValue, SingleValue } from 'react-select';
import { Filters } from '../Filters/Filters';
import { RosterDivision } from './components';
import * as S from './styles';

interface IProps {
  tab: string;
}

const perPageOptions: OptionsType[] = [
  {
    value: 10,
    label: '10/page',
  },
  {
    value: 20,
    label: '20/page',
  },
  {
    value: 50,
    label: '50/page',
  },
  {
    value: 100,
    label: '100/page',
  },
];

export const RoastersForClub: FC<IProps> = ({ tab }) => {
  const { clubId } = useParams<{ clubId: string }>();
  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const { data: currentClub } = useGetClubById(clubId);

  const { data: rostersClub } = useGetRostersByClubId({
    clubId,
    page,
    size: +perPage.value,
    open: tab === 'Open',
  });

  const [filterRoster, setFilterRoster] = useState('');

  const {
    clubDirector: {
      memberPool: { selectedUserIds },
    },
  } = useSelector((state: ApplicationState) => state.clubDashboard);

  const handleFilter = (text: string) => {
    setFilterRoster(text);
  };

  const rosterClubData = useMemo(() => {
    if (!rostersClub?.content.length || !currentClub) return [];

    return rostersClub.content
      .map((roster) => ({
        id: roster.id as string,
        eventName: roster.event?.name ?? '',
        eventLogo: roster.event?.logo ?? '',
        rosterName: roster.name ?? '',
        totalOfCoaches: roster.coaches.length,
        totalOfPlayers: roster.players.length,
        status: roster.status ?? 'PENDING',
        isLocked: (roster?.locked || roster?.ageDivision?.lockDivision) ?? false,
      }))
      .filter((roster) =>
        !filterRoster
          ? true
          : roster.eventName.toLowerCase().includes(filterRoster.toLowerCase())
      );
  }, [selectedUserIds, filterRoster, currentClub, rostersClub]);

  const handleChangeOption = (
    option: MultiValue<OptionsType> | SingleValue<OptionsType>
  ) => {
    setPage(0)
    setPerPage(option as OptionsType);
  };


  return (
    <>
      <Filters handleChange={handleFilter} />
      <>
        {rosterClubData && rosterClubData.length > 0 ? (
          <>
            {rosterClubData.map((roster) => (
              <RosterDivision
                key={roster.id}
                id={roster.id}
                eventLogo={roster.eventLogo}
                eventName={roster.eventName}
                rosterName={roster.rosterName}
                isRosterLocked={roster.isLocked}
                totalOfCoaches={roster.totalOfCoaches}
                totalOfPlayers={roster.totalOfPlayers}
              />
            ))}
            <S.PaginationWrapper>
              <Pagination
                current={page + 1}
                pageSize={rostersClub?.pageSize ?? 30}
                total={rostersClub?.total ?? 10}
                onChange={(page) => setPage(page - 1)}
              />
              <Select
                options={perPageOptions}
                defaultValue={perPageOptions[0]}
                value={perPage}
                onChange={handleChangeOption}
              />
            </S.PaginationWrapper>
          </>
        ) : (
          <S.WrapperNoItem>
            <BodyL>There are no rosters to view</BodyL>
          </S.WrapperNoItem>
        )}
      </>
    </>
  );
};
