import React, { useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  InputNumber,
  message,
  Select,
  Skeleton,
  Space,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';

import UserType from 'models/User/UserTypeModel';

import BaseEventServices from 'services/BaseEvent/BaseEventServices';
import EventService from 'services/Event/EventService';
import MembershipsServices from 'services/Memberships/MembershipsServices';
import { UploadFileResponse } from 'services/upload/UploadService';
import VenueServices from 'services/Venue/VenueApi';

import UserInputSearch from 'components/base/form/UserInputSearch/UserInputSearch';
import PageContainer from 'components/base/PageContainer/PageContainer';
import EventAgeDivisionForm from 'components/EventAgeDivisionForm/EventAgeDivisionForm';
import EventVenueForm from 'components/EventVenueForm/EventVenueForm';
import GroupBox from 'components/uielements/GroupBox/GroupBox';
import SingleUpload from 'components/Upload/SingleUpload';

import { AgeDivision } from '../../models/AgeDivision';
import { EventView } from '../../models/event/Event';

import './event-create.scss';

const { RangePicker } = DatePicker;

const EventCreatePage: React.FunctionComponent = () => {
  const form = useForm();
  const routeParams: any = useParams();
  const history = useHistory();

  let eventType = form.watch('eventType');
  const multipleTeams = form.watch('eventRules.multipleTeams');
  const [baseEventId, setBaseEventId] = useState<string>('');

  const [baseEventOptions, setBaseEventOptions] = useState<any[]>([]);

  const [membershipOptions, setMembershipOptions] = useState<any[]>([]);
  const [loadingEvent, setLoadingEvent] = useState(false);
  const [formTitle, setFormTitle] = useState('Create');
  const [eventLoaded, setEventLoaded] = useState<EventView>();
  const [date, setDate] = useState([]);

  const [listSelected, setListSelected] = useState([]);
  const [defaultValueVenue, setDefaultValueVenue] = useState([]);
  const [venueOptions, setVenueOptions] = useState<any[]>([]);

  const [defaultValueAgeDivision, setDefaultValueAgeDivision] = useState<any[]>(
    []
  );

  const onLogoUpload = (event: UploadFileResponse) => {
    form.setValue('logo', event.id);
  };

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'venues',
  });

  const appendComponent = () => {
    append({ venues: 'venues' });
  };

  const removeComponent = (index: number, listSelected: any) => {
    setListSelected(listSelected);
    remove(index);
  };

  const {
    fields: fieldsAgeDivision,
    append: appendAgeDivision,
    remove: removeAgeDivision,
  } = useFieldArray({
    control: form.control,
    name: 'ageDivisions',
  });

  const appendComponentAgeDivision = () => {
    appendAgeDivision({ ageDivisions: 'ageDivisions' });
  };

  const removeComponentAgeDivision = (index: number, listSelected: any) => {
    removeAgeDivision(index);
  };

  useEffect(() => {
    loadBaseEvents();
    loadEvent(routeParams.id);
    loadVenues();
  }, []);

  function loadMemberships(eventType: string) {
    if (!eventType) return;

    let userTypes: string[];
    switch (eventType) {
      case 'TOURNAMENT':
        userTypes = ['COACH', 'REFEREE', 'PLAYER'];
        break;

      case 'CAMP':
        userTypes = ['COACH', 'PLAYER'];
        break;

      case 'COACH_COURSE':
        userTypes = ['COACH'];
        break;

      default:
        userTypes = ['REFEREE'];
        break;
    }

    MembershipsServices.getMemberships().then((response) => {
      const key = 'request';
      if (!response.success) {
        message.error({ content: response.message, key, duration: 5 });
        return;
      }
      const options = [];
      for (const membership of response.data.content) {
        if (userTypes.includes(membership.userType)) {
          const { id, name } = membership;
          options.push({
            key: id,
            label: name,
          });
        }
      }
      setMembershipOptions(options);
    });
  }

  function loadBaseEvents() {
    BaseEventServices.list().then((response) => {
      const key = 'request';
      if (!response.success) {
        message.error({ content: response.message, key, duration: 5 });
        return;
      }
      const options = [];
      for (const baseEventView of response.data.content) {
        const { id, name } = baseEventView;
        options.push({
          key: id,
          label: name,
        });
      }
      setBaseEventOptions(options);
    });
  }

  async function loadEvent(eventId: string) {
    form.control.setValue('draft', true);

    if (eventId) {
      setLoadingEvent(true);
      setFormTitle('update');

      await EventService.find(eventId)
        .then(async (response) => {
          if (!response.success) {
            message.error({ content: response.message, duration: 5 });
            return;
          }

          const values: any = response.data;
          values.ageDivisions = response.data.ageDivisions?.map((ageDiv) => {
            return {
              years: ageDiv.years[0],
              gender: ageDiv.gender,
              rule: getRule(ageDiv),
            };
          });

          const date: any = [];
          date[0] = moment(response.data.startDate, 'YYYY-MM-DD');
          date[1] = moment(response.data.endDate, 'YYYY-MM-DD');
          setDate(date);

          loadMemberships(values.eventType);
          eventType = values.eventType;
          setEventLoaded(values);
          setBaseEventId(values.baseEventId);

          if (values.venues) {
            setDefaultValueVenue(
              values.venues.map((item: any) => {
                append({ venues: 'venues' });
                return item;
              })
            );
          }

          if (values.ageDivisions) {
            const sortedAgeDivision = [...values.ageDivisions];

            sortedAgeDivision.sort(
              (a: any, b: any) =>
                a.years - b.years ||
                String(b.gender).localeCompare(String(a.gender)) ||
                String(a.rule).localeCompare(String(b.rule))
            );

            setDefaultValueAgeDivision(
              sortedAgeDivision.map((item: any) => {
                appendAgeDivision({ ageDivisions: 'ageDivisions' });
                return item;
              })
            );
          }

          form.control.setValue('draft', values.draft);
        })
        .finally(() => {
          setLoadingEvent(false);
        });

      eventType = eventLoaded?.eventType;
    }
  }

  function getRule(ageDiv: AgeDivision) {
    if (ageDiv.rule === 'YOUNGER') {
      return 'YOUNGER';
    }
    if (ageDiv.years.length === 1) {
      return 'EQUAL';
    }
    if (ageDiv.years.length === 2) {
      return 'EQUAL+1';
    }
    if (ageDiv.years.length === 3) {
      return 'EQUAL+2';
    }
    return null;
  }

  const onFinish = async (values: any) => {
    const key = 'request';

    if (!values.date) {
      message.warning({ content: 'Invalid date', key });
      return;
    }

    if (!values.venues || values.venues.length <= 0) {
      message.warning({ content: 'Venue is required', key, duration: 5 });
      return;
    }

    if (!values.eventType || values.eventType == '') {
      message.warning({ content: 'Event type is required', key, duration: 5 });
      return;
    }

    let isReferee = false;
    if (
      values.eventType == 'TOURNAMENT' ||
      values.eventType == 'REFEREE_COURSE'
    ) {
      isReferee = true;
    }

    if (!values.userId || values.userId.length <= 0) {
      if (!isReferee) {
        message.warning({ content: 'Coaches is required', key, duration: 5 });
        return;
      }
    }

    const convertedValues = values;
    if (isReferee) {
      convertedValues.referees = values.userId;
    } else {
      convertedValues.coaches = values.userId;
    }

    message.loading({ content: 'Loading...', key });

    convertedValues.startDate = moment(convertedValues.date[0]).format(
      'YYYY-MM-DD'
    );
    convertedValues.endDate = moment(convertedValues.date[1]).format(
      'YYYY-MM-DD'
    );

    const formAgeDivisions = convertedValues.ageDivisions;

    if (formAgeDivisions) {
      const ageDivisions = [] as AgeDivision[];
      formAgeDivisions.forEach((item: any) => {
        const years = [];
        years.push(item.years);
        if (item.rule === 'EQUAL+1') {
          years.push(parseInt(item.years) + 1);
        }
        if (item.rule === 'EQUAL+2') {
          years.push(parseInt(item.years) + 1);
          years.push(parseInt(item.years) + 2);
        }

        ageDivisions.push({
          years,
          gender: item.gender,
          rule:
            item.rule === 'YOUNGER' || item.years === 1900
              ? 'YOUNGER'
              : 'EQUAL',
        });
      });

      convertedValues.ageDivisions = ageDivisions;
    }

    const { venues } = convertedValues;
    convertedValues.venues = [];
    venues.forEach((venue: { subVenues: any[]; venueId: string }) => {
      const subVenues: { name: string; abbreviation: string }[] = [];

      if (venue.subVenues) {
        venue.subVenues.forEach((item) => {
          if (item && item.name && item.abbreviation) {
            subVenues.push({
              name: item.name,
              abbreviation: item.abbreviation,
            });
          }
        });
      }

      convertedValues.venues.push({
        venueId: venue.venueId,
        subVenues,
      });
    });

    if (eventLoaded && eventLoaded.id) {
      EventService.update(eventLoaded.id, convertedValues).then((response) => {
        if (!response.success) {
          message.error({ content: response.message, key, duration: 5 });
          return;
        }

        message.success({ content: 'Event updated', key, duration: 3 });
        history.push('/admin-panel/events');
      });
    } else {
      EventService.create(convertedValues).then((response) => {
        if (!response.success) {
          message.error({ content: response.message, key, duration: 5 });
          return;
        }

        message.success({ content: 'Event created', key, duration: 3 });
        history.push('/admin-panel/events');
      });
    }
  };

  function loadVenues() {
    VenueServices.list().then((response) => {
      const key = 'request';
      if (!response.success) {
        message.error({ content: response.message, key, duration: 5 });
        return;
      }
      const options = [];

      for (const venueView of response.data.content) {
        const { id, name, availableCourt } = venueView;
        options.push({
          id,
          name,
          count: availableCourt,
        });
      }

      setVenueOptions(options);
    });
  }

  const onChangeDraft = (event: any) => {
    form.control.setValue('draft', event.target.checked);
  };

  const onChangeEventType = (event: any) => {
    const type = event.target.value;
    loadMemberships(type);
  };

  return (
    <PageContainer title='Events'>
      <GroupBox title={formTitle}>
        <Skeleton loading={loadingEvent} active title>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onFinish)}>
              <div className='form-group'>
                <label
                  htmlFor='draft'
                  className='control control-checkbox'
                  style={{ display: 'inline-block' }}
                >
                  Draft
                  <input
                    id='draft'
                    type='checkbox'
                    name='draft'
                    defaultChecked={eventLoaded?.draft}
                    onChange={onChangeDraft}
                    ref={form.register()}
                  />
                  <div className='control_indicator' />
                </label>
              </div>
              <div className='form-group'>
                <label>Tournament Director</label>
                <UserInputSearch
                  name='tournamentDirectors'
                  defaultValue={eventLoaded?.tournamentDirectors}
                  multiple
                />
              </div>
              <div className='form-group'>
                <label>Type</label>
                <select
                  name='eventType'
                  key={`eventType_${eventLoaded?.eventType}`}
                  defaultValue={eventLoaded?.eventType}
                  ref={form.register({ required: true })}
                  onChange={onChangeEventType}
                >
                  <option value=''>...</option>
                  <option value='TOURNAMENT'>Tournament</option>
                  <option value='CAMP'>Camp</option>
                  <option value='COACH_COURSE'>Coach education</option>
                  <option value='REFEREE_COURSE'>Referee education</option>
                </select>
              </div>
              <div className='form-group'>
                <label>Name</label>
                <input
                  type='text'
                  name='name'
                  defaultValue={eventLoaded?.name}
                  ref={form.register({ required: true })}
                />
              </div>
              <div className='form-group'>
                <label>Edition</label>
                <input
                  type='text'
                  name='edition'
                  defaultValue={eventLoaded?.edition}
                  ref={form.register({ required: true })}
                />
              </div>
              <div className='form-group'>
                <label>Description</label>
                <input
                  type='text'
                  name='description'
                  ref={form.register}
                  defaultValue={eventLoaded?.description}
                />
              </div>
              <div className='form-group'>
                <label>Link</label>
                <input
                  type='text'
                  name='link'
                  ref={form.register}
                  defaultValue={eventLoaded?.link}
                  placeholder='eg: https://www.unitedfutsal.com/'
                />
              </div>
              <div className='form-group'>
                <label>Date</label>
                <Controller
                  register={form.register({ required: true })}
                  name='date'
                  control={form.control}
                  defaultValue={date}
                  as={
                    <RangePicker
                      style={{ width: '100%' }}
                      renderExtraFooter={() =>
                        'First the start date and then the end date'
                      }
                    />
                  }
                />
              </div>
              <div className='form-group'>
                <label>Price</label>
                <Controller
                  name='price'
                  control={form.control}
                  ref={form.register({ required: true })}
                  defaultValue={eventLoaded?.price}
                  as={
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) =>
                        !value ? '' : value.replace(/\$\s?|(,*)/g, '')
                      }
                    />
                  }
                />
              </div>
              <div className='form-group'>
                <label>Logo</label>
                <Controller
                  name='logo'
                  defaultValue={eventLoaded?.logo}
                  as={
                    <SingleUpload
                      value={eventLoaded?.logo}
                      prefix='tournament'
                      onUpload={onLogoUpload}
                    />
                  }
                />
              </div>
              <div className='form-group'>
                {fields.map((field, index) => (
                  <div key={field.id}>
                    <EventVenueForm
                      defaultValue={defaultValueVenue[index]}
                      indexComponent={index}
                      listSelected={listSelected}
                      setListSelected={setListSelected}
                      removeItem={(listSelected: any) =>
                        removeComponent(index, listSelected)
                      }
                      venueOptions={venueOptions}
                    />
                  </div>
                ))}
              </div>
              <div className='form-group'>
                <Button type='dashed' onClick={appendComponent} block>
                  <PlusOutlined /> Add venue
                </Button>
              </div>
              {eventType === 'TOURNAMENT' ? (
                <>
                  <div className='form-group'>
                    {fieldsAgeDivision.map((field, index) => (
                      <Space
                        key={field.id}
                        style={{ display: 'flex', marginBottom: 8 }}
                        align='start'
                      >
                        <EventAgeDivisionForm
                          defaultValue={defaultValueAgeDivision[index]}
                          indexComponent={index}
                          removeItem={(listSelectedAgeDivision: any) =>
                            removeComponentAgeDivision(
                              index,
                              listSelectedAgeDivision
                            )
                          }
                        />
                      </Space>
                    ))}
                  </div>
                  <div className='form-group'>
                    <Button
                      type='dashed'
                      onClick={appendComponentAgeDivision}
                      block
                    >
                      <PlusOutlined /> Add age division
                    </Button>
                  </div>
                  <div className='form-group'>
                    <FormItem label='Players' style={{ display: 'flex' }}>
                      <Controller
                        name='minimumPlayersNumber'
                        control={form.control}
                        ref={form.register({
                          required: true,
                        })}
                        defaultValue={eventLoaded?.minimumPlayersNumber}
                        as={<InputNumber min={1} style={{ width: '100px' }} />}
                      />
                      <Controller
                        name='maximumPlayersNumber'
                        control={form.control}
                        ref={form.register({
                          required: true,
                        })}
                        defaultValue={eventLoaded?.maximumPlayersNumber}
                        as={<InputNumber min={1} style={{ width: '100px' }} />}
                      />
                    </FormItem>
                  </div>
                  <div className='form-group'>
                    <FormItem label='Coaches'>
                      <Controller
                        name='minimumCoachesNumber'
                        control={form.control}
                        ref={form.register({
                          required: true,
                        })}
                        defaultValue={eventLoaded?.minimumCoachesNumber}
                        as={<InputNumber min={1} style={{ width: '100px' }} />}
                      />
                      <Controller
                        name='maximumCoachesNumber'
                        control={form.control}
                        ref={form.register({
                          required: true,
                        })}
                        defaultValue={eventLoaded?.maximumCoachesNumber}
                        as={<InputNumber min={1} style={{ width: '100px' }} />}
                      />
                    </FormItem>
                  </div>
                </>
              ) : null}
              {eventType === 'TOURNAMENT' || eventType === 'REFEREE_COURSE' ? (
                <>
                  <label>
                    Referees
                    <UserInputSearch
                      name='userId'
                      filterTypes={[UserType.REFEREE]}
                      defaultValue={eventLoaded?.referees}
                      multiple
                    />
                  </label>
                </>
              ) : null}
              {eventType === 'CAMP' || eventType === 'COACH_COURSE' ? (
                <>
                  <label>
                    Coaches
                    <UserInputSearch
                      name='userId'
                      filterTypes={[UserType.COACH]}
                      defaultValue={eventLoaded?.coaches}
                      multiple
                    />
                  </label>
                </>
              ) : null}
              {eventType && eventType != '' ? (
                <>
                  <div className='form-group'>
                    <label>Allowed Memberships</label>
                    <Controller
                      name='membershipIds'
                      ref={form.register({ required: true })}
                      control={form.control}
                      defaultValue={eventLoaded?.membershipIds}
                      as={
                        <Select
                          mode='multiple'
                          allowClear
                          placeholder='...'
                          style={{ width: '100%' }}
                        >
                          {membershipOptions.map((item) => (
                            <Select.Option key={item.key} value={item.key}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                      }
                    />
                  </div>
                  <div className='form-group'>
                    <label>Base Event</label>
                    <select
                      placeholder='...'
                      name='baseEventId'
                      onChange={(e) => setBaseEventId(e.target.value)}
                      value={baseEventId}
                      ref={form.register({ required: true })}
                    >
                      {baseEventOptions.map((item) => (
                        <option key={item.key} value={item.key}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              ) : null}
              {eventType === 'TOURNAMENT' && (
                <>
                  <div className='form-group'>
                    <label htmlFor='multipleTeams'>
                      Do you allow players to play for multiple Teams?
                    </label>
                    <Controller
                      id='multipleTeams'
                      name='eventRules.multipleTeams'
                      control={form.control}
                      ref={form.register({
                        required: true,
                      })}
                      defaultValue={
                        eventLoaded?.eventRules?.multipleTeams !== undefined
                          ? String(eventLoaded?.eventRules?.multipleTeams)
                          : '...'
                      }
                      as={
                        <Select placeholder='...'>
                          <Select.Option key='false' value='false'>
                            No
                          </Select.Option>
                          <Select.Option key='true' value='true'>
                            Yes
                          </Select.Option>
                        </Select>
                      }
                    />
                  </div>
                  {(eventLoaded?.eventRules?.multipleTeams ||
                    multipleTeams === 'true') && (
                    <>
                      <div className='form-group'>
                        <label htmlFor='multipleClubs'>
                          Do you allow players to play in different Clubs?
                        </label>
                        <Controller
                          id='multipleClubs'
                          name='eventRules.multipleClubs'
                          control={form.control}
                          ref={form.register({
                            required: true,
                          })}
                          defaultValue={
                            eventLoaded?.eventRules?.multipleClubs !== undefined
                              ? String(eventLoaded?.eventRules?.multipleClubs)
                              : '...'
                          }
                          as={
                            <Select placeholder='...'>
                              <Select.Option key='false' value='false'>
                                No
                              </Select.Option>
                              <Select.Option key='true' value='true'>
                                Yes
                              </Select.Option>
                            </Select>
                          }
                        />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='multipleAgeDivisions'>
                          What about Age Divisions?
                        </label>
                        <Controller
                          id='multipleAgeDivisions'
                          name='eventRules.multipleAgeDivisions'
                          control={form.control}
                          ref={form.register({
                            required: true,
                          })}
                          defaultValue={
                            eventLoaded?.eventRules?.multipleAgeDivisions ??
                            '...'
                          }
                          as={
                            <Select placeholder='...'>
                              <Select.Option value='YES'>
                                Yes, players can play both in the same or
                                different Age Divisions
                              </Select.Option>
                              <Select.Option value='YES_DIFFERENT_AGE_DIVISION'>
                                Players can play in different teams, as long as
                                they are in different Age Divisions.
                              </Select.Option>
                              <Select.Option value='YES_SAME_AGE_DIVISION'>
                                Players can play in different teams, as long as
                                they are in same Age Divisions.
                              </Select.Option>
                            </Select>
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              <div className='form-group'>
                <Button type='primary' htmlType='submit'>
                  Save
                </Button>
              </div>
            </form>
          </FormProvider>
        </Skeleton>
      </GroupBox>
    </PageContainer>
  );
};

export default EventCreatePage;
