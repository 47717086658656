import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Calendar, LocalTwo } from '@icon-park/react';
import {
  useHistory,
  useParams,
  withRouter,
  useLocation,
} from 'react-router-dom';

import OrderService from 'services/v3/Order/OrderService';

import { useGetOrderById } from 'hooks/v3/orders/useGetOrderById/useGetOrderById';
import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';

import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import Loading from 'components/v3/Loading/Loading';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';

import ConvertUtil from 'util/ConvertUtil';

import {
  BodyL,
  BodyM,
  BodyMBold,
  BodyS,
  TitleH1,
  TitleH3,
  TitleH4,
} from 'styles/v3/variables';

import { PaymentStatus } from 'services/v3/Order/types';
import { notification } from 'components/v3/Notification/notification';
import { CheckoutBox } from './components/CheckoutBox/CheckoutBox';

import * as S from './styles';
import { PaymentStatusMainTypes } from './types';
import { imageSrc, paymentText, paymentTitles, status } from './config';

const ClubDirectorPayment: FC = () => {
  const history = useHistory();
  const { search } = useLocation();

  const params: { eventId: string, orderId: string } = useParams();
  const intervalId = useRef<number>(0);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const searchParams = new URLSearchParams(search);

  const orderId = searchParams.get('order') || '';

  const { data: currentEvent } = useGetEventById(params.eventId);

  const orderObject = useGetOrderById(orderId);
  const { refetch } = orderObject;
  const order = orderObject?.data;

  if (orderObject && orderObject.httpCode === 404) {
    window.location.href = `/v3/event/${params.eventId}/checkout-as-club-director`;
  }

  const paymentStatus = useMemo(() => {
    if (!order) return PaymentStatus.WAITING_PAYMENT;

    return order.paymentStatus;
  }, [order]);

  const handleCardButton = () => {
    if (isLoadingPayment) {
      return;
    }

    if (paymentStatus === 'PAID') {
      history.push(
        `/v3/clubs/${order?.item.b2bEventRosterFee?.club.id}/dashboard`
      );
    } else {
      handleContinueCheckout();
    }
  };

  const handleContinueCheckout = async () => {
    if (isLoadingPayment || !order || !order.id) {
      return;
    }

    setIsLoadingPayment(true);

    OrderService.getContinueCheckoutStripeUrl(order.id)
      .then((res) => {
        if (res.httpCode === 200) {
          setIsLoadingPayment(false);

          window.location.href = res.data.paymentUrl;
        }
      })
      .finally(() => {
        setIsLoadingPayment(false);
      });
  };

  const eventImageSrc = useMemo(() => {
    const defaultImg = '/assets/imgs/icons/event-trophy.svg';

    if (!currentEvent?.logo) {
      return defaultImg;
    }

    if (currentEvent.logo) {
      return ConvertUtil.getMinioUrl(currentEvent.logo);
    }

    return defaultImg;
  }, [currentEvent]);

  const formatDate = (date: Date | string) => {
    if (!date) return 'not set';

    return new Date(date).toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const generalInfoData = useMemo(() => {
    if (!currentEvent) return [];

    const venue = currentEvent.venues[0];
    const venueText = venue?.address?.line1
      ? `${venue?.address?.line1}, ${venue?.address?.city}, ${venue?.address?.state}`
      : '';

    return [
      {
        title: 'Starts',
        value: formatDate(currentEvent.startDate),
      },
      {
        title: 'Location',
        value: venueText,
      },
    ];
  }, [currentEvent]);

  const handlePrevious = () => {
    history.push(`/v3/event/${params.eventId}/checkout-as-club-director`);
  };

  const handleRefetchOrder = async () => {
    try {
      await refetch();
    } catch(e) {
      notification.error({ message: 'Fetch order failed' })
    }
  }

  useEffect(() => {
    if (paymentStatus !== PaymentStatus.WAITING_PAYMENT) {
      clearTimeout(intervalId.current);
    } else {
      intervalId.current = setInterval(handleRefetchOrder, 1500);
    }
    return () => {
      clearInterval(intervalId.current);
    }
  }, [paymentStatus]);

  if (!currentEvent || !order || isLoadingPayment) return <Loading />;

  return (
    <S.PaymentWrapper>
      {order && (
        <S.PaymentContainer>
          {paymentStatus !== PaymentStatus.PAID && (
            <TextButtonWithIcon
              color='primary'
              align='flex-end'
              reverse
              icon='back'
              onClick={handlePrevious}
            >
              Go back
            </TextButtonWithIcon>
          )}
          <S.CardContainer
            status={status[paymentStatus as PaymentStatusMainTypes]}
          >
            <S.Image
              src={imageSrc[paymentStatus as PaymentStatusMainTypes]}
              alt='bank card'
            />
            <S.Container>
              <TitleH3>
                {paymentTitles[paymentStatus as PaymentStatusMainTypes]}
              </TitleH3>
              <BodyM>
                {paymentText[paymentStatus as PaymentStatusMainTypes]}
              </BodyM>
              {paymentStatus !== PaymentStatus.WAITING_PAYMENT && (
                <FilledButtonWithIcon
                  color='dark-white'
                  onClick={handleCardButton}
                >
                  {paymentStatus === 'PAID'
                    ? 'Go to CLUB'
                    : 'Continue to payment'}
                </FilledButtonWithIcon>
              )}
            </S.Container>
          </S.CardContainer>
          <S.PaymentSummary>
            <TitleH1>ORDER SUMMARY</TitleH1>
          </S.PaymentSummary>
          <S.PaymentEvent>
            <S.EventLogoContainer>
              <S.Image src={eventImageSrc} alt='bank card' />
            </S.EventLogoContainer>
            <S.Container>
              <TitleH4>{currentEvent?.name || ''}</TitleH4>
              <BodyM>{currentEvent?.description || ''}</BodyM>
            </S.Container>
          </S.PaymentEvent>
          <S.InfoContainer>
            <BodyL>GENERAL INFO</BodyL>
            {generalInfoData.map((info) => (
              <S.Container key={info.title}>
                <S.Container className='title'>
                  {info.title === 'Starts' ? (
                    <Calendar size='20px' />
                  ) : (
                    <LocalTwo size='20px' />
                  )}
                </S.Container>
                <S.Container className='value'>
                  <BodyMBold>{info.value}</BodyMBold>
                </S.Container>
              </S.Container>
            ))}
          </S.InfoContainer>
          {currentEvent && <CheckoutBox order={order} />}
          <S.InfoContainer className='centered'>
            <BodyS $color='grey200'>
              Your receipt will be sent to your email.
            </BodyS>
          </S.InfoContainer>
        </S.PaymentContainer>
      )}
    </S.PaymentWrapper>
  );
};

export default withRouter(ClubDirectorPayment);
