import React, { FC, useCallback } from 'react';

import { User } from 'models/User/UserModel';
import { BaseUser } from 'redux/v3/baseUsers/types';
import { useGetJoinedEvents } from 'hooks/v3/event/useGetJoinedEvents/useGetJoinedEvents';

import MyEventCard from 'components/v3/Cards/MyEventCard/MyEventCard';
import Loading from 'components/v3/Loading/Loading';

import { DeepPartial } from 'util/types';

import { BodyM } from 'styles/v3/variables';

import * as S from './styles';

interface IMyEventsProps {
  currentUser?: DeepPartial<BaseUser>;
  users: User[];
}

export const MyEvents: FC<IMyEventsProps> = ({ currentUser, users }) => {
  const { data: events, isLoading } = useGetJoinedEvents();

  const getRelatedUserToEvent = useCallback(
    (refereesIds: string[]) => {
      const user = users.find((user) => {
        return user.baseUserId === currentUser?.id;
      });

      const matchedBaseUser = refereesIds.some(
        (refereeId) => refereeId === currentUser?.id
      );

      if (matchedBaseUser || user) {
        return currentUser?.type || user?.type || null;
      }

      return null;
    },
    [currentUser]
  );

  return (
    <S.EventsList>
      {isLoading && <Loading />}
      {!isLoading &&
        events?.data?.content?.map((event) => (
          <MyEventCard
            size='small'
            key={event.id}
            id={event.id}
            logo={event.logo}
            title={event.name}
            orientation='column'
            actionText='See event'
            eventStatus={event.draft ? 'draft' : 'published'}
            description={event.description}
            startDate={event.startDate}
            finalDate={event.endDate}
            link={`/v3/event/${event.id}`}
            currentUserType={getRelatedUserToEvent(event.referees ?? [])}
          />
        ))}
      {!isLoading && !events?.data?.content.length && (
        <BodyM>Join the Event on the Discover Event tab</BodyM>
      )}
    </S.EventsList>
  );
};
