import { PaymentStatus } from 'services/v3/Order/types';
import { PaymentStatusesColor, PaymentStatusMainTypes } from './types';

export const imageSrc = {
  [PaymentStatus.PAID]: '/assets/imgs/icons/bank-card-success.svg',
  [PaymentStatus.WAITING_PAYMENT]: '/assets/imgs/icons/loader.svg',
  [PaymentStatus.CANCELLED]: '/assets/imgs/icons/bank-card-error.svg',
};

export const paymentTitles = {
  [PaymentStatus.PAID]: 'Payment was successful',
  [PaymentStatus.WAITING_PAYMENT]: 'Payment in progress',
  [PaymentStatus.CANCELLED]: 'Something went wrong',
};

export const paymentText = {
  [PaymentStatus.PAID]:
    'You will be redirected soon. If you were not redirected, just click the button below.',
  [PaymentStatus.WAITING_PAYMENT]:
    'Please wait while your payment is being processed. If this order is taking longer than expected, please check with the United Futsal team before placing another order."',
  [PaymentStatus.CANCELLED]:
    'Please make sure your payment information is correct and try again',
};

export const status: Record<PaymentStatusMainTypes, PaymentStatusesColor> = {
  [PaymentStatus.PAID]: 'sucess',
  [PaymentStatus.WAITING_PAYMENT]: 'pending',
  [PaymentStatus.CANCELLED]: 'error',
};
