import * as React from 'react';
import CountUp from 'react-countup';
import { CardUser, TopInfo, ProfileInfo } from './CardInfoProfile.style';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

interface CardInfoProfileProps {
  name?: string;
  mainName?: string;
  nickname?: string;
  avatar?: string;
  customAvatar?: string;
  icon?: string;
  preferedNumber?: string;
  place?: number;
  speciality?: string;
  level?: number;
  bgColor?: string;
  bgGradient?: string;
  thin?: boolean;
  loading?: boolean;
}
const CardInfoProfile: React.FunctionComponent<CardInfoProfileProps> = (
  props
) => {
  const {
    name,
    mainName,
    nickname,
    avatar,
    customAvatar,
    icon,
    preferedNumber,
    speciality,
    // place,
    level,
    bgColor,
    bgGradient,
    thin,
    loading,
  } = props;
  return (
    <CardUser
      bgColor={bgColor}
      bgGradient={bgGradient}
      className={thin ? 'thin' : ''}
    >
      <TopInfo>
        {icon && (
          <div className='team-logo'>
            <img alt='' src={icon} />
          </div>
        )}
        <div style={{ marginLeft: '15px' }}>
          {!nickname && (
            <>
              <div className='box-first-name'>{name}</div>
              <div className='box-last-name'>{mainName}</div>
            </>
          )}
          {nickname && (
            <>
              <div className='box-last-name'>{nickname}</div>
            </>
          )}
        </div>
      </TopInfo>

      <ProfileInfo>
        {(avatar || customAvatar) && (
          <div className='profile-image-wrapper'>
            <div
              className='profile-image-img'
              style={{ backgroundImage: `url(${customAvatar || avatar})` }}
            />
          </div>
        )}

        {preferedNumber && (
          <div className='number-preference'>{preferedNumber}</div>
        )}

        <div className='level-box'>
          {/* {place && <div className='place'>{placeCardinal(place)} place</div>} */}
          {/* <div className='place'>Position</div> */}
          <div className='place'>{speciality}</div>

          <div className='level'>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                LEVEL <CountUp duration={1} end={level || 0} />
              </>
            )}
          </div>
        </div>
      </ProfileInfo>
    </CardUser>
  );
};

export default CardInfoProfile;
