export const xpInfo = [
  {
    key: '1',
    level: '100',
    xp: '160000',
  },
  {
    key: '1',
    level: '90',
    xp: '129600',
  },
  {
    key: '1',
    level: '80',
    xp: '102400',
  },
  {
    key: '1',
    level: '70',
    xp: '78400',
  },
  {
    key: '1',
    level: '60',
    xp: '57600',
  },
  {
    key: '1',
    level: '50',
    xp: '40000',
  },
  {
    key: '1',
    level: '40',
    xp: '25600',
  },
  {
    key: '1',
    level: '30',
    xp: '14400',
  },
  {
    key: '1',
    level: '20',
    xp: '6400',
  },
  {
    key: '1',
    level: '10',
    xp: '1600',
  },
  {
    key: '1',
    level: '9',
    xp: '1296',
  },
  {
    key: '1',
    level: '8',
    xp: '1024',
  },
  {
    key: '1',
    level: '7',
    xp: '784',
  },
  {
    key: '1',
    level: '6',
    xp: '576',
  },
  {
    key: '1',
    level: '5',
    xp: '400',
  },
  {
    key: '1',
    level: '4',
    xp: '256',
  },
  {
    key: '1',
    level: '3',
    xp: '144',
  },
  {
    key: '1',
    level: '2',
    xp: '64',
  },
  {
    key: '1',
    level: '1',
    xp: '16',
  },
  {
    key: '1',
    level: '0',
    xp: '0',
  },
];
