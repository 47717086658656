import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS, ColorsInterface } from 'styles/v3/variables';

export const Container = styled.div<{ $color?: keyof ColorsInterface }>`
  display: flex;
  align-items: flex-start;

  span {
    display: flex;
    color: ${(props) => (props.$color ? COLORS[props.$color] : 'white')};
  }

  p {
    margin: 0 ${toRem(8)};
  }
`;
