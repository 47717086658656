import React, { FunctionComponent, useMemo } from 'react';
import { Lock, PreviewOpen, Unlock } from '@icon-park/react';
import { Switch } from 'antd';

import { RosterStatus } from 'services/v3/Rosters/enums';

import IconButton from 'components/v3/Buttons/IconButton';
import { BreadCrumbRosterInformation } from 'components/v3/Breadcrumb/BreadCrumbRosterInformation';

import ConvertUtil from 'util/ConvertUtil';

import { COLORS, TitleH4 } from 'styles/v3/variables';

import { CircleInformation } from '../../../../../Home/components/CirclesNested';

import * as S from './styles';

interface RosterInformationProps {
  logoClub: string;
  nameClub: string;
  isDivisionLocked?: boolean;
  isRosterLocked?: boolean;
  coachesCircles: CircleInformation[];
  playersCircles: CircleInformation[];
  status: RosterStatus;
  handleUpgradeRosterStatus: () => void;
  handleSelectedRoster: () => void;
}

export const RosterInformation: FunctionComponent<RosterInformationProps> = ({
  logoClub,
  nameClub,
  isDivisionLocked,
  isRosterLocked,
  coachesCircles,
  playersCircles,
  status,
  handleUpgradeRosterStatus,
  handleSelectedRoster,
}) => {
  const isLocked = useMemo(() => {
    return isRosterLocked || isDivisionLocked;
  }, [isRosterLocked, isDivisionLocked]);

  const themeStatus = useMemo(() => {
    if (status === RosterStatus.Submitted) {
      return {
        label: 'Submitted',
        class: 'submitted-status',
      };
    }

    if (status === RosterStatus.Edited) {
      return {
        label: 'Edited',
        class: 'edited-status',
      };
    }

    return {
      label: 'Not started',
      class: 'pending-status',
    };
  }, [status]);

  return (
    <S.Container>
      <S.RightContainer>
        <Switch
          checked={isLocked}
          disabled={isDivisionLocked}
          onChange={handleUpgradeRosterStatus}
        />
        {isLocked ? (
          <Lock fill={COLORS.brandPrimary} size={24} />
        ) : (
          <Unlock fill={COLORS.grey400} size={24} />
        )}
        <S.TitleIconContainer>
          <S.ClubIcon>
            <img src={ConvertUtil.getMinioUrl(logoClub)} alt='Club Logo' />
          </S.ClubIcon>
          <TitleH4 $color='grey50'>{nameClub}</TitleH4>
          <S.RosterStatus className={themeStatus.class}>
            {themeStatus.label}
          </S.RosterStatus>
        </S.TitleIconContainer>
      </S.RightContainer>
      <S.LeftContainer>
        <S.CoachesAndPlayersConainer>
          <BreadCrumbRosterInformation
            icon='HeadsetOne'
            text='Coaches'
            circlesUser={coachesCircles}
            status={isLocked ? 'locked' : 'open'}
            fillIconColor={COLORS.white}
            bgColor={isLocked ? COLORS.brandSecondary : COLORS.grey800}
            colorText={isLocked ? 'white' : 'grey300'}
          />
          <BreadCrumbRosterInformation
            icon='SoccerOne'
            text='Players'
            circlesUser={playersCircles}
            status={isLocked ? 'locked' : 'open'}
            fillIconColor={isLocked ? COLORS.grey950 : COLORS.white}
            bgColor={isLocked ? COLORS.brandPrimary : COLORS.grey800}
            colorText={isLocked ? 'grey950' : 'grey300'}
          />
        </S.CoachesAndPlayersConainer>
        <IconButton
          icon={<PreviewOpen size={24} fill={COLORS.grey50} />}
          style={{ backgroundColor: COLORS.grey800 }}
          onClick={handleSelectedRoster}
        />
      </S.LeftContainer>
    </S.Container>
  );
};
