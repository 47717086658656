import { useMutation } from '@tanstack/react-query';

import EventService from 'services/v3/Event/EventService';

import { notification } from 'components/v3/Notification/notification';

interface IFilteredPaidDivisionsPayload {
  eventId: string;
  params?: {
    gender: string,
    years: string,
    search: string,
  };
}

export const useGetFilteredPaidDivisions = () => {
  return useMutation({
    mutationFn: async ({ eventId, params }: IFilteredPaidDivisionsPayload) => {
      const searchParams = new URLSearchParams();

      if (params?.gender?.length) {
        searchParams.set('gender', params.gender);
      }

      if (params?.years.length) {
        searchParams.set('years', params.years);
      }

      if (params?.search) {
        searchParams.set('name', params.search);
      }

      return await EventService.getFilteredPaidDivisions(eventId, searchParams);
    },
    onError: (error: any) => {
      notification.error({
        message: 'Failed to filter your divisions!',
        description: error?.message || 'Unexpected error.',
      });
    },
  });
};
