import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

import { DRAWER_WIDTH } from '../../styles';

export const Wrapper = styled.div`
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: ${DRAWER_WIDTH}) {
    padding: 0;
    padding: 0 ${toRem(10)};
  }
`;
