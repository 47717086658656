import { Col, message, Row } from 'antd';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import PhotoUpload from '../../components/PhotoUpload/PhotoUpload';
import Steps from '../../components/Steps/Steps';
import { ApplicationState } from '../../redux/store';
import { UserType } from '../../redux/user/types';
import {
  BoxInfo,
  NextButtonsWrapper,
  TopHeader,
} from './WizardComponents.style';

const WizardPhoto: React.FunctionComponent = (props) => {
  const { newUser } = useSelector(
    (state: ApplicationState) => state.newUser
  ).data;
  const history = useHistory();

  function nextScreen() {
    if (newUser?.photo === '' || newUser?.photo === undefined) {
      message.warning({
        content: 'You need to upload your image',
        duration: 4,
      });
    } else {
      if (newUser?.type === UserType.PLAYER) {
        history.push('/wizard/document');
      }
      if (
        newUser?.type === UserType.COACH ||
        newUser?.type === UserType.CLUB_DIRECTOR ||
        newUser?.type === UserType.REFEREE
      ) {
        history.push('/wizard/memberships');
      }
    }
  }
  return (
    <div>
      <TopHeader>
        <h5>
          COMPLETE YOUR HOUSEHOLD USER CREATION TO JOIN THE UNITED FUTSAL
          COMMUNITY!
        </h5>
        <h1>UPLOAD PHOTO OF THIS USER</h1>
      </TopHeader>
      <Row>
        <Col xxl={18} lg={18} md={24} className='column-wizard'>
          <Steps activeStep={2} />
          <PhotoUpload prefix='user' />
          <BoxInfo>
            <h5>WHY DO WE NEED THIS?</h5>
            <p>
              We require this photo of the participating player so referees can
              verify the player&apos;s identity before the match. Please make
              sure the photo shows clearly the participant player&apos;s face.
              It will also make the process easier when our staff tracks the
              participating player&apos;s stats in order to earn badges and
              ranks.
            </p>
            <span>
              Please note this is not the participating player&apos;s public
              avatar.
            </span>
          </BoxInfo>
          <NextButtonsWrapper data-testid='wizard-nav-wrapper'>
            <Link to='/wizard/info2'>
              <div style={{ margin: '0 10px' }}> ◄ PREVIOUS STEP</div>
            </Link>

            <div
              className={`link ${!newUser?.photo ? 'disabled' : ''}`}
              onClick={() => nextScreen()}
              onKeyDown={() => nextScreen()}
              role='button'
              tabIndex={0}
              aria-label='Next Step'
            >
              NEXT STEP ►
            </div>
          </NextButtonsWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(WizardPhoto);
