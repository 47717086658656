import styled from 'styled-components';

import { B2bEventStatus } from 'models/v3/Event/Event';

import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';
import { BREAKPOINTS } from 'styles/v3/constants';

import backgroundEvents from 'assets/imgs/my-events-background.jpeg';

export const EventCardWrapper = styled.div<{
  isHappening: boolean;
  eventStatus: B2bEventStatus;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${toRem(30)} ${toRem(30)} ${toRem(25)} ${toRem(30)};
  border: 1px solid
    ${({ isHappening }) => (isHappening ? COLORS.brandPrimary : COLORS.grey800)};
  border-radius: ${toRem(24)};
  background: linear-gradient(86deg, #252622 40.19%, rgba(37, 38, 34, 0.91)),
    url(${backgroundEvents}) lightgray no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${COLORS.grey400};

  cursor: ${({ eventStatus }) =>
    eventStatus === 'published' ? 'pointer' : 'default'};

  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;

export const EventContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-direction: column;
    gap: ${toRem(16)};
  }
`;

export const EventIcon = styled.div`
  width: ${toRem(69)};
  height: ${toRem(69)};
  border-radius: ${toRem(69)};
  margin: 0 auto;
  overflow: hidden;
  background-color: ${COLORS.brandPrimary};
  margin-right: ${toRem(24)};

  img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;

    width: ${toRem(69)};
    height: ${toRem(69)};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    margin: 0;
  }
`;

export const EventContent = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
`;

export const EventDates = styled.div`
  display: flex;
  padding-bottom: ${toRem(8)};
`;

export const IsTodayEvent = styled.p`
  font-family: 'Boxed Regular';
  font-size: ${toRem(13)};
  text-transform: uppercase;
  color: ${COLORS.brandPrimary};
  margin-right: ${toRem(16)};
  margin-bottom: 0;
`;

export const EventDateOccurence = styled.p`
  font-family: 'Boxed Regular';
  font-size: ${toRem(13)};
  text-transform: uppercase;
  color: ${COLORS.grey200};
  margin-bottom: 0;
`;

export const EventStatus = styled.p<{
  eventStatus: B2bEventStatus;
}>`
  width: fit-content;
  padding: ${toRem(6)} ${toRem(12)};
  font-size: ${toRem(10)};
  text-transform: uppercase;
  cursor: default;
  color: ${({ eventStatus }) => {
    let color;
    if (eventStatus === 'draft') color = COLORS.white;
    else if (eventStatus === 'published') color = COLORS.grey1000;
    else if (eventStatus === 'waiting approval') color = COLORS.white;
    else color = COLORS.brandPrimary;
    return color;
  }};

  font-weight: ${({ eventStatus }) =>
    eventStatus === 'published' ? 700 : 400};

  background-color: ${({ eventStatus }) =>
    ['draft', 'waiting approval'].includes(eventStatus)
      ? 'transparent'
      : COLORS.brandPrimary};

  border: 1px solid
    ${({ eventStatus }) =>
      ['draft', 'waiting approval'].includes(eventStatus)
        ? COLORS.white
        : COLORS.brandPrimary};
  border-radius: ${toRem(24)};
`;

export const ApplicationsResumeBtn = styled.div`
  padding-top: ${toRem(16)};
  margin: 0;

  color: ${COLORS.grey50};

  font-size: ${toRem(16)};
  text-transform: uppercase;
  text-align: center;

  display: flex;
  justify-content: center;

  cursor: pointer;

  span {
    padding-left: ${toRem(8)};
  }
`;

export const EventBtnActions = styled.div`
  margin-left: ${toRem(24)};
  width: ${toRem(185)};

  display: flex;
  flex-direction: column;
  justify-content: end;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    margin-left: 0;
    gap: ${toRem(16)};

    button {
      width: 100%;
    }
  }
`;

export const PlanCardImageWrapper = styled.div`
  border-radius: 50%;
  width: 130px;
  height: 130px;
  margin: 0 auto;
  overflow: hidden;
  background-color: ${COLORS.brandPrimary};

  img {
    object-fit: cover;
    max-width: 100%;
  }
`;

export const EventDivisionsContainer = styled.div`
  width: 100%;
  padding: ${toRem(23)};

  background-color: ${COLORS.grey900};

  border-radius: ${toRem(14)};
  overflow: hidden;
  transition: all 0.5s ease-out;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${toRem(33)};
`;

export const EventDivisionsWrapper = styled.div`
  padding-top: ${toRem(48)};
`