import styled from 'styled-components';

import { BodyXS, COLORS } from 'styles/v3/variables';

export const Tag = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  gap: 4px;

  border-radius: 24px;
  padding: 2px 12px 2px 12px;
  background-color: ${COLORS.grey800};

  span {
    margin-top: 4px;
  }

  ${BodyXS} {
    text-transform: uppercase;
  }
`;
