import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';

import RosterService from 'services/v3/Rosters/RosterService';

type UseGetRostersByClubIdProps = {
  clubId: string,
  size?: number,
  page?: number,
  open?: boolean,
};

type UseGetRostersByClubIdAndUserProps = {
  clubId: string,
  size?: number,
  page?: number,
};

export const useGetRostersByClubId = ({
  clubId,
  page,
  size,
  open,
}: UseGetRostersByClubIdProps) => {
  const response = useQuery(
    ['roster-by-club-id', clubId, page, open, size],
    () => RosterService.getRostersByClubId(clubId, { size, page, open })
  );

  return {
    ...response,
    data: response.data?.data,
  };
};

export const useGetRostersByClubIdAndUser = ({
  clubId,
  page,
  size,
}: UseGetRostersByClubIdAndUserProps) => {
  const { id: accountId } = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );

  const response = useQuery(
    ['roster-by-club-id-and-user', clubId, page, accountId, size],
    () => RosterService.getRostersByClubIdAndUser(clubId, { size, page })
  );

  return {
    ...response,
    data: response.data?.data,
  };
};
