import { UserType } from '../user/types';

export interface Guardian {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  suffix?: string;
  phoneNumber?: string;
  email?: string;
  address?: Address;
}

export interface Address {
  city: string;
  country: string;
  line1: string;
  line2: string;
  state: string;
  zipCode: string;
}

export interface ExtraInfo {
  sexCrime?: boolean | string;
  felony?: boolean | string;
  socialSecurityNumber?: string;
  certificate?: string;
}

export interface NewUser {
  type?: UserType;

  firstName?: string;
  middleName?: string;
  lastName?: string;
  suffix?: string;
  birthDate?: string;
  gender?: string;

  address?: Address;
  guardian1?: Guardian;
  guardian2?: Guardian;

  phoneNumber?: string;
  email?: string;
  guardians?: Guardian[];
  membershipId?: string;
  membershipPrice: number;
  photo?: string;
  ageProof?: string;
  profileVisibility?: string;
  signedWaivers?: boolean;
  extraInfo?: ExtraInfo;
}

export enum NewUserActionTypes {
  FETCH_REQUEST = '@@new_user/FETCH_REQUEST',
  FETCH_SUCCESS = '@@new_user/FETCH_SUCCESS',
  FETCH_ERROR = '@@new_user/FETCH_ERROR',
  UPDATE = '@@new_user/UPDATE',
  UPDATE_TYPE = '@@new_user/UPDATE_TYPE',
  UPDATE_BASIC_DATA = '@@new_user/UPDATE_BASIC_DATA',
  UPDATE_BASIC_DATA2 = '@@new_user/UPDATE_BASIC_DATA2',
  UPDATE_MEMBERSHIP = '@@new_user/UPDATE_MEMBERSHIP',
  UPDATE_PHOTO = '@@new_user/UPDATE_PHOTO',
  UPDATE_PROOF_AGE = '@@new_user/UPDATE_PROOF_AGE',
  UPDATE_EXTRA_INFO = '@@new_user/UPDATE_EXTRA_INFO',
  RESET = '@@new_user/RESET',
}

export interface NewUserState {
  readonly loading: boolean;
  readonly data: {
    newUser: NewUser | undefined;
  };
}
