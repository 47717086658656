import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';
import RosterService from 'services/v3/Rosters/RosterService';

const useChangeRosterLockState = (rosterId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (locked: boolean) =>
      RosterService.changeLockedRosterState(rosterId, locked),
    onSuccess: async () => {
      queryClient.invalidateQueries(['/get-roster-detailed-id']);
      notification.success({
        message: 'Roster locked state changed successfully!',
      });
    },
    onError: (error: any) => {
      notification.error({
        message: 'Failed to update Roster Locked State!',
        description: error.message,
      });
    },
  });
};

export default useChangeRosterLockState;
