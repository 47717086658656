import { action } from 'typesafe-actions';
import { Player } from '../../models/Match/MatchModel';
import { MemberpoolRosterActionTypes } from './types';

export const memberpoolRosterFetchRequest = (rosterId: string) =>
  action(MemberpoolRosterActionTypes.FETCH_REQUEST, rosterId);

export const memberpoolRosterFetchSuccess = (data: {
  players: Player[];
  coaches: Player[];
}) => action(MemberpoolRosterActionTypes.FETCH_SUCCESS, data);

export const memberpoolRosterFetchError = (message: string) =>
  action(MemberpoolRosterActionTypes.FETCH_ERROR, message);
