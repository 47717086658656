import * as React from 'react';

const AdminPanel: React.FunctionComponent = () => {
  return (
    <div>
      {/* <PageContainer title='Initial Page'> */}
      {/*  <GroupBox title='Group 01'> */}
      {/*    <p> */}
      {/*      Lorem ipsum dolor sit amet consectetur adipisicing elit. */}
      {/*      Perspiciatis tempore consequuntur voluptatum, accusamus nam, numquam */}
      {/*      sunt magnam error ex, pariatur iste asperiores nisi nihil distinctio */}
      {/*      repellendus laboriosam dolore hic fuga. */}
      {/*    </p> */}
      {/*  </GroupBox> */}

      {/*  <GroupBox title='Group 02'> */}
      {/*    <div className='form-group'> */}
      {/*      <Select> */}
      {/*        <Option value='sss'>dsadsa</Option> */}
      {/*        <Option value='sss'>dsadsa</Option> */}
      {/*      </Select> */}
      {/*    </div> */}
      {/*    <div className='form-group'> */}
      {/*      <DatePicker picker='week' /> */}
      {/*    </div> */}

      {/*    <div className='form-group'> */}
      {/*      <div>Input Field</div> */}
      {/*      <input type='text' /> */}
      {/*    </div> */}
      {/*    <div className='form-group'> */}
      {/*      <div>Input Field</div> */}
      {/*      <input type='text' /> */}
      {/*    </div> */}
      {/*    <div className='form-group'> */}
      {/*      <div className='btn block'>Send</div> */}
      {/*    </div> */}
      {/*  </GroupBox> */}

      {/*  <GroupBox title='Group 03'>/!* <Table title='Table 01' /> *!/</GroupBox> */}
      {/* </PageContainer> */}
    </div>
  );
};

export default AdminPanel;
