import * as React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { color } from '../../../styles/styleVariables';
import ico_print from '../../../assets/imgs/icons/ico_printing.svg';
import MatchService from '../../../services/Match/MatchService';

import ConvertUtil from '../../../util/ConvertUtil';

const MatchSheet: React.FunctionComponent = () => {
  const printSheet = () => {
    window.print();
  };

  const { matchId } = useParams<{ matchId: string }>();
  const [details, setDetails] = useState<any>();
  useEffect(() => {
    if (matchId) {
      MatchService.getDetails(matchId).then((res) => {
        if (res.success) {
          setDetails(res.data);
        }
      });
    }
  }, [matchId]);
  return (
    <Wrapper>
      <div
        className='printButton'
        onClick={printSheet}
        onKeyDown={printSheet}
        role='button'
        aria-label='Print Game Sheet'
        tabIndex={0}
      >
        <img src={ico_print} alt='' style={{ width: '20px' }} />
        <div style={{ marginLeft: '20px' }}>PRINT GAME SHEET</div>
      </div>
      <MainContainer>
        <Row>
          <div />
          <div>
            <h3>Team A</h3>
          </div>
          <div>
            <h3>FINAL SCORE</h3>
          </div>
          <div>
            <h3>Team B</h3>
          </div>
          <div />

          <div className='img'>
            <img src={ConvertUtil.getMinioUrl(details?.homeTeam.logo)} alt='' />
          </div>
          <div>
            <Box>
              <div className='text-md bold'>{details?.homeTeam.clubName}</div>
              <div style={{ fontSize: '12px' }}>{details?.homeTeam.name}</div>
            </Box>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            <Border style={{ width: '100px', height: '65px' }} />
            <Border style={{ width: '100px', height: '65px' }} />
          </div>
          <div>
            <Box>
              <div className='text-md bold'>{details?.awayTeam.clubName}</div>
              <div style={{ fontSize: '12px' }}>{details?.awayTeam.name}</div>
            </Box>
          </div>
          <div>
            <div className='img'>
              <img
                src={ConvertUtil.getMinioUrl(details?.awayTeam.logo)}
                alt=''
              />
            </div>
          </div>
        </Row>

        <Row>
          <div>
            <div className='text-sm'>FOULS</div>
            <div>1st HALF</div>
          </div>

          <div className='wrapper-foul'>
            <div>
              <div className='number'>1</div>
            </div>
            <div>
              <div className='number'>2</div>
            </div>
            <div>
              <div className='number'>3</div>
            </div>
            <div>
              <div className='number'>4</div>
            </div>
            <div>
              <div className='number'>5</div>
            </div>
            <div>
              <div className='number'>6</div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              padding: '0 10px',
              justifyContent: 'center',
            }}
          >
            <Box style={{ height: '50px', width: '30%' }} />
            <div style={{ height: '50px', padding: '5px', width: '40%' }}>
              <div className='text-xs' style={{ fontSize: '12px' }}>
                TIME OUT
              </div>
              <div>1st HALF</div>
            </div>
            <Box style={{ height: '50px', width: '30%' }} />
          </div>

          <div className='wrapper-foul'>
            <div>
              <div className='number'>1</div>
            </div>
            <div>
              <div className='number'>2</div>
            </div>
            <div>
              <div className='number'>3</div>
            </div>
            <div>
              <div className='number'>4</div>
            </div>
            <div>
              <div className='number'>5</div>
            </div>
            <div>
              <div className='number'>6</div>
            </div>
          </div>

          <div>
            <div className='text-sm'>FOULS</div>
            <div>1st HALF</div>
          </div>
        </Row>

        <Row>
          <div>
            <div className='text-sm'>FOULS</div>
            <div>2nd HALF</div>
          </div>

          <div className='wrapper-foul'>
            <div>
              <div className='number'>1</div>
            </div>
            <div>
              <div className='number'>2</div>
            </div>
            <div>
              <div className='number'>3</div>
            </div>
            <div>
              <div className='number'>4</div>
            </div>
            <div>
              <div className='number'>5</div>
            </div>
            <div>
              <div className='number'>6</div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              padding: '0 10px',
              justifyContent: 'center',
            }}
          >
            <Box style={{ height: '50px', width: '30%' }} />
            <div style={{ height: '50px', padding: '5px', width: '40%' }}>
              <div className='text-xs' style={{ fontSize: '12px' }}>
                TIME OUT
              </div>
              <div>2nd HALF</div>
            </div>
            <Box style={{ height: '50px', width: '30%' }} />
          </div>

          <div className='wrapper-foul'>
            <div>
              <div className='number'>1</div>
            </div>
            <div>
              <div className='number'>2</div>
            </div>
            <div>
              <div className='number'>3</div>
            </div>
            <div>
              <div className='number'>4</div>
            </div>
            <div>
              <div className='number'>5</div>
            </div>
            <div>
              <div className='number'>6</div>
            </div>
          </div>

          <div>
            <div className='text-sm'>FOULS</div>
            <div>2nd HALF</div>
          </div>
        </Row>

        <Main>
          <div style={{ padding: '10px' }}>
            <div className='listPlayers'>
              <div
                className='row'
                style={{ textAlign: 'center', fontWeight: 800 }}
              >
                <div>#</div>
                <div>NAME</div>
                <div>Y</div>
                <div>R</div>
              </div>

              {[...Array(15)].map((x, i) => (
                <div className='row' key={i}>
                  <div>{`# ${
                    details?.homeTeam.players.sort(
                      (a: any, b: any) => a.number - b.number
                    )[i]?.number || ''
                  }`}</div>
                  <div>{`${
                    details?.homeTeam.players.sort(
                      (a: any, b: any) => a.number - b.number
                    )[i]?.firstName || ''
                  } ${
                    details?.homeTeam.players.sort(
                      (a: any, b: any) => a.number - b.number
                    )[i]?.lastName || ''
                  }`}</div>
                  <div />
                  <div />
                </div>
              ))}
            </div>
          </div>
          <div style={{ padding: '10px' }}>
            <div style={{ fontWeight: 800, textAlign: 'center' }}>
              TEAM A GOALS
            </div>
            <div className='goalsTable'>
              {[...Array(27)].map((x, i) => (
                <div key={i}>
                  <div className='number'>{i + 1}</div>
                </div>
              ))}
            </div>
            <div className='coachList'>
              <div>{`${details?.homeTeam.coaches[0]?.firstName || ''} ${
                details?.homeTeam.coaches[0]?.lastName || ''
              }`}</div>
              <div>{`${details?.homeTeam.coaches[1]?.firstName || ''} ${
                details?.homeTeam.coaches[1]?.lastName || ''
              }`}</div>
              <div>{`${details?.homeTeam.coaches[2]?.firstName || ''} ${
                details?.homeTeam.coaches[2]?.lastName || ''
              }`}</div>
            </div>
            <div className='coachList'>
              <div style={{ height: '45px' }} />
            </div>
            <div
              style={{ textAlign: 'center', fontWeight: 800, fontSize: '9px' }}
            >
              TEAM A HEAD COACH SIGNATURE
            </div>
          </div>
          <div style={{ padding: '10px' }}>
            <div style={{ fontWeight: 800, textAlign: 'center' }}>
              TEAM B GOALS
            </div>
            <div className='goalsTable'>
              {[...Array(27)].map((x, i) => (
                <div key={i}>
                  <div className='number'>{i + 1}</div>
                </div>
              ))}
            </div>
            <div className='coachList'>
              <div>{`${details?.awayTeam.coaches[0]?.firstName || ''} ${
                details?.awayTeam.coaches[0]?.lastName || ''
              }`}</div>
              <div>{`${details?.awayTeam.coaches[1]?.firstName || ''} ${
                details?.awayTeam.coaches[1]?.lastName || ''
              }`}</div>
              <div>{`${details?.awayTeam.coaches[2]?.firstName || ''} ${
                details?.awayTeam.coaches[2]?.lastName || ''
              }`}</div>
            </div>
            <div className='coachList'>
              <div style={{ height: '45px' }} />
            </div>
            <div
              style={{ textAlign: 'center', fontWeight: 800, fontSize: '9px' }}
            >
              TEAM B HEAD COACH SIGNATURE
            </div>
          </div>
          <div style={{ padding: '10px' }}>
            <div className='listPlayers'>
              <div
                className='row'
                style={{ textAlign: 'center', fontWeight: 800 }}
              >
                <div>#</div>
                <div>NAME</div>
                <div>Y</div>
                <div>R</div>
              </div>

              {[...Array(15)].map((x, i) => (
                <div className='row' key={i}>
                  <div>{`# ${
                    details?.awayTeam.players.sort(
                      (a: any, b: any) => a.number - b.number
                    )[i]?.number || ''
                  }`}</div>
                  <div>{`${
                    details?.awayTeam.players.sort(
                      (a: any, b: any) => a.number - b.number
                    )[i]?.firstName || ''
                  } ${
                    details?.awayTeam.players.sort(
                      (a: any, b: any) => a.number - b.number
                    )[i]?.lastName || ''
                  }`}</div>
                  <div />
                  <div />
                </div>
              ))}
            </div>
          </div>
        </Main>
      </MainContainer>
      <SideContent>
        <div className='content'>
          <div>
            <div className='title'>SIGNATURE</div>
            <div />
          </div>
          <div>
            <div className='title'>TIME</div>
            <div className='info'>{moment(details?.date).format('HH:mm')}</div>
          </div>
          <div>
            <div className='title'>SCOREKEEPER</div>
          </div>
          <div>
            <div className='title'>DATE</div>
            <div className='info'>
              {moment(details?.date).format('YYYY-DD-MM')}
            </div>
          </div>
          <div>
            <div className='title'>SIGNATURE</div>
          </div>
          <div>
            <div className='title'>SUBVENUE</div>
            <div className='info'>{details?.subVenue?.name}</div>
          </div>
          <div>
            <div className='title'>REFEREE 2</div>
          </div>
          <div>
            <div className='title'>VENUE</div>
            <div className='info'>{details?.venue.name}</div>
          </div>
          <div>
            <div className='title'>SIGNATURE</div>
          </div>
          <div>
            <div className='title'>AGE DIVISION</div>
            <div className='info'>{`${details?.ageDivision?.gender} 
                ${
                  details?.ageDivision?.years[0] === 1900
                    ? 'ADULT'
                    : details?.ageDivision?.years.sort().join(', ')
                }`}</div>
          </div>
          <div>
            <div className='title'>REFEREE 1</div>
            <div className='info' />
          </div>
          <div>
            <div className='title'>TOURNAMENT</div>
            <div className='info'>{details?.event.name}</div>
          </div>
        </div>
      </SideContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  min-width: 1200px;
  //opacity: 0.8;
  @media print {
    @page {
      size: landscape;
      margin: 10px;
    }
    background: none !important;
  }

  h3 {
    @media print {
      color: ${color.greyDark};
    }
  }

  .printButton {
    @media print {
      display: none;
    }
    cursor: pointer;
    position: absolute;
    background-color: ${color.orange};
    font-weight: 800;
    padding: 10px;
    color: ${color.greyDark};
    width: 50px;
    display: flex;
    width: 50px;
    height: 50px;
    overflow: hidden;
    align-items: center;
    &:hover {
      width: auto;
      div {
        display: block;
      }
    }
    div {
      display: none;
    }
  }
`;

const SideContent = styled.div`
  @media print {
    border-color: #000 !important;
    color: #000 !important;
  }
  width: 15%;
  height: 100vh;
  padding: 20px;
  font-size: 12px;
  .content {
    border-bottom: 2px solid ${color.greyLight};
    border-left: 2px solid ${color.greyLight};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 16vh;

    @media print {
      border-color: #000;
    }
    & > div {
      padding: 5px;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      border-bottom: 2px solid ${color.greyLight};
      border-left: 2px solid ${color.greyLight};
      @media print {
        border-color: #000;
      }
      .title {
        margin-left: 5px;
      }
      .info {
        font-weight: 800;
      }
    }
  }
`;

const MainContainer = styled.div`
  width: 85%;
`;

const Row = styled.div`
  @media print {
    border-color: #000 !important;
    color: #000 !important;
  }
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
  text-align: center;
  margin-bottom: 10px;
  align-items: center;
  .img {
    img {
      width: 50px;
    }
  }
  .text-sm {
    font-size: 18px;
    font-weight: 800;
  }
  .text-xs {
    font-size: 14px;
    font-weight: 800;
  }
  .wrapper-foul {
    display: inline-grid;
    width: 100%;
    margin: 0 auto;
    height: 50px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    border-bottom: 2px solid ${color.greyLight};
    border-left: 2px solid ${color.greyLight};
    @media print {
      border-color: #000;
    }

    & > div {
      border-top: 2px solid ${color.greyLight};
      border-right: 2px solid ${color.greyLight};
      position: relative;
      @media print {
        border-color: #000;
      }
      .number {
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 2px;
        background-color: ${color.greyLight};
        color: #fff;
        height: 20px;
        width: 20px;
        font-weight: 800;
        font-size: 9px;
        @media print {
          top: 0px;
          left: 0px;
          padding: 0px;
          height: 15px;
          width: 15px;
          position: relative;
          background-color: #000;
          -webkit-print-color-adjust: exact;
          color: #fff;
        }
      }
    }
  }
`;

const Main = styled.div`
  @media print {
    border-color: #000 !important;
    color: #000 !important;
  }

  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2fr;
  .listPlayers {
    border-left: 2px solid ${color.greyLight};
    border-bottom: 2px solid ${color.greyLight};
    @media print {
      border-color: #000;
    }
    .row {
      display: grid;
      grid-template-columns: 1fr 5fr 1fr 1fr;
      div {
        padding: 5px;
        min-height: 20px;
        border-top: 2px solid ${color.greyLight};
        border-right: 2px solid ${color.greyLight};
        @media print {
          border-color: #000;
        }
      }
    }
  }
  .goalsTable {
    border-left: 2px solid ${color.greyLight};
    border-bottom: 2px solid ${color.greyLight};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media print {
      border-color: #000;
    }
    & > div {
      padding: 2px;
      min-height: 45px;
      border-top: 2px solid ${color.greyLight};
      border-right: 2px solid ${color.greyLight};
      position: relative;
      @media print {
        border-color: #000;
      }
      .number {
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 2px;
        background-color: ${color.greyLight};
        color: #fff;
        height: 20px;
        width: 20px;
        font-weight: 800;
        font-size: 9px;
        @media print {
          top: 0px;
          left: 0px;
          padding: 0px;
          height: 15px;
          width: 15px;
          position: relative;
          background-color: #000;
          -webkit-print-color-adjust: exact;
          color: #fff;
        }
      }
    }
  }
  .coachList {
    margin-top: 10px;
    border-left: 2px solid ${color.greyLight};
    border-bottom: 2px solid ${color.greyLight};
    @media print {
      border-color: #000;
    }
    & > div {
      min-height: 30px;
      padding: 5px;
      border-right: 2px solid ${color.greyLight};
      border-top: 2px solid ${color.greyLight};
      @media print {
        border-color: #000;
      }
    }
  }
`;

const Border = styled.div`
  border: 2px solid ${color.greyLight};
  @media print {
    border-color: #000;
  }
`;

const Box = styled.div`
  border: 2px solid ${color.greyLight};
  height: 100%;
  padding: 10px;
  @media print {
    border-color: #000;
  }
`;

export default MatchSheet;
