import { AxiosRequestConfig } from 'axios';

import { CoachRosterItem, ParticipationStatus, PlayerRosterItem, Roster } from 'models/Roster/RosterModel';

import { NewAgeDivision } from 'admin/models/AgeDivision';
import { EventData } from 'admin/models/event/Event';

import { ConfigApi } from 'services/config.api';
import APIServicesV3 from 'services/util/ApiServicesV3';
import PageResponse from 'services/util/PageResponse';
import ServiceResponseV3 from 'services/util/ServiceResponseV3';

import { IMemberAttendancePayload, IPlayerNumberPayload, IRosterFetchByClubIdParams, IRosterNameUpdatePayload, IRosterPaginationParams } from './types';
import { RosterStatus } from './enums';

interface PlayersDTO {
  approvedPhoto: string,
  birthDate: string,
  firstName: string,
  id: string,
  lastName: string,
  membershipId: string,
  photo: string,
  ageVerified: string,
  documentationVerified: string,
  photoVerified: string,
  membershipType: 'COMMUNITY' | 'OFFICIAL' | 'CCS',
  number?: number
}

interface EventsDTO {
  id: string;
  logo: string;
  maximumCoachesNumber: number;
  maximumManagersNumber: number;
  maximumPlayersNumber: number;
  minimumCoachesNumber: number;
  minimumManagersNumber: number;
  minimumPlayersNumber: number;
  name: string
}

export interface RosterByEventId {
  id: string;
  name: string;
  status: RosterStatus;
  locked?: boolean;
  ageDivision: NewAgeDivision,
  club: {
    id: string;
    logo: string;
    name: string;
    directorIds: string[];
  },
  event: EventsDTO;
  coaches: PlayersDTO[];
  directors: PlayersDTO[];
  players: PlayersDTO[];
}

export interface NewRoaster {
  id?: string;
  rosterId?: string;
  locked?: boolean;
  ageDivision?: NewAgeDivision;
  eventParticipationStatus?: ParticipationStatus;
  creationDate: Date;
  submittedDate: Date;
  updateDate: Date;
  coaches: CoachRosterItem[];
  players: PlayerRosterItem[];
  status?: RosterStatus;
  club?: {
    id: string,
    logo: string,
    name: string,
  };
  eventId?: string;
  name?: string;
  event?: {
    id: string,
    logo: string,
    name: string,
    type: string,
    maximumCoachesNumber: number,
    maximumManagersNumber: number,
    maximumPlayersNumber: number,
    minimumCoachesNumber: number,
    minimumManagersNumber: number,
    minimumPlayersNumber: number,
    startDate: Date;
    endDate: Date;
    data: EventData,
  };
}

class RosterService {
  public static async findDetailed(
    id: string
  ): Promise<ServiceResponseV3<NewRoaster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/rosters/${id}/detailed`,
      method: 'GET',
    };

    return APIServicesV3.request<NewRoaster>(axiosConfig);
  }

  public static async findDetailedByEventId(
    eventId?: string
  ): Promise<ServiceResponseV3<NewRoaster[]>> {
    if (!eventId) return ServiceResponseV3.success([], '');
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/rosters/event/${eventId}/detailed`,
      method: 'GET',
    };

    return APIServicesV3.request<NewRoaster[]>(axiosConfig);
  }

  public static async getRostersByClubId(
    id?: string,
    params?: IRosterFetchByClubIdParams
  ): Promise<ServiceResponseV3<PageResponse<NewRoaster>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/rosters/${id}/club`,
      params,
      method: 'GET',
    };

    return APIServicesV3.request<PageResponse<NewRoaster>>(axiosConfig);
  }

  public static async getRosterByEventId(
    eventId?: string
  ): Promise<ServiceResponseV3<RosterByEventId[]>> {
    if (!eventId) return ServiceResponseV3.success([], '');

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/rosters/event-rosters/${eventId}`,
      method: 'GET',
    };

    return APIServicesV3.request<RosterByEventId[]>(axiosConfig);
  }

  public static async getRostersByClubIdAndUser(
    id?: string,
    params?: IRosterPaginationParams,
  ): Promise<ServiceResponseV3<PageResponse<NewRoaster>>> {

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/rosters/${id}/club/user`,
      method: 'GET',
      params
    };

    return APIServicesV3.request<PageResponse<NewRoaster>>(axiosConfig);
  }

  public static async addPlayer(
    rosterId: string,
    playerId: string
  ): Promise<ServiceResponseV3<Roster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/rosters/${rosterId}/user/${playerId}/v3`,
      method: 'POST',
    };

    return APIServicesV3.request<any>(axiosConfig);
  }

  public static async removePlayer(
    rosterId: string,
    playerId: string
  ): Promise<ServiceResponseV3<Roster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/rosters/${rosterId}/user/${playerId}/v3`,
      method: 'DELETE',
    };

    return APIServicesV3.request<any>(axiosConfig);
  }

  public static async changeSubmitStatus(
    rosterId: string
  ): Promise<ServiceResponseV3<Roster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/rosters/${rosterId}/change-status`,
      method: 'PUT',
    };

    return APIServicesV3.request<Roster>(axiosConfig);
  }

  public static async changeLockedRosterState(
    rosterId: string,
    locked: boolean
  ) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/rosters/${rosterId}/locked`,
      method: 'PUT',
      params: {
        locked,
      },
    };

    return APIServicesV3.request(axiosConfig);
  }

  public static async changeRosterSetStatus(
    rosterId: string,
    rosterStatus: boolean
  ): Promise<ServiceResponseV3<Roster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/rosters/${rosterId}/set-status/${rosterStatus}`,
      method: 'PUT',
    };

    return APIServicesV3.request(axiosConfig);
  }

  public static async findAvailableRostersToTeamPlacementByEventId(
    eventId: string
  ): Promise<ServiceResponseV3<RosterByEventId[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/rosters/available-for-team/${eventId}`,
      method: 'GET',
    };

    return APIServicesV3.request<RosterByEventId[]>(axiosConfig);
  }

  public static async updatePlayerNumber(
    dto: IPlayerNumberPayload
  ): Promise<ServiceResponseV3<Roster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/rosters/${dto.rosterId}/user/${dto.playerId}/number/${dto.number}/v3`,
      method: 'PUT',
    };

    return APIServicesV3.request<any>(axiosConfig);
  }

  public static async changeMemberAttendance({
    rosterId,
    matchId,
    users,
  }: IMemberAttendancePayload) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/rosters/${rosterId}/match-id/${matchId}/set-attendances`,
      method: 'PUT',
      data: {
        users,
      },
    };

    return APIServicesV3.request<any>(axiosConfig);
  }

  public static async updateRosterName({
    rosterId,
    name,
  }: IRosterNameUpdatePayload) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/rosters/${rosterId}/update`,
      method: 'PUT',
      data: {
        name,
      },
    };

    return APIServicesV3.request<any>(axiosConfig);
  }

  public static async updateEditedRosterStatus(rosterId: string) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/rosters/${rosterId}/edited`,
      method: 'PUT',
    };

    return APIServicesV3.request<any>(axiosConfig);
  }
}

export default RosterService;
