import { all, fork } from 'redux-saga/effects';
import accountSaga from './account/sagas';
import userSaga from './user/sagas';
import clubSaga from './club/sagas';
import membershipsSaga from './memberships/sagas';
import rosterSaga from './roster/sagas';
import matchDetailsSaga from './matchDetails/sagas';
import memberpoolSaga from './memberpool/sagas';
import memberpoolRosterSaga from './memberpoolRoster/sagas';
import b2bDraftEventSaga from './v3/b2bDraftEvent/sagas';
import b2bSetupEventSaga from './v3/b2bSetupEvent/sagas';

export default function* rootSaga() {
  yield all([
    fork(accountSaga),
    fork(userSaga),
    fork(membershipsSaga),
    fork(clubSaga),
    fork(rosterSaga),
    fork(matchDetailsSaga),
    fork(memberpoolSaga),
    fork(memberpoolRosterSaga),
    fork(b2bDraftEventSaga),
    fork(b2bSetupEventSaga),
  ]);
}
