import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

interface LightBoxProps {
  isOpen: boolean;
  onClose?: () => void;
  timeout?: number;
  contentWidth?: number;
}
const modalRoot = document.querySelector('#modal-root') as HTMLElement;

const LightBox: React.FunctionComponent<LightBoxProps> = ({
  children,
  isOpen,
  onClose,
  timeout,
  contentWidth,
}) => {
  const modal = useRef(document.createElement('div'));
  const clickOutside = useRef(document.createElement('div'));
  const closeButton = useRef(document.createElement('div'));
  const content = useRef(document.createElement('div'));

  const closeModal = useCallback(
    (evt: any) => {
      evt.preventDefault();
      if (onClose) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    const clickOutsideVar = clickOutside.current;
    const closeButtonVar = closeButton.current;
    clickOutsideVar.addEventListener('click', closeModal, false);
    closeButtonVar.addEventListener('click', closeModal, false);
    return () => {
      clickOutsideVar.removeEventListener('click', closeModal, false);
      closeButtonVar.removeEventListener('click', closeModal, false);
    };
  }, [closeModal]);

  useEffect(() => {
    if (isOpen) {
      if (modalRoot) {
        modalRoot.innerHTML = '';
      }

      modal.current.className = 'modal-wrapper';
      content.current.className = 'content';
      clickOutside.current.className = 'outside-click';
      closeButton.current.className = 'close-button';

      if (contentWidth) {
        content.current.setAttribute('style', `width:${contentWidth}px`);
      }
      modal.current.appendChild(clickOutside.current);
      modal.current.appendChild(content.current);

      modalRoot.appendChild(modal.current);
      modalRoot.className = 'open';
      if (onClose) {
        if (modalRoot) {
          modalRoot.appendChild(closeButton.current);
        }
      }
      if (timeout) {
        setTimeout(() => {
          onClose?.call(null);
        }, timeout);
      }
    }
    // If is closed
    else if (modalRoot) {
      modalRoot.className = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, contentWidth]);

  if (isOpen) {
    return createPortal(children, content.current);
  }
  return <></>;
};

export default LightBox;
