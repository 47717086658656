import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const PaymantAndValueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${toRem(16)} ${toRem(24)};
  background-color: ${COLORS.grey950};

  border: ${toRem(1)} solid ${COLORS.grey800};
  border-radius: ${toRem(12)};
`;
