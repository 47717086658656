import { AxiosRequestConfig } from 'axios';
import ServiceResponse from '../util/ServiceResponse';
import APIServices from '../util/ApiServices';
import { ConfigApi } from '../config.api';
import {
  MembershipCreation,
  MembershipView,
  MembershipViewDetail,
} from '../../admin/models/membership/Membership';
import PageResponse from '../util/PageResponse';

class MembershipsServices {
  public static async getMemberships(): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: 'memberships',
      method: 'GET',
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async list(
    urlParams?: URLSearchParams
  ): Promise<ServiceResponse<PageResponse<MembershipView>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/memberships',
      method: 'GET',
      params: urlParams,
    };
    return APIServices.request<PageResponse<MembershipView>>(axiosConfig);
  }

  public static async create(
    body: MembershipCreation
  ): Promise<ServiceResponse<MembershipView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/memberships',
      method: 'POST',
      data: body,
    };
    return APIServices.request<MembershipView>(axiosConfig);
  }

  public static async update(
    id: string,
    body: MembershipCreation
  ): Promise<ServiceResponse<MembershipViewDetail>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/memberships/${id}`,
      method: 'PUT',
      data: body,
    };
    return APIServices.request<MembershipViewDetail>(axiosConfig);
  }

  public static async delete(
    id: string
  ): Promise<ServiceResponse<MembershipView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/memberships/${id}`,
      method: 'DELETE',
    };
    return APIServices.request<MembershipView>(axiosConfig);
  }

  public static async find(
    id: string
  ): Promise<ServiceResponse<MembershipViewDetail>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/memberships/${id}`,
      method: 'GET',
    };
    return APIServices.request<MembershipViewDetail>(axiosConfig);
  }
}

export default MembershipsServices;
