import React from 'react';
import { MembersPoolTable } from './components/MembersPoolTable/MembersPoolTable';
import { SelectedBox } from './components/MembersPoolTable/components';

export const MembersPoolTab = () => {
  return (
    <>
      <SelectedBox />
      <MembersPoolTable />
    </>
  );
};
