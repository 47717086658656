import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(8)};

  span {
    color: ${COLORS.grey500};
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;
