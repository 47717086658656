import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { EventView } from '../../../admin/models/event/Event';
import Button from '../../../components/uielements/Button/Button';
import usePrimaryClub from '../../../hooks/usePrimaryClub/usePrimaryClub';
import { RosterCreation } from '../../../models/Roster/RosterModel';
import EventService from '../../../services/Event/EventService';
import RosterService from '../../../services/Rosters/RosterService';
import { color } from '../../../styles/styleVariables';
import { ErrorValidationBox } from '../../Wizard/WizardComponents.style';

interface INewRosterModalProps {
  onCreate?: (rosterId: string) => void;
}

interface CreateRosterForm {
  eventId: string;
  eventCategory: string;
  teamName: string;
  name: string;
}

const NewRosterModal: React.FunctionComponent<INewRosterModalProps> = (
  props
) => {
  const { onCreate } = props;
  const [events, setEvents] = useState<EventView[]>([]);
  const { register, errors, handleSubmit, watch, getValues } = useForm<
    CreateRosterForm
  >();

  const primaryClub = usePrimaryClub();

  useEffect(() => {
    EventService.getActive().then((res) => {
      if (res.success) {
        setEvents(
          res.data.content.filter((item) => item.eventType === 'TOURNAMENT')
        );
      }
    });
  }, []);

  const createNewRoster = () => {
    if (primaryClub) {
      const data: RosterCreation = {
        ageDivision: JSON.parse(getValues().eventCategory),
        clubId: primaryClub.id,
        eventId: getValues().eventId,
        name: getValues().name,
      };
      RosterService.create(data).then((res) => {
        if (res.success) {
          onCreate?.call(null, res.data.id);
        }
      });
    }
  };
  return (
    <Wrapper>
      <h4>Which Tournament are you applying for?</h4>
      <div className='form-group'>
        <label htmlFor='eventId'>
          Events
          <div>
            <select
              name='eventId'
              id='eventId'
              ref={register()}
              defaultValue=''
            >
              <option value='' disabled>
                Select an Event
              </option>
              {events.map((event) => (
                <option key={event.id} value={event.id}>
                  {event.name}
                </option>
              ))}
            </select>
          </div>
        </label>
      </div>
      {watch('eventId') && (
        <>
          {/* <EventRequirements>
            <h4>Event Requirements</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Asperiores quae veniam reprehenderit mollitia recusandae
            </p>
          </EventRequirements> */}

          <div className='form-group'>
            <label htmlFor='eventCategory'>
              Which Category are you applying for?
              <div>
                <select
                  name='eventCategory'
                  id='eventCategory'
                  ref={register()}
                  defaultValue=''
                >
                  <option value='' disabled>
                    Select a Category
                  </option>
                  {events
                    .find((e) => e.id === watch('eventId'))
                    ?.ageDivisions?.sort((a, b) => {
                      return (
                        b.gender.localeCompare(a.gender) ||
                        a.years.join(',').localeCompare(b.years.join(','))
                      );
                    })
                    ?.map((event) => (
                      <option
                        key={`${event.gender},${event.rule},${event.years}`}
                        value={JSON.stringify({
                          gender: event.gender,
                          rule: event.rule,
                          years: event.years,
                        })}
                      >
                        {`${event.gender} 
                      ${
                        event.years[0] === 1900
                          ? 'ADULT'
                          : event.years.sort().join(', ')
                      }
                      ${event.rule === 'YOUNGER' ? '(and younger)' : ''}`}
                      </option>
                    ))}
                </select>
              </div>
            </label>
          </div>
        </>
      )}

      {watch('eventCategory') && (
        <>
          {/* <EventRequirements>
            <h4>Category Requirements</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Asperiores quae veniam reprehenderit mollitia recusandae
            </p>
          </EventRequirements> */}
          <div className='form-group'>
            <label htmlFor='name'>
              What is your team name?
              <input
                id='name'
                name='name'
                ref={register({ required: true })}
                placeholder=''
                type='text'
              />
            </label>
            <div>
              We recommend that you keep this name short, unique and include
              gender and age group. A good example would be:{' '}
              <b>FC Barcelona 2012 Girls</b>
            </div>
            <ErrorValidationBox>
              {errors.name && 'Roster name is required'}
            </ErrorValidationBox>
          </div>
          <Button onClick={handleSubmit(createNewRoster)}>
            CREATE NEW ROSTER ►
          </Button>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px 60px;
  background-color: ${color.greyLight};
  border-radius: 10px;
  width: 555px;
`;

// const EventRequirements = styled.div`
//   padding: 20px;
//   background-color: ${color.greyDark};
//   border-radius: 10px;
//   border: solid 1px ${color.orange};
//   text-align: center;
//   margin: 10px 0;
// `;

export default NewRosterModal;
