import React, { memo, FC } from 'react';
import { Tooltip } from 'antd';
import { Info } from '@icon-park/react';

import * as S from './styles';

interface IErrorTooltipProps {
  visible: boolean;
  textContents: string[];
}

export const ErrorTooltip: FC<IErrorTooltipProps> = memo(
  ({ visible, textContents }) => {
    if (!visible) {
      return null;
    }

    const renderTextContent = () => {
      return (
        <S.ErrorList>
          {textContents.map((text) => (
            <S.ErrorListItem>{text}</S.ErrorListItem>
          ))}
        </S.ErrorList>
      );
    };

    return (
      <Tooltip placement='topLeft' title={renderTextContent}>
        <S.InfoIconButton>
          <Info size={24} />
        </S.InfoIconButton>
      </Tooltip>
    );
  }
);

ErrorTooltip.displayName = 'ErrorTooltip';
