import styled from 'styled-components';

import { COLORS, BodyXL, BodyS, BodySBold } from 'styles/v3/variables';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export const OrderLimits = styled(BodyS)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  color: ${COLORS.grey500};
  user-select: none;
`;

export const OrderLimitsValue = styled(BodySBold)`
  color: ${COLORS.white};
`;

export const ApplicationStatus = styled(BodyS)`
  padding-bottom: 4px;
  color: ${COLORS.grey500};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;

  &.locked {
    pointer-events: none;
    opacity: 0.3;
  }
`;

export const Button = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: ${COLORS.grey800};
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  .i-icon svg {
    width: 24px;
    height: 24px;
    display: flex;
    &::before {
      display: none;
    }

    path {
      stroke: ${COLORS.grey50};
    }
  }
`;

export const Quantity = styled(BodyXL)`
  user-select: none;
`;
