import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { BodyL, TitleH2 } from 'styles/v3/variables';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { useGetMyNotifications } from 'hooks/v3/notifications/useGetMyNotifications/useGetMyNotifications';

import * as S from './styles';
import NotificationItem from './components/Item';
import Tabs from './components/Tabs';
import { NotificationTabs, NotificationType } from './types';

const Notifications: React.FC = () => {
  const { data: notificationsData } = useGetMyNotifications();
  const [tab, setTab] = useState<NotificationTabs>('all');

  const hasAnyNotificationsToRead = notificationsData?.filter(
    (n) => !n.alreadySeen
  );

  const notifications = notificationsData?.filter((notification) => {
    if (tab === 'invites') {
      return notification.type === NotificationType.ACCEPT_OR_DECLINE;
    }
    return notification;
  });

  const setAllAsRead = async () => {};

  return (
    <S.Wrapper>
      <TitleH2 $isUpper>Notifications</TitleH2>

      <S.Header>
        <Tabs tab={tab} setTab={setTab} />
        {hasAnyNotificationsToRead && hasAnyNotificationsToRead.length > 0 && (
          <TextButtonWithIcon
            align='flex-start'
            isUpper
            noPadding
            reverse
            color='brandPrimary'
            customIcon='CheckSmall'
            onClick={setAllAsRead}
          >
            Mark all as read
          </TextButtonWithIcon>
        )}
      </S.Header>
      <S.Container>
        {notifications && notifications.length > 0 ? (
          notifications?.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
            />
          ))
        ) : (
          <S.WrapperNoItem>
            <BodyL>There are no notifications to view</BodyL>
          </S.WrapperNoItem>
        )}
      </S.Container>
    </S.Wrapper>
  );
};

export default withRouter(Notifications);
