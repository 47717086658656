import React, { FC, useCallback, useMemo } from 'react';
import { Minus, Plus } from '@icon-park/react';
import { useDispatch } from 'react-redux';

import {
  signupDivisionQtyDecrease,
  signupDivisionQtyIncrease,
} from 'redux/v3/signupEvent/actions';

import { UpdateQtyProps } from './types';

import * as S from './styles';

export const UpdateQty: FC<UpdateQtyProps> = ({
  divisions,
  divisionId,
  applicationLocked = false,
  divisionLocked = false,
  orderInfo,
}) => {
  const dispatch = useDispatch();

  const quantity = useMemo(() => {
    return (
      divisions.find((division) => division.divisionId === divisionId)
        ?.quantity ?? 0
    );
  }, [divisionId, divisions]);

  const increment = useCallback(
    (id: string) => {
      dispatch(signupDivisionQtyIncrease(id));
    },
    [dispatch]
  );

  const decrement = useCallback(
    (id: string) => {
      dispatch(signupDivisionQtyDecrease(id));
    },
    [dispatch]
  );

  const contrainerClassByLockedApp = useMemo(() => {
    if (applicationLocked) {
      return 'locked';
    }

    return '';
  }, [applicationLocked]);

  const incrementButtonClassByLimits = useMemo(() => {
    if (!orderInfo) {
      return '';
    }

    if (
      orderInfo.target - orderInfo.ordered <= quantity &&
      !applicationLocked &&
      divisionLocked
    ) {
      return 'disabled';
    }

    return '';
  }, [quantity, orderInfo]);

  const decrementButtonClassByNegativeQuantity = useMemo(() => {
    if (quantity <= 0 && !applicationLocked) {
      return 'disabled';
    }

    return '';
  }, [quantity, applicationLocked]);

  const currentTarget = useMemo(() => {
    if (orderInfo) {
      return orderInfo.ordered + quantity;
    }

    return 0;
  }, [quantity, orderInfo]);

  return (
    <S.Wrapper>
      {orderInfo && !applicationLocked && (
        <S.OrderLimits>
          Teams/Target
          <S.OrderLimitsValue>
            {currentTarget}/{orderInfo.target}
          </S.OrderLimitsValue>
        </S.OrderLimits>
      )}
      {applicationLocked && (
        <S.ApplicationStatus>Application closed</S.ApplicationStatus>
      )}
      <S.Container className={contrainerClassByLockedApp}>
        <S.Button
          onClick={() => decrement(divisionId)}
          className={decrementButtonClassByNegativeQuantity}
        >
          <Minus />
        </S.Button>
        <S.Quantity>{quantity}</S.Quantity>
        <S.Button
          onClick={() => increment(divisionId)}
          className={incrementButtonClassByLimits}
        >
          <Plus />
        </S.Button>
      </S.Container>
    </S.Wrapper>
  );
};
