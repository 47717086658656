import React from 'react';

import { BodyL, BodyLBold, BodyM } from 'styles/v3/variables';
import ConvertUtil from 'util/ConvertUtil';
import { useGetClubsByUserId } from 'hooks/v3/clubs/useGetClubsByUserId/useGetClubsByUserId';
import { DocumentsProps } from '../Documents/types';
import * as S from './styles';

export const Clubs = ({ user }: DocumentsProps) => {
  const { data: clubs } = useGetClubsByUserId(user.id);
  return (
    <S.Container>
      <BodyLBold className='title'>Clubs</BodyLBold>
      <S.ClubsList>
        {clubs.length > 0 ? (
          clubs.map((club) => (
            <S.CardClub key={club.id}>
              <S.ImgWrapper>
                <img src={ConvertUtil.getMinioUrl(club.logo)} alt={club.name} />
              </S.ImgWrapper>
              <BodyL>{club.name}</BodyL>
            </S.CardClub>
          ))
        ) : (
          <BodyM>No data to show</BodyM>
        )}
      </S.ClubsList>
    </S.Container>
  );
};
