import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { NewAgeDivision } from 'admin/models/AgeDivision';
import { useGetEventRosters } from 'hooks/v3/event/useGetEventRosters';
import { useParams } from 'react-router-dom';
import { RosterByEventId } from 'services/v3/Rosters/RosterService';
import {
  MutationFnProps,
  useUpdateDivisionTest,
} from 'hooks/v3/event/useUpdateDivision';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import ServiceResponse from 'services/util/ServiceResponse';
import { useGetEventRostersAvailableForTeams } from 'hooks/v3/event/useGetEventRostersAvailableForTeams';
import { useGetEventDivisionsOrdered } from 'hooks/v3/event/useGetEventDivisonsOrdered';

type TeamPlacementContextType = {
  eventId: string;
  poolName?: string;
  teamIdentifier?: string;
  rosters?: RosterByEventId[];
  divisions?: NewAgeDivision[];
  selectedDivision: NewAgeDivision | null;
  setSelectedDivision: React.Dispatch<
    React.SetStateAction<NewAgeDivision | null>
  >;
  setRosters: React.Dispatch<
    React.SetStateAction<RosterByEventId[] | undefined>
  >;
  allRosters?: RosterByEventId[];
  updateDivisionFn: UseMutateAsyncFunction<
    ServiceResponse<any>,
    any,
    MutationFnProps,
    unknown
  >;
  openedPools: string[] | undefined;
  setOpenedPools: React.Dispatch<React.SetStateAction<string[] | undefined>>;
};

const TeamPlacementContext = createContext({} as TeamPlacementContextType);

export const useTeamPlacement = () => useContext(TeamPlacementContext);

export const TeamPlacementProvider: React.FC = ({ children }) => {
  const { eventId } = useParams<{ eventId: string }>();

  const { data } = useGetEventDivisionsOrdered({
    eventId,
  });
  const { data: rostersData } = useGetEventRosters({ eventId });
  const { data: availableRosters } = useGetEventRostersAvailableForTeams({
    eventId,
  });

  const { mutateAsync } = useUpdateDivisionTest({ eventId });

  const [
    selectedDivision,
    setSelectedDivision,
  ] = useState<NewAgeDivision | null>(null);

  const [rosters, setRosters] = useState<RosterByEventId[] | undefined>(
    availableRosters
  );

  const [allRosters, setAllRosters] = useState(rostersData);
  const [openedPools, setOpenedPools] = useState<string[]>();

  const divisions = useMemo(() => {
    return data?.ageDivisions;
  }, [data]);

  const values: TeamPlacementContextType = useMemo(
    () => ({
      rosters,
      eventId,
      divisions,
      setRosters,
      openedPools,
      setAllRosters,
      setOpenedPools,
      selectedDivision,
      setSelectedDivision,
      updateDivisionFn: mutateAsync,
    }),
    [rosters, eventId, divisions, allRosters, openedPools, selectedDivision]
  );

  useEffect(() => {
    if (divisions?.length && !selectedDivision) {
      setSelectedDivision(divisions[0]);
    }
  }, [divisions, selectedDivision]);

  useEffect(() => {
    if (availableRosters?.length) setRosters(availableRosters);
  }, [availableRosters]);

  useEffect(() => {
    if (rostersData?.length) setAllRosters(rostersData);
  }, [rostersData]);

  return (
    <TeamPlacementContext.Provider value={values}>
      {children}
    </TeamPlacementContext.Provider>
  );
};
