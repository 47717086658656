import { AxiosRequestConfig } from 'axios';
import ServiceResponse from '../util/ServiceResponse';
import APIServices from '../util/ApiServices';
import { Account } from '../../redux/account/types';
import { ConfigApi } from '../config.api';

class AccountServices {
  public static async getData(): Promise<ServiceResponse<Account>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: 'users',
      method: 'GET',
    };
    return APIServices.request<Account>(axiosConfig);
  }

  public static async getAccounts(): Promise<ServiceResponse<Account>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: 'users/accounts',
      method: 'GET',
    };
    return APIServices.request<Account>(axiosConfig);
  }

  public static async setActiveProfile(): Promise<ServiceResponse<Account>> {
    // TODO
    const axiosConfig: AxiosRequestConfig = {};
    return APIServices.request<Account>(axiosConfig);
  }
}

export default AccountServices;
