import * as React from 'react';

import { ColorsInterface, BodyM, BodyS, BodyL } from 'styles/v3/variables';

export interface TextProps {
  children: string;
  color?: keyof ColorsInterface;
  className?: string;
  size?: 'small' | 'medium' | 'large';
}

const Text: React.FC<TextProps> = ({ children, size, color, className }) => {
  switch (size) {
    case 'small':
      return (
        <BodyS className={className} $color={color}>
          {children}
        </BodyS>
      );
    case 'medium':
      return (
        <BodyM className={className} $color={color}>
          {children}
        </BodyM>
      );
    case 'large':
      return (
        <BodyL className={className} $color={color}>
          {children}
        </BodyL>
      );
    default:
      return (
        <BodyM className={className} $color={color}>
          {children}
        </BodyM>
      );
  }
};

export default Text;
