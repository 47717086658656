import React, { useCallback, useMemo, useState } from 'react';

import { CloseSmall } from '@icon-park/react';

import { BodyM, BodyXL, TitleH3 } from 'styles/v3/variables';

import UserType from 'models/User/UserTypeModel';
import { useProfile } from 'hooks/v3/profile/useProfile';
import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';
import FilledButton from 'components/v3/Buttons/FilledButton';
import useClubPermission from 'hooks/v3/clubs/useClubPermission';
import { useGetMyRequestsToClub } from 'hooks/v3/requests/useGetMyRequestsToClub/useGetMyRequestsToClub';
import { RequestStatus } from 'models/Request/RequestModel';
import { useRequestJoinAndCreateRule } from 'hooks/v3/clubs/useRequestJoinAndCreateRule/useRequestJoinAndCreateRule';
import { JoinClubRole } from 'models/v3/Club/ClubModel';
import * as S from './styles';
import { JoinModalProps } from './types';
import { allowedRoles } from './allowedRoles';

export const JoinModal = ({ isOpen, handleIsOpen, club }: JoinModalProps) => {
  const { currentUser } = useProfile();

  const {
    isCoachOfThisClub,
    isPlayerOfThisClub,
    isTeamManagerOfThisClub,
    isDirectorOfThisClub,
  } = useClubPermission(club);
  const { data: myClubRequests } = useGetMyRequestsToClub({
    clubId: club?.id,
    status: [RequestStatus.PENDING],
  });

  const {
    mutateAsync: requestJoin,
    isLoading: isRequesting,
  } = useRequestJoinAndCreateRule();

  const [roles, setUpdateRoles] = useState(allowedRoles);

  const alreadyAppliedAs = useMemo(() => {
    return (
      myClubRequests?.content
        ?.map((r) => r?.user?.type)
        ?.filter((type) => type !== undefined) || []
    );
  }, [myClubRequests]);

  const handleCloseModal = () => {
    handleIsOpen(false);
    const updatedRoles = roles.map((role) => ({ ...role, selected: false }));
    setUpdateRoles(updatedRoles);
  };

  const handleJoinClub = async () => {
    if (isRequesting) return;

    let rolesSeleted: JoinClubRole | JoinClubRole[] =
      roles.filter((role) => role.selected)?.map((role) => role.slug) ?? [];
    rolesSeleted = rolesSeleted.length === 4 ? 'all' : rolesSeleted;

    try {
      if (Array.isArray(rolesSeleted)) {
        const joinAll = rolesSeleted.map(async (roleItem) => {
          const response = await requestJoin({
            clubId: club.id,
            role: roleItem,
          });

          return response;
        });

        await Promise.all(joinAll).then(() => handleCloseModal());
      } else {
        requestJoin({ clubId: club.id, role: rolesSeleted }).then(() => {
          handleCloseModal();
        });
      }
    } catch (e) {
      console.warn(`Error: ${e}`);
    }
  };

  const roleIsEnabled = useCallback(
    (roleType: UserType): boolean => {
      if (!club.allowApplications) return false;
      return (
        !alreadyAppliedAs.includes(roleType) &&
        !isCoachOfThisClub &&
        !isPlayerOfThisClub &&
        !isTeamManagerOfThisClub &&
        !isDirectorOfThisClub
      );
    },
    [club, currentUser, myClubRequests]
  );

  const hasCheckedMember = useMemo(() => {
    return roles.some((role) => role.selected);
  }, [roles]);

  const handleSelectMember = (roleTitle: string) => {
    const updatedRoles = roles.map((role) => {
      if (role.title === roleTitle) {
        return {
          ...role,
          selected: !role.selected,
        };
      }

      return role;
    });
    setUpdateRoles(updatedRoles);
  };

  return (
    <S.StyledModal
      centered
      footer={false}
      closable={false}
      visible={isOpen}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
    >
      <S.Container>
        <S.HeaderWrapper>
          <TitleH3>Select how you want to join this club</TitleH3>
          <S.CloseButton onClick={handleCloseModal}>
            <CloseSmall size='24px' />
          </S.CloseButton>
        </S.HeaderWrapper>

        <BodyM $color='grey400'>You can select more than one option.</BodyM>
        <S.RolesWrapper>
          {roles.map((role) => {
            const Icon = role.icon;
            return (
              <S.RoleCard key={role.name} isEnabled={roleIsEnabled(role.name)}>
                <InputCheckbox
                  disabled={!roleIsEnabled(role.name)}
                  id={role.name}
                  name={role.name}
                  onChange={() => handleSelectMember(role.title)}
                  checked={role.selected}
                />
                <BodyXL>{role.title}</BodyXL>
                <Icon size={36} />
              </S.RoleCard>
            );
          })}
        </S.RolesWrapper>
        <FilledButton
          disabled={!hasCheckedMember || isRequesting}
          isUpper
          onClick={handleJoinClub}
        >
          {isRequesting ? 'Requesting...' : 'Request to Join'}
        </FilledButton>
      </S.Container>
    </S.StyledModal>
  );
};
