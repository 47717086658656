import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container =
  styled.div <
  { $enabled: boolean } >
  `
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${toRem(3)};

  opacity: ${({ $enabled }) => ($enabled ? 1 : 0.5)};
`;

export const Icon =
  styled.div <
  { $approved: boolean } >
  `
  .i-icon svg {
    width: ${toRem(24)};
    height: ${toRem(24)};
    display: flex;
    &::before {
      display: none;
    }
    path, rect {
      stroke: ${({ $approved }) =>
        $approved ? COLORS.grey400 : COLORS.supportError};
    }
  }
`;
