import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Loading from '../../../components/base/Loading/Loading';
import LoadingIcon from '../../../components/base/LoadingIcon/LoadingIcon';
import MatchService from '../../../services/Match/MatchService';
import { color } from '../../../styles/styleVariables';

interface IGameReportModalProps {
  matchId?: string;
}

const GameReportModal: React.FunctionComponent<IGameReportModalProps> = (
  props
) => {
  const { matchId } = props;
  const [gameReport, setGameReport] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (matchId) {
      setLoading(true);
      MatchService.getDetails(matchId).then((res) => {
        setLoading(false);
        if (res.success) {
          setGameReport(res.data.report || '-');
        }
      });
    }
  }, [matchId]);
  return (
    <WrapperBox>
      <h3>Game Report</h3>
      {loading && <LoadingIcon />}
      {!loading && <div className='report'>{gameReport}</div>}
    </WrapperBox>
  );
};
const WrapperBox = styled.div`
  padding: 20px;
  box-sizing: border-box;
  background: ${color.greyLight};
  border-radius: 20px;
  width: 400px;
  .report {
    border: 1px solid #cccccc29;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    white-space: pre-line;
  }
`;
export default GameReportModal;
