interface IRequirementListProps {
  minPlayers: number;
  maxPlayers: number;
  minCoaches: number;
  maxCoaches: number;
}

export const getRequirementList = (props: IRequirementListProps) => {
  const { minPlayers, maxPlayers, minCoaches, maxCoaches } = props;

  return [
    'Every Player and Coach has an Official Membership',
    'Every Player has a jersey number',
    `There are at least ${minPlayers} Players`,
    `There are no more than ${maxPlayers} Players`,
    `There are at least ${minCoaches} coaches`,
    `There are no more than ${maxCoaches} coaches`,
    'All the players and coaches have submitted their required documents, and their documents have been approved',
  ];
};
