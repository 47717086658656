import { AxiosRequestConfig } from 'axios'
import { VenueCreation, VenueView } from '../../admin/models/event/Venue'
import { ConfigApi } from '../config.api'
import APIServices from '../util/ApiServices'
import PageResponse from '../util/PageResponse'
import ServiceResponse from '../util/ServiceResponse'

class VenueServices {
  public static async list(): Promise<
    ServiceResponse<PageResponse<VenueView>>
  > {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/venues',
      method: 'GET',
    }
    return APIServices.request<PageResponse<VenueView>>(axiosConfig)
  }

  public static async find(id: string): Promise<ServiceResponse<VenueView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/venues/${id}`,
      method: 'GET',
    }
    return APIServices.request<VenueView>(axiosConfig)
  }

  public static async create(
    body: VenueCreation
  ): Promise<ServiceResponse<VenueView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/venues',
      method: 'POST',
      data: body,
    }
    return APIServices.request<VenueView>(axiosConfig)
  }

  public static async createEventVenue(
    eventId: string,
    body: VenueCreation
  ): Promise<ServiceResponse<VenueView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/venues/event/${eventId}`,
      method: 'POST',
      data: body,
    }
    return APIServices.request<VenueView>(axiosConfig)
  }

  public static async listEventVenues(
    eventId: string
  ): Promise<ServiceResponse<VenueView[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/venues/event/${eventId}`,
      method: 'GET',
    }
    return APIServices.request<VenueView[]>(axiosConfig)
  }

  public static async updateEventVenue(
    id: string,
    eventId: string,
    body: VenueCreation
  ): Promise<ServiceResponse<VenueView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/venues/event/${eventId}/${id}`,
      method: 'PUT',
      data: body,
    }
    return APIServices.request<VenueView>(axiosConfig)
  }

  public static async update(
    id: string,
    body: VenueCreation
  ): Promise<ServiceResponse<VenueView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/venues/${id}`,
      method: 'PUT',
      data: body,
    }
    return APIServices.request<VenueView>(axiosConfig)
  }
}

export default VenueServices
