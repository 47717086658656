import { useQuery } from '@tanstack/react-query';
import UserService from 'services/v3/User/UserService';

const useGetAllProfiles = () => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['get-all-profiles'],
    () => UserService.getProfiles()
  );

  return {
    data,
    refetch,
    isLoading,
    isFetching,
  };
};

export default useGetAllProfiles;
