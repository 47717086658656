import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import UserType from 'models/User/UserTypeModel';
import { User } from 'models/User/UserModel';
import { onClose } from 'redux/v3/profileModal/actions';
import { setCurrentUser } from 'redux/v3/currentUser/actions';

import { truncateText } from 'util/truncateText';
import ConvertUtil from 'util/ConvertUtil';
import { formatUserName } from 'util/formatUserName';

import { BodyMBold } from 'styles/v3/variables';

import useGetAllProfiles from 'hooks/v3/profile/useGetAllProfiles/useGetAllProfiles';
import { setUserId } from 'util/UsersUtil';
import { MemberTag } from './components/MemberTag/MemberTag';

import { AccountTag } from '../../../AccountTag/AccountTag';

import * as S from './styles';

interface IAccountCardProps {
  user: User;
  isMember?: boolean;
  types?: Array<UserType>;
}

export const AccountCard: FC<IAccountCardProps> = ({
  user,
  types,
  isMember,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { refetch: getProfiles } = useGetAllProfiles();

  const userName = formatUserName({
    lastName: user.lastName,
    firstName: user.firstName,
    middleName: user.middleName,
  });

  const handleClick = async () => {
    try {
      const response = await getProfiles();
      const userId = response?.data?.data?.users.find(
        (userItem) => userItem?.baseUserId === user?.id
      )?.id;

      userId && setUserId(userId);
      dispatch(setCurrentUser(user));
      dispatch(onClose());
      history.replace('/v3/home-page');
    } catch (e) {
      console.warn(`Error: ${e}`);
    }
  };

  return (
    <S.CardWrapper onClick={handleClick}>
      <S.AvatarWrapper>
        <Avatar
          size={38}
          alt={userName}
          icon={<UserOutlined />}
          src={ConvertUtil.getMinioUrl(user.photo)}
        />
      </S.AvatarWrapper>
      <S.CardHeaderWrapper>
        <S.CardHeader>
          <BodyMBold $color='grey50'>
            {truncateText(userName, isMember ? 26 : 35)}
          </BodyMBold>
          {isMember && <MemberTag />}
        </S.CardHeader>
        <S.AccountTagWrapper>
          {types?.map((type) => (
            <AccountTag key={type} type={type} />
          ))}
        </S.AccountTagWrapper>
      </S.CardHeaderWrapper>
    </S.CardWrapper>
  );
};
