import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { BodyMBold } from 'styles/v3/variables';

import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import InputText from 'components/v3/Forms/InputText/InputText';
import { GENDER_OPTIONS } from 'pages/V3/event/components/EventMain/components/AgeSection/configs';

import { DEFAULT_FILTRATION_STATE, IFiltrationParams } from './configs';

import * as S from './styles';

interface IFiltersProps {
  years: number[];
  onFilterChange: (filters: IFiltrationParams) => void;
}

export const Filters: FC<IFiltersProps> = ({ years, onFilterChange }) => {
  const searchTimeoutRef = useRef<number>(0);

  const [filtration, setFiltration] = useState<IFiltrationParams>(
    DEFAULT_FILTRATION_STATE
  );

  const yearsOptions = useMemo((): OptionsType[] => {
    const uniqueYears = new Set(years);
    const options: OptionsType[] = [
      {
        label: 'Year',
        value: 'all',
      },
    ];

    uniqueYears.forEach((year) => {
      options.push({
        label: year.toString(),
        value: year,
      });
    });

    return options;
  }, [years]);

  const handleGenderSelect = useCallback((option: any) => {
    if (option) {
      setFiltration((prevState) => ({
        ...prevState,
        gender: option,
      }));
    }
  }, []);

  const handleYearSelect = useCallback((option: any) => {
    if (option) {
      setFiltration((prevState) => ({
        ...prevState,
        years: option,
      }));
    }
  }, []);

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const targetValue =
      typeof event.target?.value === 'string' ? event.target?.value : '';

    setFiltration((prevState) => ({
      ...prevState,
      search: targetValue,
    }));
  }, []);

  useEffect(() => {
    clearTimeout(searchTimeoutRef.current);

    searchTimeoutRef.current = window.setTimeout(() => {
      onFilterChange(filtration);
    }, 100);

    return () => {
      clearTimeout(searchTimeoutRef.current);
    };
  }, [filtration]);

  return (
    <S.Container>
      <S.FilterWrapper>
        <BodyMBold $isUpper $color='grey400'>
          Filter By
        </BodyMBold>
        <Select
          menuPlacement='auto'
          menuPosition='fixed'
          isMulti={false}
          onChange={handleGenderSelect}
          placeholder='GENDER'
          aria-label='GENDER'
          name='gender'
          value={filtration.gender}
          options={GENDER_OPTIONS}
        />
        <Select
          menuPlacement='auto'
          menuPosition='fixed'
          onChange={handleYearSelect}
          placeholder='YEAR'
          aria-label='YEAR'
          name='year'
          value={filtration.years}
          options={yearsOptions}
        />
      </S.FilterWrapper>
      <InputText
        id='search'
        placeholder='Search'
        icon='Search'
        value={filtration.search}
        onChange={handleSearch}
      />
    </S.Container>
  );
};
