import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ColorPicker from '../../../../../../components/base/ColorPicker/ColorPicker';
import LightBox from '../../../../../../components/base/LightBox/LightBox';
import {
  GameAction,
  GamePeriod,
} from '../../../../../../models/GameEvent/GameEventModel';
import {
  GamePreferences,
  MatchDetailsModel,
} from '../../../../../../models/Match/MatchModel';
import { matchDetailsUpdateGamePreferences } from '../../../../../../redux/matchDetails/actions';
import { ApplicationState } from '../../../../../../redux/store';
import { color } from '../../../../../../styles/styleVariables';
import ConvertUtil from '../../../../../../util/ConvertUtil';

interface IGameResultProps {
  matchDetails: MatchDetailsModel;
  gamePreferences?: GamePreferences;
}

const GameResult: React.FunctionComponent<IGameResultProps> = (props) => {
  const { gamePreferences } = props;

  const matchDetails = useSelector(
    (state: ApplicationState) => state.matchDetails
  );

  const dispatch = useDispatch();

  const [fifthFoulAlert, setFifthFoulAlert] = useState(false);

  const setColorTeamA = (color: string) => {
    dispatch(
      matchDetailsUpdateGamePreferences({
        ...gamePreferences!,
        colorTeamA: color,
      })
    );
  };

  const setColorTeamB = (color: string) => {
    dispatch(
      matchDetailsUpdateGamePreferences({
        ...gamePreferences!,
        colorTeamB: color,
      })
    );
  };

  const getGameResult = () => {
    // Team A Goals Calc
    const teamAGoals =
      // Goals in matchEvents
      (matchDetails.data.match?.matchEvents?.filter(
        (item) =>
          item.rosterId === matchDetails.data.match?.homeTeam.id &&
          item.action == GameAction.GOAL
      ).length || 0) +
      // Goals in matchEventsFailed
      (matchDetails.data.matchEventsFailed?.filter(
        (item) =>
          item.rosterId === matchDetails.data.match?.homeTeam.id &&
          item.action == GameAction.GOAL
      ).length || 0) +
      // Own Goals in matchEvents
      (matchDetails.data.match?.matchEvents?.filter(
        (item) =>
          item.rosterId === matchDetails.data.match?.awayTeam.id &&
          item.action == GameAction.OWN_GOAL
      ).length || 0) +
      // Own Goals in matchEventsFailed
      (matchDetails.data.matchEventsFailed?.filter(
        (item) =>
          item.rosterId === matchDetails.data.match?.awayTeam.id &&
          item.action == GameAction.OWN_GOAL
      ).length || 0);

    // Team B Goals Calc
    const teamBGoals =
      // Goals in matchEvents
      (matchDetails.data.match?.matchEvents?.filter(
        (item) =>
          item.rosterId === matchDetails.data.match?.awayTeam.id &&
          item.action == GameAction.GOAL
      ).length || 0) +
      // Goals in matchEventsFailed
      (matchDetails.data.matchEventsFailed?.filter(
        (item) =>
          item.rosterId === matchDetails.data.match?.awayTeam.id &&
          item.action == GameAction.GOAL
      ).length || 0) +
      // Own Goals in matchEvents
      (matchDetails.data.match?.matchEvents?.filter(
        (item) =>
          item.rosterId === matchDetails.data.match?.homeTeam.id &&
          item.action == GameAction.OWN_GOAL
      ).length || 0) +
      // Own Goals in matchEventsFailed
      (matchDetails.data.matchEventsFailed?.filter(
        (item) =>
          item.rosterId === matchDetails.data.match?.homeTeam.id &&
          item.action == GameAction.OWN_GOAL
      ).length || 0);
    return {
      teamAGoals,
      teamBGoals,
    };
  };

  const foulsPerPeriodCalc = () => {
    // Fouls in matchEvents
    const teamAFouls =
      (matchDetails.data.match?.matchEvents?.filter(
        (item) =>
          /* 
            This expression calculates the sum of FOULS in current period
            PLUS other periods if current period is not FIRST_HALF
            It's used because FOULS restart at second period, but fouls at
            second period will be carried for extra time 
          */
          item.rosterId === matchDetails.data.match?.homeTeam.id &&
          item.action === GameAction.FOUL &&
          (item.period === matchDetails.preferences.timing.period ||
            (matchDetails.preferences.timing.period !== GamePeriod.FIRST_HALF &&
              item.period !== GamePeriod.FIRST_HALF))
      ).length || 0) +
      // Fouls in matchEventsFailed
      (matchDetails.data.matchEventsFailed?.filter(
        (item) =>
          item.rosterId === matchDetails.data.match?.homeTeam.id &&
          item.action === GameAction.FOUL &&
          (item.period === matchDetails.preferences.timing.period ||
            (matchDetails.preferences.timing.period !== GamePeriod.FIRST_HALF &&
              item.period !== GamePeriod.FIRST_HALF))
      ).length || 0);

    const teamBFouls =
      (matchDetails.data.match?.matchEvents?.filter(
        (item) =>
          item.rosterId === matchDetails.data.match?.awayTeam.id &&
          item.action === GameAction.FOUL &&
          (item.period === matchDetails.preferences.timing.period ||
            (matchDetails.preferences.timing.period !== GamePeriod.FIRST_HALF &&
              item.period !== GamePeriod.FIRST_HALF))
      ).length || 0) +
      // Fouls in matchEventsFailed
      (matchDetails.data.matchEventsFailed?.filter(
        (item) =>
          item.rosterId === matchDetails.data.match?.awayTeam.id &&
          item.action === GameAction.FOUL &&
          (item.period === matchDetails.preferences.timing.period ||
            (matchDetails.preferences.timing.period !== GamePeriod.FIRST_HALF &&
              item.period !== GamePeriod.FIRST_HALF))
      ).length || 0);

    return {
      teamAFouls,
      teamBFouls,
    };
  };

  useEffect(() => {
    if (foulsPerPeriodCalc().teamAFouls === 5) {
      setFifthFoulAlert(true);
    }
  }, [foulsPerPeriodCalc().teamAFouls]);

  useEffect(() => {
    if (foulsPerPeriodCalc().teamBFouls === 5) {
      setFifthFoulAlert(true);
    }
  }, [foulsPerPeriodCalc().teamBFouls]);

  return (
    <GameResultWrapper
      className={`${gamePreferences?.swapped ? 'order-swapped' : ''}`}
    >
      <div>
        <ColorPicker onSelectColor={(color) => setColorTeamA(color)}>
          <div
            className='logo team-a'
            style={{
              boxShadow: `${
                gamePreferences?.colorTeamA || '#177ddc'
              }  0 0 0px 10px`,
            }}
          >
            <img
              src={ConvertUtil.getMinioUrl(
                matchDetails.data.match?.homeTeam.logo
              )}
            />
          </div>
        </ColorPicker>
      </div>
      <div className='score'>{getGameResult().teamAGoals || 0}</div>

      <div className='separator'>x</div>

      <div className='score'>{getGameResult().teamBGoals || 0}</div>
      <div style={{ position: 'relative' }}>
        <ColorPicker onSelectColor={(color) => setColorTeamB(color)}>
          <div>
            <div
              className='logo team-b'
              style={{
                boxShadow: `${
                  gamePreferences?.colorTeamB || '#f94144ff'
                }  0 0 0px 10px`,
              }}
            >
              <img
                src={ConvertUtil.getMinioUrl(
                  matchDetails.data.match?.awayTeam.logo
                )}
              />
            </div>
          </div>
        </ColorPicker>
      </div>

      <div className='team-name'>
        <span className='bold'>{matchDetails.data.match?.homeTeam.name}</span>
        <br />
        (Home)
      </div>
      <div
        className={
          foulsPerPeriodCalc().teamAFouls > 4 ? 'bold text-orange' : ''
        }
      >
        FOULS
        <div>{foulsPerPeriodCalc().teamAFouls}</div>
      </div>
      <div />
      <div
        className={
          foulsPerPeriodCalc().teamBFouls > 4 ? 'bold text-orange' : ''
        }
      >
        FOULS
        <div>{foulsPerPeriodCalc().teamBFouls}</div>
      </div>
      <div className='team-name'>
        <span className='bold'>{matchDetails.data.match?.awayTeam.name}</span>
        <br />
        (Away)
      </div>

      <LightBox
        isOpen={fifthFoulAlert}
        onClose={() => setFifthFoulAlert(false)}
      >
        <Box>
          <h2>5th FOUL</h2>
        </Box>
      </LightBox>
    </GameResultWrapper>
  );
};

const GameResultWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1.5fr 1fr 0.5fr 1fr 1.5fr;
  text-align: center;
  max-width: 450px;
  margin: 0 auto;
  position: relative;
  margin-top: 30px;

  .score {
    font-size: 36px;
    font-weight: 600;
    padding: 0 10px;
  }
  .logo {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    overflow: hidden;
    padding: 15px;
    background: #fff;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .team-name {
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
  }

  .separator {
    font-size: 20px;
    font-weight: 300;
    padding: 0 10px;
  }
  &.order-swapped {
    div:nth-child(1) {
      order: 5;
    }
    div:nth-child(2) {
      order: 4;
    }
    div:nth-child(3) {
      order: 3;
    }
    div:nth-child(4) {
      order: 2;
    }
    div:nth-child(5) {
      order: 1;
    }
    div:nth-child(6) {
      order: 10;
    }
    div:nth-child(7) {
      order: 9;
    }
    div:nth-child(8) {
      order: 8;
    }
    div:nth-child(9) {
      order: 7;
    }
    div:nth-child(10) {
      order: 6;
    }
  }
`;

const Box = styled.div`
  padding: 20px;
  margin-top: 10px;
  border-radius: 8px;
  background-color: ${color.greyDark};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
`;

export default GameResult;
