import { message, Select } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ClubView } from '../../admin/models/club/Club';
import ClubService from '../../services/Club/ClubService';
import { color } from '../../styles/styleVariables';
import ConvertUtil from '../../util/ConvertUtil';
import LightBox from '../base/LightBox/LightBox';
import Button from '../uielements/Button/Button';

import icon_club from '../../assets/imgs/icons/ico_club.svg';
import { User } from '../../models/User/UserModel';

interface IAddClubModalProps {
  user?: User;
  isOpen: boolean;
  onClose: () => void;
  onSuccessfulSubmit: () => void;
}

const AddClubModal: React.FunctionComponent<IAddClubModalProps> = (props) => {
  const { user, isOpen, onClose, onSuccessfulSubmit } = props;
  const [clubList, setClubList] = useState<ClubView[]>([]);
  const [selectedClub, setSelectedClub] = useState('');

  const onAddClub = () => {
    if (user) {
      ClubService.addUserToMemberpool({
        userId: user?.id,
        clubId: selectedClub,
      }).then((response) => {
        if (response.success) {
          message.success({
            content: 'User added to club memberpool',
            duration: 3,
          });
          onSuccessfulSubmit();
        } else {
          message.error({ content: response.message, duration: 3 });
        }
      });
    }
  };

  useEffect(() => {
    ClubService.list(
      new URLSearchParams({
        size: '1000',
      })
    ).then((response) => {
      if (response.success) {
        setClubList(response.data.content);
      }
    });
  }, []);

  return (
    <LightBox isOpen={isOpen} onClose={onClose} contentWidth={400}>
      <SelectClubBox>
        <h2 style={{ margin: '10px' }}>Add user to Club</h2>
        <div className='user-box'>
          <div className='img'>
            <img
              src={ConvertUtil.getMinioUrl(user?.photo)}
              width='40px'
              height='40px'
            />
          </div>
          {user?.firstName} {user?.lastName}
        </div>
        <h4 style={{ margin: '10px' }}>add to:</h4>
        <div className='form-group'>
          <Select
            placeholder={
              <div className='option-image'>
                <div
                  className='img'
                  role='img'
                  style={{
                    backgroundColor: '#53575f',
                    display: 'grid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px',
                  }}
                >
                  <img
                    alt=''
                    src={icon_club}
                    width='20px'
                    height='20px'
                    style={{ opacity: 0.5 }}
                  />
                </div>
                Search User
              </div>
            }
            showSearch
            optionFilterProp='data-label'
            className={`fat user-select`}
            onChange={(value) => {
              setSelectedClub(value.toString());
            }}
          >
            {clubList.map((item) => {
              return (
                <Select.Option
                  key={`clubId_${item.id}`}
                  value={item.id}
                  data-label={item.name}
                >
                  <div className='option-image'>
                    <div className='img' role='img' aria-label='Photo'>
                      <img
                        alt=''
                        src={ConvertUtil.getMinioUrl(item.logo)}
                        width='40px'
                        height='40px'
                      />
                    </div>
                    {item.name}
                  </div>
                </Select.Option>
              );
            })}
          </Select>
          <Button size='small' onClick={onAddClub}>
            Add Club
          </Button>
        </div>
      </SelectClubBox>
    </LightBox>
  );
};

const SelectClubBox = styled.div`
  width: 400px;
  padding: 20px;
  border-radius: 10px;
  background-color: ${color.greyLight};

  .user-box {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 7px;
    border: solid 1px #53575f;
    .img {
      width: 40px;
      height: 40px;
      border-radius: 25px;
      overflow: hidden;
      margin-right: 10px;
    }
  }
`;
export default AddClubModal;
