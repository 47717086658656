import * as React from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from '../../../routes/PrivateRoute';
import WizardMajority from '../WizardMajority';
import WizardChooseTypePage from '../WizardChooseType.page';
import WizardPlayerMainInfo from '../WizardPlayerMainInfo';
import WizardPhoto from '../WizardPhoto';
import WizardDocument from '../WizardDocument';
import WizardMembership from '../WizardMembership';
import WizardTerms from '../WizardTerms';
import WizardCertificate from '../WizardCertificate';
import WizardPayment from '../WizardPayment';
import PaymentSuccess from '../../Payment/PaymentSuccess';
import WizardAdditionalInfo from '../WizardAdditionalInfo';

const routes = [
  {
    path: '/wizard',
    component: WizardChooseTypePage,
    exact: true,
    private: true,
    layout: 'menu-empty',
  },
  {
    path: '/wizard/info',
    component: WizardPlayerMainInfo,
    private: true,
    layout: 'menu-empty',
  },
  {
    path: '/wizard/info2',
    component: WizardMajority,
    private: true,
    layout: 'menu-empty',
  },
  {
    path: '/wizard/photo',
    component: WizardPhoto,
    private: true,
    layout: 'menu-empty',
  },
  {
    path: '/wizard/document',
    component: WizardDocument,
    private: true,
    layout: 'menu-empty',
  },
  {
    path: '/wizard/memberships',
    component: WizardMembership,
    private: true,
    layout: 'menu-empty',
  },
  {
    path: '/wizard/terms',
    component: WizardTerms,
    private: true,
    layout: 'menu-empty',
  },
  {
    path: '/wizard/additionalinfo',
    component: WizardAdditionalInfo,
    private: true,
    layout: 'menu-empty',
  },
  {
    path: '/wizard/certificate',
    component: WizardCertificate,
    private: true,
    layout: 'menu-empty',
  },
  {
    path: '/wizard/payment',
    component: WizardPayment,
    private: true,
    layout: 'menu-empty',
  },
  {
    path: '/success',
    component: PaymentSuccess,
    private: true,
    layout: 'menu-empty',
  },
];

interface PrivateRouteProps {
  auth: Keycloak.KeycloakInstance | undefined;
}

const WizardRoutes: React.FunctionComponent<PrivateRouteProps> = (props) => {
  const { auth } = props;
  return (
    <>
      {routes.map((route, i) =>
        !route.private ? (
          <Route
            key={route.path}
            exact={route.exact}
            path={route.path}
            render={() => <route.component />}
          />
        ) : (
          <PrivateRoute
            key={route.path}
            auth={auth}
            userSelectedNotRequired
            // role={route.role}
            exact={route.exact ? route.exact : false}
            path={route.path}
            render={() => <route.component auth={auth} />}
          />
        )
      )}
    </>
  );
};

export default WizardRoutes;
