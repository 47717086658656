import { RequestStatus } from 'models/Request/RequestModel';
import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  margin-top: ${toRem(14)};
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: ${toRem(4)};

  .title {
    color: ${COLORS.grey50};
  }
`;

export const DocumentList = styled.div`
  margin-top: ${toRem(18)};
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  row-gap: ${toRem(36)};
`;

export const DocumentItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const getStatusColor = (status?: RequestStatus) => {
  switch (status) {
    case 'APPROVED':
      return COLORS.brandPrimary;
    case 'PENDING':
      return COLORS.grey200;
    case 'EXPIRED':
    case 'DECLINED':
      return COLORS.supportError;
    default:
      return COLORS.grey200;
  }
};

export const StatusTag = styled.div<{ $status?: RequestStatus }>`
  display: flex;
  padding: ${toRem(4)} ${toRem(16)};
  justify-content: center;
  align-items: center;
  gap: ${toRem(4)};
  text-transform: capitalize;
  color: ${COLORS.grey800};

  border-radius: ${toRem(33)};
  background: ${({ $status }) => getStatusColor($status)};
`;
