import React, { FC } from 'react';

import * as S from '../styles';
import { NotificationTabs } from '../types';

interface IProps {
  tab: NotificationTabs;
  setTab: (value: NotificationTabs) => void;
}

const Tabs: FC<IProps> = ({ tab, setTab }) => {
  return (
    <S.Tabs>
      <S.TabItem
        onClick={() => setTab('all')}
        className={tab === 'all' ? 'active' : ''}
      >
        All
      </S.TabItem>
      <S.TabItem
        onClick={() => setTab('invites')}
        className={tab === 'invites' ? 'active' : ''}
      >
        Invites
      </S.TabItem>
    </S.Tabs>
  );
};

export default Tabs;
