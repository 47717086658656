import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import players from '../../assets/imgs/players.png';
import { color } from '../../styles/styleVariables';
import Button from '../uielements/Button/Button';

interface IJoinClubBoxProps {
  onClose?: () => void;
}

const JoinClubBox: React.FunctionComponent<IJoinClubBoxProps> = (props) => {
  const { onClose } = props;
  const setJoinClubIncative = () => {
    localStorage.setItem(
      'vault_preferences',
      JSON.stringify({ clubPopupHide: true })
    );
    onClose?.call(null);
  };
  return (
    <BoxWrapper data-testid='join-club-box'>
      <div className='image'>
        <img
          style={{
            position: 'absolute',
            top: '-80px',
            left: '-75px',
          }}
          src={players}
          alt=''
        />
      </div>
      <div className='text'>
        <h2 className='text-gradient'>You are not part of a Club yet! </h2>
        <p>
          Click on the button below and search for your current club. If you do
          not have a club yet, you can still request to be part of one.
        </p>
        <Link to='/my-club'>
          <Button>FIND A CLUB</Button>
        </Link>
        <div
          onClick={() => setJoinClubIncative()}
          onKeyDown={() => setJoinClubIncative()}
          role='button'
          tabIndex={0}
          aria-label='Add Roster'
          style={{ marginTop: '20px', fontWeight: 300, cursor: 'pointer' }}
        >
          No, Thanks. I will do it later.
        </div>
      </div>
    </BoxWrapper>
  );
};

const BoxWrapper = styled.div`
  border-radius: 10px;
  max-width: 600px;
  min-height: 267px;
  background-color: ${color.greyLight};
  padding: 30px;
  display: flex;
  box-shadow: 0 0 11px 0px #00000082;
  z-index: 10;
  .image {
    width: 40%;
    position: relative;
    @media (max-width: 600px) {
      display: none;
    }
  }
  .text {
    width: 60%;
    text-align: center;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

export default JoinClubBox;
