export interface Team {
  id: number;
  clubId: number;
  name: string;
  rank: number;
  level: number;
  city: string;
  country: string;
  logo: string;
  players: {
    idPlayer: number;
    avatar: string;
    permalink: string;
  }[];
}

export interface Club {
  id: string;
  name: string;
  rank: number;
  level: number;
  city: string;
  country: string;
  logo: string;
  members: {
    id: string;
    name: string;
    photo: string;
  }[];
}

export enum ClubActionTypes {
  FETCH_REQUEST = '@@club/FETCH_REQUEST',
  FETCH_SUCCESS = '@@club/FETCH_SUCCESS',
  FETCH_ERROR = '@@club/FETCH_ERROR',
}

export interface ClubState {
  readonly loading: boolean;
  readonly data: {
    clubs: Club[];
    teams: Team[];
    primaryTeam: Team | undefined;
  };
}
