import * as React from 'react';
import { ModalBodyProps } from './types';

import * as S from './styles';
import * as C from './components';

const ModalBody: React.FC<ModalBodyProps> = ({ user, onClose, setUserIds }) => {
  return (
    <S.Container>
      <C.Header user={user} />
      <C.UpgradeButton user={user} />
      <C.Permissions user={user} />
      <S.HR />
      <C.Documents user={user} />
      <S.HR />
      <C.Clubs user={user} />
      <S.HR />
      <C.Actions user={user} onClose={onClose} setUserIds={setUserIds} />
    </S.Container>
  );
};

export default ModalBody;
