import styled from 'styled-components';
import { Modal } from 'antd';
import { RequestStatus } from 'models/Request/RequestModel';

import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 468px;
    border-radius: 24px;
    background-color: ${COLORS.grey900};
  }

  .ant-modal-header {
    border-radius: 24px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 24px;
    padding: 6px 12px 6px 12px;
  }
`;

export const Container = styled.div`
  width: 100%;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${toRem(10)};
  justify-content: space-between;
  border-bottom: ${toRem(1)} solid ${COLORS.grey50};
`;

export const CloseWrapper = styled.div`
  cursor: pointer;
  padding: ${toRem(5)};
`;

export const StatusWrapper = styled.div`
  margin-top: ${toRem(16)};
  margin-bottom: ${toRem(16)};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const statusColorBg = (status: RequestStatus) => {
  if (status === 'APPROVED') {
    return COLORS.brandPrimary;
  }

  if (status === 'DECLINED') {
    return COLORS.supportError;
  }

  return COLORS.grey200;
};

export const Status =
  styled.div <
  { status: RequestStatus } >
  `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${toRem(4)} ${toRem(16)};
  border-radius: ${toRem(36)};

  background-color: ${({ status }) => statusColorBg(status)};
`;

export const DocumentImage =
  styled.div <
  { isPhoto: boolean } >
  `
  width: ${({ isPhoto }) => (isPhoto ? toRem(200) : '100%')};
  height: ${({ isPhoto }) => (isPhoto ? toRem(200) : toRem(187))};
  border-radius: ${({ isPhoto }) => (isPhoto ? toRem(100) : toRem(8))};

  margin: 0 auto;
  overflow: hidden;

  background-color: ${COLORS.grey50};

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${({ isPhoto }) => (isPhoto ? toRem(200) : '100%')};
    height: ${({ isPhoto }) => (isPhoto ? toRem(200) : toRem(187))};
  }
`;
