import * as React from 'react';
import { useState } from 'react';
import { Socket } from 'socket.io-client';

export const SocketContext = React.createContext<any>(undefined);

const SocketProvider: React.FC<React.ReactNode> = (props) => {
  const { children } = props;

  const [socket, setSocket] = useState<Socket | undefined>(undefined);
  socket?.on('connection', (err: any) => {
    console.log('CONNECTED');
  });

  const socketVal = { socket, setSocket };
  return (
    <SocketContext.Provider value={socketVal}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
