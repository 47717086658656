export enum ClubDashboardActionTypes {
  SET_SELECTED_MEMBER_POOL_USER = '@@clubDashboard/SET_SELECTED_MEMBER_POOL_USER',
  TOGGLE_SELECTED_MEMBER_POOL_USER = '@@clubDashboard/TOGGLE_SELECTED_MEMBER_POOL_USER',
}

export interface ClubDashboardState {
    readonly clubDirector: {
        memberPool: {
            selectedUserIds: string[]
        }
    }
}