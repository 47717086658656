import { action } from 'typesafe-actions';
import { AccountActionTypes, Account, AccountBrief } from './types';

export const accountFetchRequest = () =>
  action(AccountActionTypes.FETCH_REQUEST);

export const accountFetchSuccess = (data: Account) =>
  action(AccountActionTypes.FETCH_SUCCESS, data);

export const accountFetchError = (message: string) =>
  action(AccountActionTypes.FETCH_ERROR, message);

export const accountFetchAccounts = () =>
  action(AccountActionTypes.FETCH_ACCOUNTS);

export const accountSetAccounts = (data: AccountBrief[]) =>
  action(AccountActionTypes.SET_ACCOUNTS, data);

export const accountSetUser = (data: Account) =>
  action(AccountActionTypes.SET_USER, data);
