import React, { FC } from 'react';
import { Filter as FilterIcon } from '@icon-park/react';

import InputText from 'components/v3/Forms/InputText/InputText';

import { BodyMBold } from 'styles/v3/variables';

import { FiltersProps } from './types';

import * as S from './styles';

export const Filters: FC<FiltersProps> = ({ handleChange }) => {
  return (
    <S.Container>
      <S.FilterWrapper>
        <BodyMBold $isUpper $color='grey400'>
          Filters
        </BodyMBold>
        <FilterIcon className='btn-filter-modal' />
      </S.FilterWrapper>
      <InputText
        onChange={(e) => handleChange(e.target.value)}
        id='search'
        placeholder='Search'
        icon='Search'
      />
    </S.Container>
  );
};
