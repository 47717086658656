import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  HeadsetOne,
  SoccerOne,
  CalendarDot,
  PreviewOpen,
} from '@icon-park/react';
import ConvertUtil from 'util/ConvertUtil';
import { BodyM, COLORS } from 'styles/v3/variables';
import { SimplesBreadCrumbWithIcon } from 'components/v3/SimpleBreadCrumbWithIcon';
import IconButton from 'components/v3/Buttons/IconButton';
import { CoachRosterItem, PlayerRosterItem } from 'models/Roster/RosterModel';
import {
  Container,
  ContainerInformationDivision,
  AvatarEventInformation,
  EventImage,
  RosterTitle,
  EventInformation,
  CalendarInformation,
  DateInfo,
  DateTextBold,
  DateInline,
} from './styles';

interface RosterDivisionProps {
  id: string;
  eventLogo: string;
  eventName: string;
  gender: string;
  year: string;
  isCoach: boolean;
  isPlayer: boolean;
  startDate: string;
  endDate: string;
  players: PlayerRosterItem[];
  coaches: CoachRosterItem[];
}

export const RosterDivision = ({
  id,
  eventLogo,
  eventName,
  gender,
  year,
  isCoach,
  isPlayer,
  startDate,
  endDate,
  players,
  coaches,
}: RosterDivisionProps) => {
  const history = useHistory();

  const handleEditRoster = async () => {
    history.push(`/v3/clubs/${id}/dashboard/roster`);
  };

  const getBorderColor = () => {
    if (isCoach) {
      return COLORS.brandSecondary;
    }
    if (isPlayer) {
      return COLORS.brandPrimary;
    }
    return 'unset';
  };

  const borderColorA = getBorderColor();

  return (
    <>
      <Container style={{ borderColor: borderColorA }}>
        <ContainerInformationDivision>
          <AvatarEventInformation>
            <RosterTitle>
              {gender} - {year}
            </RosterTitle>
            <EventInformation>
              <EventImage>
                <img
                  src={ConvertUtil.getMinioUrl(eventLogo)}
                  alt='Event Logo'
                />
              </EventImage>
              <BodyM>{eventName}</BodyM>
            </EventInformation>
          </AvatarEventInformation>
          {isCoach ? (
            <SimplesBreadCrumbWithIcon
              icon={<HeadsetOne size={20} />}
              textInformation='YOU ARE A COACH'
              bgColor='brandSecondary'
              textColor='white'
            />
          ) : (
            isPlayer && (
              <SimplesBreadCrumbWithIcon
                icon={<SoccerOne fill={COLORS.grey950} size={20} />}
                textInformation='YOU ARE A PLAYER'
              />
            )
          )}
          <CalendarInformation>
            <CalendarDot fill={COLORS.grey700} size={25} />
            <DateInfo>
              <DateInline>
                <DateTextBold>STARTS </DateTextBold>
                {startDate}
              </DateInline>
              <DateInline>
                <DateTextBold>FINISHES</DateTextBold>
                {endDate}
              </DateInline>
            </DateInfo>
          </CalendarInformation>
          <IconButton
            icon={<PreviewOpen size={24} fill={COLORS.grey200} />}
            style={{ backgroundColor: COLORS.grey700 }}
            onClick={() => handleEditRoster()}
          />
        </ContainerInformationDivision>
      </Container>
    </>
  );
};
