import * as React from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { Country, State } from 'country-state-city';
import { message as MessageSnackbar } from 'antd';

import InputText from 'components/v3/Forms/InputText/InputText';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';

import { PatchBaseUser } from 'redux/v3/baseUsers/types';

import { useProfile } from 'hooks/v3/profile/useProfile';
import { Gender } from 'admin/models/Enums';
import UserServices from 'services/v3/User/UserService';

import { BodyXL } from 'styles/v3/variables';

import CompleteUserTemplate from '../Template/Template';
import * as S from '../Template/styles';

type Fields = {
  line1: { value: string; error: boolean; required: boolean };
  line2: { value: string; error: boolean; required: boolean };
  city: { value: string; error: boolean; required: boolean };
  state: { value: string; error: boolean; required: boolean };
  zipCode: { value: string; error: boolean; required: boolean };
  country: { value: string; error: boolean; required: boolean };
};

const Address = () => {
  const history = useHistory();
  const params: { userId: string } = useParams();
  const formRef = React.useRef<HTMLFormElement>(null);

  const {
    currentUser,
    isBaseUser,
    isAnAdult,
    updateCurrentAccount,
  } = useProfile();
  const userType = currentUser.type ?? '';
  const guardianData = isBaseUser ? currentUser.guardian?.address : currentUser.guardian1?.address
  const addressData = isAnAdult ? currentUser.address : guardianData;

  const stateFields = React.useMemo<Fields>(() => {
    return {
      line1: {
        value: addressData?.line1 ?? '',
        error: false,
        required: true,
      },
      line2: {
        value: addressData?.line2 ?? '',
        error: false,
        required: false,
      },
      city: {
        value: addressData?.city ?? '',
        error: false,
        required: true,
      },
      state: {
        value: addressData?.state ?? '',
        error: false,
        required: true,
      },
      zipCode: {
        value: addressData?.zipCode ?? '',
        error: false,
        required: true,
      },
      country: {
        value: addressData?.country ?? '',
        error: false,
        required: true,
      },
    };
  }, [currentUser]);

  const [fields, setFields] = React.useState<Fields>(stateFields);
  const [validating, setValidating] = React.useState<boolean>(false);
  const [countryOptions, setCountryOptions] = React.useState<OptionsType[]>([]);
  const [statesOptions, setStatesOptions] = React.useState<OptionsType[]>([]);

  const setLabelToCountry = countryOptions.find(
    (s) => s.value === stateFields.country.value
  );

  const handleChangeSelect = async (field: keyof Fields, e: any) => {
      setFields({
        ...fields,
        [field]: {
          ...fields[field],
          value: e.value,
          error: false,
        },
      });
      setValidating(false);
  };

  const handleInputClear = (field: keyof Fields) => {
    setFields({
      ...fields,
      [field]: { ...fields[field], value: '' },
    });
    setValidating(false);
  };

  const handleChangeInputValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof Fields
  ) => {
    const { name, value } = e.target;

    setFields({
      ...fields,
      [name]: {
        required: stateFields[field]?.required ?? true,
        error: false,
        value,
      },
    });
    setValidating(false);
  };

  const validate = () => {
    setValidating(true);
    for (const [key, values] of Object.entries(fields)) {
      if (values?.value === '' && values?.required) {
        setFields((newFields) => ({
          ...newFields,
          [key]: { value: values.value, error: true },
        }));
      }
    }
  };

  const saveUser = async () => {
    if (formRef.current) {
      const formData: FormData = new FormData(formRef.current);
      const payloadAddress = {
        line1: formData.get('line1') as string,
        line2: formData.get('line2') as string,
        city: formData.get('city') as string,
        state: formData.get('state') as string,
        zipCode: formData.get('zipCode') as string,
        country: formData.get('country') as string,
      };
      const payload: PatchBaseUser = {
        firstName: currentUser.firstName as string,
        lastName: currentUser.lastName as string,
        phoneNumber: currentUser.phoneNumber as string,
        birthDate: currentUser.birthDate as string,
        gender: currentUser.gender as Gender,
        ...(isAnAdult
          ? { address: payloadAddress }
          : {
              [isBaseUser ? 'guardian' : 'guardian1']: {
                ...currentUser.guardian,
                address: payloadAddress,
              },
            }),
      };

      await UserServices.updateUser(
        `${currentUser.id}`,
        payload,
        isBaseUser,
        userType
      )
        .then(async () => {
          updateCurrentAccount();
          history.push(`/v3/user/${params.userId}/documents`);
        })
        .catch((error) => {
          setValidating(false);
          if (error instanceof Error) {
            MessageSnackbar.error({ content: error.message, duration: 5 });
          }
        });
    }
  };

  React.useEffect(() => {
    const allCountries = Country.getAllCountries().map((i) => ({
      label: `${i.name} ${i.flag}`,
      value: i.isoCode,
    }));
    setCountryOptions(allCountries);
  }, []);

  React.useEffect(() => {
    if (validating) {
      const getFields = Object.values(fields);
      const hasError = getFields.find((i) => i?.error);

      if (!hasError) {
        saveUser();
      } else {
        setValidating(false);
      }
    }
  }, [validating, fields]);

  React.useEffect(() => {
    const countryCode = fields.country.value;
    if (countryCode) {
      const statesOfCountry = State.getStatesOfCountry(countryCode).map(
        (i) => ({
          label: i.name,
          value: i.isoCode,
        })
      );
      setStatesOptions(statesOfCountry);
    }
  }, [fields.country.value, currentUser]);

  const stateDefaultOptions = React.useMemo(() => {
    const countryCode = stateFields.country.value;
    if (countryCode) {
      const statesOfCountry = State.getStatesOfCountry(countryCode).map(
        (i) => ({
          label: i.name,
          value: i.isoCode,
        })
      );
      return statesOfCountry;
    }
    return []
  }, [currentUser, fields.country.value, stateFields.country.value ])


  const setLabelToState = React.useMemo(() => {
    return stateDefaultOptions.find(
      (s) => s.value === stateFields.state.value
    )
  }, [stateDefaultOptions, currentUser, countryOptions])

  const valueButton = () => {
    if (validating) {
      return 'Saving';
    }

    if (currentUser) {
      return 'Update';
    }

    return 'Continue';
  };

  return (
    <CompleteUserTemplate user={currentUser as any}>
      <S.UserForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
        <fieldset>
          <legend>
            <BodyXL $color='brandPrimary' $isUpper>
              Address {currentUser && 'Edit'}
            </BodyXL>
          </legend>

          <InputText
            id='line1'
            placeholder='Address line 1'
            label='Address line 1'
            required
            key={`line1_${currentUser?.id}`}
            defaultValue={stateFields.line1.value}
            onClearInput={(id) => handleInputClear(id)}
            error={fields.line1.error}
            onChange={(e) => handleChangeInputValue(e, 'line1')}
            containerClassName='user-info-input'
          />
          <InputText
            id='line2'
            placeholder='Address line 2'
            label='Address line 2'
            key={`line2_${currentUser?.id}`}
            defaultValue={stateFields.line2.value}
            onClearInput={(id) => handleInputClear(id)}
            onChange={(e) => handleChangeInputValue(e, 'line2')}
            containerClassName='user-info-input'
          />
        </fieldset>

        <S.FormFieldset>
          {countryOptions.length > 0 && (
            <Select
              name='country'
              placeholder='Country'
              required
              isSearchable
              options={countryOptions}
              key={`country_${currentUser?.id}`}
              defaultValue={setLabelToCountry}
              onChange={(e) => handleChangeSelect('country', e)}
              error={fields.country.error}
              className='user-info-input'
            />
          )}
          {statesOptions.length > 0 && (
            <Select
              name='state'
              placeholder='State'
              required
              isSearchable
              options={statesOptions}
              key={`state_${currentUser?.id}`}
              defaultValue={setLabelToState}
              onChange={(e) => handleChangeSelect('state', e)}
              error={fields.state.error}
              className='user-info-input'
            />
          )}
        </S.FormFieldset>

        <S.FormFieldset>
          <InputText
            id='city'
            placeholder='City'
            label='City'
            required
            key={`city_${currentUser?.id}`}
            defaultValue={stateFields.city.value}
            onClearInput={(id) => handleInputClear(id)}
            error={fields.city.error}
            onChange={(e) => handleChangeInputValue(e, 'city')}
            containerClassName='user-info-input'
          />
          <InputText
            id='zipCode'
            placeholder='Zipcode'
            label='Zipcode'
            required
            key={`zipCode_${currentUser?.id}`}
            defaultValue={stateFields.zipCode.value}
            onClearInput={(id) => handleInputClear(id)}
            error={fields.zipCode.error}
            onChange={(e) => handleChangeInputValue(e, 'zipCode')}
            containerClassName='user-info-input'
          />
        </S.FormFieldset>

        <FilledButtonWithIcon onClick={validate} isUpper>
          {valueButton()}
        </FilledButtonWithIcon>
      </S.UserForm>
    </CompleteUserTemplate>
  );
};

export default withRouter(Address);
