import { GenderOptionValues } from './enums';

export const GENDER_OPTIONS = [
  { label: 'Gender', value: GenderOptionValues.All },
  { label: 'Female', value: GenderOptionValues.Female },
  { label: 'Male', value: GenderOptionValues.Male },
  { label: 'Coed', value: GenderOptionValues.Coed },
];

export const EMPTY_STATE_MESSAGES = [
  {
    type: GenderOptionValues.All,
    text: 'There is no divisions for this event.',
  },
  {
    type: GenderOptionValues.Female,
    text: 'There is no FEMALE division for this event.',
  },
  {
    type: GenderOptionValues.Male,
    text: 'There is no MALE division for this event.',
  },
  {
    type: GenderOptionValues.Coed,
    text: 'There is no COED division for this event.',
  },
];
