import React from 'react';
import { BodyLBold, BodyM, BodyS } from 'styles/v3/variables';
import { DocumentsProps } from './types';

import * as S from './styles';
import { DocumentItem } from './components/DocumentItem/DocumentItem';

export const Documents = ({ user }: DocumentsProps) => {
  const isAgeProofRequired = user.type === 'PLAYER';
  const isSafeSportRequired = ['COACH', 'CLUB_DIRECTOR', 'REFEREE'].includes(
    user.type || 'PLAYER'
  );

  return (
    <S.Container>
      <BodyLBold className='title'>Documents</BodyLBold>
      <S.DocumentList>
        <DocumentItem title='Photo' status={user.photoVerified} isRequired />
        <DocumentItem
          title='Proof Of Age'
          status={user.ageVerified}
          isRequired={isAgeProofRequired}
        />
        <DocumentItem
          title='SafeSport'
          status={user.documentationVerified}
          isRequired={isSafeSportRequired}
        />
      </S.DocumentList>
    </S.Container>
  );
};
