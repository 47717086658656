import { Col, Row } from 'antd';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import ClubCard from '../../components/ClubCard/ClubCard';
import MemberPool from '../../components/ClubDirector/MemberPool/MemberPool';
import Button from '../../components/uielements/Button/Button';
import usePrimaryClub from '../../hooks/usePrimaryClub/usePrimaryClub';
import { memberpoolFetchRequest } from '../../redux/memberpool/actions';
import { membershipsFetchRequest } from '../../redux/memberships/actions';
import { ApplicationState } from '../../redux/store';
import { userUpdate } from '../../redux/user/actions';
import PrivateRoute from '../../routes/PrivateRoute';
import JoinRequests from './JoinRequests';
import Rosters from './Rosters';
import RosterEditPage from './Rosters/RosterEditPage';

interface IClubDirectorProps {
  auth: Keycloak.KeycloakInstance | undefined;
}

const ClubDirector: React.FunctionComponent<IClubDirectorProps> = (props) => {
  const dispatch = useDispatch();
  const primaryClub = usePrimaryClub();
  const user = useSelector((state: ApplicationState) => state.user);
  const clubs = useSelector((state: ApplicationState) => state.club);

  useEffect(() => {
    dispatch(membershipsFetchRequest());
    if (primaryClub?.id) {
      dispatch(memberpoolFetchRequest(primaryClub?.id));
    }
  }, [dispatch, primaryClub]);

  const updatePrimaryClub = (event: React.FormEvent<HTMLSelectElement>) => {
    dispatch(
      userUpdate({
        ...user.data,
        playerInfo: {
          ...user.data.playerInfo,
          primaryClubId: event.currentTarget.value,
        },
      })
    );
  };
  const { auth } = props;
  return (
    <div style={{ padding: '20px 20px' }}>
      {/* {!primaryClub && (
        <div style={{ textAlign: 'center' }}>
          <h3>
            You are not assigned to any Club yet.
            <br /> Contact the club to request it.
          </h3>
        </div>
      )}
      {primaryClub && ( */}
      <Row>
        <Col md={24} lg={6} style={{ paddingRight: '30px' }}>
          <div>
            <h3>Select Club</h3>
            <div className='form-group'>
              <select value={primaryClub?.id} onChange={updatePrimaryClub}>
                {clubs.data.clubs.map((club) => (
                  <option key={club.id} value={club.id}>
                    {club.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <MenuContainer>
            <NavLink to='/club-director' exact activeClassName='active'>
              <Button>My Club</Button>
            </NavLink>
            <NavLink to='/club-director/join-requests' activeClassName='active'>
              <Button>Requests</Button>
            </NavLink>
            <NavLink to='/club-director/member-pool' activeClassName='active'>
              <Button>Member Pool</Button>
            </NavLink>
            <NavLink to='/club-director/rosters' activeClassName='active'>
              <Button>My Rosters</Button>
            </NavLink>
          </MenuContainer>
        </Col>
        <Col md={24} lg={18} style={{ padding: '10px' }}>
          <PrivateRoute
            auth={auth}
            exact
            path='/club-director'
            render={() => (
              <Row>
                <Col span={18}>
                  <h2>My Clubs</h2>
                  <ClubCard />
                </Col>
              </Row>
            )}
          />
          <PrivateRoute
            auth={auth}
            exact
            path='/club-director/join-requests'
            render={() => <JoinRequests />}
          />

          <PrivateRoute
            auth={auth}
            exact
            path='/club-director/member-pool'
            render={() => (
              <Row>
                <h2>Member Pool</h2>
                <Col span={24}>
                  <div>
                    <MemberPool />
                  </div>
                </Col>
              </Row>
            )}
          />

          <PrivateRoute
            auth={auth}
            exact
            path='/club-director/rosters'
            render={() => (
              <Row>
                <h2>My Rosters</h2>
                <Col span={24}>
                  <div>
                    <Rosters />
                  </div>
                </Col>
              </Row>
            )}
          />

          <PrivateRoute
            auth={auth}
            exact
            path='/club-director/rosters/edit/:rosterId'
            render={() => <RosterEditPage />}
          />
        </Col>
      </Row>
      {/* )} */}
    </div>
  );
};

const MenuContainer = styled.div`
  @media (max-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    //justify-content: space-around;
  }
`;

export default ClubDirector;
