import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowRight } from '@icon-park/react';

import { b2bSetupEventFetchEventRequest } from 'redux/v3/b2bSetupEvent/actions';
import {
  clearDivisions,
  resetSignUpDivisionQty,
} from 'redux/v3/signupEvent/actions';
import { ApplicationState } from 'redux/store';
import { UserType } from 'redux/user/types';
import OrderService from 'services/v3/Order/OrderService';
import { CheckoutProps } from 'services/v3/Order/types';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { notification } from 'components/v3/Notification/notification';

import { useGetMyClubsByUserType } from 'hooks/v3/clubs/useGetMyClubsByUserType/useGetMyClubsByUserType';
import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';
import { useGetProductById } from 'hooks/v3/products/useGetProductById/useGetProductById';
import useGetRosterFee from 'hooks/v3/products/useGetRosterFee/useGetRosterFee';
import { useGetEventDivisions } from 'hooks/v3/event/useGetEventDivisons/useGetEventDivisons';

import { TitleH2 } from 'styles/v3/variables';

import { DivisionInfo } from './components/DivisionInfo/DivisionInfo';
import { CheckoutHeader } from './components/CheckoutHeader/CheckoutHeader';
import { CheckoutGeneralInfo } from './components/CheckoutGeneralInfo/CheckoutHeader';
import { CheckoutBox } from './components/CheckoutBox/CheckoutBox';
import { getDivisionInfo } from './utils';

import * as S from './styles';
import { useGetClubApplyRoleAndPath } from './useGetClubApplyRoleAndPath';
import { getOrderedDivisionDetails } from './components/DivisionInfo/utils';

const CheckoutAsClubDirector: FC = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const dispatch = useDispatch();
  const history = useHistory();

  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const { data: currentUser } = useSelector(
    (state: ApplicationState) => state.currentUser
  );

  const { data: orderDivisionsResponse } = useGetEventDivisions({ eventId });

  const userRoleAndPath = useGetClubApplyRoleAndPath();

  const { data: currentEvent } = useGetEventById(eventId);
  const { data: rosterFeeUF } = useGetRosterFee();

  const { data: rosterFeeProduct } = useGetProductById(
    currentEvent?.data?.paymentInfo?.rosterFeeProductId
  );

  const { data: myClubs } = useGetMyClubsByUserType(userRoleAndPath.role);

  const { divisions, currentDivisions, clubId, coupon } = useSelector(
    (state: ApplicationState) => state.signupEvent.data
  );

  const isUFEvent = !!eventData?.UFEvent;

  const isStaff =
    currentUser?.type === UserType.STAFF ||
    currentUser?.types?.includes(UserType.STAFF);

  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const ageDivisions = useMemo(() => {
    return currentDivisions.filter((div) => !!div.targetNumberOfTeams) ?? [];
  }, [currentDivisions]);

  const myClub = useMemo(() => {
    if (!myClubs || !clubId) return null;

    return myClubs?.find((club) => club.id === clubId) || null;
  }, [myClubs, clubId]);

  const price = useMemo(() => {
    if (isUFEvent) {
      let defaultRosterFee = rosterFeeUF?.relatedPrices.find(
        (feeUF) => feeUF.id === eventData.data?.paymentInfo?.rosterFeePriceId
      )?.unitAmount;

      if (!defaultRosterFee) {
        defaultRosterFee = rosterFeeUF?.product.price ?? 129500;
      }

      return defaultRosterFee / 100;
    }
    return rosterFeeProduct?.formattedPrice ?? 0;
  }, [isUFEvent, rosterFeeUF, eventData, rosterFeeProduct]);

  const handleBack = () => {
    history.push(
      `/v3/event/${eventId}/${userRoleAndPath.path.replace(
        'checkout',
        'signup'
      )}`
    );
  };

  const divisionInfoData = useMemo(() => {
    return ageDivisions.reduce<{ years: number[], genders: string[] }>(
      (cur, acc) => {
        acc.years.forEach((y) => {
          if (!cur.years.includes(y)) {
            cur.years.push(y);
          }
        });
        if (!cur.genders.includes(acc.gender)) {
          cur.genders.push(acc.gender);
        }
        return cur;
      },
      {
        years: [],
        genders: [],
      }
    );
  }, [ageDivisions]);

  const orderDivisionDetails = useMemo(() => {
    if (
      !orderDivisionsResponse ||
      !Array.isArray(orderDivisionsResponse.content)
    ) {
      return [];
    }

    return orderDivisionsResponse.content.flatMap(
      ({ ageDivisions, orderDetailed }) => {
        return getOrderedDivisionDetails(ageDivisions, orderDetailed);
      }
    );
  }, [orderDivisionsResponse]);

  const handleCheckout = async () => {
    if (rosterFeeProduct) {
      let cartItem = {
        productId: rosterFeeProduct.id || '',
        quantity: divisions.reduce((cur, acc) => {
          return cur + acc.quantity;
        }, 0),
        type: 'EVENT_ROSTER_FEE',
        b2bEventRosterFee: {
          divisions: divisions.map((division) => ({
            id: division.divisionId,
            name: division.name,
            description: getDivisionInfo(
              ageDivisions.find((d) => d.id === division.divisionId)
            ),
            quantity: division.quantity,
            itemPrice: rosterFeeProduct.formattedPrice,
            total: division.quantity * rosterFeeProduct.formattedPrice,
          })),
          eventId: currentEvent?.id || '',
          total: divisions.reduce((cur, acc) => {
            return cur + acc.quantity * rosterFeeProduct.formattedPrice;
          }, 0),
          club: {
            name: myClub?.name || '',
            id: myClub?.id || '',
            logo: myClub?.logo || '',
          },
        },
      };

      const type = isUFEvent ? 'UF_EVENT_ROSTER_FEE' : 'EVENT_ROSTER_FEE';
      const priceId =
        eventData.data?.paymentInfo?.rosterFeePriceId ??
        rosterFeeUF?.product?.stripePriceId ??
        '';

      if (isUFEvent && priceId) {
        cartItem = Object.assign(cartItem, { priceId });
      }

      const checkoutDto: CheckoutProps = {
        cart: [cartItem],
        type,
        cancelUrl: `/v3/event/${currentEvent?.id}/${userRoleAndPath.path}`,
        couponId: coupon?.id || '',
      };

      setIsLoadingPayment(true);

      OrderService.getCheckoutStripeUrl(checkoutDto)
        .then((response) => {
          dispatch(resetSignUpDivisionQty());

          if (isUFEvent && isStaff) {
            history.push('/v3/events?tab=created-by-me');

            notification.success({
              message: 'The payment was completed successfully',
            });
          } else {
            window.location.href = response.data.paymentUrl;
          }
        })
        .catch((error) => {
          notification.error({
            message:
              error.message ||
              'Error on checkout check event information and try again',
          });
        })
        .finally(() => {
          setIsLoadingPayment(false);
        });
    }
  };

  useEffect(() => {
    dispatch(b2bSetupEventFetchEventRequest(eventId));

    return () => {
      dispatch(clearDivisions());
      dispatch(resetSignUpDivisionQty());
    };
  }, [eventId]);

  return (
    <S.CheckoutAsClubDirectorContainer>
      <S.DivisionWrapper>
        <S.Header>
          <TextButtonWithIcon
            color='primary'
            align='flex-end'
            reverse
            icon='back'
            onClick={handleBack}
          >
            Go back
          </TextButtonWithIcon>
          <TitleH2>SELECT YOUR AGE DIVISIONS</TitleH2>
        </S.Header>
        <DivisionInfo
          ageDivisions={ageDivisions}
          years={divisionInfoData.years}
          genders={divisionInfoData.genders}
          price={price}
          divisions={divisions}
          orderDivisionDetails={orderDivisionDetails}
        />
      </S.DivisionWrapper>
      <S.CheckoutWrapper>
        {currentEvent && (
          <>
            <CheckoutHeader
              image={currentEvent.logo}
              name={currentEvent.name}
            />
            <CheckoutGeneralInfo event={currentEvent} />
            <CheckoutBox
              price={price}
              divisions={divisions}
              event={currentEvent}
            />
            <FilledButton
              className='btn-checkout'
              disabled={divisions.length === 0 || isLoadingPayment}
              onClick={handleCheckout}
              color='primary'
              isLoading={isLoadingPayment}
              isUpper
            >
              Continue To Payment <ArrowRight /> {isLoadingPayment && '...'}
            </FilledButton>
          </>
        )}
      </S.CheckoutWrapper>
    </S.CheckoutAsClubDirectorContainer>
  );
};

export default withRouter(CheckoutAsClubDirector);
