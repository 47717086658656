import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { Mail } from '@icon-park/react';
import { useHistory, useParams } from 'react-router-dom';
import moment, { Moment } from 'moment';

import { Address } from 'models/User/AddressModel';
import { User } from 'models/User/UserModel';
import { EventInvitesTypes } from 'models/v3/EventInvite/EventInvitesModel';
import UserType from 'models/User/UserTypeModel';
import { IFilteredUserParameters } from 'services/v3/User/types';

import { useInviteByEvent } from 'hooks/v3/event/useInviteByEvent/useInviteByEvent';
import { useGetAdvancedFilters } from 'hooks/v3/users/useGetAdvancedFilters/useGetAdvancedFilters';
import { useGetUsersByFilters } from 'hooks/v3/users/useGetUsersByFilters/useGetUsersByFilters';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import InputText from 'components/v3/Forms/InputText/InputText';
import FilledButton from 'components/v3/Buttons/FilledButton';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import { notification } from 'components/v3/Notification/notification';

import { BodyM, BodyMBold, TitleH2, TitleH4 } from 'styles/v3/variables';

import { formatUserName } from 'util/formatUserName';

import { Card, SimpleCard } from '../../../components';
import { IAdvancedFilterOptions } from '../interfaces';

import * as S from './styles';

export const InviteByList = () => {
  const { eventId } = useParams<{ eventId?: string }>();

  const history = useHistory();

  const timeoutRef = useRef<number>(0);

  const [
    selectedAdvancedFilters,
    setSelectedAdvancedFilters,
  ] = useState<IFilteredUserParameters>({
    userType: UserType.REFEREE,
  });

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const { advancedFilters } = useGetAdvancedFilters();
  const { mutateAsync: inviteReferee } = useInviteByEvent();
  const { users, isUsersLoading, fetchUsersByFilters } = useGetUsersByFilters();

  const modifyFiltersToOptions = useCallback(
    (items?: string[]) =>
      items?.map((item) => ({
        label: item,
        value: item,
      })) || [],
    []
  );

  const advancedFilterOptions: IAdvancedFilterOptions = useMemo(
    () => ({
      birthYears: modifyFiltersToOptions(advancedFilters?.birthYears),
      cities: modifyFiltersToOptions(advancedFilters?.cities),
    }),
    [advancedFilters, modifyFiltersToOptions]
  );

  const handleSearchReferee = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const targetValue =
        typeof event.target?.value === 'string' ? event.target?.value : '';

      setSelectedAdvancedFilters((prevState) => ({
        ...prevState,
        name: targetValue,
      }));
    },
    []
  );

  const handleChangeMultiSelect = (
    type: keyof IFilteredUserParameters,
    value: MultiValue<OptionsType> | SingleValue<OptionsType>
  ) => {
    if (Array.isArray(value)) {
      setSelectedAdvancedFilters((prevState) => ({
        ...prevState,
        [type]: value.map((v) => v.value),
      }));
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleRemoveCard = useCallback(
    (userId: string) => {
      setSelectedUsers(selectedUsers?.filter((user) => user.id !== userId));
    },
    [selectedUsers]
  );

  const handleCardSelect = useCallback(
    (userId: string) => {
      const existedSelectedUser = selectedUsers.find(
        (user) => user.id === userId
      );

      if (existedSelectedUser) {
        handleRemoveCard(existedSelectedUser.id);

        return;
      }

      const findUser = users?.find((user) => user.id === userId);

      if (findUser) {
        setSelectedUsers((prevState) => [...prevState, findUser]);
      }
    },
    [users, selectedUsers, handleRemoveCard]
  );

  const handleSendInvites = useCallback(async () => {
    const refereesIds = selectedUsers.map((user) => user.id);

    if (!refereesIds.length) {
      notification.error({
        message: 'An error occurs!',
        description:
          'Please select at least one referee to send the invitation to',
      });

      return;
    }

    if (eventId) {
      await inviteReferee({
        eventId,
        receiversIds: refereesIds,
        type: EventInvitesTypes.EVENT_TO_REFEREE,
      });

      history.push(`/v3/event/${eventId}/dashboard`);
    }
  }, [selectedUsers, eventId, history, inviteReferee]);

  const getReadableAddress = useCallback((address?: Address) => {
    if (address) {
      return `${address.city} - ${address.state}`;
    }

    return '';
  }, []);

  const getReadableBirthday = useCallback((birthDate?: string | Moment) => {
    const date = moment(birthDate);

    return date.format('YYYY');
  }, []);

  useEffect(() => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = window.setTimeout(() => {
      fetchUsersByFilters(selectedAdvancedFilters);
    }, 100);

    return (() => {
      clearTimeout(timeoutRef.current);
    });
  }, [selectedAdvancedFilters]);

  return (
    <S.Container>
      <S.FindWrapper>
        <TextButtonWithIcon
          reverse
          icon='back'
          color='light'
          align='flex-end'
          onClick={handleGoBack}
        >
          Go back
        </TextButtonWithIcon>
        <TitleH2 $color='brandPrimary'>Find referees</TitleH2>
        <InputText
          id='search'
          icon='Search'
          value={selectedAdvancedFilters.name}
          placeholder='Search by name'
          onChange={handleSearchReferee}
        />
        <S.FilterWrapper>
          <BodyMBold $isUpper $color='grey500'>
            Filter by
          </BodyMBold>
          <Select
            isMulti
            name='city'
            placeholder='City'
            isSearchable
            options={advancedFilterOptions.cities}
            onChange={(option) => handleChangeMultiSelect('city', option)}
          />
          <Select
            isMulti
            name='birthYear'
            placeholder='Age'
            isSearchable
            options={advancedFilterOptions.birthYears}
            onChange={(option) => handleChangeMultiSelect('birthYear', option)}
          />
        </S.FilterWrapper>
        {users?.map((user) => (
          <Card
            id={user.id}
            key={user.id}
            logoSrc={user.photo}
            selected={selectedUsers.some(selectedUser => selectedUser.id === user.id)}
            address={getReadableAddress(user.address)}
            year={getReadableBirthday(user.birthDate)}
            name={formatUserName({
              firstName: user.firstName,
              middleName: user.middleName,
              lastName: user.lastName,
            })}
            onSelect={handleCardSelect}
          />
        ))}
        {!isUsersLoading && !users?.length && (
           <S.FallbackMessageWrapper>
            <TitleH4 $isUpper $color='grey700'>
              didn&apos;t find the referee you were looking for?
            </TitleH4>
            <TitleH4 $color='grey500'>Invite via email</TitleH4>
          </S.FallbackMessageWrapper>
        )}
      </S.FindWrapper>
      <S.InviteListWrapper>
        <S.InviteListMain>
          <TitleH4 $isUpper>Invite list</TitleH4>
          <BodyM $color='grey400'>
            If you invite referees that have already been invited, they will be
            notified again.
          </BodyM>
          <S.SimpleCardList>
            {selectedUsers?.map((user) => (
              <SimpleCard
                id={user.id}
                key={user.id}
                imgSrc={user.photo}
                address={getReadableAddress(user.address)}
                userType={user.type}
                name={formatUserName({
                  firstName: user.firstName,
                  middleName: user.middleName,
                  lastName: user.lastName,
                })}
                onRemoveCard={handleRemoveCard}
              />
            ))}
          </S.SimpleCardList>
          <FilledButton isUpper isBold={false} onClick={handleSendInvites}>
            Send invites
          </FilledButton>
        </S.InviteListMain>
        <S.InviteEmailWrapper>
          <BodyM $isUpper>Invite via email</BodyM>
          <Mail size={24} />
        </S.InviteEmailWrapper>
      </S.InviteListWrapper>
    </S.Container>
  );
};
