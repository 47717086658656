import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useHistory } from 'react-router-dom';

import { User } from 'models/User/UserModel';
import UserType from 'models/User/UserTypeModel';

import { DOWNLOAD_STORAGE_ENDPOINT } from 'services/config.api';

import { myClubsFetchRequest } from 'redux/club/actions';
import { ApplicationState } from 'redux/store';
import { userFetchRequest, userUpdateActive } from 'redux/user/actions';

import { useProfile } from 'hooks/v3/profile/useProfile';

import TokenUtil from 'util/TokenUtil';
import { getUserId, setUserId } from 'util/UsersUtil';

import * as S from './styles';

const MobileMenu = () => {
  const userData = useSelector((state: ApplicationState) => state.account);

  const { currentUser } = useProfile();

  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openPlayers, setOpenPlayers] = useState(false);

  useEffect(() => {
    const userId = getUserId();

    if (userId) {
      setUserId(userId);
    }
  }, []);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const toggleMenuPlayers = () => {
    setOpenPlayers(!openPlayers);
  };

  const handleClickToNewVault = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    window.location.href = `${window.location.protocol}//${window.location.host}/v3/home-page`;
  };

  const selectUser = (userId: string) => {
    const userTemp: User | undefined = userData.data.accounts?.find(
      (account) => account.id === userId
    );

    setUserId(userId);

    if (userId) {
      dispatch(userFetchRequest(userId));
    }

    if (userTemp) {
      setOpenPlayers(false);
      dispatch(userUpdateActive(userTemp));
      dispatch(myClubsFetchRequest());

      TokenUtil.refreshToken();

      history.push('/home');
    }
  };

  return (
    <S.MobileMenuWrapper
      countSubItens={
        userData.data.accounts?.length ? userData.data.accounts?.length : 0
      }
    >
      <div className={`menu-content ' ${open ? 'opened' : ''}`}>
        <ul>
          <li>
            <Link to='/home'>Home</Link>
          </li>
          {currentUser.type !== UserType.CLUB_DIRECTOR && (
            <li>
              <Link to='/my-club'>My Club</Link>
            </li>
          )}
          {currentUser.type === UserType.CLUB_DIRECTOR && (
            <li>
              <Link to='/club-director'>Club Director</Link>
            </li>
          )}
          {currentUser.type === UserType.STAFF && (
            <li>
              <Link to='/admin-panel/tournaments' className='link'>
                My Tournaments
              </Link>
            </li>
          )}
          {currentUser.type === UserType.STAFF && (
            <li>
              <Link to='/admin-panel'>Admin Panel</Link>
            </li>
          )}
          <li>
            <Link to='/ranking' className='link'>
              Ranking
            </Link>
          </li>
          <li>
            <a
              href='/v3/home-page'
              className='link'
              onClick={handleClickToNewVault}
            >
              New Vault
            </a>
          </li>
          {currentUser.type !== UserType.CLUB_DIRECTOR && (
            <li>
              <Link to='/membership-upgrade' className='text-orange'>
                Upgrade Membership
              </Link>
            </li>
          )}
          <li>
            <Link to='/v3/logout'>logout</Link>
          </li>
        </ul>
      </div>
      <div className='menu-bottom'>
        <div
          role='button'
          tabIndex={0}
          className='left-bt'
          onClick={toggleMenu}
          onKeyDown={toggleMenu}
        >
          <img alt='' src='/assets/imgs/icons/ico_menu.svg' />
        </div>
        <div
          className='right-bt'
          style={{
            backgroundImage: 'url(/assets/imgs/icons/ico_manage.svg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '30%',
          }}
        >
          <NavLink to='my-settings' activeClassName='active'>
            <div style={{ width: '100%', height: '100%' }} />
          </NavLink>
        </div>
        <div
          className={`players-list styled-scrollbar ${
            openPlayers ? 'opened' : ''
          }`}
        >
          <div
            className='item'
            onClick={() => toggleMenuPlayers()}
            onKeyDown={() => toggleMenuPlayers()}
            role='button'
            aria-label=''
            tabIndex={0}
          >
            <div className='avatar-box'>
              <div
                className='avatar'
                style={{
                  backgroundImage: `url(${DOWNLOAD_STORAGE_ENDPOINT}${currentUser.photo})`,
                }}
              />
            </div>
            <div className='name'>{currentUser.firstName}</div>
          </div>
          {userData.data?.accounts
            ?.filter((account) => account.id !== getUserId())
            .map((userItem) => (
              <div
                key={userItem.id}
                className='item'
                onClick={() => selectUser(userItem.id)}
                onKeyDown={() => selectUser(userItem.id)}
                role='button'
                aria-label=''
                tabIndex={0}
              >
                <div className='avatar-box'>
                  <div
                    className='avatar'
                    style={{
                      backgroundImage: `url(${DOWNLOAD_STORAGE_ENDPOINT}${userItem.photo})`,
                    }}
                  />
                </div>
                <div className='name'>{userItem.firstName}</div>
              </div>
            ))}
          <div
            className='item'
            onClick={() => history.push('/wizard')}
            onKeyDown={() => history.push('/wizard')}
            role='button'
            aria-label=''
            tabIndex={0}
          >
            <div className='name' style={{ width: '100%' }}>
              + ADD NEW USER
            </div>
          </div>
        </div>
      </div>
    </S.MobileMenuWrapper>
  );
};

export default MobileMenu;
