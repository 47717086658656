import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import ClubService from '../../services/Club/ClubService';
import { myClubsFetchSuccess, myClubsFetchError } from './actions';
import { ClubActionTypes } from './types';

function* handleFetch() {
  try {
    const res = yield call(ClubService.getMyClubs);
    if (!res.success) {
      yield put(myClubsFetchError(res.error));
    } else {
      const data = res.data || [];
      yield put(myClubsFetchSuccess(data));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      // yield put(myClubsFetchError(err.stack));
    } else {
      // yield put(myClubsFetchError('An unknown error occured.'));
    }
  }
}

function* watchFetchRequest() {
  yield takeEvery(ClubActionTypes.FETCH_REQUEST, handleFetch);
}

function* clubSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default clubSaga;
