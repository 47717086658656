import {
  CheckOutlined,
  CloseOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Skeleton,
  Space,
  Switch,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PageContainer from '../../../components/base/PageContainer/PageContainer';
import GroupBox from '../../../components/uielements/GroupBox/GroupBox';
import { countries, currencies } from '../../../models/LocaleList';
import MembershipsServices from '../../../services/Memberships/MembershipsServices';
import { MembershipViewDetail } from '../../models/membership/Membership';

const { Option } = Select;

const MembershipCreatePage: React.FunctionComponent = () => {
  const formRef = React.createRef<FormInstance>();
  const history = useHistory();
  const routeParams: any = useParams();

  const [loading, setLoading] = useState(false);
  const [formTitle, setFormTitle] = useState('Create');
  const [loadedObj, setLoadedObj] = useState<MembershipViewDetail>();

  useEffect(() => {
    find(routeParams.id);
  }, [routeParams.id]);

  async function find(id: string) {
    if (id) {
      setLoading(true);
      setFormTitle('Update memberships');

      await MembershipsServices.find(id)
        .then((response) => {
          if (!response.success) {
            message.error({ content: response.message, duration: 5 });
            return;
          }

          setLoadedObj(response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const onFinish = async (values: any) => {
    const key = 'request';
    message.loading({ content: 'Loading...', key });

    if (loadedObj && loadedObj.id) {
      MembershipsServices.update(loadedObj.id, values).then((response) => {
        if (!response.success) {
          message.error({ content: response.message, key, duration: 5 });
          return;
        }

        message.success({ content: 'Membership updated', key, duration: 3 });
        history.push('/admin-panel/memberships');
      });
    } else {
      MembershipsServices.create(values).then((response) => {
        if (!response.success) {
          message.error({ content: response.message, key, duration: 5 });
          return;
        }

        message.success({ content: 'Membership created', key, duration: 3 });
        history.push('/admin-panel/memberships');
      });
    }
  };

  const onReset = () => {
    formRef.current?.resetFields();
  };

  return (
    <PageContainer title='Membership'>
      <GroupBox title={formTitle}>
        <Skeleton loading={loading} active title>
          <Form
            layout='vertical'
            ref={formRef}
            name='control-ref'
            onFinish={onFinish}
            initialValues={loadedObj}
          >
            <div className='form-group'>
              <Form.Item
                name='name'
                label='Name'
                rules={[{ required: true, message: 'name required' }]}
              >
                <Input />
              </Form.Item>
            </div>

            <div className='form-group'>
              <Form.Item
                name='type'
                label='Type'
                rules={[{ required: true, message: 'Type is required' }]}
              >
                <Select placeholder='...'>
                  <Option value='COMMUNITY'>Community</Option>
                  <Option value='OFFICIAL'>Official</Option>
                  <Option value='CCS'>CCS</Option>
                </Select>
              </Form.Item>
            </div>

            <div className='form-group'>
              <Form.Item
                name='country'
                label='Country'
                rules={[{ required: true, message: 'Country is required' }]}
              >
                <Select placeholder='...'>
                  {countries.map((item) => (
                    <Select.Option key={item.code} value={item.code}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className='form-group'>
              <Form.Item
                name='currency'
                label='Currency'
                rules={[{ required: true, message: 'Currency is required' }]}
              >
                <Select placeholder='...'>
                  {currencies.map((item) => (
                    <Select.Option key={item.code} value={item.code}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className='form-group'>
              <Form.Item
                name='price'
                label='Price'
                rules={[{ required: true, message: 'Price is required' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) =>
                    !value ? '' : value.replace(/\$\s?|(,*)/g, '')
                  }
                />
              </Form.Item>
            </div>

            <div className='form-group'>
              <Form.Item
                name='userType'
                label='User type'
                rules={[{ required: true, message: 'User type is required' }]}
              >
                <Select placeholder='...'>
                  <Option value='SUPPORTER'>Supporter</Option>
                  <Option value='PLAYER'>Player</Option>
                  <Option value='COACH'>Coach</Option>
                  <Option value='REFEREE'>Referee</Option>
                  <Option value='CLUB_DIRECTOR'>Club director</Option>
                  <Option value='TEAM_MANAGER'>Team manager</Option>
                  <Option value='STAFF'>Staff</Option>
                </Select>
              </Form.Item>
            </div>

            <div className='form-group'>
              <Form.List name='documents'>
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field) => (
                        <Space
                          key={field.key}
                          style={{ display: 'flex', marginBottom: 8 }}
                          align='start'
                        >
                          <Form.Item
                            name={[field.name, 'name']}
                            fieldKey={[field.fieldKey, 'name']}
                            rules={[
                              { required: true, message: 'Name is required' },
                            ]}
                          >
                            <Input placeholder='Name' />
                          </Form.Item>

                          <Form.Item
                            name={[field.name, 'fileType']}
                            fieldKey={[field.fieldKey, 'fileType']}
                            rules={[
                              { required: true, message: 'Type is required' },
                            ]}
                          >
                            <Select placeholder='Type'>
                              <Option value='image'>Image</Option>
                              <Option value='pdf'>PDF</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item
                            name={[field.name, 'required']}
                            fieldKey={[field.fieldKey, 'required']}
                          >
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                            />
                          </Form.Item>

                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Space>
                      ))}

                      <Form.Item>
                        <Button
                          type='dashed'
                          onClick={() => {
                            add();
                          }}
                          block
                        >
                          <PlusOutlined /> Add documents
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </div>

            <div className='form-group'>
              <Form.List name='additionalInfo'>
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field) => (
                        <Space
                          key={field.key}
                          style={{ display: 'flex', marginBottom: 8 }}
                          align='start'
                        >
                          <Form.Item
                            rules={[
                              { required: true, message: 'Info is required' },
                            ]}
                          >
                            <Input placeholder='Additional info' />
                          </Form.Item>

                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Space>
                      ))}

                      <Form.Item>
                        <Button
                          type='dashed'
                          onClick={() => {
                            add();
                          }}
                          block
                        >
                          <PlusOutlined /> Add additional info
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </div>

            <div className='form-group'>
              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Save
                </Button>
                <Button htmlType='button' onClick={onReset}>
                  Reset
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Skeleton>
      </GroupBox>
    </PageContainer>
  );
};

export default MembershipCreatePage;
