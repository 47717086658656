import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { GameSource } from 'models/v3/Games/game-source.enum';

import { EventSideMenu } from 'components/base/EventSideMenu';
import { OptionsType } from 'components/v3/Forms/Select/Select';

import { useGetEventDivisionsOrdered } from 'hooks/v3/event/useGetEventDivisonsOrdered';
import { useDebounce } from 'hooks/useDebounce/useDebounce';

import { TableContent } from './components/TableContent/TableContent';
import { useGetPaginatedMatches } from './hooks/useGetPaginatedMatches';

import { gameStatus } from './config';
import { StyledWrapper } from './styles';

const INIT_PAGE = 1;
const INIT_SIZE = 10;
const INIT_DIVISION_OPTION = { value: '', label: 'Select Age Division' };

export const Games = () => {
  const { eventId } = useParams<{ eventId: string }>();

  // ENG-145 TODO - make filtering on the b/e side
  const [statusId, setStatusId] = useState<string>(gameStatus[0].id);
  const [source, setSource] = useState<GameSource>(GameSource.POOL);
  const [currentPage, setCurrentPage] = useState<number>(INIT_PAGE);
  const [selectedDivision, setSelectedDivision] = useState<OptionsType | undefined>(INIT_DIVISION_OPTION);
  const [searchText, setSearchText] = useState<string>('');

  const debouncedSearchText = useDebounce<string>(searchText, 300);

  const { data: divisionsByEvent, isDivisionListFetched } = useGetEventDivisionsOrdered({ eventId });

  const { data: matches } = useGetPaginatedMatches(eventId, {
    filters: {
      pageSize: INIT_SIZE,
      page: currentPage,
      name: debouncedSearchText,
    },
    payload: {
      ageDivisionId: selectedDivision?.value
    }
  });

  const divisionsAsOptions = useMemo(() => { 
    const divisions = isDivisionListFetched && divisionsByEvent?.ageDivisions ? divisionsByEvent?.ageDivisions : [];

    return divisions.map((division, index) => ({label: division.name || `division ${index}`, value: division.id}));
  }, [divisionsByEvent, isDivisionListFetched]);

  const status = useMemo(() => {
    return gameStatus.find((status) => status.id === statusId)?.title;
  }, [statusId]);

  const type = gameStatus.reduce((acc, game) => {
    if (game.title === 'All games') {
      return { ...acc, [game.title]: '' };
    }
    return { ...acc, [game.title]: game.title.toUpperCase() };
  }, {} as { [key: string]: string });

  const handleChangePage = useCallback((pageToFetch: number) => {
    setCurrentPage(pageToFetch);
  }, [setCurrentPage]);

  const handleFilterByDivision = useCallback((selected) => {
    setSelectedDivision(selected);
  }, [setSelectedDivision]);

  const handleSearchChange = useCallback((searchText: string) => {
    setSearchText(searchText);
  }, []);

  const handleFiltersReset = useCallback(() => {
    setSelectedDivision(INIT_DIVISION_OPTION);
  }, [])

  return (
    <StyledWrapper>
      <EventSideMenu
        hideGoBack
        items={gameStatus}
        selectedId={statusId}
        subtitle='Game Status'
        onSelection={setStatusId}
        title='Games'
      />
      {status && (
        <TableContent
          source={source}
          eventId={eventId}
          type={type[status]}
          total={matches?.total}
          current={currentPage}
          matches={matches?.content ?? []}
          divisions={divisionsAsOptions}
          selectedDivision={selectedDivision}
          onSourceChange={setSource}
          onPageChange={handleChangePage}
          onSearchChange={handleSearchChange}
          onFilterByDivision={handleFilterByDivision}
          onFiltersReset={handleFiltersReset}
        />
      )}
    </StyledWrapper>
  );
};
