import { action } from 'typesafe-actions';
import { DSConnection } from '../../models/DSConnection/DSConnection';
import { dsConnectionActionTypes } from './types';

export const dsConnectionFetchRequest = (id: string) =>
  action(dsConnectionActionTypes.FETCH_REQUEST, id);

export const dsConnectionFetchSuccess = (data: DSConnection) =>
  action(dsConnectionActionTypes.FETCH_SUCCESS, data);

export const dsConnectionFetchError = (message: string) =>
  action(dsConnectionActionTypes.FETCH_ERROR, message);

export const dsConnectionUpdate = (data: DSConnection) =>
  action(dsConnectionActionTypes.UPDATE, data);
