import * as React from 'react';
import { CloseSmall } from '@icon-park/react';

import ClickOutsideHandle from '../ClickOutsideHandle/ClickOutsideHandle';

import * as S from './styles';
import { BodyM } from 'styles/v3/variables';

import colors from './colors.json';

interface IColorPickerProps {
  onSelectColor: (color: string) => void;
  selectedColor?: string;
  className?: string;
}

const ColorPicker: React.FunctionComponent<IColorPickerProps> = ({
  onSelectColor,
  className,
  selectedColor,
  children,
}) => {
  const [display, setDisplay] = React.useState<boolean>(false);
  const [localColor, setLocalColor] = React.useState<string>(
    selectedColor ?? ''
  );

  React.useEffect(() => {
    if (localColor) onSelectColor(localColor);
  }, [localColor]);
  React.useEffect(() => {
    if (selectedColor) setLocalColor(selectedColor);
  }, [selectedColor]);

  return (
    <ClickOutsideHandle
      className={className}
      onClickOutside={() => setDisplay(false)}
    >
      <S.ColorPickeContainer>
        <div onClick={() => setDisplay(true)}>{children}</div>

        <S.ColorPickerWrapper className={display ? 'show' : ''}>
          <S.ColorPickerHeader>
            <BodyM>Select a color</BodyM>
            <CloseSmall size={24} onClick={() => setDisplay(false)} />
          </S.ColorPickerHeader>
          <S.ColorPickerGrid>
            {colors.map((color, index) => (
              <S.ColorElement
                key={`${color}-${index}`}
                color={color}
                className={`color ${color === localColor ? 'active' : ''}`}
                onClick={() => setLocalColor(color)}
              />
            ))}
          </S.ColorPickerGrid>
        </S.ColorPickerWrapper>
      </S.ColorPickeContainer>
    </ClickOutsideHandle>
  );
};

export default ColorPicker;
