import { useQuery } from '@tanstack/react-query';
import EventService from 'services/v3/Event/EventService';

type UseGetEventDivisionsProps = {
  eventId: string,
};

export const useGetEventDivisions = ({
  eventId,
}: UseGetEventDivisionsProps) => {
  const response = useQuery(['event-divisions', eventId], () =>
    EventService.getClubsAndDivisionsPaidForEvent(eventId)
  );

  return {
    ...response,
    data: response.data?.data,
  };
};
