import React, { useState } from 'react';

import FilledButton from 'components/v3/Buttons/FilledButton';
import { useParams } from 'react-router-dom';
import { notification } from 'components/v3/Notification/notification';
import OrderService from 'services/v3/Order/OrderService';
import { UpgradeButtonProps } from './types';

import * as S from './styles';

export const UpgradeButton = ({ user }: UpgradeButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const params: { clubId: string } = useParams();

  const validUserTypesForOfficialMembership = ['PLAYER', 'COACH'];
  const upgradable =
    (user?.membership?.type === 'OFFICIAL' ||
      !validUserTypesForOfficialMembership.includes(user?.type || 'PLAYER')) ===
    false;

  const handleCheckout = async () => {
    if (!upgradable || !user) return;

    setIsLoading(true);

    OrderService.getMembershipCheckoutStripeUrl({
      userIds: [user.id],
      cancelUrl: `/v3/clubs/${params.clubId}/dashboard?checkout=error`,
      successUrl: `/v3/clubs/${params.clubId}/dashboard?checkout=success`,
    })
      .then((res) => {
        if (res.httpCode === 200) {
          window.location.href = res.data.paymentUrl;
        } else {
          notification.error({
            duration: 3,
            message: 'Error',
            description:
              res.message ||
              'Error on checkout check selected users and try again',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      {upgradable && (
        <S.ButtonWrapper>
          <FilledButton disabled={isLoading} onClick={handleCheckout}>
            {isLoading ? 'Processing...' : `UPGRADE MEMBERSHIP`}
          </FilledButton>
        </S.ButtonWrapper>
      )}
    </>
  );
};
