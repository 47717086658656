import styled from 'styled-components';

import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 380px;
  width: 100%;
  padding: ${toRem(24)};
  background-color: ${COLORS.grey900};
  border-radius: ${toRem(12)};
  margin-left: ${toRem(32)};

  @media screen and (max-width: ${BREAKPOINTS.tablet}) {
    max-width: none;
    margin-left: 0;
    margin-top: 26px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    margin-top: 0;
  }
`;

export const UserXPContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(4)};
  padding-top: 32px;

  div {
    &:nth-child(2) {
      min-width: 330px;
      max-width: 358px;
    }
  }
`;

export const UserXPInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UserLevelInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RolesList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

export const RoleItem = styled.div`
  padding: 5px 15px;
  border-radius: 24px;
  border: 1px solid ${COLORS.grey800};
  text-transform: uppercase;
  color: ${COLORS.white};
  font-family: 'Boxed Regular';
  font-size: 14px;
`;
