import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { countries } from '../../../models/LocaleList';

const LocationBox: React.FunctionComponent<{
  city?: string;
  country?: string;
}> = (props) => {
  const { city, country } = props;
  return (
    <LocationBoxWrapper>
      {city && country && (
        <CSSTransition
          timeout={3500}
          appear
          enter
          in
          classNames='transition_fade'
        >
          <div style={{ display: 'flex' }}>
            <div className='icon-location' />
            {`${city}, ${countries.find((e) => e.code === country)?.name}`}
          </div>
        </CSSTransition>
      )}
    </LocationBoxWrapper>
  );
};

const LocationBoxWrapper = styled.div`
  font-size: 12px;
  font-weight: 700;
  display: flex;
  text-transform: uppercase;
  margin: 5px 0;
  padding: 0 10px;
  span {
    color: $orange;
  }
  .icon-location {
    height: 17px;
    width: 30px;
    background-image: url('/assets/imgs/icons/ico_location.svg');
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 5px;
  }
`;

export default LocationBox;
