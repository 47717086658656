import { DeepPartial } from 'util/types';
import { BaseUser } from '../baseUsers/types';

export enum CurrentUserActionTypes {
  SET_CURRENT_USER = '@@currentUser/SET_CURRENT_USER',
  RESET_CURRENT_USER = '@@currentUser/RESET_CURRENT_USER',
}

export interface CurrentUserState {
  readonly data: DeepPartial<BaseUser>;
}
