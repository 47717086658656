import { Tooltip } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { RosterListing } from '../../../models/Roster/RosterModel';
import EventService from '../../../services/Event/EventService';
import ConfirmBox from '../../base/ConfirmBox/ConfirmBox';

interface RosterApprovalButtonsProps {
  active?: boolean;
  actionsEnabled?: boolean;
  roster: RosterListing;
  reloadList?: () => void;
  openRosterDetails: (rosterId: string) => void;
  notSubmitted?: boolean;
}

const RosterApprovalButtons: React.FunctionComponent<RosterApprovalButtonsProps> = (
  props
) => {
  const { roster, actionsEnabled, reloadList, notSubmitted } = props;

  const [confirmPaymentOpen, setConfirmPaymentOpen] = useState(false);
  const [confirmApprovalOpen, setConfirmApprovalOpen] = useState(false);
  const [confirmDeclineOpen, setConfirmDeclineOpen] = useState(false);

  const approvePayment = () => {
    setConfirmPaymentOpen(true);
  };

  const approveRosterPayment = () => {
    EventService.approveRosterPayment(roster.id).then((res) => {
      reloadList?.call(null);
      setConfirmPaymentOpen(false);
    });
  };

  const approveConfirmation = () => {
    setConfirmApprovalOpen(true);
  };

  const approveRoster = () => {
    EventService.approveRoster(roster.id).then((res) => {
      reloadList?.call(null);
      setConfirmApprovalOpen(false);
    });
  };

  const rejectRoster = (reason?: string) => {
    EventService.declineRoster(roster.id, reason || '').then((res) => {
      if (res.success) {
        reloadList?.call(null);
        setConfirmDeclineOpen(false);
      }
    });
  };

  return (
    <WrapperBox>
      <div className='actions'>
        {actionsEnabled && !notSubmitted && (
          <>
            {roster.eventParticipationStatus === 'PENDING' && (
              <>
                <Tooltip placement='topRight' title='Approve Roster'>
                  <div
                    className='bt-action i-icon-check'
                    onClick={approveConfirmation}
                    onKeyDown={approveConfirmation}
                    role='button'
                    aria-label='Approve Team'
                    tabIndex={0}
                  />
                </Tooltip>
              </>
            )}
            {roster.eventParticipationStatus === 'WAITING_PAYMENT' && (
              <>
                <Tooltip placement='topRight' title='Confirm Roster Payment'>
                  <div
                    className='bt-action i-icon-check'
                    onClick={approvePayment}
                    onKeyDown={approvePayment}
                    role='button'
                    aria-label='Approve Team'
                    tabIndex={0}
                  />
                </Tooltip>
              </>
            )}
            <Tooltip placement='topRight' title='Reject Roster'>
              <div
                className='bt-action i-icon-trash'
                onClick={() => setConfirmDeclineOpen(true)}
                onKeyDown={() => setConfirmDeclineOpen(true)}
                role='button'
                aria-label='Reject Team'
                tabIndex={0}
              />
            </Tooltip>
          </>
        )}
      </div>

      <ConfirmBox
        isOpen={confirmApprovalOpen}
        onClose={() => setConfirmApprovalOpen(false)}
        onConfirm={approveRoster}
        onCancel={() => setConfirmApprovalOpen(false)}
        title='Are you sure you want to approve this Roster?'
        labelConfirm='Approve'
        labelCancel='Cancel'
      />
      <ConfirmBox
        isOpen={confirmPaymentOpen}
        onClose={() => setConfirmPaymentOpen(false)}
        onConfirm={approveRosterPayment}
        onCancel={() => setConfirmPaymentOpen(false)}
        title='Are you sure you want to confirm the payment from this Roster?'
        labelConfirm='Confirm'
        labelCancel='Cancel'
      />
      <ConfirmBox
        isOpen={confirmDeclineOpen}
        onClose={() => setConfirmDeclineOpen(false)}
        onConfirm={rejectRoster}
        onCancel={() => setConfirmDeclineOpen(false)}
        textArea=''
        title='Are you sure you want to decline this Roster?'
        labelConfirm='Decline'
        labelCancel='Cancel'
      />
    </WrapperBox>
  );
};

const WrapperBox = styled.div<{ active?: boolean }>`
  .actions {
    display: flex;
    text-align: center;
    margin-left: auto;
  }
`;

export default RosterApprovalButtons;
