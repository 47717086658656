import React, { memo, useEffect, useLayoutEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { ScheduleType } from 'models/Schedule/ScheduleModel';
import { MatchEventModel } from 'models/Match/MatchModel';

import { ApplicationState } from 'redux/store';
import { b2bSetupEventFetchEventRequest } from 'redux/v3/b2bSetupEvent/actions';

import { TABS_KEYS } from 'pages/V3/EventManager/tab-keys';

import { BodyLBold } from 'styles/v3/variables';

import { AlertMessageContainer, Container } from './styles';
import { CalendarCourt } from '../../types';
import Scheduler from '../Scheduler/Scheduler';

interface ScheduledWrapperProps {
  days: string[];
  activeDivision: string;
  scheduleType: ScheduleType;
  divisionColors: { [key: string]: string };
  scheduleRules: {
    minTimeBetweenGames: number,
    maxTimeBetweenGames: number,
    maxGamesPerTeam: number,
    poolGamesLength: number,
    bracketGamesLength: number,
  };
  courts: CalendarCourt[];
  matches: MatchEventModel[];
}

const ScheduledWrapper = ({
  days,
  activeDivision,
  divisionColors,
  scheduleRules,
  scheduleType,
  courts,
  matches,
}: ScheduledWrapperProps) => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dispatch = useDispatch();

  const { eventId } = useParams<{ eventId: string }>();
  const day = params.get('day');
  const tab = params.get('tab');
  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const dailyEventTimes = useMemo(() => {
    const daysFormatted = days.map((day) => moment(day).format('YYYY-MM-DD'));

    return daysFormatted.map((day) => {
      const dayItem = eventData?.dailyEventTimes?.find(
        (dayTime) => day === dayTime?.day
      );
      if (dayItem) {
        return {
          day,
          startTime: dayItem.startTime,
          endTime: dayItem.endTime,
        };
      }
      return {
        day,
        startTime: '08:00:00',
        endTime: '22:00:00',
      };
    });
  }, [eventData, days]);

  useLayoutEffect(() => {
    if (!day && tab === TABS_KEYS.SCHEDULES) {
      const path = `${location.pathname}${location.search}`;

      history.replace(`${path}&day=${moment(days[0]).format('YYYY-MM-DD')}`);
    }
  }, [day, tab, days, history, location.pathname]);

  useEffect(() => {
    dispatch(b2bSetupEventFetchEventRequest(eventId));
  }, [eventId]);

  return (
    <Container style={{ width: '100%' }}>
      {!courts.length && (
        <AlertMessageContainer>
          <BodyLBold>Please add a Court to setup the Schedule</BodyLBold>
        </AlertMessageContainer>
      )}
      {matches && dailyEventTimes && (
        <Scheduler
          days={days}
          dailyEventTimes={dailyEventTimes}
          currentDay={day}
          courts={courts}
          matches={matches}
          scheduleType={scheduleType}
          divisionId={activeDivision}
          divisionColors={divisionColors}
          scheduleRules={scheduleRules}
        />
      )}
    </Container>
  );
};

export default memo(ScheduledWrapper);
