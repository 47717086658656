import { AxiosRequestConfig } from 'axios';
import { ConfigApi } from '../config.api';
import APIServices from '../util/ApiServices';
import ServiceResponse from '../util/ServiceResponse';

class DownloadService {
  public static async download(
    fileId: string
  ): Promise<ServiceResponse<string>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/downloads?file=${fileId}`,
      method: 'GET',
    };
    return APIServices.request<string>(axiosConfig);
  }
}

export default DownloadService;
