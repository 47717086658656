import React from 'react';
import { BodyS } from 'styles/v3/variables';
import { colors } from './colors';
import * as S from './styles';

type SimpleTagProps = {
  text?: string;
  type?: 'success' | 'info' | 'error';
};

export const SimpleTag = ({ text, type = 'info' }: SimpleTagProps) => {
  const color = colors[type];

  return (
    <S.TagWrapper color={color}>
      <BodyS>{text}</BodyS>
    </S.TagWrapper>
  );
};
