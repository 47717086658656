import React, { FC, useCallback, ChangeEvent } from 'react';

import InputText from 'components/v3/Forms/InputText/InputText';
import Select from 'components/v3/Forms/Select/Select';

import { BodyMBold } from 'styles/v3/variables';

import { IFiltrationState } from '../Invites/interfaces';
import { STATUS_OPTIONS } from './configs';

import * as S from './styles';

interface IFiltersProps {
  filters: IFiltrationState;
  onSetFiltration: (filters: IFiltrationState) => void;
}

export const Filters: FC<IFiltersProps> = ({ filters, onSetFiltration }) => {
  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const targetValue =
        typeof event.target?.value === 'string' ? event.target?.value : '';

      onSetFiltration({
        search: targetValue,
      });
    },
    [onSetFiltration]
  );

  const handleStatusChange = useCallback(
    (option: any) => {
      onSetFiltration({
        status: option.value,
      });
    },
    [onSetFiltration]
  );

  return (
    <S.Container>
      <S.FilterWrapper>
        <BodyMBold $isUpper $color='grey500'>
          Filter by
        </BodyMBold>
        <Select
          placeholder='Status'
          name='status'
          options={STATUS_OPTIONS}
          defaultValue={{
            label: 'All',
            value: '',
          }}
          onChange={handleStatusChange}
        />
      </S.FilterWrapper>
      <InputText
        id='search'
        icon='Search'
        placeholder='Search'
        value={filters.search}
        onChange={handleSearch}
      />
    </S.Container>
  );
};
