import { action } from 'typesafe-actions';

import { DeepPartial } from 'util/types';
import { BaseUsersActionTypes, BaseUser, PatchBaseUser } from './types';

export const baseUserSetData = (data: BaseUser[]) =>
  action(BaseUsersActionTypes.BASE_USERS_SET_DATA, data);

export const baseUserSetUser = (user: BaseUser) =>
  action(BaseUsersActionTypes.BASE_USERS_SET_USER, user);

export const baseUserSetUserId = (id: string) =>
  action(BaseUsersActionTypes.BASE_USERS_SET_USER_ID, id);

export const baseUserUpdateData = (data: DeepPartial<PatchBaseUser>) =>
  action(BaseUsersActionTypes.BASE_USERS_UPDATE_DATA, data);
