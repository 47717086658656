import React from 'react';

import { BodyM, BodyMBold } from 'styles/v3/variables';
import { NumberToUSDollar } from 'util/CurrencyUtil';
import { DivisionItemProps } from './types';
import * as S from './styles';

export const DivisionItem = ({ division, price }: DivisionItemProps) => {
  const divisionPrice = division.quantity * price;
  return (
    <S.Container>
      <S.DivisionTitle>
        <BodyM>{division.quantity}x</BodyM>
        <BodyM>{division.name}</BodyM>
      </S.DivisionTitle>
      <BodyMBold>{NumberToUSDollar.format(divisionPrice)}</BodyMBold>
    </S.Container>
  );
};
