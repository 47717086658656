import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

interface SpinnerProps extends Omit<SpinProps, 'size'> {
  size?: number;
}

type LoadingIconProps = {
  size: number;
};

const LoadingIcon = ({ size }: LoadingIconProps) => {
  return (
    <LoadingOutlined
      spin
      style={{ fontSize: toRem(size), color: COLORS.brandPrimary }}
    />
  );
};

export const Spinner = ({ size = 80, ...rest }: SpinnerProps) => {
  return <Spin indicator={<LoadingIcon size={size} />} {...rest} />;
};
