import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import InputText from 'components/v3/Forms/InputText/InputText';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { notification } from 'components/v3/Notification/notification';

import { TitleH2 } from 'styles/v3/variables';

import * as S from './styles';

const Email = () => {
  const history = useHistory();

  const showSnackbar = () => {
    notification.info({
      duration: 3,
      message: 'Hellowwwwww',
      description: 'Helow helow hloe hloe hloe hloe hloe',
    });
    notification.error({
      duration: 3,
      message: 'Hellowwwwww',
      description: 'Helow helow hloe hloe hloe hloe hloe',
    });
    notification.success({
      duration: 3,
      message: 'Hellowwwwww',
      description: 'Helow helow hloe hloe hloe hloe hloe',
    });
  };

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <S.Container>
      <S.Wrapper>
        <S.TextWrapper>
          <TextButtonWithIcon
            color='light'
            align='flex-end'
            reverse
            icon='back'
            onClick={handleBack}
          >
            Go back
          </TextButtonWithIcon>
          <TitleH2 $color='brandPrimary'>Invite via email</TitleH2>
        </S.TextWrapper>
        <S.FormWrapper>
          <S.FieldsWrapper>
            <InputText id='email' placeholder='Email' label='Email' required />
            <InputText
              id='newEmail'
              placeholder='New Email'
              label='New Email'
              required
            />
          </S.FieldsWrapper>
          <FilledButton isUpper isBold={false} onClick={showSnackbar}>
            Send invites
          </FilledButton>
        </S.FormWrapper>
      </S.Wrapper>
    </S.Container>
  );
};

export default Email;
