import UserType from 'models/User/UserTypeModel';

export const USER_TYPES_OPTIONS = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Player',
    value: UserType.PLAYER,
  },
  {
    label: 'Coach',
    value: UserType.COACH,
  },
  {
    label: 'Club Director',
    value: UserType.CLUB_DIRECTOR,
  },
  {
    label: 'Team Manager',
    value: UserType.TEAM_MANAGER,
  },
];
