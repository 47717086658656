import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Skeleton,
} from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PageContainer from '../../../components/base/PageContainer/PageContainer';
import GroupBox from '../../../components/uielements/GroupBox/GroupBox';
import { countries, states } from '../../../models/LocaleList';
import VenueServices from '../../../services/Venue/VenueApi';
import { VenueView } from '../../models/event/Venue';

const VenueCreatePage: React.FunctionComponent = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const routeParams: any = useParams();

  const [loadingEvent, setLoadingEvent] = useState(false);
  const [formTitle, setFormTitle] = useState('Create');
  const [loadedVenue, setLoadedVenue] = useState<VenueView>();

  useEffect(() => {
    loadVenue(routeParams.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadVenue(id: string) {
    if (id) {
      setLoadingEvent(true);
      setFormTitle('update');

      await VenueServices.find(id)
        .then((response) => {
          if (!response.success) {
            message.error({ content: response.message, duration: 5 });
            return;
          }

          setLoadedVenue(response.data);
        })
        .finally(() => {
          setLoadingEvent(false);
        });
    }
  }

  const onFinish = async (values: any) => {
    const key = 'request';
    if (loadedVenue && loadedVenue.id) {
      VenueServices.update(loadedVenue.id, values).then((response) => {
        if (!response.success) {
          message.error({ content: response.message, key, duration: 5 });
          return;
        }

        message.success({ content: 'Venue updated', key, duration: 3 });
        history.push('/admin-panel/venues');
      });
    } else {
      VenueServices.create(values).then((response) => {
        if (!response.success) {
          message.error({ content: response.message, key, duration: 5 });
          return;
        }

        message.success({ content: 'Event created', key, duration: 3 });
        history.push('/admin-panel/venues');
      });
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <PageContainer title='Venues'>
      <GroupBox title={formTitle}>
        <Skeleton loading={loadingEvent} active title>
          <Form
            layout='vertical'
            form={form}
            onFinish={onFinish}
            initialValues={loadedVenue}
          >
            <div className='form-group'>
              <Form.Item
                name='name'
                label='Name'
                rules={[{ required: true, message: 'Name is required' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item name='abbreviation' label='Abbreviation'>
                <Input />
              </Form.Item>

              <Form.Item
                name={['address', 'line1']}
                label='Address Line 1'
                rules={[{ required: true, message: 'Address is required' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item name={['address', 'line2']} label='Address Line 2'>
                <Input />
              </Form.Item>

              <Form.Item
                name={['address', 'city']}
                label='City'
                rules={[{ required: true, message: 'City is required' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name={['address', 'state']}
                label='State'
                rules={[{ required: true, message: 'State is required' }]}
              >
                <Select placeholder='...'>
                  {states.map((item) => (
                    <Select.Option key={item.code} value={item.code}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name={['address', 'zipCode']}
                label='Zip code'
                rules={[{ required: true, message: 'Zip code is required' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name={['address', 'country']}
                label='Country'
                rules={[{ required: true, message: 'Country is required' }]}
              >
                <Select placeholder='...'>
                  {countries.map((item) => (
                    <Select.Option key={item.code} value={item.code}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label='Geolocation'>
                <Form.Item
                  name='latitude'
                  help='Latitude'
                  style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                  rules={[{ type: 'number', min: -100000000, max: 100000000 }]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <span
                  style={{
                    display: 'inline-block',
                    width: '24px',
                    lineHeight: '32px',
                    textAlign: 'center',
                  }}
                >
                  -
                </span>
                <Form.Item
                  name='longitude'
                  help='Longitude'
                  style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                  rules={[{ type: 'number', min: -100000000, max: 100000000 }]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Form.Item>

              <Form.Item
                label='Courts Available'
                name='availableCourt'
                rules={[
                  {
                    type: 'number',
                    min: 0,
                    max: 100,
                    message: 'Courts must be between 0 and 100',
                  },
                  { required: true, message: 'Courts is required' },
                ]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </div>

            <div className='form-group'>
              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Save
                </Button>
                <Button htmlType='button' onClick={onReset}>
                  Reset
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Skeleton>
      </GroupBox>
    </PageContainer>
  );
};

export default VenueCreatePage;
