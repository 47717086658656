import { DatePicker, message, Table, TimePicker } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import LightBox from '../../../../components/base/LightBox/LightBox';
import Button from '../../../../components/uielements/Button/Button';
import {
  MatchDetailsModel,
  MatchModel,
} from '../../../../models/Match/MatchModel';
import {
  ParticipationStatus,
  RosterListing,
} from '../../../../models/Roster/RosterModel';
import EventService from '../../../../services/Event/EventService';
import MatchService from '../../../../services/Match/MatchService';
import { color } from '../../../../styles/styleVariables';
import ConvertUtil from '../../../../util/ConvertUtil';
import { AgeDivision, SpecialDivision } from '../../../models/AgeDivision';
import { VenueView } from '../../../models/event/Venue';

const MatchCreation: React.FunctionComponent = () => {
  const columns = [
    {
      title: 'Team Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: 'Club Name',
      key: 'clubName',
      dataIndex: ['club', 'name'],
    },

    {
      title: '',
      key: 'print',
      dataIndex: 'type',

      // eslint-disable-next-line react/display-name
      render: (text: any, record: any) => (
        <>
          {record.id !== game.teamA.id && record.id !== game.teamB.id && (
            <div
              className='bt-action i-icon-add'
              style={{ marginLeft: 'auto' }}
              onClick={() => AddTeam(record.name, record.id)}
            />
          )}
        </>
      ),
    },
  ];

  const { eventId, matchId } = useParams<{
    eventId: string;
    matchId?: string;
  }>();
  const history = useHistory();
  const [game, setGame] = useState({
    teamA: {
      name: '',
      id: '',
    },
    teamB: {
      name: '',
      id: '',
    },
  });
  const [rostersList, setRostersList] = useState<RosterListing[]>([]);
  const [searchRoster, setSearchRoster] = useState('');
  const [rostersListFiltred, setRostersListFiltred] = useState<RosterListing[]>(
    []
  );
  const [venue, setVenue] = useState<VenueView[]>();
  const [selectedVenue, setSelectedVenue] = useState<VenueView>();
  const [selectedAgeDivision, setSelectedAgeDivision] = useState<string>();
  const [ageDivision, setAgeDivision] = useState<AgeDivision>();
  const [specialDivisions, setSpecialDivisions] = useState<SpecialDivision[]>(
    []
  );
  const [selectedSpecialDivision, setSelectedSpecialDivision] = useState('');

  const [matchCreatedSuccess, setMatchCreatedSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
  } = useForm<MatchModel>();

  useEffect(() => {
    if (eventId) {
      EventService.getRosters(
        eventId,
        new URLSearchParams(`status=${ParticipationStatus.APPROVED}`)
      ).then((res) => {
        if (res.success) {
          setRostersList(res.data);
        } else {
          message.error('Error to create match');
        }
      });

      EventService.detail(eventId).then((res) => {
        if (res.success) {
          setVenue(res.data.venues);
          setSpecialDivisions(res.data.specialDivisions || []);
        }
      });
    }
  }, [eventId]);

  const fillEditMatch = (match: MatchDetailsModel) => {
    setGame({
      teamA: {
        name: match.homeTeam.name,
        id: match.homeTeam.id,
      },
      teamB: {
        name: match.awayTeam.name,
        id: match.awayTeam.id,
      },
    });
  };

  useEffect(() => {
    if (matchId && venue) {
      MatchService.getDetails(matchId).then((res) => {
        if (res.success) {
          fillEditMatch(res.data);
          setValue('date', moment(res.data.date));
          setValue('time', moment(res.data.date));
          setSelectedVenue(
            venue?.filter((item) => item.id == res.data.venue.id)[0]
          );
          setValue('venueId', res.data.venue.id);
          if (res.data.subVenue) {
            setValue('subVenue.name', res.data.subVenue.name);
            setValue('subVenue.abbreviation', res.data.subVenue?.abbreviation);
          }
          if (res.data.specialDivision) {
            setSelectedAgeDivision(`special|${res.data.specialDivision}`);
          } else {
            setSelectedAgeDivision(
              ConvertUtil.convertAgeDivisionToString(res.data.ageDivision)
            );
          }
          filterRosterSearch();
        } else {
          message.error('Error to load match');
        }
      });
    }
  }, [matchId, venue]);

  useEffect(() => {
    if (selectedAgeDivision) {
      filterRosters(selectedAgeDivision);
    }
  }, [selectedAgeDivision, rostersList]);

  useEffect(() => {
    filterRosterSearch();
  }, [searchRoster]);

  const AddTeam = (teamName: string, teamId: string) => {
    if (game.teamA.id === '') {
      setGame({ ...game, teamA: { name: teamName, id: teamId } });
    } else if (game.teamB.id === '') {
      setGame({ ...game, teamB: { name: teamName, id: teamId } });
    }
  };

  const resetGameSettings = () => {
    setGame({
      teamA: {
        name: '',
        id: '',
      },
      teamB: {
        name: '',
        id: '',
      },
    });
  };

  const filterRosters = (ageDivisionString: string) => {
    setSelectedAgeDivision(ageDivisionString);
    const special = ageDivisionString.split('|');
    if (special[0] === 'special') {
      const specialRosterList = specialDivisions.find(
        (item) => item.name === special[1]
      )?.rosterList;
      setRostersListFiltred(
        rostersList.filter((roster) => specialRosterList?.includes(roster.id))
      );
      setSelectedSpecialDivision(special[1]);
      return;
    }

    setSelectedSpecialDivision('');
    const gender = ageDivisionString.substr(0, ageDivisionString.indexOf(' '));
    const rule = ageDivisionString
      .substr(ageDivisionString.indexOf(' ') + 1)
      .split(' ')
      .pop();

    const ageDivisionTemp = ageDivisionString
      .substr(ageDivisionString.indexOf(' ') + 1)
      .split(', ');
    ageDivisionTemp.pop();
    const ageDivision = ConvertUtil.arrayEquals(ageDivisionTemp, ['ADULT'])
      ? [1900]
      : ageDivisionTemp.map(Number);
    setAgeDivision({
      gender,
      years: ageDivision,
      rule: rule!,
    });

    setRostersListFiltred(
      rostersList.filter(
        (roster) =>
          roster.ageDivision.gender === gender &&
          roster.ageDivision.rule === rule &&
          ConvertUtil.arrayEquals(roster.ageDivision.years, ageDivision)
      )
    );

    setSearchRoster('');
  };

  const filterRosterSearch = () => {
    if (searchRoster) {
      setRostersListFiltred(
        rostersList.filter(
          (roster) =>
            // Filter searched field
            (roster.name
              .toLocaleLowerCase()
              .includes(searchRoster.toLocaleLowerCase()) ||
              roster.club.name
                .toLocaleLowerCase()
                .includes(searchRoster.toLocaleLowerCase())) &&
            // Filter selected age division
            (!ageDivision ||
              (roster.ageDivision.gender === ageDivision.gender &&
                roster.ageDivision.rule === ageDivision.rule &&
                ConvertUtil.arrayEquals(
                  roster.ageDivision.years,
                  ageDivision.years
                )))
        )
      );
    } else {
      setRostersListFiltred([]);
    }
  };

  const parseMatchData = (data: any) => {
    const dateMatch = data.date
      .set({
        hour: data.time?.get('hour'),
        minute: data.time?.get('minute'),
        second: data.time?.get('second'),
      })
      .format('YYYY-MM-DDTHH:mm:ss.SSSSSS');

    let olderAgeDivision: AgeDivision;
    if (!ageDivision) {
      olderAgeDivision = getOlderAgeDivision(data.homeTeamId, data.awayTeamId);
    }

    return {
      ...(matchId && { id: matchId }),
      ageDivision: ageDivision || olderAgeDivision!,
      homeTeamId: data.homeTeamId,
      awayTeamId: data.awayTeamId,
      ...(selectedSpecialDivision && {
        specialDivision: selectedSpecialDivision,
      }),
      eventId,
      date: dateMatch,
      venueId: data.venueId,
      subVenue: {
        abbreviation: '',
        name: data.subVenue || '',
      },
    };
  };

  const createMatch = (data: any) => {
    const match = parseMatchData(data);
    if (matchId) {
      MatchService.update({ matchId: matchId, body: match }).then((res) => {
        if (res.success) {
          history.push(`/admin-panel/tournaments/${eventId}/games`);
        } else {
          message.error(res.message);
        }
      });
    } else {
      MatchService.create(match).then((res) => {
        if (res.success) {
          setMatchCreatedSuccess(true);
          resetGameSettings();
          setValue('subVenue', {});
        } else {
          message.error(res.message);
        }
      });
    }
  };

  const onChangeVenue = (event: any) => {
    const item = venue?.filter(
      (item) => item.id == event.currentTarget.value
    )[0];
    setSelectedVenue(item);
  };

  const getOlderAgeDivision = (rosterA: string, rosterB: string) => {
    const ageDivisions = rostersList
      .filter((item) => item.id === rosterA || item.id === rosterB)
      .map((item) => item.ageDivision);

    return ageDivisions.reduce((a, b) => {
      return Math.max(...a.years) < Math.max(...b.years) ? a : b;
    });
  };

  return (
    <div>
      {matchId && <h1>Game edit</h1>}
      {!matchId && <h1>Game Creation</h1>}

      <div style={{ display: 'flex' }}>
        <div style={{ width: '60%', marginRight: '20px' }}>
          <div className='group-box'>
            <div className='form-group' style={{ marginBottom: '30px' }}>
              <div className='flex'>
                <select
                  onChange={(event) => filterRosters(event.currentTarget.value)}
                  defaultValue=''
                  value={selectedAgeDivision}
                >
                  <option value='' disabled>
                    &nbsp;&nbsp;&nbsp;Select Age Division
                  </option>
                  {rostersList
                    .map(
                      (item) =>
                        `${item.ageDivision?.gender} ${
                          item.ageDivision?.years[0] === 1900
                            ? 'ADULT'
                            : item.ageDivision?.years.sort().join(', ')
                        }, ${item.ageDivision?.rule}`
                    )
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )
                    .map((item) => (
                      <option key={item}>{item}</option>
                    ))}
                  {specialDivisions.length && (
                    <>
                      <option value='' disabled>
                        &nbsp;&nbsp;&nbsp;Special Divisions
                      </option>
                      {specialDivisions
                        .sort((a, b) =>
                          a.name.toLocaleLowerCase() >
                          b.name.toLocaleLowerCase()
                            ? 1
                            : -1
                        )
                        .map((item) => {
                          return (
                            <option value={'special|' + item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                    </>
                  )}
                </select>
              </div>
              <div className='form-group'>
                <span className='icon icon-search' />
                <input
                  name='query'
                  value={searchRoster}
                  onChange={(val) => {
                    setSearchRoster(val.target.value);
                  }}
                  type='text'
                  className='icon'
                  placeholder='Find Rosters'
                />
              </div>
            </div>

            <h3>Available Rosters</h3>
            <div style={{ marginTop: '20px' }}>
              <Table
                dataSource={rostersListFiltred}
                pagination={{ position: ['bottomCenter'] }}
                columns={columns}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
        <div style={{ width: '40%' }}>
          <div className='group-box'>
            <h3>Game Set Up</h3>
            <div style={{ marginTop: '20px' }}>
              <form onSubmit={handleSubmit(createMatch)}>
                <Game>
                  <div
                    className='bold text-center'
                    style={{ opacity: game.teamA.id ? 1 : 0.3 }}
                  >
                    {game.teamA && (
                      <div
                        className='remove'
                        onClick={() =>
                          setGame({
                            ...game,
                            teamA: {
                              name: '',
                              id: '',
                            },
                          })
                        }
                      >
                        <div className='bt-action i-icon-close' />
                      </div>
                    )}
                    {game.teamA.name ? game.teamA.name : 'Team A'}
                    <input
                      name='homeTeamId'
                      ref={register({ required: true })}
                      type='hidden'
                      value={game.teamA.id}
                    />
                  </div>
                </Game>
                <div className='text-orange'>
                  {errors.homeTeamId && 'Team A is required'}
                </div>
                <Spacer />
                <Game>
                  <div
                    className='bold text-center'
                    style={{ opacity: game.teamB.id ? 1 : 0.3 }}
                  >
                    {game.teamB && (
                      <div
                        className='remove'
                        onClick={() =>
                          setGame({
                            ...game,
                            teamB: {
                              name: '',
                              id: '',
                            },
                          })
                        }
                      >
                        <div className='bt-action i-icon-close' />
                      </div>
                    )}
                    {game.teamB.name ? game.teamB.name : 'Team B'}
                    <input
                      name='awayTeamId'
                      ref={register({ required: true })}
                      type='hidden'
                      value={game.teamB.id}
                    />
                  </div>
                </Game>
                <div className='text-orange'>
                  {errors.awayTeamId && 'Team B is required'}
                </div>

                <div style={{ marginTop: '20px' }}>
                  <div className='form-group'>
                    <div className='label'>Select Date</div>
                    <Controller
                      defaultValue=''
                      render={(properties) => <DatePicker {...properties} />}
                      id='date'
                      name='date'
                      rules={{ required: true }}
                      control={control}
                    />
                    <div className='text-orange'>
                      {errors.date && 'Date is required'}
                    </div>
                  </div>

                  <div className='form-group'>
                    <div className='label'>Select Time</div>
                    <Controller
                      render={(properties) => (
                        <TimePicker {...properties} format='HH:mm' />
                      )}
                      defaultValue=''
                      id='time'
                      name='time'
                      rules={{ required: true }}
                      control={control}
                    />
                    <div className='text-orange'>
                      {errors.time && 'Time is required'}
                    </div>
                  </div>

                  {venue?.length && (
                    <div className='form-group'>
                      <div className='label'>Select Venue</div>
                      <select
                        placeholder='Venue'
                        defaultValue=''
                        name='venueId'
                        ref={register({ required: true })}
                        onChange={onChangeVenue}
                      >
                        <option disabled value=''>
                          Select venue
                        </option>
                        {venue?.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>

                      <div className='text-orange'>
                        {errors.venueId && 'Venue is required'}
                      </div>
                    </div>
                  )}

                  {selectedVenue && (
                    <div className='form-group'>
                      <div className='label'>Select Sub-Venue</div>
                      <select
                        placeholder='Sub-Venue'
                        defaultValue={getValues('subVenue')?.name}
                        name='subVenue'
                        ref={register({ required: true })}
                      >
                        <option disabled value=''>
                          Select sub-venue
                        </option>
                        {selectedVenue?.subVenues?.map((subvenue) => {
                          return (
                            <option key={subvenue.name} value={subvenue.name}>
                              {subvenue.name} ({subvenue.abbreviation})
                            </option>
                          );
                        })}
                      </select>
                      <div className='text-orange'>
                        {errors.subVenue && 'Sub venue is required'}
                      </div>
                    </div>
                  )}

                  {matchId && (
                    <Button onClick={() => handleSubmit(createMatch)}>
                      Save edited Game
                    </Button>
                  )}
                  {!matchId && (
                    <Button onClick={() => handleSubmit(createMatch)}>
                      Create Game
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <LightBox
        timeout={3000}
        isOpen={matchCreatedSuccess}
        onClose={() => setMatchCreatedSuccess(false)}
      >
        {/* TODO: Handle with dispatch error */}

        <h3>Match created!</h3>
      </LightBox>
    </div>
  );
};

const Game = styled.div`
  padding: 20px;
  border-radius: 20px;
  border: dashed 1px #fff;
  position: relative;
  .remove {
    position: absolute;
    top: 5px;
    right: 0px;
    transform: scale(0.6);
  }
`;
const Spacer = styled.div`
  width: 1px;
  margin: 10px auto;
  height: 20px;
  background-color: ${color.silverLight};
`;

export default MatchCreation;
