import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  GameAction,
  GamePeriod,
} from '../../../../models/GameEvent/GameEventModel';
import { matchDetailsRemoveGameEventRequest } from '../../../../redux/matchDetails/actions';
import { ApplicationState } from '../../../../redux/store';
import { color } from '../../../../styles/styleVariables';
import GameFactItem from './components/GameFactItem';
import GameResult from './components/GameResult/GameResult';
import PenaltyResult from './components/PenaltyResult/PenaltyResult';

interface IGameFactsProps {}

const GameFacts: React.FunctionComponent<IGameFactsProps> = (props) => {
  const matchDetails = useSelector(
    (state: ApplicationState) => state.matchDetails
  );
  const dispatch = useDispatch();

  const getPlayer = (userId: string) => {
    if (matchDetails.data.match) {
      if (
        matchDetails.data.match.homeTeam.players.find((e) => e.id === userId)
      ) {
        return matchDetails.data.match.homeTeam.players.find(
          (e) => e.id === userId
        );
      }
      if (
        matchDetails.data.match.awayTeam.players.find((e) => e.id === userId)
      ) {
        return matchDetails.data.match.awayTeam.players.find(
          (e) => e.id === userId
        );
      }
    }
  };

  const getCoach = (userId: string) => {
    if (matchDetails.data.match) {
      if (
        matchDetails.data.match.homeTeam.coaches.find((e) => e.id === userId)
      ) {
        return matchDetails.data.match.homeTeam.coaches.find(
          (e) => e.id === userId
        );
      }
      if (
        matchDetails.data.match.awayTeam.coaches.find((e) => e.id === userId)
      ) {
        return matchDetails.data.match.awayTeam.coaches.find(
          (e) => e.id === userId
        );
      }
    }
  };

  const isSecondYellow = (userId: string, eventId: string) => {
    return matchDetails.data
      .match!.matchEvents.filter(
        (item) =>
          item.userId === userId && item.action === GameAction.YELLOW_CARD
      )
      .findIndex((item) => item.id === eventId);
  };

  // Return all game events including failed persisted ones
  const getGameEvents = () => {
    return [
      ...(matchDetails.data.match?.matchEvents || []),
      ...(matchDetails.data.matchEventsFailed || []),
    ];
  };

  const deleteGameEvent = (eventId: string) => {
    dispatch(matchDetailsRemoveGameEventRequest(eventId));
  };
  return (
    <div>
      <div>
        {matchDetails.data.match && (
          <GameResult
            matchDetails={matchDetails.data.match}
            gamePreferences={matchDetails.preferences}
          />
        )}
      </div>
      <EventWrapper>
        {/* Penalty List */}

        {getGameEvents().filter(
          (event) => event.period === GamePeriod.PENALTY_SHOOTOUT
        ).length! > 0 && (
          <>
            <h3 className='text-center'>Penalty Shootout</h3>
            <PenaltyResult noTitle small />
            {getGameEvents()
              .filter((event) => event.period === GamePeriod.PENALTY_SHOOTOUT)
              .sort((a: any, b: any) => `${b.time}`.localeCompare(a.time))
              .map((matchEvent) => (
                <GameFactItem
                  onDelete={deleteGameEvent}
                  swapped={matchDetails.preferences.swapped || false}
                  matchEvent={matchEvent}
                  awayTeamId={matchDetails.data.match?.awayTeam.id}
                  homeTeamId={matchDetails.data.match?.homeTeam.id}
                  player={getPlayer(matchEvent.userId)!}
                  secondYellow={
                    isSecondYellow(matchEvent.userId, matchEvent.id!) > 0
                  }
                />
              ))}
          </>
        )}
        {/* END Penalty List */}
        {/* First Extra Time List */}
        {getGameEvents().filter(
          (event) => event.period === GamePeriod.SECOND_EXTRA_TIME
        ).length! > 0 && (
          <>
            <h3 className='text-center'>2nd Extra Time</h3>
            {getGameEvents()
              .filter((event) => event.period === GamePeriod.SECOND_EXTRA_TIME)
              .sort((a: any, b: any) => `${b.time}`.localeCompare(a.time))
              .map((matchEvent) => (
                <GameFactItem
                  onDelete={deleteGameEvent}
                  swapped={matchDetails.preferences.swapped || false}
                  matchEvent={matchEvent}
                  awayTeamId={matchDetails.data.match?.awayTeam.id}
                  homeTeamId={matchDetails.data.match?.homeTeam.id}
                  player={getPlayer(matchEvent.userId)}
                  coach={
                    !getPlayer(matchEvent.userId)
                      ? getCoach(matchEvent.userId)
                      : undefined
                  }
                  secondYellow={
                    isSecondYellow(matchEvent.userId, matchEvent.id!) > 0
                  }
                />
              ))}
          </>
        )}

        {/* First Extra Time List */}
        {getGameEvents().filter(
          (event) => event.period === GamePeriod.FIRST_EXTRA_TIME
        ).length! > 0 && (
          <>
            <h3 className='text-center'>1st Extra Time</h3>
            {getGameEvents()
              .filter((event) => event.period === GamePeriod.FIRST_EXTRA_TIME)
              .sort((a: any, b: any) => `${b.time}`.localeCompare(a.time))
              .map((matchEvent) => (
                <GameFactItem
                  onDelete={deleteGameEvent}
                  swapped={matchDetails.preferences.swapped || false}
                  matchEvent={matchEvent}
                  awayTeamId={matchDetails.data.match?.awayTeam.id}
                  homeTeamId={matchDetails.data.match?.homeTeam.id}
                  player={getPlayer(matchEvent.userId)}
                  coach={
                    !getPlayer(matchEvent.userId)
                      ? getCoach(matchEvent.userId)
                      : undefined
                  }
                  secondYellow={
                    isSecondYellow(matchEvent.userId, matchEvent.id!) > 0
                  }
                />
              ))}
          </>
        )}
        {/* Extra Extra Time List */}

        {/* Second Half List */}
        {getGameEvents().filter(
          (event) => event.period === GamePeriod.SECOND_HALF
        ).length! > 0 && (
          <>
            <h3 className='text-center'>Second Half</h3>
            {getGameEvents()
              .filter((event) => event.period === GamePeriod.SECOND_HALF)
              .sort((a: any, b: any) => `${b.time}`.localeCompare(a.time))
              .map((matchEvent) => (
                <GameFactItem
                  onDelete={deleteGameEvent}
                  swapped={matchDetails.preferences.swapped || false}
                  matchEvent={matchEvent}
                  awayTeamId={matchDetails.data.match?.awayTeam.id}
                  homeTeamId={matchDetails.data.match?.homeTeam.id}
                  player={getPlayer(matchEvent.userId)}
                  coach={
                    !getPlayer(matchEvent.userId)
                      ? getCoach(matchEvent.userId)
                      : undefined
                  }
                  secondYellow={
                    isSecondYellow(matchEvent.userId, matchEvent.id!) > 0
                  }
                />
              ))}
          </>
        )}
        {/* END Second Half List */}

        {/* First Half List */}
        {getGameEvents().filter(
          (event) => event.period === GamePeriod.FIRST_HALF
        ).length! > 0 && (
          <>
            <h3 className='text-center'>First Half</h3>
            {getGameEvents()
              .filter((event) => event.period === GamePeriod.FIRST_HALF)
              .sort((a: any, b: any) => `${b.time}`.localeCompare(a.time))
              .map((matchEvent) => (
                <GameFactItem
                  onDelete={deleteGameEvent}
                  swapped={matchDetails.preferences.swapped || false}
                  matchEvent={matchEvent}
                  awayTeamId={matchDetails.data.match?.awayTeam.id}
                  homeTeamId={matchDetails.data.match?.homeTeam.id}
                  player={getPlayer(matchEvent.userId)}
                  coach={
                    !getPlayer(matchEvent.userId)
                      ? getCoach(matchEvent.userId)
                      : undefined
                  }
                  secondYellow={
                    isSecondYellow(matchEvent.userId, matchEvent.id!) > 0
                  }
                />
              ))}
          </>
        )}
        {/* END First Half List */}
      </EventWrapper>
    </div>
  );
};

const EventWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  margin-top: 20px;
  h3 {
    text-transform: uppercase;
    color: ${color.orange};
    font-size: 10px;
  }
`;

export default GameFacts;
