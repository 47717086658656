import React, { useState } from 'react';
import { ArrowLeft, Filter as FilterIcon } from '@icon-park/react';

import { RequestStatus } from 'models/Request/RequestModel';
import { User } from 'models/User/UserModel';

import IconButton from 'components/v3/Buttons/IconButton';
import FilledButton from 'components/v3/Buttons/FilledButton';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import InputText from 'components/v3/Forms/InputText/InputText';
import { BodyL, BodyLBold, BodyMBold, COLORS } from 'styles/v3/variables';
import { ActionMeta, MultiValue, SingleValue } from 'react-select';
import { FiltersProps } from './types';

import * as S from './styles';

export const Filters = ({ handleChange, handleDetailedFilters }: FiltersProps) => {
  return (
    <S.Container>
      <S.FilterWrapper onClick={handleDetailedFilters}>
        <BodyMBold $isUpper $color='grey400'>
          Filters
        </BodyMBold>
        <FilterIcon />
      </S.FilterWrapper>
      <InputText
        onChange={(e) => handleChange(e.target.value)}
        id='search'
        placeholder='Search'
        icon='Search'
      />
    </S.Container>
  );
};

interface FiltersDetailedProps {
  handleGoBack: () => void;
  usersToFilter: User[];
  filterDetailed: {
    value: string;
    label: string;
}[];
  setFilterDetailed: React.Dispatch<React.SetStateAction<{
    value: string;
    label: string;
}[]>>
}

export const FiltersDetailed = ({
  handleGoBack,
  usersToFilter,
  filterDetailed,
  setFilterDetailed,
}: FiltersDetailedProps) => {

  const handleSelected = (
    newValue: SingleValue<OptionsType> | MultiValue<OptionsType>,
    actionMeta: ActionMeta<OptionsType>
  ) => {
    if (!newValue) return;
    if ('value' in newValue) {

        const updateStatus = filterDetailed.map((val) => {
          if (val.value.includes('Gender') && newValue.value.toString().includes('Gender')) {
            return {
              value: `${newValue.value} Gender`,
              label: newValue.label,
            }
          }

          if (val.value.includes('Type') && newValue.value.toString().includes('Type')) {
            return {
              value: `${newValue.value} Type`,
              label: newValue.label,
            }
          }

          if (val.value.includes('Year') && newValue.value.toString().includes('Year')) {
            return {
              value: `${newValue.value} Year`,
              label: newValue.label,
            }
          }

          if (val.value.includes('Status') && newValue.value.toString().includes('Status')) {
            return {
              value: `${newValue.value} Status`,
              label: newValue.label,
            }
          }
          return val;
        })

        setFilterDetailed(updateStatus);
    }
  };

  const ages = usersToFilter.map((user) => {
    const usersYear = new Date(user.birthDate as string).getFullYear();

    return {
      value: `${usersYear.toString()} Year`,
      label: usersYear.toString(),
    }
  }).filter((val, index, self) => index === self.findIndex((t) => t.value === val.value && t.label === val.label));

  return (
    <S.ContainerForFiltersDetailed>
      <S.GoBackAndAllSelecteds>
        <IconButton
          icon={<ArrowLeft size={24} fill={COLORS.grey50} />}
          style={{ backgroundColor: 'transparent' }}
          onClick={handleGoBack}
        />
        <BodyL $color='brandPrimary' $isUpper style={{ marginLeft: 16 }}>
          {' '}
          Filters{' '}
        </BodyL>
      </S.GoBackAndAllSelecteds>
      <S.Filters>
        <BodyLBold $color='white'>Description</BodyLBold>
        <fieldset>
          <Select
            placeholder='Gender'
            name='genderPlayers'
            key='key_for_genders'
            defaultValue={filterDetailed[0]}
            options={[
              { value: 'Gender', label: 'Genders'},
              { value: 'MALE Gender', label: 'MALE' },
              { value: 'FEMALE Gender', label: 'FEMALE' },
              { value: 'OTHER Gender', label: 'OTHER' }
            ]}
            onChange={handleSelected}
          />
        </fieldset>
        <fieldset>
          <Select
            placeholder='Age'
            name='agePlayers'
            key='key_for_age'
            defaultValue={filterDetailed[2]}
            options={[
              { value: 'Year', label: 'All Years'},
              ...ages
            ]}
            onChange={handleSelected}
          />
        </fieldset>

        <BodyLBold $color='white'>Managment</BodyLBold>
        <fieldset>
          <Select
            placeholder='Membership Type'
            name='typePlayers'
            key='key_for_type'
            defaultValue={filterDetailed[1]}
            options={[
              { value: 'Type', label: 'Types'},
              { value: 'OFFICIAL Type', label: 'OFFICIAL' },
              { value: 'COMMUNITY Type', label: 'COMMUNITY' },
            ]}
            onChange={handleSelected}
          />
        </fieldset>
        <fieldset>
          <Select
            placeholder='Document Status'
            name='documentsPlayers'
            key='key_for_docs'
            defaultValue={filterDetailed[3]}
            options={[
              { value: 'Status', label: 'All Status'},
              { value: `${RequestStatus.APPROVED} Status`, label: RequestStatus.APPROVED },
              { value: `${RequestStatus.PENDING} Status`, label: RequestStatus.PENDING },
            ]}
            onChange={handleSelected}
          />
        </fieldset>

        <FilledButton isUpper isBold={false} onClick={handleGoBack}>
          Apply
        </FilledButton>
      </S.Filters>
    </S.ContainerForFiltersDetailed>
  );
};
