import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import RosterService from '../../services/Rosters/RosterService';
import { rosterFetchError, rosterFetchSuccess, rosterUpdate } from './actions';
import { RosterActionTypes } from './types';

function* handleFetch(action: any) {
  try {
    const res = yield call(RosterService.getRoster, action.payload);
    if (!res.success) {
      yield put(rosterFetchError(res.error));
    } else {
      yield put(rosterFetchSuccess(res.data));
    }
  } catch (err) {
    // console.log(err);
  }
}

function* handleAddPlayer(action: any) {
  try {
    const res = yield call(RosterService.addPlayer, {
      rosterId: action.payload.rosterId,
      playerId: action.payload.playerId,
    });
    if (!res.success) {
      yield put(rosterFetchError(res.message));
    } else {
      yield put(rosterUpdate(res.data));
    }
  } catch (err) {
    // console.log(err);
  }
}

function* handleRemovePlayer(action: any) {
  try {
    const res = yield call(RosterService.removePlayer, {
      rosterId: action.payload.rosterId,
      playerId: action.payload.playerId,
    });
    if (!res.success) {
      yield put(rosterFetchError(res.message));
    } else {
      yield put(rosterUpdate(res.data));
    }
  } catch (err) {
    // console.log(err);
  }
}

function* watchFetchRequest() {
  yield takeEvery(RosterActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchAddPlayer() {
  yield takeEvery(RosterActionTypes.ADD_PLAYER, handleAddPlayer);
}

function* watchRemovePlayer() {
  yield takeEvery(RosterActionTypes.REMOVE_PLAYER, handleRemovePlayer);
}

function* rosterSaga() {
  yield all([
    fork(watchFetchRequest),
    fork(watchAddPlayer),
    fork(watchRemovePlayer),
  ]);
}

export default rosterSaga;
