import { useQuery } from '@tanstack/react-query';
import OrderService from 'services/v3/Order/OrderService';

export function useGetOrderById(id?: string) {
  const { data, isLoading, error, refetch } = useQuery(
    ['/get-order-by-id', id],
    async () => await OrderService.getOrderById(id)
  );

  return {
    httpCode: data?.httpCode,
    ...data,
    data: data?.data ?? undefined,
    isLoading,
    error,
    refetch,
  };
}
