import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import MatchEventService from '../../services/GameEvent/GameEventService';
import MatchService from '../../services/Match/MatchService';
import {
  matchDetailsAddGameEventError,
  matchDetailsAddGameEventSuccess,
  matchDetailsFetchError,
  matchDetailsFetchRequest,
  matchDetailsFetchSuccess,
  matchDetailsRemoveGameEventError,
  matchDetailsRemoveGameEventSuccess,
  matchDetailsResendGameEventError,
  matchDetailsResendGameEventSuccess,
  matchDetailsResendRemoveGameEventError,
  matchDetailsResendRemoveGameEventSuccess,
} from './actions';
import { MatchDetailsActionTypes } from './types';

function* handleFetch(action: any) {
  try {
    const res = yield call(MatchService.getDetails, action.payload);
    if (!res.success) {
      yield put(matchDetailsFetchError(res.error));
    } else {
      const data = res.data || [];
      yield put(matchDetailsFetchSuccess(data));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      // yield put(myClubsFetchError(err.stack));
    } else {
      // yield put(myClubsFetchError('An unknown error occured.'));
    }
  }
}

function* handleAddGameEventRequest(action: any) {
  try {
    const res = yield call(MatchEventService.create, action.payload);
    if (!res.success) {
      yield put(matchDetailsAddGameEventError(action.payload));
    } else {
      const data = res.data || [];
      yield put(matchDetailsAddGameEventSuccess(data));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      // yield put(myClubsFetchError(err.stack));
    } else {
      // yield put(myClubsFetchError('An unknown error occured.'));
    }
  }
}

function* handleResendGameEventRequest(action: any) {
  try {
    const res = yield call(MatchEventService.create, action.payload);
    if (!res.success) {
      yield put(matchDetailsResendGameEventError());
    } else {
      const data = res.data || [];
      yield put(
        matchDetailsResendGameEventSuccess({
          ...data,
          localId: action.payload.id,
        })
      );
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      // yield put(myClubsFetchError(err.stack));
    } else {
      // yield put(myClubsFetchError('An unknown error occured.'));
    }
  }
}

function* handleRemoveGameEventRequest(action: any) {
  try {
    const res = yield call(MatchEventService.delete, action.payload);
    if (!res.success) {
      yield put(matchDetailsRemoveGameEventError(action.payload));
    } else {
      const data = res.data || [];
      yield put(matchDetailsRemoveGameEventSuccess(data));
    }
  } catch (err) {}
}

function* handleResendRemoveGameEventRequest(action: any) {
  try {
    const res = yield call(MatchEventService.delete, action.payload);
    if (!res.success) {
      yield put(matchDetailsResendRemoveGameEventError());
    } else {
      const data = res.data || [];
      yield put(matchDetailsResendRemoveGameEventSuccess(data));
    }
  } catch (err) {}
}

function* handleUpdateMatchDetails(action: any) {
  try {
    const res = yield call(MatchService.update, {
      matchId: action.payload.matchId,
      body: action.payload,
    });
    if (!res.success) {
      yield put(matchDetailsFetchError(res.error));
    } else {
      // if success, reload details again
      yield put(matchDetailsFetchRequest(action.payload.matchId));
    }
  } catch (err) {}
}

function* watchFetchRequest() {
  yield takeEvery(MatchDetailsActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchAddGameEventRequest() {
  yield takeEvery(
    MatchDetailsActionTypes.ADD_GAME_EVENT_REQUEST,
    handleAddGameEventRequest
  );
}

function* watchResendGameEventRequest() {
  yield takeEvery(
    MatchDetailsActionTypes.RESEND_GAME_EVENT_REQUEST,
    handleResendGameEventRequest
  );
}

function* watchRemoveGameEventRequest() {
  yield takeEvery(
    MatchDetailsActionTypes.REMOVE_GAME_EVENT_REQUEST,
    handleRemoveGameEventRequest
  );
}

function* watchResendRemoveGameEventRequest() {
  yield takeEvery(
    MatchDetailsActionTypes.RESEND_REMOVE_GAME_EVENT_REQUEST,
    handleResendRemoveGameEventRequest
  );
}

function* watchUpdateMatchDetails() {
  yield takeEvery(
    MatchDetailsActionTypes.UPDATE_MATCH_DETAILS,
    handleUpdateMatchDetails
  );
}

function* matchDetailsSaga() {
  yield all([
    fork(watchFetchRequest),
    fork(watchAddGameEventRequest),
    fork(watchRemoveGameEventRequest),
    fork(watchResendGameEventRequest),
    fork(watchResendRemoveGameEventRequest),
    fork(watchUpdateMatchDetails),
  ]);
}

export default matchDetailsSaga;
