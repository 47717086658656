import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-left: ${toRem(16)};
    font-weight: 600;
    max-width: 142px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
