import React, { useState } from 'react';
import { PeoplePlusOne, Plus } from '@icon-park/react';

import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';

import { BodyLBold } from 'styles/v3/variables';

import * as S from './styles';
import MyRosters from './components/RoastersOpen/components/RoastersForClub/MyRosters';
import AllOpenedRosters from './components/RoastersOpen/components/RoastersForClub/AllOpenedRosters';

type ExtraButtonsProps = {
  type: 'clubs' | 'referees';
};

const SUB_TAB_KEYS = {
  YOUR_ROSTERS: 'Your Rosters',
  OPEN: 'Open',
};

const ExtraButtons = ({ type }: ExtraButtonsProps) => {
  if (type === 'clubs') {
    return (
      <OutlinedButtonWithIcon isUpper customIcon={<Plus />}>
        Invite club
      </OutlinedButtonWithIcon>
    );
  }

  if (type === 'referees') {
    return (
      <OutlinedButtonWithIcon isUpper customIcon={<PeoplePlusOne />}>
        Invite referee
      </OutlinedButtonWithIcon>
    );
  }

  return null;
};

export const MemberTeamsAndRoster = () => {
  const [activeTab, setActiveTab] = useState(SUB_TAB_KEYS.YOUR_ROSTERS);

  const handleOnChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <S.Container>
      <S.Tabs
        type='card'
        destroyInactiveTabPane
        activeKey={activeTab}
        onChange={handleOnChange}
        tabBarExtraContent={<ExtraButtons type={activeTab as any} />}
      >
        <S.TabPane
          key={SUB_TAB_KEYS.YOUR_ROSTERS}
          tab={<BodyLBold>{SUB_TAB_KEYS.YOUR_ROSTERS}</BodyLBold>}
        >
          <MyRosters onlyMyResults />
        </S.TabPane>
        <S.TabPane
          key={SUB_TAB_KEYS.OPEN}
          tab={<BodyLBold>{SUB_TAB_KEYS.OPEN}</BodyLBold>}
        >
          <AllOpenedRosters onlyMyResults={false} />
        </S.TabPane>
      </S.Tabs>
    </S.Container>
  );
};