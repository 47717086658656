import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { UseMutateAsyncFunction } from '@tanstack/react-query';

import ServiceResponse from 'services/util/ServiceResponse';
import { NewAgeDivision } from 'admin/models/AgeDivision';

import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';
import { useGetEventDivisionsOrdered } from 'hooks/v3/event/useGetEventDivisonsOrdered';
import {
  MutationFnProps,
  useUpdateDivision,
} from 'hooks/v3/event/useUpdateDivision';

interface IGroupsContextType {
  eventId: string;
  poolName?: string;
  countAllTeams: number;
  teamIdentifier?: string;
  isLoadingFetching: boolean;
  isLoadingUpdateDivision: boolean;
  divisions?: NewAgeDivision[];
  selectedDivision: NewAgeDivision | null;
  setSelectedDivision: React.Dispatch<
    React.SetStateAction<NewAgeDivision | null>
  >;
  updateDivisionFn: UseMutateAsyncFunction<
    ServiceResponse<any>,
    any,
    MutationFnProps,
    unknown
  >;
};

const GroupsContext = createContext({} as IGroupsContextType);

export const useGroups = () => useContext(GroupsContext);

export const GroupsProvider: React.FC = ({ children }) => {
  const { eventId } = useParams<{ eventId: string }>();

  const { data: eventData, isLoading: isLoadingGetEvent } = useGetEventById(
    eventId
  );

  const {
    data,
    isLoading: isLoadingGetEventDivisions,
  } = useGetEventDivisionsOrdered({
    eventId,
  });

  const {
    mutateAsync,
    isLoading: isLoadingUpdateDivision,
  } = useUpdateDivision({ eventId });

  const isLoadingFetching = isLoadingGetEvent || isLoadingGetEventDivisions;

  const divisions = useMemo(() => {
    const divisionsList = data?.ageDivisions;

    return divisionsList?.filter((division) => division.format);
  }, [data]);

  const [
    selectedDivision,
    setSelectedDivision,
  ] = useState<NewAgeDivision | null>(null);

  const countAllTeams =
    selectedDivision?.pools?.reduce(
      (total, pool) => total + (pool.teams?.length || 0),
      0
    ) || 0;

  const values: IGroupsContextType = useMemo(
    () => ({
      eventId,
      divisions,
      countAllTeams,
      selectedDivision,
      isLoadingFetching,
      setSelectedDivision,
      isLoadingUpdateDivision,
      updateDivisionFn: mutateAsync,
      poolName: eventData?.data?.eventInfo?.poolName,
      teamIdentifier: eventData?.data?.eventInfo?.teamIdentifier,
    }),
    [selectedDivision, divisions, eventId, eventData]
  );

  useEffect(() => {
    if (divisions?.length && !selectedDivision) {
      setSelectedDivision(divisions[0]);
    }
  }, [divisions, selectedDivision]);

  return (
    <GroupsContext.Provider value={values}>{children}</GroupsContext.Provider>
  );
};
