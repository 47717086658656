import { useMutation } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';
import { InviteClubsByEvent } from 'services/v3/Event/types';
import EventService from 'services/v3/Event/EventService';

export const useInviteByEvent = () => {
  return useMutation({
    mutationFn: async (body: InviteClubsByEvent) =>
      await EventService.inviteByEvent(body),
    onSuccess: async () => {
      notification.success({
        message: 'Your invitations were sent',
        description: 'Now make sure to follow up with the invites.',
      });
    },
    onError: (error: any) => {
      notification.error({
        message: 'An error occurs!',
        description: error?.message || 'Unexpected error.',
      });
    },
  });
};
