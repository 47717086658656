import React, { FC, useCallback } from 'react';
import { Mail } from '@icon-park/react';

import { User } from 'models/User/UserModel';
import { Address } from 'admin/models/event/Address';

import FilledButton from 'components/v3/Buttons/FilledButton';

import { formatUserName } from 'util/formatUserName';

import { BodyM, TitleH4 } from 'styles/v3/variables';

import { SimpleCard } from '../../../components';

import * as S from './styles';

interface IInviteListProps {
  selectedUsers?: User[];
  onSendInvites: () => Promise<void>;
  onRemoveCard: (id: string) => void;
}

export const InviteList: FC<IInviteListProps> = ({
  selectedUsers,
  onSendInvites,
  onRemoveCard,
}) => {
  const handleGetAddress = useCallback((address?: Address) => {
    if (address) {
      return `${address.city} - ${address.state}`;
    }

    return '';
  }, []);

  return (
    <S.InviteListWrapper>
      <S.InviteListMain>
        <TitleH4 $isUpper>Invite list</TitleH4>
        <BodyM $color='grey400'>
          If you invite members that have already been invited, they will be
          notified again.
        </BodyM>
        <S.SimpleCardList>
          {selectedUsers?.map((user) => (
            <SimpleCard
              id={user.id}
              key={user.id}
              imgSrc={user.photo}
              onRemoveCard={onRemoveCard}
              address={handleGetAddress(user.address)}
              userType={user.type}
              name={formatUserName({
                firstName: user.firstName,
                lastName: user.lastName,
                middleName: user.middleName,
              })}
            />
          ))}
        </S.SimpleCardList>
        <FilledButton isUpper isBold={false} onClick={onSendInvites}>
          Send invites
        </FilledButton>
      </S.InviteListMain>
      <S.InviteEmailWrapper>
        <BodyM $isUpper>Invite via email</BodyM>
        <Mail size={24} />
      </S.InviteEmailWrapper>
    </S.InviteListWrapper>
  );
};
