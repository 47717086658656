import { Roster } from '../../models/Roster/RosterModel';

export enum RosterActionTypes {
  FETCH_REQUEST = '@@roster/FETCH_REQUEST',
  FETCH_SUCCESS = '@@roster/FETCH_SUCCESS',
  FETCH_ERROR = '@@roster/FETCH_ERROR',
  UPDATE = '@@roster/UPDATE',

  ADD_PLAYER = '@@roster/ADD_PLAYER',
  ADD_PLAYER_SUCCESS = '@@roster/ADD_PLAYER_SUCCESS',

  REMOVE_PLAYER = '@@roster/REMOVE_PLAYER',
  REMOVE_PLAYER_SUCCESS = '@@roster/REMOVE_PLAYER_SUCCESS',

  CLEAR_ERRORS = '@@roster/CLEAR_ERRORS',
}

export interface RosterState {
  readonly loading: boolean;
  readonly data: Roster;
  readonly errors?: string;
}
