import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

interface ICustomTransitionProps {
  transitionIn: boolean;
}

const CustomTransition: React.FunctionComponent<ICustomTransitionProps> = (
  props
) => {
  const { children, transitionIn } = props;
  return (
    <CSSTransition
      timeout={3500}
      appear
      enter
      in={transitionIn}
      classNames='transition_fade'
    >
      {children}
    </CSSTransition>
  );
};

export default CustomTransition;
