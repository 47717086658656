import { useQuery } from '@tanstack/react-query';
import UserService from 'services/v3/User/UserService';

export function useGetUserDocuments(userId: string) {
  const { data, isLoading, error } = useQuery(
    ['/get-user-documents', userId],
    async () => await UserService.getUserDocsV3(userId)
  );
  const docs = data?.data;
  return {
    ...data,
    data: docs,
    isLoading,
    error,
  };
}
