import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TypeUserSelectionWrapper,
  SelectionItem,
} from './TypeUserSelection.style';

import playerImg from '../../assets/imgs/selection_player.png';
import coachImg from '../../assets/imgs/selection_coach.png';
import directorImg from '../../assets/imgs/selection_director.png';
import refereeImg from '../../assets/imgs/selection_referee.png';

import { ApplicationState } from '../../redux/store';
import { newUserUpdateType } from '../../redux/newUser/actions';
import { UserType } from '../../redux/user/types';

import playerSelectionBG from '../../assets/imgs/player_selection_bg.svg';
import coachSelectionBG from '../../assets/imgs/coach_selection_bg.svg';
import directorSelectionBG from '../../assets/imgs/director_selection_bg.svg';
import refereeSelectionBG from '../../assets/imgs/referee_selection_bg.svg';

const TypeUserSelection: React.FunctionComponent = () => {
  const { data } = useSelector((state: ApplicationState) => state.newUser);
  const dispatch = useDispatch();

  function setNewUserType(userType: UserType) {
    dispatch(newUserUpdateType(userType));
  }

  return (
    <TypeUserSelectionWrapper data-testid='selectionWrapper'>
      <SelectionItem
        className={data.newUser?.type === UserType.PLAYER ? 'selected' : ''}
        onClick={() => setNewUserType(UserType.PLAYER)}
        data-testid='select-player'
      >
        <div className='bg-skew'>
          <div
            className='bg-text'
            style={{ backgroundImage: `url(${playerSelectionBG})` }}
          />
        </div>
        <div className='img-inactive-wrapper'>
          <div className='img-inactive'>
            <img src={playerImg} alt='' />
          </div>
        </div>
        <div className='bg-skew-bg'>
          <div
            className='bg-text'
            style={{ backgroundImage: `url(${playerSelectionBG})` }}
          />
        </div>
        <div className='item-img-effect'>
          <img src={playerImg} alt='' />
        </div>
        <div className='item-img'>
          <img src={playerImg} alt='' />
        </div>
        <div className='user-title'>Player</div>
      </SelectionItem>

      <SelectionItem
        className={data.newUser?.type === UserType.COACH ? 'selected' : ''}
        onClick={() => setNewUserType(UserType.COACH)}
        data-testid='select-coach'
      >
        <div className='bg-skew'>
          <div
            className='bg-text'
            style={{ backgroundImage: `url(${coachSelectionBG})` }}
          />
        </div>
        <div className='img-inactive-wrapper'>
          <div className='img-inactive'>
            <img
              src={coachImg}
              style={{ width: '129%', marginLeft: '7px' }}
              alt=''
            />
          </div>
        </div>
        <div className='bg-skew-bg'>
          <div
            className='bg-text'
            style={{ backgroundImage: `url(${coachSelectionBG})` }}
          />
        </div>
        <div className='item-img-effect'>
          <img src={coachImg} alt='' />
        </div>
        <div className='item-img'>
          <img src={coachImg} alt='' />
        </div>
        <div className='user-title'>Coach</div>
      </SelectionItem>

      <SelectionItem
        className={
          data.newUser?.type === UserType.CLUB_DIRECTOR ? 'selected' : ''
        }
        onClick={() => setNewUserType(UserType.CLUB_DIRECTOR)}
        data-testid='select-director'
      >
        <div className='bg-skew'>
          <div
            className='bg-text'
            style={{ backgroundImage: `url(${directorSelectionBG})` }}
          />
        </div>
        <div className='img-inactive-wrapper'>
          <div className='img-inactive'>
            <img
              src={directorImg}
              style={{ width: '100%', marginLeft: '7px' }}
              alt=''
            />
          </div>
        </div>
        <div className='bg-skew-bg'>
          <div
            className='bg-text'
            style={{ backgroundImage: `url(${directorSelectionBG})` }}
          />
        </div>
        <div className='item-img-effect'>
          <img src={directorImg} alt='' />
        </div>
        <div className='item-img'>
          <img src={directorImg} alt='' />
        </div>
        <div className='user-title'>Club Director</div>
      </SelectionItem>

      <SelectionItem
        className={data.newUser?.type === UserType.REFEREE ? 'selected' : ''}
        onClick={() => setNewUserType(UserType.REFEREE)}
        data-testid='select-director'
      >
        <div className='bg-skew'>
          <div
            className='bg-text'
            style={{ backgroundImage: `url(${refereeSelectionBG})` }}
          />
        </div>
        <div className='img-inactive-wrapper'>
          <div className='img-inactive'>
            <img
              src={refereeImg}
              style={{ width: '190%', marginLeft: '6px' }}
              alt=''
            />
          </div>
        </div>
        <div className='bg-skew-bg'>
          <div
            className='bg-text'
            style={{ backgroundImage: `url(${refereeSelectionBG})` }}
          />
        </div>
        <div className='item-img-effect'>
          <img src={refereeImg} alt='' />
        </div>
        <div className='item-img'>
          <img src={refereeImg} alt='' />
        </div>
        <div className='user-title'>Referee</div>
      </SelectionItem>
    </TypeUserSelectionWrapper>
  );
};

export default TypeUserSelection;
