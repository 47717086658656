import { Button, Form, Input, InputNumber, message, Skeleton } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PageContainer from '../../../components/base/PageContainer/PageContainer';
import GroupBox from '../../../components/uielements/GroupBox/GroupBox';
import BaseEventServices from '../../../services/BaseEvent/BaseEventServices';
import { BaseEventView } from '../../models/event/BaseEvent';

const BaseEventCreatePage: React.FunctionComponent = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const routeParams: any = useParams();

  const [loadingEvent, setLoadingEvent] = useState(false);
  const [formTitle, setFormTitle] = useState('Create');
  const [loadedBaseEvent, setLoadedBaseEvent] = useState<BaseEventView>();

  useEffect(() => {
    loadBaseEvent(routeParams.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadBaseEvent(id: string) {
    if (id) {
      setLoadingEvent(true);
      setFormTitle('update');

      await BaseEventServices.find(id)
        .then((response) => {
          if (!response.success) {
            message.error({ content: response.message, duration: 5 });
            return;
          }

          setLoadedBaseEvent(response.data);
        })
        .finally(() => {
          setLoadingEvent(false);
        });
    }
  }

  const onFinish = async (values: any) => {
    const key = 'request';
    if (loadedBaseEvent && loadedBaseEvent.id) {
      BaseEventServices.update(loadedBaseEvent.id, values).then((response) => {
        if (!response.success) {
          message.error({ content: response.message, key, duration: 5 });
          return;
        }

        message.success({ content: 'Venue updated', key, duration: 3 });
        history.push('/admin-panel/base-events');
      });
    } else {
      BaseEventServices.create(values).then((response) => {
        if (!response.success) {
          message.error({ content: response.message, key, duration: 5 });
          return;
        }

        message.success({ content: 'Event created', key, duration: 3 });
        history.push('/admin-panel/base-events');
      });
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <PageContainer title='Base event'>
      <GroupBox title={formTitle}>
        <Skeleton loading={loadingEvent} active title>
          <Form
            layout='vertical'
            form={form}
            onFinish={onFinish}
            initialValues={loadedBaseEvent}
          >
            <div className='form-group'>
              <Form.Item
                name='name'
                label='Name'
                rules={[{ required: true, message: 'Name is required' }]}
              >
                <Input />
              </Form.Item>
            </div>

            <div className='form-group'>
              <Form.Item
                name='multiplier'
                label='Multiplier'
                rules={[{ required: true, message: 'Multiplier is required' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={1}
                  defaultValue={loadedBaseEvent?.multiplier}
                />
              </Form.Item>
            </div>

            <div className='form-group'>
              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Save
                </Button>
                <Button htmlType='button' onClick={onReset}>
                  Reset
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Skeleton>
      </GroupBox>
    </PageContainer>
  );
};

export default BaseEventCreatePage;
