import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { color, font } from '../../../styles/styleVariables';
import LoadingIcon from '../../base/LoadingIcon/LoadingIcon';

interface ButtonProps {
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  className?: string;
  size?: 'small' | 'medium' | 'large';
}

const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const {
    disabled,
    onClick,
    style,
    className,
    children,
    loading,
    size,
  } = props;
  return (
    <ButtonWrapper
      disabled={disabled}
      onClick={onClick}
      style={style}
      className={className}
      size={size}
    >
      {loading && <LoadingIcon small />}
      {children}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled('button')<{ size?: 'small' | 'medium' | 'large' }>`
  outline: none;
  border-style: none;
  width: 100%;
  padding: 15px 30px;
  border-radius: 10px;
  min-width: 180px;
  display: inline-block;
  background: ${color.silver};
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  font-family: ${font.druk};
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 20px;
  display: block;
  transition: all 0.2s;
  color: #fff;
  ${(props) => props.size === 'small' && `padding: 10px 15px;`}
  &:hover {
    background: ${color.orange};
  }
  &:disabled {
    background: ${color.greyDark};
    color: ${color.silver};
    cursor: not-allowed;
  }
  &.disabled {
    background: ${color.greyDark};
    color: ${color.silver};
    cursor: not-allowed;
  }
`;

export default Button;
