import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import LightBox from '../../../../../../components/base/LightBox/LightBox';
import DigitalScoreboardConnectionModal from './DigitalScoreboardConnectionModal';

import iconCast from '../../../../../../assets/imgs/icons/ico_cast.svg';
import { io, Socket } from 'socket.io-client';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../../redux/store';
import { DSConnectionStatus } from '../../../../../../models/DSConnection/DSConnection';
import { useEffect } from 'react';
import { useContext } from 'react';
import { SocketContext } from '../../../../../../context/SocketContext';

interface IDigitalScoreboardConnectProps {
  matchData: {
    teamA: {
      color: string;
      abbv: string;
      logo: string;
    };
    teamB: {
      color: string;
      abbv: string;
      logo: string;
    };
  };
}

const DigitalScoreboardConnect: React.FunctionComponent<IDigitalScoreboardConnectProps> = (
  props
) => {
  const { matchData } = props;

  const [connectModalOpen, setConnectModalOpen] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState<DSConnectionStatus>(
    DSConnectionStatus.DISCONNECTED
  );
  const { socket, setSocket } = useContext(SocketContext);
  const dsConnection = useSelector(
    (appState: ApplicationState) => appState.dsConnection
  );

  const tryConnectPanel = () => {
    if (dsConnection.data.ipPanel) {
      setSocket(
        io(
          //`${process.env.REACT_APP_DS_INIT_ADDRESS}.${dsConnection.data.ipPanel}:9080`,
          `${process.env.REACT_APP_DS_INIT_ADDRESS}.${dsConnection.data.ipPanel}:${process.env.REACT_APP_DS_PORT}`
        )
      );
    } else {
      socket?.disconnect();
      setConnectionStatus(DSConnectionStatus.DISCONNECTED);
    }
  };

  // Initiate connection with socket
  useEffect(() => {
    tryConnectPanel();
  }, [dsConnection.data.ipPanel]);

  useEffect(() => {
    if (socket) {
      setConnectionStatus(DSConnectionStatus.CONNECTING);
      socket?.on('connect', (err: any) => {
        setConnectionStatus(DSConnectionStatus.CONNECTED);
        socket.emit('initData', matchData);
        setConnectModalOpen(false);
      });

      socket?.on('connect_error', (err: any) => {
        setConnectionStatus(DSConnectionStatus.ERROR);
        socket.disconnect();
      });
    }
  }, [socket]);

  // Sync screens
  useEffect(() => {
    if (socket) {
      socket.emit('initData', matchData);
    }
  }, [matchData]);

  return (
    <Wrapper>
      <Button onClick={() => setConnectModalOpen(true)} />
      <Icon
        className={
          connectionStatus === DSConnectionStatus.CONNECTED ? 'connected' : ''
        }
      />
      <LightBox
        isOpen={connectModalOpen}
        onClose={() => setConnectModalOpen(false)}
      >
        <DigitalScoreboardConnectionModal
          connectionStatus={connectionStatus}
          setConnectionStatus={setConnectionStatus}
        />
      </LightBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;
const Button = styled.div`
  width: 40px;
  height: 40px;
  background-color: #fd502f;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center;
  background-image: url(${iconCast});
  padding: 10px;
`;

const Icon = styled.div`
  position: absolute;
  bottom: -3px;
  left: 32px;
  width: 12px;
  border: solid 2px #2e3137;
  height: 12px;
  border-radius: 6px;
  background-color: #ff0000;
  &.connected {
    background-color: #3a9641;
  }
`;

export default DigitalScoreboardConnect;
