import React from 'react';

import { CornerDownRight } from '@icon-park/react';
import { BodyMBold, BodyS } from 'styles/v3/variables';
import * as S from './styles';

type NameWithRoleProps = {
  name?: string;
  roleName?: string;
};

export const NameWithRole = ({ name, roleName }: NameWithRoleProps) => {
  return (
    <S.Container>
      <CornerDownRight size={20} />
      <S.Info>
        <BodyMBold $color='grey200'>{name}</BodyMBold>
        <BodyS $color='grey400'>{roleName}</BodyS>
      </S.Info>
    </S.Container>
  );
};
