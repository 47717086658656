import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const FiltersForm = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: ${toRem(16)};
  color: ${COLORS.grey500};

  .title {
    color: ${COLORS.white};
  }
`;

export const HR = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.grey800};
  &::after {
    content: ' ';
  }
`;
