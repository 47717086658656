import React from 'react';
import { Filter as FilterIcon } from '@icon-park/react';

import { BodyMBold } from 'styles/v3/variables';
import InputText from 'components/v3/Forms/InputText/InputText';
import * as S from './styles';
import { FiltersProps } from './types';

export const Filters = ({
  handleChange,
  handleOpenFilterModal,
}: FiltersProps) => {
  return (
    <S.Container>
      <S.FilterWrapper>
        <BodyMBold $isUpper $color='grey400'>
          Filters
        </BodyMBold>
        <FilterIcon
          className='btn-filter-modal'
          onClick={handleOpenFilterModal}
        />
      </S.FilterWrapper>
      <InputText
        onChange={(e) => handleChange(e.target.value)}
        id='search'
        placeholder='Search'
        icon='Search'
      />
    </S.Container>
  );
};
