import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const DraggableCardWrapper = styled.div`
  width: inherit;
  max-width: 444px;
  height: 72px;
  cursor: grab;
  color: ${COLORS.white};

  & + div {
    &:not(.drop-zone.hidden) {
      margin-top: 1rem;
    }
  }

  &.floating {
    z-index: 1;
    position: absolute;
  }
  &.drop-zone {
    overflow: hidden;
    border-radius: ${toRem(12)};
    transition-duration: 250ms;
    background-color: ${COLORS.grey900};
    transition-property: height;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);

    .simple-card-wrapper {
      border: 0;
      height: 0;
      padding: 0;
      .icons-wrapper {
        display: none;
      }
    }

    &.hidden {
      height: 0;
    }
  }

  .simple-card-wrapper {
    width: 100%;
    height: 100%;
    border-color: ${COLORS.grey800};
    background-color: ${COLORS.grey950};
    align-items: center;
    justify-content: space-between;
  }
`;
export const DragIcon = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 1rem;
  width: 24px;
  height: 24px;
  cursor: grab;

  &::before,
  &::after {
    content: '';
    background-color: ${COLORS.white};
    display: block;
    height: 3px;
    width: 100%;
    border-radius: ${toRem(30)};
  }
`;
export const InfoContainer = styled.div`
  display: flex;
  max-width: 260px;
  margin-right: 1rem;
  flex: 1;
`;
export const BgIcon = styled.div`
  border-radius: 12px;
  padding: ${toRem(8)};
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: ${COLORS.grey800};

  & + div {
    margin-left: 1rem;
  }
`;
export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
