import { Table } from 'antd';
import * as React from 'react';

const columns = [
  {
    title: 'Level',
    dataIndex: 'level',
    key: 'level',
  },
  {
    title: 'XP',
    dataIndex: 'xp',
    key: 'xp',
    width: 120,
    align: 'center' as const,
  },
];

const dataTable = [
  {
    key: '1',
    level: 'Level 100',
    xp: '160000',
  },
  {
    key: '1',
    level: 'Level 90',
    xp: '129600',
  },
  {
    key: '1',
    level: 'Level 80',
    xp: '102400',
  },
  {
    key: '1',
    level: 'Level 70',
    xp: '78400',
  },
  {
    key: '1',
    level: 'Level 60',
    xp: '57600',
  },
  {
    key: '1',
    level: 'Level 50',
    xp: '40000',
  },
  {
    key: '1',
    level: 'Level 40',
    xp: '25600',
  },
  {
    key: '1',
    level: 'Level 30',
    xp: '14400',
  },
  {
    key: '1',
    level: 'Level 20',
    xp: '6400',
  },
  {
    key: '1',
    level: 'Level 10',
    xp: '1600',
  },
  {
    key: '1',
    level: 'Level 9',
    xp: '1296',
  },
  {
    key: '1',
    level: 'Level 8',
    xp: '1024',
  },
  {
    key: '1',
    level: 'Level 7',
    xp: '784',
  },
  {
    key: '1',
    level: 'Level 6',
    xp: '576',
  },
  {
    key: '1',
    level: 'Level 5',
    xp: '400',
  },
  {
    key: '1',
    level: 'Level 4',
    xp: '256',
  },
  {
    key: '1',
    level: 'Level 3',
    xp: '144',
  },
  {
    key: '1',
    level: 'Level 2',
    xp: '64',
  },
  {
    key: '1',
    level: 'Level 1',
    xp: '16',
  },
  {
    key: '1',
    level: 'Level 0',
    xp: '0',
  },
];

const Levels: React.FunctionComponent = () => {
  return (
    <div>
      <h3>How is XP calculated?</h3>
      <p>
        We multiply your action X the eventmultiplier. So, choose the right
        events and do the better actions in order to improve your VAULT card.
      </p>
      <div className='group-box'>
        <h3 style={{ display: 'flex', alignItems: 'center' }}>
          <span
            className='icon clear lg i-graph'
            style={{ marginRight: '15px' }}
          ></span>{' '}
          Levels
        </h3>

        <Table
          rowKey='id'
          dataSource={dataTable}
          pagination={false}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default Levels;
