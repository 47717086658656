import { Reducer } from 'redux';
import { UserActionTypes, UserState } from './types';

export const initialState: UserState = {
  data: { id: '', firstName: '', lastName: '', photo: '', clubs: [] },
  loading: false,
  errors: undefined,
};

const reducer: Reducer<UserState> = (state = initialState, action) => {
  switch (action.type) {
    case UserActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case UserActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }
    case UserActionTypes.FETCH_ERROR: {
      return { ...state, loading: false };
    }
    case UserActionTypes.UPDATE: {
      return { ...state, data: action.payload };
    }
    case UserActionTypes.UPDATE_ACTIVE: {
      return { ...state, data: action.payload };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as userReducer };
