import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.grey500};
`;

type ImageProps = {
  $src?: string,
};
export const Image =
  styled.div <
  ImageProps >
  `
  width: ${toRem(100)};
  height: ${toRem(100)};
  border-radius: 50%;
  background: url(${(props) => props.$src}), lightgray 50%;
  background-size: cover;
  background-position: center;
`;
