import { AxiosRequestConfig } from 'axios';
import {
  BaseEventCreation,
  BaseEventView,
} from '../../admin/models/event/BaseEvent';
import { ConfigApi } from '../config.api';
import APIServices from '../util/ApiServices';
import PageResponse from '../util/PageResponse';
import ServiceResponse from '../util/ServiceResponse';

class BaseEventServices {
  public static async list(): Promise<
    ServiceResponse<PageResponse<BaseEventView>>
  > {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/base-events',
      method: 'GET',
    };
    return APIServices.request<PageResponse<BaseEventView>>(axiosConfig);
  }

  public static async find(
    id: string
  ): Promise<ServiceResponse<BaseEventView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/base-events/${id}`,
      method: 'GET',
    };
    return APIServices.request<BaseEventView>(axiosConfig);
  }

  public static async create(
    body: BaseEventCreation
  ): Promise<ServiceResponse<BaseEventView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/base-events',
      method: 'POST',
      data: body,
    };
    return APIServices.request<BaseEventView>(axiosConfig);
  }

  public static async update(
    id: string,
    body: BaseEventCreation
  ): Promise<ServiceResponse<BaseEventView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/base-events/${id}`,
      method: 'PUT',
      data: body,
    };
    return APIServices.request<BaseEventView>(axiosConfig);
  }
}

export default BaseEventServices;
