import { produce } from 'immer';
import { Reducer } from 'redux';
import { EventsActionTypes, EventsState } from './types';

export const initialState: EventsState = {
  data: [],
  error: '',
  loading: false,
};

const reducer: Reducer<EventsState> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case EventsActionTypes.FETCH_EVENTS_REQUEST: {
        draft.loading = true;
        break;
      }
      case EventsActionTypes.FETCH_EVENTS_SUCCESS: {
        draft.loading = false;
        draft.data = action.payload;
        break;
      }
      case EventsActionTypes.FETCH_EVENTS_ERROR: {
        draft.loading = false;
        draft.error = action.payload;
        break;
      }

      default:
        break;
    }
  });

export { reducer as eventsReducer };
