import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(32)};
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;

  gap: ${toRem(16)};
`;

export const Footer = styled.div`
  padding: ${toRem(24)} ${toRem(30)} ${toRem(24)} ${toRem(30)};

  button {
    width: 100%;
  }
`;
