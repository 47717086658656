import * as React from 'react';
import Slider from 'antd/es/slider';
import Modal from 'antd/lib/modal/Modal';
import { useCallback, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Minus, Plus, Return } from '@icon-park/react';

import FilledButton from 'components/v3/Buttons/FilledButton';
import TextButton from 'components/v3/Buttons/TextButton';

import * as S from './styles';

interface IEditPhotoProps {
  imgURL: string;
  open?: boolean;
  afterEdit: (file: File) => void;
  onClose: () => void;
  newStyle?: boolean;
  changePhoto?: () => void;
}

const ZOOM_STEP = 0.1;

const MIN_ROTATE = -180;
const MAX_ROTATE = 180;

const MIN_ZOOM = 1;
const MAX_ZOOM = 3;

const ROTATE_STEP = 1;

const EditPhoto: React.FunctionComponent<IEditPhotoProps> = ({
  imgURL,
  open,
  onClose,
  afterEdit,
  newStyle,
  changePhoto,
}) => {
  const [zoomVal, setZoomVal] = useState(1);
  const [rotateVal, setRotateVal] = useState(0);

  const editor = useRef<AvatarEditor>(null);
  /**
   * Controls
   */
  const isMinZoom = zoomVal - ZOOM_STEP < MIN_ZOOM;
  const isMaxZoom = zoomVal + ZOOM_STEP > MAX_ZOOM;
  const isMinRotate = rotateVal === MIN_ROTATE;
  const isMaxRotate = rotateVal === MAX_ROTATE;

  const subZoomVal = useCallback(() => {
    if (!isMinZoom) setZoomVal(zoomVal - ZOOM_STEP);
  }, [isMinZoom, zoomVal]);

  const addZoomVal = useCallback(() => {
    if (!isMaxZoom) setZoomVal(zoomVal + ZOOM_STEP);
  }, [isMaxZoom, zoomVal]);

  const subRotateVal = useCallback(() => {
    if (!isMinRotate) setRotateVal(rotateVal - ROTATE_STEP);
  }, [isMinRotate, rotateVal]);

  const addRotateVal = useCallback(() => {
    if (!isMaxRotate) setRotateVal(rotateVal + ROTATE_STEP);
  }, [isMaxRotate, rotateVal]);

  const onOk = useCallback(async () => {
    onClose();
    const canvasScaled = editor.current?.getImageScaledToCanvas();

    canvasScaled?.toBlob(
      async (blob) => {
        const newFile = new File([blob!], 'tempEditedImg.jpg', {
          type: 'image/jpg',
        });
        afterEdit(newFile);
      },
      'image/jpeg',
      0.5
    );
  }, [onClose, rotateVal]);

  const renderAvatar = () => (
    <div style={{ display: 'flex' }}>
      <AvatarEditor
        ref={editor}
        image={imgURL}
        width={newStyle ? 200 : 250}
        height={newStyle ? 200 : 250}
        border={0}
        borderRadius={200}
        color={[0, 0, 0, 0.6]} // RGBA
        scale={zoomVal}
        rotate={rotateVal}
        style={{ margin: '0 auto' }}
        crossOrigin='unitedfutsal.com'
      />
    </div>
  );

  const renderSliderZoom = () => (
    <Slider
      value={zoomVal}
      min={MIN_ZOOM}
      max={MAX_ZOOM}
      step={0.1}
      aria-labelledby='Zoom'
      onChange={setZoomVal}
    />
  );

  const renderSliderRotate = () => (
    <Slider
      min={MIN_ROTATE}
      max={MAX_ROTATE}
      step={ROTATE_STEP}
      value={rotateVal}
      onChange={setRotateVal}
    />
  );

  return newStyle ? (
    <>
      {renderAvatar()}
      <S.SliderStyle $newStyle={newStyle}>
        <div className='antd-img-crop-control zoom'>
          <S.IconWrapper $disabled={isMinZoom}>
            <Minus size={16} onClick={subZoomVal} />
          </S.IconWrapper>
          {renderSliderZoom()}
          <S.IconWrapper $disabled={isMaxZoom}>
            <Plus size={16} onClick={addZoomVal} />
          </S.IconWrapper>
        </div>

        <div className='antd-img-crop-control rotate'>
          <S.IconWrapper $disabled={isMinRotate}>
            <Return
              className='icon-return-revert'
              onClick={subRotateVal}
              size={16}
            />
          </S.IconWrapper>
          {renderSliderRotate()}
          <S.IconWrapper $disabled={isMaxRotate}>
            <Return size={16} onClick={addRotateVal} />
          </S.IconWrapper>
        </div>
      </S.SliderStyle>
      <S.ButtonsWrapper>
        <FilledButton isUpper onClick={onOk}>
          Save Photo
        </FilledButton>
        <TextButton isUpper onClick={changePhoto}>
          Change Photo
        </TextButton>
      </S.ButtonsWrapper>
    </>
  ) : (
    <div>
      <Modal
        visible={open}
        maskClosable={false}
        onCancel={onClose}
        onOk={onOk}
        title='Edit Image'
        destroyOnClose
        wrapClassName='antd-img-crop-modal'
      >
        {renderAvatar()}
        <S.SliderStyle>
          <div className='antd-img-crop-control zoom'>
            <button onClick={subZoomVal} disabled={isMinZoom} type='button'>
              －
            </button>
            {renderSliderZoom()}
            <button onClick={addZoomVal} disabled={isMaxZoom} type='button'>
              ＋
            </button>
          </div>

          <div className='antd-img-crop-control rotate'>
            <button onClick={subRotateVal} disabled={isMinRotate} type='button'>
              ↺
            </button>
            {renderSliderRotate()}
            <button onClick={addRotateVal} disabled={isMaxRotate} type='button'>
              ↻
            </button>
          </div>
        </S.SliderStyle>
      </Modal>
    </div>
  );
};

export default EditPhoto;
