import { action } from 'typesafe-actions';
import { Player } from '../../models/Match/MatchModel';
import { MemberpoolActionTypes } from './types';

export const memberpoolFetchRequest = (clubId: string) =>
  action(MemberpoolActionTypes.FETCH_REQUEST, clubId);

export const memberpoolFetchSuccess = (data: Player[]) =>
  action(MemberpoolActionTypes.FETCH_SUCCESS, data);

export const memberpoolFetchError = (message: string) =>
  action(MemberpoolActionTypes.FETCH_ERROR, message);
