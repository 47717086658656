import * as React from 'react';
import Icon, { IconType } from '@icon-park/react/es/all';

import * as S from './styles';
import { COLORS } from 'styles/v3/variables';

export interface BasicAvatarProps {
  children?: React.ReactElement;
  bgImage?: string;
  size?: 'small' | 'medium' | 'large' | 'extra-large';
  icon?: IconType;
}

const BasicAvatar: React.FC<BasicAvatarProps> = ({
  children,
  size,
  bgImage,
  icon,
}) => {
  let setSize;

  switch (size) {
    case 'small':
      setSize = '16px';
      break;
    case 'medium':
      setSize = '24px';
      break;
    case 'large':
      setSize = '32px';
      break;
    case 'extra-large':
      setSize = '40px';
      break;
    default:
      setSize = '24px';
      break;
  }

  return (
    <S.Container
      $bgImage={bgImage}
      $size={setSize}
      $hasIcon={!!icon}
      className='avatar-wrapper'
    >
      {icon && <Icon type={icon} size='24' color={COLORS.grey500} />}
      {children}
    </S.Container>
  );
};

export default BasicAvatar;
