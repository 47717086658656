import * as React from 'react';
import { ModalBodyProps } from './types';

import * as S from './styles';
import * as C from './components';

const ModalBody: React.FC<ModalBodyProps> = ({ user }) => {
  return (
    <S.Container>
      <C.Header user={user} />
      <C.Documents user={user} />
      <C.Clubs user={user} />
    </S.Container>
  );
};

export default ModalBody;
