import * as React from 'react';

import * as S from './styles';
import InputCheckbox from '../Forms/InputCheckbox/InputCheckbox';
import { BodyM } from 'styles/v3/variables';
import FilledButtonWithIcon from '../Buttons/FilledButtonWithIcon';
import { useHistory } from 'react-router-dom';

interface TemplateReadAndAcceptDocumentProps {
  children: React.ReactNode;
  label: string;
  buttonText: string;
  infoText: React.ReactNode;
  link: string;
  setRead: (e: boolean) => void;
}

const TemplateReadAndAcceptDocument: React.FC<TemplateReadAndAcceptDocumentProps> = ({
  children,
  setRead,
  label,
  buttonText,
  infoText,
  link,
}) => {
  const history = useHistory();
  const [waiverRead, setWaiverRead] = React.useState(false);
  const [accept, setAccept] = React.useState(false);

  const handleTermsScroll = (event: any) => {
    if (
      event.target.scrollHeight - event.target.scrollTop - 20 <
      event.target.clientHeight
    ) {
      setWaiverRead(true);
    }
  };

  React.useEffect(() => {
    setRead(waiverRead);
  }, [waiverRead]);

  return (
    <>
      <S.Wrapper onScroll={handleTermsScroll}>{children}</S.Wrapper>
      {waiverRead && (
        <S.CheckboxWrapper>
          <InputCheckbox
            id='accept'
            name='accept'
            checked={accept}
            onChange={() => setAccept(!accept)}
          />
          <BodyM $color='grey100'>{label}</BodyM>
        </S.CheckboxWrapper>
      )}
      <S.ButtonWrapper>
        <FilledButtonWithIcon
          isUpper
          disabled={!accept}
          onClick={() => history.push(link)}
        >
          {buttonText}
        </FilledButtonWithIcon>
      </S.ButtonWrapper>
      <S.InfoContainer>{infoText}</S.InfoContainer>
    </>
  );
};

export default TemplateReadAndAcceptDocument;
