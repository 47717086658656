import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import usePrimaryClub from '../../hooks/usePrimaryClub/usePrimaryClub';
import { ApplicationState } from '../../redux/store';
import DateUtil from '../../util/DateUtil';
import CustomTransition from '../base/CustomTransition/CustomsTransition';
import InfoProfileItem from '../base/InfoProfileItem/InfoProfileItem';

interface IMyInfoGroupProps {
  heightSize?: number;
}

const MyInfoGroup: React.FunctionComponent<IMyInfoGroupProps> = (props) => {
  const user = useSelector((state: ApplicationState) => state.user);
  const club = useSelector((state: ApplicationState) => state.club);
  const [countItems, setCountItems] = React.useState(0);

  const primaryClub = usePrimaryClub();

  React.useEffect(() => {
    let t = 0;
    if (primaryClub?.id) {
      t += 1;
    }
    if (user.data.birthDate) {
      t += 1;
    }
    if (user.data.playerInfo?.preferredShirtNumber) {
      t += 1;
    }
    if (user.data.playerInfo?.height) {
      t += 1;
    }
    setCountItems(t);
  }, [user, club, primaryClub]);
  return (
    <Wrapper height={countItems * 125}>
      {primaryClub && (
        <CustomTransition transitionIn={!!primaryClub}>
          <InfoProfileItem
            title='club'
            primaryText={`${primaryClub.city}, ${primaryClub.country}`}
            mainText={primaryClub.name}
            // secondaryText={['192', 'MEMBERS']}
          />
        </CustomTransition>
      )}
      {/* <InfoProfileItem
        title='team'
        primaryText='Barcelona, Spain'
        mainText='Boys 3 Red'
        secondaryText={['16', 'MEMBERS']}
      /> */}
      {user.data.birthDate && (
        <CustomTransition transitionIn={!!user.data.birthDate}>
          <InfoProfileItem
            title='age'
            primaryText={DateUtil.getDayYear(
              new Date(user.data.birthDate.toString())
            ).toString()}
            mainText={DateUtil.getAge(
              new Date(user.data.birthDate.toString())
            ).toString()}
            secondaryText={[
              new Date(user.data.birthDate.toString())
                .getUTCFullYear()
                .toString(),
            ]}
          />
        </CustomTransition>
      )}
      {user.data.playerInfo?.height && (
        <CustomTransition transitionIn={!!user.data.playerInfo?.height}>
          <InfoProfileItem
            title='height'
            primaryText={`${user.data.address?.city}, ${user.data.address?.country}`}
            mainText={`${user.data.playerInfo?.height.toString()} m`}
            // secondaryText={[convertInch(user.data.playerInfo?.height)]}
          />
        </CustomTransition>
      )}
      {user.data.playerInfo?.preferredShirtNumber && (
        <CustomTransition
          transitionIn={!!user.data.playerInfo?.preferredShirtNumber}
        >
          <InfoProfileItem
            title='position'
            primaryText=''
            mainText={user.data.playerInfo?.preferredShirtNumber?.toString()}
            secondaryText={[
              user.data.playerInfo?.preferredPosition
                ? user.data.playerInfo?.preferredPosition
                : '',
            ]}
          />
        </CustomTransition>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ height: number }>`
  transition: all 1s;
  overflow: hidden;
  height: ${(props) => (props.height ? `${props.height}px` : '0px')};
`;

export default MyInfoGroup;
