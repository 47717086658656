import React, { ReactElement, useEffect, useState } from 'react';
import Keycloak from 'keycloak-js';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { isArray } from 'lodash';

import { accountSetUser } from 'redux/account/actions';

import Layouts from 'components/base/Layout/Layouts';

import BaseEventCreatePage from 'admin/pages/base-event/BaseEventCreatePage';
import BaseEventListPage from 'admin/pages/base-event/BaseEventListPage';
import ClubCreatePage from 'admin/pages/clubs/ClubCreatePage';
import ClubListPage from 'admin/pages/clubs/ClubListPage';
import DocumentsApprovalListPage from 'admin/pages/documents/DocumentsApprovalListPage';
import EventCreatePage from 'admin/pages/events/EventCreatePage';
import EventListPage from 'admin/pages/events/EventListPage';
import MembershipCreatePage from 'admin/pages/memberships/MembershipCreatePage';
import MembershipListPage from 'admin/pages/memberships/MembershipListPage';
import MatchSheet from 'admin/pages/tournaments/MatchSheet';
import RosterPrint from 'admin/pages/tournaments/RosterPrint';
import { TournamentsRoutes } from 'admin/pages/tournaments/routes/Tournaments.routes';
import UserEditPage from 'admin/pages/users/UserEditPage';
import UsersListPage from 'admin/pages/users/UsersListPage';
import VenueCreatePage from 'admin/pages/venues/VenueCreatePage';
import VenueListPage from 'admin/pages/venues/VenueListPage';
import EventListPageB2b from 'admin/pages/events-b2b/EventB2bListPage';
import ClubPendingPage from 'admin/pages/clubs/ClubPendingPage';

import AdminPanel from 'pages/AdminPanel/AdminPanel';
import ClubDirector from 'pages/ClubDirector/ClubDirector';
import Dashboard from 'pages/Dashboard/Dashboard';
import IndexPage from 'pages/Index/IndexPage';
import MembershipUpgrade from 'pages/MembershipUpgrade/MembershipUpgrade';
import MembershipUpgradeAdditionalInfo from 'pages/MembershipUpgrade/MembershipUpgradeAdditionalInfo';
import MembershipUpgradeCertificate from 'pages/MembershipUpgrade/MembershipUpgradeCertificate';
import MembershipUpgradePayment from 'pages/MembershipUpgrade/MembershipUpgradePayment';
import MySettings from 'pages/MySettings/MySettings.page';
import NotFound from 'pages/NotFound/NotFound';
import PaymentSuccess from 'pages/Payment/PaymentSuccess';
import MyClubPage from 'pages/PlayerPortal/MyClub/MyClub.page';
import RankingsPage from 'pages/Rankings/Rankings.page';
import UserSelection from 'pages/UserSelection/UserSelection.page';
import WizardRoutes from 'pages/Wizard/routes/Wizard.routes';
import XPRating from 'pages/XPRating/XPRating';

import KeycloakUtil from 'util/KeycloakUtil';

import PrivateRoute from './PrivateRoute';
import { V3Routes } from './V3Routes';

import { IRoute } from './interfaces/route.interface';
import { LayoutsName } from './enums/layouts-name.enum';

export const routes: IRoute[] = [
  ...V3Routes,
  {
    path: '/selectuser',
    component: UserSelection,
    private: true,
    layout: LayoutsName.NoMenu,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    private: true,
  },
  {
    path: '/wizard',
    component: WizardRoutes,
    private: true,
    userSelectedNotRequired: true,
    layout: LayoutsName.MenuEmpty,
  },
  {
    path: '/membership-upgrade/:userId?',
    component: MembershipUpgrade,
    private: true,
    layout: LayoutsName.MenuEmpty,
  },
  {
    path: '/membership-upgrade-extra-info/:userId?',
    component: MembershipUpgradeAdditionalInfo,
    private: true,
    layout: LayoutsName.MenuEmpty,
  },
  {
    path: '/membership-upgrade-certificate/:userId?',
    component: MembershipUpgradeCertificate,
    private: true,
    layout: LayoutsName.MenuEmpty,
  },
  {
    path: '/membership-upgrade-payment/:userId?',
    component: MembershipUpgradePayment,
    private: true,
    layout: LayoutsName.MenuEmpty,
  },
  {
    path: '/success',
    component: PaymentSuccess,
    private: true,
    layout: LayoutsName.MenuEmpty,
  },
  {
    path: '/my-club',
    component: MyClubPage,
    private: true,
  },
  {
    path: '/club-director',
    component: ClubDirector,
    private: true,
  },
  {
    path: '/admin-panel',
    component: AdminPanel,
    exact: true,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/admin-panel/events',
    component: EventListPage,
    exact: true,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/admin-panel/events-b2b',
    component: EventListPageB2b,
    exact: true,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/admin-panel/events/create/:id?',
    component: EventCreatePage,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/admin-panel/memberships',
    component: MembershipListPage,
    exact: true,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/admin-panel/memberships/create/:id?',
    component: MembershipCreatePage,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/admin-panel/clubs',
    component: ClubListPage,
    exact: true,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/admin-panel/clubs-pending',
    component: ClubPendingPage,
    exact: true,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/admin-panel/clubs/create/:id?',
    component: ClubCreatePage,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/admin-panel/document-analysis',
    component: DocumentsApprovalListPage,
    exact: true,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/admin-panel/users-list',
    component: UsersListPage,
    exact: true,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/admin-panel/users-list/:userId?',
    component: UserEditPage,
    exact: true,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/admin-panel/match-sheet/:matchId',
    component: MatchSheet,
    exact: true,
    private: true,
    layout: LayoutsName.None,
  },
  {
    path: '/admin-panel/tournaments/:eventId?',
    component: TournamentsRoutes,
    private: true,
  },
  {
    path: '/admin-panel/venues',
    component: VenueListPage,
    exact: true,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/admin-panel/venues/create/:id?',
    component: VenueCreatePage,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/admin-panel/base-events',
    component: BaseEventListPage,
    exact: true,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/admin-panel/base-events/create/:id?',
    component: BaseEventCreatePage,
    private: true,
    layout: LayoutsName.Admin,
  },
  {
    path: '/roster-print/:rosterId',
    component: RosterPrint,
    private: true,
    layout: LayoutsName.None,
  },
  {
    path: '/my-settings',
    component: MySettings,
    private: true,
  },
  {
    path: '/ranking',
    component: RankingsPage,
    private: true,
  },
  {
    path: '/xp-ratings',
    component: XPRating,
    private: true,
  },
  {
    path: '/home',
    component: IndexPage,
    private: true,
  },
  {
    path: '/*',
    component: NotFound,
    private: false,
    noPaddingOnMobile: false,
    layout: LayoutsName.SetupEvent,
  },
];

export default function Routes(): ReactElement {
  const [auth, setAuth] = useState<Keycloak.KeycloakInstance>();

  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('kc_token') || '';

    const refreshToken = localStorage.getItem('kc_refreshToken') || '';

    try {
      const keycloak = KeycloakUtil.getInstance('frontend-b2b');

      keycloak
        .init({
          onLoad: 'check-sso',
          token,
          refreshToken,
          pkceMethod: 'S256',
          checkLoginIframe: true,
        })
        .then(() => {
          if (keycloak.token && keycloak.refreshToken) {
            localStorage.setItem('kc_token', keycloak.token || '');

            localStorage.setItem(
              'kc_refreshToken',
              keycloak.refreshToken || ''
            );

            const tokenParsed: any = keycloak;

            dispatch(
              accountSetUser({ id: tokenParsed.sub, email: tokenParsed.email })
            );
          }

          setAuth(keycloak);
        })
    } catch {
      window.location.replace('/v3/logout');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Router>
      <Switch>
        {routes.map((route) => {
          const routePath = isArray(route.path) ? route.path[0] : route.path;

          if (!route.private) {
            return (
              <Route
                key={routePath}
                path={route.path}
                auth={auth}
                exact={!!route.exact}
                render={() => (
                  <Layouts auth={auth} layout={route.layout}>
                    <route.component
                      auth={auth as Keycloak.KeycloakInstance}
                      routeFrom={routePath}
                    />
                  </Layouts>
                )}
              />
            )
          }

          return (
            <PrivateRoute
              key={routePath}
              userSelectedNotRequired={route.userSelectedNotRequired}
              auth={auth}
              exact={!!route.exact}
              path={routePath}
              render={() => (
                <Layouts
                  auth={auth}
                  layout={route.layout}
                  noPaddingOnMobile={route?.noPaddingOnMobile}
                >
                  <route.component
                    auth={auth as Keycloak.KeycloakInstance}
                    routeFrom={routePath}
                  />
                </Layouts>
              )}
            />
          );
        })}
      </Switch>
    </Router>
  );
}
