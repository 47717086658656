import React, { FC, useState, useMemo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { UserType } from 'redux/user/types';

import { useGetRosterByIdDetailed } from 'hooks/v3/clubs/useGetRosterByIdDetailed/useGetRosterByIdDetailed';
import { useGetClubMembersPool } from 'hooks/v3/clubs/useGetClubMembersPool/useGetClubMembersPool';

import { HeaderRosterEdit } from './HeaderRosterEdit';
import { ListOfUser } from './ListOfUser';

const RosterDetails: FC = () => {
  const params: { rosterId: string } = useParams();
  const history = useHistory();

  const { data: rosterDetailed } = useGetRosterByIdDetailed(params.rosterId);
  const { data: members } = useGetClubMembersPool(rosterDetailed?.club?.id);

  const [selectedUsers, setSelectedUsers] = useState<string[]>(['']);

  const handleGoBack = async () => {
    const id = rosterDetailed?.club?.id ?? '';

    history.push(`/v3/clubs/${id}/dashboard`);
  };

  const coachesSelected = useMemo(() => {
    if (!members?.length) return [];

    return members.filter(
      (member) =>
        member.type === UserType.COACH && selectedUsers.includes(member.id)
    );
  }, [members, selectedUsers]);

  const playersSelected = useMemo(() => {
    if (!members?.length) return [];

    return members.filter(
      (member) =>
        member.type === UserType.PLAYER && selectedUsers.includes(member.id)
    );
  }, [members, selectedUsers]);

  const jerseyNumbers = useMemo(() => {
    const filteredPlayers = rosterDetailed?.players.filter((player) =>
      selectedUsers.includes(player.id)
    );

    return filteredPlayers?.map((player) => ({
      id: player.id,
      number: player.number,
    }));
  }, [selectedUsers, rosterDetailed]);

  useEffect(() => {
    const selectedPlayers = rosterDetailed?.players.map((player) => player.id);
    const selectedCoaches = rosterDetailed?.coaches.map((player) => player.id);

    if (selectedPlayers && selectedPlayers?.length > 0) {
      setSelectedUsers((oldSelected) => [...oldSelected, ...selectedPlayers]);
    }

    if (selectedCoaches && selectedCoaches?.length > 0) {
      setSelectedUsers((oldSelected) => [...oldSelected, ...selectedCoaches]);
    }
  }, [members, rosterDetailed]);

  return (
    <>
      {rosterDetailed?.ageDivision && (
        <HeaderRosterEdit
          name={rosterDetailed?.name ?? ''}
          eventName={rosterDetailed?.event?.name ?? ''}
          eventLogo={rosterDetailed?.event?.logo ?? ''}
          division={rosterDetailed.ageDivision}
          locksIn={rosterDetailed?.event?.data.generalRules?.rosterLockDate}
          handleGoBack={handleGoBack}
        />
      )}

      {rosterDetailed?.coaches && (
        <ListOfUser type={UserType.COACH} coachesList={coachesSelected} />
      )}

      {rosterDetailed?.players && (
        <ListOfUser
          type={UserType.PLAYER}
          playersList={playersSelected}
          jerseyNumbers={jerseyNumbers}
        />
      )}
    </>
  );
};

export default RosterDetails;
