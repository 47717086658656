import { produce } from 'immer';
import { Reducer } from 'redux';

import { SignupEventActionTypes, SignupEventState } from './types';

export const initialState: SignupEventState = {
  data: {
    clubId: '',
    divisions: [],
    currentDivisions: [],
  },
};

const reducer: Reducer<SignupEventState> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SignupEventActionTypes.SET_CLUB: {
        draft.data.clubId = action.payload;
        break;
      }
      case SignupEventActionTypes.DIVISION_QTY_INCREASE: {
        const divisionIndex = state.data.divisions.findIndex(
          (d) => d.divisionId === action.payload
        );

        if (divisionIndex !== -1) {
          draft.data.divisions[divisionIndex].quantity += 1;
        } else {
          const division = state.data.currentDivisions.find(
            (d) => d.id === action.payload
          );
          if (division) {
            draft.data.divisions.push({
              divisionId: division.id,
              name: division.name ?? '',
              quantity: 1,
            });
          }
        }
        break;
      }
      case SignupEventActionTypes.DIVISION_QTY_DECREASE: {
        const divisionIndex = state.data.divisions.findIndex(
          (d) => d.divisionId === action.payload
        );
        if (divisionIndex !== -1) {
          if (draft.data.divisions[divisionIndex].quantity < 2) {
            draft.data.divisions.splice(divisionIndex, 1);
          } else {
            draft.data.divisions[divisionIndex].quantity -= 1;
          }
        }
        break;
      }
      case SignupEventActionTypes.VALIDATE_DIVISIONS: {
        draft.data.currentDivisions = action.payload;
        draft.data.divisions =
          state.data?.divisions?.filter((division) =>
            action.payload
              .map((div: { id: string }) => div.id)
              .includes(division.divisionId)
          ) || [];
        break;
      }
      case SignupEventActionTypes.RESET_CLUB_ID: {
        draft.data.clubId = '';
        break;
      }
      case SignupEventActionTypes.RESET_QTY: {
        draft.data.divisions = [];
        break;
      }
      case SignupEventActionTypes.SET_COUPON: {
        draft.data.coupon = action.payload;
        break;
      }
      case SignupEventActionTypes.DIVISIONS_CLEAR: {
        draft.data.currentDivisions = [];
        break;
      }
      default:
        break;
    }
  });

export { reducer as signupEventReducer };
