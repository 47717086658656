import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { MatchEventModel } from 'models/Match/MatchModel';
import moment from 'moment';
import { GridItemHTMLElement, GridStack } from 'gridstack';
import { useGetEventRosters } from 'hooks/v3/event/useGetEventRosters';
import DateUtil from 'util/DateUtil';
import { CalendarCourt } from '../../types';
import { colorGroup, stepperIntervals } from '../Scheduler/lib';
import { mapTimeToPosition } from '../../utils';

type GameParams = {
  grid: GridStack | null,
  dayStartTime?: string,
  days: string[],
  courts: CalendarCourt[],
  divisionColors: { [key: string]: string },
  selectedMatch: MatchEventModel,
  getHomeTeamName: (match: MatchEventModel) => string,
  getAwayTeamName: (match: MatchEventModel) => string,
  getMatchTitle: (match: MatchEventModel) => string | string[] | undefined,
};

export const useGetGameData = (data: GameParams) => {
  const {
    grid,
    days,
    dayStartTime,
    courts,
    divisionColors,
    selectedMatch,
    getHomeTeamName,
    getAwayTeamName,
    getMatchTitle,
  } = data;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const day = params.get('day');
  const { eventId } = useParams<{ eventId: string }>();
  const { data: rostersData } = useGetEventRosters({ eventId });

  const courtsOptions = useMemo(() => {
    return courts.map((court) => ({
      value: court.name,
      label: court.name,
    }));
  }, [courts]);

  const clubs = useMemo(() => {
    const allClubs = rostersData
      ?.filter(
        (roster) => roster.ageDivision.id === selectedMatch.ageDivisionId
      )
      ?.map((roster) => ({
        value: roster?.id ?? '',
        label: roster?.club?.name,
      }));

    const uniq = new Set();
    return allClubs?.filter(
      ({ label }) => label && (uniq.has(label) ? false : uniq.add(label))
    );
  }, [rostersData, selectedMatch]);

  const initialCourtSelected = useMemo(() => {
    const item = courts.find(
      (court) =>
        selectedMatch?.subVenue && court.name === selectedMatch?.subVenue
    );

    return {
      value: item?.name ?? '',
      label: item?.name ?? '',
    };
  }, [courts, selectedMatch]);

  const initialDateSelected = useMemo(() => {
    const activeDay = days.find(
      (day) =>
        selectedMatch?.date &&
        moment(day).format('YYYY-MM-DD') ===
          moment(selectedMatch?.date ?? '').format('YYYY-MM-DD')
    );

    return activeDay
      ? moment(activeDay).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
  }, [selectedMatch, days]);

  const initialHomeTeam = useMemo(
    () => ({
      label: getHomeTeamName(selectedMatch),
      value: selectedMatch?.homeTeam?.id ?? '',
    }),
    [selectedMatch]
  );

  const initialAwayTeam = useMemo(
    () => ({
      label: getAwayTeamName(selectedMatch),
      value: selectedMatch?.awayTeam?.id ?? '',
    }),
    [selectedMatch]
  );

  const startTime = useMemo(() => {
    return moment(selectedMatch.date);
  }, [selectedMatch]);

  const changeTimeInnerScheduledElem = (
    element: GridItemHTMLElement,
    title: string,
    time: string,
    homeTeam: string,
    awayClub: string,
    match: MatchEventModel
  ) => {
    const el = element?.querySelector('.grid-stack-item-content .card');
    const editBg =
      colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
        ? '#fff'
        : '#000';
    const editIcon =
      colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
        ? 'edit_black'
        : 'edit_white';
    if (el) {
      el.innerHTML = `
                  <div class='widget'>
                    <div class="title">${title} - ${time}</div>
                    <div class="team">${homeTeam ?? ''}</div>
                    <div class="team">${awayClub ?? ''}</div>
                  </div>
                    <div class='edit_container' style='background-color:${editBg}'>
                        <div class='${editIcon}' />
                    </div>
                  `;
    }
  };

  const homeClubs = useMemo(() => {
    return clubs?.filter((club) => initialHomeTeam.value !== club.value);
  }, [clubs, initialHomeTeam]);

  const awayClubs = useMemo(() => {
    return clubs?.filter((club) => initialAwayTeam.value !== club.value);
  }, [clubs, initialAwayTeam]);

  const updateWidgets = (
    match: MatchEventModel,
    date: string,
    venue: string
  ) => {
    const widgets = grid?.getGridItems();
    const currentWidget = widgets?.find((widget) => {
      const id = widget?.id === '' ? widget.getAttribute('gs-id') : widget?.id;
      return id === `match-${match.matchId}`;
    });
    if (currentWidget) {
      const difY = mapTimeToPosition(
        moment(dayStartTime ?? '', 'HH:mm:ss').format('HH:mm'),
        stepperIntervals
      );
      const time = moment(date).format('hh:mm A');
      changeTimeInnerScheduledElem(
        currentWidget,
        getMatchTitle(match)?.toString() ?? '',
        time ?? '',
        getHomeTeamName(match)?.toString() || '',
        getAwayTeamName(match)?.toString() || '',
        match
      );
      const y =
        mapTimeToPosition(moment(date).format('HH:mm'), stepperIntervals) -
        difY;
      const x = courts.findIndex((court) => court.name === venue) ?? 0;
      grid?.update(currentWidget, {
        x,
        y,
      });
    }
  };

  const removeWidget = (match: MatchEventModel) => {
    const widgets = grid?.getGridItems();
    const currentWidget = widgets?.find((widget) => {
      const id = widget?.id === '' ? widget.getAttribute('gs-id') : widget?.id;
      return id === `match-${match.matchId}`;
    });
    if (currentWidget) {
      console.log('remove widget');
      grid?.removeWidget(currentWidget, true);
    }
  };

  return {
    day,
    homeClubs,
    awayClubs,
    startTime,
    courtsOptions,
    initialCourtSelected,
    initialDateSelected,
    initialHomeTeam,
    initialAwayTeam,
    updateWidgets,
    removeWidget,
  };
};
