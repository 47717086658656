import React, { FC, useCallback, useMemo } from 'react';
import { Camera, IdCardH, IdCardV } from '@icon-park/react';

import { UserType } from 'redux/user/types';
import { User } from 'models/User/UserModel';
import { RequestStatus } from 'models/Request/RequestModel';

import ConvertUtil from 'util/ConvertUtil';

import Logo from 'components/v3/Elements/Logo/Logo';

import { BodyXL, BodyM, COLORS, BodyS } from 'styles/v3/variables';

import {
  ContainerUser,
  UserInformationContent,
  UserIconName,
  UserImage,
  UserMemberType,
  UserDocumentsAndMore,
  UserDocuments,
  UserDocument,
  UserJerseyContainer,
  JersetNumber,
} from './styles';

interface IPlayerOrCoachInformationProps {
  user: User;
  jerseyNumber?: number;
}

export const PlayerOfCoachInformation: FC<IPlayerOrCoachInformationProps> = ({
  user,
  jerseyNumber = '-',
}) => {
  const getDocColorByStatus = useCallback((status?: RequestStatus) => {
    switch (status) {
      case RequestStatus.APPROVED:
        return COLORS.green;
      case RequestStatus.PENDING:
        return '#67e4ff';
      case RequestStatus.DECLINED:
      case RequestStatus.EXPIRED:
        return COLORS.supportError;
      default:
        return COLORS.grey400;
    }
  }, []);

  const documents = useMemo(
    () => [
      {
        icon: Camera,
        text: 'PH',
        excludeRoles: [],
        color: getDocColorByStatus(user.photoVerified),
      },
      {
        icon: IdCardH,
        text: 'AV',
        excludeRoles: [
          UserType.CLUB_DIRECTOR,
          UserType.COACH,
          UserType.REFEREE,
          UserType.STAFF,
          UserType.TEAM_MANAGER,
          UserType.TOURNAMENT_DIRECTOR,
        ],
        color: getDocColorByStatus(user.ageVerified),
      },
      {
        icon: IdCardV,
        text: 'SS',
        excludeRoles: [UserType.PLAYER],
        color: getDocColorByStatus(user.documentationVerified),
      },
    ],
    [user, getDocColorByStatus]
  );

  return (
    <ContainerUser>
      <UserInformationContent>
        <UserIconName>
          <UserImage>
            <img
              src={ConvertUtil.getMinioUrl(user.photo)}
              alt='User Profile pic'
            />
          </UserImage>
          <BodyXL>
            {user.firstName} {user.lastName}
          </BodyXL>
        </UserIconName>
        <UserMemberType>
          <Logo
            width={20}
            height={20}
            minimal
            color={
              user.membership?.type === 'OFFICIAL' ? 'brandPrimary' : 'grey600'
            }
          />
          <BodyM
            $color={user.membership?.type === 'OFFICIAL' ? 'grey50' : 'grey300'}
          >
            {user.membership?.type === 'OFFICIAL'
              ? 'Official Member'
              : 'Community Member'}
          </BodyM>
        </UserMemberType>
      </UserInformationContent>
      <UserDocumentsAndMore>
        <UserDocuments>
          {documents.map((document) => {
            const Icon = document.icon;

            if (user.type && document.excludeRoles.includes(user.type)) {
              return null;
            }

            return (
              <UserDocument>
                <Icon style={{ color: document.color }} />
                <BodyS>{document.text}</BodyS>
              </UserDocument>
            );
          })}
        </UserDocuments>
        {user.type === 'PLAYER' && (
          <UserJerseyContainer>
            <BodyS $color='grey400'>Jersey</BodyS>
            <JersetNumber>{jerseyNumber}</JersetNumber>
          </UserJerseyContainer>
        )}
      </UserDocumentsAndMore>
    </ContainerUser>
  );
};
