import * as React from 'react';
import './group-wrapper.scss';

interface GroupWrapperProps {
  title?: string;
}

const GroupWrapper: React.FunctionComponent<GroupWrapperProps> = (props) => {
  const { title, children } = props;
  return (
    <div className='group-wrapper'>
      <h4>{title}</h4>
      <div>{children}</div>
    </div>
  );
};

export default GroupWrapper;
