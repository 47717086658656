import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { BracketSource } from 'models/Match/MatchModel';
import { BracketType, BracketView } from 'models/Bracket/BracketModel';
import BracketService from 'services/v3/Brackets/BracketService';
import MatchService from 'services/v3/Match/MatchService';

import { useGetBracketsByEventId } from 'hooks/v3/brackets/useGetBracketsByEventId/useGetBracketsByEventId';
import { useGetPoolsEvent } from 'hooks/v3/event/useGetPoolsEvent/useGetPoolsEvent';
import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';

import { EventSideMenu } from 'components/base/EventSideMenu';

import {
  BracketInformationModal,
  ModalUpdateGameInfo,
} from './components/ModalUpdateGameInfo';
import Bracket from './components/Bracket';
import CreateBracketModal from './components/CreateBracketModal';

import {
  Container,
  MainBody,
  MainContainer,
  MainHeader,
  MainHeaderCTA,
  MainHeaderTitle,
  MainHeaderTitleDetail,
} from './styles';

const FormatBrackets: FC = () => {
  const params: { eventId: string } = useParams();

  const { data } = useGetBracketsByEventId(params.eventId);
  const { data: pools } = useGetPoolsEvent(params.eventId);
  const { data: event } = useGetEventById(params.eventId);
  const queryClient = useQueryClient();

  const [modalUpdate, setModalUpdate] = useState(false);
  const [brackets, setBrackets] = useState<BracketView[]>([]);
  const [selectedId, setSelectedId] = useState<string>();
  const [sideModalOpen, setSideModalOpen] = useState(false);
  const [
    selectedBracket,
    setSelectedBracket,
  ] = useState<BracketInformationModal>();

  const divisions = useMemo(() => {
    return event?.ageDivisions?.filter((division) => division.format) || [];
  }, [event]);

  const selectedDivision = useMemo(() => {
    if (!selectedId && divisions.length > 0) {
      setSelectedId(divisions[0].id);

      return divisions[0];
    }

    return divisions.find((division) => division.id === selectedId);
  }, [divisions, selectedId]);

  const handleUpdateGameInfo = async (match: {
    matchId?: string,
    homeTeamBracketSource?: BracketSource,
    awayTeamBracketSource?: BracketSource,
  }) => {
    await MatchService.updateMatches([match]);

    queryClient.invalidateQueries({ queryKey: ['get-matches-by-bracket'] });

    await fetchBrackets();

    setSelectedBracket(undefined);
    setModalUpdate(false);
  };

  const handleOpenEditModal = (
    bracketId: string,
    opponent: number,
    matchId: string
  ) => {
    const bracket = brackets.find((b) => b.id === bracketId);
    setSelectedBracket({ bracket, opponent, matchId });
    setModalUpdate(true);
  };

  const handleCloseModal = () => {
    setSelectedBracket(undefined);
    setModalUpdate(false);
  };

  const fetchBrackets = async () => {
    if (!event || !selectedDivision) {
      setBrackets([]);

      return;
    }

    const {
      data: newBrackets,
    } = await BracketService.getBracketsByEventIdAndAgeDivisionId(
      event.id,
      selectedDivision?.id
    );

    setBrackets(newBrackets);
  };

  const handleCreateBracket = async (type: BracketType) => {
    if (!event?.id || !selectedId) {
      return false;
    }

    await BracketService.createBracket(event.id, selectedId, type);

    fetchBrackets();

    return true;
  };

  useEffect(() => {
    fetchBrackets();
  }, [selectedDivision]);

  return (
    <Container>
      <EventSideMenu
        title='Brackets'
        items={divisions.map((ad) => ({
          id: ad.id,
          status: 'none',
          title: ad.name ?? '',
        }))}
        selectedId={selectedId}
        onSelection={(id) => setSelectedId(id)}
      />
      <MainContainer>
        {selectedDivision && (
          <>
            <MainHeader>
              <MainHeaderTitle>{selectedDivision.name ?? ''}</MainHeaderTitle>
              <MainHeaderTitleDetail>
                {selectedDivision.targetNumberOfTeams} TEAMS
              </MainHeaderTitleDetail>
              <MainHeaderCTA onClick={() => setSideModalOpen(true)}>
                Add new bracket
              </MainHeaderCTA>
            </MainHeader>
            <MainBody>
              {brackets.map((bracket) => (
                <Bracket
                  key={bracket.id}
                  bracket={bracket}
                  onUpdate={fetchBrackets}
                  handleOpenEditModal={handleOpenEditModal}
                />
              ))}
            </MainBody>
          </>
        )}
      </MainContainer>
      <ModalUpdateGameInfo
        isOpen={modalUpdate}
        bracketInformation={selectedBracket}
        pools={pools}
        matches={data.map((dataBracket) => ({
          id: dataBracket.id,
          matches: dataBracket.matches,
        }))}
        handleCloseModal={handleCloseModal}
        handleUpdateGameInfo={handleUpdateGameInfo}
      />
      <CreateBracketModal
        isOpen={sideModalOpen}
        onClose={() => setSideModalOpen(false)}
        onCreate={handleCreateBracket}
      />
    </Container>
  );
};

export default withRouter(FormatBrackets);
