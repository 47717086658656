import { Tooltip } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  ParticipationStatus,
  RosterListing,
} from '../../../models/Roster/RosterModel';
import { Club } from '../../../redux/club/types';
import RosterService from '../../../services/Rosters/RosterService';
import { color, font } from '../../../styles/styleVariables';
import ConvertUtil from '../../../util/ConvertUtil';
import ConfirmBox from '../../base/ConfirmBox/ConfirmBox';

interface IRosterListRowProps {
  club?: Club;
  roster: RosterListing;
  updateRosterList?: () => void;
}

const RosterListRow: React.FunctionComponent<IRosterListRowProps> = (props) => {
  const { roster, club, updateRosterList } = props;
  const [confirmDeleteBox, setConfirmDeleteBox] = React.useState(false);
  const confirmDelete = (rosterId: string) => {
    RosterService.deleteRoster(rosterId).then((res) => {
      setConfirmDeleteBox(false);
      updateRosterList?.call(null);
    });
  };
  return (
    <WrapperBox
      active={roster.status === 'PENDING'}
      className={
        roster.status === 'SUBMITTED' &&
        roster.eventParticipationStatus === ParticipationStatus.DECLINED
          ? 'disabled'
          : ''
      }
    >
      <div className='photo'>
        <div
          className='content'
          style={{
            backgroundImage: `url(${ConvertUtil.getMinioUrl(club?.logo)})`,
          }}
        />
      </div>
      <div className='name'>
        <div className='sub'>{roster?.club?.name}</div>
        <div>{roster?.name}</div>
      </div>
      <div className='info1' style={{ width: '30%' }}>
        <div className='sub'>Tournament</div>
        <div>{roster.event?.name}</div>
      </div>
      <div className='info2'>
        <div className='sub'>Category</div>
        <div>
          {`${roster?.ageDivision.gender} ${
            roster?.ageDivision.years[0] === 1900
              ? 'ADULT'
              : roster?.ageDivision.years.sort().join(', ')
          }
        `}
          {roster?.ageDivision.rule === 'YOUNGER' && (
            <>
              <br />
              <span style={{ fontSize: '11px' }}>(and younger)</span>
            </>
          )}
        </div>
      </div>

      <div className='actions'>
        {roster.status === 'PENDING' && (
          <>
            <div
              className='bt i-icon-trash'
              onClick={() => setConfirmDeleteBox(true)}
              onKeyDown={() => setConfirmDeleteBox(true)}
              role='button'
              tabIndex={0}
              aria-label='Delete Roster'
            />
            <Link to={`/club-director/rosters/edit/${roster?.id}`}>
              <div className='bt i-icon-edit' />
            </Link>
          </>
        )}
        {roster.status === 'SUBMITTED' && (
          <Link to={`/club-director/rosters/edit/${roster?.id}`}>
            <Tooltip
              placement='leftTop'
              title={() => (
                <div>
                  This Roster is <b>SUBMITTED</b>. But you still can view it
                </div>
              )}
            >
              <div className='bt grey i-icon-lock' />
            </Tooltip>
          </Link>
        )}

        {roster.status === 'SUBMITTED' &&
          roster.eventParticipationStatus === ParticipationStatus.PENDING && (
            <Tooltip
              placement='leftTop'
              title={() => <div>This Roster under review for approval</div>}
            >
              <div className='bt grey i-icon-hourglass' />
            </Tooltip>
          )}

        {roster.status === 'SUBMITTED' &&
          roster.eventParticipationStatus ===
            ParticipationStatus.WAITING_PAYMENT && (
            <Tooltip
              placement='leftTop'
              title={() => <div>The payment is pending</div>}
            >
              <div className='bt grey i-icon-payment' />
            </Tooltip>
          )}

        {roster.status === 'SUBMITTED' &&
          roster.eventParticipationStatus === ParticipationStatus.APPROVED && (
            <Tooltip
              placement='leftTop'
              title={() => (
                <div>This Roster is confirmed in the tournament</div>
              )}
            >
              <div className='bt grey i-icon-check-green' />
            </Tooltip>
          )}

        {roster.status === 'SUBMITTED' &&
          roster.eventParticipationStatus === ParticipationStatus.DECLINED && (
            <Tooltip
              placement='leftTop'
              title={() => <div>This Roster was declined</div>}
            >
              <div className='bt grey i-icon-close-white' />
            </Tooltip>
          )}
      </div>
      <ConfirmBox
        isOpen={confirmDeleteBox}
        onClose={() => setConfirmDeleteBox(false)}
        onConfirm={() => confirmDelete(roster?.id)}
        onCancel={() => setConfirmDeleteBox(false)}
        title='Are you sure you want delete this Roster?'
        labelConfirm='Yes'
        labelCancel='Cancel'
      />
    </WrapperBox>
  );
};

const WrapperBox = styled.div<{ active?: boolean }>`
  padding: 10px 15px;
  border-radius: 10px;
  background-color: ${(props) => (props.active ? color.orange : color.silver)};
  display: flex;
  font-family: ${font.druk};
  margin: 10px 0;
  &.disabled {
    opacity: 0.5;
  }
  .photo {
    width: 10%;
    align-self: center;
    .content {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      background-color: #fff;
      background-size: cover;
      background-position: center;
      background-size: 80%;
      background-repeat: no-repeat;
    }
  }
  .name,
  .info1,
  .info2 {
    .sub {
      font-family: 'Montserrat', sans-serif;
    }
    width: 25%;
    padding-right: 10px;
  }
  .actions {
    display: flex;
    text-align: center;
    margin-left: auto;
    align-self: center;
    .bt {
      width: 40px;
      height: 40px;
      background: #fff;
      border-radius: 20px;
      margin: 0 5px;
      cursor: pointer;
      position: relative;
      &.grey {
        background: ${color.greyLight};
      }
      &::before {
        position: absolute;
        width: 15px;
        top: 12px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
      &:hover {
        background: ${color.silverLight};
      }
    }
  }
`;

export default RosterListRow;
