import React from 'react';

import * as S from './styles';

export default function NotFound() {
  return (
    <S.Container>
      <S.Title>404</S.Title>
      <S.Subtitle>Oops! Error 404 - Page Not Found</S.Subtitle>
    </S.Container>
  );
}
