import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { color } from '../../../styles/styleVariables';
import LightBox from '../LightBox/LightBox';

interface IConfirmBoxProps {
  isOpen: boolean;
  title: string;
  text?: string;
  labelConfirm: string;
  labelCancel: string;
  textArea?: string;
  onConfirm: (text?: string) => void; // Button Confirm trigger it
  onCancel: () => void; // Button Cancel trigger it
  onClose: () => void; // Click out of box trigger it
}

const ConfirmBox: React.FunctionComponent<IConfirmBoxProps> = (props) => {
  const {
    onConfirm,
    onCancel,
    text,
    title,
    labelConfirm,
    labelCancel,
    textArea,
    isOpen,
    onClose,
  } = props;

  const [reason, setReason] = useState('');

  // const test = (reason: string) => {
  //   console.log(reason);
  // };
  const handleChangeReason = (event: React.FormEvent<HTMLTextAreaElement>) => {
    setReason(event.currentTarget.value);
  };
  return (
    <LightBox isOpen={isOpen} onClose={onClose}>
      <Wrapper>
        <h3 style={{ textAlign: 'center' }}>{title}</h3>
        {text && <p>{text}</p>}
        {textArea && (
          <div className='form-group'>
            <label>
              Describe the reason
              <textarea value={reason} onChange={handleChangeReason} />
            </label>
          </div>
        )}
        <div style={{ textAlign: 'center' }}>
          <div onClick={onCancel} className='bt'>
            {labelCancel}
          </div>
          <span> </span>
          {textArea && (
            <div onClick={() => onConfirm(reason)} className='bt confirm'>
              {labelConfirm}
            </div>
          )}
          {!textArea && (
            <div onClick={() => onConfirm()} className='bt confirm'>
              {labelConfirm}
            </div>
          )}
        </div>
      </Wrapper>
    </LightBox>
  );
};

const Wrapper = styled.div`
  padding: 50px;
  box-sizing: border-box;
  background: ${color.greyLight};
  border-radius: 20px;
  max-width: 500px;

  .bt {
    display: inline-block;
    font-weight: 600;
    margin: 10px 10px;
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 5px;
    color: #fff;
    &.confirm {
      background-color: #fff;
      color: ${color.greyLight};
    }
  }
`;

export default ConfirmBox;
