import { Tooltip } from 'antd';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { RosterMemberpool } from '../../../models/Roster/RosterModel';
import { MemberpoolState } from '../../../redux/memberpool/types';
import { rosterFetchAddPlayer } from '../../../redux/roster/actions';
import { ApplicationState } from '../../../redux/store';
import { color } from '../../../styles/styleVariables';
import ConvertUtil from '../../../util/ConvertUtil';
import DateUtil from '../../../util/DateUtil';

interface IPlayerPoolRowProps {
  user?: RosterMemberpool;
  inactive?: boolean;
}

const MemberPoolSideboxRow: React.FunctionComponent<IPlayerPoolRowProps> = (
  props
) => {
  const { user, inactive } = props;
  const routeParams: { rosterId: string } = useParams();
  const dispatch = useDispatch();
  const memberships = useSelector(
    (state: ApplicationState) => state.memberships
  );

  const addUserToRoster = (userId: string) => {
    if (!inactive) {
      dispatch(rosterFetchAddPlayer(routeParams.rosterId, userId));
    }
  };

  const memberPool = useSelector(
    (state: ApplicationState) => state.memberpool.data
  );

  const getAvaiabilityDetails = (userId: string) => {
    return memberPool.find((player) => player.id === userId);
  };

  return (
    <RowBox className={inactive ? 'inactive' : ''}>
      <Tooltip title='prompt text' />
      <div
        className='photo'
        style={{
          backgroundImage: `url(${ConvertUtil.getMinioUrl(user?.photo, 200)})`,
        }}
      />
      <div className='info'>
        <div className='name'>{`${user?.firstName} ${user?.lastName}`}</div>
        <div className='birthday'>{`${user?.gender}, ${
          user?.birthDate
            ? DateUtil.getYear(new Date(user.birthDate.toString()))
            : ''
        }`}</div>
        <div className='type'>
          {
            memberships.data.find((item) => item.id === user?.membershipId)
              ?.name
          }
        </div>
      </div>
      {inactive && user && (
        <Tooltip
          placement='topRight'
          title={() => (
            <>
              {!user?.rosterAvailability.age && (
                <div>
                  <b>Age</b> doesn&lsquo;t match the requirements
                </div>
              )}

              {!user?.rosterAvailability.ageProof && (
                <div>
                  The <b>Age Verification</b> is{' '}
                  <b>{getAvaiabilityDetails(user.id)?.ageVerified}</b>
                </div>
              )}

              {!user?.rosterAvailability.document && (
                <div>
                  The <b>Documents</b> is{' '}
                  <b>{getAvaiabilityDetails(user.id)?.documentationVerified}</b>
                </div>
              )}

              {!user?.rosterAvailability.photo && (
                <div>
                  The <b>Photo</b> is{' '}
                  <b>{getAvaiabilityDetails(user.id)?.photoVerified}</b>
                </div>
              )}
              {!user?.rosterAvailability.gender && (
                <div>
                  <b>Gender</b> doesn&lsquo;t match the requirements
                </div>
              )}
              {!user?.rosterAvailability.membership && (
                <div>
                  <b>Membership</b> doesn&lsquo;t match the requirements
                </div>
              )}
            </>
          )}
        >
          <div className='blocked' />
        </Tooltip>
      )}
      {!inactive && user?.id && (
        <div
          className='add'
          onClick={() => addUserToRoster(user?.id)}
          onKeyDown={() => addUserToRoster(user?.id)}
          role='button'
          tabIndex={0}
          aria-label='Add Roster'
        />
      )}
    </RowBox>
  );
};

const RowBox = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: 0px 76px 1fr 40px;
  grid-column-gap: 10px;
  background-color: ${color.greyLight};
  border-radius: 10px;
  align-items: center;
  margin: 10px 0;
  &.inactive {
    opacity: 0.5;
  }
  .photo {
    margin-right: 20px;
    width: 56px;
    height: 56px;
    border-radius: 30px;
    background-color: #fff;
    background-size: cover;
    background-position: center;
  }
  .info {
    font-weight: 600;
    .type {
      color: ${color.orange};
    }
  }
  .add,
  .blocked {
    height: 40px;
    width: 40px;
    border-radius: 5px;
    background-color: ${color.silver};
    margin-left: auto;
    position: relative;
    transition: all 0.3s;

    &:hover {
      background-color: ${color.orange};
    }
    &::after {
      position: absolute;
      top: 12px;
      left: 11px;
    }
  }
  .blocked {
    cursor: not-allowed;
    &::after {
      content: url('/assets/imgs/icons/ico_forbidden.svg');
    }
  }
  .add {
    cursor: pointer;
    &::after {
      content: url('/assets/imgs/icons/ico_plus_black.svg');
    }
  }
`;

export default MemberPoolSideboxRow;
