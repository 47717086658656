import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const ButtonWrapper = styled.div`
  button {
    width: 100%;
    color: ${COLORS.grey50};
    background-color: ${COLORS.grey950};
    border-color: ${COLORS.grey50};

    font-family: 'Boxed Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;

    &:hover {
      background-color: ${COLORS.grey900};
      border-color: ${COLORS.grey50};
    }
  }
`;
