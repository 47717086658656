import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useNavigationBlocker = () => {
  const location = useLocation();
  const history = useHistory();

  const [initialisedBlocker, setInitialisedBlocker] = useState(false);
  const [nextLocation, setNextLocation] = useState<any>(null);
  const [openedPrompt, setOpenedPrompt] = useState(false);
  const [unblock, setUnblock] = useState<(() => void) | null>(null);

  useEffect(() => {
    if (!initialisedBlocker) {
      return () => {};
    }

    const unblockFunction = history.block((nextLocation) => {
      const ignoredRoutes = [location.pathname];

      if (!ignoredRoutes.includes(nextLocation.pathname)) {
        setNextLocation(nextLocation);
        setOpenedPrompt(true);

        return false;
      }

      // eslint-disable-next-line consistent-return, no-useless-return
      return;
    });

    setUnblock(() => unblockFunction);

    return () => {
      unblockFunction();
    };
  }, [history, location, initialisedBlocker]);

  const handlePromptConfirm = useCallback(() => {
    setOpenedPrompt(false);

    if (nextLocation && unblock) {
      unblock();

      history.push(`${nextLocation.pathname}${nextLocation.search}`);
    }
  }, [nextLocation, unblock]);

  const handleResetNavigationBlocker = useCallback(() => {
    unblock?.();
  }, [unblock]);

  const handlePromptCancel = useCallback(() => {
    setOpenedPrompt(false);
    setNextLocation(null);
  }, []);

  const handleInitialiseBlocker = useCallback(() => {
    setInitialisedBlocker(true);
  }, []);

  return {
    openedPrompt,
    initialisedBlocker,
    handlePromptConfirm,
    handlePromptCancel,
    handleInitialiseBlocker,
    handleResetNavigationBlocker,
  };
};
