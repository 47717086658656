import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SettingTwo, Printer } from '@icon-park/react';

import { EventStatus } from 'components/v3/Cards/EventCard/styles';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';

import ConvertUtil from 'util/ConvertUtil';

import { TitleH2 } from 'styles/v3/variables';

import FallbackImage from 'assets/imgs/event_placeholder.png';

import { PrintModal } from './components/PrintModal/PrintModal';

import * as S from './styles';

interface IHeaderProps {
  id?: string;
  title?: string;
  imgSrc?: string;
  hasAccessedUserType?: boolean;
}

export const Header: FC<IHeaderProps> = ({
  id,
  title,
  imgSrc,
  hasAccessedUserType = false,
}) => {
  const history = useHistory();
  const location = useLocation();

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const handleGoBack = () => {
    const params = new URLSearchParams(location.search);

    const prevRoute = params.get('prev');

    if (prevRoute) {
      history.push(`/v3/events?tab=${prevRoute}`);
    } else {
      history.push('/v3/events?tab=created-by-me');
    }
  };

  const handleGoToEventSetup = () => {
    if (id)
      history.push(`/v3/setup-event/my-events/${id}`, { from: 'dashboard' });
  };

  const handlePrintFiles = () => {
    setIsPrintModalOpen(true);
  };

  return (
    <>
      <PrintModal
        isOpen={isPrintModalOpen}
        handleIsOpen={setIsPrintModalOpen}
      />
      <S.HeaderContainer
        image={imgSrc ? ConvertUtil.getMinioUrl(imgSrc) : FallbackImage}
      >
        <TextButtonWithIcon
          className='goBack'
          reverse
          icon='back'
          color='primary'
          align='flex-end'
          onClick={handleGoBack}
        >
          Go back
        </TextButtonWithIcon>
        <S.Flex>
          <S.TitleWrapper>
            <TitleH2>{title}</TitleH2>
            <EventStatus eventStatus='published'>Published</EventStatus>
          </S.TitleWrapper>
          <S.ButtonsWrapper className={hasAccessedUserType ? 'disabled' : ''}>
            <FilledButtonWithIcon
              isUpper
              customIcon={<Printer />}
              onClick={handlePrintFiles}
            >
              Print Files
            </FilledButtonWithIcon>
            <FilledButtonWithIcon
              isUpper
              customIcon={<SettingTwo />}
              onClick={handleGoToEventSetup}
            >
              Event Settings
            </FilledButtonWithIcon>
          </S.ButtonsWrapper>
        </S.Flex>
      </S.HeaderContainer>
    </>
  );
};
