import React from 'react';

import Logo from 'components/v3/Elements/Logo/Logo';

import { BodyXS } from 'styles/v3/variables';

import * as S from './styles';

export const MemberTag = () => {
  return (
    <S.TagWrapper>
      <Logo minimal color='brandPrimary' />
      <BodyXS $isUpper $color='brandPrimary'>
        Member
      </BodyXS>
    </S.TagWrapper>
  );
};
