import { GenderCategory } from 'admin/models/Enums';

export const gendersLabel = {
  [GenderCategory.MALE]: 'Boys',
  [GenderCategory.FEMALE]: 'Girls',
  [GenderCategory.COED]: 'Coed',
};

export const allowedOtherGenders = {
  [GenderCategory.MALE]: 'Allow Girls',
  [GenderCategory.FEMALE]: 'Allow Boy',
};
