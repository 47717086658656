import { Col, Row } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import Steps from '../../components/Steps/Steps';
import { ApplicationState } from '../../redux/store';
import { UserType } from '../../redux/user/types';
import DateUtil from '../../util/DateUtil';
import {
  BoxInfo,
  NextButtonsWrapper,
  ErrorValidationBox,
  TermsBox,
  SignatureBox,
  TopHeader,
} from './WizardComponents.style';
import ReleaseTerms from 'pages/Terms/ReleaseTerms';

const WizardTerms: React.FunctionComponent = (props) => {
  const { register, handleSubmit, errors } = useForm<{
    terms_1: string;
  }>();

  const { newUser } = useSelector(
    (state: ApplicationState) => state.newUser
  ).data;
  const history = useHistory();

  function nextScreen() {
    if (newUser?.type === UserType.PLAYER) {
      history.push('/wizard/payment');
    }
    // If UserType is Coach or Director but Membership Free, go to finish
    else if (
      newUser?.membershipPrice === 0 &&
      newUser?.type === UserType.COACH
    ) {
      history.push('/wizard/payment');
    } else {
      history.push('/wizard/additionalinfo');
    }
  }

  const [waiverRead, setWaiverRead] = useState(false);

  const handleTermsScroll = (event: any) => {
    if (
      event.target.scrollHeight - event.target.scrollTop - 20 <
      event.target.clientHeight
    ) {
      setWaiverRead(true);
    }
  };

  return (
    <div>
      <TopHeader>
        <h5>
          COMPLETE YOUR HOUSEHOLD USER CREATION TO JOIN THE UNITED FUTSAL
          COMMUNITY!
        </h5>
        <h1>READ AND ACCEPT TERMS AND CONDITIONS</h1>
      </TopHeader>
      <Row>
        <Col xxl={18} lg={18} md={24} className='column-wizard'>
          <Steps
            activeStep={newUser?.type === UserType.CLUB_DIRECTOR ? 3 : 4}
          />
          <form onSubmit={handleSubmit(nextScreen)}>
            <TermsBox data-testid='terms-1' onScroll={handleTermsScroll}>
              <h3>Release and Waiver of Liability and Publicity</h3>
              <h5>You Must Read This Document in its Entirety to Proceed</h5>
              <ReleaseTerms />
              {!waiverRead && (
                <p style={{ marginTop: '10px' }}>
                  Please read the waiver before agree with terms and conditions.
                  <br />
                  Scroll to accept terms and conditions.
                </p>
              )}
              <div
                className='form-group'
                style={{ display: waiverRead ? 'block' : 'none' }}
              >
                <label htmlFor='terms_1' className='control control-checkbox'>
                  I AGREE to ALL terms and conditions.
                  <input
                    type='checkbox'
                    id='terms_1'
                    value='true'
                    name='terms_1'
                    ref={register({ required: true })}
                  />
                  <div className='control_indicator' />
                  <ErrorValidationBox>
                    {errors.terms_1 &&
                      'You need to accept the terms and conditions'}
                  </ErrorValidationBox>
                </label>
              </div>
            </TermsBox>
          </form>
          <SignatureBox>
            <span className='icon clear icon-user-black' />
            {`${newUser?.firstName} ${newUser?.lastName} - ${
              newUser?.birthDate
                ? DateUtil.formatDate(new Date(newUser?.birthDate))
                : ''
            }`}
          </SignatureBox>
          <BoxInfo>
            <p>
              By checking the boxes above and clicking NEXT STEP below you
              authorize United Futsal to authenticate the requested
              documentation and accept and acknowledge the Publicity Release and
              Liability Release presented to you during the application process
              for both you and this User.
            </p>
          </BoxInfo>
          <NextButtonsWrapper data-testid='wizard-nav-wrapper'>
            {newUser?.type !== UserType.CLUB_DIRECTOR && (
              <Link to='/wizard/memberships'>
                <div style={{ margin: '0 10px' }}> ◄ PREVIOUS STEP</div>
              </Link>
            )}
            {newUser?.type === UserType.CLUB_DIRECTOR && (
              <Link to='/wizard/photo'>
                <div style={{ margin: '0 10px' }}> ◄ PREVIOUS STEP</div>
              </Link>
            )}
            <Link to='/wizard/payment'>
              <div
                className={`link ${!waiverRead ? 'disabled' : ''}`}
                onClick={handleSubmit(nextScreen)}
                onKeyDown={handleSubmit(nextScreen)}
                role='button'
                aria-label='Next Step'
                tabIndex={0}
              >
                NEXT STEP ►
              </div>
            </Link>
          </NextButtonsWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(WizardTerms);
