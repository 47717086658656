import React, { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Keycloak from 'keycloak-js';

import { resetCurrentUser } from 'redux/v3/currentUser/actions';

import Loading from 'components/v3/Loading/Loading';
import { FUTSAL_SEASON_CONFIRM_KEY } from 'components/v3/FutsalSeasonResetConfirm/configs';

interface IV3LogoutProps {
  auth?: Keycloak.KeycloakInstance | undefined;
}

const clearStorageExepectSpecificItems = () => {
  const futsalSeasonConfirmTime = localStorage.getItem(
    FUTSAL_SEASON_CONFIRM_KEY
  );

  localStorage.clear();

  if (futsalSeasonConfirmTime) {
    localStorage.setItem(FUTSAL_SEASON_CONFIRM_KEY, futsalSeasonConfirmTime);
  }
};

const V3Logout: FC<IV3LogoutProps> = ({ auth }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = useCallback(async () => {
    if (auth?.authenticated) {
      clearStorageExepectSpecificItems();

      await auth?.logout();

      dispatch(resetCurrentUser());
    }

    history.replace('/v3/login');
  }, [auth, history]);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return (
    <div>
      <Loading />
    </div>
  );
};

export default V3Logout;
