import styled from 'styled-components';
import { color, font } from '../../styles/styleVariables';

import list_check from '../../assets/imgs/icons/list_check.svg';
import list_uncheck from '../../assets/imgs/icons/list_uncheck.svg';

const MembershipSelectionWrapper = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  .type-wrapper {
    width: 400px;
    @media (max-width: 768px) {
      width: 100%;
    }
    .type-box {
      width: 356px;
      margin-bottom: 20px;
      opacity: 0.5;
      cursor: pointer;
      box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.1);
      transition: all 0.5s;
      @media (max-width: 768px) {
        width: 100%;
      }
      &:hover {
        opacity: 1;
        box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.3);
      }
      &.active {
        opacity: 1;
        .top {
          background-color: ${color.orange};
        }
      }
      &.current {
        box-shadow: 0 0 0 1px ${color.orange};
        //cursor: not-allowed;
        opacity: 0.5;
      }
      .top {
        background-color: ${color.silver};
        padding: 12px 20px;
        border-radius: 10px;
        color: ${color.greyDark};
        font-size: 12px;
        font-weight: 500;
        position: relative;
        z-index: 10;
        text-transform: uppercase;
        span {
          color: #fff;
        }
      }
      .content {
        margin-top: -10px;
        position: relative;
        z-index: 1;
        height: 150px;
        background-color: ${color.greyLight};
        border-radius: 0px 0px 10px 10px;
        text-align: center;
        font-size: 22px;
        padding: 60px 20px;
        font-family: ${font.druk};
        span {
          color: ${color.orange};
        }
        @media (max-width: 768px) {
          height: auto;
          padding: 20px 20px;
        }
      }
    }
  }
  .description-box {
    background-color: ${color.greyLight};
    padding: 30px;
    margin-left: 20px;
    border-radius: 10px;
    @media (max-width: 768px) {
      margin-left: 0;
    }
    li {
      list-style: none;

      ul {
        padding-left: 20px;
        position: relative;
        color: ${color.silver};
        transition: all 0.5s;
        &::before {
          left: 0px;
          top: 2px;
          content: url(${list_uncheck});
          height: 20px;
          width: 20px;
          position: absolute;
        }
      }
      &.active ul {
        color: #fff;
        opacity: 1;
        &::before {
          content: url(${list_check});
        }
      }
    }
  }
`;

export default MembershipSelectionWrapper;
