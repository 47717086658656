import styled from 'styled-components';

import { color, font } from 'styles/styleVariables';

export const MobileMenuWrapper =
  styled.div <
  { countSubItens: number } >
  `
  @media (min-width: 769px) {
    display: none;
  }
  .menu-content {
    width: 70%;
    height: 100%;
    position: fixed;
    background-color: ${color.greyDark};
    z-index: 50;
    left: -70%;
    transition: all 0.5s;
    border-right: solid 1px ${color.orange};
    overflow: auto;
    &.opened {
      left: 0;
    }
    ul {
      list-style: none;
      padding: 0;
    }
    ul li {
      font-family: ${font.druk};
      text-transform: uppercase;
      padding: 20px 30px;
      font-size: 11px;
      &:hover {
        background-color: ${color.orange};
        color: ${color.greyDark};
      }
    }
  }
  .menu-bottom {
    height: 60px;
    width: 100%;
    background-color: ${color.greyDark};
    position: fixed;
    display: flex;
    bottom: 0;
    z-index: 50;
    .left-bt,
    .right-bt {
      width: 20%;
      padding: 17px;
      text-align: center;
      background-color: ${color.greyDark};
      z-index: 10;
      cursor: pointer;
      img {
        max-width: 30px;
        width: 100%;
      }
    }
    .middle-bt {
      width: 60%;
      padding: 20px 0;
      text-align: center;
      font-family: ${font.druk};
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: relative;
      z-index: 1;
      .avatar {
        height: 40px;
        width: 40px;
        border-radius: 25px;
      }
      .name {
        width: 80%;
        text-align: center;
        font-family: ${font.druk};
      }
    }
    .players-list {
      position: absolute;
      width: 70%;
      background-color: ${color.orange};
      z-index: 5;
      bottom: ${(props) =>
        props.countSubItens ? `${props.countSubItens * -60}px` : '-1000px'};
      border-radius: 20px 20px 0px 0px;
      overflow: hidden;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      transition: all 0.5s;
      &.opened {
        bottom: 0;
        max-height: 90vh;
        overflow-y: auto;
        .item {
          &:first-child {
            .name {
              &:after {
                content: '▲';
              }
            }
          }
        }
      }
      .item {
        display: flex;
        align-items: center;
        height: 60px;
        padding: 10px 50px;
        cursor: pointer;
        background-color: ${color.greyLight};
        border-bottom: solid 1px ${color.silver};
        &:hover {
          background-color: ${color.silver};
        }
        &:first-child {
          padding-top: 25px;
          height: 75px;
          background-color: ${color.orange};
          .name {
            color: ${color.greyDark};
            &::after {
              font-size: 10px;
              content: '▼';
              height: 10px;
              width: 10px;
              top: -1px;
              position: absolute;
              margin-left: 10px;
            }
          }
        }
        .avatar-box {
          width: 20%;
          .avatar {
            height: 40px;
            width: 40px;
            border-radius: 25px;
            margin: 0 auto;

            background-size: cover;
          }
        }
        .name {
          width: 80%;
          text-align: center;
          font-size: 10px;
          font-family: ${font.druk};
          //line-height: 40px;
          text-transform: uppercase;
          color: #fff;
          position: relative;
        }
      }
    }
  }
`;
