import React from 'react';

import { BodyLBold, BodyM, TitleH3 } from 'styles/v3/variables';

import * as S from './styles';
import { tierData } from './tierData';

type EventTierCardProps = {
  multiplier: number;
  extra?: React.ReactNode;
};

export const EventTierCard = ({
  multiplier,
  extra = null,
}: EventTierCardProps) => {
  const selectedTier = tierData.find(
    (tier) =>
      multiplier >= tier.minMultiplier && multiplier <= tier.maxMultiplier
  );

  if (!selectedTier) return null;

  return (
    <S.Container>
      <img src={selectedTier.image} alt={`${selectedTier.tier} tier event`} />
      <S.TitleWrapper>
        <BodyLBold>{selectedTier.tier} Event</BodyLBold>
        <S.MultiplierWrapper multiplierColor={selectedTier.multiplierColor}>
          <BodyM $isUpper $color='grey400'>
            Point multiplier
          </BodyM>
          <TitleH3>{multiplier}x</TitleH3>
        </S.MultiplierWrapper>
      </S.TitleWrapper>
      {extra}
    </S.Container>
  );
};
