import { message, Upload } from 'antd';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ico_plus from '../../assets/imgs/icons/ico_plus.svg';
import { newUserUpdatePhoto } from '../../redux/newUser/actions';
import { ApplicationState } from '../../redux/store';
import UploadService from '../../services/upload/UploadService';
import { color } from '../../styles/styleVariables';
import ConvertUtil from '../../util/ConvertUtil';
import EditPhoto from '../base/EditPhoto/EditPhoto';
import LoadingIcon from '../base/LoadingIcon/LoadingIcon';
import messages from '../../util/messages';

interface SingleUploadProps {
  value?: string; // use this property for preview a file uploaded before
  validExtensions?: string[];
  publicFile?: boolean;
  prefix: 'user' | 'team' | 'tournament';
}

const PhotoUpload: React.FunctionComponent<SingleUploadProps> = (props) => {
  const uploadRef = useRef<HTMLDivElement>(null);
  const { newUser } = useSelector(
    (state: ApplicationState) => state.newUser
  ).data;
  const dispatch = useDispatch();

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(messages.photoUpload.fileType);
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error(messages.photoUpload.fileSize);
    }
    return isJpgOrPng && isLt2M;
  };

  const [editPhotoOpen, setEditPhotoOpen] = useState(false);
  const [editedImg, setEditedImg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const uploadEdited = (file: File) => {
    const type = 'photo';
    setIsLoading(true);
    UploadService.upload({
      file,
      prefix: 'user',
      public: type === 'photo',
    }).then((response) => {
      if (!response.success) {
        message.error({
          content: response.message || messages.photoUpload.genericError,
          duration: 5
        });
        return;
      }
      const url = response.data.id;
      if (type === 'photo') {
        dispatch(newUserUpdatePhoto(url));
        setEditedImg(url);
      }
      setIsLoading(false);
    });
  };

  const upload = (event: any) => {
    setIsLoading(true);
    UploadService.upload({
      file: event.file,
      prefix: props.prefix.toString(),
      public: props.publicFile || true,
    }).then((response) => {
      if (!response.success) {
        message.error({
          content: response.message || messages.photoUpload.genericError,
          duration: 5
        });
        setIsLoading(false);
        return;
      }
      dispatch(newUserUpdatePhoto(response.data.id));
      setIsLoading(false);
      setEditPhotoOpen(true);
    });
  };

  return (
    <BoxWrapper>
      <Box className={newUser?.photo ? 'hide' : ''}>
        <div className='title'>Example of the photo</div>
        <BoxImage className='example' />
        <div className='bottom-text'>2x2 with white background</div>
      </Box>
      <Box>
        <div className='title'>Upload your Image</div>

        {isLoading && <LoadingIcon text={messages.photoUpload.uploading} />}
        {!isLoading && (
          <BoxImage
            style={{
              border: `solid 1px ${color.orange}`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${ConvertUtil.getMinioUrl(
                editedImg || newUser?.photo
              )})`,
            }}
          >
            {newUser?.photo && <Mask />}

            <EditPhoto
              imgURL={`${ConvertUtil.getMinioUrl(newUser?.photo, 1000)}?t=rand`}
              afterEdit={uploadEdited}
              open={editPhotoOpen}
              onClose={() => setEditPhotoOpen(false)}
            />

            <Upload
              showUploadList={false}
              customRequest={upload}
              beforeUpload={beforeUpload}
            >
              {!newUser?.photo && (
                <PlusIcon data-testid='image-upload-box'>
                  <div className='icon'>
                    <img alt='' width='20px' src={ico_plus} />
                  </div>
                </PlusIcon>
              )}
              {newUser?.photo && (
                <PlusIcon data-testid='image-upload-box'>
                  <div className='icon' ref={uploadRef} />
                </PlusIcon>
              )}
            </Upload>
          </BoxImage>
        )}
        {newUser?.photo && (
          <>
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <h5 style={{ fontWeight: 700, color: `${color.orange}` }}>
                CHECK THE PHOTO!
              </h5>
              <p>Make sure your face is centralized inside the circle.</p>
            </div>
            <Button onClick={() => uploadRef.current?.click()}>
              Replace Image
            </Button>
          </>
        )}
      </Box>
    </BoxWrapper>
  );
};

const BoxImage = styled.div`
  position: relative;
  width: 60%;
  padding-bottom: 60%;
  height: 0;
  border-radius: 8px;
  margin: 0 auto;
  background: ${color.greyDark};
  margin-top: 20px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  &.example {
    background-image: url('https://www.dfa.ie/media/dfa/passport/passportphotographs/Pose-and-Expression---Acceptable-300x458.jpg');
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Mask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 1px 1px;
  border-radius: 50%;
  box-shadow: 1px 1px 900px 900px;
  color: rgba(0, 0, 0, 0.5);
`;
const Box = styled.div`
  width: 48%;
  border-radius: 8px;
  padding: 20px;
  border: solid 1px ${color.greyLight};
  background: ${color.greyDark};
  @media (max-width: 768px) {
    width: 80%;
    margin-bottom: 10px;
  }
  &.hide {
    display: none;
  }
  .title {
    text-align: center;
  }
  .bottom-text {
    margin: 20px 0;
    text-align: center;
  }
`;

const PlusIcon = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  .icon {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }
`;

const Button = styled.div`
  padding: 10px 20px;
  margin: 20px auto;
  width: 75%;
  font-weight: 500;
  border-radius: 10px;
  text-align: center;
  background-color: ${color.orange};
  color: #fff;
  cursor: pointer;
`;

export default PhotoUpload;
