import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

type StripeCardWrapperProps = { $width?: string };

export const StripeCardWrapper =
  styled.div <
  StripeCardWrapperProps >
  `
  ${(props) => !!props.$width && `width: 100%;`}
  .simple-card-wrapper {
    width: 100%;
    height: 100%;
    border-color: ${COLORS.grey800};
    justify-content: space-between;
    align-items: center;
  }
`;
export const Stripe =
  styled.span <
  { $color: string } >
  `
  width: 9px;
  min-height: ${toRem(40)};
  border-radius: 21px;
  background-color: ${(props) => props.$color};
  margin-right: ${toRem(8)};

  &:nth-child(2n) {
    background-color: ${COLORS.blue};
  }
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const BgIcon = styled.div`
  border-radius: 12px;
  padding: ${toRem(8)};
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: ${COLORS.grey800};

  & + div {
    margin-top: ${toRem(8)};
  }
`;
export const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
