import React from 'react';
import { BodyL, BodyLBold } from 'styles/v3/variables';
import { useApproveOrDeclineApplication } from 'hooks/v3/referees/useApproveOrDeclineApplication';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { CloseSmall } from '@icon-park/react';
import {
  CloseButton,
  Container,
  HeaderTitle,
  HeaderWrapper,
  StyledModal,
} from './styles';

type AlertModalProps = {
  isOpen: boolean,
  eventId: string,
  refereeId: string,
  onClose: () => void,
  type: 'disapprove' | 'approve',
};

export const AlertModal = ({
  type,
  isOpen,
  onClose,
  eventId,
  refereeId,
}: AlertModalProps) => {
  const { mutateAsync } = useApproveOrDeclineApplication({ eventId, onClose });

  const buttonText = type === 'disapprove' ? 'deny' : type;

  const handleAction = async () => {
    await mutateAsync({ type, eventId, refereeId });
  };

  return (
    <StyledModal
      centered
      footer={false}
      closable={false}
      visible={isOpen}
      onOk={onClose}
      onCancel={onClose}
    >
      <Container $type={type}>
        <HeaderWrapper>
          {type === 'approve' ? (
            <HeaderTitle>
              <BodyL>Are you sure you want to</BodyL>
              <BodyLBold>APPROVE</BodyLBold>
              <BodyL>this referee?</BodyL>
            </HeaderTitle>
          ) : (
            <HeaderTitle>
              <BodyL>Are you sure you want to</BodyL>
              <BodyLBold>DENY</BodyLBold>
              <BodyL>this referee?</BodyL>
            </HeaderTitle>
          )}
          <CloseButton onClick={onClose}>
            <CloseSmall size='24px' />
          </CloseButton>
        </HeaderWrapper>

        <FilledButton
          isUpper
          isBold
          onClick={handleAction}
          color={type === 'approve' ? 'primary' : 'support-error'}
        >
          {buttonText}
        </FilledButton>
      </Container>
    </StyledModal>
  );
};
