import React from 'react';
import { BodyL, TitleH4 } from 'styles/v3/variables';

import { PaymantAndValueContainer } from './styles';

export interface PaymentAndValueProps {
  title: string;
  value: number;
}

export const PaymentAndValue: React.FC<PaymentAndValueProps> = ({
  title,
  value,
  ...props
}) => {
  return (
    <PaymantAndValueContainer {...props}>
      <BodyL>{title}</BodyL>
      <TitleH4 $color='brandPrimary'>${value}</TitleH4>
    </PaymantAndValueContainer>
  );
};

export default PaymentAndValue;
