import * as React from 'react';
import { useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../../../components/uielements/Button/Button';
import { SocketContext } from '../../../../../../context/SocketContext';
import {
  DSConnection,
  DSConnectionStatus,
} from '../../../../../../models/DSConnection/DSConnection';
import { dsConnectionUpdate } from '../../../../../../redux/dsConnection/actions';
import { ApplicationState } from '../../../../../../redux/store';
import { color } from '../../../../../../styles/styleVariables';

import accept_gif from '../../../../../../assets/imgs/accept_insecure.gif';

interface IDigitalScoreboardConnectionModalProps {
  connectionStatus: DSConnectionStatus;
  setConnectionStatus: (connectionStatus: DSConnectionStatus) => void;
}

const DigitalScoreboardConnectionModal: React.FunctionComponent<IDigitalScoreboardConnectionModalProps> = (
  props
) => {
  const { connectionStatus } = props;
  const dsConnection = useSelector(
    (appState: ApplicationState) => appState.dsConnection
  );
  const refIpNumber = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const updateDSConnection = (dsConnection: DSConnection) => {
    dispatch(dsConnectionUpdate(dsConnection));
  };

  const tryConnect = () => {
    if (refIpNumber.current) {
      console.log(refIpNumber.current?.value);
      updateDSConnection({
        ...dsConnection.data,
        ipPanel: parseInt(refIpNumber.current.value),
      });
    }
  };

  const disconnect = () => {
    updateDSConnection({
      ...dsConnection.data,
      ipPanel: 0,
    });
  };

  return (
    <div
      style={{
        margin: '0 auto',
        padding: '50px',
        borderRadius: '10px',
        backgroundColor: color.greyLight,
      }}
    >
      <div style={{ display: 'flex' }}>
        <div className='i-icon-cast'></div>
        <h3 className='text-center'>Digital Scoreboard Connection</h3>
      </div>

      {connectionStatus !== DSConnectionStatus.ERROR && (
        <>
          <div>
            Certify that your device is connected with this WIFI:
            <br />
            <br />
            Wifi: <b>VaultDigitalPanel</b>
            <br />
            Password: <b>vaultfootball555</b>
          </div>

          <div style={{ width: '50%', margin: '0 auto' }}></div>
          <div>
            <div className='form-group' style={{ textAlign: 'center' }}>
              <input
                placeholder='Number'
                className='form'
                type='number'
                min={1}
                max={254}
                style={{
                  fontSize: '36px',
                  width: '200px',
                  textAlign: 'center',
                  margin: '0 auto',
                }}
                ref={refIpNumber}
                defaultValue={dsConnection.data.ipPanel || ''}
              ></input>
              {/* {connectionStatus === DSConnectionStatus.ERROR && (
            <div style={{ color: color.orange }}>
              There was an error trying to connect to this panel
            </div>
          )} */}
              <Button
                onClick={() => {
                  tryConnect();
                }}
                disabled={connectionStatus === DSConnectionStatus.CONNECTING}
              >
                {connectionStatus === DSConnectionStatus.CONNECTING
                  ? 'Connecting...'
                  : 'Connect'}
              </Button>

              {connectionStatus === DSConnectionStatus.CONNECTED && (
                <Button
                  onClick={() => {
                    disconnect();
                  }}
                >
                  Disconnect
                </Button>
              )}
            </div>
          </div>
        </>
      )}
      {connectionStatus === DSConnectionStatus.ERROR && (
        <div>
          <h2>Initial enabling</h2>
          <p>
            Access this link and case the alert below shows up, click in{' '}
            <span style={{ fontWeight: 'bold' }}>
              Advanced -&gt; Proceed to 192.168...
            </span>
            <br />
            <span style={{ color: color.orange }}>
              If this page does not load, contact the support
            </span>
            <br />
            <img src={accept_gif} style={{ maxWidth: '100%' }} />
          </p>
          <a
            href={`${process.env.REACT_APP_DS_INIT_ADDRESS}.${dsConnection.data.ipPanel}:${process.env.REACT_APP_DS_PORT}?url=${window.location.href}`}
          >
            <Button>Enable</Button>
          </a>
          <Button
            onClick={() => {
              disconnect();
            }}
          >
            Back
          </Button>
        </div>
      )}
    </div>
  );
};

export default DigitalScoreboardConnectionModal;
