import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { RequestStatus } from 'models/Request/RequestModel';

import UserServices from 'services/v3/User/UserService';

import { ApplicationState } from 'redux/store';
import { UserType } from 'redux/user/types';
import { setCurrentUser } from 'redux/v3/currentUser/actions';
import { usersFetchSuccess } from 'redux/v3/users/actions';

import DateUtil from 'util/DateUtil';

export const useProfile = () => {
  const dispatch = useDispatch();

  const { data: currentUser } = useSelector(
    (state: ApplicationState) => state.currentUser
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isBaseUser = useMemo(() => {
    if (!currentUser) return false;

    return Array.isArray(currentUser?.types);
  }, [currentUser]);

  const userTypes: UserType[] = useMemo(() => {
    if (!currentUser) return [];

    return [...(currentUser?.types || []), currentUser?.type].reduce<
      UserType[]
    >((acc, cur) => {
      if (cur && !acc.includes(cur)) {
        acc.push(cur);
      }

      return acc;
    }, []);
  }, [currentUser]);

  const isStaff = useMemo(() => {
    if (!userTypes.length) return false;

    return userTypes.includes(UserType.STAFF);
  }, [userTypes]);

  const isAnAdult = useMemo(() => {
    if (!currentUser) return false;

    const userBirthDate =
      typeof currentUser?.birthDate === 'string'
        ? currentUser?.birthDate?.split('T')[0]
        : moment();

    return DateUtil.isAnAdult(userBirthDate);
  }, [currentUser]);

  const isValidStatus = useCallback(
    (
      key: 'ageVerified' | 'photoVerified' | 'documentationVerified',
      validStatus: RequestStatus[] = [
        RequestStatus.APPROVED,
        RequestStatus.PENDING,
      ]
    ) => {
      if (!currentUser || !currentUser[key]) return false;

      const status = currentUser[key];

      if (!status) return false;

      if (
        key === 'documentationVerified' &&
        !currentUser.extraInfo?.certificate
      ) {
        return false;
      }
      if (key === 'ageVerified' && !currentUser.ageProof) {
        return false;
      }

      if (key === 'photoVerified' && !currentUser.photo) {
        return false;
      }

      return validStatus.includes(status);
    },
    [currentUser]
  );

  const missingInformation = useMemo(() => {
    const profileErrors: string[] = [];

    const add = (value: string) => {
      if (!profileErrors.includes(value)) {
        profileErrors.push(value);
      }
    };

    if (!currentUser) return [];

    if (!currentUser.photo) add('photo not uploaded');

    if (!isValidStatus('photoVerified')) add('photo not verified');

    if (!currentUser?.firstName || currentUser.firstName.length < 3)
      add('first name too short');
    if (!currentUser?.lastName || currentUser.lastName.length < 3)
      add('last name too short');

    if (
      currentUser?.safesportValidUntil &&
      currentUser.extraInfo?.certificate
    ) {
      if (DateUtil.isExpired(currentUser.safesportValidUntil)) {
        add('safesport expired');
      }
    }

    if (!isAnAdult) {
      const guardian = currentUser.guardian || currentUser.guardian1;

      if (!guardian?.address) add('incomplete guardian address');

      if (!guardian?.firstName || !guardian?.lastName)
        add('incomplete guardian name');
    } else if (!currentUser.address) add('address not completed');

    if (userTypes.includes(UserType.PLAYER)) {
      if (!isValidStatus('ageVerified')) add('age not verified');
    }

    if (
      userTypes.includes(UserType.REFEREE) ||
      userTypes.includes(UserType.COACH) ||
      userTypes.includes(UserType.CLUB_DIRECTOR)
    ) {
      if (!isValidStatus('documentationVerified'))
        add('upload safeSport document');

      if (!isAnAdult) add('not an adult');

      if (!currentUser.address) add('address not completed');
    }

    return profileErrors;
  }, [currentUser, userTypes]);

  const updateCurrentAccount = useCallback(async () => {
    setIsLoading(true);

    const { data: updatedProfile } = await UserServices.getProfiles();

    if (isBaseUser) {
      const updatedBaseUser = updatedProfile?.baseUsers?.find(
        (u) => u.id === currentUser.id
      );

      if (updatedBaseUser) {
        dispatch(setCurrentUser(updatedBaseUser));

        const newUsers = [
          ...(updatedProfile.baseUsers || []),
          ...(updatedProfile.users || []),
        ];

        dispatch(usersFetchSuccess(newUsers));
        setIsLoading(false);
      }
    } else {
      const myNewBaseUser = updatedProfile?.baseUsers?.find((u) =>
        u.userIds?.includes(`${currentUser.id}`)
      );

      const updatedUser = updatedProfile?.users?.find(
        (u) => u.id === currentUser.id
      );

      if (myNewBaseUser) {
        dispatch(setCurrentUser(myNewBaseUser));
      } else if (updatedUser) {
        dispatch(setCurrentUser(updatedUser));
      }

      const newUsers = [
        ...(updatedProfile.baseUsers || []),
        ...(updatedProfile.users || []),
      ];

      dispatch(usersFetchSuccess(newUsers));
      setIsLoading(false);
    }
  }, [dispatch, currentUser]);

  return {
    updateCurrentAccount,
    isBaseUser,
    isStaff,
    currentUser,
    userTypes,
    isAnAdult,
    missingInformation,
    isLoading,
  };
};
