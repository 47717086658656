import * as React from 'react';

import * as S from './styles';

interface InputRadioProps {
  value: string | number;
  name: string;
  id: string;
  onChange: () => void;
  checked?: boolean;
  theme?: 'light' | 'dark';
  onHover?: boolean;
}

const InputRadio: React.FC<InputRadioProps> = ({
  id,
  theme = 'light',
  onHover = false,
  ...props
}) => {
  return (
    <S.Container>
      <S.Item>
        <S.RadioButton
          className={onHover ? 'hover' : ''}
          type='radio'
          id={id}
          $theme={theme}
          {...props}
        />
        <S.RadioButtonLabel htmlFor={id} $theme={theme} />
      </S.Item>
    </S.Container>
  );
};

export default InputRadio;
