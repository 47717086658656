import _ from 'lodash';

export default class FilterUtil {
  static objectCompares = (obj1: any, obj2: any, allKeys = false) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    const object1Copy = { ...obj1 };

    if (allKeys === false) {
      keys1.forEach((k) => {
        if (!keys2.includes(k)) {
          delete object1Copy[k];
        }
      });
    }

    return _.isEqual(object1Copy, obj2);
  };
}
