import React, { useMemo } from 'react';
import { BodyL, BodyS, BodySBold, COLORS } from 'styles/v3/variables';
import { Dot } from '@icon-park/react';

import { Pools } from 'admin/models/AgeDivision';
import { GenderCategory } from 'admin/models/Enums';
import {
  AgeRulesEnums,
  AgeRulesEnumsToLabel,
} from 'pages/V3/setupEvent/Divisions/constants';
import {
  Container,
  ContainerSpace,
  DivisionInformation,
  DivisionNotFormated,
  DivisionTags,
  DivisionTagsWrapper,
  DivisionText,
  DivisionTextBox,
  DivisionTextWrapper,
  TagColor,
} from './styles';
import {
  allowedOtherGenders,
  gendersLabel,
} from '../../../DivisionSetFormat/config';

interface EventDivisionProps {
  id: string;
  name: string;
  rule: AgeRulesEnums;
  gender: GenderCategory;
  totalYears: number;
  firstYear: number;
  allowMixedTeams: boolean;
  bracketType: string;
  pools: Pools[];
  format: string;
  coedRules: string;
  color: string;
}

export const DivisionCard = ({
  id,
  name,
  gender,
  rule,
  totalYears,
  firstYear,
  allowMixedTeams,
  bracketType,
  pools,
  format,
  coedRules,
  color,
}: EventDivisionProps) => {
  const getRuleAllowed = useMemo(() => {
    if (allowMixedTeams && gender !== GenderCategory.COED) {
      return allowedOtherGenders[gender];
    }

    return coedRules;
  }, [allowMixedTeams, gender, coedRules]);

  const getYearRule = useMemo(() => {
    return AgeRulesEnumsToLabel[rule].replace('Year', firstYear.toString());
  }, [rule, firstYear]);

  return (
    <>
      <Container>
        <ContainerSpace>
          <TagColor style={{ backgroundColor: color }} />
          <DivisionInformation>
            <DivisionText>
              <BodyL>{name}</BodyL>
              <DivisionTextWrapper>
                <DivisionTextBox>
                  <BodyS>{gendersLabel[gender]}</BodyS>
                </DivisionTextBox>
                <DivisionTextBox>
                  <Dot fill={COLORS.grey800} />
                  <BodyS>{getYearRule}</BodyS>
                </DivisionTextBox>
                <DivisionTextBox>
                  {getRuleAllowed && <Dot fill={COLORS.grey800} />}
                  <BodyS>{getRuleAllowed}</BodyS>
                </DivisionTextBox>
              </DivisionTextWrapper>
            </DivisionText>
            {bracketType || pools.length > 0 || format ? (
              <DivisionTagsWrapper>
                {format && (
                  <DivisionTags>
                    <BodyS>Format</BodyS>
                    <BodySBold $color='grey50'>{format}</BodySBold>
                  </DivisionTags>
                )}
                {pools.length > 0 && (
                  <DivisionTags>
                    <BodyS>Pools</BodyS>
                    <BodySBold $color='grey50'>{pools.length}</BodySBold>
                  </DivisionTags>
                )}
                {bracketType && (
                  <DivisionTags>
                    <BodyS>Pools</BodyS>
                    <BodySBold $color='grey50'>{bracketType}</BodySBold>
                  </DivisionTags>
                )}
              </DivisionTagsWrapper>
            ) : (
              <DivisionTagsWrapper>
                <DivisionNotFormated>
                  <BodyS $color='grey400'>Not formated</BodyS>
                </DivisionNotFormated>
              </DivisionTagsWrapper>
            )}
          </DivisionInformation>
        </ContainerSpace>
      </Container>
    </>
  );
};
