import { Col, Row } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import JoinRequestRow from '../../components/ClubDirector/JoinRequest/JoinRequestRow';
import usePrimaryClub from '../../hooks/usePrimaryClub/usePrimaryClub';
import ClubService from '../../services/Club/ClubService';

const JoinRequests: React.FunctionComponent = () => {
  const [listRequests, setListRequests] = useState<any[]>([]);

  const primaryClub = usePrimaryClub();

  useEffect(() => {
    if (primaryClub) {
      ClubService.getClubRequests(primaryClub.id, 'PENDING').then(
        (response) => {
          if (response.success) {
            setListRequests(response.data.content);
          }
        }
      );
    }
  }, [primaryClub]);

  return (
    <Row>
      <Col span={24}>
        <h2>Join Requests</h2>
        {listRequests.length > 0 && (
          <div>
            <p>
              These people are willing to join your Club.{' '}
              <b>Approve or Reject each.</b>
            </p>
          </div>
        )}
        {!listRequests.length && (
          <div>
            <p>You do not have any request for this club.</p>
          </div>
        )}
        <div>
          {listRequests.map((item) => {
            return <JoinRequestRow key={item.id} user={item} />;
          })}
        </div>
      </Col>
    </Row>
  );
};

export default JoinRequests;
