interface UserFullNameProps {
  firstName?: string;
  lastName?: string;
  middleName?: string;
}

export function formatUserName(fullName: UserFullNameProps): string {
  const { firstName, middleName, lastName } = fullName;
  return [firstName, middleName, lastName].filter(Boolean).join(' ');
}
