import styled from 'styled-components';
import { COLORS, ColorsInterface } from 'styles/v3/variables';

export const Container = styled.div<{
  $orientation: 'column' | 'row';
  $borderColor?: keyof ColorsInterface;
  $bgColor?: keyof ColorsInterface;
  $clickable?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => props.$orientation};
  position: relative;
  cursor: ${(props) => (props.$clickable ? 'pointer' : 'default')};
  transition: 0.3s;
  border: 1px solid;
  border-color: ${(props) =>
    props.$borderColor ? COLORS[props.$borderColor] : 'transparent'};
  background-color: ${(props) =>
    props.$bgColor ? COLORS[props.$bgColor] : 'transparent'};
`;
