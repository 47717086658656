import React, { useMemo } from 'react';

import { BodyM, BodyMBold } from 'styles/v3/variables';
import { ApplicationState } from 'redux/store';
import { useSelector } from 'react-redux';
import { NumberToUSDollar } from 'util/CurrencyUtil';
import { DivisionSummaryProps } from './types';
import * as S from './styles';

export const DivisionSummary = ({ divisions, price }: DivisionSummaryProps) => {
  const { coupon } = useSelector(
    (state: ApplicationState) => state.signupEvent.data
  );

  const summaryPurchaseValue = useMemo(() => {
    return divisions.reduce((acc, cur) => {
      return acc + cur.quantity * price;
    }, 0);
  }, [divisions, price]);

  const couponDiscount = useMemo(() => {
    if (!coupon || !summaryPurchaseValue) return 0;
    if (coupon.type === 'PERCENTAGE_OFF') {
      if (!coupon.percentageOff) return 0;
      return Math.abs(summaryPurchaseValue * (coupon.percentageOff / 100));
    }
    if (!coupon.amountOff) return 0;
    return Math.abs(coupon.amountOff / 100);
  }, [coupon, summaryPurchaseValue]);

  const summaryTotal =
    summaryPurchaseValue - couponDiscount > 0
      ? summaryPurchaseValue - couponDiscount
      : 0;

  return (
    <S.Container>
      <S.Column>
        <BodyM>Summary Purchase</BodyM>
        {coupon && <BodyM $color='supportNeutral'>Coupon discount</BodyM>}
        <BodyM $color='brandPrimary'>Total</BodyM>
      </S.Column>
      <S.Column>
        <BodyMBold>{NumberToUSDollar.format(summaryPurchaseValue)}</BodyMBold>
        {coupon && (
          <BodyMBold $color='supportNeutral'>
            -{NumberToUSDollar.format(couponDiscount)}
          </BodyMBold>
        )}
        <BodyMBold $color='brandPrimary'>
          {NumberToUSDollar.format(summaryTotal)}
        </BodyMBold>
      </S.Column>
    </S.Container>
  );
};
