import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: ${toRem(18)};
  color: ${COLORS.grey500};
`;

export const HR = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.grey800};
  &::after {
    content: ' ';
  }
`;
