import styled from 'styled-components';
import { color } from '../../styles/styleVariables';

export const NextButtonsWrapper = styled.div`
  text-align: center;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .link {
    cursor: pointer;
    margin: 0 10px;
    &.disabled {
      opacity: 0.2;
      cursor: inherit;
      &:hover {
        color: inherit;
      }
    }
    &:hover {
      color: ${color.orange};
    }
  }
`;

export const ErrorValidationBox = styled.div`
  color: ${color.orange};
`;

export const TopHeader = styled.div`
  text-align: center;
  padding: 0 15px;
  padding-top: 100px;
  h1 {
    font-size: 36px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
`;

export const BoxInfo = styled.div`
  margin: 30px 0;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  border: solid 1px ${color.orange};
  h5 {
    font-size: 14px;
    font-weight: 700;
  }
  p {
    color: ${color.greyText};
  }
  span {
    color: ${color.orange};
    font-size: 14px;
    font-weight: 500;
  }
`;

export const TermsBox = styled.div`
  margin: 20px 0;
  h3,
  h5 {
    text-align: center;
  }
  h5 {
    color: ${color.silver};
  }
  .terms-text {
    max-height: 200px;
    overflow-y: scroll;
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    color: ${color.greyDark};
    font-size: 10px;
  }
`;

export const SignatureBox = styled.div`
  text-align: center;
  font-weight: 500;
  padding: 10px 20px;
  background-color: ${color.orange};
  color: ${color.greyDark};
  border-radius: 5px;
`;
