import * as React from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as S from './styles';

export interface ButtonProps {
  children: React.ReactNode;
  isUpper?: boolean;
  isBold?: boolean;
  isLoading?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onLeave?: () => void;
  onHover?: () => void;
}

const antIcon = <LoadingOutlined spin />;

const BasicButton: React.FC<ButtonProps> = ({
  children,
  className,
  disabled,
  onClick,
  onLeave,
  onHover,
  isBold = true,
  isUpper = false,
  isLoading = false,
}) => {
  return (
    <S.Button
      $isBold={isBold}
      onClick={onClick}
      $isUpper={isUpper}
      className={className}
      onMouseOver={onHover}
      onMouseLeave={onLeave}
      $isLoading={isLoading}
      disabled={disabled || isLoading}
    >
      {isLoading ? <Spin indicator={antIcon} /> : children}
    </S.Button>
  );
};

export default BasicButton;
