import 'gridstack/dist/gridstack.min.css';
import 'gridstack/dist/gridstack-extra.min.css';
import './Scheduler.css';
import './CustomColumns.css';
import { GridHTMLElement, GridStack, GridStackNode } from 'gridstack';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useUpdateMatchesEvent } from 'hooks/v3/matches/useUpdateMatchesEvent/useUpdateMatchesEvent';
import { useParams } from 'react-router-dom';
import { MatchEventModel, MatchStatus } from 'models/Match/MatchModel';
import Modal from 'react-modal';
import useGetVenues from 'hooks/v3/event/useGetVenues/useGetVenues';
import { timezones } from 'util/timezones';
import { DaysTableContainer, ScheduleWrapper } from './styles';
import { mapTimeToPosition } from '../../utils';
import PagesControl from './PagesControl';
import { SchedulerProps } from './types';
import DaysSwitcher from './DaysSwitcher';
import {
  cellHeight,
  getModalStyles,
  perTableMaxColumn,
  stepperIntervals,
} from './lib';
import Table from './Table';
import UnscheduledMatches from './UnscheduledMatches';
import EditGameModal from '../EditGameModal/EditGameModal';
import { useGetScheduleConfig } from './hooks/useGetScheduleConfig';
import { useGetTimeData } from './hooks/useGetTimeData';
import { useGetMatchRulesSettings } from './hooks/useGetMatchRulesSettings';

function Scheduler({
  days,
  currentDay,
  divisionId,
  divisionColors,
  scheduleRules,
  scheduleType,
  courts,
  matches,
  dailyEventTimes,
}: SchedulerProps) {
  const params: { eventId: string } = useParams();
  const {
    pools,
    getMatchTitle,
    getHomeTeamName,
    getAwayTeamName,
    changeTimeInnerScheduledElem,
    createWidget,
    resetPreviousErrorWidget,
  } = useGetScheduleConfig(params.eventId, divisionColors);
  const { data: venueData } = useGetVenues(params.eventId);
  const { mutateAsync: updateSchedule } = useUpdateMatchesEvent();
  const {
    checkTeamsCount,
    checkMinTimeForSameTeams,
    checkMaxTimeForSameTeams,
  } = useGetMatchRulesSettings({ scheduleRules, matches });
  const pagesCount = Math.floor(courts.length / 8) + 1;
  const initializedEvnentRef = useRef<boolean>(false);
  const gridRefs = useRef<Array<GridStack | null>>(
    new Array(pagesCount).fill(null)
  );
  const usceduledRef = useRef<HTMLDivElement>(null);
  const startPositionRef = useRef<{ x: string, y: string }>({
    x: '',
    y: '',
  });
  const [page, setPage] = useState<number>(0);
  const [errorElement, setErrorElement] = useState<GridHTMLElement>();
  const [error, setError] = useState<{ message: string, type: string }>({
    message: '',
    type: '',
  });
  const [selectedMatch, setSelectedMatch] = useState<
    MatchEventModel | undefined
  >();
  const [opened, setOpened] = useState<boolean>(false);
  const [preventUpdate, setPreventUpdate] = useState(false);
  const source = scheduleType === 'POOL_GAMES' ? 'POOL' : 'BRACKET';

  const unscheduledMatch = matches.filter((match) => {
    const unscheduled = !!gridRefs?.current[page]
      ?.getGridItems()
      ?.some((widget) => {
        const id =
          widget?.id === '' ? widget.getAttribute('gs-id') : widget?.id;
        return id === `match-${match.matchId}`;
      });
    return (
      match.status === MatchStatus.UNSCHEDULED &&
      match.ageDivisionId === divisionId &&
      !unscheduled &&
      match.sourceType === source
    );
  });
  const scheduledMatch = matches.filter(
    (match) => match.status !== MatchStatus.UNSCHEDULED
  );
  const currentDailyEventTime = dailyEventTimes?.find(
    (item) => item?.day === currentDay
  );
  // ENG-594 temporary show only timezone for the 1st venue
  const venueTimezone = useMemo(() => {
    return timezones.find(
      (timezone) => venueData && timezone.value === venueData[0].timezone
    );
  }, [venueData]);
  const { fromDayStart } = useGetTimeData(currentDailyEventTime);
  const firstPageIndex = page * perTableMaxColumn;
  const lastPageIndex = firstPageIndex + perTableMaxColumn;

  const visibleCourts = useMemo(() => {
    return courts.slice(firstPageIndex, lastPageIndex);
  }, [firstPageIndex, lastPageIndex, courts]);

  const mapPositionToTime = (position: number, stepperIntervals: number) => {
    const minutes = position * stepperIntervals;
    const time = `${String(Math.floor(minutes / 60)).padStart(2, '0')}:${String(
      minutes % 60
    ).padStart(2, '0')}`;
    return moment(time, ['HH:mm']).add(fromDayStart, 'hours').format('hh:mm A');
  };
  const removeAll = () => {
    startPositionRef.current.x = '';
    startPositionRef.current.y = '';
    gridRefs.current[page]?.removeAll(true);
  };

  const modalStyles = useMemo(() => {
    return getModalStyles();
  }, []);

  const handleDrop = (element: GridStackNode) => {
    if (!currentDay) return;

    const id = element.el?.id ?? 'match-1';
    const matchId = id.replace('match-', '');
    const x = element?.x ?? 0;
    const y = element?.y ?? 0;
    const court = courts[firstPageIndex + x].name;
    const time = mapPositionToTime(y, stepperIntervals);
    const date = moment(`${currentDay} ${time}`, 'YYYY-MM-DD h:mm A').format(
      'YYYY-MM-DDTHH:mm'
    );
    if (element.el?.classList.contains('in-restricted-zone')) {
      const count = usceduledRef?.current?.children?.length ?? 0;
      for (let i = 0; i < count; i += 1) {
        usceduledRef.current?.children[i].classList.remove(
          'in-restricted-zone'
        );
      }
      setErrorElement(element.el);
    } else {
      setErrorElement(undefined);
      updateSchedule([
        {
          matchId,
          date,
          subVenue: court,
        },
      ]);
    }

    const currentMatch = matches.find((match) => match.matchId === matchId);
    if (currentMatch) {
      changeTimeInnerScheduledElem(
        element,
        getMatchTitle(currentMatch)?.toString() || '',
        time,
        getHomeTeamName(currentMatch)?.toString() || '',
        getAwayTeamName(currentMatch)?.toString() || '',
        currentMatch
      );

      gridRefs.current[page]?.getGridItems().forEach((widget) => {
        gridRefs.current[page]?.update(widget, { locked: true });
      });
    }
  };

  const handleChange = (items: GridStackNode[]) => {
    if (!preventUpdate) {
      const element = items[0];
      const id =
        element.el?.id === ''
          ? element.id ?? 'match-1'
          : element.el?.id ?? 'match-1';
      const matchId = id.toString().replace('match-', '');

      const x = element?.x ?? 0;
      const court = courts[firstPageIndex + x]?.name;
      const y = element?.y ?? 0;

      const time = mapPositionToTime(y, stepperIntervals);
      if (!currentDay) return;
      const date = moment(`${currentDay} ${time}`, 'YYYY-MM-DD h:mm A').format(
        'YYYY-MM-DDTHH:mm'
      );
      if (element.el?.classList.contains('in-restricted-zone')) {
        setErrorElement(element?.el);
      } else {
        setErrorElement(undefined);
        updateSchedule([
          {
            matchId,
            date,
            subVenue: court,
          },
        ]);
      }

      const newTime = moment.utc(date).format('hh:mm A');
      const currentMatch = matches.find((match) => match.matchId === matchId);
      if (currentMatch) {
        changeTimeInnerScheduledElem(
          element,
          getMatchTitle(currentMatch)?.toString() || '',
          newTime,
          getHomeTeamName(currentMatch)?.toString() || '',
          getAwayTeamName(currentMatch)?.toString() || '',
          currentMatch
        );
      }
    }
  };

  const handleRemove = (element: GridStackNode) => {
    if (!preventUpdate) {
      if (
        startPositionRef.current.x !== '' &&
        startPositionRef.current.y !== ''
      ) {
        const removedElem = element;
        const id =
          removedElem.el?.id === ''
            ? removedElem.id ?? 'match-1'
            : removedElem.el?.id ?? 'match-1';
        const matchId = id.toString().replace('match-', '');

        updateSchedule([
          {
            matchId,
            unschedule: true,
          },
        ]);
        element?.el?.classList.remove('locked_item', 'unlocked_item');
        const currentMatch = matches.find((match) => match.matchId === matchId);
        if (currentMatch) {
          changeTimeInnerScheduledElem(
            element,
            getMatchTitle(currentMatch)?.toString() || '',
            '11:00 AM',
            getHomeTeamName(currentMatch)?.toString() || '',
            getAwayTeamName(currentMatch)?.toString() || '',
            currentMatch
          );
        }

        GridStack.setupDragIn(`#${id}`, {
          handle: '.grid-stack-item-content',
          helper: 'clone',
        });
      }
    }
  };

  const handleDragStart = (element: GridHTMLElement) => {
    const x = element.getAttribute('gs-x') ?? '0';
    const y = element.getAttribute('gs-y') ?? '0';
    const widgets = gridRefs.current[page]?.getGridItems();
    const elemId =
      element.id !== '' ? element.id : element.getAttribute('gs-id');
    const errorElementId = errorElement?.getAttribute('gs-id');
    const grid = gridRefs.current[page];
    if (errorElementId && errorElementId !== elemId && grid) {
      resetPreviousErrorWidget(
        scheduledMatch,
        visibleCourts,
        errorElementId,
        grid,
        currentDailyEventTime,
        widgets
      );
      if (errorElement?.classList.contains('in-restricted-zone')) {
        setErrorElement(undefined);
        errorElement?.classList?.remove('in-restricted-zone');
      }
    }
    startPositionRef.current = {
      x,
      y,
    };
    widgets?.forEach((widget) => {
      const widgetId =
        widget?.id === '' ? widget.getAttribute('gs-id') : widget?.id;
      if (elemId && widgetId && widgetId !== elemId) {
        grid?.update(widget, {
          locked: true,
          noMove: true,
          noResize: true,
        });
      }
    });
    setErrorElement(undefined);
  };
  const handleDrag = (element: GridHTMLElement) => {
    const gridRef = gridRefs.current[page];
    const x = element.getAttribute('gs-x') ?? '0';
    const y = element.getAttribute('gs-y') ?? '0';
    const currentWidgetHeight = parseInt(
      element.getAttribute('gs-h') ?? '0',
      10
    ); // ENG-62 - dragged widget

    const elemId =
      element.id !== '' ? element.id : element.getAttribute('gs-id');
    const widgets = gridRef?.getGridItems();

    let rulesSettingsData = checkMinTimeForSameTeams(
      x,
      y,
      currentWidgetHeight,
      elemId ?? '',
      widgets
    );

    !rulesSettingsData.isBlocked &&
      (rulesSettingsData = checkMaxTimeForSameTeams(
        x,
        y,
        currentWidgetHeight,
        elemId ?? '',
        widgets
      ));

    !rulesSettingsData.isBlocked &&
      (rulesSettingsData = checkTeamsCount(elemId ?? '', widgets));

    setError({
      type: rulesSettingsData.type,
      message: rulesSettingsData.message,
    });

    if (rulesSettingsData.isBlocked) {
      element.classList.add('in-restricted-zone');
    } else {
      element.classList.remove('in-restricted-zone');
    }
  };

  const handleDragStop = () => {
    const currentGrid = gridRefs.current[page];
    const widgets = currentGrid?.getGridItems();
    const lockedWidgetsId = matches
      .filter((match) => match.gridLocked)
      .map((item) => `match-${item.matchId}`);
    widgets?.forEach((widget) => {
      const widgetId =
        widget?.id === '' ? widget.getAttribute('gs-id') : widget?.id;
      if (widgetId) {
        const elementLocked = lockedWidgetsId?.includes(widgetId);
        currentGrid?.update(widget, {
          locked: true,
          noMove: !!elementLocked,
          noResize: !!elementLocked,
        });
      }
    });
  };

  const setOpenGameEditor = () => {
    setOpened(true);
    setPreventUpdate(true);
  };

  const handleCloseGameEditor = () => {
    setPreventUpdate(false);
    setOpened(false);
  };

  useEffect(() => {
    gridRefs.current = gridRefs.current.map((_, index) => {
      const fPageIndex = index * perTableMaxColumn;
      const lPageIndex = fPageIndex + perTableMaxColumn;
      const count = courts.slice(fPageIndex, lPageIndex).length;

      return GridStack.init(
        {
          cellHeight: `${cellHeight}px`,
          minRow: (4 * 60) / stepperIntervals,
          maxRow: (24 * 60) / stepperIntervals,
          margin: 0,
          float: true,
          disableResize: true,
          column: count,
          removable: true,
          acceptWidgets: true,
        },
        `.dnd-grid-${index}`
      );
    });
    GridStack.setupDragIn('.dragNDrop', {
      handle: '.grid-stack-item-content',
      helper: 'clone',
    });

    // eslint-disable-next-line consistent-return
    return () => {
      gridRefs.current.forEach((grid) => {
        if (grid) {
          grid.removeAll();
          grid.destroy();
        }
      });
    };
  }, []);

  useEffect(() => {
    const gridRef = gridRefs.current[page];
    if (!gridRef) {
      return;
    }
    const drop = (
      event: Event,
      prev: GridStackNode,
      newWidget: GridStackNode
    ) => {
      handleDrop(newWidget);
    };
    const change = (event: Event, items: GridStackNode[]) => {
      handleChange(items);
    };
    const remove = (event: Event, items: GridStackNode[]) => {
      if (items.length === 1) {
        handleRemove(items[0]);
      }
    };
    const dragstart = (event: Event, element: GridHTMLElement) => {
      handleDragStart(element);
    };

    const dragstop = (event: Event, element: GridHTMLElement) => {
      handleDragStop();
    };

    if (initializedEvnentRef.current) {
      gridRef?.off('dropped');
      gridRef?.off('change');
      gridRef?.off('removed');
      gridRef?.off('dragstart');
      gridRef?.off('dragstop');
      initializedEvnentRef.current = false;
    }

    if (!initializedEvnentRef.current) {
      gridRef?.on('dropped', drop);
      gridRef?.on('change', change);
      gridRef?.on('removed', remove);
      gridRef?.on('dragstart', dragstart);
      gridRef?.on('dragstop', dragstop);
      initializedEvnentRef.current = true;
    }
  }, [page, currentDay, pools, matches, preventUpdate]);

  useEffect(() => {
    const gridRef = gridRefs.current[page];
    if (!gridRef) {
      return;
    }
    const drag = (event: Event, element: GridHTMLElement) => {
      handleDrag(element);
    };
    gridRef?.on('drag', drag);
  }, [page, matches]);

  useEffect(() => {
    const currentGrid = gridRefs.current[page];
    const widgetsIds = currentGrid?.getGridItems().map((item) => {
      return item?.id === '' ? item.getAttribute('gs-id') : item?.id;
    });
    const fPageIndex = page * perTableMaxColumn;
    const lPageIndex = fPageIndex + perTableMaxColumn;

    if (widgetsIds?.length === 0 && currentDailyEventTime?.startTime) {
      scheduledMatch.forEach((match) => {
        const x = courts
          .slice(fPageIndex, lPageIndex)
          ?.findIndex((court) => court.name === match.subVenue);
        if (x === -1) return;
        const time = moment.utc(match.date).format('HH:mm');
        const y = mapTimeToPosition(time, stepperIntervals);
        const difY = mapTimeToPosition(
          moment(currentDailyEventTime?.startTime ?? '', 'HH:mm:ss').format(
            'HH:mm'
          ),
          stepperIntervals
        );
        if (!widgetsIds?.includes(match.matchId)) {
          const matchLenght =
            match.sourceType === 'POOL'
              ? scheduleRules.poolGamesLength
              : scheduleRules.bracketGamesLength;
          const data = createWidget({
            x,
            y: y - difY,
            match,
            matchLenght,
          });
          currentGrid?.addWidget(data);
        }
      });
    }

    unscheduledMatch?.forEach((match) => {
      GridStack.setupDragIn(`#match-${match.matchId}`, {
        handle: '.grid-stack-item-content',
        helper: 'clone',
      });
    });
  }, [
    divisionId,
    page,
    matches,
    courts,
    scheduleRules,
    pools,
    createWidget,
    currentDailyEventTime?.startTime,
  ]);

  useEffect(() => {
    const widgets = gridRefs.current[page]?.getGridItems();
    widgets?.forEach((widget) => {
      const widgetId =
        widget?.id === '' ? widget.getAttribute('gs-id') : widget?.id;
      const currentMatch = scheduledMatch.find((match) => {
        return `match-${match.matchId}` === widgetId;
      });

      if (currentMatch) {
        const id = widgetId?.replace('match-', '') ?? '';
        const element = !!id && document.getElementById(id);
        if (source !== currentMatch.sourceType && element) {
          gridRefs.current[page]?.update(widget, {
            noMove: true,
            noResize: true,
          });
          element.classList.add('inactive');
        }

        if (source === currentMatch.sourceType && element) {
          gridRefs.current[page]?.update(widget, {
            noMove: !!currentMatch.gridLocked,
            noResize: !!currentMatch.gridLocked,
          });
          element.classList.remove('inactive');
        }
      }
    });
  }, [scheduledMatch, page]);

  return (
    <ScheduleWrapper style={{ width: '100%' }}>
      <div style={{ width: '100%' }}>
        <UnscheduledMatches
          ref={usceduledRef}
          unscheduledMatch={unscheduledMatch}
          divisionColors={divisionColors}
          errorElement={errorElement}
          scheduleRules={scheduleRules}
          getAwayTeamName={getAwayTeamName}
          getHomeTeamName={getHomeTeamName}
          getMatchTitle={getMatchTitle}
        />
        <DaysTableContainer>
          {currentDay && (
            <DaysSwitcher
              days={days}
              currentDay={currentDay}
              visibleCourts={visibleCourts}
              venueTimezone={venueTimezone}
              removeAll={removeAll}
            />
          )}
          <Table
            page={page}
            currentDailyEventTime={currentDailyEventTime}
            source={source}
            pagesCount={pagesCount}
            cellHeight={cellHeight}
            error={error}
            errorElement={errorElement}
            scheduledMatch={scheduledMatch}
            visibleCourts={visibleCourts}
            grid={gridRefs.current[page]}
            setEditModalOpen={setOpenGameEditor}
            setSelectedMatch={setSelectedMatch}
            divisionColors={divisionColors}
          />
        </DaysTableContainer>
      </div>
      {selectedMatch && (
        <Modal
          isOpen={opened}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          ariaHideApp={false}
          style={modalStyles}
          onRequestClose={handleCloseGameEditor}
        >
          <EditGameModal
            days={days}
            courts={visibleCourts}
            dayStartTime={currentDailyEventTime?.startTime}
            matchLength={
              selectedMatch?.sourceType === 'POOL'
                ? scheduleRules.poolGamesLength
                : scheduleRules.bracketGamesLength
            }
            grid={gridRefs.current[page]}
            selectedMatch={selectedMatch}
            divisionColors={divisionColors}
            handleCloseGameEditor={handleCloseGameEditor}
            mapPositionToTime={mapPositionToTime}
            getHomeTeamName={getHomeTeamName}
            getAwayTeamName={getAwayTeamName}
            getMatchTitle={getMatchTitle}
          />
        </Modal>
      )}
      {pagesCount > 1 && (
        <PagesControl
          page={page}
          disabled={lastPageIndex >= courts.length}
          setPage={setPage}
        />
      )}
    </ScheduleWrapper>
  );
}

export default memo(Scheduler);
