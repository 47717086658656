import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import players from '../../assets/imgs/players.png';
import { color } from '../../styles/styleVariables';
import Button from '../uielements/Button/Button';

interface INewSafesportBox {
  onClose?: () => void;
}

const YEAR = 2024;

const NewSafesportBox: React.FunctionComponent<INewSafesportBox> = (props) => {
  const { onClose } = props;
  return (
    <BoxWrapper data-testid='join-club-box'>
      <div className='image'>
        <img
          style={{
            position: 'absolute',
            top: '-80px',
            left: '-125px',
          }}
          src={players}
          alt=''
        />
      </div>
      <div className='text'>
        <h2 className='text-gradient'>SafeSport Certificate Update</h2>
        <p>
          Your SafeSport Trained Certificate might be expired.
          <br />
          We are starting a new season on September 1st and it is necessary to
          send again the SafeSport that must be valid until August 30th, {YEAR}
          <br />
          Please access{' '}
          <Link style={{ fontWeight: 'bold' }} to='/my-settings/'>
            My Settings
          </Link>{' '}
          Page and upload it for this new season.
        </p>
        <Link to='/my-settings/'>
          <Button onClick={onClose}>Upload SafeSport</Button>
        </Link>
      </div>
    </BoxWrapper>
  );
};

const BoxWrapper = styled.div`
  border-radius: 10px;
  max-width: 600px;
  min-height: 267px;
  background-color: ${color.greyLight};
  padding: 30px;
  display: flex;
  box-shadow: 0 0 11px 0px #00000082;
  z-index: 10;
  .image {
    width: 40%;
    position: relative;
    @media (max-width: 600px) {
      display: none;
    }
  }
  .text {
    width: 50%;
    text-align: center;
    min-width: 300px;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

export default NewSafesportBox;
