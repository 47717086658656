import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  gap: 16px;
  border-radius: 12px;
  background: ${COLORS.grey900};
`;

export const DivisionList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const HR = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.grey800};
  &::after {
    content: ' ';
  }
`;
