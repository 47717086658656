import { Col, Row } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import MembershipSelection from '../../components/MembershipSelection/MembershipSelection';
import Steps from '../../components/Steps/Steps';
import { ApplicationState } from '../../redux/store';
import { UserType } from '../../redux/user/types';
import {
  ErrorValidationBox,
  NextButtonsWrapper,
  TopHeader,
} from './WizardComponents.style';

const WizardMembership: React.FunctionComponent = (props) => {
  const [validationError, setValidationError] = useState(false);
  const { newUser } = useSelector(
    (state: ApplicationState) => state.newUser
  ).data;
  const history = useHistory();

  // Trick to remove error message
  function handleClick() {
    setValidationError(false);
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  function nextScreen() {
    // Validate if user has selected a Membership
    if (newUser?.membershipId) {
      if (newUser?.type === UserType.PLAYER) {
        history.push('/wizard/terms');
      }
      if (
        newUser?.type === UserType.COACH ||
        newUser?.type === UserType.CLUB_DIRECTOR ||
        newUser?.type === UserType.REFEREE
      ) {
        history.push('/wizard/terms');
      }
    } else {
      setValidationError(true);
    }
  }

  function prevScreen() {
    if (newUser?.type === UserType.PLAYER) {
      history.push('/wizard/document');
    }
    if (
      newUser?.type === UserType.COACH ||
      newUser?.type === UserType.CLUB_DIRECTOR ||
      newUser?.type === UserType.REFEREE
    ) {
      history.push('/wizard/photo');
    }
  }

  return (
    <div>
      <TopHeader>
        <h5>
          COMPLETE YOUR HOUSEHOLD USER CREATION TO JOIN THE UNITED FUTSAL
          COMMUNITY!
        </h5>
        <h1>CHOOSE YOUR ACCOUNT</h1>
      </TopHeader>
      <Row>
        <Col
          xxl={18}
          lg={18}
          md={24}
          className='column-wizard'
          style={{ maxWidth: '1200px' }}
        >
          <Steps activeStep={3} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <MembershipSelection />
          </div>
          <ErrorValidationBox
            style={{ textAlign: 'center', marginTop: '20px' }}
          >
            {validationError && 'You must select a memberships'}
          </ErrorValidationBox>
          <NextButtonsWrapper data-testid='wizard-nav-wrapper'>
            <div
              onClick={() => prevScreen()}
              onKeyDown={() => prevScreen()}
              role='button'
              tabIndex={0}
              aria-label='Previous Step'
              style={{ margin: '0 10px' }}
              className='link'
            >
              ◄ PREVIOUS STEP
            </div>

            <div
              onClick={() => nextScreen()}
              onKeyDown={() => nextScreen()}
              role='button'
              tabIndex={0}
              aria-label='Next Step'
              style={{ margin: '0 10px' }}
              className='link'
            >
              NEXT STEP ►
            </div>
          </NextButtonsWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(WizardMembership);
