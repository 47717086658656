import { Col, Row } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MemberPoolSidebox from '../../../components/ClubDirector/MemberPoolSidebox/MemberPoolSidebox';
import RosterEdit from '../../../pages/ClubDirector/Rosters/RosterEdit';
import { memberpoolFetchRequest } from '../../../redux/memberpool/actions';
import { rosterFetchRequest } from '../../../redux/roster/actions';
import { ApplicationState } from '../../../redux/store';

interface IStaffRosterEdit {}

const StaffRosterEdit: React.FunctionComponent<IStaffRosterEdit> = (props) => {
  const routeParams: { rosterId: string } = useParams();
  const roster = useSelector((state: ApplicationState) => state.roster);

  const [playerPoolListOpen, setPlayerPoolListOpen] = useState(false);
  const [coachPoolListOpen, setCoachPoolListOpen] = useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(rosterFetchRequest(routeParams.rosterId));
  }, [dispatch, routeParams.rosterId]);

  React.useEffect(() => {
    if (roster.data.club?.id) {
      dispatch(memberpoolFetchRequest(roster.data.club?.id));
    }
  }, [dispatch, roster]);

  return (
    <Row>
      <Col span={24}>
        <h1>Edit Rosters</h1>
        <Row>
          <Col md={12} lg={14}>
            <RosterEdit
              openCoachPool={() => {
                setPlayerPoolListOpen(false);
                setCoachPoolListOpen(true);
              }}
              openPlayerPool={() => {
                setPlayerPoolListOpen(true);
                setCoachPoolListOpen(false);
              }}
              staffUnlock
            />
          </Col>
          <Col md={12} lg={10}>
            <>
              <MemberPoolSidebox
                type='PLAYERS'
                open={playerPoolListOpen}
                rosterId={routeParams.rosterId}
                staffUnlock
              />
              <MemberPoolSidebox
                type='COACHES'
                open={coachPoolListOpen}
                rosterId={routeParams.rosterId}
                staffUnlock
              />
            </>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default StaffRosterEdit;
