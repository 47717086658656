import * as React from 'react';
import { matchPath, useLocation, Link } from 'react-router-dom';

import { BodyM } from 'styles/v3/variables';

import * as S from './styles';

type TabItems = {
  name: string;
  link: string;
  path: string;
};

interface TabBarProps {
  items: TabItems[];
}

const TabBar: React.FC<TabBarProps> = ({ items }) => {
  const location = useLocation();
  return (
    <S.TabsWrapper>
      <S.TabsContainer>
        {items.map((i) => {
          const pathMatch = matchPath(location.pathname, i.path);
          const hasMatch = pathMatch && pathMatch.isExact;

          return (
            <S.TabItem key={i.name} className={hasMatch ? 'active' : ''}>
              <Link to={i.link}>
                <BodyM $isUpper>{i.name}</BodyM>
              </Link>
            </S.TabItem>
          );
        })}
      </S.TabsContainer>
    </S.TabsWrapper>
  );
};

export default TabBar;
