import * as React from 'react';
import styled from 'styled-components';

import { color, font, gradient } from '../../styles/styleVariables';
import ico_plus from '../../assets/imgs/icons/ico_plus.svg';

interface IUserSelectionBoxProps {
  teamIcon?: string;
  photo?: string;
  firstName?: string;
  lastName?: string;
  membership?: string;
  newButton?: boolean;
  onClick?: () => void;
}

const UserSelectionBox: React.FunctionComponent<IUserSelectionBoxProps> = (
  props
) => {
  const {
    firstName,
    lastName,
    photo,
    teamIcon,
    newButton,
    membership,
    onClick,
  } = props;
  if (!newButton) {
    return (
      <BoxWrapper onClick={onClick}>
        <Box>
          <TopInfo>
            <div className='team-logo'>
              <img alt='' src={teamIcon} />
            </div>
            <div style={{ marginLeft: '15px' }}>
              <div className='box-first-name'>{firstName}</div>
              <div className='box-last-name'>{lastName}</div>
            </div>
          </TopInfo>
          <PhotoBox>
            <div className='profile-image-wrapper'>
              <div
                className='profile-image-img'
                style={{
                  backgroundImage: `url("${photo}")`,
                }}
              />
            </div>
          </PhotoBox>
        </Box>
        <BoxType>
          <h3>{membership}</h3>
        </BoxType>
      </BoxWrapper>
    );
  }
  return (
    <BoxWrapper onClick={onClick}>
      <Box>
        <CircleAdd>
          <div>
            <img alt='' src={ico_plus} />
          </div>
        </CircleAdd>
      </Box>
      <BoxType>
        <h3>ADD NEW USER</h3>
      </BoxType>
    </BoxWrapper>
  );
};

const Box = styled.div`
  width: 272px;
  height: 272px;
  border-radius: 20px;
  padding: 20px;
  background-color: ${color.greyLight};
  cursor: pointer;
  @media (max-width: 1100px) {
    width: 200px;
    height: auto;
  }
`;

const PhotoBox = styled.div`
  position: relative;
  .profile-image-wrapper {
    width: 170px;
    height: 170px;
    border-radius: 300px;
    background-color: ${color.silverLightShadow};
    padding: 10px;
    z-index: 10;
    margin: 0 auto;
    margin-top: 10px;
    @media (max-width: 1100px) {
      width: 100px;
      height: 100px;
      padding: 10px;
    }
    .profile-image-img {
      width: 150px;
      height: 150px;
      border-radius: 300px;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      @media (max-width: 1100px) {
        width: 80px;
        height: 80px;
      }
      img {
        width: 100%;
      }
    }
  }
`;

const TopInfo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  .team-logo {
    width: 45px;
    height: 50px;
    padding-right: 15px;
    border-right: solid 1px ${color.silverLight};
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .box-first-name {
    line-height: 5px;
  }
  .box-last-name {
    font-family: ${font.druk};
    font-size: 22px;
    overflow: hidden;
    @media (max-width: 1440px) {
      font-size: 18px;
    }
    @media (max-width: 1100px) {
      font-size: 14px;

      max-width: 100px;
    }
  }
`;

const BoxType = styled.div`
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
`;

const BoxWrapper = styled.div`
  width: 272px;
  margin: 10px;
  @media (max-width: 1100px) {
    width: 200px;
  }
  &:hover {
    ${Box} {
      background: ${gradient.orange};
    }
    ${BoxType} {
      h3 {
        color: ${color.orange};
      }
    }
  }
`;

const CircleAdd = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 300px;
  margin: 0 auto;
  margin-top: 20px;
  border: solid 1px ${color.orange};
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${color.greyLight};
  @media (max-width: 1100px) {
    width: 120px;
    height: 120px;
  }
  img {
    width: 25px;
  }
`;
export default UserSelectionBox;
