import * as React from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import PaymentCard from 'components/v3/Cards/PaymentCard/PaymentCard';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import Avatar from 'components/v3/Elements/Avatares/AvatarAction/AvatarAction';

import DateUtil from 'util/DateUtil';
import OrderService from 'services/v3/Order/OrderService';

import { notification } from 'components/v3/Notification/notification';

import { TitleH1, TitleH3, BodyM, BodyS } from 'styles/v3/variables';
import Logo from 'assets/imgs/logos/logo-vault-green.png';
import { useProfile } from 'hooks/v3/profile/useProfile';
import * as S from './styles';

const UpgradeMembershipPayment: React.FC = () => {
  const [urlParams] = React.useState<URLSearchParams>(
    new URLSearchParams(window.location.search)
  );
  const [urlParamsParsed, setUrlParamsParsed] = React.useState<{
    membership_id?: string,
    success?: boolean,
  }>({});

  const [isLoading, setIsLoading] = React.useState(false);

  const { currentUser, updateCurrentAccount } = useProfile();

  React.useEffect(() => {
    updateCurrentAccount();
  }, []);

  const history = useHistory();

  function handlePreviousStep() {
    history.goBack();
  }

  const createMembership = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (currentUser.membershipType === 'OFFICIAL' || isLoading) return;

    setIsLoading(true);

    OrderService.getMembershipCheckoutStripeUrl({
      userIds: [],
      cancelUrl: `/v3/profile/upgrade-membership/payment`,
      successUrl: `/v3/profile/upgrade-membership/payment?success=true`,
    })
      .then((res) => {
        if (res.httpCode === 200) {
          window.location.href = res.data.paymentUrl;
        } else {
          notification.error({
            duration: 3,
            message: 'Error',
            description:
              res.message ||
              'Error upgrading membership checkout please try again',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const currentSeason = DateUtil.formatSeason();
  const firstYear = currentSeason.split('-')[0];
  const lastYear = currentSeason.split('-')[1];

  React.useEffect(() => {
    urlParams.forEach((value, key) => {
      const newValue =
        value === 'true' || value === 'false' ? JSON.parse(value) : value;
      setUrlParamsParsed((obj) => ({ ...obj, [key]: newValue }));
    });
  }, [urlParams]);

  return (
    <S.PaymentWrapper>
      {currentUser.membershipType === 'OFFICIAL' || urlParamsParsed.success ? (
        <PaymentCard
          title='Payment was a success'
          description='You will be redirected soon. If you were not redirected, just click the button below.'
          link='/v3/home-page'
          buttonLabel='Go to home page'
          orientation='row'
          size='large'
          theme='success'
        />
      ) : (
        <>
          <TextButtonWithIcon
            className='back-button'
            color='light'
            align='flex-end'
            reverse
            icon='back'
            onClick={handlePreviousStep}
          >
            Go back
          </TextButtonWithIcon>
          <PaymentCard
            title='something went wrong'
            description='Please make sure your payment information is correct and try again'
            action={createMembership}
            buttonLabel={isLoading ? 'Processing....' : 'Continue to payment'}
            orientation='row'
            size='large'
            theme='error'
          />
        </>
      )}

      <TitleH1>Order Summary</TitleH1>

      <S.OrderSummaryWrapper>
        <S.SeasonWrapper>
          <img src={Logo} alt='Vault Logo' />
          <S.SeasonDetails>
            <TitleH3>Season {currentSeason}</TitleH3>
            <BodyM $color='grey300'>
              Starts at September 1st, {firstYear}
              <br />
              Ends at August 31th, {lastYear}
            </BodyM>
          </S.SeasonDetails>
        </S.SeasonWrapper>

        <S.MembershipDetailsWrapper>
          <S.MembershipInfo $direction='row'>
            <TitleH3 $color='brandPrimary'>$29</TitleH3>
            <BodyM>Official Membership</BodyM>
          </S.MembershipInfo>

          <S.MembershipInfo $direction='column'>
            <BodyM className='user-status'>User upgraded</BodyM>
            {currentUser?.firstName && <Avatar />}
          </S.MembershipInfo>
        </S.MembershipDetailsWrapper>
      </S.OrderSummaryWrapper>

      <BodyS className='disclaimer'>
        Your receipt will be sent to your email.
      </BodyS>
    </S.PaymentWrapper>
  );
};

export default withRouter(UpgradeMembershipPayment);
