import { RequestStatus } from 'models/Request/RequestModel';
import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  margin-top: ${toRem(14)};
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: ${toRem(4)};

  .title {
    color: ${COLORS.grey50};
  }
`;

export const DocumentList = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr ${toRem(24)};
  align-items: center;
  row-gap: ${toRem(16)};
`;

const getStatusColor = (status?: RequestStatus) => {
  switch (status) {
    case 'APPROVED':
      return COLORS.brandPrimary;
    case 'PENDING':
      return COLORS.supportNeutral;
    case 'EXPIRED':
    case 'DECLINED':
      return COLORS.supportError;
    default:
      return COLORS.grey500;
  }
};

export const StatusTag =
  styled.div <
  { $status?: RequestStatus } >
  `
  display: flex;
  padding: ${toRem(4)} ${toRem(16)};
  justify-content: center;
  align-items: center;
  gap: ${toRem(4)};
  text-transform: capitalize;

  border-radius: ${toRem(33)};
  border: ${toRem(1)} solid;
  border-color: ${({ $status }) => getStatusColor($status)};
`;
