import React from 'react';
import { PermissionFieldProps } from './types';

import * as S from './styles';

export const Permission = ({ permission }: PermissionFieldProps) => {
  const permissionText =
    permission === 'TOURNAMENT_DIRECTOR'
      ? 'Team Manager'
      : permission.toLocaleLowerCase();
  return <S.Container $permission={permission}>{permissionText}</S.Container>;
};
