import styled from 'styled-components';

import { color } from 'styles/styleVariables';

export const Wrapper = styled.div`
  padding: 50px;
  box-sizing: border-box;
  background: #333;
  border-radius: 20px;
  max-width: 800px;
  width: 800px;
`;

export const PhotoBox = styled.div`
  position: relative;

  .profile-image-wrapper {
    width: 220px;
    height: 220px;
    border-radius: 300px;
    background-color: ${color.silverLightShadow};
    padding: 10px;
    z-index: 10;
    margin: 0 auto;
    margin-top: 10px;

    @media (max-width: 1100px) {
      width: 140px;
      height: 140px;
    }
    .profile-image-img {
      width: 200px;
      height: 200px;
      border-radius: 300px;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-color: #fff;

      @media (max-width: 1100px) {
        width: 120px;
        height: 120px;
      }
      img {
        width: 100%;
      }
    }
  }
`;
