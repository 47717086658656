import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const ButtonWrapper = styled.div`
  margin-top: ${toRem(14)};
  display: flex;
  flex-direction: column;

  .title {
    color: ${COLORS.grey50};
    margin-bottom: ${toRem(18)};
  }
`;
