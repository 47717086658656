import styled from 'styled-components';
import { color } from '../../../styles/styleVariables';

const XpBarWrapper = styled.div`
  margin-bottom: 15px;
  .info-stats {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    .info-min {
      span {
        font-weight: 700;
        color: ${color.orange};
      }
    }
    .info-max {
    }
  }
  .progress-bar-wrapper {
    position: relative;
    padding: 5px 0;
    .progress {
      height: 4px;
      background-color: ${color.orange};
      position: absolute;
      z-index: 2;
      transition: all 1s;
      width: 0;
    }
    .bg {
      height: 4px;
      background-color: ${color.silver};
      position: absolute;
      width: 100%;
    }
  }
  .tag {
    font-size: 10px;
    padding: 4px 7px;
    background-color: ${color.silver};
    color: #fff !important;
    border-radius: 2px;
    margin-left: 5px;
    font-weight: 600;
  }
`;

export default XpBarWrapper;
