import { useQuery } from '@tanstack/react-query';
import ClubService from 'services/v3/Clubs/ClubService';

export function useGetClubById(id?: string) {
  const { data, isLoading, error } = useQuery(
    ['/get-club-by-id', id],
    async () => await ClubService.getClubById(id)
  );

  return {
    httpCode: data?.httpCode,
    ...data,
    data: data?.data ?? undefined,
    isLoading,
    error,
  };
}
