import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import B2bEventService from 'services/v3/B2bEvent/B2bEventService';

export function useGetMyRefereeApplications(eventId?: string) {
  const { id: accountId } = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );
  const { data, isLoading, error } = useQuery(
    ['get-my-referee-applications', eventId, accountId],
    async () => await B2bEventService.getMyRefereeApplications(eventId)
  );

  return {
    ...data,
    data: data?.data ?? [],
    isLoading,
    error,
  };
}
