import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';
import { PatchEventProps } from 'services/v3/B2bEvent/types';
import { useDispatch } from 'react-redux';
import {
  b2bSetupEventFetchEventRequest,
  b2bSetupEventPatchEventRequest,
} from 'redux/v3/b2bSetupEvent/actions';
import { sideModalSetStep } from 'redux/sideModal/actions';
import B2bEventService from 'services/v3/B2bEvent/B2bEventService';
import { DeepPartial } from 'util/types';

type MutationFnProps = {
  id: string,
  data: DeepPartial<PatchEventProps>,
};

type UseUpdateEventProps = {
  eventId?: string,
};

export const useUpdateEvent = ({ eventId }: UseUpdateEventProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: MutationFnProps) => {
      dispatch(b2bSetupEventPatchEventRequest(id, data));

      return await B2bEventService.patchEvent(id, data);
    },
    onSuccess: async () => {
      if (eventId) dispatch(b2bSetupEventFetchEventRequest(eventId));

      notification.success({
        message: 'Successfully updated event',
      });
      queryClient.invalidateQueries(['event-divisions', eventId]);
      dispatch(sideModalSetStep(0));
    },
    onError: (error: any) => {
      notification.error({
        message: 'An error occurs!',
        description: error?.message || 'Unexpected error.',
      });
    },
  });
};
