import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../../../../components/uielements/Button/Button';
import { GamePeriod } from '../../../../../../models/GameEvent/GameEventModel';
import { matchDetailsUpdateGamePreferences } from '../../../../../../redux/matchDetails/actions';
import { ApplicationState } from '../../../../../../redux/store';
import { color } from '../../../../../../styles/styleVariables';
import FinishGameModal from '../FinishGameModal/FinishGameModal';

const EndPeriodModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const matchDetails = useSelector(
    (state: ApplicationState) => state.matchDetails
  );
  const [confirmFinishGameBox, setConfirmFinishGameBox] = useState(false);

  const startPeriodTime = (period: GamePeriod) => {
    dispatch(
      matchDetailsUpdateGamePreferences({
        timing: {
          timerStart: false,
          initialTime: 0,
          timerInSeconds: 0,
          timerInSecondsTotal: 0,
          period,
          editOpen: false,
          editPeriod: period,
        },
      })
    );
  };

  const editTime = () => {
    dispatch(
      matchDetailsUpdateGamePreferences({
        timing: {
          ...matchDetails.preferences.timing!,
          editOpen: true,
        },
      })
    );
  };

  const getCurrentPeriod = () => {
    // return matchDetails.preferences.timing.period;
    switch (matchDetails.preferences.timing.period) {
      case GamePeriod.FIRST_HALF:
        return 'First Half';
        break;
      case GamePeriod.SECOND_HALF:
        return 'Second Half';
        break;
      case GamePeriod.FIRST_EXTRA_TIME:
        return 'First Extra Time';
      case GamePeriod.SECOND_EXTRA_TIME:
        return 'Second Extra Time';
        break;
      case GamePeriod.PENALTY_SHOOTOUT:
        return 'Penalty Shootout';
        break;
      default:
        break;
    }
  };

  return (
    <ConfirmBox>
      <h3 style={{ textAlign: 'center' }}>End of {getCurrentPeriod()}</h3>
      {matchDetails.preferences.timing.period === GamePeriod.FIRST_HALF && (
        <>
          <Button
            style={{ fontSize: '9px' }}
            onClick={() => startPeriodTime(GamePeriod.SECOND_HALF)}
          >
            Start Second Half
          </Button>
          <Button style={{ fontSize: '9px' }} onClick={editTime}>
            Edit Time
          </Button>
        </>
      )}
      {matchDetails.preferences.timing.period === GamePeriod.SECOND_HALF && (
        <>
          <Button
            style={{ fontSize: '9px' }}
            onClick={() => startPeriodTime(GamePeriod.FIRST_EXTRA_TIME)}
          >
            Start First Extra Time
          </Button>
          <Button
            style={{ fontSize: '9px' }}
            onClick={() => startPeriodTime(GamePeriod.PENALTY_SHOOTOUT)}
          >
            Start Penalty Shootout
          </Button>
          <Button style={{ fontSize: '9px' }} onClick={editTime}>
            Edit Time
          </Button>

          <Button
            style={{ fontSize: '9px' }}
            onClick={() => setConfirmFinishGameBox(true)}
          >
            Finish Game
          </Button>
        </>
      )}
      {matchDetails.preferences.timing.period ===
        GamePeriod.FIRST_EXTRA_TIME && (
        <>
          <Button
            style={{ fontSize: '9px' }}
            onClick={() => startPeriodTime(GamePeriod.SECOND_EXTRA_TIME)}
          >
            Start Second Extra Time
          </Button>
          <Button
            style={{ fontSize: '9px' }}
            onClick={() => startPeriodTime(GamePeriod.PENALTY_SHOOTOUT)}
          >
            Start Penalty Shootout
          </Button>
          <Button style={{ fontSize: '9px' }} onClick={editTime}>
            Edit Time
          </Button>

          <Button
            style={{ fontSize: '9px' }}
            onClick={() => setConfirmFinishGameBox(true)}
          >
            Finish Game
          </Button>
        </>
      )}
      {matchDetails.preferences.timing.period ===
        GamePeriod.SECOND_EXTRA_TIME && (
        <>
          <Button
            style={{ fontSize: '9px' }}
            onClick={() => startPeriodTime(GamePeriod.PENALTY_SHOOTOUT)}
          >
            Start Penalty Shootout
          </Button>
          <Button style={{ fontSize: '9px' }} onClick={editTime}>
            Edit Time
          </Button>
          <Button
            style={{ fontSize: '9px' }}
            onClick={() => setConfirmFinishGameBox(true)}
          >
            Finish Game
          </Button>
        </>
      )}

      {matchDetails.preferences.timing.period ===
        GamePeriod.PENALTY_SHOOTOUT && (
        <>
          <Button style={{ fontSize: '9px' }} onClick={editTime}>
            Edit Time
          </Button>
          <Button
            style={{ fontSize: '9px' }}
            onClick={() => setConfirmFinishGameBox(true)}
          >
            Finish Game
          </Button>
        </>
      )}
      <FinishGameModal
        confirmFinishGameBox={confirmFinishGameBox}
        closeModal={() => setConfirmFinishGameBox(false)}
      />
    </ConfirmBox>
  );
};

const ConfirmBox = styled.div`
  padding: 30px 50px;
  background-color: ${color.greyLight};
  color: #fff;
  border-radius: 5px;
`;
const ConfirmBt = styled.div`
  display: inline-block;
  font-weight: 600;
  margin: 10px 10px;
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
  &.save-leave {
    background-color: #fff;
    color: ${color.orange};
  }
  &:hover {
    opacity: 0.8;
  }
`;
export default EndPeriodModal;
