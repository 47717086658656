import * as React from 'react';
import { useState } from 'react';
import { ParticipationStatus } from '../../../models/Roster/RosterModel';
import RosterService from '../../../services/Rosters/RosterService';

interface IEditTeamApplicationStatusProps {
  rosterId: string | undefined;
  participationStatus: string;
  rosterStatus: string;
  handleClose: () => void;
  handleOnSave: () => void;
}

const EditTeamApplicationStatus: React.FunctionComponent<IEditTeamApplicationStatusProps> = (
  props
) => {
  const {
    rosterId,
    participationStatus,
    rosterStatus,
    handleClose,
    handleOnSave,
  } = props;
  const [selectedStatus, setSelectedStatus] = useState(participationStatus);

  const updateRosterStatus = (rosterId: string) => {
    if (selectedStatus) {
      RosterService.updateStatus(rosterId, {
        // If trying to change roster to NOT_SUBMITTED, change both eventParticipationStatus and status to PENDING
        eventParticipationStatus:
          selectedStatus == 'NOT_SUBMITTED'
            ? ParticipationStatus.PENDING
            : (selectedStatus as ParticipationStatus),
        ...(selectedStatus == 'NOT_SUBMITTED'
          ? { status: 'PENDING' }
          : { status: 'SUBMITTED' }),
      }).then((res) => {
        if (res.success) {
          handleOnSave();
          handleClose();
        }
      });
    } else {
      RosterService.updateStatus(rosterId, {
        eventParticipationStatus: selectedStatus as ParticipationStatus,
      }).then((res) => {
        if (res.success) {
          handleOnSave();
          handleClose();
        }
      });
    }
  };

  const onSave = () => {
    if (rosterId) {
      updateRosterStatus(rosterId);
    }
  };

  return (
    <div className='card-popup'>
      <h3>Roster Status</h3>
      <div className='form-group'>
        <select
          onChange={(val) => {
            setSelectedStatus(val.currentTarget.value);
            // setEditJerseyNumber({
            //   ...editJerseyNumber,
            //   newNumber: Number(val.currentTarget.value),
            // })
          }}
          value={selectedStatus}
        >
          <option value={'PENDING'}>Pending</option>
          <option value={'WAITING_PAYMENT'}>Waiting for payment</option>
          <option value={'APPROVED'}>Confirmed</option>
          <option value={'DECLINED'}>Rejected</option>
          <option value={'NOT_SUBMITTED'}>Not Submitted</option>
        </select>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: '10px',
        }}
      >
        <button
          className='btn sm white'
          onClick={handleClose}
          style={{ width: '45%' }}
        >
          Cancel
        </button>
        <button className='btn sm' style={{ width: '45%' }} onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default EditTeamApplicationStatus;
