import { Reducer } from 'redux';
import { RosterActionTypes, RosterState } from './types';

export const initialState: RosterState = {
  data: {
    creationDate: new Date('0000-00-00'),
    submittedDate: new Date('0000-00-00'),
    updateDate: new Date('0000-00-00'),
    coaches: [],
    players: [],
  },
  loading: false,
};

const reducer: Reducer<RosterState> = (state = initialState, action) => {
  switch (action.type) {
    case RosterActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }

    case RosterActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, errors: '', data: action.payload };
    }

    case RosterActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }

    case RosterActionTypes.CLEAR_ERRORS: {
      return { ...state, errors: '' };
    }

    case RosterActionTypes.UPDATE: {
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }

    case RosterActionTypes.ADD_PLAYER: {
      return {
        ...state,
        errors: '',
        loading: true,
      };
    }

    case RosterActionTypes.ADD_PLAYER_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: '',
        data: {
          players: [...state.data.players, action.payload],
        },
      };
    }

    case RosterActionTypes.REMOVE_PLAYER: {
      return {
        ...state,
        loading: true,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as rosterReducer };
