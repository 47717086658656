import { action } from 'typesafe-actions'
import { SelectedSideModal, SideModalActionTypes } from './types'

export const sideModalSetTitle = (title: string) =>
  action(SideModalActionTypes.SET_TITLE, title)

export const sideModalOpenModal = () => action(SideModalActionTypes.OPEN_MODAL)

export const sideModalCloseModal = () =>
  action(SideModalActionTypes.CLOSE_MODAL)

export const sideModalToggleModal = () =>
  action(SideModalActionTypes.TOGGLE_MODAL)

export const sideModalSetSelected = (selected: SelectedSideModal) =>
  action(SideModalActionTypes.SET_SELECTED, selected)

export const sideModalHasBackButton = (hasBackButton: boolean) =>
  action(SideModalActionTypes.HAS_BACK_BUTTON, hasBackButton)

export const sideModalBackButtonClicked = () =>
  action(SideModalActionTypes.INC_BACK_BUTTON_CLICK_COUNT)

export const sideModalSetStep = (step: number) =>
  action(SideModalActionTypes.SET_STEP, step)
