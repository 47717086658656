import { GridItemHTMLElement } from 'gridstack';
import { MatchEventModel } from 'models/Match/MatchModel';
import { stepperIntervals } from '../lib';
import { countTeamAppearances } from '../../../utils';

interface Params {
  scheduleRules: {
    minTimeBetweenGames: number,
    maxGamesPerTeam: number,
    maxTimeBetweenGames: number,
    poolGamesLength: number,
    bracketGamesLength: number,
  };
  matches: MatchEventModel[];
}

export const useGetMatchRulesSettings = (params: Params) => {
  const { scheduleRules, matches } = params;

  const getWidgetsWithSameTeams = (
    elemId: string,
    widgets?: GridItemHTMLElement[]
  ) => {
    const widgetsWithoutCurrent = widgets?.filter((widget) => {
      const id = widget?.id === '' ? widget.getAttribute('gs-id') : widget?.id;

      return id !== elemId;
    });
    const widgetIds = widgetsWithoutCurrent?.map((widget) => {
      return widget?.id === '' ? widget.getAttribute('gs-id') : widget?.id;
    });
    const matchTeams = matches.find(
      (match) => `match-${match.matchId}` === elemId
    ); // curent match

    const scheduled = matches?.filter((match) => {
      return (
        widgetIds?.includes(`match-${match?.matchId ?? ''}`) &&
        matchTeams?.ageDivisionId === match?.ageDivisionId
      );
    });

    const scheduledWidgets = scheduled.filter((match) => {
      let isFind = false;
      widgetsWithoutCurrent?.forEach((widget) => {
        if (!isFind) {
          const id =
            widget?.id === '' ? widget.getAttribute('gs-id') : widget?.id;
          isFind = id === `match-${match.matchId}`;
        }
      });

      return isFind;
    });

    const scheduledWithSameTeamsIds = scheduledWidgets
      ?.filter((match) => {
        let hasSameTeams = false;
        if (
          !!match?.homeTeam?.clubId &&
          !!match?.awayTeam?.clubId &&
          (!!matchTeams?.homeTeam?.clubId || !!matchTeams?.awayTeam?.clubId)
        ) {
          const clubIds = [match?.homeTeam?.clubId, match?.awayTeam?.clubId];
          hasSameTeams =
            clubIds.includes(matchTeams?.homeTeam?.clubId) ||
            clubIds.includes(matchTeams?.awayTeam?.clubId);
        }
        return hasSameTeams;
      })
      ?.map((item) => `match-${item.matchId}`);

    return {
      matchTeams,
      widgetsWithoutCurrent,
      scheduledWithSameTeamsIds,
    };
  };

  const checkMinTimeForSameTeams = (
    x: string,
    y: string,
    currentWidgetHeight: number,
    elemId: string,
    widgets?: GridItemHTMLElement[]
  ) => {
    let isBlocked = false;
    let message = '';
    let type = '';

    const {
      matchTeams,
      widgetsWithoutCurrent,
      scheduledWithSameTeamsIds,
    } = getWidgetsWithSameTeams(elemId, widgets);

    isBlocked = !!widgetsWithoutCurrent?.some((widget) => {
      const widgetY = parseInt(widget.getAttribute('gs-y') ?? '0', 10);
      const widgetHeight = parseInt(widget.getAttribute('gs-h') ?? '0', 10);
      const id = widget?.id === '' ? widget.getAttribute('gs-id') : widget?.id;
      let blocked = false;
      if (id && scheduledWithSameTeamsIds?.includes(id) && matchTeams) {
        const minStep = scheduleRules.minTimeBetweenGames / stepperIntervals;
        blocked =
          +y >= widgetY - minStep - currentWidgetHeight &&
          +y <= widgetY + minStep + widgetHeight;
      }

      return blocked;
    });

    if (isBlocked) {
      message = scheduleRules.minTimeBetweenGames.toString();
      type = 'minTimePerTeam';
    }

    return {
      type,
      message,
      isBlocked,
    };
  };

  const checkMaxTimeForSameTeams = (
    x: string,
    y: string,
    currentWidgetHeight: number,
    elemId: string,
    widgets?: GridItemHTMLElement[]
  ) => {
    let isBlocked = false;
    let message = '';
    let type = '';

    const {
      matchTeams,
      widgetsWithoutCurrent,
      scheduledWithSameTeamsIds,
    } = getWidgetsWithSameTeams(elemId, widgets);

    isBlocked = !!widgetsWithoutCurrent?.some((widget) => {
      const widgetY = parseInt(widget.getAttribute('gs-y') ?? '0', 10);
      const widgetHeight = parseInt(widget.getAttribute('gs-h') ?? '0', 10);
      const id = widget?.id === '' ? widget.getAttribute('gs-id') : widget?.id;
      let blocked = false;
      if (id && scheduledWithSameTeamsIds?.includes(id) && matchTeams) {
        const maxStep = scheduleRules.maxTimeBetweenGames / stepperIntervals;
        blocked =
          +y >= widgetY + maxStep + widgetHeight ||
          +y <= widgetY - maxStep - currentWidgetHeight;
      }

      return blocked;
    });

    if (isBlocked) {
      message = scheduleRules.maxTimeBetweenGames.toString();
      type = 'maxTimePerTeam';
    }

    return {
      type,
      message,
      isBlocked,
    };
  };

  const checkTeamsCount = (elemId: string, widgets?: GridItemHTMLElement[]) => {
    let isBlocked = false;
    let message = '';
    let type = '';

    const widgetIdsWithoutCurrent = widgets
      ?.filter((widget) => {
        const id =
          widget?.id === '' ? widget.getAttribute('gs-id') : widget?.id;
        return id !== elemId;
      })
      ?.map((widget) => {
        return widget?.id === '' ? widget.getAttribute('gs-id') : widget?.id;
      });

    const matchTeams = matches.find(
      (match) => `match-${match.matchId}` === elemId
    ); // curent match

    const scheduled = matches?.filter((match) => {
      return (
        widgetIdsWithoutCurrent?.includes(`match-${match?.matchId ?? ''}`) &&
        match?.ageDivisionId === matchTeams?.ageDivisionId
      );
    });

    const matchInfo = countTeamAppearances(scheduled);
    if (!isBlocked) {
      for (const key in matchInfo) {
        if (
          matchTeams?.homeTeam?.clubId === key ||
          matchTeams?.awayTeam?.clubId === key
        ) {
          if (!isBlocked) {
            isBlocked = matchInfo[key] + 1 > scheduleRules.maxGamesPerTeam;
          }
        }
      }
    }

    if (isBlocked) {
      message = scheduleRules.maxGamesPerTeam.toString();
      type = 'maxGamesPerteam';
    }
    return {
      type,
      message,
      isBlocked,
    };
  };

  return {
    checkTeamsCount,
    checkMinTimeForSameTeams,
    checkMaxTimeForSameTeams,
  };
};
