import { action } from 'typesafe-actions';
import { DeepPartial } from 'util/types';
import { PatchEventProps } from 'services/v3/B2bEvent/types';
import { B2bEventsList, B2bSetupEventActionTypes } from './types';

export const b2bSetupEventFetchEventsRequest = () =>
  action(B2bSetupEventActionTypes.FETCH_EVENTS_REQUEST);

export const b2bSetupEventFetchEventsSuccess = (data: B2bEventsList) =>
  action(B2bSetupEventActionTypes.FETCH_EVENTS_SUCCESS, data);

export const b2bSetupEventFetchEventsError = (message: string) =>
  action(B2bSetupEventActionTypes.FETCH_EVENTS_ERROR, message);

export const b2bSetupEventFetchEventRequest = (eventId: string) =>
  action(B2bSetupEventActionTypes.FETCH_EVENT_REQUEST, eventId);

export const b2bSetupEventFetchEventSuccess = (
  data: DeepPartial<PatchEventProps>
) => action(B2bSetupEventActionTypes.FETCH_EVENT_SUCCESS, data);

export const b2bSetupEventFetchEventError = (message: string) =>
  action(B2bSetupEventActionTypes.FETCH_EVENT_ERROR, message);

export const b2bSetupEventPatchEventRequest = (
  eventId: string,
  data: DeepPartial<PatchEventProps>
) => action(B2bSetupEventActionTypes.PATCH_EVENT_REQUEST, { eventId, data });

export const b2bSetupEventPatchEventSuccess = (
  data: DeepPartial<PatchEventProps>
) => action(B2bSetupEventActionTypes.PATCH_EVENT_SUCCESS, data);

export const b2bSetupEventPatchEventError = (message: any) =>
  action(B2bSetupEventActionTypes.PATCH_EVENT_ERROR, message);
