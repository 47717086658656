import {AxiosError, AxiosRequestConfig} from 'axios';
import ServiceResponseV3 from 'services/util/ServiceResponseV3';

import APIServicesV3 from 'services/util/ApiServicesV3';
import { UserViewAccounts } from 'models/User/UserViewAccounts';
import { DeepPartial } from 'redux';
import { PatchBaseUser } from 'redux/v3/baseUsers/types';
import { User } from 'models/User/UserModel';
import PageResponse from 'services/util/PageResponse';
import { ConfigApi } from '../../config.api';
import { GetProfiles, IAdvancedFilters } from './types';

interface UserDocsResponse {
  photoUser: string;
  ageProofUser: string;
  safeSSportUser: string;
}
export default class UserService {
  public static async getProfiles(): Promise<ServiceResponseV3<GetProfiles>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `users/profile-accounts`,
      method: 'GET',
    };
    return APIServicesV3.request<GetProfiles>(axiosConfig);
  }

  public static async getAllProfiles(): Promise<ServiceResponseV3<GetProfiles>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `users/profile-accounts/all`,
      method: 'GET',
    };
    return APIServicesV3.request<GetProfiles>(axiosConfig);
  }

  public static async getUser(id?: string): Promise<ServiceResponseV3<User>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/users/find-user/${id}`,
      method: 'GET',
    };
    return APIServicesV3.request<User>(axiosConfig);
  }

  public static async getBaseUserInfo(
    id?: string
  ): Promise<ServiceResponseV3<User>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/base-users/${id}`,
      method: 'GET',
    };
    return APIServicesV3.request<User>(axiosConfig);
  }

  public static async getUserInfo(
    id?: string
  ): Promise<ServiceResponseV3<UserViewAccounts | undefined>> {
    if (!id) return ServiceResponseV3.success(undefined, '');
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `users/${id}/info`,
      method: 'GET',
    };
    return APIServicesV3.request<UserViewAccounts>(axiosConfig);
  }

  public static async getUserDocsV3(
    id?: string
  ): Promise<ServiceResponseV3<UserDocsResponse | undefined>> {
    if (!id) return ServiceResponseV3.success(undefined, '');
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `users/${id}/v3/documents`,
      method: 'GET',
    };
    return APIServicesV3.request<UserDocsResponse>(axiosConfig);
  }

  public static async updateUser(
    id: string,
    data: DeepPartial<PatchBaseUser>,
    isBaseUser: boolean,
    userType?: string
  ): Promise<ServiceResponseV3<AxiosError>> {
    const url = isBaseUser ? `/base-users/${id}/v3` : `/users/${id}/v3`;

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url,
      params: {
        userType,
      },
      method: 'PUT',
      data,
    };
    return APIServicesV3.request<AxiosError>(axiosConfig);
  }

  public static async fixKcAccount(
    userId: string
  ): Promise<ServiceResponseV3<AxiosError>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/users/fix-kc-account/${userId}`,
      method: 'POST',
    };
    return APIServicesV3.request<AxiosError>(axiosConfig);
  }

  public static async fetchAdvancedFilters(): Promise<ServiceResponseV3<IAdvancedFilters>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: '/club-invites/filters',
      method: 'GET',
    };

    return APIServicesV3.request<IAdvancedFilters>(axiosConfig);
  }

  public static async fetchUsersByFilters(params: URLSearchParams): Promise<ServiceResponseV3<PageResponse<User>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: '/users',
      method: 'GET',
      params,
    };

    return APIServicesV3.request<PageResponse<User>>(axiosConfig);
  }
}
