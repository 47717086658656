import React from 'react';
import { PreviewOpen } from '@icon-park/react';
import { ViewMemberFieldProps } from './types';

import * as S from './styles';

export const ViewMember = ({ openDrawer }: ViewMemberFieldProps) => {
  return (
    <S.Container onClick={openDrawer}>
      <PreviewOpen />
    </S.Container>
  );
};
