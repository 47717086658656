import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${toRem(38)} 1fr;
  column-gap: ${toRem(16)};
  row-gap: ${toRem(8)};
  align-items: unset;

  padding: ${toRem(16)};

  border-radius: ${toRem(12)};
  background: ${COLORS.grey900};
`;


export const Column = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: ${toRem(8)};

  h4 {
    color: ${COLORS.grey50};
  }
`;

export const Icon = styled.div<{ $src?: string }>`
  width: ${toRem(38)};
  height: ${toRem(38)};
  border-radius: 50%;
  background: url(${(props) => props.$src}), lightgray 50%;
  background-size: cover;
  background-position: center;
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${toRem(8)};
  align-items: center;

  .i-icon svg {
    width: ${toRem(20)};
    height: ${toRem(20)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.grey500};
    }
  }
`;
