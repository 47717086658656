import React, { FunctionComponent } from 'react';
import { BodyM, COLORS, ColorsInterface } from 'styles/v3/variables';

import { Container } from './styles'

interface SimpleBreadCrumbWithIconProps {
  icon: React.ReactNode;
  textInformation: string;
  bgColor?: keyof ColorsInterface;
  textColor?: keyof ColorsInterface;
}

export const SimplesBreadCrumbWithIcon: FunctionComponent<SimpleBreadCrumbWithIconProps> = ({
  icon,
  textInformation,
  bgColor = 'brandPrimary',
  textColor = 'grey950',
}) => {
  return (
    <Container bgColor={bgColor}>
      {icon}
      <BodyM $color={textColor} $isUpper>{textInformation}</BodyM>
    </Container>
  );
};
