import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  padding: ${toRem(24)};

  border-radius: ${toRem(24)};
  border: ${toRem(1)} solid ${COLORS.grey800};

  background-color: ${COLORS.grey950};

  margin-top: ${toRem(40)};
`;

export const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ${toRem(24)};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export const BodyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerOfInfos = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  gap: ${toRem(16)};
`;
