import React from 'react';
import { PreviewOpen } from '@icon-park/react';

import { BodyLBold, BodyM, BodyS, COLORS } from 'styles/v3/variables';
import ProfilePhotoModal from 'components/v3/UploadModal/ProfilePhotoModal';
import { ModalState } from 'pages/V3/profile/CompleteProfile/Documents/Documents';
import { DocumentStatusLabel } from 'redux/v3/baseUsers/types';
import ProofOfAgeModal from 'components/v3/UploadModal/ProofOfAgeModal';
import SafeSportModal from 'components/v3/UploadModal/SafesportModal';

import { DocumentsProps } from './types';
import * as S from './styles';

export const Documents = ({ user }: DocumentsProps) => {
  const [modalId, setModalId] = React.useState<ModalState>('');

  return (
    <S.Container>
      <BodyLBold className='title'>Documents</BodyLBold>
      <S.DocumentList>
        <BodyM $color='grey200'>Photo</BodyM>
        <S.StatusTag $status={user.photoVerified}>
          <BodyS>{user.photoVerified}</BodyS>
        </S.StatusTag>
        <S.IconWrapper onClick={() => setModalId('photo')}>
          <PreviewOpen size={24} />
        </S.IconWrapper>

        <BodyM>Proof Of Age</BodyM>
        <S.StatusTag $status={user.ageVerified}>
          <BodyS>{user.ageVerified}</BodyS>
        </S.StatusTag>
        {user.ageProof ? (
          <S.IconWrapper onClick={() => setModalId('age')}>
            <PreviewOpen color={COLORS.grey100} size={24} />
          </S.IconWrapper>
        ) : (
          <p>&nbsp;</p>
        )}

        {user.extraInfo?.certificate ? (
          <>
            <BodyM>SafeSport</BodyM>
            <S.StatusTag $status={user.documentationVerified}>
              <BodyS>{user.documentationVerified}</BodyS>
            </S.StatusTag>
            <S.IconWrapper onClick={() => setModalId('safesport')}>
              <PreviewOpen color={COLORS.grey100} size={24} />
            </S.IconWrapper>
          </>
        ) : (
          <>
            <BodyM className='no-document'>SafeSport</BodyM>
            <p>&nbsp;</p>
            <BodyM className='no-document align-end'>No Document</BodyM>
          </>
        )}
      </S.DocumentList>

      <ProfilePhotoModal
        isOpen={modalId === 'photo'}
        status={DocumentStatusLabel.VIEW}
        handleCloseModal={() => setModalId('')}
        user={user}
      />
      <ProofOfAgeModal
        isOpen={modalId === 'age'}
        status={DocumentStatusLabel.VIEW}
        handleCloseModal={() => setModalId('')}
        user={user}
      />
      <SafeSportModal
        isOpen={modalId === 'safesport'}
        status={DocumentStatusLabel.VIEW}
        handleCloseModal={() => setModalId('')}
        user={user}
      />
    </S.Container>
  );
};
