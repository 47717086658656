import { useQuery } from '@tanstack/react-query';
import { EventInvitesTypes } from 'models/v3/EventInvite/EventInvitesModel';
import EventService from 'services/v3/Event/EventService';
import ConvertUtil from 'util/ConvertUtil';
import { EventInvitesParams } from './types';

export const useGetEventInvites = (
  id: string,
  type: EventInvitesTypes,
  params?: EventInvitesParams
) => {
  const reqParams = params && ConvertUtil.filterParams(params);
  const response = useQuery(['event-invites', id, type, reqParams], () =>
    EventService.getEventInvites(id, type, reqParams)
  );

  return {
    ...response,
    data: response.data?.data,
  };
};
