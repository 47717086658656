import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';
import CheckIconBlack from 'assets/imgs/icons/check-black.svg';
import CheckIconWhite from 'assets/imgs/icons/check-white.svg';

export const Container = styled.div`
  height: auto;
`;

export const Item = styled.div`
  position: relative;
`;

export const RadioButtonLabel = styled.label<{ $theme: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid
    ${(props) => (props.$theme === 'light' ? COLORS.white : COLORS.grey1000)};
`;

export const RadioButton = styled.input<{ $theme: string }>`
  position: relative;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  width: 25px;
  height: 25px;

  &:hover ~ ${RadioButtonLabel}, &.hover:hover ~ ${RadioButtonLabel} {
    background: ${(props) =>
      props.$theme === 'light' ? COLORS.white : COLORS.grey1000};
    border: 5px double
      ${(props) => (props.$theme === 'light' ? COLORS.grey1000 : COLORS.white)};

    &::after {
      content: '';
      background: url(${(props) =>
          props.$theme === 'light' ? CheckIconBlack : CheckIconWhite})
        center center no-repeat;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &:checked + ${Item} {
    background: ${(props) =>
      props.$theme === 'light' ? COLORS.white : COLORS.grey1000};
    border: 5px double
      ${(props) => (props.$theme === 'light' ? COLORS.grey1000 : COLORS.white)};
  }

  &:checked + ${RadioButtonLabel} {
    background: ${(props) =>
      props.$theme === 'light' ? COLORS.white : COLORS.grey1000};
    border: 5px double
      ${(props) => (props.$theme === 'light' ? COLORS.grey1000 : COLORS.white)};

    &::after {
      content: '';
      background: url(${(props) =>
          props.$theme === 'light' ? CheckIconBlack : CheckIconWhite})
        center center no-repeat;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
`;
