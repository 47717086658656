import styled from 'styled-components';
import { color, font } from '../../../styles/styleVariables';

interface CardUserProps {
  readonly bgColor?: string;
  readonly bgGradient?: string;
  readonly thin?: boolean;
}

const TopInfo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  .team-logo {
    width: 45px;
    height: 50px;
    padding-right: 15px;
    border-right: solid 1px ${color.silverLight};
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .box-first-name {
    line-height: 5px;
  }
  .box-last-name {
    font-family: ${font.druk};
    font-size: 22px;
    padding-right: 30%;
    @media (max-width: 1440px) {
      font-size: 24px;
    }
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
`;

const ProfileInfo = styled.div`
  .profile-image-wrapper {
    position: absolute;
    right: 10px;
    top: -10px;
    width: 10vw;
    height: 10vw;
    border-radius: 300px;
    background-color: ${color.silverLightShadow};
    padding: 11px;
    z-index: 10;
    @media (max-width: 1200px) {
      width: 15vw;
      height: 15vw;
    }
    @media (max-width: 768px) {
      width: 20vw;
      height: 20vw;
    }
    @media (max-width: 500px) {
      padding: 6px;
      top: 10px;
    }

    .profile-image-img {
      width: calc(10vw - 22px);
      height: calc(10vw - 22px);
      border-radius: 300px;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-color: #fff;
      @media (max-width: 1200px) {
        width: calc(15vw - 22px);
        height: calc(15vw - 22px);
      }
      @media (max-width: 768px) {
        width: calc(20vw - 22px);
        height: calc(20vw - 22px);
      }
      @media (max-width: 500px) {
        width: calc(20vw - 12px);
        height: calc(20vw - 12px);
      }
      img {
        width: 100%;
      }
    }
  }
  .number-preference {
    font-family: ${font.druk};
    font-size: 90px;
    position: absolute;
    right: 30%;
    top: -15px;
    z-index: 5;
    //color: ${color.silverLight};
    opacity: 0.3;
    -webkit-text-stroke: 1px #fff;
    -webkit-text-fill-color: transparent;
    @media (max-width: 1075px) {
      font-size: 60px;
      top: 0px;
    }
  }
  .level-box {
    color: ${color.greyLight};
    position: absolute;
    bottom: 10px;
    line-height: 22px;
    .place {
    }
    .level {
      font-family: ${font.druk};
      font-size: 24px;
    }
  }
  .custom-avatar {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 20;
    img {
      width: 100%;
    }
  }
`;

const CardUser = styled.div<CardUserProps>`
  border-radius: 24px;
  max-width: calc(41vw - 40px);
  height: 10.8vw;
  padding: 25px 20px;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    max-width: calc(58vw - 40px);
    height: 15.75vw;
  }
  @media (max-width: 768px) {
    max-width: calc(89vw);
    height: 26vw;
    margin: 0;
  }
  @media (max-width: 500px) {
    max-width: calc(89vw);
    min-height: 36vw;
    height: auto;
    margin: 0;
  }
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : color.silver};
  background: ${(props) =>
    props.bgGradient ? props.bgGradient : color.silver};

  margin: 20px auto;
  position: relative;
  &.thin {
    height: inherit !important;
    min-height: 70px;
    padding: 15px 20px;
    ${TopInfo} {
      .box-last-name {
        padding-right: 0;
      }
    }
    ${ProfileInfo} {
      .level-box {
        right: 15px;
        bottom: 15px;
      }
    }
  }
`;

export { CardUser, TopInfo, ProfileInfo };
