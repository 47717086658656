import FilledButton from 'components/v3/Buttons/FilledButton';
import * as React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { BodyL, BodyM } from 'styles/v3/variables';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';
import { NotificationsView } from 'services/v3/Notifications/types';
import { useApproveOrRejectRequests } from 'hooks/v3/clubs/useApproveOrRejectRequests/useApproveOrRejectRequests';
import AvatarPlaceholder from 'assets/imgs/avatares/avatar_placeholder.png';
import ClubService from 'services/v3/Clubs/ClubService';
import { useSetNotificationAsSeen } from 'hooks/v3/notifications/useSetNotificationAsSeen/useSetNotificationAsSeen';

import { CloseOne } from '@icon-park/react';
import { confirm } from 'components/v3/ConfirmModal/ConfirmModal';
import { useDeleteNotifications } from 'hooks/v3/notifications/useDeleteNotifications/useDeleteNotifications';
import * as S from '../styles';
import { NotificationType, InviteType } from '../types';

interface NotificationItemProps {
  notification: NotificationsView;
}

type AcceptOrDenyType = 'accept' | 'deny';

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
}) => {
  const history = useHistory();
  const notificationFormattedDate = moment(notification.createdAt).format(
    `DD MMM h:mma`
  );

  const { mutateAsync: processRequests } = useApproveOrRejectRequests([
    ['get-request-by-club-status'],
    ['get-club-members-pool'],
  ]);

  const { mutateAsync: deleteNotification } = useDeleteNotifications();

  const { mutateAsync: updateNotification } = useSetNotificationAsSeen();

  const acceptOrDenyInvite = async (
    inviteId: string,
    type: AcceptOrDenyType,
    notificationId: string
  ) => {
    await ClubService.acceptOrDenyInviteToJoinClub(inviteId, type);
    await updateNotification(notificationId);
  };

  const acceptOrDenyRequest = async (
    requesterId: string,
    type: 'APPROVE' | 'REJECT',
    notificationId: string
  ) => {
    await processRequests({
      action: type,
      requestIds: [requesterId],
    });
    await updateNotification(notificationId);
  };

  const seeRequest = async (notificationId: string, link: string) => {
    await updateNotification(notificationId);
    notification?.inviteType &&
    notification?.inviteType === 'SCHEDULE_PUBLISHED'
      ? history.push({
          pathname: link,
          state: { from: 'notification' },
        })
      : history.push(link);
  };

  const handleDelete = () => {
    confirm({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete the notification?',
      onOk: async () => {
        await deleteNotification(notification.id);
      },
    });
  };

  return (
    <S.NotificationItemContainer $unread={notification.alreadySeen}>
      {!notification.alreadySeen && <S.Bullet />}
      <S.Image src={AvatarPlaceholder} />
      <S.Info>
        <BodyL>{notification.content}</BodyL>
        <BodyM $color='grey300'>{notificationFormattedDate}</BodyM>
      </S.Info>
      {!notification.alreadySeen &&
        notification.type &&
        notification.type !== NotificationType.SEE && (
          <S.Buttons>
            {notification.type === NotificationType.OPEN ? (
              <FilledButton
                isUpper
                onClick={() =>
                  notification.link
                    ? seeRequest(notification.id, notification.link)
                    : null
                }
              >
                See Request
              </FilledButton>
            ) : (
              notification.inviteType && (
                <>
                  <OutlinedButton
                    isUpper
                    onClick={() => {
                      switch (notification.inviteType) {
                        case InviteType.JOIN_AS_PLAYER_OR_COACH_TO_CLUB:
                          if (notification.requesterId) {
                            return acceptOrDenyRequest(
                              notification.requesterId,
                              'REJECT',
                              notification.id
                            );
                          }
                          return null;
                        case InviteType.INVITE_PLAYER_OR_COACH_TO_CLUB:
                          return acceptOrDenyInvite(
                            notification.inviteId,
                            'deny',
                            notification.id
                          );
                        default:
                          return null;
                      }
                    }}
                  >
                    Decline
                  </OutlinedButton>
                  <FilledButton
                    isUpper
                    onClick={() => {
                      switch (notification.inviteType) {
                        case InviteType.JOIN_AS_PLAYER_OR_COACH_TO_CLUB:
                          if (notification.requesterId) {
                            return acceptOrDenyRequest(
                              notification.requesterId,
                              'APPROVE',
                              notification.id
                            );
                          }
                          return null;
                        case InviteType.INVITE_PLAYER_OR_COACH_TO_CLUB:
                          return acceptOrDenyInvite(
                            notification.inviteId,
                            'accept',
                            notification.id
                          );
                        default:
                          return null;
                      }
                    }}
                  >
                    {notification.inviteType ===
                      InviteType.INVITE_CLUB_TO_EVENT ||
                    notification.inviteType ===
                      InviteType.INVITE_PLAYER_OR_COACH_TO_CLUB ||
                    notification.inviteType ===
                      InviteType.INVITE_REFEREE_TO_EVENT
                      ? 'Join'
                      : 'Accept'}
                  </FilledButton>
                </>
              )
            )}
          </S.Buttons>
        )}
      {notification.alreadySeen && (
        <S.DeleteWrapper>
          <CloseOne onClick={handleDelete} />
        </S.DeleteWrapper>
      )}
    </S.NotificationItemContainer>
  );
};

export default NotificationItem;
