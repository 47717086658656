import { action } from 'typesafe-actions';
import { UserType } from '../user/types';
import { NewUserActionTypes, NewUser, ExtraInfo } from './types';

export const newUserFetchRequest = (id: string) =>
  action(NewUserActionTypes.FETCH_REQUEST);

export const newUserFetchSuccess = (data: NewUser) =>
  action(NewUserActionTypes.FETCH_SUCCESS, data);

export const newUserFetchError = (message: string) =>
  action(NewUserActionTypes.FETCH_ERROR, message);

export const newUserUpdate = (data: NewUser) =>
  action(NewUserActionTypes.UPDATE, data);

export const newUserUpdateType = (data: UserType) =>
  action(NewUserActionTypes.UPDATE_TYPE, data);

export const newUserUpdateBasicInfo = (data: NewUser) =>
  action(NewUserActionTypes.UPDATE_BASIC_DATA, data);

export const newUserUpdateBasicInfo2 = (data: NewUser) =>
  action(NewUserActionTypes.UPDATE_BASIC_DATA2, data);

export const newUserUpdateMembership = (data: {
  id?: string;
  price?: number;
}) => action(NewUserActionTypes.UPDATE_MEMBERSHIP, data);

export const newUserUpdatePhoto = (data: string) =>
  action(NewUserActionTypes.UPDATE_PHOTO, data);

export const newUserUpdateProofAge = (data: string) =>
  action(NewUserActionTypes.UPDATE_PROOF_AGE, data);

export const newUserUpdateExtraInfo = (data: ExtraInfo) =>
  action(NewUserActionTypes.UPDATE_EXTRA_INFO, data);

export const newUserReset = () => action(NewUserActionTypes.RESET);
