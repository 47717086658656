import { useQuery } from '@tanstack/react-query';
import ClubService from 'services/v3/Clubs/ClubService';

export function useGetClubsByUserId(userId?: string) {
  const { data, isLoading, error } = useQuery(
    ['get-club-by-user-id', userId],
    async () => await ClubService.getClubsByUserId(userId)
  );
  return {
    ...data,
    data: data?.data ?? [],
    isLoading,
    error,
  };
}
