import { message } from 'antd';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LightBox from '../../../../../../components/base/LightBox/LightBox';
import Button from '../../../../../../components/uielements/Button/Button';
import {
  matchDetailsFetchRequest,
  matchDetailsResetData,
  matchDetailsUpdateReport,
} from '../../../../../../redux/matchDetails/actions';
import { ApplicationState } from '../../../../../../redux/store';
import MatchService from '../../../../../../services/Match/MatchService';

interface IFinishGameModalProps {
  confirmFinishGameBox: boolean;
  closeModal: () => void;
}

const FinishGameModal: React.FunctionComponent<IFinishGameModalProps> = (
  props
) => {
  const { confirmFinishGameBox, closeModal } = props;
  const dispatch = useDispatch();

  const matchDetails = useSelector(
    (state: ApplicationState) => state.matchDetails
  );

  function groupBy(list: any, keyGetter: any) {
    const map = new Map();
    list?.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return Array.from(map);
  }

  const requiredReport = () => {
    if (checkRedCard()) {
      return !matchDetails.data.matchReport;
    } else {
      return false;
    }
  };

  const checkRedCard = () => {
    return (
      // Cheeck if same userId user has more than 1 YELLOW_CARD
      groupBy(
        matchDetails.data.match?.matchEvents.filter(
          (item) => item.action === 'YELLOW_CARD'
        ),
        (item: any) => item.userId
      ).filter((item: any) => item[1].length > 1).length ||
      // Or if there is some RED_CARD
      matchDetails.data.match?.matchEvents.find(
        (item) => item.action === 'RED_CARD'
      )
    );
  };

  const finishGame = () => {
    closeModal();
    if (matchDetails.data.match) {
      MatchService.finishGame(
        matchDetails.data.match.matchId,
        matchDetails.data.matchReport
      ).then((res) => {
        if (res.success) {
          dispatch(matchDetailsFetchRequest(matchDetails.data.match?.matchId!));
          dispatch(matchDetailsResetData());
        } else {
          message.error(
            'Ops... Something wrong happened please try again later'
          );
        }
      });
    }
  };

  return (
    <LightBox
      isOpen={confirmFinishGameBox}
      onClose={closeModal}
      contentWidth={400}
    >
      <div>
        <div className='form-group'>
          <h3>Game report</h3>
          {checkRedCard() && (
            <div style={{ color: '#ff877e', fontWeight: 'bold' }}>
              There is one or more RED CARDs in this match. Please provide the
              report.
            </div>
          )}
          <textarea
            style={{ height: '300px' }}
            value={matchDetails.data.matchReport}
            onChange={(e) => dispatch(matchDetailsUpdateReport(e.target.value))}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            className='btn sm transparent'
            onClick={() => {
              closeModal();
            }}
            aria-label='Close'
            style={{ width: '45%' }}
          >
            Cancel
          </Button>
          <Button
            disabled={requiredReport()}
            className='btn sm'
            onClick={finishGame}
            aria-label='Close'
            style={{ width: '45%' }}
          >
            Finish Game
          </Button>
        </div>
      </div>
    </LightBox>
  );
};

export default FinishGameModal;
