import * as React from 'react';
import { CSSProperties } from 'react';
import './page-container.scss';

interface PageContainerProps {
  title: string;
  titleStyle?: CSSProperties;
}
const PageContainer: React.FunctionComponent<PageContainerProps> = (props) => {
  const { children, title, titleStyle } = props;
  return (
    <div className='container'>
      <div className='container-wrapper'>
        <div>
          <h1 style={titleStyle}>{title}</h1>
        </div>

        <div className='content'>{children}</div>
      </div>
    </div>
  );
};

export default PageContainer;
