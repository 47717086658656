export type CheckoutProps = {
  email?: string,
  accountId?: string,
  cart: Array<CartItem>,
  type: OrderTypes,
  cancelUrl?: string,
  couponId: string,
};

export type MembershipCheckoutProps = {
  userIds: string[],
  cancelUrl: string,
  successUrl: string,
};

export type OrderTypes =
  | 'B2B_EVENT'
  | 'MEMBERSHIP'
  | 'EVENT_ROSTER_FEE'
  | 'UF_EVENT_ROSTER_FEE'
  | 'MIXED';

export type CartItemType = Omit<OrderTypes, 'MIXED'>;

export type CartItem = {
  b2bEvent?: any,
  b2bEventRosterFee?: CartItemRosterFee,
  productId: string,
  priceId?: string,
  type: CartItemType,
  quantity: number,
};

export type CartItemRosterFeeClub = {
  id: string,
  name: string,
  logo?: string,
};

export type CartItemRosterFee = {
  eventId: string,
  divisions: Array<CartItemRosterDivision>,
  total: number,
  fees?: number,
  club: CartItemRosterFeeClub,
};

export type CartItemRosterDivision = {
  id: string,
  name: string,
  description: string,
  quantity: number,
  itemPrice: number,
  total: number,
};

export type CheckoutReturnProps = {
  paymentUrl: string,
};

export enum PaymentStatus {
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  REFUNDED = 'REFUNDED',
}

export type SimpleOrderView = {
  id?: string,
  item: CartItem,
  accountId?: string,
  userId?: string,
  userEmail: string,
  paymentType:
    | 'FIXED_INSTALLMENT'
    | 'VARIABLE_INSTALLMENT'
    | 'FULL_PRICE'
    | 'MANUAL',
  transactions: {
    succeed: OrderTransaction[],
    failed: OrderTransaction[],
    refunded: OrderTransaction[],
  },

  paymentStatus: PaymentStatus,
  createdAt: Date,
  note?: string,
  subtotal?: number,
  discount?: number,
  discountCode?: string,
  vaultFee?: number,
  stripeFee?: number,
  totalFee?: number,
  exchangeRate?: number,
  currency?: string,
  total?: number,
  type?: 'B2B_EVENT' | 'MEMBERSHIP' | 'EVENT_ROSTER_FEE' | 'MIXED',
  paidAt?: Date,
};

export type OrderTransaction = {
  type: 'SESSION_COMPLETED' | 'REFUNDED' | 'PAYMENT_FAILED',
  created: Date,
  updated: Date,
  stripePaymentId?: string,
  stripeSubscription?: string,
  amountSubTotal?: number,
  amountTotal?: number,
  amountDiscount?: number,
  amountRefunded?: number,
  message?: string,
  receiptUrl?: string,
};
