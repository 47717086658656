import React, { useEffect, useMemo, useState, useCallback, useRef, FC } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import {
  Plus as PlusIcon,
  Check as CheckIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@icon-park/react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import { Country, State } from 'country-state-city';

import VenueServices from 'services/Venue/VenueApi';
import {
  sideModalHasBackButton,
  sideModalSetTitle,
  sideModalCloseModal,
} from 'redux/sideModal/actions';

import { VenueCreation } from 'admin/models/event/Venue';
import FilledButton from 'components/v3/Buttons/FilledButton';
import InputText from 'components/v3/Forms/InputText/InputText';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import { notification } from 'components/v3/Notification/notification';
import { confirm } from 'components/v3/ConfirmModal/ConfirmModal';

import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';

import { BodyL, BodyLBold, BodyM, BodyXLBold } from 'styles/v3/variables';

import { timezones } from 'util/timezones';
import { MyVenueView, CurrentPageType } from './types';

import * as S from './styles';

const Venues: FC = () => {
  const dispatch = useDispatch();

  const params: { eventId: string } = useParams();
  const eventId: string = params?.eventId || '';

  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const [venues, setVenues] = useState<MyVenueView[]>([]);
  const [selectedVenue, setSelectedVenue] = useState<MyVenueView>();
  const [currentPage, setCurrentPage] = useState<CurrentPageType>('Venues');
  const [stateOptions, setStateOptions] = useState<OptionsType[]>([]);

  const formRef = useRef<HTMLFormElement>(null);
  const formRefEditVenue = useRef<HTMLFormElement>(null);

  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const { backButtonClickCount, hasBackButton } = useSelector(
    (state: ApplicationState) => state.sideModal
  );

  const selectedVenuesCount = useMemo(() => venues.filter((v) => v.preSelect).length, [venues]);

  const countriesList = useMemo(() => {
    const countries = Country.getAllCountries().map((i) => ({
      label: `${i.name} ${i.flag}`,
      value: i.isoCode,
    }));

    return countries;
  }, []);

  const defaultSelectedCountry = useMemo(() => {
    return countriesList.find(
      (country) => country.value === selectedVenue?.address?.country
    );
  }, [countriesList, selectedVenue]);

  const defaultSelectedState = useMemo(() => {
    return stateOptions.find(
      (state) => state.value === selectedVenue?.address?.state
    );
  }, [stateOptions, selectedVenue]);
 
  const timezoneOptions = useMemo(() => {
    return timezones.map((timezone) => ({
      label: `${timezone.locations} - ${timezone.value}`,
      value: timezone.value,
    }))
  }, [])

  const defaultSelectedTimezone = useMemo(() => {
    return timezoneOptions.find((timezone) => timezone.value === selectedVenue?.timezone)
  }, [timezoneOptions, selectedVenue])
  
  console.log(selectedVenue);

  const loadVenues = useCallback(async () => {
    try {
      const { data, success } = await VenueServices.listEventVenues(eventId);

      if (success && data) {
        const updatedVenues =
          data.map((v) => {
            const selected =
              !!eventData?.venues?.find((e) => e?.venueId === v.id) ||
              !!venues.find((e) => e?.id === v.id && e.preSelect);
            return {
              ...v,
              selected,
              preSelect: selected,
            };
          }) ?? [];

        setVenues(updatedVenues);
      }
    } catch (e) {
      console.log('error loading venues', e);
    }
  }, [eventData, eventId]);

  const eventVenues = useMemo(() => {
    return (
      venues.filter((v) =>
        eventData?.venues?.find((e) => e?.venueId === v.id)
      ) ?? []
    );
  }, [eventData, venues]);

  const handleSaveVenue = async () => {
    try {
      if (formRef.current) {
        const formData = new FormData(formRef.current);
        const availableCourt = formData.get('availableCourt') as string;

        if (
          !(
            !!availableCourt &&
            Number(availableCourt) > 0 &&
            Number(availableCourt) < 100
          )
        ) {
          notification.error({
            message: 'Please enter a number between 0 and 100',
          });
          return;
        }

        const payload: Partial<VenueCreation> = {
          name: (formData.get('name') as string) || '',
          abbreviation: (formData.get('abbreviation') as string) || '',
          address: {
            line1: (formData.get('line1') as string) || '',
            line2: (formData.get('line2') as string) || '',
            city: (formData.get('city') as string) || '',
            state: (formData.get('state') as string) || '',
            zipCode: (formData.get('zipCode') as string) || '',
            country: (formData.get('country') as string) || '',
          },
          timezone: (formData.get('timezone') as string) || '',
          availableCourt: availableCourt ? Number(availableCourt) : 0,
        };

        VenueServices.createEventVenue(eventId, payload as VenueCreation).then(
          (response) => {
            if (!response.success) {
              notification.error({
                message: 'Error creating venue',
              });
            } else {
              setVenues([...venues, response.data]);
              setCurrentPage('Your Venues');
            }
          }
        );
      }
    } catch (e) {
      console.log('Error updating event', e);
    }
  };

  const handleUpdateVenue = async (venueId: string) => {
    try {
      if (formRefEditVenue.current) {
        const formData = new FormData(formRefEditVenue.current);

        const availableCourt = formData.get('availableCourt') as string;

        if (
          !(
            !!availableCourt &&
            Number(availableCourt) > 0 &&
            Number(availableCourt) < 100
          )
        ) {
          notification.error({
            message: 'Please enter a number between 0 and 100',
          });
          return;
        }

        const payload: Partial<VenueCreation> = {
          name: (formData.get('name') as string) || '',
          abbreviation: (formData.get('abbreviation') as string) || '',
          address: {
            line1: (formData.get('line1') as string) || '',
            line2: (formData.get('line2') as string) || '',
            city: (formData.get('city') as string) || '',
            state: (formData.get('state') as string) || '',
            zipCode: (formData.get('zipCode') as string) || '',
            country: (formData.get('country') as string) || '',
          },
          timezone: (formData.get('timezone') as string) || '',
          availableCourt: availableCourt ? Number(availableCourt) : 0,
        };

        VenueServices.updateEventVenue(
          venueId,
          eventId,
          payload as VenueCreation
        ).then((response) => {
          if (!response.success) {
            notification.error({
              message: 'Error updating venue',
            });
          } else {
            setVenues(
              venues.map((v) => (v.id === response.data.id ? response.data : v))
            );
            setCurrentPage('Venues');
          }
        });
      }
    } catch (e) {
      console.log('Error updating event', e);
    }
  };

  const handleNewPage = () => {
    setCurrentPage('New Venues');
    dispatch(sideModalHasBackButton(true));
  };

  const handleMyVenues = () => {
    setCurrentPage('Your Venues');
    dispatch(sideModalHasBackButton(true));
  };

  const handleChangeCountry = useCallback((event: any) => {
    if (event?.value) {
      const statesOfCountry = State.getStatesOfCountry(event.value).map(
        (i) => ({
          label: i.name,
          value: i.isoCode,
        })
      );

      setStateOptions(statesOfCountry);
    }
  }, []);

  const handleSelectVenue = (venue: MyVenueView) => {
    setVenues(
      venues.map((v) => ({
        ...v,
        preSelect: v.id === venue.id ? !v.preSelect : v.preSelect,
      }))
    );
  };

  const handleDeleteVenue = (venueId: string) => {
    confirm({
      message: 'Are you sure that you want to delete this venue?',
      onOk: () => {
        const newVenues = venues.filter((v) => v.id !== venueId);
        handleSaveSelectedVenues(newVenues, false);
      }
    });
  };

  const handleSaveSelectedVenues = async (updatedVenues?: MyVenueView[], isEnabledToCloseSidebar = true) => {
    const newVenues =
      updatedVenues ??
      venues.map((v) => ({
        ...v,
        selected: v.preSelect,
      }));

    setVenues(newVenues);

    const payload = {
      venues: newVenues
        .filter((v) => v.preSelect)
        .map((venue) => ({
          venueId: venue.id,
          subVenues:
            eventData.venues?.find((ev) => ev?.venueId === venue.id)
              ?.subVenues ?? [],
        })),
    };

    await mutateAsync({ id: eventId, data: payload });

    setCurrentPage('Venues');
    dispatch(sideModalHasBackButton(false));

    if (isEnabledToCloseSidebar) {
      dispatch(sideModalCloseModal());
    }
  };

  const handleLoadVenue = async (venueId: string) => {
    const { data, success } = await VenueServices.find(venueId);
    if (success) {
      setSelectedVenue(data);
    }
  };

  const handleLoadEditVenuePage = (venueId: string) => {
    setCurrentPage('Edit Venue');
    dispatch(sideModalHasBackButton(true));
    handleLoadVenue(venueId);
  };

  useEffect(() => {
    if (defaultSelectedCountry) {
      handleChangeCountry(defaultSelectedCountry);
    }
  }, [defaultSelectedCountry]);

  useEffect(() => {
    loadVenues();
  }, [eventData, loadVenues]);

  useEffect(() => {
    if (currentPage === 'Venues') {
      dispatch(sideModalHasBackButton(false));
    }

    dispatch(sideModalSetTitle(currentPage));
  }, [currentPage, dispatch]);

  useEffect(() => {
    if (hasBackButton && backButtonClickCount > 0) {
      if (currentPage === 'Your Venues') {
        setCurrentPage('Venues');
      } else if (currentPage === 'New Venues') {
        setCurrentPage('Your Venues');
      } else if (currentPage === 'Edit Venue') {
        setCurrentPage('Venues');
      }
    }

     return () => {
      setSelectedVenue({} as MyVenueView);
     }
  }, [backButtonClickCount, hasBackButton]);
  console.log(eventVenues);
  return (
    <S.Container>
      {currentPage === 'Venues' && (
        <S.VenuesWrapper>
          <S.VenuesList>
            {eventVenues.map((venue) => (
              <S.VenuesListItem key={venue.id}>
                <S.Content>
                  <BodyXLBold>{venue.name}</BodyXLBold>
                  <BodyM>{venue.abbreviation}</BodyM>
                  <BodyM>{venue.availableCourt} Courts</BodyM>
                </S.Content>
                <S.Content>
                  <S.Content
                    onClick={() => handleDeleteVenue(venue.id)}
                    className='button-container'
                  >
                    <DeleteIcon className='eventIcon delete' size='24px' />
                  </S.Content>
                  <S.Content
                    onClick={() => handleLoadEditVenuePage(venue.id)}
                    className='button-container'
                  >
                    <EditIcon className='eventIcon' size='24px' />
                  </S.Content>
                </S.Content>
              </S.VenuesListItem>
            ))}
          </S.VenuesList>
          <FilledButton
            className='add-venue-button'
            onClick={handleMyVenues}
            color='dark-white'
            isUpper
          >
            Add Venue
            <PlusIcon size='20px' />
          </FilledButton>
        </S.VenuesWrapper>
      )}
      {currentPage === 'New Venues' && (
        <S.NewVenuesFormWrapper>
          <S.VenuesForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
            <fieldset>
              <InputText
                id='name'
                label='Venue Name'
                placeholder='Venue Name'
                type='text'
                className='general-event-input'
                required
              />
            </fieldset>
            <fieldset>
              <InputText
                id='abbreviation'
                label='Abbreviation'
                placeholder='Abbreviation'
                type='text'
                className='general-event-input'
              />
            </fieldset>
            <BodyLBold $color='white'>Address</BodyLBold>
            <fieldset>
              <InputText
                required
                id='line1'
                label='Address 1'
                placeholder='Address 1'
                type='text'
                className='general-event-input'
              />
            </fieldset>
            <fieldset>
              <InputText
                id='line2'
                label='Address 2'
                placeholder='Address 2'
                type='text'
                className='general-event-input'
              />
            </fieldset>
            <fieldset>
              <Select
                required
                placeholder='Country'
                name='country'
                isSearchable
                options={countriesList}
                onChange={handleChangeCountry}
              />
            </fieldset>
            {!!stateOptions.length && (
              <fieldset>
                <Select
                  placeholder='State'
                  name='state'
                  required
                  isSearchable
                  options={stateOptions}
                />
              </fieldset>
            )}
            <fieldset>
              <Select
                placeholder='Location and timezone'
                name='timezone'
                required
                isSearchable
                options={timezoneOptions}
              />
            </fieldset>
            <fieldset>
              <InputText
                required
                id='city'
                label='City'
                placeholder='City'
                type='text'
                className='general-event-input'
              />
            </fieldset>
            <fieldset>
              <InputText
                required
                id='zipCode'
                label='Zip Code'
                placeholder='Zip Code'
                type='text'
                className='general-event-input'
              />
            </fieldset>
            <BodyLBold $color='white'>Courts</BodyLBold>
            <fieldset>
              <InputText
                id='availableCourt'
                required
                label='Number of courts'
                placeholder='Number of courts'
                type='number'
                className='general-event-input'
              />
            </fieldset>
            <FilledButton
              disabled={isLoading}
              onClick={handleSaveVenue}
              color='primary'
              isUpper
            >
              Save {isLoading && '...'}
            </FilledButton>
          </S.VenuesForm>
        </S.NewVenuesFormWrapper>
      )}
      {currentPage === 'Edit Venue' && (
        <S.NewVenuesFormWrapper>
          <S.VenuesForm
            ref={formRefEditVenue}
            onSubmit={(e) => e.preventDefault()}
          >
            <fieldset>
              <InputText
                id='name'
                label='Venue Name'
                placeholder='Venue Name'
                defaultValue={selectedVenue?.name || ''}
                type='text'
                className='general-event-input'
                required
              />
            </fieldset>
            <fieldset>
              <InputText
                id='abbreviation'
                label='Abbreviation'
                placeholder='Abbreviation'
                defaultValue={selectedVenue?.abbreviation || ''}
                type='text'
                className='general-event-input'
              />
            </fieldset>
            <BodyLBold $color='white'>Address</BodyLBold>
            <fieldset>
              <InputText
                required
                id='line1'
                label='Address 1'
                placeholder='Address 1'
                defaultValue={selectedVenue?.address?.line1 || ''}
                type='text'
                className='general-event-input'
              />
            </fieldset>
            <fieldset>
              <InputText
                id='line2'
                label='Address 2'
                placeholder='Address 2'
                defaultValue={selectedVenue?.address?.line2 || ''}
                type='text'
                className='general-event-input'
              />
            </fieldset>
            {selectedVenue?.address?.country && (
              <fieldset>
                <Select
                  required
                  placeholder='Country'
                  name='country'
                  defaultValue={defaultSelectedCountry}
                  options={countriesList}
                />
              </fieldset>
            )}
            {!!selectedVenue?.address?.state && (
              <fieldset>
                <Select
                  placeholder='State'
                  name='state'
                  required
                  defaultValue={defaultSelectedState}
                  options={stateOptions}
                />
              </fieldset>
            )}

            {!!selectedVenue?.timezone && (
              <fieldset>
                <Select
                  placeholder='Location and timezone'
                  name='timezone'
                  required
                  defaultValue={defaultSelectedTimezone}
                  options={timezoneOptions}
                />
              </fieldset>
            )}

            <fieldset>
              <InputText
                required
                id='city'
                label='City'
                placeholder='City'
                defaultValue={selectedVenue?.address?.city || ''}
                type='text'
                className='general-event-input'
              />
            </fieldset>

            <fieldset>
              <InputText
                required
                id='zipCode'
                label='Zip Code'
                placeholder='Zip Code'
                defaultValue={selectedVenue?.address?.zipCode || ''}
                type='text'
                className='general-event-input'
              />
            </fieldset>
            <BodyLBold $color='white'>Courts</BodyLBold>
            <fieldset>
              <InputText
                id='availableCourt'
                required
                label='Number of courts'
                placeholder='Number of courts'
                defaultValue={selectedVenue?.availableCourt || ''}
                type='number'
                className='general-event-input'
              />
            </fieldset>
            <FilledButton
              disabled={isLoading}
              onClick={() => handleUpdateVenue(selectedVenue?.id || '')}
              color='primary'
              isUpper
            >
              Save {isLoading && '...'}
            </FilledButton>
          </S.VenuesForm>
        </S.NewVenuesFormWrapper>
      )}
      {currentPage === 'Your Venues' && (
        <S.MyVenuesWrapper>
          <FilledButton
            className='add-venue-button'
            onClick={handleNewPage}
            color='dark-white'
            isUpper
          >
            New Venue
          </FilledButton>
          <S.MyVenuesList>
            {venues?.map((venue) => (
              <S.MyVenuesListItem
                onClick={() => handleSelectVenue(venue)}
                key={venue.id}
                $selected={venue.preSelect}
              >
                {venue.preSelect ? <CheckIcon size='24px' /> : <div />}
                <BodyL>{venue?.name}</BodyL>
              </S.MyVenuesListItem>
            ))}
          </S.MyVenuesList>
          <FilledButton
            isUpper
            disabled={selectedVenuesCount === 0}
            color='primary'
            isLoading={isLoading}
            onClick={() => handleSaveSelectedVenues()}
          >
            Select {!!selectedVenuesCount && selectedVenuesCount}
          </FilledButton>
        </S.MyVenuesWrapper>
      )}
    </S.Container>
  );
};

export default withRouter(Venues);
