import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  padding: 36px 0;
`;

export const Title = styled.span`
  width: fit-content;
  font-family: 'Boxed Regular Bold';
  font-size: 126px;
`;

export const Subtitle = styled.span`
  width: fit-content;
  font-family: 'Boxed Regular';
  font-size: 18px;
`;
