import React, { useState } from 'react';
import { Lock } from '@icon-park/react';
import { NewAgeDivision } from 'admin/models/AgeDivision';
import { BodyLBold, BodyM, BodyS, COLORS } from 'styles/v3/variables';

import {
  DivisionCardContainer,
  DivisionStripColor,
  DivisionColorInformation,
  DivisionsContentInformation,
  DivisionInformationTeams,
} from './styles';

interface DivisionCardProps {
  division: NewAgeDivision;
}

const DivisionCard: React.FC<DivisionCardProps> = ({ division, ...props }) => {
  const [statusDivision, setStatusDivision] = useState<
    'FULL' | 'LOCKED' | 'OPEN'
  >('OPEN');
  const {
    name,
    color,
    gender,
    rule,
    allowGirlBoysOnAnotherDivision,
    years,
    targetNumberOfTeams,
    lockDivision,
  } = division;

  const generateDesription = () => {
    const textRule =
      rule === 'EQUAL' ? `${years[0]} Only` : `${years[0]} Youth`;

    let textAllow = '';

    if (allowGirlBoysOnAnotherDivision) {
      if (gender === 'FEMALE') {
        textAllow = '- Allow Boys';
      } else if (gender === 'MALE') {
        textAllow = '- Allow Girls';
      }
    }

    return `${gender} - ${textRule} ${textAllow}`;
  };

  const generateInformationTeams = () => {
    const numberTeams = `0 / ${targetNumberOfTeams}`;

    // Implementation of lock division or full

    return numberTeams;
  };

  return (
    <DivisionCardContainer>
      <DivisionColorInformation>
        <DivisionStripColor colorStripe={color || COLORS.brandPrimary} />

        <DivisionsContentInformation>
          <BodyLBold>{name}</BodyLBold>
          <BodyS>{generateDesription()}</BodyS>
        </DivisionsContentInformation>
      </DivisionColorInformation>

      <DivisionInformationTeams divisionStatus={statusDivision}>
        <BodyM>{division.locked ? <Lock /> : generateInformationTeams()}</BodyM>
      </DivisionInformationTeams>
    </DivisionCardContainer>
  );
};

export default DivisionCard;
