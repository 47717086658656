import React, { useState } from 'react';

import { BodyLBold } from 'styles/v3/variables';
import { ClubsTable, ExtraButtons, RefereesTable } from './components';
import { SUB_TAB_KEYS } from './sub-tab-keys';

import * as S from './styles';

type InvitesProps = {
  id: string;
};

export const Invites = ({ id }: InvitesProps) => {
  const [activeTab, setActiveTab] = useState(SUB_TAB_KEYS.CLUBS);

  const handleOnChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <S.Container>
      <S.Tabs
        type='card'
        activeKey={activeTab}
        onChange={handleOnChange}
        tabBarExtraContent={<ExtraButtons id={id} type={activeTab as any} />}
      >
        <S.TabPane key={SUB_TAB_KEYS.CLUBS} tab={<BodyLBold>Clubs</BodyLBold>}>
          <ClubsTable id={id} />
        </S.TabPane>
        <S.TabPane
          key={SUB_TAB_KEYS.REFEREES}
          tab={<BodyLBold>Referees</BodyLBold>}
        >
          <RefereesTable id={id} />
        </S.TabPane>
      </S.Tabs>
    </S.Container>
  );
};
