import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';

const blackListIds = [
    '617c77b18fdde40dc7993e7f',
]

export default function useBlackList() {
    const { user } = useSelector((state: ApplicationState) => state);


    if (blackListIds.find(item => item === user.data.id)) {
        return true
    }
    return false;

}
