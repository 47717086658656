import React from 'react';

import { BodyM, BodyMBold } from 'styles/v3/variables';
import { NumberToUSDollar } from 'util/CurrencyUtil';
import { DivisionItemProps } from './types';
import * as S from './styles';

export const DivisionItem = ({ division }: DivisionItemProps) => {
  return (
    <S.Container>
      <S.DivisionTitle>
        <BodyM>{division.quantity}x</BodyM>
        <BodyM>{division.name}</BodyM>
      </S.DivisionTitle>
      <BodyMBold>{NumberToUSDollar.format(division.total)}</BodyMBold>
    </S.Container>
  );
};
