import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  &.smaller-space {
    gap: 18px;
  }
`;
