import { useMutation } from '@tanstack/react-query';

import UserService from 'services/v3/User/UserService';
import { IFilteredUserParameters } from 'services/v3/User/types';

import { notification } from 'components/v3/Notification/notification';

export const useGetUsersByFilters = () => {
  const { data, isLoading, mutateAsync } = useMutation({
    mutationFn: async (filters: IFilteredUserParameters) => {
      const params = new URLSearchParams();

      params.set('page', '1');
      params.set('size', '20');

      if (filters.birthYear?.length) {
        params.set('birthYear', filters.birthYear.toString());
      }

      if (filters.country?.length) {
        params.set('country', filters.country.toString());
      }

      if (filters.city?.length) {
        params.set('city', filters.city.toString());
      }

      if (filters.state?.length) {
        params.set('state', filters.state.toString());
      }

      if (filters.membershipType?.length) {
        params.set('membershipType', filters.membershipType.toString());
      }

      if (filters.gender?.length) {
        params.set('gender', filters.gender.toString());
      }

      if (filters.userType) {
        params.set('userType', filters.userType);
      }

      if (filters.name) {
        params.set('name', filters.name);
      }

      return UserService.fetchUsersByFilters(params);
    },
    onError: (error: any) => {
      notification.error({
        message: 'Failed to search users!',
        description: error.message,
      });
    },
  });

  const users = data?.data.content ?? null;

  return {
    users,
    isUsersLoading: isLoading,
    fetchUsersByFilters: mutateAsync,
  };
};
