import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
`;

export const Image =
  styled.div <
  { $src: string } >
  `
  background: url(${(props) => props.$src}), lightgray 50%;
  background-size: cover;
  background-position: center;
  width: 140px;
  height: 140px;
  border-radius: 50%;
`;
