import styled from 'styled-components';
import { Modal } from 'antd';

import FilledButton from 'components/v3/Buttons/FilledButton';

import { BREAKPOINTS } from 'styles/v3/constants';
import { COLORS } from 'styles/v3/variables';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 468px;
    border-radius: 12px;
    background-color: ${COLORS.grey900};

    button {
      margin-bottom: 0;
    }
  }

  .ant-modal-header {
    border-radius: 12px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 12px;
    padding: 6px 12px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    .ant-modal-body {
      padding: 16px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-top: 16px;
`;

export const List = styled.ol`
  padding-left: 36px;
`;

export const ListItem = styled.li`
  font-family: 'Boxed Medium';
  font-size: 16px;
  list-style-type: disc;
`;

export const ConfirmButton = styled(FilledButton)`
  background-color: ${COLORS.brandPrimary} !important;
  color: ${COLORS.grey1000};

  &:hover {
    background-color: ${COLORS.grey700} !important;
    color: ${COLORS.brandPrimary};
    border-color: ${COLORS.brandPrimary};
  }
`;
