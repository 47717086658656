import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import UploadService, {
  UploadFileResponse,
} from '../../services/upload/UploadService';
import ConvertUtil from '../../util/ConvertUtil';

interface SingleUploadProps {
  value?: string; // use this property for preview a file uploaded before
  validExtensions?: string[];
  publicFile?: boolean;

  prefix: 'user' | 'club' | 'tournament';
  onUpload: (file: UploadFileResponse) => void;
}

const SingleUpload: React.FunctionComponent<SingleUploadProps> = (props) => {
  const { validExtensions, value } = props;
  const [extension] = useState(validExtensions || ['jpeg', 'jpg', 'png']);

  const [imageUrl, setimageUrl] = useState(value);

  const [loading, setLoading] = useState(false);

  const change = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
    }

    // if (info.file.status === 'done') {
    // }
  };

  const beforeUpload = (file: File) => {
    const split = file.name.split('.');
    const type = split[split.length - 1];

    if (!extension?.includes(type)) {
      message.error(`Invalid file. Accepted only: ${extension}`);
      return false;
    }

    return true;
  };

  const upload = (event: any) => {
    UploadService.upload({
      file: event.file,
      prefix: props.prefix.toString(),
      public: props.publicFile || true,
    }).then((response) => {
      if (!response.success) {
        message.error({ content: response.message, duration: 5 });
        return;
      }

      setimageUrl(response.data.id);
      props.onUpload(response.data);
    });
  };

  const uploadButton = (
    <div>{loading ? <LoadingOutlined /> : <PlusOutlined />}</div>
  );

  // const deleteButton = (
  //     <Popconfirm
  //         title='Are you sure delete this file?'
  //         onConfirm={() => setimageUrl('')}
  //         okText='Yes'
  //         cancelText='No'
  //     >
  //         <Button shape='circle' icon={<DeleteOutlined />} />
  //     </Popconfirm>
  // );

  return (
    <>
      <Upload
        listType='picture-card'
        customRequest={upload}
        showUploadList={false}
        onChange={change}
        beforeUpload={beforeUpload}
      >
        {imageUrl !== '' ? (
          <img
            alt=''
            src={`${ConvertUtil.getMinioUrl(imageUrl)}`}
            style={{ width: '100%' }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </>
  );
};

export default SingleUpload;
