import { EventInvitesStatus } from 'models/v3/EventInvite/EventInvitesModel';
import { AllEventInvites } from 'services/v3/Event/types';

interface IUseClubDataFilterProps {
  clubs?: AllEventInvites[];
  searchValue?: string;
  statusValue?: EventInvitesStatus;
}

export const useClubDataFilter = ({
  clubs,
  searchValue,
  statusValue,
}: IUseClubDataFilterProps) => {
  if (searchValue && clubs?.length) {
    const filteredClubs = clubs?.filter((club) => {
      const clubLowerName = club.club?.name.toLocaleLowerCase();
      const searchLowerValue = searchValue.toLocaleLowerCase();

      return clubLowerName?.includes(searchLowerValue);
    });

    return filteredClubs;
  }

  if (statusValue && clubs?.length) {
    const filteredClubs = clubs?.filter(
      (club) => club.invite.status === statusValue
    );

    return filteredClubs;
  }

  return clubs ?? [];
};
