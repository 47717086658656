import React from 'react';
import { OrderItem } from './components/OrderItem/OrderItem';
import * as S from './styles';
import { OrderListProps } from './types';

export const OrderList = ({ handleOpenModal, orders }: OrderListProps) => {
  return (
    <S.Container>
      {orders.map((order) => (
        <OrderItem
          handleOpenModal={handleOpenModal}
          key={order.id}
          order={order}
        />
      ))}
    </S.Container>
  );
};
