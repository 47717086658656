import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { EventView } from '../../admin/models/event/Event';
import ExpandableContainer from '../../components/base/ExpandableContainer/ExpandableContainer';
import LightBox from '../../components/base/LightBox/LightBox';
import LoadingIcon from '../../components/base/LoadingIcon/LoadingIcon';
import TabMenu from '../../components/base/TabMenu/TabMenu';
import RosterListRow from '../../components/ClubDirector/RosterListRow/RosterListRow';
import Button from '../../components/uielements/Button/Button';
import usePrimaryClub from '../../hooks/usePrimaryClub/usePrimaryClub';
import { RosterListing } from '../../models/Roster/RosterModel';
import ClubService from '../../services/Club/ClubService';
import { color } from '../../styles/styleVariables';
import NewRosterModal from './Rosters/NewRosterModal';

const Rosters: React.FunctionComponent = () => {
  const history = useHistory();

  const [rosters, setRosters] = useState<RosterListing[]>([]);
  const [rostersFiltered, setRostersFiltred] = useState<RosterListing[]>([]);
  const [filter, setFilter] = useState('');
  const [newRosterModal, setNewRosterModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const pagination = {
    current: 0,
    pageSize: 200,
    total: 0,
    pageSizeOptions: ['10', '30', '50', '100'],
    loading: false,
  };

  const primaryClub = usePrimaryClub();

  const getRosterList = useCallback(() => {
    //setPagination({ ...pagination, loading: true });
    if (primaryClub) {
      setIsLoading(true);
      ClubService.getRosters(
        primaryClub.id,
        new URLSearchParams({
          size: pagination.pageSize.toString(),
          page: pagination.current.toString(),
        })
      ).then((res) => {
        setIsLoading(false);
        if (res.success) {
          setRosters(res.data.content);
          setRostersFiltred(res.data.content);
        }
      });
    }
  }, [primaryClub]);

  useEffect(() => {
    getRosterList();
  }, [primaryClub, getRosterList]);

  function onCreateRoster(rosterId: string) {
    setNewRosterModal(false);
    history.push(`/club-director/rosters/edit/${rosterId}`);
  }
  const filterRosters = useCallback(() => {
    if (filter) {
      let filtered = [...rosters];
      filtered = [
        ...rosters.filter((item) =>
          item.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
        ),
      ];
      //setPagination({ ...pagination, current: 0 });
      setRostersFiltred(filtered);
    } else {
      setRostersFiltred(rosters);
    }
  }, [filter, rosters]);

  const handleFilterChange = (
    event: React.FormEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setFilter(event.currentTarget.value);
  };
  useEffect(() => {
    filterRosters();
  }, [filter, filterRosters]);

  const [selectedTab, setSelectedTab] = useState('active');
  const [activeEvents, setActiveEvents] = useState<EventView[]>([]);
  useEffect(() => {
    if (primaryClub?.id) {
      if (selectedTab === 'active') {
        ClubService.getActiveEventsClub(primaryClub.id).then((response) => {
          if (response.success) {
            setActiveEvents(response.data.content);
          }
        });
      }

      if (selectedTab === 'previous') {
        ClubService.getPastEventsClub(primaryClub.id).then((response) => {
          if (response.success) {
            setActiveEvents(response.data.content);
          }
        });
      }
    }
  }, [primaryClub?.id, selectedTab]);

  return (
    <div>
      <div className='group-box'>
        <Header>
          <div style={{ width: '50%', paddingRight: '20px' }}>
            <Button
              style={{ marginTop: '0' }}
              onClick={() => setNewRosterModal(true)}
            >
              CREATE NEW ROSTER
            </Button>
          </div>
          <div className='form-group'>
            <span className='icon icon-search' />
            <input
              name='query'
              value={filter}
              onChange={handleFilterChange}
              type='text'
              className='icon'
              placeholder='Search'
            />
          </div>
        </Header>

        <TabMenu
          style={{ marginTop: '20px' }}
          items={[
            { title: 'Active Rosters', id: 'active' },
            { title: 'Previous Events', id: 'previous' },
          ]}
          onChange={(selected) => {
            setSelectedTab(selected);
          }}
        />

        {!rostersFiltered.length && !isLoading && (
          <NotFound>No Roster Found</NotFound>
        )}
        {isLoading && <LoadingIcon />}
        {activeEvents.map((event) => {
          return (
            <ExpandableContainer
              key={event.id}
              event={event}
              isExpanded={false}
            >
              {rostersFiltered

                .filter((item) => item.event?.id === event.id)
                .map((roster: any) => (
                  <RosterListRow
                    roster={roster}
                    club={primaryClub}
                    updateRosterList={() => getRosterList()}
                    key={roster.id}
                  />
                ))}
            </ExpandableContainer>
          );
        })}
      </div>
      <LightBox
        isOpen={newRosterModal}
        onClose={() => setNewRosterModal(false)}
      >
        <NewRosterModal onCreate={onCreateRoster} />
      </LightBox>
    </div>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const NotFound = styled.div`
  margin: 20px 0;
  text-align: center;
  background: ${color.greyLight};
  padding: 5px;
  border-radius: 5px;
`;

export default Rosters;
