import React, { ReactElement } from 'react';

export default function Dashboard(): ReactElement {
  return (
    <>
      <h1>Dashboard Private Route</h1>

      <p>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquam
        debitis magni incidunt autem aliquid nobis facere quaerat? Nostrum ipsa
        quasi repellat ratione consequuntur quod, rerum tempore explicabo
        ducimus recusandae itaque?
      </p>
    </>
  );
}
