import { DSConnection } from '../../models/DSConnection/DSConnection';

export enum dsConnectionActionTypes {
  FETCH_REQUEST = '@@dsConnection/FETCH_REQUEST',
  FETCH_SUCCESS = '@@dsConnection/FETCH_SUCCESS',
  FETCH_ERROR = '@@dsConnection/FETCH_ERROR',
  UPDATE = '@@dsConnection/UPDATE',
}

export interface DSConnectionState {
  readonly loading: boolean;
  readonly data: DSConnection;
  readonly errors?: string;
}
