import React from 'react';
import { CloseSmall } from '@icon-park/react';
import { COLORS } from 'styles/v3/variables';

import * as S from './styles';
import { HeaderProps } from './types';

const Header: React.FC<HeaderProps> = ({
  headerComponent,
  handleCloseModal,
}) => {
  return (
    <S.Wrapper>
      <S.BackButtonContainer>{headerComponent}</S.BackButtonContainer>
      <S.CloseButtonContainer onClick={handleCloseModal}>
        <CloseSmall theme='outline' size='24px' fill={COLORS.white} />
      </S.CloseButtonContainer>
    </S.Wrapper>
  );
};

export default Header;
