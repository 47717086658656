import styled from 'styled-components';
import BasicButton from './BasicButton';

const TextButton = styled(BasicButton)`
  color: ${(props) => props.theme.buttons.default.bgColor};
  background-color: transparent;
  border-width: 0;

  &:hover {
    color: ${(props) => props.theme.buttons.default.color};
    text-shadow: 1px 1px 2px ${(props) => props.theme.buttons.default.bgColor},
      0px -1px 2px ${(props) => props.theme.buttons.default.bgColor};
  }
`;

export default TextButton;
