import React from 'react';

import { BodyM } from 'styles/v3/variables';
import { InfoItemProps } from './types';
import * as S from './styles';

export const InfoItem = ({ icon, text }: InfoItemProps) => {
  return (
    <S.Container>
      {icon}
      <BodyM>{text}</BodyM>
    </S.Container>
  );
};
