export enum NotificationType {
  ACCEPT_OR_DECLINE = 'ACCEPT_OR_DECLINE',
  OPEN = 'OPEN',
  SEE = 'SEE',
}

export enum InviteType {
  JOIN_AS_CLUB_TO_EVENT = 'JOIN_AS_CLUB_TO_EVENT',
  JOIN_AS_REFEREE_TO_EVENT = 'JOIN_AS_REFEREE_TO_EVENT',
  JOIN_AS_PLAYER_OR_COACH_TO_CLUB = 'JOIN_AS_PLAYER_OR_COACH_TO_CLUB',
  INVITE_CLUB_TO_EVENT = 'INVITE_CLUB_TO_EVENT',
  INVITE_REFEREE_TO_EVENT = 'INVITE_REFEREE_TO_EVENT',
  INVITE_PLAYER_OR_COACH_TO_CLUB = 'INVITE_PLAYER_OR_COACH_TO_CLUB',
  SCHEDULE_PUBLISHED = 'SCHEDULE_PUBLISHED',
}

export type NotificationTabs = 'all' | 'invites';
