import React, { useMemo } from 'react';

import { BodyMBold } from 'styles/v3/variables';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import { ActionMeta, MultiValue, SingleValue } from 'react-select';
import * as S from './styles';
import { OrderFiltersProps, SelectOption } from './types';

export const OrderFilters = ({
  orders,
  setDivision,
  setClub,
  setOrderBy,
}: OrderFiltersProps) => {
  const divisions = useMemo(
    () =>
      orders.reduce<SelectOption[]>((acc, cur) => {
        const orderDivisions = cur?.item?.b2bEventRosterFee?.divisions || [];
        orderDivisions.forEach((division) => {
          if (acc.findIndex((item) => item.value === division.id) === -1) {
            acc.push({
              value: division.id,
              label: division.name,
            });
          }
        });
        return acc;
      }, []),
    [orders]
  );

  const clubs = useMemo(
    () =>
      orders.reduce<SelectOption[]>((acc, cur) => {
        const orderClub = cur?.item?.b2bEventRosterFee?.club;
        if (acc.findIndex((item) => item.value === orderClub?.id) === -1) {
          acc.push({
            value: orderClub?.id || '',
            label: orderClub?.name || '',
          });
        }
        return acc;
      }, []),
    [orders]
  );

  const [selected, setSelected] = React.useState<SelectOption | undefined>({
    value: '',
    label: 'DIVISION',
  });
  const [selectedClub, setSelectedClub] = React.useState<
    SelectOption | undefined
  >({ value: '', label: 'CLUBS' });

  const [selectedOrderBy, setSelectedOrderBy] = React.useState<
    SelectOption | undefined
  >({ value: 'last', label: 'LAST' });

  const handleSelectDivision = (
    newValue: SingleValue<OptionsType> | MultiValue<OptionsType>,
    actionMeta: ActionMeta<OptionsType>
  ) => {
    if (!newValue) return;
    if ('value' in newValue) {
      setDivision(newValue.value.toString() || '');
      if (!newValue.value) {
        setSelected(undefined);
      } else {
        setSelected({
          value: newValue.value.toString(),
          label: newValue.label,
        });
      }
    }
  };
  const handleSelectClub = (
    newValue: SingleValue<OptionsType> | MultiValue<OptionsType>,
    actionMeta: ActionMeta<OptionsType>
  ) => {
    if (!newValue) return;
    if ('value' in newValue) {
      setClub(newValue.value.toString() || '');
      if (!newValue.value) {
        setSelectedClub(undefined);
      } else {
        setSelectedClub({
          value: newValue.value.toString(),
          label: newValue.label,
        });
      }
    }
  };

  const handleSelectOrderBy = (
    newValue: SingleValue<OptionsType> | MultiValue<OptionsType>,
    actionMeta: ActionMeta<OptionsType>
  ) => {
    if (!newValue) return;
    if ('value' in newValue) {
      setOrderBy(newValue.value.toString() || 'last');
      setSelectedOrderBy({
        value: newValue.value.toString(),
        label: newValue.label,
      });
    }
  };

  return (
    <S.Container>
      <S.FilterWrapper>
        <BodyMBold $isUpper $color='grey500'>
          Filter by
        </BodyMBold>
        <S.SelectWrapper>
          <Select
            menuPlacement='auto'
            menuPosition='fixed'
            onChange={handleSelectDivision}
            placeholder='DIVISION'
            aria-label='DIVISION'
            name='division'
            value={selected}
            defaultValue={selected}
            options={[{ value: '', label: 'DIVISION' }, ...divisions]}
          />
        </S.SelectWrapper>

        <S.SelectWrapper>
          <Select
            menuPlacement='auto'
            menuPosition='fixed'
            onChange={handleSelectClub}
            placeholder='CLUB'
            aria-label='CLUB'
            name='club'
            value={selectedClub}
            defaultValue={selectedClub}
            options={[{ value: '', label: 'CLUBS' }, ...clubs]}
          />
        </S.SelectWrapper>
      </S.FilterWrapper>
      <S.OrderByWrapper>
        <BodyMBold $isUpper $color='grey500'>
          ORDER BY
        </BodyMBold>
        <S.SelectWrapper>
          <Select
            menuPlacement='auto'
            menuPosition='fixed'
            onChange={handleSelectOrderBy}
            placeholder='ORDER BY'
            aria-label='ORDER BY'
            name='orderBy'
            value={selectedOrderBy}
            defaultValue={selectedOrderBy}
            options={[
              { value: 'last', label: 'LAST' },
              { value: 'first', label: 'FIRST' },
            ]}
          />
        </S.SelectWrapper>
      </S.OrderByWrapper>
    </S.Container>
  );
};
