import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 16px 0 16px 0;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${toRem(8)};

  .i-icon svg {
    width: ${toRem(24)};
    height: ${toRem(24)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.grey400};
    }
  }
`;

export const SelectWrapper = styled.div`
  width: fit-content;
  min-width: ${toRem(140)};
`;

export const OrderByWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${toRem(24)};
`;
