import React from 'react';
import { CloseSmall, Left as BackButton } from '@icon-park/react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import { TitleH4, COLORS } from 'styles/v3/variables';
import {
  sideModalBackButtonClicked,
  sideModalSetStep,
  sideModalCloseModal,
  sideModalHasBackButton,
} from 'redux/sideModal/actions';

import * as S from './styles';

const Header: React.FC = () => {
  const { title: headerTitle, hasBackButton, step } = useSelector(
    (state: ApplicationState) => state.sideModal
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(sideModalCloseModal());
    dispatch(sideModalHasBackButton(false));
  };

  const handleBackButton = () => {
    dispatch(sideModalSetStep(step - 1));
    dispatch(sideModalBackButtonClicked());
  };

  return (
    <S.Wrapper>
      <S.BackButtonContainer>
        {(hasBackButton || step > 0) && (
          <S.CloseButtonContainer onClick={handleBackButton}>
            <BackButton theme='outline' size='24px' fill={COLORS.white} />
          </S.CloseButtonContainer>
        )}
        <TitleH4 $color='brandPrimary'>{headerTitle}</TitleH4>
      </S.BackButtonContainer>
      <S.CloseButtonContainer onClick={handleClose}>
        <CloseSmall theme='outline' size='24px' fill={COLORS.white} />
      </S.CloseButtonContainer>
    </S.Wrapper>
  );
};

export default Header;
