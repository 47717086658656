import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ApplicationState } from '../../../redux/store';
import { UserType } from '../../../redux/user/types';

const TournamentPage: React.FunctionComponent = () => {
  const user = useSelector((state: ApplicationState) => state.user);
  const history = useHistory();
  const routeParams: any = useParams();

  if (user.data.type === UserType.REFEREE) {
    history.push(`/admin-panel/tournaments/${routeParams.eventId}/games`);
  }
  if (user.data.type === UserType.STAFF) {
    history.push(
      `/admin-panel/tournaments/${routeParams.eventId}/team-applications`
    );
  }
  return <></>;
};

export default TournamentPage;
