import { Reducer } from 'redux';
import { produce } from 'immer';
import { B2bSetupEventActionTypes, B2bSetupEventState } from './types';

export const initialState: B2bSetupEventState = {
  data: {
    content: [],
    hasNextPage: false,
    hasPreviousPage: false,
    page: 0,
    pageSize: 0,
    total: 0,
  },
  eventData: {},
  loading: false,
  saving: false,
  error: '',
};

const reducer: Reducer<B2bSetupEventState> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case B2bSetupEventActionTypes.FETCH_EVENTS_SUCCESS: {
        draft.data = action.payload;
        break;
      }
      case B2bSetupEventActionTypes.FETCH_EVENT_REQUEST: {
        draft.loading = true;
        break;
      }
      case B2bSetupEventActionTypes.FETCH_EVENT_SUCCESS: {
        draft.loading = false;
        draft.eventData = action.payload;
        break;
      }
      case B2bSetupEventActionTypes.FETCH_EVENT_ERROR: {
        draft.loading = false;
        break;
      }
      case B2bSetupEventActionTypes.PATCH_EVENT_REQUEST: {
        draft.saving = true;
        break;
      }
      case B2bSetupEventActionTypes.PATCH_EVENT_SUCCESS: {
        draft.saving = false;
        break;
      }
      case B2bSetupEventActionTypes.PATCH_EVENT_ERROR: {
        draft.saving = false;
        draft.error = action.payload;
        break;
      }

      default:
        break;
    }
  });

export { reducer as b2bSetupEventReducer };
