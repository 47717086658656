import { RosterMemberpool } from '../../models/Roster/RosterModel';

export enum MemberpoolRosterActionTypes {
  FETCH_REQUEST = '@@memberpoolRoster/FETCH_REQUEST',
  FETCH_SUCCESS = '@@memberpoolRoster/FETCH_SUCCESS',
  FETCH_ERROR = '@@memberpoolRoster/FETCH_ERROR',
}

export interface MemberpoolRosterState {
  readonly loading: boolean;
  readonly data: {
    players: RosterMemberpool[];
    coaches: RosterMemberpool[];
  };
  readonly errors?: string;
}
