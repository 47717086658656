import * as React from 'react';
import styled from 'styled-components';
import players from '../../assets/imgs/players.png';
import { color } from '../../styles/styleVariables';
import Button from '../uielements/Button/Button';
interface INewSeasonBox {
  onClose?: () => void;
}

const NewSeasonBox: React.FunctionComponent<INewSeasonBox> = (props) => {
  const { onClose } = props;
  return (
    <BoxWrapper data-testid='join-club-box'>
      <div className='image'>
        <img
          style={{
            position: 'absolute',
            top: '-80px',
            left: '-75px',
          }}
          src={players}
          alt=''
        />
      </div>
      <div className='text'>
        <h2 className='text-gradient'>2023/2024 Season Begins NOW!</h2>
        <p>
          As of September 1st, all XP from the previous season have been reset
          for the start of the new season which will run until{' '}
          <b>August 31, 2024</b>. Don’t worry though, all your career
          accomplishments have been saved and are viewable at any time in your
          dashboard. <br />
          See you on the courts!
        </p>
        <Button onClick={onClose}>I understand</Button>
      </div>
    </BoxWrapper>
  );
};

const BoxWrapper = styled.div`
  border-radius: 10px;
  max-width: 600px;
  min-height: 267px;
  background-color: ${color.greyLight};
  padding: 30px;
  display: flex;
  box-shadow: 0 0 11px 0px #00000082;
  z-index: 10;
  .image {
    width: 40%;
    position: relative;
    @media (max-width: 600px) {
      display: none;
    }
  }
  .text {
    width: 60%;
    text-align: center;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

export default NewSeasonBox;
