import { Reducer } from 'redux';
import { DSConnectionStatus } from '../../models/DSConnection/DSConnection';
import { dsConnectionActionTypes, DSConnectionState } from './types';

export const initialState: DSConnectionState = {
  data: { status: DSConnectionStatus.DISCONNECTED },
  loading: false,
  errors: undefined,
};

const reducer: Reducer<DSConnectionState> = (state = initialState, action) => {
  switch (action.type) {
    case dsConnectionActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case dsConnectionActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }
    case dsConnectionActionTypes.FETCH_ERROR: {
      return { ...state, loading: false };
    }
    case dsConnectionActionTypes.UPDATE: {
      return { ...state, data: action.payload };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as dsConnectionReducer };
