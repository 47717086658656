import { Table, Tooltip } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import FilterUtil from 'util/FilterUtil';
import LightBox from '../../../components/base/LightBox/LightBox';
import LoadingIcon from '../../../components/base/LoadingIcon/LoadingIcon';
import EditTeamApplicationStatus from '../../../components/tournament/EditTeamApplicationStatus/EditTeamApplicationStatus';
import RosterApprovalButtons from '../../../components/Tournaments/RosterApprovalButtons/RosterApprovalButtons';
import VerifyRoster from '../../../components/Tournaments/RosterApprovalButtons/VerifyRoster';
import {
  ParticipationStatus,
  Roster,
  RosterListing,
} from '../../../models/Roster/RosterModel';
import { ApplicationState } from '../../../redux/store';
import EventService from '../../../services/Event/EventService';
import RosterService from '../../../services/Rosters/RosterService';
import { color } from '../../../styles/styleVariables';
import ConvertUtil from '../../../util/ConvertUtil';
import { AgeDivision } from '../../models/AgeDivision';

const TeamApplications: React.FunctionComponent = (props) => {
  const columns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any) => (
        <ClubLogo
          style={{
            backgroundImage: `url(${ConvertUtil.getMinioUrl(
              record.club.logo
            )})`,
          }}
        />
      ),
    },
    {
      title: 'Team Name',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a: any, b: any) => a.name.localeCompare(b.name),
        multiple: 4,
      },
    },
    {
      title: 'Club Name',
      key: 'club.name',
      dataIndex: ['club', 'name'],
      sorter: {
        compare: (a: any, b: any) => a.club.name.localeCompare(b.club.name),
        multiple: 3,
      },
    },
    {
      title: 'Submission date',
      key: 'submittedDate',
      dataIndex: 'submittedDate',
      sorter: {
        compare: (a: any, b: any) =>
          moment(a.submittedDate)
            .format('YYYY-MM-DD')
            .localeCompare(moment(b.submittedDate).format('YYYY-MM-DD')),
        multiple: 6,
      },
      render: (text: any, record: any) => (
        <div>{moment(text).format('MM-DD-YYYY')}</div>
      ),
    },
    {
      title: 'Gender',
      key: 'ageDivision.gender',
      dataIndex: ['ageDivision', 'gender'],
      sorter: {
        compare: (a: any, b: any) =>
          a.ageDivision.gender.localeCompare(b.ageDivision.gender),
        multiple: 5,
      },
      filters: [
        {
          text: 'COED',
          value: 'COED',
        },
        {
          text: 'MALE',
          value: 'MALE',
        },
        {
          text: 'FEMALE',
          value: 'FEMALE',
        },
      ],
      onFilter: (value: any, record: any) =>
        record.ageDivision.gender.indexOf(value) !== -1,
    },
    {
      title: 'Age Division',
      key: 'ageDivision.years',
      dataIndex: ['ageDivision', 'years'],
      sorter: {
        compare: (a: any, b: any) =>
          a.ageDivision.years
            .toString()
            .localeCompare(b.ageDivision.years.toString()),
        multiple: 4,
      },
      filters: Array.from({ length: 20 }, (_, i) => ({
        text: (2020 - i).toString(),
        value: 2020 - i,
      })),
      onFilter: (value: any, record: any) =>
        record.ageDivision.years.indexOf(value) !== -1,
      render: (text: any, record: any) => (
        <div>
          {record?.ageDivision?.years[0] === 1900
            ? 'ADULT'
            : record?.ageDivision?.years.sort().join(', ')}
        </div>
      ),
    },
    {
      title: 'Roster avaiability',
      key: 'print',
      dataIndex: 'type',
      render: (text: any, record: any) => <VerifyRoster rosterId={record.id} />,
    },
    {
      title: '',
      key: 'print',
      dataIndex: 'type',

      // eslint-disable-next-line react/display-name
      render: (text: any, record: any) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip placement='topRight' title='Edit Roster status'>
            <div
              onClick={() => editRosterStatus(record.id)}
              className='bt-action i-icon-edit-status'
            />
          </Tooltip>

          <Link to={`./roster-edit/${record.id}`}>
            <div className='bt-action i-icon-edit' />
          </Link>

          <Tooltip placement='topRight' title='Show Roster details'>
            <div
              className='bt-action i-icon-list'
              onClick={() => openRosterDetails(record.id)}
              onKeyDown={() => openRosterDetails(record.id)}
              role='button'
              aria-label='Show Roster details'
              tabIndex={0}
            />
          </Tooltip>
          <RosterApprovalButtons
            active={record.id === rosterDetails?.rosterId}
            key={record.id}
            roster={record}
            reloadList={reloadRosterList}
            openRosterDetails={openRosterDetails}
            notSubmitted={rosterStatus === 'PENDING'}
            actionsEnabled={
              participationStatus === ParticipationStatus.PENDING ||
              participationStatus === ParticipationStatus.WAITING_PAYMENT ||
              participationStatus === ParticipationStatus.APPROVED
            }
          />
        </div>
      ),
    },
  ];

  const { eventId } = useParams<{ eventId?: string }>();
  const [rostersList, setRostersList] = useState<RosterListing[]>([]);
  const [filteredRostersList, setFilteredRostersList] = useState<
    RosterListing[]
  >([]);
  const memberships = useSelector(
    (state: ApplicationState) => state.memberships
  ).data;
  const [filterQuery, setFilterQuery] = useState('');

  const [loadingDetails, setLoadingDetails] = useState(false);
  const [participationStatus, setParticipationStatus] = useState(
    ParticipationStatus.PENDING
  );
  const [rosterStatus, setRosterStatus] = useState('SUBMITTED');
  const [filterAgeDivision, setFilterAgeDivision] = useState<AgeDivision>();

  const [rosterDetails, setRosterDetails] = useState<{
    rosterId?: string;
    roster?: Roster;
  }>();
  const [rosterDetailOpen, setRosterDetailOpen] = useState(false);

  const openRosterDetails = (rosterId: string) => {
    setRosterDetailOpen(true);
    getRosterDetail(rosterId);
  };

  const getRosterDetail = (rosterId: string) => {
    setRosterDetails({ ...rosterDetails, rosterId });
  };

  useEffect(() => {
    if (rosterDetails?.rosterId) {
      setLoadingDetails(true);
      RosterService.getRoster(rosterDetails?.rosterId)
        .then((res) => {
          if (res.success) {
            setRosterDetails({ ...rosterDetails, roster: res.data });
          }
          setLoadingDetails(false);
        })
        .catch(() => {
          // setLoadingDetails(false);
        });
    }
  }, [rosterDetails?.rosterId]);

  useEffect(() => {
    if (eventId) {
      EventService.getRosters(eventId).then((res) => {
        if (res.success) {
          setRostersList(res.data);
        }
      });
    }
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      setRosterDetails({});
      setRosterDetailOpen(false);
      EventService.getRosters(eventId).then((res) => {
        if (res.success) {
          setRostersList(res.data);
        }
      });
    }
  }, [participationStatus, rosterStatus]);

  // Refresh filteredRostersList when filterQuery change
  useEffect(() => {
    if (filterQuery) {
      const filtered: RosterListing[] = [
        ...rostersList
          .filter(
            (item) =>
              item.name
                ?.toLocaleLowerCase()
                .includes(filterQuery.toLocaleLowerCase()) ||
              item.club.name
                ?.toLocaleLowerCase()
                .includes(filterQuery.toLocaleLowerCase())
          )
          .filter(
            (item) =>
              item.eventParticipationStatus === participationStatus &&
              item.status === rosterStatus &&
              (!filterAgeDivision ||
                FilterUtil.objectCompares(item.ageDivision, filterAgeDivision))
          ),
      ];
      setFilteredRostersList(filtered);
    } else {
      setFilteredRostersList(
        rostersList.filter(
          (item) =>
            item.eventParticipationStatus === participationStatus &&
            item.status === rosterStatus &&
            (!filterAgeDivision ||
              FilterUtil.objectCompares(item.ageDivision, filterAgeDivision))
        )
      );
    }
  }, [
    filterQuery,
    rostersList,
    participationStatus,
    rosterStatus,
    filterAgeDivision,
  ]);

  const reloadRosterList = () => {
    if (eventId) {
      EventService.getRosters(eventId).then((res) => {
        if (res.success) {
          setRostersList(res.data);
        }
      });
    }
  };

  const handleSearchChange = (
    event: React.FormEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setFilterQuery(event.currentTarget.value);
  };

  const handleAgeDivisionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setFilterAgeDivision(
      ConvertUtil.convertStringAgeDivision(event.currentTarget.value)
    );
  };

  const counterRosters = (
    status: ParticipationStatus,
    statusRoster?: string
  ) => {
    return rostersList.filter(
      (item) =>
        item.eventParticipationStatus === status &&
        (!filterAgeDivision ||
          FilterUtil.objectCompares(item.ageDivision, filterAgeDivision)) &&
        item.status === (statusRoster || 'SUBMITTED')
    ).length;
  };

  const [isEditStatusOpen, setIsEditStatusOpen] = useState(false);
  const [editStaatusRosterId, setEditStaatusRosterId] = useState<
    string | undefined
  >(undefined);

  const editRosterStatus = (rosterId: string) => {
    setIsEditStatusOpen(true);
    setEditStaatusRosterId(rosterId);
  };

  const onEditRosterStatusSuccess = () => {
    reloadRosterList();
  };

  return (
    <>
      <h1>Team Applications</h1>

      <div style={{ display: 'flex' }}>
        <div
          style={{
            transition: 'all .5s',
            width: rosterDetailOpen ? 'calc(100% - 300px)' : '100%',
            marginRight: '20px',
          }}
        >
          <div>
            <div className='group-box' style={{ margin: 0 }}>
              <div className='form-group'>
                <span className='icon icon-search' />
                <input
                  name='query'
                  value={filterQuery}
                  onChange={handleSearchChange}
                  type='text'
                  className='icon'
                  placeholder='Search'
                />
              </div>

              <div className='form-group'>
                <select defaultValue='' onChange={handleAgeDivisionChange}>
                  <option value='' disabled>
                    Select Age Division
                  </option>
                  <option value=''>All Divisions</option>
                  {rostersList
                    .map(
                      (item) =>
                        `${item.ageDivision?.gender} ${
                          item.ageDivision?.years[0] === 1900
                            ? 'ADULT'
                            : item.ageDivision?.years.sort().join(', ')
                        }, ${item.ageDivision?.rule}`
                    )
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )
                    .map((item) => (
                      <option key={item}>{item}</option>
                    ))}
                </select>
              </div>
              <div className='tab-card-wrapper'>
                <div
                  className={`item ${
                    participationStatus === 'PENDING' &&
                    rosterStatus === 'SUBMITTED'
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => {
                    setParticipationStatus(ParticipationStatus.PENDING);
                    setRosterStatus('SUBMITTED');
                  }}
                >
                  Pending {`(${counterRosters(ParticipationStatus.PENDING)})`}
                </div>
                <div
                  className={`item ${
                    participationStatus === 'WAITING_PAYMENT' ? 'active' : ''
                  }`}
                  onClick={() => {
                    setParticipationStatus(ParticipationStatus.WAITING_PAYMENT);
                    setRosterStatus('SUBMITTED');
                  }}
                >
                  Waiting for payment{' '}
                  {`(${counterRosters(ParticipationStatus.WAITING_PAYMENT)})`}
                </div>
                <div
                  className={`item ${
                    participationStatus === 'APPROVED' ? 'active' : ''
                  }`}
                  onClick={() => {
                    setParticipationStatus(ParticipationStatus.APPROVED);
                    setRosterStatus('SUBMITTED');
                  }}
                >
                  Confirmed{' '}
                  {`(${counterRosters(ParticipationStatus.APPROVED)})`}
                </div>
                <div
                  className={`item ${
                    participationStatus === 'DECLINED' ? 'active' : ''
                  }`}
                  onClick={() =>
                    setParticipationStatus(ParticipationStatus.DECLINED)
                  }
                >
                  Rejected {`(${counterRosters(ParticipationStatus.DECLINED)})`}
                </div>

                <div
                  className={`item ${
                    participationStatus === 'PENDING' &&
                    rosterStatus === 'PENDING'
                      ? 'active-orange'
                      : ''
                  }`}
                  onClick={() => {
                    setParticipationStatus(ParticipationStatus.PENDING);
                    setRosterStatus('PENDING');
                  }}
                >
                  Not Submitted{' '}
                  {`(${counterRosters(
                    ParticipationStatus.PENDING,
                    'PENDING'
                  )})`}
                </div>
              </div>

              {participationStatus === 'PENDING' && rosterStatus === 'PENDING' && (
                <h4
                  style={{
                    color: color.orange,
                    padding: '20px 20px',
                    backgroundColor: color.greyDark,
                    borderRadius: '10px',
                    marginTop: '10px',
                  }}
                >
                  Attention: This list shows Rosters that has NOT been submitted
                  by the Clubs yet.
                </h4>
              )}

              <Table
                pagination={{ position: ['bottomCenter'], defaultPageSize: 10 }}
                dataSource={filteredRostersList}
                columns={columns}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
        <RosterListWrapper
          className='styled-scrollbar'
          style={{
            width: rosterDetailOpen ? '300px' : 0,
            opacity: rosterDetailOpen ? 1 : 0,
            padding: rosterDetailOpen ? '15px' : 0,
          }}
        >
          <PlayerList>
            {loadingDetails && (
              <>
                <LoadingIcon />
              </>
            )}
            {!loadingDetails && (
              <>
                <div className='title'>
                  <div style={{ fontSize: '12px' }}>
                    {rosterDetails?.roster?.club?.name}
                  </div>
                  <h3>{rosterDetails?.roster?.name}</h3>
                </div>

                <div className='list styled-scrollbar'>
                  <div className='bold'>Coaches</div>
                  {rosterDetails?.roster?.coaches.map((coach) => (
                    <PlayerRow key={coach.id}>
                      <div
                        className='photo'
                        style={{
                          backgroundImage: `url(${ConvertUtil.getMinioUrl(
                            coach?.approvedPhoto || coach?.photo
                          )})`,
                        }}
                      />
                      <div className='info'>
                        <div className='name'>{`${coach.firstName} ${coach.lastName}`}</div>
                        <div className='birthday'>{coach.birthDate}</div>
                        <div className='type'>
                          {
                            memberships.find(
                              (item) => item.id === coach.membershipId
                            )?.name
                          }
                        </div>
                      </div>
                    </PlayerRow>
                  ))}

                  <div className='bold'>Players</div>
                  {rosterDetails?.roster?.players.map((player) => (
                    <PlayerRow key={player.id}>
                      <div
                        className='photo'
                        style={{
                          backgroundImage: `url(${ConvertUtil.getMinioUrl(
                            player?.approvedPhoto || player?.photo
                          )})`,
                        }}
                      >
                        <div className='number'>{player.number || '-'}</div>
                      </div>

                      <div className='info'>
                        <div className='name'>{`${player.firstName} ${player.lastName}`}</div>
                        <div className='birthday'>{player.birthDate}</div>
                        <div className='type'>
                          {
                            memberships.find(
                              (item) => item.id === player.membershipId
                            )?.name
                          }
                        </div>
                      </div>
                    </PlayerRow>
                  ))}
                </div>
              </>
            )}
          </PlayerList>
        </RosterListWrapper>

        <LightBox
          isOpen={isEditStatusOpen}
          onClose={() => {
            setIsEditStatusOpen(false);
          }}
        >
          <EditTeamApplicationStatus
            rosterId={editStaatusRosterId}
            participationStatus={participationStatus}
            rosterStatus={rosterStatus}
            handleClose={() => {
              setIsEditStatusOpen(false);
            }}
            handleOnSave={onEditRosterStatusSuccess}
          />
        </LightBox>
      </div>
    </>
  );
};

const ClubLogo = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
`;

const RosterListWrapper = styled.div`
  transition: all 0.5s;
  height: calc(100vh - 200px);
  position: relative;
  overflow: hidden;
  background-color: ${color.greyLight};
  border-radius: 24px;
  position: fixed;
  right: 10px;
`;
const PlayerList = styled.div`
  // background-color: ${color.greyLight};
  // padding: 15px;
  // border-radius: 10px;
  transition: all 0.5s;
  &.open {
    right: 50px;
  }
  .title {
    text-align: center;
    &:after {
      position: absolute;
      content: '';
      height: 10px;
      width: 100%;
    }
    h3 {
      border-bottom: 1px solid #52575e;
      padding-bottom: 10px;
      margin-bottom: 0px;
      padding: 5px 15px 15px 15px;
    }
  }

  .list {
    overflow-x: hidden;
    height: calc(80vh - 120px);
    padding-top: 20px;
  }
`;

const PlayerRow = styled.div`
  padding: 5px;
  display: grid;
  grid-template-columns: 76px 1fr;
  grid-column-gap: 10px;
  border-radius: 10px;
  align-items: center;
  margin: 10px 0;
  .photo {
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    background-color: #fff;
    background-size: cover;
    background-position: center;
    position: relative;
    .number {
      position: absolute;
      z-index: 10;
      font-weight: 600;
      width: 20px;
      height: 20px;
      text-align: center;
      bottom: 0;
      right: 0;
      text-align: center;
      font-size: 10px;
      background-color: #fff;
      border-radius: 10px;
      line-height: 21px;
      color: ${color.greyDark};
    }
  }

  .info {
    font-size: 12px;
    font-weight: 600;
    .birthday {
      font-weight: 300;
    }
    .type {
      color: ${color.orange};
    }
  }
`;
const Tab = styled.div`
  display: flex;
  margin-bottom: 20px;
  .item {
    padding: 5px 0;
    margin-right: 20px;
    font-weight: 400;
    color: ${color.silver};
    &.active {
      color: #fff;
      border-bottom: 2px solid #fff;
    }
  }
`;

export default TeamApplications;
