import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import LightBox from '../../../components/base/LightBox/LightBox';
import Loading from '../../../components/base/Loading/Loading';
import LoadingIcon from '../../../components/base/LoadingIcon/LoadingIcon';
import { matchDetailsUpdateReport } from '../../../redux/matchDetails/actions';
import { ApplicationState } from '../../../redux/store';
import MatchService from '../../../services/Match/MatchService';
import { color } from '../../../styles/styleVariables';

interface IGameReportModalProps {
  matchId?: string;
  gameReportOpen: boolean;
  closeGameReport: () => void;
  extraInfo?: string;
}

const GameReportModalEdit: React.FunctionComponent<IGameReportModalProps> = (
  props
) => {
  const dispatch = useDispatch();
  const { gameReportOpen, closeGameReport, extraInfo } = props;
  const [loading, setLoading] = useState(false);

  const matchDetails = useSelector(
    (state: ApplicationState) => state.matchDetails
  );

  return (
    <LightBox
      isOpen={gameReportOpen}
      onClose={() => closeGameReport()}
      contentWidth={400}
    >
      <div
        style={{
          padding: '15px',
          borderRadius: '10px',
          backgroundColor: color.greyDark,
        }}
      >
        <div className='form-group'>
          <h2>Game report</h2>
          {extraInfo && (
            <p style={{ color: '#ff877e', fontWeight: 'bold' }}>{extraInfo}</p>
          )}
          <textarea
            style={{ height: '300px' }}
            value={matchDetails.data.matchReport}
            onChange={(e) => {
              dispatch(matchDetailsUpdateReport(e.target.value));
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <button
            className='btn sm'
            onClick={() => closeGameReport()}
            onKeyDown={() => closeGameReport()}
            role='button'
            tabIndex={0}
            aria-label='Close'
            style={{ width: '45%' }}
          >
            Save Report
          </button>
        </div>
      </div>
    </LightBox>
  );
};

const WrapperBox = styled.div`
  padding: 20px;
  box-sizing: border-box;
  background: ${color.greyLight};
  border-radius: 20px;
  width: 400px;
  .report {
    border: 1px solid #cccccc29;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    white-space: pre-line;
  }
`;
export default GameReportModalEdit;
