import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { EventListView, EventView } from '../../../admin/models/event/Event';
import { color, font } from '../../../styles/styleVariables';
import ConvertUtil from '../../../util/ConvertUtil';

interface IEventCardProps {
  event: EventListView | EventView;
  style?: CSSProperties;
}

const EventCard: React.FunctionComponent<IEventCardProps> = (props) => {
  const { event, style } = props;
  return (
    <CardEvent style={style}>
      <div className='image'>
        <img alt='' src={`${ConvertUtil.getMinioUrl(event.logo)}`} />
      </div>
      <div className='text'>
        <div className='title'>{event.name}</div>
        <div className='date'>
          {event.startDate} - {event.endDate}
        </div>
        {/* <div className='description'>
                              {event.description}
                            </div> */}
        {event.venues && (
          <div className='location'>{`${event.venues[0]?.name} - ${event.venues[0]?.address.city}, ${event.venues[0]?.address.country}`}</div>
        )}
      </div>
    </CardEvent>
  );
};

const CardEvent = styled.div`
  padding: 20px;
  border-radius: 20px;
  background: ${color.greyLight};
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  transition: background-color 0.5s;
  &:hover {
    .image {
      img {
        transform: scale(1.1);
      }
    }
  }
  .image {
    width: 40%;
    padding-right: 25px;
    img {
      transition: all 0.5s;
      width: 100%;
    }
  }
  .text {
    width: 60%;
    .title {
      font-family: ${font.druk};
    }
    .location {
      font-weight: 600;
      margin: 10px 0;
      color: ${color.orange};
    }
    .description {
      padding-top: 10px;
      text-align: justify;
      font-size: 12px;
      color: ${color.silverLight};
    }
  }
  @media (max-width: 1400px) {
    flex-wrap: wrap;
    .image {
      width: 75%;
      margin: 0 auto;
      margin-bottom: 20px;
      padding: 0;
    }
    .text {
      width: 100%;
    }
  }
`;

export default EventCard;
