import React from 'react';
import { PeoplePlusOne, Plus } from '@icon-park/react';
import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';
import { Link } from 'react-router-dom';

type ExtraButtonsProps = {
  id: string;
  type: 'clubs' | 'referees';
};

export const ExtraButtons = ({ id, type }: ExtraButtonsProps) => {
  if (type === 'clubs') {
    return (
      <Link to={`/v3/invites/${type}/${id}`}>
        <OutlinedButtonWithIcon isUpper customIcon={<Plus />}>
          Invite club
        </OutlinedButtonWithIcon>
      </Link>
    );
  }

  if (type === 'referees') {
    return (
      <Link to={`/v3/invites/${type}/${id}`}>
        <OutlinedButtonWithIcon isUpper customIcon={<PeoplePlusOne />}>
          Invite referee
        </OutlinedButtonWithIcon>
      </Link>
    );
  }

  return null;
};
