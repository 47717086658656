import { RequestStatus } from 'models/Request/RequestModel';
import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  margin-top: ${toRem(14)};
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: ${toRem(4)};

  .title {
    color: ${COLORS.grey50};
  }
`;

export const DocumentList = styled.div`
  margin-top: ${toRem(24)};
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr 2fr;
  align-items: center;
  row-gap: ${toRem(32)};

  .no-document {
    color: ${COLORS.grey700};
  }
  .align-end {
    justify-self: flex-end;
  }
`;

const getStatusColor = (status?: RequestStatus) => {
  switch (status) {
    case 'APPROVED':
      return COLORS.brandPrimary;
    case 'PENDING':
      return COLORS.supportNeutral;
    case 'EXPIRED':
    case 'DECLINED':
      return COLORS.supportError;
    default:
      return COLORS.grey500;
  }
};

export const StatusTag = styled.div<{ $status?: RequestStatus }>`
  display: flex;
  padding: ${toRem(4)} ${toRem(16)};
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  gap: ${toRem(4)};
  text-transform: capitalize;

  border-radius: ${toRem(33)};
  background-color: ${({ $status }) => getStatusColor($status)};

  p {
    color: ${(props) => {
      if (props.$status === 'EXPIRED' || props.$status === 'DECLINED') {
        return COLORS.white;
      }
      return COLORS.grey800;
    }};
  }
`;

export const IconWrapper = styled.span`
  width: ${toRem(40)};
  height: ${toRem(40)};
  border-radius: ${toRem(12)};
  background-color: ${COLORS.grey700};
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  cursor: pointer;

  .i-icon {
    display: flex;
  }
`;
