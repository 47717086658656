import { DatePicker, message, TimePicker } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../components/uielements/Button/Button';
import { MatchModel, MatchStatus } from '../../../models/Match/MatchModel';
import EventService from '../../../services/Event/EventService';
import MatchService from '../../../services/Match/MatchService';
import { color } from '../../../styles/styleVariables';
import { VenueView } from '../../models/event/Venue';

interface IGameEditModalProps {
  match?: MatchModel;
  onSave?: () => void;
}

const MatchEditModal: React.FunctionComponent<IGameEditModalProps> = (
  props
) => {
  const { match, onSave } = props;
  const { eventId } = useParams<{ eventId: string }>();
  const [venue, setVenue] = useState<VenueView[]>();
  const [selectedVenue, setSelectedVenue] = useState<VenueView>();
  const history = useHistory();

  useEffect(() => {
    EventService.detail(eventId).then((res) => {
      if (res.success) {
        setVenue(res.data.venues);
        setSelectedVenue(
          res.data.venues.find((venue) => venue.id === match?.venue?.id)
        );
      }
    });
  }, [eventId]);

  const { register, handleSubmit, errors, control, setValue } = useForm<
    MatchModel
  >();

  const saveMatch = (data: any) => {
    const parsedData = {
      ...data,
      date: data.date
        .set({
          hour: data.time?.get('hour'),
          minute: data.time?.get('minute'),
          second: data.time?.get('second'),
        })
        .format('YYYY-MM-DDTHH:mm:ss.SSSSSS'),
      subVenue: {
        abbreviation: '',
        name: data.subVenue || '',
      },
    };

    MatchService.update({ matchId: match?.id!, body: parsedData }).then(
      (res) => {
        if (res.success) {
          onSave?.call(null);
        } else {
          message.error(
            'Ops... Something wrong happened please try again later'
          );
        }
      }
    );
  };

  const onChangeVenue = (event: any) => {
    const item = venue?.filter(
      (item) => item.id == event.currentTarget.value
    )[0];
    setSelectedVenue(item);
  };
  return (
    <WrapperBox>
      <h3>Edit Game</h3>
      <div>
        <form onSubmit={handleSubmit(saveMatch)}>
          <div style={{ marginTop: '20px' }}>
            <div className='form-group'>
              <div className='label'>Select Date</div>
              <Controller
                render={(properties) => (
                  <DatePicker {...properties} format='YYYY/DD/MM' />
                )}
                defaultValue={moment(match?.date)}
                id='date'
                name='date'
                rules={{ required: true }}
                control={control}
              />
              <div className='text-orange'>
                {errors.date && 'Date is required'}
              </div>
            </div>

            <div className='form-group'>
              <div className='label'>Select Time</div>
              <Controller
                render={(properties) => (
                  <TimePicker {...properties} format='HH:mm' />
                )}
                defaultValue={moment(match?.date)}
                id='time'
                name='time'
                rules={{ required: true }}
                control={control}
              />
              <div className='text-orange'>
                {errors.time && 'Time is required'}
              </div>
            </div>

            {venue?.length && (
              <div className='form-group'>
                <div className='label'>Select Venue</div>
                <select
                  placeholder='Venue'
                  defaultValue={match?.venue?.id}
                  name='venueId'
                  ref={register()}
                  onChange={onChangeVenue}
                >
                  <option disabled value=''>
                    Select venue
                  </option>
                  {venue?.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            {selectedVenue?.subVenues && (
              <div className='form-group'>
                <div className='label'>Select Sub-Venue</div>
                <select
                  placeholder='Sub-Venue'
                  defaultValue={match?.subVenue?.name}
                  name='subVenue'
                  ref={register()}
                >
                  <option disabled value='123'>
                    Select sub-venue
                  </option>
                  {selectedVenue?.subVenues?.map((subvenue) => {
                    return (
                      <option key={subvenue.name} value={subvenue.name}>
                        {subvenue.name} ({subvenue.abbreviation})
                      </option>
                    );
                  })}
                </select>
                <div className='text-orange'>
                  {errors.subVenue && 'Sub venue is required'}
                </div>
              </div>
            )}
            {match?.status === MatchStatus.SCHEDULED && (
              <Button
                onClick={() =>
                  history.push(
                    `/admin-panel/tournaments/${eventId}/game-creation/edit/${match.id}`
                  )
                }
              >
                Edit Teams
              </Button>
            )}

            <Button onClick={() => handleSubmit(saveMatch)}>Save</Button>
          </div>
        </form>
      </div>
    </WrapperBox>
  );
};

const WrapperBox = styled.div`
  padding: 20px;
  box-sizing: border-box;
  background: ${color.greyLight};
  border-radius: 20px;
`;
export default MatchEditModal;
