import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import { PDFReader } from 'reactjs-pdf-reader';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons/lib';
import { TextAreaRef } from 'antd/lib/input/TextArea';

import DownloadService from 'services/download/DownloadService';

import { User } from '../../../models/User/UserModel';
import ConvertUtil from '../../../util/ConvertUtil';
import Button from '../../uielements/Button/Button';
import LightBox from '../LightBox/LightBox';

import { REJECT_REASONS_MESSAGES } from './configs';

import * as S from './styles';

interface IDocumentApprovalBoxProps {
  isOpen: boolean;
  title: string;
  documentUrl: string;
  user?: User;
  documentType: 'photo' | 'age' | 'documents';
  labelConfirm: string;
  labelCancel: string;
  onConfirm: () => void;
  onCancel: () => void;
  setFeedback: (text: string) => void;
  onClose: () => void;
}

const DocumentApprovalBox: FC<IDocumentApprovalBoxProps> = ({
  documentType,
  documentUrl,
  user,
  title,
  labelConfirm,
  labelCancel,
  isOpen,
  onConfirm,
  onCancel,
  setFeedback,
  onClose,
}) => {
  const feedbackRef = useRef<TextAreaRef>(null);

  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [fileType, setFileType] = useState<'PDF' | 'IMAGE' | ''>('');
  const [loading, setLoading] = useState<boolean>(true);

  const [privateDocuments, setPrivateDocuments] = useState('');

  const currentDocument = useMemo(() => {
    if (!documentUrl.length) {
      return null;
    }

    if (documentType === 'age' || documentType === 'documents') {
      return ConvertUtil.getPrivateMinioUrl(privateDocuments);
    }

    return ConvertUtil.getMinioUrl(documentUrl);
  }, [privateDocuments, documentUrl]);

  const getPrivateDocumentPath = useCallback(async () => {
    if (documentType === 'documents' || documentType === 'age') {
      const response = await DownloadService.download(documentUrl);

      setPrivateDocuments(response.data);
    }
  }, [documentUrl, documentType]);

  const showImageContent = () => {
    let content;

    if (fileType === 'IMAGE') {
      content = (
        <img
          alt=''
          src={`${ConvertUtil.getPrivateMinioUrl(privateDocuments)}`}
          style={{ maxWidth: '500px' }}
        />
      );
    } else if (fileType === 'PDF') {
      content = (
        <div style={{ height: 700, overflow: 'scroll' }}>
          <PDFReader
            url={ConvertUtil.getPrivateMinioUrl(privateDocuments)}
            scale={1}
            showAllPage
          />
        </div>
      );
    }

    return content;
  };

  const photoContainer = () => {
    let content;

    if (documentType === 'photo' && documentUrl.length) {
      content = (
        <S.PhotoBox>
          <div className='profile-image-wrapper'>
            <div
              className='profile-image-img'
              style={{
                backgroundImage: `url("${ConvertUtil.getMinioUrl(
                  documentUrl
                )}")`,
              }}
            />
          </div>
        </S.PhotoBox>
      );
    } else if (privateDocuments.length) {
      content = (
        <div style={{ maxWidth: '100%', maxHeight: '100%' }}>
          {showImageContent()}
        </div>
      );
    } else {
      content = <p>No document uploaded.</p>;
    }

    return content;
  };

  const prefillFeedback = (reason: string) => {
    const message =
      REJECT_REASONS_MESSAGES.find((r) => r.reason === reason)?.message || '';

    setFeedback(message);

    if (feedbackRef.current) {
      feedbackRef.current.value = message;
    }
  };

  useEffect(() => {
    if (isOpen) {
      setPrivateDocuments('');
      setLoading(true);
      setShowFeedback(false);

      getPrivateDocumentPath();

      if (documentUrl.trim() !== '') {
        fetch(`${ConvertUtil.getPrivateMinioUrl(documentUrl)}`)
          .then((res) => res.blob())
          .then((blob) => {
            if (blob.type === 'application/pdf') {
              setFileType('PDF');
            } else {
              setFileType('IMAGE');
            }
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    } else {
      setFileType('');
      setShowFeedback(false);
    }
  }, [isOpen, documentUrl]);

  return (
    <LightBox isOpen={isOpen} onClose={onClose}>
      <S.Wrapper style={{ maxHeight: '100vh', overflowY: 'auto' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {loading ? (
            <LoadingOutlined />
          ) : (
            <>
              <h3 style={{ textAlign: 'center' }}>{title}</h3>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  flexBasis: '100%',
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {photoContainer()}
                {!!currentDocument?.length && (
                  <>
                    <a
                      href={currentDocument}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ alignItems: 'center' }}
                    >
                      <p>
                        <DownloadOutlined /> View original file
                      </p>
                    </a>
                    <div>
                      <h4>
                        Name: {user?.firstName} {user?.middleName}{' '}
                        {user?.lastName} {user?.suffix}
                      </h4>
                      <h4>
                        Birth Date:{' '}
                        {moment(user?.birthDate).format('MM/DD/YYYY')}
                      </h4>
                      <h4>Gender: {user?.gender}</h4>
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  flex: 1,
                  justifyContent: 'center',
                }}
              >
                {!showFeedback ? (
                  <>
                    <Button onClick={onConfirm} style={{ margin: '10px 5px' }}>
                      {labelConfirm}
                    </Button>
                    <span> </span>
                    <Button
                      onClick={() => setShowFeedback(true)}
                      style={{ margin: '10px 5px' }}
                    >
                      {labelCancel}
                    </Button>
                  </>
                ) : (
                  <>
                    <div className='form-group'>
                      <select
                        defaultValue=''
                        onChange={(selected) =>
                          prefillFeedback(selected.currentTarget.value)
                        }
                      >
                        {documentType === 'photo' && (
                          <>
                            <option value='' disabled>
                              Custom reasons
                            </option>
                            <option value='dark'>Too Dark</option>
                            <option value='pixelated'>Pixelated</option>
                            <option value='nonHeadshot'>Non headshot</option>
                            <option value='obstructed'>Obstructed</option>
                            <option value='solo'>Not Solo</option>
                            <option value=''>Other</option>
                          </>
                        )}
                        {documentType === 'age' && (
                          <>
                            <option value='' disabled>
                              Custom reasons
                            </option>
                            <option value='wrongDocument'>
                              Wrong document
                            </option>
                            <option value='pixelated'>Pixelated</option>
                            <option value='wrongName'>Wrong name</option>
                            <option value='wrongDate'>
                              Wrong date of birth
                            </option>
                            <option value='wrongVaultData'>
                              Wrong Vault Data
                            </option>
                            <option value='wrongUser'>Wrong user</option>
                            <option value=''>Other</option>
                          </>
                        )}
                        {documentType === 'documents' && (
                          <>
                            <option value='' disabled>
                              Custom reasons
                            </option>
                            <option value='expiringSoon'>Expiring soon</option>
                            <option value='expired'>Expired</option>
                            <option value='invalid'>Invalid</option>
                            <option value='nameMismatch'>Name Mismatch</option>
                            <option value=''>Other</option>
                          </>
                        )}
                      </select>
                    </div>
                    <div className='form-group'>
                      <textarea
                        defaultValue=''
                        ref={feedbackRef}
                        maxLength={300}
                        onChange={(e: any) => {
                          setFeedback(e.target.value);
                        }}
                        style={{ margin: '10px 5px', height: '150px' }}
                      />
                    </div>
                    <Button onClick={onCancel} style={{ margin: '10px 5px' }}>
                      SEND
                    </Button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </S.Wrapper>
    </LightBox>
  );
};

export default DocumentApprovalBox;
