import { Reducer } from 'redux';
import { MemberpoolActionTypes, MemberpoolState } from './types';

export const initialState: MemberpoolState = {
  data: [],
  loading: false,
};

const reducer: Reducer<MemberpoolState> = (state = initialState, action) => {
  switch (action.type) {
    case MemberpoolActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }

    case MemberpoolActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, errors: '', data: action.payload };
    }

    case MemberpoolActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }

    default: {
      return state;
    }
  }
};

export { reducer as memberpoolReducer };
