import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const TabsWrapper = styled.nav``;
export const TabsContainer = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
`;
export const TabItem = styled.li`
  transition: 0.3s;
  color: ${COLORS.grey300};
  border-bottom: 2px solid transparent;

  a {
    padding: 1rem 0;
    display: block;
    height: 100%;
  }

  &.active {
    pointer-events: none;
  }

  &.active,
  &:hover {
    color: ${COLORS.brandPrimary};
    border-color: ${COLORS.brandPrimary};
  }

  & + li {
    margin-left: 1.5rem;
  }
`;
