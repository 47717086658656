import * as React from 'react';
import { CSSProperties } from 'react';
import play from '../../../assets/imgs/icons/ico_play.svg';
import DateUtil from '../../../util/DateUtil';
import './card-box.scss';

interface CardBoxProps {
  featureImage?: string;
  title?: string;
  text?: string;
  date?: string;
  link?: string;
  style?: CSSProperties;
  video?: boolean;
}

const CardBox: React.FunctionComponent<CardBoxProps> = (props) => {
  const { featureImage, title, text, date, link, style, video } = props;
  return (
    <a href={link} target='_blank' rel='noopener noreferrer'>
      {' '}
      <div className='card-box'>
        {featureImage !== undefined && (
          <div className='feature-image'>
            {video && (
              <div
                className='video-icon'
                style={{ backgroundImage: `url(${play})` }}
              />
            )}
            <img alt='' src={featureImage} />
          </div>
        )}
        {title && (
          <div className='content-card' style={style}>
            <h4>{title}</h4>
            <p>{text}</p>
            {date && (
              <div className='date'>{DateUtil.formatDate(new Date(date))}</div>
            )}
          </div>
        )}
      </div>
    </a>
  );
};

export default CardBox;
