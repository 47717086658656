import { ClubView } from 'admin/models/club/Club';

type UseFilterClubsProps = {
  clubs?: ClubView[];
  clubName: string;
  selectedCity: string;
  selectedMembersCount?: number;
};

export const useFilterClubs = ({
  clubs,
  clubName,
  selectedCity,
  selectedMembersCount,
}: UseFilterClubsProps) => {
  return clubs
    ?.filter(
      ({ name, directorInfo }) =>
        name.toLowerCase().includes(clubName.toLowerCase()) ||
        directorInfo?.name.toLowerCase().includes(clubName.toLowerCase())
    )
    ?.filter(({ city }) =>
      selectedCity ? city.toLowerCase() === selectedCity.toLowerCase() : true
    )
    .filter(({ totalMembers: total }) =>
      selectedMembersCount ? total === selectedMembersCount : true
    );
};
