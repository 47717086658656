import styled from 'styled-components';

import { color, font } from '../../styles/styleVariables';

export const SideMenuWrapper = styled.div<{ countSubItens?: number }>`
  width: 72px;
  height: 100%;
  background-color: ${color.greyDark};
  position: fixed;
  padding-top: 72px;
  color: #fff;
  z-index: 30;
  left: -73px;
  opacity: 0;
  transition: .3s left;
  &.open {
    left: 0;
    opacity: 1;
  }
  @media (max-width: 768px) {
    display: none;
  }
  .item {
    height: 50%;
    display: flex;
    &.link {
      .wrapper {
        cursor: pointer;
      }
    }
    &.link:hover {
      .wrapper {
        background: ${color.orange};
        & + .sub-menu {
          margin-left: 0;
        }
      }
    }
    .wrapper {
      background: ${color.greyDark};
      display: flex;
      align-items: center;
      position: relative;
      border-right: solid 1px #53575f;
      z-index: 100;
      transition: all 0.3s;
      &.active {
        background-color: ${color.silver};
      }

      .text {
        width: 72px;
        font-size: 11px;
        font-family: ${font.druk};
        padding-top: 0px;
        text-align: center;
        text-transform: uppercase;
        transform: rotate(270deg);
      }
    }
    .avatar {
      .avatar-img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background: #fff;
        overflow: hidden;
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        background-size: cover;
        img {
          width: 100%;
        }
      }
      .notification-circle {
        position: absolute;
        top: 40px;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        font-size: 8px;
        text-align: center;
        background-color: #fff;
        top: 40px;
        left: 25px;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        color: #000;
        font-weight: 700;
      }
    }
    .sub-menu {
      height: 100%;
      display: flex;
      margin-left: ${(props) =>
    props.countSubItens
      ? `${(props.countSubItens + 1) * -100}px`
      : '-1000px'};
      transition: ${(props) => (props.countSubItens ? 'all .5s' : 'none')};
      cursor: pointer;
      &:hover {
        margin-left: 0;
      }
      .sub-item {
        width: 72px;
        height: 100%;
        background-color: ${color.greyDark};
        border-right: solid 1px ${color.silver};
        align-items: center;
        position: relative;
        z-index: 10;

        overflow: hidden;
        &:hover {
          background-color: ${color.greyLight};
        }
        &.new-user {
          background-color: ${color.silver};
        }
        .text-sub {
          font-size: 11px;
          font-family: ${font.druk};
          padding-top: 0px;
          text-align: center;
          text-transform: uppercase;
          transform: rotate(270deg);
          position: absolute;
          top: 49%;
          left: -117px;
          width: 300px;
          z-index: 10;
          transition: all 0.5s;
          .role,
          .name {
            position: absolute;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: 30px;
            transition: all 0.3s;
          }
          .name {
            opacity: 1;
            top: -5px;
          }
          .role {
            opacity: 0;
            bottom: -60px;
          }
        }
        &:hover {
          .text-sub {
            .name {
              opacity: 0;
              top: -60px;
            }
            .role {
              opacity: 1;
              bottom: -12px;
            }
          }
        }
      }
    }
  }
`;
