import UserType from 'models/User/UserTypeModel';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export type RoleAndPath = {
  role: string | UserType.CLUB_DIRECTOR | UserType.TEAM_MANAGER,
  path: string,
};

export const useGetClubApplyRoleAndPath = () => {
  const location = useLocation();

  const userRoleAndPath: RoleAndPath = useMemo(() => {
    let dataObject: RoleAndPath = {
      role: UserType.CLUB_DIRECTOR,
      path: 'checkout-as-club-director',
    };

    if (
      location.pathname?.includes('checkout-as-club-director-or-team-manager')
    ) {
      dataObject = {
        role: `${UserType.CLUB_DIRECTOR},${UserType.TEAM_MANAGER}`,
        path: 'checkout-as-club-director-or-team-manager',
      };
    }

    if (location.pathname?.includes('checkout-as-team-manager')) {
      dataObject = {
        role: UserType.TEAM_MANAGER,
        path: 'checkout-as-team-manager',
      };
    }

    return dataObject;
  }, [location.pathname]);

  return userRoleAndPath;
};
