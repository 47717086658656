import { useQuery } from '@tanstack/react-query';
import UserService from 'services/v3/User/UserService';

export function useGetAdvancedFilters() {
  const { data } = useQuery(
    ['/get-invite-user-filters'],
    async () => await UserService.fetchAdvancedFilters()
  );

  return {
    advancedFilters: data?.data ?? null,
  };
}
