import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ApplicationState } from '../../redux/store';
import { UserType } from '../../redux/user/types';
import { color, gradient } from '../../styles/styleVariables';

interface IStepsProps {
  activeStep?: number;
}

const Steps: React.FunctionComponent<IStepsProps> = (props) => {
  const { newUser } = useSelector(
    (state: ApplicationState) => state.newUser
  ).data;

  const steps = {
    player: [
      'Fill out personal information',
      'Document authentication',
      'Membership selection',
      'Sign Waivers',
    ],
    coach_free: [
      'Fill out personal information',
      'Document authentication',
      'Membership selection',
      'Sign Waivers',
    ],
    coach: [
      'Fill out personal information',
      'Document authentication',
      'Membership selection',
      'Sign Waivers',
      'Additional Personal Info',
      'Upload Safesport Certificate',
    ],
    referee_free: [
      'Fill out personal information',
      'Document authentication',
      'Membership selection',
      'Sign Waivers',
    ],
    referee: [
      'Fill out personal information',
      'Document authentication',
      'Membership selection',
      'Sign Waivers',
      'Additional Personal Info',
      'Upload Safesport Certificate',
    ],
    club_director: [
      'Fill out personal information',
      'Document authentication',
      'Sign Waivers',
      'Additional Personal Info',
      'Upload Safesport Certificate',
    ],
  };
  return (
    <StepsWrapper>
      {newUser?.type === UserType.PLAYER &&
        steps.player.map((text, index) => (
          <Step
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}_${text}`}
            className={
              props.activeStep && props.activeStep > index + 1 ? 'done' : ''
            }
          >
            <div
              className={`dot
          ${props.activeStep && props.activeStep > index + 1 ? 'done' : ''} ${
                props.activeStep === index + 1 ? 'active' : ''
              }`}
            >
              <Description>
                <div className='title'>STEP {index + 1}</div>
                {text}
                <div className='arrow' />
              </Description>
            </div>
          </Step>
        ))}

      {newUser?.type === UserType.COACH &&
        newUser?.membershipPrice === 0 &&
        steps.coach_free.map((text, index) => (
          <Step
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}_${text}`}
            className={
              props.activeStep && props.activeStep > index + 1 ? 'done' : ''
            }
          >
            <div
              className={`dot
          ${props.activeStep && props.activeStep > index + 1 ? 'done' : ''} ${
                props.activeStep === index + 1 ? 'active' : ''
              }`}
            >
              <Description>
                <div className='title'>STEP {index + 1}</div>
                {text}
                <div className='arrow' />
              </Description>
            </div>
          </Step>
        ))}

      {newUser?.type === UserType.COACH &&
        newUser?.membershipPrice !== 0 &&
        steps.coach.map((text, index) => (
          <Step
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}_${text}`}
            className={
              props.activeStep && props.activeStep > index + 1 ? 'done' : ''
            }
          >
            <div
              className={`dot
          ${props.activeStep && props.activeStep > index + 1 ? 'done' : ''} ${
                props.activeStep === index + 1 ? 'active' : ''
              }`}
            >
              <Description>
                <div className='title'>STEP {index + 1}</div>
                {text}
                <div className='arrow' />
              </Description>
            </div>
          </Step>
        ))}

      {newUser?.type === UserType.REFEREE &&
        newUser?.membershipPrice === 0 &&
        steps.coach_free.map((text, index) => (
          <Step
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}_${text}`}
            className={
              props.activeStep && props.activeStep > index + 1 ? 'done' : ''
            }
          >
            <div
              className={`dot
          ${props.activeStep && props.activeStep > index + 1 ? 'done' : ''} ${
                props.activeStep === index + 1 ? 'active' : ''
              }`}
            >
              <Description>
                <div className='title'>STEP {index + 1}</div>
                {text}
                <div className='arrow' />
              </Description>
            </div>
          </Step>
        ))}

      {newUser?.type === UserType.REFEREE &&
        newUser?.membershipPrice !== 0 &&
        steps.coach.map((text, index) => (
          <Step
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}_${text}`}
            className={
              props.activeStep && props.activeStep > index + 1 ? 'done' : ''
            }
          >
            <div
              className={`dot
          ${props.activeStep && props.activeStep > index + 1 ? 'done' : ''} ${
                props.activeStep === index + 1 ? 'active' : ''
              }`}
            >
              <Description>
                <div className='title'>STEP {index + 1}</div>
                {text}
                <div className='arrow' />
              </Description>
            </div>
          </Step>
        ))}

      {newUser?.type === UserType.CLUB_DIRECTOR &&
        steps.club_director.map((text, index) => (
          <Step
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}_${text}`}
            className={
              props.activeStep && props.activeStep > index + 1 ? 'done' : ''
            }
          >
            <div
              className={`dot
          ${props.activeStep && props.activeStep > index + 1 ? 'done' : ''} ${
                props.activeStep === index + 1 ? 'active' : ''
              }`}
            >
              <Description>
                <div className='title'>STEP {index + 1}</div>
                {text}
                <div className='arrow' />
              </Description>
            </div>
          </Step>
        ))}
    </StepsWrapper>
  );
};

const Description = styled.div`
  width: 200px;
  background-color: ${color.greyDark};
  font-size: 12px;
  position: absolute;
  z-index: 20;
  bottom: 60px;
  left: -76px;
  text-align: center;
  padding: 15px;
  border-radius: 5px;
  border: solid 1px ${color.silver};
  display: none;
  @media (max-width: 768px) {
    bottom: 40px;
    left: -86px;
  }
  &.active {
    display: block;
  }
  .title {
    font-weight: 700;
    color: ${color.silverLight};
  }

  .arrow {
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 11px solid #80838a;
    position: absolute;
    bottom: -11px;
    left: 85px;
  }

  .arrow:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #24272c;
    position: absolute;
    top: -11px;
    left: -10px;
  }
`;

const Step = styled.div`
  width: 100%;
  position: relative;
  &::before {
    content: '';
    width: calc(100%);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 20px;
    border-bottom: solid 1px ${color.silverLight};
    @media (max-width: 768px) {
      top: 10px;
    }
  }
  &.done {
    &::before {
      border-bottom: solid 1px ${color.orange};
    }
  }
  &:first-child {
    @media (max-width: 768px) {
      ${Description} {
        left: -20px;
        .arrow {
          left: 15px;
        }
      }
    }
  }
  &:last-child {
    width: 40px;
    &::before {
      display: none;
    }
    @media (max-width: 768px) {
      ${Description} {
        left: inherit;
        right: -15px;
        .arrow {
          left: inherit;
          right: 15px;
        }
      }
    }
  }

  .dot {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: solid 1px ${color.silverLight};
    padding: 9px;
    background-color: ${color.greyDark};
    position: relative;
    z-index: 10;
    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
      padding: 3px;
    }
    &::after {
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background: ${gradient.orange};
      position: relative;
      z-index: 10;
      display: none;
      @media (max-width: 768px) {
        width: 12px;
        height: 12px;
      }
    }

    &.done {
      border: solid 1px ${color.orange};
    }
    &.active {
      ${Description} {
        display: block;
      }
      &::after {
        display: block;
      }
    }

    &.done {
      &::after {
        display: block;
        background: ${gradient.orange};
      }
    }
  }
`;

const StepsWrapper = styled.div`
  display: flex;
  padding: 30px 0px;
  justify-content: space-between;
  position: relative;
  margin-top: 100px;
  &::after {
    z-index: 0;
    content: '';
    width: calc(100% - 60px);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 50px;
  }
`;

export default Steps;
