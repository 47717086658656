import { User } from 'models/User/UserModel';
import { action } from 'typesafe-actions';
import { BaseUser } from 'redux/v3/baseUsers/types';
import { CurrentUserActionTypes } from './types';

export const setCurrentUser = (user: User | BaseUser) => {
  return action(CurrentUserActionTypes.SET_CURRENT_USER, user);
};

export const resetCurrentUser = () => {
  return action(CurrentUserActionTypes.RESET_CURRENT_USER);
};
