import { action } from 'typesafe-actions';
import { Roster } from '../../models/Roster/RosterModel';
import { RosterActionTypes } from './types';

export const rosterFetchRequest = (rosterId: string) =>
  action(RosterActionTypes.FETCH_REQUEST, rosterId);

export const rosterFetchSuccess = (data: Roster) =>
  action(RosterActionTypes.FETCH_SUCCESS, data);

export const rosterFetchError = (message: string) =>
  action(RosterActionTypes.FETCH_ERROR, message);

export const rosterFetchAddPlayer = (rosterId: string, playerId: string) =>
  action(RosterActionTypes.ADD_PLAYER, {
    rosterId,
    playerId,
  });

export const rosterFetchRemovePlayer = (rosterId: string, playerId: string) =>
  action(RosterActionTypes.REMOVE_PLAYER, {
    rosterId,
    playerId,
  });

export const rosterAddPlayer = (playerId: string) =>
  action(RosterActionTypes.ADD_PLAYER_SUCCESS, playerId);

export const rosterUpdate = (data: Roster) =>
  action(RosterActionTypes.UPDATE, data);

export const rosterClearErrors = () => action(RosterActionTypes.CLEAR_ERRORS);

// export const accountFetchAccounts = () =>
//   action(AccountActionTypes.FETCH_ACCOUNTS);

// export const accountSetAccounts = (data: AccountBrief[]) =>
//   action(AccountActionTypes.SET_ACCOUNTS, data);
