import React from 'react';
import moment from 'moment';
import { BodySBold } from 'styles/v3/variables';
import { useHistory, useLocation } from 'react-router-dom';
import {
  HeaderCourts,
  StyledDays,
  StyledDay,
  StyledDaysContainer,
} from './styles';
import { DaysSwitcherProps } from './types';

function DaysSwitcher({
  days,
  currentDay,
  visibleCourts,
  venueTimezone,
  removeAll,
}: DaysSwitcherProps) {
  const history = useHistory();
  const location = useLocation();

  const handleClick = (day: string) => {
    const path = `${location.pathname}${location.search}`;
    const regex = /([?&]day=)([^&]*)/;

    const updatedUrl = path.replace(
      regex,
      `$1${moment(day).format('YYYY-MM-DD')}`
    );

    history.replace(updatedUrl);

    removeAll();
  };

  return (
    <StyledDaysContainer>
      <div>
        Your time will be configured in the {venueTimezone?.locations} -{' '}
        {venueTimezone?.value} timezone
      </div>
      <StyledDays style={{ width: '100%' }}>
        <div>
          {days.map((day) => (
            <StyledDay
              key={day}
              active={
                moment(currentDay).format('MMMM D') ===
                moment(day).format('MMMM D')
              }
              onClick={() => handleClick(day)}
            >
              {moment(day).format('MMMM D')}
            </StyledDay>
          ))}
        </div>
        <HeaderCourts columns={visibleCourts.length}>
          {visibleCourts.map((court) => {
            return (
              <BodySBold className='item' key={court.id}>
                {court.name}
              </BodySBold>
            );
          })}
        </HeaderCourts>
      </StyledDays>
    </StyledDaysContainer>
  );
}

export default DaysSwitcher;
