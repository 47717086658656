import React, { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import {
  useGetRostersByClubIdAndUser,
} from 'hooks/v3/clubs/useGetRostersByClubId/useGetRostersByClubId';
import { Pagination } from 'antd';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import { MultiValue, SingleValue } from 'react-select';
import { BodyL } from 'styles/v3/variables';
import { RosterDivision } from './components';
import * as S from './styles';

interface IProps {
  onlyMyResults: boolean;
}

const perPageOptions: OptionsType[] = [
  {
    value: 10,
    label: '10/page',
  },
  {
    value: 20,
    label: '20/page',
  },
  {
    value: 50,
    label: '50/page',
  },
  {
    value: 100,
    label: '100/page',
  },
];

const MyRosters: FC<IProps> = ({ onlyMyResults }) => {
  const params: { clubId: string } = useParams();
  const { data: currentClub } = useGetClubById(params.clubId);
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState(perPageOptions[0]);

  const currentUserId = useSelector(
    (state: ApplicationState) => state.currentUser.data.id
  );

  const { data: rostersClubMyRosters } = useGetRostersByClubIdAndUser({
    clubId: params.clubId,
    page,
    size: perPage.value as number,
  });

  const {
    clubDirector: {
      memberPool: { selectedUserIds },
    },
  } = useSelector((state: ApplicationState) => state.clubDashboard);

  const rosterClubData = useMemo(() => {
    if (!rostersClubMyRosters?.content.length || !currentClub) return [];

    return rostersClubMyRosters.content.map((roster) => {
      return {
        id: roster.id as string,
        eventName: roster.event?.name ?? '',
        eventLogo: roster.event?.logo ?? '',
        gender: roster.ageDivision?.gender ?? '',
        year: ((roster.ageDivision?.years[0] as unknown) as string) ?? '',
        isCoach:
          roster.coaches?.some((coach) => coach.id === currentUserId) ?? false,
        isPlayer:
          roster.players?.some((player) => player.id === currentUserId) ??
          false,
        startDate: dateTime2DateStr(roster.event?.startDate) ?? '',
        endDate: dateTime2DateStr(roster.event?.endDate) ?? '',
        players: roster.players ?? [],
        coaches: roster.coaches ?? [],
      };
    });
  }, [selectedUserIds, currentClub, rostersClubMyRosters]);


  const handleChangeOption = (
    option: MultiValue<OptionsType> | SingleValue<OptionsType>
  ) => {
    setPage(0);
    setPerPage(option as OptionsType);
  };

  return (
    <>
      <>
        {rosterClubData && rosterClubData?.length > 0 ? (
          <>
            {rosterClubData.map((roster) => (
              <RosterDivision
                key={`key_roster_${roster.id}`}
                id={roster.id}
                eventLogo={roster.eventLogo}
                eventName={roster.eventName}
                gender={roster.gender}
                year={roster.year}
                isCoach={roster.isCoach}
                isPlayer={roster.isPlayer}
                startDate={roster.startDate}
                endDate={roster.endDate}
                players={roster.players}
                coaches={roster.coaches}
              />
            ))}
            <S.PaginationWrapper>
              <Pagination
                current={page + 1}
                pageSize={rostersClubMyRosters?.pageSize ?? 30}
                total={rostersClubMyRosters?.total ?? 10}
                onChange={(page) => setPage(page - 1)}
              />
              <Select
                options={perPageOptions}
                defaultValue={perPageOptions[0]}
                value={perPage}
                onChange={handleChangeOption}
              />
            </S.PaginationWrapper>
          </>
        ) : (
          <S.WrapperNoItem>
            <BodyL>There are no rosters to view</BodyL>
          </S.WrapperNoItem>
        )}
      </>
    </>
  );
};
const dateTime2DateStr = (date: Date | string | undefined) => {
  if (!date) return '';
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  const [month, day, year] = formattedDate.split(' ');
  const formattedMonth = month.toUpperCase();
  return `${formattedMonth} ${day} ${year}`;
};
export default MyRosters;
