import Axios, { AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/react';
import TokenUtil from '../../util/TokenUtil';
import ServiceResponse from './ServiceResponse';

class APIServices<T> {
  static async request<T>(
    axiosConfig: AxiosRequestConfig
  ): Promise<ServiceResponse<T>> {
    return Axios.request(axiosConfig)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          return ServiceResponse.success(response.data, '');
        }

        return ServiceResponse.error(response.data, '');
      })
      .catch((error) => {
        // If unautorized try refresh token and request again
        if (error.response?.status === 401) {
          return TokenUtil.refreshToken().then(() => {
            // Replace previous axiosConfig with new token
            const newAxiosConfig = {
              ...axiosConfig,
              headers: {
                ...axiosConfig.headers,
                Authorization: `Bearer ${localStorage.getItem('kc_token')}`,
              },
            };

            // Resend request
            return Axios.request(newAxiosConfig).then((response) => {
              if (response.status >= 200 && response.status < 400) {
                return ServiceResponse.success(response.data, '');
              }

              return ServiceResponse.error(response.data, '');
            });
          });
        }
        Sentry.captureException(error, error.response?.data?.message);

        return ServiceResponse.error(
          error,
          error.response?.data?.message,
          error.response?.status
        );
      });
  }
}

export default APIServices;
