import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 16px 0 16px 0;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${toRem(8)};
  cursor: pointer;

  .i-icon svg {
    width: ${toRem(24)};
    height: ${toRem(24)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.grey400};
    }
  }
`;

export const SelectWrapper = styled.div`
  width: fit-content;
  min-width: ${toRem(140)};
`;

export const OrderByWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${toRem(24)};
`;

export const ContainerForFiltersDetailed = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(16)};
  width: 100%;
`;

export const GoBackAndAllSelecteds = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};
  margin-bottom: ${toRem(28)};
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(33)};
`;
