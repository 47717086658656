import styled from 'styled-components'

export const InputWrapper = styled.div`
  position: relative;

  input {
    color: transparent;
  }

  p {
    text-transform: uppercase;
    position: absolute;
    bottom: 5px;
    left: 50px;
  }
`
export const SelectedColor = styled.span<{ $color: string }>`
  position: absolute;
  display: block;
  bottom: 5px;
  left: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.$color};
`
