import React, { FC, useEffect, useMemo } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import Keycloak from 'keycloak-js';

import Loading from 'components/v3/Loading/Loading';

interface IV3LoginProps {
  auth?: Keycloak.KeycloakInstance | undefined;
  routeFrom?: string;
}

interface ILocationState {
  from: Location;
}

const V3Login: FC<IV3LoginProps> = ({ auth }) => {
  const location = useLocation<ILocationState>();
  const history = useHistory();

  useEffect(() => {
    if (auth?.authenticated && auth.isTokenExpired()) {
      auth
        .updateToken(5)
        .then(() => {
          localStorage.setItem('kc_token', auth.token || '');
          localStorage.setItem('kc_refreshToken', auth.refreshToken || '');
        })
        .catch(() => {
          localStorage.setItem('kc_token', '');
          localStorage.setItem('kc_refreshToken', '');

          history.replace('/v3/logout');
        });
    }

    if (!auth?.authenticated) {
      auth?.login();
    }
  }, [auth, history]);

  const redirectionURL = useMemo(() => {
    const locationFrom = location.state?.from;

    if (location.state) {
      return `${locationFrom.pathname}${locationFrom.search}`;
    }

    return '/v3/home-page';
  }, [location.state?.from]);

  if (auth?.authenticated) {
    return <Redirect to={redirectionURL} />;
  }

  return <Loading />;
};

export default V3Login;
