import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export type ContainerStyleProps = {
    $bgColor: string
    $textColor: string
}

export const Container = styled.div<ContainerStyleProps>`
  display: flex;
  flex-direction: row;
  padding: ${toRem(6)} ${toRem(8)};
  justify-content: space-between;
  align-items: center;
  column-gap: ${toRem(8)};
  border-radius: ${toRem(24)};
  background-color: ${(props) => props.$bgColor};
  color: ${(props) => props.$textColor};

  .i-icon svg {
    width: ${toRem(20)};
    height: ${toRem(20)};
    display: flex;
    &::before {
      display: none;
    }

    path {
      stroke: ${(props) => props.$textColor};
    }
  }
`;
