import React, { memo, FC, useMemo } from 'react';

import { BodyLBold } from 'styles/v3/variables';

import { getRequirementList } from './utils';

import * as S from './styles';

interface IRequiredPointsModalProps {
  visible: boolean;
  minPlayers: number;
  maxPlayers: number;
  minCoaches: number;
  maxCoaches: number;
  onClose: () => void;
}

export const RequiredPointsModal: FC<IRequiredPointsModalProps> = memo(
  ({ visible, minPlayers, maxPlayers, minCoaches, maxCoaches, onClose }) => {
    const requirementList = useMemo(() => {
      return getRequirementList({
        minPlayers,
        maxPlayers,
        minCoaches,
        maxCoaches,
      });
    }, [minPlayers, maxPlayers, minCoaches, maxCoaches]);

    return (
      <S.StyledModal
        centered
        visible={visible}
        footer={false}
        closable={false}
        onCancel={onClose}
      >
        <S.Container>
          <BodyLBold>
            Please make sure that your roster meets the following criteria
            before submitting
          </BodyLBold>
          <S.List>
            {requirementList.map((item) => (
              <S.ListItem>{item}</S.ListItem>
            ))}
          </S.List>
          <S.ConfirmButton color='primary' isUpper onClick={onClose}>
            Understand
          </S.ConfirmButton>
        </S.Container>
      </S.StyledModal>
    );
  }
);

RequiredPointsModal.displayName = 'RequiredPointsModal';
