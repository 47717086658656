import { TimePicker } from 'antd';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import ico_swap from '../../../../../../assets/imgs/icons/ico_swap.svg';
import ConfirmBox from '../../../../../../components/base/ConfirmBox/ConfirmBox';
import LoadingIcon from '../../../../../../components/base/LoadingIcon/LoadingIcon';
import { SocketContext } from '../../../../../../context/SocketContext';
import { DSEmitEvent } from '../../../../../../models/DSConnection/DSConnection';
import {
  GameAction,
  GameEventModel,
  GamePeriod,
} from '../../../../../../models/GameEvent/GameEventModel';
import {
  matchDetailsAddGameEventRequest,
  matchDetailsResendGameEventRequest,
  matchDetailsResendRemoveGameEventRequest,
  matchDetailsUpdateGamePreferences,
} from '../../../../../../redux/matchDetails/actions';
import { ApplicationState } from '../../../../../../redux/store';
import { color, font } from '../../../../../../styles/styleVariables';
import ConvertUtil from '../../../../../../util/ConvertUtil';
import GameReportModalEdit from '../../../GameReportModalEdit';

interface IGameEventButtonsProps {}

interface selectedEvent {
  teamId: string;
  action: GameAction | undefined;
  period: GamePeriod | undefined;
  userId: string;
  time: number;
  submited: boolean;
}

const GameEventButtons: React.FunctionComponent<IGameEventButtonsProps> = (
  props
) => {
  const { matchId } = useParams<{ matchId: string }>();

  const matchDetails = useSelector(
    (state: ApplicationState) => state.matchDetails
  );

  const dispatch = useDispatch();

  // Socket events
  const { socket } = useContext(SocketContext);
  const sendEventSocket = (data: any) => {
    socket?.emit('scoreUpdate', data);
  };

  const [gameReport, setGameReport] = useState<{
    modalOpened: boolean;
    match?: string;
  }>({
    modalOpened: false,
  });

  useEffect(() => {
    sendEventSocket({
      teamAScore: matchDetails.data.match?.result?.homeTeamScore,
      teamBScore: matchDetails.data.match?.result?.awayTeamScore,
      teamAFouls: foulsPerPeriodCalc().teamAFouls,
      teamBFouls: foulsPerPeriodCalc().teamBFouls,
      period: matchDetails.preferences.timing.period,
      pkEvents: getPkEvents(),
    });
  }, [matchDetails.data.match?.result, matchDetails.preferences]);

  const [secondYellow, setSecondYellow] = useState({ state: false, user: '' });
  const [eventState, setEventState] = useState<selectedEvent>({
    teamId: '',
    action: undefined,
    userId: '',
    time: -1,
    period: GamePeriod.FIRST_HALF,
    submited: false,
  });
  const [editTime, setEditTime] = useState<{
    edited: boolean;
    time: string;
  }>({
    edited: false,
    time: '',
  });

  const secToTime = (time: number) => {
    const hours = ~~(time / 3600);
    const minutes = ~~((time % 3600) / 60);
    const seconds = ~~time % 60;

    return {
      hours: hours.toString().padStart(2, '0'),
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0'),
    };
  };

  const resetSelectedEvent = () => {
    setEventState({
      ...eventState,
      teamId: '',
      action: undefined,
      userId: '',
      time: -1,
      submited: false,
    });
    setEditTime({ ...editTime, edited: false });
  };

  const markEvent = (event: GameAction, teamId: string) => {
    setEventState({
      ...eventState,
      teamId,
      action: event,
      period: matchDetails.preferences.timing.period,
      time:
        matchDetails.preferences.timing!.timerInSeconds +
        matchDetails.preferences.timing!.timerInSecondsTotal,
    });
  };

  const reSubmitFailedEvents = () => {
    matchDetails.data.matchEventsFailed?.map((event) => {
      dispatch(matchDetailsResendGameEventRequest(event));
    });

    matchDetails.data.matchEventsRemoveFailed?.map((eventId) => {
      dispatch(matchDetailsResendRemoveGameEventRequest(eventId));
    });
  };
  const foulsPerPeriodCalc = () => {
    // Fouls in matchEvents
    const teamAFouls =
      (matchDetails.data.match?.matchEvents?.filter(
        (item) =>
          /* 
            This expression calculates the sum of FOULS in current period
            PLUS other periods if current period is not FIRST_HALF
            It's used because FOULS restart at second period, but fouls at
            second period will be carried for extra time 
          */
          item.rosterId === matchDetails.data.match?.homeTeam.id &&
          item.action === GameAction.FOUL &&
          (item.period === matchDetails.preferences.timing.period ||
            (matchDetails.preferences.timing.period !== GamePeriod.FIRST_HALF &&
              item.period !== GamePeriod.FIRST_HALF))
      ).length || 0) +
      // Fouls in matchEventsFailed
      (matchDetails.data.matchEventsFailed?.filter(
        (item) =>
          item.rosterId === matchDetails.data.match?.homeTeam.id &&
          item.action === GameAction.FOUL &&
          (item.period === matchDetails.preferences.timing.period ||
            (matchDetails.preferences.timing.period !== GamePeriod.FIRST_HALF &&
              item.period !== GamePeriod.FIRST_HALF))
      ).length || 0);

    const teamBFouls =
      (matchDetails.data.match?.matchEvents?.filter(
        (item) =>
          item.rosterId === matchDetails.data.match?.awayTeam.id &&
          item.action === GameAction.FOUL &&
          (item.period === matchDetails.preferences.timing.period ||
            (matchDetails.preferences.timing.period !== GamePeriod.FIRST_HALF &&
              item.period !== GamePeriod.FIRST_HALF))
      ).length || 0) +
      // Fouls in matchEventsFailed
      (matchDetails.data.matchEventsFailed?.filter(
        (item) =>
          item.rosterId === matchDetails.data.match?.awayTeam.id &&
          item.action === GameAction.FOUL &&
          (item.period === matchDetails.preferences.timing.period ||
            (matchDetails.preferences.timing.period !== GamePeriod.FIRST_HALF &&
              item.period !== GamePeriod.FIRST_HALF))
      ).length || 0);

    return {
      teamAFouls,
      teamBFouls,
    };
  };

  const getPkEvents = () => {
    return {
      homeTeam: getGameEvents().filter(
        (event) =>
          event.rosterId === matchDetails.data.match?.homeTeam.id &&
          (event.action === GameAction.PK_GOAL ||
            event.action === GameAction.PK_MISS)
      ),
      awayTeam: getGameEvents().filter(
        (event) =>
          event.rosterId === matchDetails.data.match?.awayTeam.id &&
          (event.action === GameAction.PK_GOAL ||
            event.action === GameAction.PK_MISS)
      ),
    };
  };

  const checkPlayerSuspension = (userId: string) => {
    // Verify if user has 2 YELLOW or 1 RED.
    return (
      getGameEvents().filter(
        (e) => e.userId === userId && e.action === GameAction.YELLOW_CARD
      ).length > 1 ||
      getGameEvents().filter(
        (e) => e.userId === userId && e.action === GameAction.RED_CARD
      ).length > 0
    );
  };

  const checkYellow = (userId: string) => {
    // Verify if user has 2 YELLOW or 1 RED.
    return (
      getGameEvents().filter(
        (e) => e.userId === userId && e.action === GameAction.YELLOW_CARD
      ).length > 0 &&
      getGameEvents().filter(
        (e) => e.userId === userId && e.action === GameAction.YELLOW_CARD
      ).length < 2
    );
  };

  const submitEvent = (userId: string, confirmationFlag = false) => {
    // Verify if user has YELLOW card
    if (
      !confirmationFlag && // if confirmationFlag is true, does not open modal and just submit event
      eventState.action === GameAction.YELLOW_CARD &&
      getGameEvents().filter(
        (e) => e.userId === userId && e.action === GameAction.YELLOW_CARD
      ).length > 0
    ) {
      setSecondYellow({ state: true, user: userId });
    } else {
      reSubmitFailedEvents();
      if (eventState.action) {
        const event: GameEventModel = {
          action: eventState.action,
          matchId,
          userId,
          rosterId: eventState.teamId,
          period: eventState.period,
          time: editTime.edited // if Time was edited get time from Timepicker
            ? editTime.time
            : `${secToTime(eventState.time).hours}:${
                secToTime(eventState.time).minutes
              }:${secToTime(eventState.time).seconds}`,
        };

        dispatch(matchDetailsAddGameEventRequest(event));
        resetSelectedEvent();
        if (eventState.action === GameAction.GOAL) {
          socket?.emit(DSEmitEvent.GOAL);
        }

        if (eventState.action === GameAction.FOUL) {
          socket?.emit(DSEmitEvent.FOUL, foulsPerPeriodCalc());
        }
      }
    }
  };

  // Return all game events including failed persisted ones
  const getGameEvents = () => {
    return [
      ...(matchDetails.data.match?.matchEvents || []),
      ...(matchDetails.data.matchEventsFailed || []),
    ];
  };
  const checkDisabledPK = () => {
    const pkEvents = getGameEvents().filter(
      (event) =>
        event.action === GameAction.PK_GOAL ||
        event.action === GameAction.PK_MISS
    );
    if (
      // If exist at least one PK event
      pkEvents.length > 0
    ) {
      // Check who did last Penalty Kick
      if (
        pkEvents[pkEvents.length - 1].rosterId ===
        matchDetails.data.match!.homeTeam.id
      ) {
        return { teamA: true, teamB: false };
      }
      return { teamA: false, teamB: true };
    }
    return { teamA: false, teamB: false };
  };

  return (
    <GameEventsWrapper>
      {!eventState.action && matchDetails.data.match && (
        <GameEvents
          className={matchDetails.preferences.swapped ? 'order-swapped' : ''}
        >
          <div className='team'>
            <div
              className='bt-swap'
              onClick={() =>
                dispatch(
                  matchDetailsUpdateGamePreferences({
                    ...matchDetails.preferences!,
                    swapped: !matchDetails.preferences?.swapped,
                  })
                )
              }
            />
            <div
              className='logo team-a'
              style={{
                boxShadow: `${
                  matchDetails.preferences?.colorTeamA || '#177ddc'
                }  0 0 0px 5px`,
              }}
            >
              <img
                src={ConvertUtil.getMinioUrl(
                  matchDetails.data.match.homeTeam.logo
                )}
              />
            </div>
            <div className='buttons-wrapper'>
              {matchDetails.preferences.timing.period ===
                GamePeriod.PENALTY_SHOOTOUT && (
                <>
                  <div
                    className={`bt ${
                      checkDisabledPK().teamA ? 'disabled' : ''
                    }`}
                    onClick={() =>
                      !checkDisabledPK().teamA
                        ? markEvent(
                            GameAction.PK_GOAL,
                            matchDetails.data.match!.homeTeam.id
                          )
                        : null
                    }
                  >
                    <span className='bt-span-icon left icon-goal' />
                    PK Goal
                  </div>
                  <div
                    className={`bt ${
                      checkDisabledPK().teamA ? 'disabled' : ''
                    }`}
                    onClick={() =>
                      !checkDisabledPK().teamA
                        ? markEvent(
                            GameAction.PK_MISS,
                            matchDetails.data.match!.homeTeam.id
                          )
                        : null
                    }
                  >
                    <span className='bt-span-icon left icon-goal' />
                    PK Miss
                  </div>
                </>
              )}
              {matchDetails.preferences.timing.period !==
                GamePeriod.PENALTY_SHOOTOUT && (
                <>
                  <div
                    className='bt'
                    onClick={() =>
                      markEvent(
                        GameAction.GOAL,
                        matchDetails.data.match!.homeTeam.id
                      )
                    }
                  >
                    <span className='bt-span-icon left icon-goal' />
                    Goal
                  </div>
                  <div
                    className='bt'
                    onClick={() =>
                      markEvent(
                        GameAction.FOUL,
                        matchDetails.data.match!.homeTeam.id
                      )
                    }
                  >
                    <span className='bt-span-icon left icon-foul' />
                    Foul
                  </div>
                </>
              )}

              <div
                className='bt'
                onClick={() =>
                  markEvent(
                    GameAction.YELLOW_CARD,
                    matchDetails.data.match!.homeTeam.id
                  )
                }
              >
                <span className='bt-span-icon left icon-yellow-card' />
                Yellow Card
              </div>
              <div
                className='bt'
                onClick={() => {
                  setGameReport({ ...gameReport, modalOpened: true });
                  markEvent(
                    GameAction.RED_CARD,
                    matchDetails.data.match!.homeTeam.id
                  );
                }}
              >
                <span className='bt-span-icon left icon-red-card ' />
                Red Card
              </div>
              {/* <div className='bt'>Timeout (?)</div> */}
              {matchDetails.preferences.timing.period !==
                GamePeriod.PENALTY_SHOOTOUT && (
                <div
                  className='bt'
                  onClick={() =>
                    markEvent(
                      GameAction.OWN_GOAL,
                      matchDetails.data.match!.homeTeam.id
                    )
                  }
                >
                  <span className='bt-span-icon left icon-goal' />
                  Own goal
                </div>
              )}
            </div>
          </div>
          <div className='team'>
            <div
              className='logo team-b'
              style={{
                boxShadow: `${
                  matchDetails.preferences?.colorTeamB || '#f94144ff'
                }  0 0 0px 5px`,
              }}
            >
              <img
                src={ConvertUtil.getMinioUrl(
                  matchDetails.data.match.awayTeam.logo
                )}
              />
            </div>
            <div className='buttons-wrapper'>
              {matchDetails.preferences.timing.period ===
                GamePeriod.PENALTY_SHOOTOUT && (
                <>
                  <div
                    className={`bt ${
                      checkDisabledPK().teamB ? 'disabled' : ''
                    }`}
                    onClick={() =>
                      !checkDisabledPK().teamB
                        ? markEvent(
                            GameAction.PK_GOAL,
                            matchDetails.data.match!.awayTeam.id
                          )
                        : null
                    }
                  >
                    <span className='bt-span-icon right icon-goal' />
                    PK Goal
                  </div>
                  <div
                    className={`bt ${
                      checkDisabledPK().teamB ? 'disabled' : ''
                    }`}
                    onClick={() =>
                      !checkDisabledPK().teamB
                        ? markEvent(
                            GameAction.PK_MISS,
                            matchDetails.data.match!.awayTeam.id
                          )
                        : null
                    }
                  >
                    <span className='bt-span-icon right icon-goal' />
                    PK Miss
                  </div>
                </>
              )}
              {matchDetails.preferences.timing.period !==
                GamePeriod.PENALTY_SHOOTOUT && (
                <>
                  <div
                    className='bt'
                    onClick={() =>
                      markEvent(
                        GameAction.GOAL,
                        matchDetails.data.match!.awayTeam.id
                      )
                    }
                  >
                    <span className='bt-span-icon right icon-goal' />
                    Goal
                  </div>
                  <div
                    className='bt'
                    onClick={() =>
                      markEvent(
                        GameAction.FOUL,
                        matchDetails.data.match!.awayTeam.id
                      )
                    }
                  >
                    <span className='bt-span-icon right icon-foul' />
                    Foul
                  </div>
                </>
              )}

              <div
                className='bt'
                onClick={() =>
                  markEvent(
                    GameAction.YELLOW_CARD,
                    matchDetails.data.match!.awayTeam.id
                  )
                }
              >
                <span className='bt-span-icon right icon-yellow-card' />
                Yellow Card
              </div>
              <div
                className='bt'
                onClick={() => {
                  setGameReport({ ...gameReport, modalOpened: true });
                  markEvent(
                    GameAction.RED_CARD,
                    matchDetails.data.match!.awayTeam.id
                  );
                }}
              >
                <span className='bt-span-icon right icon-red-card ' />
                Red Card
              </div>
              {/* <div className='bt'>Timeout (?)</div> */}
              {matchDetails.preferences.timing.period !==
                GamePeriod.PENALTY_SHOOTOUT && (
                <div
                  className='bt'
                  onClick={() =>
                    markEvent(
                      GameAction.OWN_GOAL,
                      matchDetails.data.match!.awayTeam.id
                    )
                  }
                >
                  <span className='bt-span-icon right icon-goal' />
                  Own goal
                </div>
              )}
            </div>
          </div>
        </GameEvents>
      )}
      {eventState.action && (
        <PlayerSelector>
          <div className='action'>
            <div className='event'>{eventState.action} </div>
            <div
              className='time'
              style={{ fontSize: '14px', marginLeft: '10px' }}
            >
              {!editTime.edited && (
                <>
                  <div>
                    Time: {secToTime(eventState.time).minutes}:
                    {secToTime(eventState.time).seconds}
                  </div>
                  <div
                    className='bt-edit'
                    onClick={() => setEditTime({ ...editTime, edited: true })}
                  >
                    Edit Time
                  </div>
                </>
              )}
              {editTime.edited && (
                <>
                  <div>
                    <TimePicker
                      format='mm:ss'
                      onChange={(time, timeString) => {
                        setEditTime({
                          ...editTime,
                          time: timeString?.toString() || '',
                        });
                      }}
                    />
                  </div>
                  <div
                    className='bt-edit'
                    onClick={() => {
                      setEditTime({ ...editTime, edited: false });
                    }}
                  >
                    Cancel
                  </div>
                </>
              )}
            </div>
          </div>
          <h5 className='players-grid-title'>Select the Player</h5>
          {!eventState.submited && ( // If gameEvent was not submitted yet
            <>
              <div className='players-grid'>
                {/* If teamId selected id is homeTeam, show HomeTeam list of numbers */}
                {matchDetails.data.match?.homeTeam.id === eventState.teamId &&
                  matchDetails.data.match?.homeTeam.players
                    .sort((a: any, b: any) => a.number - b.number)
                    .filter((player) => player.attend)
                    .map((player) => (
                      <div
                        onClick={() => {
                          if (!checkPlayerSuspension(player.id)) {
                            submitEvent(player.id);
                          }
                        }}
                        className={`item ${
                          eventState.userId === player.id ? 'selected' : ''
                        } ${
                          checkPlayerSuspension(player.id) ? 'suspended' : ''
                        } 
                        ${checkYellow(player.id) ? 'yellow' : ''}
                          `}
                      >
                        {player.number}
                      </div>
                    ))}
                {/* If teamId selected id is awayTeam, show AwayTeam list of numbers */}
                {matchDetails.data.match?.awayTeam.id === eventState.teamId &&
                  matchDetails.data.match?.awayTeam.players
                    .sort((a: any, b: any) => a.number - b.number)
                    .filter((player) => player.attend)
                    .map((player) => (
                      <div
                        onClick={() => {
                          if (!checkPlayerSuspension(player.id)) {
                            submitEvent(player.id);
                          }
                        }}
                        className={`item ${
                          eventState.userId === player.id ? 'selected' : ''
                        } ${checkPlayerSuspension(player.id) ? 'suspended' : ''}
                         ${checkYellow(player.id) ? 'yellow' : ''}
                        `}
                      >
                        {player.number}
                      </div>
                    ))}
              </div>
              {(eventState.action === GameAction.YELLOW_CARD ||
                eventState.action === GameAction.RED_CARD) && (
                <div className='coach-box'>
                  <h3>Card to Coache:</h3>

                  <div className='coach-events'>
                    {matchDetails.data.match?.homeTeam.id ===
                      eventState.teamId &&
                      matchDetails.data.match?.homeTeam.coaches.map((coach) => (
                        <div
                          className={`coach-card ${
                            checkPlayerSuspension(coach.id) ? 'suspended' : ''
                          } ${
                            checkYellow(coach.id) ? 'yellow' : ''
                          }`}
                          onClick={() => {
                            if (!checkPlayerSuspension(coach.id)) {
                              submitEvent(coach.id);
                            }
                          }}
                        >
                          {`${coach.firstName} ${coach.lastName}`}
                        </div>
                      ))}

                    {(eventState.action === GameAction.YELLOW_CARD ||
                      eventState.action === GameAction.RED_CARD) &&
                      matchDetails.data.match?.awayTeam.id ===
                        eventState.teamId &&
                      matchDetails.data.match?.awayTeam.coaches.map((coach) => (
                        <div
                          className={`coach-card ${
                            checkPlayerSuspension(coach.id) ? 'suspended' : ''
                          } ${
                            checkYellow(coach.id) ? 'yellow' : ''
                          }`}
                          onClick={() => {
                            if (!checkPlayerSuspension(coach.id)) {
                              submitEvent(coach.id);
                            }
                          }}
                        >
                          {`${coach.firstName} ${coach.lastName}`}
                        </div>
                      ))}
                  </div>
                </div>
              )}
              <div className='confirm-box'>
                <div className='bt' onClick={resetSelectedEvent}>
                  Cancel
                </div>
              </div>
            </>
          )}
          {eventState.submited && <LoadingIcon />}
        </PlayerSelector>
      )}

      <ConfirmBox
        isOpen={secondYellow.state}
        onClose={() => {
          setSecondYellow({ state: false, user: '' });
        }}
        onConfirm={() => {
          submitEvent(secondYellow.user, true);
          setSecondYellow({ state: false, user: '' });
        }}
        onCancel={() => {
          setSecondYellow({ state: false, user: '' });
        }}
        title='Second Yellow card. Confirm it?'
        labelConfirm='Yes'
        labelCancel='Cancel'
      />

      <GameReportModalEdit
        gameReportOpen={gameReport.modalOpened}
        extraInfo='Provide the report about the red card, and after, mark the player.'
        closeGameReport={() =>
          setGameReport({ ...gameReport, modalOpened: false })
        }
      />
    </GameEventsWrapper>
  );
};

const GameEventsWrapper = styled.div`
  margin: 10px 0;
  background-color: ${color.greyLight};
  border-radius: 10px;
`;

const GameEvents = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  &.order-swapped {
    div:nth-child(1) {
      order: 2;
    }
    div:nth-child(2) {
      order: 1;
    }
    .team {
      .bt-swap {
        right: inherit;
        left: -15px;
      }
    }
  }
  .team {
    padding: 5px;
    text-align: center;
    position: relative;
    .bt-swap {
      position: absolute;
      z-index: 10;
      width: 30px;
      height: 30px;
      background: #fff;
      border-radius: 20px;
      right: -15px;
      top: 20px;
      background-position: center;
      background-size: 50%;
      background-repeat: no-repeat;
      background-image: url(${ico_swap});
      order: 20;
    }
    .logo {
      width: 50px;
      height: 50px;
      border-radius: 30px;
      overflow: hidden;
      margin: 10px auto;
      padding: 8px;
      background: #fff;

      img {
        width: 100%;
      }
    }
    .buttons-wrapper {
      &.disabled {
        opacity: 0.2;
        .bt {
          &:hover {
            background-color: #fff;
          }
        }
      }
    }
    .bt {
      position: relative;
      padding: 10px;
      border-radius: 5px;
      text-align: center;
      background-color: #fff;
      margin-right: 5px;
      color: ${color.greyDark};
      font-family: ${font.druk};
      text-transform: uppercase;
      font-size: 10px;
      margin-bottom: 10px;
      &:hover {
        background-color: ${color.orange};
      }
      &.disabled {
        opacity: 0.2;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
`;

const PlayerSelector = styled.div`
  margin: 10px 0;
  position: relative;
  .players-grid-title {
    text-align: center;
    border-top: solid 1px #8888887a;
    padding: 15px;
  }
  .edit-time {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${color.greyLight};
    border: solid 1px ${color.orange};
    padding: 20px;
    border-radius: 10px;
    z-index: 10;
  }
  .action {
    font-size: 22px;
    font-weight: 600;
    margin: 20px 0;
    align-items: center;
    .event {
      text-align: center;
    }
    .time {
      justify-content: center;
      align-items: center;
      display: flex;
      font-weight: 300;
      margin: 10px 0;
    }
    .bt-edit {
      margin-left: 15px;
      background-color: ${color.silver};
      font-size: 10px;
      padding: 10px 15px;
      border-radius: 10px;
    }
  }
  .players-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    .item {
      width: 50px;
      height: 50px;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 30px;
      font-weight: 600;
      font-size: 22px;
      text-align: center;
      color: ${color.greyDark};
      line-height: 50px;
      cursor: pointer;
      &.selected {
        background-color: ${color.orange};
      }
      &.suspended {
        opacity: 0.3;
        cursor: not-allowed;
        box-shadow: 0px 0px 0px 3px #f00;
      }
      &.yellow {
        background-color: ${color.yellow};
      }
    }
  }
  .coach-box {
    padding: 20px;
    background: ${color.greyDark};
    border-radius: 20px;
    margin-top: 15px;
  }
  .coach-events {
    display: flex;
    flex-wrap: wrap;
  }
  .coach-card {
    background: ${color.greyLight};
    padding: 5px 15px;
    font-weight: 600;
    border-radius: 5px;
    text-align: center;
    margin: 10px;
    &.suspended {
      opacity: 0.3;
      cursor: not-allowed;
      box-shadow: 0px 0px 0px 3px #f00;
    }
    &.yellow {
      color: ${color.black};
      background-color: ${color.yellow};
    }
  }
  .confirm-box {
    margin: 20px 0;
    display: flex;
    .bt {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      text-align: center;
      background-color: ${color.silverLight};
      margin-right: 5px;
      color: ${color.greyDark};
      font-family: ${font.druk};
      text-transform: uppercase;
      font-size: 10px;
      margin-bottom: 10px;
      &.disabled {
        opacity: 0.3;
      }
    }
  }
`;

export default GameEventButtons;
