import * as React from 'react';

import ConvertUtil from 'util/ConvertUtil';
import { ModalHeaderProps } from './types';

import * as S from './styles';

const ModalHeader: React.FC<ModalHeaderProps> = ({ photo }) => {
  return (
    <S.Container>
      <S.Image $src={ConvertUtil.getMinioUrl(photo)} />
    </S.Container>
  );
};

export default ModalHeader;
