import { message, Upload } from 'antd';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ico_plus from '../../../assets/imgs/icons/ico_plus.svg';
import pdf_icon from '../../../assets/imgs/icons/pdf_file.svg';
import certificate_example from '../../../assets/imgs/safesport_certificate_example.jpg';
import { newUserUpdateExtraInfo } from '../../../redux/newUser/actions';
import { ApplicationState } from '../../../redux/store';
import DownloadService from '../../../services/download/DownloadService';
import UploadService from '../../../services/upload/UploadService';
import { color } from '../../../styles/styleVariables';
import ConvertUtil from '../../../util/ConvertUtil';

const CertificateUpload: React.FunctionComponent = (props) => {
  const [imageUrl, setImageUrl] = useState('');
  const uploadRef = useRef<HTMLDivElement>(null);

  const { newUser } = useSelector(
    (state: ApplicationState) => state.newUser
  ).data;
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (newUser?.extraInfo?.certificate) {
      DownloadService.download(newUser?.extraInfo.certificate).then((res) => {
        if (res.success) {
          fetch(ConvertUtil.getPrivateMinioUrl(res.data))
            .then((blobResp) => blobResp.blob())
            .then((blob) => {
              if (blob.type === 'application/pdf') {
                setImageUrl(pdf_icon);
              } else {
                setImageUrl(ConvertUtil.getPrivateMinioUrl(res.data));
              }
            });
        }
      });
    }
  }, [newUser]);

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'application/pdf';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/PDF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('File must smaller than 5MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const upload = (event: any) => {
    UploadService.upload({
      file: event.file,
      prefix: 'user',
      public: false,
    }).then((response) => {
      if (!response.success) {
        message.error({ content: response.message, duration: 5 });
        return;
      }
      const url = response.data.id;
      dispatch(
        newUserUpdateExtraInfo({
          certificate: url,
        })
      );

      DownloadService.download(url).then((res) => {
        if (res.success) {
          fetch(ConvertUtil.getPrivateMinioUrl(res.data))
            .then((blobResp) => blobResp.blob())
            .then((blob) => {
              if (blob.type === 'application/pdf') {
                setImageUrl(pdf_icon);
              } else {
                setImageUrl(ConvertUtil.getPrivateMinioUrl(res.data));
              }
            });
        }
      });
    });
  };

  return (
    <BoxWrapper>
      <Box className={newUser?.extraInfo?.certificate ? 'hide' : ''}>
        <div className='title'>Example of Safesport Certificate</div>
        <BoxImage className='example' />
        <div className='bottom-text' />
      </Box>
      <Box>
        <div className='title'>Upload your Safesport Certificate</div>

        <BoxImage
          style={{
            backgroundImage: `url("${imageUrl}")`,
          }}
        >
          <Upload
            showUploadList={false}
            accept='image/*,application/pdf'
            customRequest={upload}
            beforeUpload={beforeUpload}
          >
            {!newUser?.extraInfo?.certificate && (
              <PlusIcon>
                <div className='icon'>
                  <img alt='' width='20px' src={ico_plus} />
                </div>
              </PlusIcon>
            )}
            {newUser?.extraInfo?.certificate && (
              <PlusIcon>
                <div className='icon' ref={uploadRef} />
              </PlusIcon>
            )}
          </Upload>
        </BoxImage>
        {newUser?.extraInfo?.certificate && (
          <>
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <h5 style={{ fontWeight: 700, color: `${color.orange}` }}>
                CHECK THE DOCUMENT!
              </h5>
              <p>Make sure the document is readable.</p>
            </div>
            <Button onClick={() => uploadRef.current?.click()}>
              Replace File
            </Button>
          </>
        )}
      </Box>
    </BoxWrapper>
  );
};

const BoxImage = styled.div`
  position: relative;
  width: 60%;
  padding-bottom: 60%;
  height: 0;
  border-radius: 8px;
  margin: 0 auto;
  background: ${color.greyDark};
  margin-top: 20px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  border: solid 1px ${color.orange};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  &.example {
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #fff;
    background-image: url(${certificate_example});
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Box = styled.div`
  width: 48%;
  border-radius: 8px;
  padding: 20px;
  border: solid 1px ${color.greyLight};
  background: ${color.greyDark};
  @media (max-width: 768px) {
    width: 80%;
    margin-bottom: 10px;
  }
  &.hide {
    display: none;
  }
  .title {
    text-align: center;
  }
  .bottom-text {
    margin: 20px 0;
    text-align: center;
  }
`;

const PlusIcon = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  .icon {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }
`;

const Button = styled.div`
  padding: 10px 20px;
  margin: 20px auto;
  width: 75%;
  font-weight: 500;
  border-radius: 10px;
  text-align: center;
  background-color: ${color.orange};
  color: #fff;
  cursor: pointer;
`;

export default CertificateUpload;
