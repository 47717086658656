import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: ${toRem(16)};

  .btn-stripe {
    border: 1px solid ${COLORS.grey50};
    background-color: ${COLORS.grey950};
  }
`;
export const SummaryList = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SummaryGrid = styled.div`
  width: 100%;
  display: table;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: ${toRem(16)};
`;

export const SummaryTable = styled.div`
  display: table;
  border-collapse: separate;
  border-spacing: 0 ${toRem(16)};

  &.secondary {
    width: auto;
    margin-right: 0px;
    margin-left: auto;
  }
`;

export const Row = styled.div`
  display: table-row;
  vertical-align: middle;
  padding: 40px;
`;

export const Cell = styled.div`
  display: table-cell;
  vertical-align: middle;
  color: ${COLORS.grey50};

  &.header {
    color: ${COLORS.grey400};
  }

  text-align: end;
  > p {
    text-align: end;
  }
  > div > p {
    text-align: end;
  }

  &.firstColumn {
    text-align: start;
    > p {
      text-align: start;
    }
  }

  &.qty {
    padding: ${toRem(8)};
    width: 40px;
    border-radius: ${toRem(8)};
    background: ${COLORS.grey800};
    p {
      text-align: center;
    }
  }

  .headerMiddle {
    margin-right: ${toRem(10)};
  }

  &.secondary {
    padding-left: 10px;
  }
`;

export const Column = styled.div`
  /* display: flex;
  align-items: flex-start; */
  text-align: end;
  > p {
    text-align: end;
  }

  &.firstColumn {
    text-align: start;
    > p {
      text-align: start;
    }
  }
`;

export const ColumnHeader = styled.div`
  text-align: end;
  &.firstColumn {
    text-align: start;
  }
`;

export const DivisionColumn = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${toRem(8)};
`;

export const QtyWrapper = styled.div`
  display: flex;
  padding: ${toRem(8)};
  width: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${toRem(10)};
  flex-shrink: 0;
  align-self: flex-end;

  border-radius: ${toRem(8)};
  background: ${COLORS.grey800};
`;

export const TotalWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 100px;
  grid-auto-flow: column;

  align-items: center;
`;

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${toRem(8)};

  .i-icon svg {
    width: ${toRem(24)};
    height: ${toRem(24)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.grey50};
    }
  }
`;
