export function setUserId(userId: string): void {
  localStorage.setItem('vault_account_id', userId);
}
export function getUserId(): string | null {
  return localStorage.getItem('vault_account_id');
}

export function setSelectedAccountType(accountType: string): void {
  localStorage.setItem('account_type', accountType);
}
export function getSelectedAccountType(): string | null {
  return localStorage.getItem('account_type');
}

export function setSelectedAccountId(accountType: string): void {
  localStorage.setItem('account_id', accountType);
}
export function getSelectedAccountId(): string | null {
  return localStorage.getItem('account_id');
}
