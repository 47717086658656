const getValidatedYear = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return currentYear + 1;
};

export const REJECT_REASONS_MESSAGES = [
  {
    reason: 'expiringSoon',
    message: `SafeSport training needs to be valid through the entire season until August 30, ${getValidatedYear()}. Please complete the following refresher training to maintain compliance. You can access the SafeSport Training Modules using the following link and access code YC3E-6P5G-YYIL-CS2M: https://safesporttrained.org/#/signup`,
  },
  {
    reason: 'expired',
    message: `Your SafeSport document is expired. Please complete a refresher training and upload the updated certificate valid through August 30,  ${getValidatedYear()}. You can access the SafeSport Training Modules using the following link and access code YC3E-6P5G-YYIL-CS2M: https://safesporttrained.org/#/signup`,
  },
  {
    reason: 'invalid',
    message: `This document is not valid. Please upload an official SafeSport Certificate valid through August 30,  ${getValidatedYear()}. If needed, you can access the SafeSport Training Modules using the following link and access code YC3E-6P5G-YYIL-CS2M: https://safesporttrained.org/#/signup`,
  },
  {
    reason: 'nameMismatch',
    message:
      'Please upload your own SafeSport Certificate. The full name provided is not matching the name added on the VAULT profile.',
  },
  {
    reason: 'wrongDocument',
    message:
      'Please upload the required documents (Passport or Official Birth Certificate)',
  },
  {
    reason: 'pixelated',
    message:
      'Your picture is too pixelated and we cannot confirm the information. Please upload a photo with a higher resolution.',
  },
  {
    reason: 'wrongName',
    message:
      'Please upload your own birth certificate. The full name provided does not match the name added on the VAULT profile.',
  },
  {
    reason: 'wrongDate',
    message:
      'Please upload your own birth certificate. The date of birth provided does not match the data provided on the VAULT profile.',
  },
  {
    reason: 'wrongVaultData',
    message:
      'Please note that the information from the document provided does not match with the data provided on the VAULT profile. To move forward, please create another user with correct data because the VAULT will require you to add additional guardian information (for those 18 and under).',
  },
  {
    reason: 'wrongUser',
    message:
      'Please note that the document does not match with the data provided on the VAULT profile. To move forward, please create a new user with the correct data and send an email to montse@unitedfutsal.com. We will move the membership information from the incorrect accounts to the updated account.',
  },
  {
    reason: 'dark',
    message:
      'Your picture is too dark. Please try to upload your photo on a different browser and device. Uploads via smartphone will not work.',
  },
  {
    reason: 'pixelated',
    message:
      'Your picture is too pixelated. Please upload a photo with a higher resolution.',
  },
  {
    reason: 'obstructed',
    message:
      'We require an unobstructed photo to complete this step. Therefore, please upload a headshot photo with a clear background, and without hats, glasses, etc.',
  },
  {
    reason: 'nonHeadshot',
    message:
      'We require a headshot to complete this step. Therefore, please upload a picture with just your face. A full body or half-body picture will not be accepted.',
  },
  {
    reason: 'solo',
    message:
      'We require a solo-headshot to complete this step. Please upload a picture without any additional individuals in the photo or background.',
  },
];
