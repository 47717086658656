import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 ${toRem(30)} ${toRem(24)};

  .add-venue-button {
    width: 100%;
    background-color: transparent;
    border-color: ${COLORS.grey50};
    align-items: center;
    display: flex;
    column-gap: 8px;

    > span {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: ${COLORS.brandPrimary};
    }
  }
`;

export const PricingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  .priceItem {
    width: 100%;
  }
`;

export const PricingList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const Content = styled.div``;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
`;

export const PricingListItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;

  border-radius: 12px;
  border: 1px solid var(--grey-800, #52544c);

  div:first {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .button-container {
      cursor: pointer;
      display: flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 12px;
      background: ${COLORS.grey800};

      .eventIcon {
        width: 24px;
        height: 24px;

        &::before {
          content: none;
        }

        &:hover {
          svg path {
            stroke: ${COLORS.brandPrimary};
          }
        }

        &.delete:hover {
          svg path {
            stroke: ${COLORS.supportError};
          }
        }
      }
    }
  }
`;

export const DisconnectWrapper = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid ${COLORS.grey400};

  .leftSide {
    display: flex;
    flex-direction: column;
  }

  .rightSide {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;

    font-family: 'Boxed Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    text-transform: uppercase;

    .eventIcon {
      &::before {
        content: none;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;

      border: 2px solid white;
      border-radius: 10px;
    }
  }
`;

export const HR = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.grey900};
  &::after {
    content: ' ';
  }
`;
export const MyPricingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;

  > button {
    width: 100%;
  }
`;

export const MyPricingList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding-bottom: 24px;
`;

export const MyPricingListItem = styled.div<{ $selected?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 8px;
  align-items: center;

  cursor: pointer;

  span {
    &::before {
      content: none;
    }
    svg path {
      stroke: ${COLORS.brandPrimary};
    }
  }

  font-weight: 600;
  color: ${(props) => (props.$selected ? COLORS.grey50 : COLORS.grey400)};

  &:hover {
    color: ${COLORS.grey50};
  }
`;

export const DiscountsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const LargeButton = styled.div<{
  $color: keyof typeof COLORS;
  $bgColor: keyof typeof COLORS;
  $borderColor: keyof typeof COLORS;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding: 8px 24px;
  align-self: stretch;

  cursor: pointer;
  font-family: 'Boxed Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  text-transform: uppercase;

  color: ${(props) => COLORS[props.$color]};
  background-color: ${(props) => COLORS[props.$bgColor]};
  border-radius: 12px;
  border: 1px solid ${(props) => COLORS[props.$borderColor]};

  &:hover {
    filter: brightness(110%);
  }
`;

export const TextButtonWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;

  cursor: pointer;
  font-family: 'Boxed Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  text-transform: uppercase;

  color: ${COLORS.grey50};

  &:hover {
    color: ${COLORS.brandPrimary};

    svg path {
      stroke: ${COLORS.brandPrimary};
    }
  }

  .eventIcon {
    width: 24px;
    height: 24px;

    &::before {
      content: none;
    }
  }
`;

export const CouponListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
`;
export const CouponListItem = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  justify-content: space-between;

  border-radius: 12px;
  border: 1px solid ${COLORS.grey800};

  .rightSide {
    display: flex;
    column-gap: 8px;
  }

  .button-container {
    cursor: pointer;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    background: ${COLORS.grey800};

    .eventIcon {
      width: 24px;
      height: 24px;

      &::before {
        content: none;
      }

      &:hover {
        svg path {
          stroke: ${COLORS.brandPrimary};
        }
      }

      &.delete:hover {
        svg path {
          stroke: ${COLORS.supportError};
        }
      }
    }
  }
`;

export const NewCouponFormWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: ${toRem(48)};
  }
`;

export const SubmitButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  flex: 1;

  button {
    flex: 1;
  }
`;

export const PricingForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  legend {
    margin: ${toRem(6)} 0 ${toRem(24)};
  }

  .field-description {
    font-weight: 500;
    > span {
      font-weight: 700;
    }
  }

  fieldset {
    button:last-child {
      width: 100%;
    }

    .input-info {
      padding: 8px 16px 0;
      color: ${COLORS.grey400};
    }

    /* & + fieldset {
      margin-top: 1.5rem;
    } */
    .general-event-info {
      margin: ${toRem(4)} 0 ${toRem(24)} ${toRem(16)};
    }
    .general-event-input {
      & + .general-event-input {
        margin-top: 1.5rem;
      }
    }
    .booster-item {
      & + .booster-item {
        margin-top: ${toRem(24)};
      }
    }
  }

  bottom {
    width: 100%;
  }
`;
export const PricingFieldset = styled.fieldset`
  display: flex;
  justify-content: space-between;
  column-gap: 16px;

  .general-event-input-date {
    width: 100%;
    & + .general-event-input-date {
      margin-left: 1.5rem;
    }
  }
`;
export const PricingSummary = styled.div`
  width: 100%;
  max-width: 384px;

  .summary-subtitle {
    margin-bottom: 1rem;
  }
  .trophy-image {
    max-width: 50px;
  }
  .general-event-selected {
    margin: ${toRem(14)} 0 ${toRem(30)};
  }
`;
export const PricingSummaryHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${toRem(30)};

  .summary-title {
    margin: ${toRem(30)} 0 ${toRem(4)};
  }
`;
export const PricingSummaryBody = styled.div`
  display: grid;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${toRem(10)};
`;
export const PricingSummaryMapWrapper = styled.div`
  max-width: 324px;
  max-height: 110px;
  display: flex;
  margin-bottom: ${toRem(30)};
  align-items: center;
  justify-content: center;
  position: relative;

  p {
    position: absolute;
    z-index: 1;
  }

  img {
    object-fit: cover;
  }
`;
export const PricingSummaryEventSelected = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;

  h4,
  p {
    margin: 0;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  position: relative;
  font-family: 'Boxed Regular';
  font-weight: 500;
  border: 1px solid ${COLORS.grey400};
  border-radius: ${toRem(12)};
  padding: ${toRem(8)} ${toRem(16)};

  &:hover {
    border-color: ${COLORS.brandPrimary};
  }

  > select {
  }
`;

export const Select = styled.select`
  width: 100%;
  color: ${COLORS.white};
  font-size: 1rem;
  background-color: ${COLORS.grey950};
  border: 0;
  flex: 1;
  padding: 0;
  line-height: 1rem;

  &:focus {
    outline: none;
  }
  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`;
