import * as React from 'react';
import { BodyS } from 'styles/v3/variables';
import InputText, { InputTextProps } from '../InputText/InputText';

import * as S from './styles';

interface InputTextLimitProps extends InputTextProps {
  limit: number;
  caption?: string;
}

const InputTextLimit: React.FC<InputTextLimitProps> = ({
  limit,
  caption,
  className,
  value,
  onChange,
  ...props
}) => {
  const [inputLength, setInputLength] = React.useState<number>(0);
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputLength(e.target.value.length);
    onChange && onChange(e);
  };

  return (
    <div className={className}>
      <InputText onChange={onInputChange} maxLength={limit} {...props} />
      <S.TextWrapper>
        <BodyS $color='grey400'>{caption}</BodyS>
        <BodyS $color='grey400'>{`${inputLength} / ${limit}`}</BodyS>
      </S.TextWrapper>
    </div>
  );
};

export default InputTextLimit;
