import * as React from 'react';
import styled from 'styled-components';
import { ArrowLeft, Down } from '@icon-park/react';
import Icon, { IconType } from '@icon-park/react/es/all';

import { COLORS, ColorsInterface } from 'styles/v3/variables';
import { ButtonProps } from './BasicButton';
import TextButton from './TextButton';

interface TextButtonIconProps extends ButtonProps {
  color?: 'primary' | 'dark' | 'light' | keyof ColorsInterface;
  rotateIcon?: boolean;
  reverse?: boolean;
  icon?: 'back' | 'down';
  customIcon?: IconType;
  align?: 'flex-start' | 'center' | 'flex-end';
  noPadding?: boolean;
}

const TextButtonWithIcon: React.FC<TextButtonIconProps> = ({
  color,
  children,
  icon,
  customIcon,
  reverse = false,
  rotateIcon = false,
  align = 'center',
  noPadding,
  ...props
}) => {
  let renderIcon: React.ReactNode | string;
  if (customIcon) {
    renderIcon = <Icon type={customIcon} size={24} />;
  } else {
    renderIcon = icon === 'down' ? <Down /> : <ArrowLeft />;
  }
  return (
    <TextButtonIcon
      $theme={color}
      $rotateIcon={rotateIcon}
      $reverse={reverse}
      $align={align}
      $noPadding={noPadding}
      {...props}
    >
      {children}
      {renderIcon}
    </TextButtonIcon>
  );
};

const TextButtonIcon = styled(TextButton)<{
  $theme?: 'primary' | 'dark' | 'light' | keyof ColorsInterface;
  $rotateIcon?: boolean;
  $reverse?: boolean;
  $align: 'flex-start' | 'center' | 'flex-end';
  $noPadding?: boolean;
}>`
  justify-content: ${(props) => props.$align};
  flex-direction: ${(props) => (props.$reverse ? 'row-reverse' : 'initial')};
  padding-left: ${(props) => {
    if (props.$noPadding) {
      return 0;
    }
    return props.$reverse ? 0 : '1.5rem';
  }};
  padding-right: ${(props) => {
    if (props.$noPadding) {
      return 0;
    }
    return !props.$reverse ? 0 : '1.5rem';
  }};
  color: ${(props) => {
    if (props.$theme) {
      if (props.$theme === 'primary') {
        return COLORS.brandPrimary;
      }
      if (props.$theme === 'dark') {
        return COLORS.grey1000;
      }
      if (props.$theme === 'light') {
        return COLORS.white;
      }
      return COLORS[props.$theme];
    }

    return COLORS.white;
  }};

  &:hover {
    color: inherit;
    text-shadow: none;
  }

  span {
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    margin-right: ${(props) => (props.$reverse ? '0.5rem' : '0')};
    margin-left: ${(props) => (props.$reverse ? '0' : '0.5rem')};
    transform: ${(props) => (props.$rotateIcon ? 'rotate(180deg)' : 'none')};
  }
`;

export default TextButtonWithIcon;
