import { Modal } from 'antd';
import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container =
  styled.div <
  { $type: 'disapprove' | 'approve' } >
  `
  display: flex;
  flex-direction: column;

  gap: ${toRem(16)};
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: ${toRem(384)};
    border-radius: ${toRem(24)};
    background-color: ${COLORS.grey950};
  }

  .ant-modal-header {
    border-radius: 24px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 24px;
    padding: 6px 12px 6px 12px;
  }
`;

export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 38px;
  h3 {
    margin: 0 !important;
  }
`;

export const HeaderTitle = styled.div`
  width: 100%;
  max-width: ${toRem(280)};
  display: flex;
  flex-direction: row;
  column-gap: ${toRem(4)};
  flex-wrap: wrap;
  align-items: center;
`;

export const CloseButton = styled.button`
  width: 38px;
  height: 38px;

  border: none;
  box-shadow: none;
  padding: 7.4px 0;
  border-radius: 10px;
  background-color: ${COLORS.grey700};

  cursor: pointer;

  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: cednter;
  &:hover {
    background-color: ${COLORS.grey600};
  }
`;
