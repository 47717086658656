const color = {
  orange: '#fd502f',
  orangeLight: '#fd922f',
  black: '#000000',
  yellow: '#ffee00',
  greyDark: '#24272c',
  greyLight: '#2e3137',
  greyText: '#878B93',
  silver: '#53575f',
  silverLight: '#80838a',
  silverLightShadow: '#40444b',
};

const font = {
  druk: 'Druk Wide Cyr',
  montserrat: 'Montserrat, sans-serif',
};

const gradient = {
  orange: 'linear-gradient(180deg, #fd922f 0%, #fd502f 110%)',
  barcelona:
    'linear-gradient(0deg, rgba(36,53,142,1) 0%, rgba(139,7,84,1) 100%)',
};
export { color, gradient, font };
