import * as React from 'react';
import { CornerDownRight } from '@icon-park/react';

import { BodyMBold, BodyS, TitleH4 } from 'styles/v3/variables';
import { useGetUserInfo } from 'hooks/v3/users/useGetUserInfo/useGetUserInfo';
import ConvertUtil from 'util/ConvertUtil';
import { getUserTypeName } from 'models/User/UserModel';
import { ClubBannerProps } from './types';

import * as S from './styles';

const ClubBanner: React.FC<ClubBannerProps> = ({ club, userId }) => {
  const { data: userInfo } = useGetUserInfo(userId);

  return (
    <S.Container>
      <S.Column>
        <S.Icon $src={ConvertUtil.getMinioUrl(club?.logo)} />
      </S.Column>
      <S.Column>
        <TitleH4>{club?.name}</TitleH4>
        <S.UserInfoWrapper>
          <CornerDownRight />
          <BodyMBold $color='grey200'>
            {userInfo?.firstName} {userInfo?.lastName}
          </BodyMBold>
          <BodyS $color='grey400'>
            {getUserTypeName(userInfo?.type) ?? 'Team Manager'}
          </BodyS>
        </S.UserInfoWrapper>
      </S.Column>
    </S.Container>
  );
};

export default ClubBanner;
