import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { ApplicationState } from 'redux/store';

import useBlackList from 'hooks/useBlackList/useBlackList';

import Button from 'components/uielements/Button/Button';
import LoadingIcon from 'components/base/LoadingIcon/LoadingIcon';

const LayoutBaseAdmin: FC = ({ children }) => {
  const userData = useSelector((state: ApplicationState) => state.account);

  const blackListed = useBlackList();

  if (userData.data?.accounts) {
    return (
      <Row>
        <Col span={6} style={{ padding: '30px' }}>
          <div>
            {!blackListed && (
              <>
                <NavLink
                  to='/admin-panel/events'
                  exact
                  activeClassName='active'
                >
                  <Button>Events</Button>
                </NavLink>
                <NavLink to='/admin-panel/events-b2b' exact>
                  <Button>Events B2B</Button>
                </NavLink>
                <NavLink to='/admin-panel/memberships' activeClassName='active'>
                  <Button>Memberships</Button>
                </NavLink>
              </>
            )}
            <NavLink to='/admin-panel/clubs' activeClassName='active'>
              <Button>Clubs</Button>
            </NavLink>
            <NavLink to='/admin-panel/clubs-pending' activeClassName='active'>
              <Button>Clubs Pending</Button>
            </NavLink>
            <NavLink
              to='/admin-panel/document-analysis'
              activeClassName='active'
            >
              <Button>Documents</Button>
            </NavLink>
            <NavLink to='/admin-panel/users-list' activeClassName='active'>
              <Button>Users</Button>
            </NavLink>
            {!blackListed && (
              <>
                <NavLink to='/admin-panel/venues' activeClassName='active'>
                  <Button>Venues</Button>
                </NavLink>
                <NavLink to='/admin-panel/base-events' activeClassName='active'>
                  <Button>Base events</Button>
                </NavLink>
              </>
            )}
          </div>
        </Col>
        <Col span={18} style={{ padding: '10px' }}>
          {children}
        </Col>
      </Row>
    );
  }

  return (
    <div style={{ height: '80vh', display: 'flex', alignItems: 'center' }}>
      <LoadingIcon />
    </div>
  );
};

export default LayoutBaseAdmin;
