export const timezones = [
  { value: 'UTC-11', locations: 'Pacific/Midway' },
  { value: 'UTC-10', locations: 'Pacific/Honolulu' },
  { value: 'UTC-9', locations: 'America/Adak' },
  { value: 'UTC-8', locations: 'America/Anchorage' },
  { value: 'UTC-7', locations: 'America/Los_Angeles' },
  { value: 'UTC-6', locations: 'America/Denver' },
  { value: 'UTC-5', locations: 'America/Chicago' },
  {
    value: 'UTC-4',
    locations: 'America/New_York, America/Caracas, America/Santiago',
  },
  { value: 'UTC-3', locations: 'America/Halifax, America/Sao_Paulo' },
  { value: 'UTC-2', locations: 'Atlantic/South_Georgia' },
  { value: 'UTC-1', locations: 'Atlantic/Azores' },
  { value: 'UTC', locations: 'Europe/London, Europe/Lisbon' },
  { value: 'UTC+1', locations: 'Europe/Madrid, Europe/Rome' },
  { value: 'UTC+2', locations: 'Europe/Paris, Europe/Berlin' },
  { value: 'UTC+3', locations: 'Europe/Kiev, Europe/Moscow' },
  { value: 'UTC+4', locations: 'Asia/Dubai' },
  { value: 'UTC+5', locations: 'Asia/Karachi' },
  { value: 'UTC+6', locations: 'Asia/Dhaka' },
  { value: 'UTC+7', locations: 'Asia/Bangkok' },
  { value: 'UTC+8', locations: 'Asia/Shanghai' },
  { value: 'UTC+9', locations: 'Asia/Tokyo' },
  { value: 'UTC+11', locations: 'Australia/Sydney' },
  { value: 'UTC+13', locations: 'Pacific/Auckland' },
];
