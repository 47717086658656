import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UserType from '../../../models/User/UserTypeModel';
import { ApplicationState } from '../../../redux/store';
import StatsService from '../../../services/Stats/StatsService';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import StatsBoxWrapper from './StatisticsBox.style';

interface StatisticsData {
  action: string;
  quantity: number;
}

const StatisticsBox: React.FunctionComponent = () => {
  const { user } = useSelector((state: ApplicationState) => state);

  const [loading, setLoading] = useState(true);

  const [statisticsData, setStatisticsData] = useState<StatisticsData[]>([]);

  const filterStatsByType = (userType: UserType | undefined) => {
    if (userType === UserType.PLAYER) {
      return statisticsData.filter(
        (item) =>
          item.action === 'APPEARANCE' ||
          item.action === 'WIN' ||
          item.action === 'WIN_RATE' ||
          item.action === 'GOAL' ||
          item.action === 'GOAL_RATE'
      );
    }

    if (userType === UserType.COACH || userType === UserType.CLUB_DIRECTOR) {
      return statisticsData.filter(
        (item) =>
          item.action === 'APPEARANCE' ||
          item.action === 'WIN' ||
          item.action === 'DRAW' ||
          item.action === 'LOSS' ||
          item.action === 'WIN_RATE'
      );
    }
    return [];
  };

  const renderStats = (userType: UserType) => {
    return (
      <>
        <div className='item'>
          <div className='value'>
            {statisticsData.find((item) => item.action === 'APPEARANCE')
              ?.quantity || 0}
          </div>
          <div className='label'>TOTAL</div>
          <div className='label-2'>Games</div>
        </div>

        <div className='item'>
          <div className='value'>
            {statisticsData.find((item) => item.action === 'WIN')?.quantity ||
              0}
          </div>
          <div className='label'>TOTAL</div>
          <div className='label-2'>Wins</div>
        </div>

        <div className='item'>
          <div className='value'>
            {Math.round(
              ((statisticsData.find((item) => item.action === 'WIN')
                ?.quantity || 0) /
                (statisticsData.find((item) => item.action === 'APPEARANCE')
                  ?.quantity || 1)) *
                100
            ) || 0}
            %
          </div>
          <div className='label'>TOTAL</div>
          <div className='label-2'>Win Rate</div>
        </div>

        {(userType === UserType.COACH ||
          userType === UserType.CLUB_DIRECTOR) && (
          <div className='item'>
            <div className='value'>
              {statisticsData.find((item) => item.action === 'LOSS')
                ?.quantity || 0}
            </div>
            <div className='label'>TOTAL</div>
            <div className='label-2'>Losses</div>
          </div>
        )}

        {(userType === UserType.COACH ||
          userType === UserType.CLUB_DIRECTOR) && (
          <div className='item'>
            <div className='value'>
              {statisticsData.find((item) => item.action === 'DRAW')
                ?.quantity || 0}
            </div>
            <div className='label'>TOTAL</div>
            <div className='label-2'>Draws</div>
          </div>
        )}

        {userType === UserType.PLAYER && (
          <div className='item'>
            <div className='value'>
              {statisticsData.find((item) => item.action === 'GOAL')
                ?.quantity || 0}
            </div>
            <div className='label'>TOTAL</div>
            <div className='label-2'>Goals</div>
          </div>
        )}
        {userType === UserType.PLAYER && (
          <div className='item'>
            <div className='value'>
              {Math.round(
                ((statisticsData.find((item) => item.action === 'GOAL')
                  ?.quantity || 0) /
                  (statisticsData.find((item) => item.action === 'APPEARANCE')
                    ?.quantity || 1)) *
                  10
              ) / 10 || 0}
            </div>
            <div className='label'>TOTAL</div>
            <div className='label-2'>Goals per Match</div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    setLoading(true);
    setStatisticsData([]);
    StatsService.getUserStats({
      userIdList: [user.data.id],
      actionList: [],
      timeFrame: 'CURRENT_SEASON',
    }).then((res) => {
      if (res.success) {
        setStatisticsData(res.data.content[0]?.summary || []);
        setLoading(false);
      }
    });
  }, [user.data.id]);

  return (
    <StatsBoxWrapper>
      {loading && <LoadingIcon />}
      {user.data.type && !loading && (
        <div className='stats-box'>{renderStats(user.data.type)}</div>
      )}
    </StatsBoxWrapper>
  );
};

export default StatisticsBox;
