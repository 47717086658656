export enum DSConnectionStatus {
  CONNECTED = 'CONNECTED',
  CONNECTING = 'CONNECTING',
  ERROR = 'ERROR',
  DISCONNECTED = 'DISCONNECTED',
}

export enum DSEmitEvent {
  INIT_DATA = 'initialData',
  TIME_UPDATE = 'timeUpdate',
  SCORE_UPDATE = 'scoreUpdate',
  GOAL = 'goal',
  FOUL = 'foul',
  PK_GOAL = 'pkGoal',
  PK_MISS = 'pkMiss',
  OWN_GOAL = 'ownGoal',
  YELLOW_CARD = 'yellowCard',
  RED_CARD = 'redCard',
}

export interface DSConnection {
  ipPanel?: number;
  status: DSConnectionStatus;
}
