import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';
import NotificationsService from 'services/v3/Notifications/NotificationsService';

export const useDeleteNotifications = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) =>
      await NotificationsService.deleteNotification(id),
    onSuccess: async () => {
      notification.success({
        message: 'Notification removed successfully',
      });

      await queryClient.invalidateQueries(['get-my-notifications']);
    },
    onError: async () => {
      notification.error({
        message: 'An error occurred while trying to delete the notification.',
      });
    },
  });
};
