import React, { useMemo } from 'react';
import ConvertUtil from 'util/ConvertUtil';
import { PreviewOpen } from '@icon-park/react';

import {
  BodyLBold,
  BodyM,
  BodyS,
  BodySBold,
  TitleH4,
} from 'styles/v3/variables';
import moment from 'moment';
import { NumberToUSDollar } from 'util/CurrencyUtil';
import { OrderItemProps } from './types';
import * as S from './styles';

export const OrderItem = ({ order, handleOpenModal }: OrderItemProps) => {
  const info = useMemo(() => {
    const soldAt = moment(order?.createdAt ?? '').format('MMM DD yy, hh:mm A');

    const total = order.subtotal ? order.subtotal / 100 : 0;
    return {
      soldAt,
      total: NumberToUSDollar.format(total),
      teams:
        order.item.b2bEventRosterFee?.divisions.reduce((acc, cur) => {
          return acc + cur.quantity;
        }, 0) || 0,
    };
  }, [order]);

  return (
    <S.Container>
      <S.LeftColumn>
        <S.TitleWrapper>
          <S.Icon
            $src={ConvertUtil.getMinioUrl(
              order?.item.b2bEventRosterFee?.club.logo
            )}
          />
          <TitleH4>{order?.item.b2bEventRosterFee?.club.name}</TitleH4>
        </S.TitleWrapper>
        <S.SubTitleWrapper>
          <S.SubTitleItem>
            <BodyS>Order Number</BodyS>
            <BodySBold>{order.id}</BodySBold>
          </S.SubTitleItem>
          <S.SubTitleItem>
            <BodyS>Sold at</BodyS>
            <BodySBold>{info.soldAt}</BodySBold>
          </S.SubTitleItem>
        </S.SubTitleWrapper>
      </S.LeftColumn>
      <S.RightColumn>
        <S.PriceWrapper>
          <BodyLBold $color='brandPrimary'>{info.total}</BodyLBold>
          <BodyM>{info.teams} teams</BodyM>
        </S.PriceWrapper>
        <S.ViewIconWrapper onClick={() => handleOpenModal(order)}>
          <PreviewOpen />
        </S.ViewIconWrapper>
      </S.RightColumn>
    </S.Container>
  );
};
