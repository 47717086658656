import { PoolMatch } from 'admin/models/AgeDivision';
import { ScheduleType, ScheduleView } from 'models/Schedule/ScheduleModel';
import { v4 as uuidv14 } from 'uuid';

export const divisionsData = ['DivisionA', 'DivisionB', 'DivisionC'];

export interface SportEventType {
  id: string;
  bracketId: string;
  scheduleType: ScheduleType;
  name: string;
  scheduled?: boolean;
  labelData: {
    text1: string,
    text2: string,
    text3: string,
    text4: string,
  };
  bg: string;
  division: string | null;
}

export interface DivistionType {
  name: string;
  days: any[];
  unscheduleGames: [];
}

export interface CalendarSchedule {
  id: string | number;
  startTime: string;
  endTime: string;
  status: 'available' | 'booked';
  event: SportEventType | null;
  division: string | null;
}

export interface CalendarCourt {
  id: string;
  name: string;
  schedules: CalendarSchedule[];
  venueId: string;
}

export interface CalendarDay {
  id: string;
  date: string;
  courts: CalendarCourt[];
  active: boolean;
}

interface CustomCalendarType {
  days: CalendarDay[];
}

export const getEventsAvailableSpaces = () => {
  const startTime = new Date('2023-11-01T07:00:00.000Z');
  const endTime = new Date('2023-11-02T06:30:00.000Z');

  const eventsSpaceAvailable = [];

  for (
    let currentTime = startTime;
    currentTime <= endTime;
    currentTime.setMinutes(currentTime.getMinutes() + 30)
  ) {
    const eventSpaceObject: CalendarSchedule = {
      id: uuidv14(),
      startTime: currentTime.toISOString(),
      endTime: new Date(currentTime.getTime() + 60 * 30 * 1000).toISOString(), // Adding 1 hour to the start time
      status: 'available',
      event: null,
      division: null,
    };

    eventsSpaceAvailable.push(eventSpaceObject);
  }

  return eventsSpaceAvailable;
};

export interface CustomCalendarProps {
  allSportEvent: SportEventType[];
  allDays: CalendarDay[];
  divisionsData: {
    id: string,
    name: string,
  }[];
  activeDivision: string;
  divisionColors: { [key: string]: string };
  scheduleRules: {
    minTimeBetweenGames: number,
    maxTimeBetweenGames: number,
    maxGamesPerTeam: number,
    poolGamesLength: number,
    bracketGamesLength: number,
  };
  currentSchedule?: ScheduleView;
  otherSchedule?: ScheduleView;
  poolMatches: PoolMatch[];
}
