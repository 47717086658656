import { AxiosRequestConfig } from 'axios';
import {
  GameEventModel,
  GameEventReturn,
} from '../../models/GameEvent/GameEventModel';
import { ConfigApi } from '../config.api';
import APIServices from '../util/ApiServices';
import ServiceResponse from '../util/ServiceResponse';

class MatchEventService {
  public static async create(
    body: GameEventModel
  ): Promise<ServiceResponse<GameEventReturn>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/matches/futsal-events',
      method: 'POST',
      data: body,
    };
    return APIServices.request<GameEventReturn>(axiosConfig);
  }

  public static async delete(
    gameEventId: string
  ): Promise<ServiceResponse<GameEventReturn>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/matches/futsal-events/${gameEventId}`,
      method: 'DELETE',
    };
    return APIServices.request<GameEventReturn>(axiosConfig);
  }
}

export default MatchEventService;
