import { message, Select, Tooltip, Upload } from 'antd';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import avatar_icon from '../../assets/imgs/avatar.svg';
import useKeyPress from '../../hooks/useKeypress/useKeypress';
import playerSpecialties from '../../models/PlayerSpecialties';
import UserType from '../../models/User/UserTypeModel';
import { ApplicationState } from '../../redux/store';
import { userUpdate } from '../../redux/user/actions';
import UploadService from '../../services/upload/UploadService';
import { color } from '../../styles/styleVariables';
import ConvertUtil from '../../util/ConvertUtil';
import Button from '../uielements/Button/Button';
import { RcFile } from 'antd/lib/upload';
import EditPhoto from '../base/EditPhoto/EditPhoto';

interface IEditInfoProps {
  onSave?: () => void;
  onClose?: () => void;
}
const { Option } = Select;
const EditInfo: React.FunctionComponent<IEditInfoProps> = (props) => {
  const { onSave, onClose } = props;

  const [userInfo, setUserInfo] = useState<any>({});
  const [dirtyForm, setDirtyForm] = useState(false);
  const [confirmLeave, setConfirmLeave] = useState(false);

  const handleFormChange = () => {
    setDirtyForm(true);
  };

  const dispatch = useDispatch();

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    control,
    errors,
    watch,
  } = useForm<{
    height: number;
    preferredPosition: string;
    preferredShirtNumber: number;
    avatar: string;
    nickName: string;
    preferredName: string;
    primaryClubId: string;
    specialty: string;
  }>();

  const { user, club } = useSelector((state: ApplicationState) => state);

  const preferredPosition = watch(
    'preferredPosition',
    user.data.playerInfo?.preferredPosition
      ? user.data.playerInfo.preferredPosition
      : ''
  );

  useEffect(() => {
    if (
      !playerSpecialties
        ?.get(preferredPosition)
        ?.includes(getValues('specialty'))
    ) {
      const specialties = playerSpecialties.get(preferredPosition);
      setValue('specialty', specialties ? specialties[0] : '');
    }
  }, [preferredPosition, setValue, getValues]);

  const upload = (event: any) => {
    UploadService.upload({
      file: event.file,
      prefix: 'user',
      public: true,
    }).then((response) => {
      if (!response.success) {
        if (response.message) {
          message.error({ content: response.message, duration: 5 });
        }
        return;
      }
      const url = response.data.id;
      setUserInfo({ avatar: url });
      setEditPhotoOpen(true);
    });
  };

  function onSubmit() {
    const data = getValues();
    dispatch(
      userUpdate({
        ...user.data,
        playerInfo: {
          ...(data.height && {
            height: data.height,
          }),

          ...(data.preferredPosition && {
            preferredPosition: data.preferredPosition,
          }),

          ...(data.specialty && {
            specialty: data.specialty,
          }),

          ...(data.preferredShirtNumber && {
            preferredShirtNumber: data.preferredShirtNumber
              ? data.preferredShirtNumber
              : undefined,
          }),

          ...(data.nickName && {
            nickName: data.nickName,
          }),

          preferredName: data.preferredName ? 'NICKNAME' : 'OFFICIAL',

          ...(data.primaryClubId && {
            primaryClubId: data.primaryClubId,
          }),
        },
        avatar:
          editedImg || (userInfo.avatar ? userInfo.avatar : user.data.avatar),
      })
    );
    onSave?.call(null);
  }

  const isEscapePressed = useKeyPress({ targetKey: 'Escape' });

  // Memoized closeForm to use inside useEffect
  const closeForm = useCallback(() => {
    if (dirtyForm) {
      setConfirmLeave(true);
    } else {
      onClose?.call(null);
    }
  }, [dirtyForm, onClose]);

  useEffect(() => {
    if (isEscapePressed) {
      closeForm();
    }
  }, [isEscapePressed, closeForm]);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    return isJpgOrPng;
  };

  const [editedImg, setEditedImg] = useState('');
  const [editPhotoOpen, setEditPhotoOpen] = useState(false);
  const uploadEdited = (file: File) => {
    const type = 'photo';
    UploadService.upload({
      file,
      prefix: 'user',
      public: type === 'photo',
    }).then((response) => {
      if (!response.success) {
        message.error({ content: response.message, duration: 5 });
        return;
      }
      const url = response.data.id;
      setUserInfo({ avatar: url });

      if (type === 'photo') {
        setEditedImg(url);
      }
    });
  };

  return (
    <WrapperBox>
      <div
        className='close-button'
        style={{ top: '20px', right: '20px' }}
        onClick={closeForm}
        onKeyDown={closeForm}
        role='button'
        tabIndex={0}
        aria-label='Close'
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return false;
        }}
      >
        <h1 style={{ textAlign: 'center' }}>MY INFO</h1>
        <div>
          <div className='form-group'>
            <label htmlFor='nickName'>
              Nickname
              <div>
                <span className='icon icon-user' />
                <input
                  className='icon'
                  type='text'
                  id='nickName'
                  name='nickName'
                  ref={register()}
                  onChange={handleFormChange}
                  defaultValue={user?.data.playerInfo?.nickName}
                />
              </div>
            </label>
          </div>
          <div className='form-group'>
            <label
              htmlFor='preferredName'
              className='control control-checkbox'
              style={{ display: 'inline-block' }}
            >
              Show Nickname in my Profile
              <input
                type='checkbox'
                id='preferredName'
                name='preferredName'
                onChange={handleFormChange}
                ref={register()}
                defaultChecked={
                  user?.data.playerInfo?.preferredName === 'NICKNAME'
                }
              />
              <div className='control_indicator' />
            </label>
          </div>
          {user.data.type === UserType.PLAYER && (
            <>
              <div>
                <div
                  className='form-group'
                  style={{ width: '50%', paddingRight: '10px' }}
                >
                  <label htmlFor='height'>
                    My Height (meters)
                    <div>
                      <span className='icon icon-ruler' />
                      <Tooltip
                        title='Height should not exceed 2m'
                        placement='bottom'
                        visible={
                          errors.height?.type === 'max' ||
                          errors.height?.type === 'min'
                        }
                      >
                        <input
                          type='number'
                          onChange={handleFormChange}
                          ref={register({ max: 2, min: 0 })}
                          name='height'
                          id='height'
                          className='icon'
                          defaultValue={user?.data.playerInfo?.height}
                        />
                      </Tooltip>
                    </div>
                  </label>
                </div>

                <div className='form-group' style={{ width: '50%' }}>
                  <label htmlFor='preferredShirtNumber'>
                    My Jersey Number
                    <div>
                      <span className='icon icon-shirt' />
                      <Tooltip
                        title='Valid numbers: 1 to 99'
                        placement='bottom'
                        visible={
                          errors.preferredShirtNumber?.type === 'max' ||
                          errors.preferredShirtNumber?.type === 'min'
                        }
                      >
                        <input
                          className='icon'
                          id='preferredShirtNumber'
                          name='preferredShirtNumber'
                          ref={register({ min: 1, max: 99 })}
                          type='number'
                          onChange={handleFormChange}
                          defaultValue={
                            user?.data.playerInfo?.preferredShirtNumber
                          }
                        />
                      </Tooltip>
                    </div>
                  </label>
                </div>
              </div>

              <div>
                <div
                  className='form-group'
                  style={{ width: '50%', paddingRight: '10px' }}
                >
                  <div className='label'>My Position</div>
                  <div>
                    <span className='icon icon-shoe' />
                    <Controller
                      render={(property) => (
                        <Select
                          className='icon'
                          placeholder='Position'
                          defaultValue={
                            user?.data.playerInfo?.preferredPosition
                          }
                          onChange={(e) => {
                            property.onChange(e);
                            handleFormChange();
                          }}
                        >
                          <Option value='Goalkeeper'>Goalkeeper</Option>
                          <Option value='Fixo'>Fixo</Option>
                          <Option value='Ala/Fixo'>Ala/Fixo</Option>
                          <Option value='Ala'>Ala</Option>
                          <Option value='Ala/Pivot'>Ala/Pivot</Option>
                          <Option value='Pivot'>Pivot</Option>
                        </Select>
                      )}
                      id='preferredPosition'
                      name='preferredPosition'
                      control={control}
                    />
                  </div>
                </div>

                <div className='form-group' style={{ width: '50%' }}>
                  <div className='label'>Specialty</div>
                  <div>
                    <span className='icon icon-tatic' />
                    <Controller
                      render={(property) => (
                        <Select
                          className='icon'
                          placeholder='Specialty'
                          disabled={!preferredPosition.trim().length}
                          defaultValue={user?.data.playerInfo?.specialty}
                          onChange={(e) => {
                            property.onChange(e);
                            handleFormChange();
                          }}
                        >
                          {!preferredPosition.trim().length && (
                            <Option value='' disabled>
                              Select a position
                            </Option>
                          )}
                          {preferredPosition.trim().length > 0 &&
                            playerSpecialties
                              .get(preferredPosition)
                              ?.map((skill) => (
                                <Option key={skill} value={skill}>
                                  {skill}
                                </Option>
                              ))}
                        </Select>
                      )}
                      id='specialty'
                      name='specialty'
                      control={control}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {(user.data.type === UserType.PLAYER ||
            user.data.type === UserType.COACH ||
            user.data.type === UserType.CLUB_DIRECTOR) && (
            <>
              <div className='form-group'>
                <div className='label'>My Primary Club</div>
                <div>
                  <span className='icon icon-club' />
                  <Controller
                    className='icon'
                    as={
                      <Select placeholder='Primary Club'>
                        {club.data.clubs.map((clubItem) => (
                          <Option key={clubItem.id} value={clubItem.id}>
                            {clubItem.name}
                          </Option>
                        ))}
                      </Select>
                    }
                    onChange={handleFormChange}
                    id='primaryClubId'
                    name='primaryClubId'
                    control={control}
                    defaultValue={
                      club.data.clubs.some(
                        (clubItem) =>
                          clubItem.id === user?.data.playerInfo?.primaryClubId
                      )
                        ? user?.data.playerInfo?.primaryClubId
                        : ''
                    }
                  />
                </div>
              </div>
            </>
          )}
          <div className='form-group'>
            <div className='label'>Edit Your Avatar</div>
            <EditAvatarBox>
              <div className='content'>
                <div
                  className='avatar-image'
                  style={{
                    backgroundImage: userInfo.avatar
                      ? `url(${ConvertUtil.getMinioUrl(userInfo.avatar)})`
                      : `url(${
                          user.data.avatar
                            ? ConvertUtil.getMinioUrl(user.data.avatar)
                            : avatar_icon
                        })`,
                  }}
                />
                <div className='edit'>
                  <EditPhoto
                    imgURL={`${ConvertUtil.getMinioUrl(
                      userInfo.avatar
                    )}?t=rand`}
                    afterEdit={uploadEdited}
                    open={editPhotoOpen}
                    onClose={() => setEditPhotoOpen(false)}
                  />

                  <Upload
                    beforeUpload={beforeUpload}
                    style={{ width: '100%' }}
                    showUploadList={false}
                    accept='image/*'
                    customRequest={upload}
                    onChange={handleFormChange}
                  >
                    <Button style={{ marginTop: '50px' }}>Choose File</Button>
                  </Upload>
                </div>
              </div>
            </EditAvatarBox>
          </div>
        </div>
        <Button onClick={handleSubmit(onSubmit)}>Save Edit</Button>
      </form>
      {confirmLeave && (
        <ConfirmLeave>
          <div className='content'>
            You have unsaved changes.
            <br />
            Are you sure want to leave?
            <div style={{ margin: '10px 0' }}>
              <div
                className='bt'
                onClick={onClose}
                onKeyDown={onClose}
                role='button'
                tabIndex={0}
                aria-label='Close'
              >
                Leave
              </div>
              <div
                className='bt'
                onClick={() => setConfirmLeave(false)}
                onKeyDown={() => setConfirmLeave(false)}
                role='button'
                tabIndex={0}
                aria-label='Cancel'
              >
                Cancel
              </div>
              <div
                className='bt save-leave'
                onClick={() => {
                  handleSubmit(onSubmit)();
                  setConfirmLeave(false);
                }}
                onKeyDown={() => {
                  handleSubmit(onSubmit)();
                  setConfirmLeave(false);
                }}
                role='button'
                tabIndex={0}
                aria-label='Save and leave'
              >
                Save and leave
              </div>
            </div>
          </div>
        </ConfirmLeave>
      )}
    </WrapperBox>
  );
};

const WrapperBox = styled.div`
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  background-color: ${color.greyLight};
  max-height: 100vh;
  overflow-y: auto;
  @media (max-width: 768px) {
    padding-top: 40px;
  }
`;

const EditAvatarBox = styled.div`
  padding: 20px;
  margin-top: 10px;
  border-radius: 8px;
  background-color: ${color.greyDark};

  .content {
    display: flex;
    @media (max-width: 500px) {
      display: block;
    }
    .avatar-image {
      width: 150px;
      height: 150px;
      border-radius: 100px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin-right: 20px;
      background-color: #fff;
      @media (max-width: 500px) {
        margin: 0 auto;
      }
    }
    .edit {
      width: calc(100% - 180px);
      padding: 0 10px;
      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }
`;

const ConfirmLeave = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(37, 41, 46, 0.8);
  position: absolute;
  .content {
    top: 40%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    padding: 20px;
    background-color: ${color.greyLight};
    color: #fff;
    border-radius: 5px;
    z-index: 10;
    width: 75%;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    margin: 0 auto;
    .bt {
      display: inline-block;
      font-weight: 600;
      margin: 10px 10px;
      cursor: pointer;
      padding: 5px 15px;
      border-radius: 5px;
      color: #fff;
      &.save-leave {
        background-color: #fff;
        color: ${color.greyLight};
      }
    }
  }
`;

export default EditInfo;
