import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import ClubService from '../../services/Club/ClubService';
import { memberpoolFetchError, memberpoolFetchSuccess } from './actions';
import { MemberpoolActionTypes } from './types';

function* handleFetch(action: any) {
  try {
    const res = yield call(ClubService.getMemberpool, action.payload);
    if (!res.success) {
      yield put(memberpoolFetchError(res.error));
    } else {
      yield put(memberpoolFetchSuccess(res.data));
    }
  } catch (err) {
    // console.log(err);
  }
}

function* watchFetchRequest() {
  yield takeEvery(MemberpoolActionTypes.FETCH_REQUEST, handleFetch);
}

function* memberpoolSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default memberpoolSaga;
