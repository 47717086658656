import { produce } from 'immer';
import { Reducer } from 'redux';
import { UsersActionTypes, UsersState } from './types';

export const initialState: UsersState = {
  data: [],
  error: '',
  loading: false,
};

const reducer: Reducer<UsersState> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UsersActionTypes.FETCH_USERS_REQUEST: {
        draft.loading = true;
        break;
      }
      case UsersActionTypes.FETCH_USERS_SUCCESS: {
        draft.loading = false;
        draft.data = action.payload;
        break;
      }
      case UsersActionTypes.FETCH_USERS_ERROR: {
        draft.loading = false;
        draft.error = action.payload;
        break;
      }

      default:
        break;
    }
  });

export { reducer as usersReducer };
