import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  margin-top: ${toRem(16)};
  display: flex;
  flex-direction: column;
  row-gap: ${toRem(16)};

  .title {
    color: ${COLORS.grey50};
  }
`;

export const PermissionList = styled.div`
  display: grid;
  grid-template-columns: ${toRem(36)} 1fr;
  column-gap: ${toRem(24)};
  row-gap: ${toRem(24)};
  align-items: center;
`;

export const PermissionItem = styled.div`
  display: flex;
  flex-direction: column;
`;
