export default interface EventInvitesModel {
  id: string;
  creationDate: Date;
  eventId: string;
  receiverId: string;
  status: EventInvitesStatus;
  type: EventInvitesTypes;
}

export enum EventInvitesTypes {
  EVENT_TO_CLUB = 'EVENT_TO_CLUB',
  EVENT_TO_REFEREE = 'EVENT_TO_REFEREE',
}

export enum EventInvitesStatus {
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED',
  INVITED = 'INVITED',
}
