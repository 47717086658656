import Axios, { AxiosRequestConfig } from 'axios';
import { DeepPartial } from 'util/types';
import { PatchBaseUser } from 'redux/v3/baseUsers/types';
import { UserDocumentsDTO } from '../../models/User/UserDocumentsDTO';
import { UserFilter } from '../../models/User/UserFilter';
import { User } from '../../models/User/UserModel';
import { NewUser } from '../../redux/newUser/types';
import { ConfigApi } from '../config.api';
import APIServices from '../util/ApiServices';
import PageResponse from '../util/PageResponse';
import ServiceResponse from '../util/ServiceResponse';

class UserServices {
  private static configHeader() {
    return {
      'Content-type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('kc_token')}`,
    };
  }

  public static async getAll(
    urlParams?: URLSearchParams
  ): Promise<ServiceResponse<PageResponse<User>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      headers: UserServices.configHeader(),
      url: 'users',
      method: 'GET',
      params: urlParams,
    };
    return APIServices.request<PageResponse<User>>(axiosConfig);
  }

  public static async search(
    urlParams?: URLSearchParams,
    filters?: UserFilter
  ): Promise<ServiceResponse<PageResponse<User>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: 'users/search',
      method: 'POST',
      params: urlParams,
      data: filters,
    };

    return APIServices.request<PageResponse<User>>(axiosConfig);
  }

  public static async export(data: {
    query: string;
    urlParams?: URLSearchParams;
    typeUser?: string[];
    clubIdList?: string[];
    membershipIdList?: string[];
    birthYear?: number[];
  }): Promise<any> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfigExport(),
      headers: UserServices.configHeader(),
      url: 'users/export',
      method: 'POST',
      params: data.urlParams,
      data: {
        quickSearch: data.query,
        typeList: data.typeUser,
        clubIdList: data.clubIdList,
        membershipIdList: data.membershipIdList,
        birthYear: data.birthYear,
      },
    };
    return Axios.request(axiosConfig);
  }

  public static async quickSearch(params: {
    urlParams?: URLSearchParams;
    body?: any;
  }): Promise<ServiceResponse<PageResponse<User>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: 'users/search',
      method: 'POST',
      params: params.urlParams,
      data: params.body,
    };
    return APIServices.request<PageResponse<User>>(axiosConfig);
  }

  public static async getUser(id: string): Promise<ServiceResponse<User>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `users/${id}`,
      method: 'GET',
    };
    return APIServices.request<User>(axiosConfig);
  }

  public static async getUserDocuments(
    id: string
  ): Promise<ServiceResponse<UserDocumentsDTO>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `users/${id}/documents`,
      method: 'GET',
    };
    return APIServices.request<UserDocumentsDTO>(axiosConfig);
  }

  public static async newUser(newUser: NewUser): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      headers: UserServices.configHeader(),
      url: 'users',
      method: 'POST',
      data: newUser,
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async updateExtraInfo(
    newUser: NewUser,
    id: string
  ): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `users/${id}`,
      method: 'PUT',
      data: newUser,
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async updateUser(
    user: DeepPartial<PatchBaseUser>,
    id?: string,
    userType?: string
  ): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `users/${id}`,
      method: 'PUT',
      data: user,
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async membershipUpgrade(
    userId: string,
    membershipId: string,
    log: string
  ): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `users/${userId}/membership-upgrade/${membershipId}`,
      method: 'POST',
      data: log,
    };
    return APIServices.request<any>(axiosConfig);
  }
}

export default UserServices;
