import * as React from 'react';
import styled from 'styled-components';
import { GamePeriod } from '../../../../../../models/GameEvent/GameEventModel';
import { color, font } from '../../../../../../styles/styleVariables';
import ConvertUtil from '../../../../../../util/ConvertUtil';

interface ITimerClockProps {
  period: GamePeriod;
  gameDuration: number;
  timerInSeconds: number;
  timerInSecondsTotal: number;
}

const TimerClock: React.FunctionComponent<ITimerClockProps> = (props) => {
  const { period, gameDuration, timerInSeconds, timerInSecondsTotal } = props;
  return (
    <TimeBox>
      <div style={{ textAlign: 'center', fontWeight: 600 }}>
        {period === GamePeriod.FIRST_HALF && '1st Half'}
        {period === GamePeriod.SECOND_HALF && '2nd Half'}
        {period === GamePeriod.FIRST_EXTRA_TIME && '1st Extra Time'}
        {period === GamePeriod.SECOND_EXTRA_TIME && '2nd Extra Time'}
      </div>
      <div className='timer' style={{ textAlign: 'center' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '80px' }}>
            {gameDuration - timerInSeconds > 0
              ? ConvertUtil.secToTime(
                  gameDuration - timerInSeconds - timerInSecondsTotal
                ).minutes
              : '00'}
          </div>
          :
          <div style={{ width: '80px' }}>
            {gameDuration - timerInSeconds > 0
              ? ConvertUtil.secToTime(
                  gameDuration -
                    Math.round(timerInSeconds) -
                    Math.round(timerInSecondsTotal)
                ).seconds
              : '00'}
          </div>
        </div>
      </div>
    </TimeBox>
  );
};

const TimeBox = styled.div`
  .timer {
    font-size: 48px;
    font-weight: 600;
    padding: 0 10px;
    text-align: center;
  }
  .actions {
    display: flex;
    .bt {
      width: 50%;
      padding: 10px;
      border-radius: 5px;
      text-align: center;
      background-color: ${color.silverLight};
      margin-right: 5px;
      color: ${color.greyDark};
      font-family: ${font.druk};
      text-transform: uppercase;
      font-size: 10px;
      &.disabled {
        opacity: 0.3;
      }
    }
  }
  .time-edit {
    .edit-period {
      display: flex;
      justify-content: center;
      .item {
        font-weight: 600;
        font-size: 10px;
        margin: 3px;
        padding: 5px 10px;
        background-color: ${color.silverLight};
        border-radius: 5px;
        text-align: center;
        &.active {
          background-color: ${color.orange};
        }
      }
    }
  }
`;

export default TimerClock;
