import React, { useMemo } from 'react';
import { Dot } from '@icon-park/react';

import { BodyS, TitleH4 } from 'styles/v3/variables';
import { useGetUserStats } from 'hooks/v3/users/useGetUserStats/useGetUserStats';
import DateUtil from 'util/DateUtil';
import { HeaderProps } from './types';

import * as S from './styles';
import { Membership } from '../../../..';

export const Header = ({ user }: HeaderProps) => {
  const { data: userStats } = useGetUserStats(user.id);

  const getGender = (currentGender?: string) => {
    switch (currentGender) {
      case 'MALE':
        return 'Boy';
      case 'FEMALE':
        return 'Girl';
      default:
        return 'Other';
    }
  };

  return (
    <S.Container>
      <TitleH4>
        {user.firstName} {user.lastName}
      </TitleH4>
      <Membership size='s' membership={user.membership?.type || 'COMMUNITY'} />
      <S.StatsContainer>
        <BodyS>{getGender(user.gender)}</BodyS>
        <Dot />
        <BodyS>
          {DateUtil.getYearFromDateString(user.birthDate?.toString())}
        </BodyS>
        <Dot />
        <BodyS>XP</BodyS>
        <BodyS>{userStats?.xp || 0}</BodyS>
      </S.StatsContainer>
    </S.Container>
  );
};
