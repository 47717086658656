import * as React from 'react';

import { BodyMBold, BodyS, BodyXS, ColorsInterface } from 'styles/v3/variables';
import { RoundedCardProps } from '../RoundedCard/RoundedCard';
import * as S from './styles';
import SimpleCard from '../SimpleCard/SimpleCard';

interface CompactStripeCardProps extends Omit<RoundedCardProps, 'orientation'> {
  title: string;
  subtitle?: string;
  description?: string;
  stripeColor: string;
  titleColor?: keyof ColorsInterface;
  subtitleColor?: keyof ColorsInterface;
  descriptionColor?: keyof ColorsInterface;
  hasChildren?: boolean;
  width?: string;
}

const CompactStripeCard: React.FC<CompactStripeCardProps> = ({
  title,
  subtitle,
  description,
  titleColor,
  subtitleColor,
  descriptionColor,
  stripeColor,
  size,
  width,
  children,
  hasChildren,
  ...props
}) => {
  return (
    <S.StripeCardWrapper $width={width} className='card-detail'>
      <SimpleCard {...props} orientation='row'>
        <S.Stripe $color={stripeColor} />
        <S.InfoContainer>
          <BodyMBold $color={titleColor ?? 'grey50'} >{title}</BodyMBold>
          {!!subtitle && <BodyS $color={subtitleColor ?? 'grey50'}>{subtitle}</BodyS>}
          {!!description && <BodyXS $color={descriptionColor ?? 'grey50'}>{description}</BodyXS>}
        </S.InfoContainer>

        {hasChildren && <S.ChildrenWrapper>{children}</S.ChildrenWrapper>}
      </SimpleCard>
    </S.StripeCardWrapper>
  );
};

export default CompactStripeCard;
