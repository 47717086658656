import { ExportOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Checkbox, message, Table } from 'antd';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import FilterUtil from 'util/FilterUtil';
import LightBox from '../../../components/base/LightBox/LightBox';
import Button from '../../../components/uielements/Button/Button';
import {
  ParticipationStatus,
  RosterListing,
} from '../../../models/Roster/RosterModel';
import EventService from '../../../services/Event/EventService';
import ConvertUtil from '../../../util/ConvertUtil';
import { AgeDivision, SpecialDivision } from '../../models/AgeDivision';
import CreateDivisionModal from './RostersList/CreateDivisionModal';

interface IRostersListProps {}

const RostersList: React.FunctionComponent<IRostersListProps> = (props) => {
  const columns = [
    {
      title: 'Select',
      render: (text: any, record: any) => (
        <div
          style={{
            padding: '4px 5px 4px 5px',
            textAlign: 'center',
            borderRadius: '3px',
            backgroundColor: '#8f8f8f',
          }}
        >
          <Checkbox
            checked={isRosterSelected(record.id)}
            onClick={() => toggleRoster(record.id)}
          />
        </div>
      ),
    },
    {
      title: 'Team Name',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a: any, b: any) => a.name.localeCompare(b.name),
        multiple: 4,
      },
    },
    {
      title: 'Club Name',
      key: 'club.name',
      dataIndex: ['club', 'name'],
      sorter: {
        compare: (a: any, b: any) => a.club.name.localeCompare(b.club.name),
        multiple: 3,
      },
    },
    {
      title: 'Submission date',
      key: 'submittedDate',
      dataIndex: 'submittedDate',
      sorter: {
        compare: (a: any, b: any) =>
          moment(a.submittedDate)
            .format('YYYY-MM-DD')
            .localeCompare(moment(b.submittedDate).format('YYYY-MM-DD')),
        multiple: 6,
      },
      render: (text: any, record: any) => (
        <div>{moment(text).format('MM-DD-YYYY')}</div>
      ),
    },
    {
      title: 'Gender',
      key: 'ageDivision.gender',
      dataIndex: ['ageDivision', 'gender'],
      sorter: {
        compare: (a: any, b: any) =>
          a.ageDivision.gender.localeCompare(b.ageDivision.gender),
        multiple: 5,
      },
      filters: [
        {
          text: 'COED',
          value: 'COED',
        },
        {
          text: 'MALE',
          value: 'MALE',
        },
        {
          text: 'FEMALE',
          value: 'FEMALE',
        },
      ],
      onFilter: (value: any, record: any) =>
        record.ageDivision.gender.indexOf(value) !== -1,
    },
    {
      title: 'Age Division',
      key: 'ageDivision.years',
      dataIndex: ['ageDivision', 'years'],
      sorter: {
        compare: (a: any, b: any) =>
          a.ageDivision.years
            .toString()
            .localeCompare(b.ageDivision.years.toString()),
        multiple: 4,
      },
      filters: Array.from({ length: 20 }, (_, i) => ({
        text: (2020 - i).toString(),
        value: 2020 - i,
      })),
      onFilter: (value: any, record: any) =>
        record.ageDivision.years.indexOf(value) !== -1,
      render: (text: any, record: any) => (
        <div>
          {record?.ageDivision?.years[0] === 1900
            ? 'ADULT'
            : record?.ageDivision?.years.sort().join(', ')}
        </div>
      ),
    },
    {
      title: '',
      key: 'print',
      dataIndex: 'type',

      // eslint-disable-next-line react/display-name
      render: (text: any, record: any) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to={`./roster-edit/${record.id}`}>
            <div className='bt-action i-icon-edit' />
          </Link>

          <a href={`/roster-print/${record.id}`} target='_blank'>
            <div className='bt-action i-icon-print' />
          </a>
        </div>
      ),
    },
  ];
  const { eventId } = useParams();
  const [rostersList, setRostersList] = useState<RosterListing[]>([]);
  const [filteredRostersList, setFilteredRostersList] = useState<
    RosterListing[]
  >([]);
  const [selectedRosters, setSelectedRosters] = useState<string[]>([]);
  const [selectedSpecialDivision, setSelectedSpecialDivision] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [filterAgeDivision, setFilterAgeDivision] = useState<AgeDivision>();
  const [isCreateDivisionModalOpen, setIsCreateDivisionModalOpen] = useState(
    false
  );
  const [specialDivisions, setSpecialDivisions] = useState<SpecialDivision[]>(
    []
  );

  const toggleRoster = (rosterId: string) => {
    if (selectedRosters.find((item) => item === rosterId)) {
      setSelectedRosters(selectedRosters.filter((item) => item !== rosterId));
    } else {
      setSelectedRosters([...selectedRosters, rosterId]);
    }
  };

  const isRosterSelected = (rosterId: string): boolean => {
    if (selectedRosters.find((item) => item === rosterId)) {
      return true;
    }
    return false;
  };

  const updateSpecialList = () => {
    EventService.detail(eventId).then((res) => {
      if (res.success) {
        setSpecialDivisions(res.data.specialDivisions || []);
      }
    });
  };

  useEffect(() => {
    if (eventId) {
      EventService.getRosters(
        eventId,
        new URLSearchParams(`status=${ParticipationStatus.APPROVED}`)
      ).then((res) => {
        if (res.success) {
          setRostersList(res.data);
          setFilteredRostersList(res.data);
        }
      });
      updateSpecialList();
    }
  }, [eventId]);

  const handleSearchChange = (
    event: React.FormEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setFilterQuery(event.currentTarget.value);
  };

  useEffect(() => {
    if (!selectedSpecialDivision) {
      if (filterQuery) {
        const filtered: RosterListing[] = [
          ...rostersList.filter(
            (item) =>
              (item.name
                ?.toLocaleLowerCase()
                .includes(filterQuery.toLocaleLowerCase()) ||
                item.club.name
                  ?.toLocaleLowerCase()
                  .includes(filterQuery.toLocaleLowerCase())) &&
              (!filterAgeDivision ||
                FilterUtil.objectCompares(item.ageDivision, filterAgeDivision))
          ),
        ];
        setFilteredRostersList(filtered);
      } else {
        setFilteredRostersList(
          rostersList.filter(
            (item) =>
              !filterAgeDivision ||
              FilterUtil.objectCompares(item.ageDivision, filterAgeDivision)
          )
        );
      }
    }
  }, [filterQuery, filterAgeDivision, selectedSpecialDivision]);

  const [loadingExport, setLoadingExport] = useState(false);
  const exportRecords = () => {
    setLoadingExport(true);

    EventService.exportRosters(eventId)
      .then((response) =>
        FileSaver.saveAs(new Blob([response.data]), 'exported-rosters.csv')
      )
      .then(() => setLoadingExport(false))
      .catch((error) => {
        setLoadingExport(false);
        Sentry.captureException(error, error.response?.data?.message);
        message.error({
          content: error.response?.data?.message,
          duration: 5,
        });
      });
  };

  const handleAgeDivisionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const special = event.target.value.split('|');
    if (special[0] === 'special') {
      setSelectedSpecialDivision(special[1]);
      filterSpecialDivision(special[1]);
    } else {
      setSelectedSpecialDivision('');
      setFilterAgeDivision(
        ConvertUtil.convertStringAgeDivision(event.currentTarget.value)
      );
    }
  };

  const filterSpecialDivision = (filterName: string) => {
    const rostersToFilter = specialDivisions.find(
      (item) => item.name === filterName
    )?.rosterList;
    setFilteredRostersList(
      rostersList.filter((item) => rostersToFilter?.includes(item.id))
    );
  };
  return (
    <div>
      <h1>Rosters</h1>
      <div className='group-box'>
        {!selectedSpecialDivision && (
          <div className='form-group'>
            <span className='icon icon-search' />
            <input
              name='query'
              value={filterQuery}
              onChange={handleSearchChange}
              type='text'
              className='icon'
              placeholder='Search'
            />
          </div>
        )}

        <div className='form-group'>
          <select defaultValue='' onChange={handleAgeDivisionChange}>
            <option value='' disabled>
              Select Age Division
            </option>
            <option value=''>All Divisions</option>
            {rostersList
              .map(
                (item) =>
                  `${item.ageDivision?.gender} ${
                    item.ageDivision?.years[0] === 1900
                      ? 'ADULT'
                      : item.ageDivision?.years.sort().join(', ')
                  }, ${item.ageDivision?.rule}`
              )
              .filter((value, index, self) => self.indexOf(value) === index)
              .map((item) => (
                <option key={item}>{item}</option>
              ))}
            <option value='' disabled>
              &nbsp;&nbsp;&nbsp;Special Divisions
            </option>
            {specialDivisions
              .sort((a, b) =>
                a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1
              )
              .map((item) => {
                return (
                  <option value={'special|' + item.name}>{item.name}</option>
                );
              })}
          </select>
        </div>
        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <Button
            loading={loadingExport}
            onClick={() => {
              exportRecords();
            }}
            style={{ marginRight: '5px', fontSize: '9px' }}
          >
            <ExportOutlined /> Export records
          </Button>

          <Button
            disabled={selectedRosters.length < 1}
            onClick={() => setIsCreateDivisionModalOpen(true)}
            style={{ marginRight: '5px' }}
          >
            Add selected rosters to Division
          </Button>
        </div>
        <Table
          pagination={{ position: ['bottomCenter'], defaultPageSize: 10 }}
          dataSource={filteredRostersList}
          columns={columns}
          rowKey={(record) => record.id}
        />
        <LightBox
          isOpen={isCreateDivisionModalOpen}
          onClose={() => {
            setIsCreateDivisionModalOpen(false);
            updateSpecialList();
          }}
        >
          <CreateDivisionModal
            selectedRosters={selectedRosters}
            specialDivisions={specialDivisions}
            closeModal={() => {
              setIsCreateDivisionModalOpen(false);
              updateSpecialList();
            }}
            //ageDivisions={getUniqueAgeDivisionFromRosters(rostersList)}
          />
        </LightBox>

        <div className='italic text-grey-light'>
          *Only the approved Rosters will be displayed.
        </div>
      </div>
    </div>
  );
};

export default RostersList;
