import React from 'react';

import ConvertUtil from 'util/ConvertUtil';
import { TitleH4 } from 'styles/v3/variables';
import { CheckoutHeaderProps } from './types';
import * as S from './styles';

export const CheckoutHeader = ({ image, name }: CheckoutHeaderProps) => {
  return (
    <S.Container>
      <S.Image $src={ConvertUtil.getMinioUrl(image)} />
      <TitleH4>{name}</TitleH4>
    </S.Container>
  );
};
