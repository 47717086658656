import { AxiosRequestConfig } from 'axios';
import { ConfigApi } from '../config.api';
import APIServices from '../util/ApiServices';
import ServiceResponse from '../util/ServiceResponse';

class PaymentServices {
  formatPrice = () => {
    const numberFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'usd',
      currencyDisplay: 'symbol',
    });
    // const parts = numberFormat.formatToParts(amount);
    // let zeroDecimalCurrency = true;
    // for (const part of parts) {
    //   if (part.type === 'decimal') {
    //     zeroDecimalCurrency = false;
    //   }
    // }
    // const convertedAmount = zeroDecimalCurrency ? amount : amount / 100;
    // const total: any = (quantity * convertedAmount).toFixed(2);
    return numberFormat.format(100);
  };

  public static async getPaymentKey(): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: '/payments/',
      method: 'GET',
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async fetchCheckoutSession(
    membershipId: string,
    accountId: string,
    url?: string
  ): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/payments/checkout/${membershipId}/${accountId}`,
      method: 'POST',
      data: { url },
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async fetchCheckoutMembershipSession(
    url?: string
  ): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: '/payments/checkout-membership',
      method: 'POST',
      data: { url },
    };
    return APIServices.request<any>(axiosConfig);
  }
}

export default PaymentServices;
