import React from 'react';
import { MembersPoolTable } from './components/MembersPoolTable/MembersPoolTable';

export const MembersPoolTab = () => {
  return (
    <>
      <MembersPoolTable />
    </>
  );
};
