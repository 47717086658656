import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import UserServices from '../../services/User/UserApi';
import { userFetchError, userFetchSuccess } from './actions';
import { UserActionTypes } from './types';

function* handleFetch(action: any) {
  try {
    const res = yield call(UserServices.getUser, action.payload);
    if (!res.success) {
      yield put(userFetchError(res.error));
    } else {
      yield put(userFetchSuccess(res.data));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      // yield put(userFetchError(err.stack));
    } else {
      // yield put(userFetchError('An unknown error occured.'));
    }
  }
}

function* handleUpdate(action: any) {
  try {
    const res = yield call(
      UserServices.updateUser,
      action.payload,
      action.payload.id
    );
    if (!res.success) {
      yield put(userFetchError(res.error));
    } else {
      yield put(userFetchSuccess(res.data));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      // yield put(userFetchError(err.stack));
    } else {
      // yield put(userFetchError('An unknown error occured.'));
    }
  }
}

function* watchFetchRequest() {
  yield takeEvery(UserActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchUpdateRequest() {
  yield takeEvery(UserActionTypes.UPDATE, handleUpdate);
}

function* userSaga() {
  yield all([fork(watchFetchRequest), fork(watchUpdateRequest)]);
}

export default userSaga;
