import BronzeEventTier from 'assets/imgs/icons/bronze-event-tier.svg';
import SilverEventTier from 'assets/imgs/icons/silver-event-tier.svg';
import GoldEventTier from 'assets/imgs/icons/gold-event-tier.svg';
import PlatinumEventTier from 'assets/imgs/icons/platinum-event-tier.svg';
import DiamondEventTier from 'assets/imgs/icons/diamond-event-tier.svg';

export const tierData = [
  {
    tier: 'Bronze',
    minMultiplier: 1,
    maxMultiplier: 1,
    image: BronzeEventTier,
    multiplierColor: '#C6826D',
  },
  {
    tier: 'Silver',
    minMultiplier: 2,
    maxMultiplier: 4,
    image: SilverEventTier,
    multiplierColor: '#F1F2F2',
  },

  {
    tier: 'Gold',
    minMultiplier: 5,
    maxMultiplier: 7,
    image: GoldEventTier,
    multiplierColor: '#F7D29B',
  },
  {
    tier: 'Platinum',
    minMultiplier: 8,
    maxMultiplier: 11,
    image: PlatinumEventTier,
    multiplierColor: '#65FBFE',
  },
  {
    tier: 'Diamond',
    minMultiplier: 12,
    maxMultiplier: Infinity,
    image: DiamondEventTier,
    multiplierColor: '#24A3F9',
  },
];
