import { PaginationUsersModel } from 'models/Pagination/Pagination';

export const convertUsersURLParams = (pagination: PaginationUsersModel) => {
  const urlParams = new URLSearchParams();

  urlParams.append('page', pagination.current.toString());
  urlParams.append('size', pagination.size.toString());

  if (pagination.sort) {
    urlParams.append('sort', pagination.sort);
  }

  return urlParams;
};
