import styled from 'styled-components';
import { color } from '../../../../../styles/styleVariables';

export const TeamBtWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
`;

export const Card = styled.div`
  border-radius: 10px;
  padding: 10px;
  background-color: ${color.silverLight};
  display: flex;
  align-items: center;
  width: 49%;
  margin: 10px 0;
  height: 150px;
  cursor: pointer;
  @media (max-width: 500px) {
    width: 100%;
    height: 100px;
  }
  &:hover {
    background-color: ${color.orange};
  }
  &.active {
    background-color: ${color.orange};
  }
  .img {
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-right: 10px;
    background-color: #fff;
    background-size: cover;
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    img {
    }
  }

  .text {
    text-align: center;
    width: calc(100% - 40px);
  }
`;

export const RosterWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
`;

export const PlayerCard = styled.div`
  margin-right: 10px;
  margin-bottom: 30px;
  .number {
    padding: 0 10px;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    .edit {
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 10px;
      background-color: #fff;
      color: ${color.greyDark};
    }
  }
  .coach {
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
  }
  .picture {
    .img {
      width: 180px;
      height: 180px;
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
      filter: grayscale(0);
      transition: all 0.3s;
      &.disabled {
        filter: grayscale(100%);
        opacity: 0.3;
      }
      img {
        width: 100%;
      }
    }
    .text {
      padding: 10px 0;
    }
  }
  .btn {
    padding: 10px;
    background-color: ${color.greyLight};
    text-align: center;
    border-radius: 5px;
    width: 49%;
    cursor: pointer;
    font-weight: 600;
    &.active {
      border: solid 2px #fff;
    }
  }
`;
