import { Col, message, Row } from 'antd';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import Steps from '../../components/Steps/Steps';
import CertificateUpload from '../../components/Wizard/CertificateUpload/CertificateUpload';
import { ApplicationState } from '../../redux/store';
import { UserType } from '../../redux/user/types';
import { NextButtonsWrapper, TopHeader } from './WizardComponents.style';

const WizardCertificate: React.FunctionComponent = (props) => {
  const { newUser } = useSelector(
    (state: ApplicationState) => state.newUser
  ).data;

  const history = useHistory();
  function nextScreen() {
    if (
      newUser?.extraInfo?.certificate === '' ||
      newUser?.extraInfo?.certificate === undefined
    ) {
      message.warning({
        content: 'You need to upload your document',
        duration: 4,
      });
    } else {
      history.push('/wizard/payment');
    }
  }

  return (
    <div>
      <TopHeader>
        <h5>COMPLETE THE REGISTRATION TO JOIN THE UNITED FUTSAL COMMUNITY!</h5>
        <h1>UPLOAD SAFESPORT CERTIFICATE</h1>
      </TopHeader>
      <Row>
        <Col xxl={18} lg={18} md={24} className='column-wizard'>
          <Steps
            activeStep={newUser?.type === UserType.CLUB_DIRECTOR ? 5 : 6}
          />
          <div style={{ textAlign: 'center' }}>
            <h3>Instructions</h3>
            <div style={{ textAlign: 'justify' }}>
              <p>
                <b>United Futsal</b> is an official member of US Soccer and as
                such, all registered members of United Futsal must comply with
                the player safety requirements imposed by US Soccer. US Soccer
                REQUIRES every adult coach (18 years or older) who coaches,
                directs or supervises minors (younger than 18 years) go online
                and take the three (3) SafeSport Training Modules and obtain
                their SafeSport Certificate. Once you complete all three (3)
                Modules and pass the test, you will be able to download your
                SafeSport Trained Certificate and upload it to the Vault.
                Completion of the three (3) Modules and test will take you 45-60
                minutes. Your SafeSport Trained Certificate will be valid for 2
                years from the date of its issuance. Upon expiration of your
                initial 2 years of your certification, you will be required
                every year thereafter to go back online and take the SafeSport
                Refresher Course and download your SafeSport Refresher
                Certificate and upload it to the Vault.
                <br />
                You can access the &nbsp;
                <b>SafeSport Training Modules</b> using this link:
                <br />
                <b>
                  <a
                    href='https://safesporttrained.org/#/signup'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    https://safesporttrained.org/#/signup
                  </a>
                </b>
                &nbsp; along with this Access Code: <b>YC3E-6P5G-YYIL-CS2M</b>
              </p>
              <p>
                Check an example of the certificate on the picture below. This
                certificate is only valid for a period of 2 years. We will not
                accept certificates that are older than this.
              </p>
            </div>
          </div>

          <CertificateUpload data-testid='cetificate-upload-wrapper' />
          {/* <BoxInfo>
            <h5>WHY DO WE NEED THIS?</h5>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Recusandae, ipsa ipsum iure illum provident odit non voluptatum,
              laudantium itaque veritatis quasi doloremque quia. Minima vitae
              quod libero nostrum ipsam excepturi!
            </p>
          </BoxInfo> */}
          <NextButtonsWrapper data-testid='wizard-nav-wrapper'>
            <Link to='/wizard/additionalinfo'>
              <div style={{ margin: '0 10px' }}> ◄ PREVIOUS STEP</div>
            </Link>
            <div
              className={`link ${
                !newUser?.extraInfo?.certificate ? 'disabled' : ''
              }`}
              onClick={() => nextScreen()}
              onKeyDown={() => nextScreen()}
              role='button'
              tabIndex={0}
              aria-label='Next Step'
              style={{ margin: '0 10px' }}
            >
              NEXT STEP ►
            </div>
          </NextButtonsWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(WizardCertificate);
