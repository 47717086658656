import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

interface Dots {
  isEnabled?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 8px;
  border-radius: 8px;
  background-color: ${COLORS.grey950};
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 2px;
`;

export const MultiplierWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 16px;
`;

export const DotsWrapper = styled.div`
  display: flex;
  gap: 2px;
`;

export const Dots = styled.div<Dots>`
  width: 8px;
  height: 8px;

  background-color: ${({ isEnabled }) =>
    isEnabled ? COLORS.brandPrimary : COLORS.grey600};

  border-radius: 50%;
`;

export const MultiplierNumber = styled.div<Dots>`
  width: 46px;
  height: 42px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background-color: ${COLORS.grey900};

  p {
    color: ${({ isEnabled }) =>
      isEnabled ? COLORS.brandPrimary : COLORS.grey600};
  }
`;
