import { UserType } from '../user/types';

export interface Membership {
  country: string;
  currency: string;
  id: string;
  name: string;
  price: number;
  type: string;
  userType: UserType;
}

export interface MembershipsState {
  readonly loading: boolean;
  readonly data: Membership[];
}

export enum MembershipsActionTypes {
  FETCH_REQUEST = '@@memberships/FETCH_REQUEST',
  FETCH_SUCCESS = '@@memberships/FETCH_SUCCESS',
  FETCH_ERROR = '@@memberships/FETCH_ERROR',
}
