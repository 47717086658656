import React from 'react';

import { CheckoutBoxProps } from './types';
import * as S from './styles';
import { ClubSelect } from './components/ClubSelect/ClubSelect';
import { DivisionItem } from './components/DivisionItem/DivisionItem';
import { DivisionSummary } from './components/DivisionSummary/DivisionSummary';

export const CheckoutBox = ({ order }: CheckoutBoxProps) => {
  return (
    <S.Container>
      {order.item.b2bEventRosterFee && (
        <>
          <S.Box>
            <ClubSelect club={order.item.b2bEventRosterFee.club} />
            <S.DivisionList>
              {order.item.b2bEventRosterFee.divisions.map((d) => (
                <DivisionItem key={d.id} division={d} />
              ))}
            </S.DivisionList>
            <S.HR />
            <DivisionSummary
              total={order.total}
              discount={order.discount}
              subtotal={order.subtotal}
            />
          </S.Box>
        </>
      )}
    </S.Container>
  );
};
