import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import MembershipsServices from '../../services/Memberships/MembershipsServices';
import { membershipsFetchError, membershipsFetchSuccess } from './actions';
import { MembershipsActionTypes } from './types';

function* handleFetch() {
  try {
    const res = yield call(MembershipsServices.getMemberships);
    if (!res.success) {
      yield put(membershipsFetchError(res.error));
    } else {
      yield put(membershipsFetchSuccess(res.data.content));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      // yield put(membershipsFetchError(err.stack));
    } else {
      // yield put(membershipsFetchError('An unknown error occured.'));
    }
  }
}

function* watchFetchRequest() {
  yield takeEvery(MembershipsActionTypes.FETCH_REQUEST, handleFetch);
}

function* membershipsSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default membershipsSaga;
