import * as React from 'react';

import { BodyM, BodyS } from 'styles/v3/variables';
import BasicAvatar, { BasicAvatarProps } from '../BasicAvatar/BasicAvatar';
import * as S from './styles';

export interface AvatarTextProps extends BasicAvatarProps {
  text: string;
  caption?: string;
}

const AvatarText: React.FC<AvatarTextProps> = ({ text, caption, ...props }) => {
  return (
    <S.Container>
      <BasicAvatar {...props} />
      <S.TextWrapper>
        <BodyM className='avatar-text'>{text}</BodyM>
        {caption && <BodyS $color='grey400'>{caption}</BodyS>}
      </S.TextWrapper>
    </S.Container>
  );
};

export default AvatarText;
