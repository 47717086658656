import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import DateUtil from 'util/DateUtil';
import { RequestStatus } from 'models/Request/RequestModel';
import UserService from 'services/v3/User/UserService';

import { ApplicationState } from 'redux/store';
import { DocumentStatusLabel } from 'redux/v3/baseUsers/types';
import { baseUserSetData } from 'redux/v3/baseUsers/actions';

import DocumentCard from 'components/v3/Cards/DocumentCard/DocumentCard';
import ProfilePhotoModal from 'components/v3/UploadModal/ProfilePhotoModal';
import ProofOfAgeModal from 'components/v3/UploadModal/ProofOfAgeModal';
import SafeSportModal from 'components/v3/UploadModal/SafesportModal';

import { BodyXL } from 'styles/v3/variables';

import * as S from './styles';
import { DocumentStatusViewModal } from '../../../../../../components/v3/ModalInformations/DocumentStatusViewModal/DocumentStatusViewModal';

export type ModalState = 'photo' | 'age' | 'safesport' | 'ageView' | 'photoView' | '';

const Documents = () => {
  const dispatch = useDispatch();
  const { userId, data } = useSelector(
    (state: ApplicationState) => state.baseUsers
  );
  const userData = data.find((u) => u?.id === userId);

  const [modalId, setModalId] = React.useState<ModalState>('');
  const [photoStatus, setPhotoStatus] = React.useState<DocumentStatusLabel>(
    DocumentStatusLabel.EMPTY
  );
  const [ageStatus, setAgeStatus] = React.useState<DocumentStatusLabel>(
    DocumentStatusLabel.EMPTY
  );
  const [
    safesportStatus,
    setSafesportStatus,
  ] = React.useState<DocumentStatusLabel>(DocumentStatusLabel.EMPTY);

  React.useEffect(() => {
    switch (userData?.photoVerified) {
      case RequestStatus.PENDING:
        setPhotoStatus(DocumentStatusLabel.PENDING);
        break;
      case RequestStatus.APPROVED:
        setPhotoStatus(DocumentStatusLabel.APPROVED);
        break;
      case RequestStatus.DECLINED:
        setPhotoStatus(DocumentStatusLabel.DECLINED);
        break;
      default:
        setPhotoStatus(DocumentStatusLabel.EMPTY);
        break;
    }
  }, [userData?.photoVerified]);

  React.useEffect(() => {
    switch (userData?.ageVerified) {
      case RequestStatus.PENDING:
        setAgeStatus(DocumentStatusLabel.PENDING);
        break;
      case RequestStatus.APPROVED:
        setAgeStatus(DocumentStatusLabel.APPROVED);
        break;
      case RequestStatus.DECLINED:
        setAgeStatus(DocumentStatusLabel.DECLINED);
        break;
      default:
        setAgeStatus(DocumentStatusLabel.EMPTY);
        break;
    }
  }, [userData?.ageVerified]);

  React.useEffect(() => {
    switch (userData?.documentationVerified) {
      case RequestStatus.PENDING:
        setSafesportStatus(DocumentStatusLabel.PENDING);
        break;
      case RequestStatus.APPROVED:
        setSafesportStatus(DocumentStatusLabel.APPROVED);
        break;
      case RequestStatus.DECLINED:
        setSafesportStatus(DocumentStatusLabel.DECLINED);
        break;
      case RequestStatus.EXPIRED:
        setSafesportStatus(DocumentStatusLabel.EXPIRED);
        break;
      default:
        setSafesportStatus(DocumentStatusLabel.EMPTY);
        break;
    }
  }, [userData?.documentationVerified]);

  React.useEffect(() => {
    if (userData?.safesportValidUntil) {
      const isExpired = DateUtil.isExpired(userData?.safesportValidUntil);
      if (isExpired) {
        setSafesportStatus(DocumentStatusLabel.EXPIRED);
      } else if (userData?.documentationVerified === RequestStatus.PENDING) {
        setSafesportStatus(DocumentStatusLabel.PENDING);
      }
    }
  }, [userData?.safesportValidUntil, userData?.documentationVerified]);

  React.useEffect(() => {
    (async () => {
      const { data: usersData } = await UserService.getProfiles();
      const { baseUsers } = usersData;
      dispatch(baseUserSetData(baseUsers));
    })();
  }, []);

  return (
    <>
      <S.DocumentsWrapper>
        <S.DocumentsWrapperHeader>
          <BodyXL $color='brandPrimary' $isUpper>
            Your Documents
          </BodyXL>
        </S.DocumentsWrapperHeader>

        <div>
          <DocumentCard
            title='Profile Photo'
            caption='This photo will be used so referees can verify your identity before the matches.'
            status={
              userData?.photo && userData?.photo !== ''
                ? photoStatus
                : DocumentStatusLabel.EMPTY
            }
            onClick={() => setModalId('photo')}
          />
          <DocumentCard
            title='Proof of Age'
            caption="Your proof of age can be a Passport, Driver's License or Birth certificate."
            status={
              userData?.ageProof && userData?.ageProof !== ''
                ? ageStatus
                : DocumentStatusLabel.EMPTY
            }
            onClick={() => setModalId('age')}
          />
          {DateUtil.isAnAdult(userData?.birthDate) && (
            <DocumentCard
              isOptional
              title='SafeSport'
              caption='SafeSport is only needed if you intend to be a coach, referee or club director.'
              status={
                userData?.extraInfo?.certificate &&
                userData?.extraInfo?.certificate !== ''
                  ? safesportStatus
                  : DocumentStatusLabel.EMPTY
              }
              onClick={() => setModalId('safesport')}
            />
          )}
        </div>
      </S.DocumentsWrapper>

      <ProfilePhotoModal
        isOpen={modalId === 'photo'}
        status={photoStatus}
        handleCloseModal={() => setModalId('')}
      />
      <ProofOfAgeModal
        isOpen={modalId === 'age'}
        status={ageStatus}
        handleCloseModal={() => setModalId('')}
      />
      <SafeSportModal
        isOpen={modalId === 'safesport'}
        status={safesportStatus}
        handleCloseModal={() => setModalId('')}
      />

      <DocumentStatusViewModal
        isOpen={modalId === 'ageView'}
        documentName='Proof of Age'
        documentStatus={userData?.ageVerified as RequestStatus}
        urlImg={userData?.ageProof as string}
        handleIsOpen={() => setModalId('')}
      />

      <DocumentStatusViewModal
        isOpen={modalId === 'photoView'}
        documentName='Photo'
        documentStatus={userData?.photo as RequestStatus}
        urlImg={userData?.photoVerified as string}
        handleIsOpen={() => setModalId('')}
      />
    </>
  );
};

export default withRouter(Documents);
