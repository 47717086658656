import { Table } from 'antd';
import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const StyledTable = styled(Table)`
  .ant-table {
    font-weight: 600;
    font-size: 11px;
    background-color: ${COLORS.grey950};
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    font-size: 13px;
    color: ${COLORS.grey400};
  }

  .ant-table-thead {
    tr {
      th {
        background: none;

        font-size: 16px;
        line-height: 19px;
        font-family: 'Boxed Regular Bold';

        border-bottom: 1px solid ${COLORS.grey900};
        border-top: 1px solid ${COLORS.grey900};
      }
    }
  }

  .ant-upload.ant-upload-select {
    display: block;
  }
  .ant-table-row {
    border-bottom: solid 1px ${COLORS.grey900};
    &.finished-game-row {
      background-color: $orange;
    }
    &.scheduled-game-row {
      background-color: $grey-dark;
    }
    .label-status {
      padding: 5px 15px;
      border-radius: 5px;
      text-align: center;
      border: solid 1px #ccc;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
  }

  .ant-pagination-options {
    display: none;
  }
`;
