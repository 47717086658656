import { User } from 'models/User/UserModel';

type ClubInvitesModel = {
  id: string,
  creationDate: Date,
  eventId: string,
  receiverId: string,
  status: ClubInvitesStatus,
  type: ClubInvitesTypes,
};

export enum ClubInvitesTypes {
  CLUB_TO_USER = 'CLUB_TO_USER',
}

export enum ClubInvitesStatus {
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED',
  INVITED = 'INVITED',
}

export type ClubInvitesViewWithReceiver = ClubInvitesModel & {
  receiver: User,
};

export default ClubInvitesModel;
