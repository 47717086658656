import React, { useMemo, useState } from 'react';
import { Table } from 'components/v3/Table/Table';
import { useParams } from 'react-router-dom';
import { useGetClubMembersPool } from 'hooks/v3/clubs/useGetClubMembersPool/useGetClubMembersPool';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import { clubDashboardToggleSelectedMemberPoolUser } from 'redux/v3/clubDashboard/actions';
import RightDrawer from 'components/v3/RightDrawer';
import { User } from 'models/User/UserModel';
import DateUtil from 'util/DateUtil';
import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import { useGetUsersStats } from 'hooks/v3/users/useGetUserStats/useGetUserStats';
import { BodyLBold } from 'styles/v3/variables';
import { RequestStatus } from 'models/Request/RequestModel';
import { UserType } from 'redux/user/types';
import { useGetMyClubsInvites } from 'hooks/v3/clubs/useGetMyClubsInvites/useGetMyClubsInvites';
import { Filters } from '../Filters/Filters';
import { columns } from './columns';
import ModalHeader from './components/Modal/ModalHeader/ModalHeader';
import ModalBody from './components/Modal/ModalBody/ModalBody';
import { FilterModalBody } from './components/Modal/FilterModalBody/FilterModalBody';
import { InvitationFiltersType } from './components/Modal/FilterModalBody/types';

export const InvitationsTable = () => {
  const params: { clubId: string } = useParams();
  const { data: currentClub } = useGetClubById(params.clubId);

  const { data: invites } = useGetMyClubsInvites(params.clubId);

  const { data: usersStats } = useGetUsersStats(
    invites?.map((m) => m.receiverId) || []
  );

  const [filterMember, setFilterMember] = useState('');
  const [advancedFilter, setAdvancedFilter] = useState<InvitationFiltersType>({
    age: [],
    documentStatus: [],
    gender: [],
    membership: [],
  });

  const [isOpen, setIsOpen] = React.useState(false);
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [currentMember, setCurrentMember] = React.useState<User | undefined>(
    undefined
  );

  const {
    clubDirector: {
      memberPool: { selectedUserIds },
    },
  } = useSelector((state: ApplicationState) => state.clubDashboard);

  const dispatch = useDispatch();

  const handleToggleSelectedUser = (userId: string) => {
    dispatch(clubDashboardToggleSelectedMemberPoolUser(userId));
  };

  const handleFilter = (text: string) => {
    setFilterMember(text);
  };

  const membersData = useMemo(() => {
    if (!invites?.length || !currentClub) return [];

    const getDocumentStatus = (
      documentationVerified?: RequestStatus,
      ageVerified?: RequestStatus,
      type?: UserType
    ): RequestStatus => {
      if (!type) return RequestStatus.APPROVED;

      switch (type) {
        case UserType.PLAYER:
          return ageVerified || RequestStatus.DECLINED;
        default:
          return documentationVerified || RequestStatus.DECLINED;
      }
    };

    const getGender = (currentGender?: string) => {
      switch (currentGender) {
        case 'MALE':
          return 'Boy';
        case 'FEMALE':
          return 'Girl';
        default:
          return 'Other';
      }
    };
    return invites
      .map((invite) => ({
        id: invite.id,
        firstName: invite.receiver.firstName ?? '',
        gender: getGender(invite.receiver.gender),
        birthYear: DateUtil.getYearFromDateString(
          invite.receiver.birthDate?.toString()
        ),
        xp: usersStats?.find((u) => u.userId === invite.receiver.id)?.xp || 0,
        email: invite.receiver.email ?? invite.receiver.accountEmail,
        status: invite.status,
        membershipType: invite.receiver.membership?.type ?? 'COMMUNITY',
        documentStatus: getDocumentStatus(
          invite.receiver.documentationVerified,
          invite.receiver.ageVerified,
          invite.receiver.type
        ),
        photo: invite.receiver.photo,
        selected: selectedUserIds.includes(invite.receiver.id),
        toggleSelected: () => handleToggleSelectedUser(invite.receiver.id),
        openDrawer: () => handleOpenDrawer(invite.receiver),
      }))
      .filter((member) =>
        !filterMember
          ? true
          : member.firstName.toLowerCase().includes(filterMember.toLowerCase())
      )
      .filter((member) => {
        const ageFilter =
          advancedFilter.age.length === 0
            ? true
            : advancedFilter.age.includes(Number(member.birthYear));

        const genderFilter =
          advancedFilter.gender.length === 0
            ? true
            : advancedFilter.gender.includes(member.gender);

        const membershipFilter =
          advancedFilter.membership.length === 0
            ? true
            : advancedFilter.membership.includes(member.membershipType);

        const documentStatusFilter =
          advancedFilter.documentStatus.length === 0
            ? true
            : advancedFilter.documentStatus.includes(
                member.documentStatus.toString()
              );

        return (
          ageFilter && genderFilter && membershipFilter && documentStatusFilter
        );
      });
  }, [invites, selectedUserIds, filterMember, advancedFilter, currentClub]);

  const handleOpenDrawer = (member: User) => {
    setCurrentMember(member);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setCurrentMember(undefined);
    setIsOpen(false);
  };

  const handleCloseFilterModal = () => {
    setIsFilterOpen(false);
  };
  const handleOpenFilterModal = () => {
    setIsFilterOpen(true);
  };

  const handleSetAdvancedFilter = (filters: InvitationFiltersType) => {
    setAdvancedFilter(filters);
  };

  return (
    <>
      <Filters
        handleOpenFilterModal={handleOpenFilterModal}
        handleChange={handleFilter}
      />
      <Table columns={columns} dataSource={membersData} />
      <RightDrawer
        isOpen={isOpen && !!currentMember}
        handleCloseModal={handleCloseModal}
        headerComponent={<ModalHeader photo={currentMember?.photo} />}
      >
        {currentMember && <ModalBody user={currentMember} />}
      </RightDrawer>
      <RightDrawer
        isOpen={isFilterOpen}
        handleCloseModal={handleCloseFilterModal}
        headerComponent={<BodyLBold $color='brandPrimary'>Filters</BodyLBold>}
      >
        <FilterModalBody
          filters={advancedFilter}
          handleChange={handleSetAdvancedFilter}
        />
      </RightDrawer>
    </>
  );
};
