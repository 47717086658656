import * as React from 'react';

import { ColorsInterface } from 'styles/v3/variables';
import * as S from './styles';

export interface SimpleCardProps {
  orientation: 'column' | 'row';
  className?: string;
  borderColor?: keyof ColorsInterface;
  bgColor?: keyof ColorsInterface;
  children?: React.ReactFragment;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const SimpleCard: React.FC<SimpleCardProps> = ({
  children,
  orientation,
  borderColor,
  bgColor,
  onClick,
  ...props
}) => (
  <S.Container
    $orientation={orientation}
    $borderColor={borderColor}
    $bgColor={bgColor}
    $clickable={!!onClick}
    onClick={onClick}
    {...props}
  >
    {children}
  </S.Container>
);

export default SimpleCard;
