import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import ClubService from 'services/v3/Clubs/ClubService';

export function useGetMyClubsInvites(clubId: string) {
  const { id: accountId } = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );
  const { data, isLoading, error } = useQuery(
    ['get-my-club-invites', clubId, accountId],
    async () => await ClubService.getMyClubsInvites(clubId),
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  return {
    ...data,
    data: data?.data ?? [],
    isLoading,
    error,
  };
}
