import styled from 'styled-components';

import { Avatar } from 'antd';

export const ContainerCircles = styled.div`
  display: flex;
`;

export const CirclesGroup = styled(Avatar.Group)`
  span {
    border: none !important;
  }
`;

export const CircleInfo = styled(Avatar)`
  .ant-avatar-group .ant-avatar:not(:first-child) {
    margin-left: -10px !important;
  }
`;
