import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: ${toRem(16)};
  color: ${COLORS.grey500};
`;

export const GeneralInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${toRem(24)};

  border-bottom: 1px solid ${COLORS.grey900};
  gap: ${toRem(24)};
`;

export const GeneralInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ApplicationStatusContainer = styled.div`
  background-color: ${COLORS.grey900};
  display: flex;
  align-items: center;
  gap: ${toRem(16)};

  padding: ${toRem(4)} ${toRem(8)} ${toRem(4)} ${toRem(4)};
  border-radius: ${toRem(25)};
`;

export const DivisionContentDatas = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DivisionData = styled.div`
  display: flex;
  padding: ${toRem(8)} ${toRem(22)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${toRem(4)};

  border: ${toRem(1)} solid ${COLORS.grey800};
  border-radius: ${toRem(8)};
`;

export const TeamsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${toRem(24)} 0;
`;

export const TeamsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};
`;
