import { Col, Row, message } from 'antd';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../redux/store';
import {
  BoxInfo,
  NextButtonsWrapper,
  TopHeader,
} from './WizardComponents.style';
import Steps from '../../components/Steps/Steps';
import DocumentUpload from '../../components/DocumentUpload/DocumentUpload';

const WizardDocument: React.FunctionComponent = (props) => {
  const { newUser } = useSelector(
    (state: ApplicationState) => state.newUser
  ).data;

  const history = useHistory();
  function nextScreen() {
    if (newUser?.ageProof === '' || newUser?.ageProof === undefined) {
      message.warning({
        content: 'You need to upload your document',
        duration: 4,
      });
    } else {
      history.push('/wizard/memberships');
    }
  }

  return (
    <div>
      <TopHeader>
        <h5>
          COMPLETE YOUR HOUSEHOLD USER CREATION TO JOIN THE UNITED FUTSAL
          COMMUNITY!
        </h5>
        <h1>UPLOAD PROOF OF AGE DOCUMENTATION</h1>
      </TopHeader>
      <Row>
        <Col xxl={18} lg={18} md={24} className='column-wizard'>
          <Steps activeStep={2} />
          <DocumentUpload />
          <BoxInfo>
            <h5>WHY DO WE NEED THIS?</h5>
            <p>
              We require this proof of age document to make sure all participant
              players will play in the appropriate age group. We value fair and
              challenging competition and we want to make sure there will be no
              cheating in relation to player&apos;s age. As such, you on behalf
              of yourself (or your minor child if you are a parent or legal
              guardian), must submit the participating player&apos;s proof of
              age document as part of our registration process.
            </p>
          </BoxInfo>
          <NextButtonsWrapper data-testid='wizard-nav-wrapper'>
            <Link to='/wizard/photo'>
              <div style={{ margin: '0 10px' }}> ◄ PREVIOUS STEP</div>
            </Link>
            <div
              className={`link ${!newUser?.ageProof ? 'disabled' : ''}`}
              onClick={() => nextScreen()}
              onKeyDown={() => nextScreen()}
              role='button'
              tabIndex={0}
              aria-label='Next Step'
              style={{ margin: '0 10px' }}
            >
              NEXT STEP ►
            </div>
          </NextButtonsWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(WizardDocument);
