import React from 'react';
import { InvitationsTable } from './components/InvitationsTable/InvitationsTable';

export const InvitationsTab = () => {
  return (
    <>
      <InvitationsTable />
    </>
  );
};
