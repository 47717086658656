import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { User } from 'models/User/UserModel';
import { ClubInvitesTypes } from 'models/v3/ClubInvite/ClubInvitesModel';
import { IFilteredUserParameters } from 'services/v3/User/types';

import { useGetUsersByFilters } from 'hooks/v3/users/useGetUsersByFilters/useGetUsersByFilters';
import { useInviteMembersByClub } from 'hooks/v3/clubs/useInviteMembersByClub/useInviteMembersByClub';
import { useGetAdvancedFilters } from 'hooks/v3/users/useGetAdvancedFilters/useGetAdvancedFilters';

import { notification } from 'components/v3/Notification/notification';

import { Find, InviteList } from './components';

import * as S from './styles';

const InviteMembersToClub = () => {
  const history = useHistory();
  const { clubId } = useParams<{ clubId?: string }>();

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const { mutateAsync: inviteMemberByClub } = useInviteMembersByClub();
  const { users, isUsersLoading, fetchUsersByFilters } = useGetUsersByFilters();
  const { advancedFilters } = useGetAdvancedFilters();

  const handleRemoveCard = useCallback(
    (userId: string) => {
      setSelectedUsers(selectedUsers?.filter((user) => user.id !== userId));
    },
    [selectedUsers]
  );

  const handleCardSelect = useCallback(
    (userId: string) => {
      const existedSelectedUser = selectedUsers.find(
        (user) => user.id === userId
      );

      if (existedSelectedUser) {
        handleRemoveCard(existedSelectedUser.id);

        return;
      }

      const findUser = users?.find((user) => user.id === userId);

      if (findUser) {
        setSelectedUsers((prevState) => [...prevState, findUser]);
      }
    },
    [users, selectedUsers, handleRemoveCard]
  );

  const handleFetchUsersByFilters = useCallback(
    async (selectedFilters: IFilteredUserParameters) => {
      await fetchUsersByFilters(selectedFilters);
    },
    [fetchUsersByFilters]
  );

  const handleSendInvites = useCallback(async () => {
    if (!selectedUsers.length) {
      notification.error({
        message: 'An error occurs!',
        description:
          'Please select at least one member to send the invitation to',
      });

      return;
    }

    if (clubId) {
      await inviteMemberByClub({
        clubId,
        receiversIds: selectedUsers.map((user) => user.id),
        type: ClubInvitesTypes.CLUB_TO_USER,
      });

      history.push(`/v3/clubs/${clubId}/dashboard`);
    }
  }, [selectedUsers, clubId, history, inviteMemberByClub]);

  useEffect(() => {
    fetchUsersByFilters({});
  }, [fetchUsersByFilters]);

  return (
    <S.Container>
      <Find
        users={users}
        advancedFilters={advancedFilters}
        isUsersLoading={isUsersLoading}
        selectedUserIds={selectedUsers.map((user) => user.id)}
        onSelectCard={handleCardSelect}
        onFetchUsersByFilters={handleFetchUsersByFilters}
      />
      <InviteList
        selectedUsers={selectedUsers}
        onSendInvites={handleSendInvites}
        onRemoveCard={handleRemoveCard}
      />
    </S.Container>
  );
};

export default InviteMembersToClub;
