import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  cursor: pointer;

  .i-icon svg {
    width: ${toRem(32)};
    height: ${toRem(32)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.grey400};
    }
  }

  &:hover {
    .i-icon svg {
      path {
        transition: stroke 0.4s linear;
        stroke: ${COLORS.brandPrimary};
      }
    }
  }
`;
