import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { BodyS } from 'styles/v3/variables';

import { ClubInvitesStatus } from 'models/v3/ClubInvite/ClubInvitesModel';
import * as F from './components';

export type DataType = {
  id: string,
  firstName: string,
  gender: string,
  birthYear: number,
  xp: number,
  email: string,
  status: ClubInvitesStatus,
  photo: string,
  toggleSelected: () => void,
  openDrawer: () => void,
};

export const columns: ColumnsType<DataType> = [
  {
    title: 'First Name',
    render(_, { firstName, photo }) {
      return <F.Name name={firstName} photo={photo} />;
    },
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    render(value) {
      return <BodyS $color='grey400'>{value}</BodyS>;
    },
  },
  {
    title: 'Birth Year',
    dataIndex: 'birthYear',
    render(value) {
      return <BodyS $color='grey400'>{value}</BodyS>;
    },
  },
  {
    title: 'XP',
    dataIndex: 'xp',
    render(value) {
      return <BodyS $color='grey400'>{value}</BodyS>;
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render(value) {
      return <BodyS $color='grey400'>{value}</BodyS>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render(value) {
      return <F.Status status={value} />;
    },
  },
  {
    title: '',
    render(_, { openDrawer }) {
      return <F.ViewMember openDrawer={openDrawer} />;
    },
  },
];
