import moment from 'moment';

export function mountYearCategories() {
  const year = moment().year();
  const years = [];
  for (let i = year - 5; i >= year - 20; i -= 1) {
    years.push({
      label: String(i),
      value: i,
    });
  }
  years.push({
    label: 'Adult',
    value: 1900,
  });
  return years;
}
