import styled from 'styled-components'
import { COLORS } from 'styles/v3/variables'

export const CONTENT_WIDTH = '504px'

export const SideModalContentWrapper = styled.div`
  background-color: ${COLORS.grey950};
  width: ${CONTENT_WIDTH};
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media (max-width: ${CONTENT_WIDTH}) {
    width: 100%;
  }
`
