import styled from 'styled-components';
import { color } from '../../../styles/styleVariables';

const StatsBoxWrapper = styled.div`
  overflow: auto;
  width: 100%;
  .stats-box {
    width: 100%;
    display: flex;
    padding: 20px 0;

    .item {
      text-align: center;
      padding: 0 30px;
      position: relative;
      justify-content: space-between;
      &:not(:last-child)::after {
        content: '';
        height: 50px;
        width: 1px;
        background-color: ${color.silverLight};
        position: absolute;
        right: 0;
        top: 30px;
      }
      .value {
        font-size: 36px;
      }
      .label {
        font-size: 11px;
        text-transform: uppercase;
        color: ${color.silverLight};
      }
      .label-2 {
        font-size: 18px;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  &::-webkit-scrollbar-track {
    background: ${color.silver};
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${color.orange};
    border-radius: 5px; /* roundness of the scroll thumb */
    border: 3px solid ${color.silver}; /* creates padding around scroll thumb */
  }
`;

export default StatsBoxWrapper;
