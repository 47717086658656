import * as React from 'react';
import { EveryUser, Local } from '@icon-park/react';

import { BodyS, TitleH4 } from 'styles/v3/variables';
import { DocumentStatusLabel } from 'redux/v3/baseUsers/types';

import ConvertUtil from 'util/ConvertUtil';

import * as S from './styles';

interface ClubCardInlineProps {
  status?: DocumentStatusLabel;
  locality: string;
  logo: string;
  name: string;
  members?: number;
  onClick: () => void;
}

const ClubCardInline: React.FC<ClubCardInlineProps> = ({
  status,
  members,
  locality,
  logo,
  name,
  onClick,
}) => (
  <S.Wrapper onClick={onClick}>
    <S.LogoWrapper>
      <img src={ConvertUtil.getMinioUrl(logo)} alt='' />
    </S.LogoWrapper>
    <S.InfoWrapper>
      {status && status === DocumentStatusLabel.PENDING && (
        <S.Label>
          <BodyS $isUpper $color='grey400'>
            {status}
          </BodyS>
        </S.Label>
      )}
      <TitleH4 $color='grey200' className='title'>
        {name}
      </TitleH4>
      <S.Details>
        {members && (
          <>
            <EveryUser size={24} />
            <BodyS>
              {members} {`${members > 1 ? 'members' : 'member'}`}
            </BodyS>
          </>
        )}
        <Local size={24} />
        <BodyS>{locality}</BodyS>
      </S.Details>
    </S.InfoWrapper>
  </S.Wrapper>
);

export default ClubCardInline;
