import { Col, Row, Table } from 'antd';
import * as React from 'react';

interface IActionXPProps {}

const playerColumns = [
  {
    title: 'Player Actions',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: 'XP per action',
    dataIndex: 'xp',
    key: 'xp',
    width: 120,
    align: 'center' as const,
  },
];

const clubColumns = [
  {
    title: 'Club Actions',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: 'XP per action',
    dataIndex: 'xp',
    key: 'xp',
    width: 120,
    align: 'center' as const,
  },
];

const clubDirectorColumns = [
  {
    title: 'Club Director Actions',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: 'XP per action',
    dataIndex: 'xp',
    key: 'xp',
    width: 120,
    align: 'center' as const,
  },
];

const coachColumns = [
  {
    title: 'Coache Actions',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: 'XP per action',
    dataIndex: 'xp',
    key: 'xp',
    width: 120,
    align: 'center' as const,
  },
];

const playerTable = [
  {
    key: '1',
    action: 'Appearance',
    xp: '20',
  },
  {
    key: '1',
    action: 'Goal',
    xp: '6',
  },
  {
    key: '1',
    action: 'PK Goal',
    xp: '2',
  },
  {
    key: '1',
    action: 'PK Miss',
    xp: '-2',
  },
  {
    key: '1',
    action: 'Own Goal',
    xp: '-6',
  },
  {
    key: '1',
    action: 'Yellow Card',
    xp: '-2',
  },
  {
    key: '1',
    action: 'Red Card',
    xp: '-6',
  },
  {
    key: '1',
    action: 'Win',
    xp: '60',
  },
  {
    key: '1',
    action: 'Draw',
    xp: '30',
  },
  {
    key: '1',
    action: 'Loss',
    xp: '0',
  },
  {
    key: '1',
    action: 'Clean Sheet',
    xp: '10',
  },
  {
    key: '1',
    action: 'Hat Trick (3 goals in a game)',
    xp: '2',
  },
];

const clubTable = [
  {
    key: '1',
    action: 'Win',
    xp: '60',
  },
  {
    key: '1',
    action: 'Draw',
    xp: '30',
  },
  {
    key: '1',
    action: 'Loss',
    xp: '0',
  },
  {
    key: '1',
    action: 'Clean Sheet',
    xp: '10',
  },
];

const clubDirectorTable = [
  {
    key: '1',
    action: 'Win',
    xp: '60',
  },
  {
    key: '1',
    action: 'Draw',
    xp: '30',
  },
  {
    key: '1',
    action: 'Loss',
    xp: '0',
  },
  {
    key: '1',
    action: 'Clean Sheet',
    xp: '10',
  },
];

const coachTable = [
  {
    key: '1',
    action: 'Appearance',
    xp: '20',
  },
  {
    key: '1',
    action: 'Yellow Card',
    xp: '-2',
  },
  {
    key: '1',
    action: 'Red Card',
    xp: '-6',
  },
  {
    key: '1',
    action: 'Win',
    xp: '60',
  },
  {
    key: '1',
    action: 'Draw',
    xp: '30',
  },
  {
    key: '1',
    action: 'Loss',
    xp: '0',
  },
  {
    key: '1',
    action: 'Clean Sheet',
    xp: '10',
  },
];

const ActionXP: React.FunctionComponent<IActionXPProps> = (props) => {
  return (
    <div>
      <div className='group-box'>
        <h3 style={{ display: 'flex', alignItems: 'center' }}>
          <span
            className='icon clear lg i-ball'
            style={{ marginRight: '15px' }}
          ></span>{' '}
          Action XP
        </h3>
        <Row gutter={20}>
          <Col sm={24} md={24} lg={8}>
            <Table
              rowKey='id'
              dataSource={playerTable}
              pagination={false}
              columns={playerColumns}
            />
          </Col>

          <Col sm={24} md={24} lg={8}>
            <Table
              rowKey='id'
              dataSource={clubTable}
              pagination={false}
              columns={clubColumns}
            />

            <Table
              rowKey='id'
              dataSource={clubDirectorTable}
              pagination={false}
              columns={clubDirectorColumns}
            />
          </Col>

          <Col sm={24} md={24} lg={8}>
            <Table
              rowKey='id'
              dataSource={coachTable}
              pagination={false}
              columns={coachColumns}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ActionXP;
