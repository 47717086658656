import { Reducer } from 'redux'
import { produce } from 'immer'
import { SideModalActionTypes, SideModalState } from './types'

export const initialState: SideModalState = {
  title: '',
  isOpen: false,
  selected: 'none',
  hasBackButton: false,
  backButtonClickCount: 0,
  step: 0,
}

const reducer: Reducer<SideModalState> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // Set Modal title
      case SideModalActionTypes.SET_TITLE: {
        draft.title = action.payload
        break
      }
      case SideModalActionTypes.OPEN_MODAL: {
        draft.isOpen = true
        break
      }
      case SideModalActionTypes.CLOSE_MODAL: {
        draft.isOpen = false
        draft.step = 0
        break
      }
      case SideModalActionTypes.TOGGLE_MODAL: {
        draft.isOpen = !state.isOpen
        if (!state.isOpen) draft.step = 0
        break
      }
      case SideModalActionTypes.SET_SELECTED: {
        draft.selected = action.payload
        break
      }
      case SideModalActionTypes.HAS_BACK_BUTTON: {
        draft.hasBackButton = action.payload
        if (!action.payload) {
          draft.backButtonClickCount = 0
        }
        break
      }
      case SideModalActionTypes.INC_BACK_BUTTON_CLICK_COUNT: {
        draft.backButtonClickCount = state.backButtonClickCount + 1
        break
      }
      case SideModalActionTypes.SET_STEP: {
        draft.step = action.payload
        break
      }

      default: {
        break
      }
    }
  })

export { reducer as sideModalReducer }
