import * as React from 'react';
import { useEffect, useState } from 'react';
import RosterService from '../../../services/Rosters/RosterService';

interface IVerifyRosterProps {
  rosterId: string;
}

const VerifyRoster: React.FunctionComponent<IVerifyRosterProps> = (props) => {
  const { rosterId } = props;
  const [loading, setLoading] = useState(false);

  const [rosterIssue, setRosterIssue] = useState<{
    jerseyNumber: boolean;
    minAmountOfPlayers: boolean;
    document: boolean;
    wrongMembership: boolean;
  }>({
    jerseyNumber: false,
    minAmountOfPlayers: false,
    document: false,
    wrongMembership: false,
  });

  const verifyRoster = () => {
    setLoading(true);
    let playerList: String[] = [];
    RosterService.getRoster(rosterId).then((returnData) => {
      if (returnData.success) {
        playerList = returnData.data.players.map((player) => player.id);
        if (
          returnData.data.players.filter(
            (player) => player.number === undefined || player.number === null
          ).length > 0
        ) {
          setRosterIssue((prev) => {
            return {
              ...prev,
              ...{ jerseyNumber: true },
            };
          });
        }

        if (
          returnData.data.players.length <
          returnData.data.event!.minimumPlayersNumber
        ) {
          setRosterIssue((prev) => {
            return {
              ...prev,
              ...{ minAmountOfPlayers: true },
            };
          });
        }
      }
      RosterService.getRosterMemberpool(rosterId, 'players').then(
        (returnData) => {
          if (returnData.success) {
            if (
              returnData.data
                .filter((player) => playerList.includes(player.id))
                .filter(
                  (player) => player.rosterAvailability.membership === false
                ).length > 0
            ) {
              setRosterIssue((prev) => {
                return {
                  ...prev,
                  ...{ wrongMembership: true },
                };
              });
            }
          }
          setLoading(false);
        }
      );
    });
  };

  useEffect(() => {
    verifyRoster();
  }, []);

  return (
    <div>
      {loading && <div>Loading...</div>}

      {!loading && (
        <div style={{ color: '#ffa600' }}>
          <>{rosterIssue.jerseyNumber && <div>! Missing Number(s)</div>}</>
          <>{rosterIssue.wrongMembership && <div>! Wrong membership(s)</div>}</>
          <>{rosterIssue.minAmountOfPlayers && <div>! Minimum players</div>}</>
        </div>
      )}
    </div>
  );
};

export default VerifyRoster;
