import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Divider } from 'antd';
import { Info } from '@icon-park/react';

import { useGetFilteredPaidDivisions } from 'hooks/v3/event/useGetFilteredPaidDivisions/useGetFilteredPaidDivisions';

import FilledButton from 'components/v3/Buttons/FilledButton';
import Loading from 'components/v3/Loading/Loading';

import { BodyL, BodyM, COLORS } from 'styles/v3/variables';

import { DivisionCard } from './components/DivisionCard/DivisionCard';
import { DivisionSetFormat } from '../DivisionSetFormat/DivisionSetFormat';
import { ContinueToGroups } from '../DivisionSetFormat/components/ContinueToGroups/ContinueToGroups';
import { IFiltrationParams } from './components/Filters/configs';
import { Filters } from './components';

import * as S from '../../styles';

interface IDivisionsEventProps {
  eventId: string;
}

export const DivisionsEvent: FC<IDivisionsEventProps> = ({ eventId }) => {
  const [selectFormat, setSelectFormat] = useState(false);
  const [years, setYears] = useState<number[]>([]);

  const {
    data,
    isLoading,
    mutateAsync: fetchFilteredDivisions,
  } = useGetFilteredPaidDivisions();

  const handleChange = useCallback(() => {
    setSelectFormat(true);
  }, []);

  const handleFilterChange = useCallback(
    (filters: IFiltrationParams) => {
      const gender =
        filters.gender.value !== 'all' ? filters.gender.value.toString() : '';
      const years =
        filters.years.value !== 'all' ? filters.years.value.toString() : '';

      fetchFilteredDivisions({
        eventId,
        params: {
          search: filters.search,
          gender: gender.toLocaleUpperCase(),
          years,
        },
      });
    },
    [eventId]
  );

  useEffect(() => {
    setSelectFormat(false);
  }, []);

  const eventDivisionsData = useMemo(() => {
    if (!data?.data.content.length || !data) return [];

    return data?.data.content?.flatMap((division) => {
      return division?.ageDivisions?.map((item) => {
        setYears((prevState) => [...prevState, ...item.years]);
        return {
          id: item.id,
          name: item.name ?? '',
          gender: item.gender,
          totalYears: item.years.length,
          firstYear: item.years[0],
          allowMixedTeams: item.allowGirlBoysOnAnotherDivision ?? false,
          bracketType: item.bracketType ?? '',
          pools: item.pools ?? [],
          format: item.format ?? '',
          color: item.color ?? '',
          rule: item.rule,
          years: item.years,
          coedRules: item?.coedRules ?? '',
        };
      });
    });
  }, [data]);

  return (
    <>
      {selectFormat && (
        <>
          <S.Header>
            <S.TitleBox>
              <BodyL>Set format</BodyL>
            </S.TitleBox>
            <S.DivisionIcon>
              <Info
                size={24}
                fill={COLORS.grey50}
                style={{ display: 'flex' }}
              />
            </S.DivisionIcon>
          </S.Header>
          <Divider style={{ margin: 0 }} />
          <Filters years={years} onFilterChange={handleFilterChange} />
          <Divider style={{ margin: 0 }} />
          <S.DivisionsBox>
            {eventDivisionsData.map((division) => (
              <DivisionSetFormat
                key={`key_roster_${division.name}`}
                id={division.id}
                name={division.name}
                gender={division.gender}
                totalYears={division.totalYears}
                firstYear={division.firstYear}
                allowMixedTeams={division.allowMixedTeams}
                bracketType={division.bracketType}
                pools={division.pools}
                format={division.format}
                color={division.color}
                rule={division.rule}
                years={division.years}
                coedRules={division.coedRules}
                eventId={eventId}
              />
            ))}
          </S.DivisionsBox>
          <ContinueToGroups />
        </>
      )}
      {!selectFormat && (
        <>
          <S.Header>
            <S.TitleBox>
              <BodyL>Your Divisions</BodyL>
            </S.TitleBox>
            <FilledButton
              className='button-formating'
              onClick={handleChange}
              isUpper
            >
              Start Formating
            </FilledButton>
          </S.Header>
          <Divider style={{ margin: 0 }} />
          <Filters years={years} onFilterChange={handleFilterChange} />
          <Divider style={{ margin: 0 }} />
          <S.DivisionsBox>
            {eventDivisionsData.map((division) => (
              <DivisionCard
                key={`key_roster_${division.name}`}
                id={division.id}
                name={division.name}
                rule={division.rule}
                gender={division.gender}
                totalYears={division.totalYears}
                firstYear={division.firstYear}
                allowMixedTeams={division.allowMixedTeams}
                bracketType={division.bracketType}
                pools={division.pools}
                format={division.format}
                color={division.color}
                coedRules={division.coedRules}
              />
            ))}
          </S.DivisionsBox>
        </>
      )}
      {isLoading && <Loading />}
      {!isLoading && !eventDivisionsData.length && (
        <BodyM>Divisions are currently missing or change the filtering</BodyM>
      )}
    </>
  );
};
