import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const SetupEventContentWrapper = styled.div`
  background-color: ${COLORS.grey1000};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: ${COLORS.white};
`;
