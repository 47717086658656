import { Table } from 'antd';
import * as React from 'react';

interface IEventMultiplierProps {}

const columns = [
  {
    title: 'Event',
    dataIndex: 'event',
    key: 'event',
  },
  {
    title: 'Multiplier',
    dataIndex: 'multiplier',
    key: 'multiplier',
    width: 120,
    align: 'center' as const,
  },
];

const dataTable = [
  {
    key: '1',
    event: 'World Futsal Cup',
    multiplier: 'x100',
  },
  {
    key: '2',
    event: 'World Futsal Championships',
    multiplier: 'x50',
  },
  {
    key: '3',
    event: 'CCS National Championships',
    multiplier: 'x40',
  },
  {
    key: '4',
    event: 'CCS Regional Championships',
    multiplier: 'x40',
  },
];

const EventMultiplier: React.FunctionComponent<IEventMultiplierProps> = (
  props
) => {
  return (
    <div>
      <h3>How is XP calculated?</h3>
      <p>
        We multiply your action X the event multiplier. So, choose the right
        events and do the better actions in order to improve your VAULT card.
      </p>
      <div className='group-box'>
        <h3 style={{ display: 'flex', alignItems: 'center' }}>
          <span
            className='icon clear lg i-trophy'
            style={{ marginRight: '15px' }}
          ></span>{' '}
          Event Multiplier
        </h3>

        <Table
          rowKey='id'
          dataSource={dataTable}
          pagination={false}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default EventMultiplier;
