import React from 'react';
import { Avatar } from 'antd';
import ConvertUtil from 'util/ConvertUtil';
import { BodyS } from 'styles/v3/variables';
import { UserOutlined } from '@ant-design/icons';
import * as S from './styles';

type AvatarWithTextProps = {
  text?: string;
  imgSrc?: string;
};

export const AvatarWithText = ({ text, imgSrc }: AvatarWithTextProps) => {
  return (
    <S.Container>
      <Avatar icon={<UserOutlined />} src={ConvertUtil.getMinioUrl(imgSrc)} />
      <BodyS>{text}</BodyS>
    </S.Container>
  );
};
