import React from 'react';
import { BodyS } from 'styles/v3/variables';
import ConvertUtil from 'util/ConvertUtil';
import { NameFieldProps } from './types';

import * as S from './styles';

export const Name = ({ name, photo }: NameFieldProps) => {
  return (
    <S.Container>
      <S.Image $src={ConvertUtil.getMinioUrl(photo)} />
      <BodyS>{name}</BodyS>
    </S.Container>
  );
};
