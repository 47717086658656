import * as React from 'react';

import * as S from './styles';
import SimpleCard, { SimpleCardProps } from '../SimpleCard/SimpleCard';

export interface RoundedCardProps extends SimpleCardProps {
  size: 'small' | 'large';
}

const RoundedCard: React.FC<RoundedCardProps> = ({
  children,
  size,
  orientation,
  borderColor,
  className,
  ...props
}) => (
  <S.Container $size={size} className={className}>
    <SimpleCard
      orientation={orientation}
      className='simple-card-wrapper'
      {...props}
    >
      {children}
    </SimpleCard>
  </S.Container>
);

export default RoundedCard;
