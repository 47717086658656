import React from 'react';

import { BodyL, BodyLBold, BodyM } from 'styles/v3/variables';
import ClubService from 'services/v3/Clubs/ClubService';
import { ClubView } from 'admin/models/club/Club';

import ConvertUtil from 'util/ConvertUtil';
import { DocumentsProps } from '../Documents/types';
import * as S from './styles';

export const Clubs = ({ user }: DocumentsProps) => {
  const [clubs, setClubs] = React.useState<ClubView[]>([]);

  React.useEffect(() => {
    (async () => {
      const { data: myClubs } = await ClubService.getClubsByUserId(user.id);
      setClubs(myClubs);
    })();
  }, []);

  return (
    <S.Container>
      <BodyLBold className='title'>Clubs</BodyLBold>
      <S.ClubsList>
        {clubs.length > 0 ? (
          clubs.map((club) => (
            <S.CardClub key={club.id}>
              <S.ImgWrapper>
                <img src={ConvertUtil.getMinioUrl(club.logo)} alt={club.name} />
              </S.ImgWrapper>
              <BodyL>{club.name}</BodyL>
            </S.CardClub>
          ))
        ) : (
          <BodyM>No data to show</BodyM>
        )}
      </S.ClubsList>
    </S.Container>
  );
};
