import React, { useMemo } from 'react';
import { User } from 'models/User/UserModel';

import UserType from 'models/User/UserTypeModel';

import { BodyXL, BodyM } from 'styles/v3/variables';

import { PlayerOfCoachInformation } from './PlayerOrCoachInformation';

import { Container, HeaderList, BodyList, ContainerOfInfos } from './styles';

interface ListOfUserProps {
  type: UserType;
  jerseyNumbers?: Array<{ id: string, number: number }>;
  coachesList?: User[];
  playersList?: User[];
}

export const ListOfUser = ({
  type,
  jerseyNumbers,
  coachesList,
  playersList,
}: ListOfUserProps) => {
  const usersList = useMemo(() => {
    if (type === UserType.COACH) {
      return coachesList;
    }

    return playersList;
  }, [type, coachesList, playersList]);

  const title = useMemo(() => {
    if (type === UserType.COACH) {
      return 'Coaches';
    }

    return 'Players';
  }, [type]);

  return (
    <Container>
      <HeaderList>
        <BodyXL $color='white'>
          {title} ({usersList?.length})
        </BodyXL>
      </HeaderList>
      <BodyList>
        {usersList && (
          <>
            {usersList.length === 0 ? (
              <BodyM
                $color='grey400'
                style={{ paddingTop: 50, paddingBottom: 50 }}
              >
                You don&apos;t have{' '}
                {type === UserType.COACH ? 'coaches' : 'players'} in this
                roster. Add now
              </BodyM>
            ) : (
              <ContainerOfInfos>
                {usersList.map((user) => (
                  <PlayerOfCoachInformation
                    key={user.id}
                    user={user}
                    jerseyNumber={
                      jerseyNumbers?.find((number) => number.id === user.id)
                        ?.number
                    }
                  />
                ))}
              </ContainerOfInfos>
            )}
          </>
        )}
      </BodyList>
    </Container>
  );
};
