import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Main = styled.div`
  padding: ${toRem(64)} ${toRem(30)} ${toRem(36)};

  background-color: ${COLORS.grey950};
  border-radius: 0 0 ${toRem(24)} ${toRem(24)};

  .ant-tabs-nav {
    padding: 0;
  }

  .ant-tabs-tab {
    margin: 0;
  }
`;

export const SpinnerWrapper = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`;
