import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const ColorPickeContainer = styled.div`
  position: relative;
`;

export const ColorPickerHeader = styled.div`
  color: ${COLORS.white};
  margin-bottom: ${toRem(16)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ColorPickerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  row-gap: 5px;
`;

export const ColorElement = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid ${COLORS.grey100};
  margin: 0 auto;
  background-color: ${(props) => props.color ?? COLORS.grey100};

  &.active {
    border: 3px solid ${COLORS.white};
  }
`;

export const ColorPickerWrapper = styled.div`
  position: absolute;
  background: ${COLORS.grey800};
  z-index: 100;
  width: 300px;
  padding: 10px;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  &.show {
    opacity: 1;
    visibility: visible;
  }
`;
