import { OptionsType } from 'components/v3/Forms/Select/Select';
import { GenderOptionValues } from 'pages/V3/event/components/EventMain/components/AgeSection/enums';

export interface IFiltrationParams {
  gender: OptionsType;
  years: OptionsType;
  search: string;
}

export const DEFAULT_FILTRATION_STATE: IFiltrationParams = {
  gender: {
    label: 'Gender',
    value: GenderOptionValues.All,
  },
  years: {
    label: 'Year',
    value: 'all',
  },
  search: '',
};
