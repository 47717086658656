import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 50px;
`;

export const LogoContainer = styled.div`
  img {
    opacity: 0.15;
  }
`;
