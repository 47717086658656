import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Write, Check, Close, Info } from '@icon-park/react';

import ConvertUtil from 'util/ConvertUtil';
import { NewAgeDivision } from 'admin/models/AgeDivision';

import { AgeRulesEnumsToLabel } from 'pages/V3/setupEvent/Divisions/constants';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import IconButton from 'components/v3/Buttons/IconButton';

import { COLORS, TitleH2, BodyM, BodyMBold } from 'styles/v3/variables';

import * as S from './styles';

interface HeaderRosterEditProps {
  rosterId: Maybe<string>;
  name: string;
  eventName: string;
  eventLogo: string;
  division: NewAgeDivision;
  locksIn?: Date;
  isLockedRoster?: boolean;
  onGoBack: () => void;
  onUpdateRosterName: (rosterId: string, name: string) => void;
  onSubmitRoster: () => void;
  onViewRequiredPoints: () => void;
}

export const HeaderRosterEdit: FC<HeaderRosterEditProps> = ({
  rosterId,
  name,
  eventName,
  eventLogo,
  division,
  isLockedRoster = false,
  locksIn = new Date(),
  onGoBack,
  onUpdateRosterName,
  onSubmitRoster,
  onViewRequiredPoints,
}) => {
  const [editRosterNameShown, setEditRosterNameShown] = useState(false);
  const [rosterNameValue, setRosterNameValue] = useState('');

  const lockDate = new Date(locksIn).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const years = useMemo(() => {
    return division.years.map((year) =>
      AgeRulesEnumsToLabel[division.rule].replace('Year', year.toString())
    );
  }, [division.rule, division.years]);

  const allowGender = useCallback(() => {
    if (division.allowGirlBoysOnAnotherDivision) {
      if (division.gender === 'FEMALE') {
        return `- Boys can Play`;
      }

      if (division.gender === 'MALE') {
        return `- Girls can Play`;
      }
    }

    return '';
  }, [division]);

  const handleEditRosterName = useCallback(() => {
    setEditRosterNameShown(true);
  }, []);

  const handleCloseEditRosterName = useCallback(() => {
    setEditRosterNameShown(false);
    setRosterNameValue(name);
  }, [name]);

  const handleChangeRosterName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRosterNameValue(event.target.value);
    },
    []
  );

  const handleConfirmEditRosterName = useCallback(() => {
    if (rosterId) {
      onUpdateRosterName(rosterId, rosterNameValue);
    }

    setEditRosterNameShown(false);
  }, [rosterId, rosterNameValue, onUpdateRosterName]);

  useEffect(() => {
    setRosterNameValue(name);
  }, [name]);

  return (
    <S.Container>
      <S.HeaderInformationsContainer>
        <TextButtonWithIcon
          reverse
          icon='back'
          color='light'
          align='flex-end'
          onClick={onGoBack}
        >
          Go back
        </TextButtonWithIcon>
        <S.HeaderTitleContainer>
          <S.AvatarEventInformation>
            <S.EventImage>
              <img src={ConvertUtil.getMinioUrl(eventLogo)} alt='Event Logo' />
            </S.EventImage>
            <BodyM>{eventName}</BodyM>
          </S.AvatarEventInformation>
          <S.EditFormWrapper>
            {!editRosterNameShown && <TitleH2>{rosterNameValue}</TitleH2>}
            {editRosterNameShown && (
              <S.EditForm>
                <S.EditInputField
                  id='roster_name'
                  value={rosterNameValue}
                  onChange={handleChangeRosterName}
                />
                <IconButton
                  onClick={handleCloseEditRosterName}
                  icon={<Close size={20} className='reset' />}
                />
                <S.EditRosterConfirm
                  onClick={handleConfirmEditRosterName}
                  disabled={rosterNameValue === name}
                  icon={
                    <Check
                      size={20}
                      fill={
                        rosterNameValue === name
                          ? COLORS.grey300
                          : COLORS.grey900
                      }
                      className='reset'
                    />
                  }
                />
              </S.EditForm>
            )}
            {!editRosterNameShown && !isLockedRoster && (
              <S.EditBt onClick={handleEditRosterName}>
                <Write theme='outline' size='16' />
                Edit name
              </S.EditBt>
            )}
          </S.EditFormWrapper>
        </S.HeaderTitleContainer>
        <S.RulesInformation>
          <S.Rules>
            <BodyMBold $color='grey400'>Gender Rules</BodyMBold>
            <BodyM $color='grey400'>
              {division.gender} {allowGender()}
            </BodyM>
          </S.Rules>
          <S.Rules>
            <BodyMBold $color='grey400'>Years Allowed</BodyMBold>
            <BodyM $color='grey400'>{years.join(', ')}</BodyM>
          </S.Rules>
          {division.gender === 'COED' && (
            <S.Rules>
              <BodyMBold $color='grey400'>Coed Rules</BodyMBold>
              <BodyM $color='grey400'>{division.coedRules}</BodyM>
            </S.Rules>
          )}
        </S.RulesInformation>
      </S.HeaderInformationsContainer>
      <S.ButtonsHeader>
        <S.ActionWrapper>
          <S.InfoIconButton onClick={onViewRequiredPoints}>
            <Info size={24} />
          </S.InfoIconButton>
          <S.SubmitRoster
            color='primary'
            isUpper
            disabled={isLockedRoster}
            onClick={onSubmitRoster}
          >
            Submit
          </S.SubmitRoster>
        </S.ActionWrapper>
        <S.LocksContainer>
          <BodyM $isUpper $color='grey400'>
            Locks
          </BodyM>
          <BodyM $isUpper $color='grey50'>
            {lockDate}
          </BodyM>
        </S.LocksContainer>
      </S.ButtonsHeader>
    </S.Container>
  );
};
