import * as React from 'react';
import { useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { color, font } from '../../../styles/styleVariables';

interface ItemMenu {
  id: string;
  title: string;
  onPress?: () => void;
}
interface ITabMenuProps {
  items: ItemMenu[];
  style?: CSSProperties;
  onChange?: (event: any) => void;
}

const TabMenu: React.FunctionComponent<ITabMenuProps> = (props) => {
  const { items, style, onChange } = props;
  const [tabSelected, setTabSelected] = useState(items[0]?.id);

  const handleOnChange = (event: any) => {
    setTabSelected(event);
    onChange?.call(null, event);
  };
  return (
    <TabMenuDiv style={style}>
      {items.map((item) => {
        return (
          <div
            className={`item ${tabSelected === item.id ? 'active' : ''}`}
            onClick={() => handleOnChange(item.id)}
          >
            {item.title}
          </div>
        );
      })}
    </TabMenuDiv>
  );
};

const TabMenuDiv = styled.div`
  display: flex;
  overflow-x: auto;
  .item {
    width: 50%;
    padding: 10px 20px;
    text-align: center;
    color: #fff;
    font-family: ${font.druk};
    text-transform: uppercase;
    font-size: 9px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    background: ${color.greyLight};
    align-items: center;
    border-bottom: 1px solid ${color.silver};
    &:first-child {
      border-radius: 10px 0 0 0;
    }
    &:last-child {
      border-radius: 0 10px 0 0;
    }
    &:hover {
      opacity: 0.5;
    }
    &.disabled {
      opacity: 0.5;
    }
    &.active {
      border-bottom: 2px solid #fff;
      &:hover {
        opacity: 1;
      }
    }
  }
`;

export default TabMenu;
