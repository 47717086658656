import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';
import { DocumentStatusLabel } from 'redux/v3/baseUsers/types';

export const ModalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0 ,0, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    borderRadius: toRem(12),
    background: COLORS.grey900,
    maxWidth: '384px',
    width: '100%',
    position: 'static',
    borderWidth: '0',
    padding: '0',
    margin: '0',
  },
};

export const ModalHeader = styled.div`
  padding: ${toRem(24)} ${toRem(24)} ${toRem(10)} ${toRem(24)};
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.grey600};

  .i-icon {
    display: flex;
    cursor: pointer;
    &::before {
      display: none;
    }
  }
`;
export const ModalBody = styled.div`
  padding: ${toRem(24)};
`;
export const ModalFooter = styled.div`
  padding: 0 ${toRem(24)} ${toRem(24)} ${toRem(24)};

  .tip-text {
    margin-top: ${toRem(8)};
  }
`;
export const ModalFooterInfo = styled.div`
  padding: ${toRem(24)};
  background-color: ${COLORS.grey800};

  .tip-text {
    margin-top: ${toRem(8)};
  }
`;
export const ModalContentUploadPhoto = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ModalContentWrapperPhoto = styled.div`
  .example-title {
    margin-bottom: 1rem;
  }
  img {
    border-radius: ${toRem(12)};
    max-width: 156px;
  }

  & + div {
    margin-left: ${toRem(24)};
  }

  .input-file-container {
    height: 156px;
  }
`;
export const WrapperImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 156px;
  background-color: ${COLORS.white};
  border-radius: ${toRem(12)};
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .button-approval {
    margin-top: 1rem;
  }
`;
export const EditPhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ChoseFileWrapper = styled.div<{
  $status?: DocumentStatusLabel;
  $type: 'circle' | 'square';
}>`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  border-radius: ${(props) => (props.$type === 'circle' ? '50%' : toRem(8))};
  width: ${(props) => (props.$type === 'circle' ? '200px' : '100%')};
  height: 200px;
  overflow: hidden;
  background-color: ${COLORS.white};
  border: 1px solid
    ${(props) => {
      switch (props.$status) {
        case DocumentStatusLabel.PENDING:
          return COLORS.grey200;
        case DocumentStatusLabel.APPROVED:
          return COLORS.brandPrimary;
        case DocumentStatusLabel.DECLINED:
          return COLORS.supportError;
        default:
          return COLORS.brandPrimary;
      }
    }};

  img {
    object-fit: cover;
    max-width: -webkit-fill-available;
  }
`;
export const PdfWrapper = styled.div`
  width: 100%;
  height: 200px;
  overflow-y: scroll;
`;
export const StatusHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;
export const DateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
