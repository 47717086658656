import * as React from 'react';
import {
  Delete as DeleteIcon,
  Info as InfoIcon,
  Plus as PlusIcon,
} from '@icon-park/react';

import RoundedCard, { RoundedCardProps } from '../RoundedCard/RoundedCard';

import * as S from './styles';
import { BodyLBold } from 'styles/v3/variables';

interface DraggableCardProps extends RoundedCardProps {
  title?: string;
  info?: string;
  active?: boolean;
  handleDelete?: (e: any) => void;
  handleAdd?: (e: any) => void;
  onMouseDown?: (e: any) => void;
  onMouseUp?: (e: any) => void;
  style?: object;
}

const DraggableCard: React.FC<DraggableCardProps> = ({
  title,
  info,
  active,
  style,
  className,
  onMouseDown,
  handleDelete,
  handleAdd,
  ...props
}) => {
  const handleClickInfo = () => {
    console.log('[OFF] INFO');
  };
  return (
    <S.DraggableCardWrapper
      style={style}
      className={`card-detail ${className ?? ''}`}
    >
      <RoundedCard {...props}>
        {title && active && <S.DragIcon onMouseDown={onMouseDown} />}
        <S.InfoContainer>
          <BodyLBold $color={active ? 'white' : 'grey400'}>{title}</BodyLBold>
        </S.InfoContainer>
        <S.IconsWrapper className='icons-wrapper'>
          {active && info && (
            <S.BgIcon onClick={handleClickInfo}>
              <InfoIcon size='24px' />
            </S.BgIcon>
          )}
          {handleDelete && (
            <S.BgIcon onClick={handleDelete}>
              <DeleteIcon size='24px' />
            </S.BgIcon>
          )}
          {!active && (
            <S.BgIcon onClick={handleAdd}>
              <PlusIcon size='24px' />
            </S.BgIcon>
          )}
        </S.IconsWrapper>
      </RoundedCard>
    </S.DraggableCardWrapper>
  );
};

export default DraggableCard;
