import { KeycloakInstance } from 'keycloak-js';
import KeycloakUtil from './KeycloakUtil';

export default class TokenUtil {
  public static async refreshToken(auth?: KeycloakInstance): Promise<void> {
    const keycloak = KeycloakUtil.getInstance();
    const token = localStorage.getItem('kc_token') || '';
    const refreshToken = localStorage.getItem('kc_refreshToken') || '';
    await keycloak
      .init({
        onLoad: 'check-sso',
        token,
        refreshToken,
        pkceMethod: 'S256',
        checkLoginIframe: false,
      })
      .then((authenticated) => {
        return keycloak.updateToken(5).then(() => {
          // Force refresh KeycloakInstance variable
          auth?.updateToken(999999);

          // Set Refreshed token in localStorage for use in API requests
          localStorage.setItem('kc_token', keycloak.token || '');
          localStorage.setItem('kc_refreshToken', keycloak.refreshToken || '');
          return keycloak.token;
        });
      });
  }
}
