import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AccountActionTypes } from './types';
import {
  accountFetchError,
  accountFetchSuccess,
  accountSetAccounts,
} from './actions';
import AccountServices from '../../services/Account/AccountApi';

function* handleFetch() {
  try {
    const res = yield call(AccountServices.getData);
    if (!res.success) {
      yield put(accountFetchError(res.error));
    } else {
      yield put(accountFetchSuccess(res.data));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      // yield put(accountFetchError(err.stack));
    } else {
      // yield put(accountFetchError('An unknown error occured.'));
    }
  }
}

function* handleFetchAccounts() {
  try {
    const res = yield call(AccountServices.getAccounts);
    if (!res.success) {
      yield put(accountFetchError(res.error));
    } else {
      yield put(accountSetAccounts(res.data));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      // yield put(accountFetchError(err.stack));
    } else {
      // yield put(accountFetchError('An unknown error occured.'));
    }
  }
}

function* watchFetchRequest() {
  yield takeEvery(AccountActionTypes.FETCH_REQUEST, handleFetch);
}
function* watchFetchAccounts() {
  yield takeEvery(AccountActionTypes.FETCH_ACCOUNTS, handleFetchAccounts);
}

function* accountSaga() {
  yield all([fork(watchFetchRequest), fork(watchFetchAccounts)]);
}

export default accountSaga;
