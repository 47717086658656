// eslint-disable-next-line import/no-extraneous-dependencies
import { Action, Location } from 'history';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import LightBox from '../../../../components/base/LightBox/LightBox';
import { SocketContext } from '../../../../context/SocketContext';
import { GamePeriod } from '../../../../models/GameEvent/GameEventModel';
import { MatchStatus } from '../../../../models/Match/MatchModel';
import {
  matchDetailsResendGameEventRequest,
  matchDetailsResendRemoveGameEventRequest,
  matchDetailsResetData,
  matchDetailsUpdateGamePreferences,
} from '../../../../redux/matchDetails/actions';
import { ApplicationState } from '../../../../redux/store';
import { color } from '../../../../styles/styleVariables';
import DigitalScoreboardConnect from './components/DigitalScoreboardConnect/DigitalScoreboardConnect';
import EndPeriodModal from './components/EndPeriodModal/EndPeriodModal';
import GameEventButtons from './components/GameEventButtons/GameEventButtons';
import GameResult from './components/GameResult/GameResult';
import LastEvents from './components/LastEvents/LastEvents';
import Timer from './components/Timer/Timer';

interface IScoresheetProps {
  editFinishedGame?: boolean;
}

const Scoresheet: React.FunctionComponent<IScoresheetProps> = (props) => {
  const { editFinishedGame } = props;
  const gameDuration = { normal: 1200, extra: 300 };
  const history = useHistory();
  const dispatch = useDispatch();

  const matchDetails = useSelector(
    (state: ApplicationState) => state.matchDetails
  );
  const [currentPeriodDuration, setCurrentPeriodDuration] = useState(
    gameDuration.normal
  );
  const [confirmLeave, setConfirmLeave] = useState({
    confirm: false,
    boxVisible: false,
    locationNext: '',
  });

  // Socket connection to DigitalScoreboard
  const { socket } = useContext(SocketContext);
  const emitUpdateTimerSocket = (time: string) => {
    socket?.emit('timeUpdate', { time: time });
  };

  const ticTimer = () => {
    if (matchDetails.preferences.timing.timerStart) {
      if (
        new Date().getTime() >
        matchDetails.preferences.timing.initialTime +
          matchDetails.preferences.timing.timerInSeconds * 1000 +
          500
      ) {
        // Update value in Redux
        dispatch(
          matchDetailsUpdateGamePreferences({
            timing: {
              ...matchDetails.preferences.timing,
              timerInSeconds:
                (new Date().getTime() -
                  matchDetails.preferences.timing.initialTime) /
                1000,
            },
          })
        );
        emitUpdateTimerSocket(
          (
            matchDetails.preferences.timing!.timerInSecondsTotal +
            matchDetails.preferences.timing!.timerInSeconds
          ).toString()
        );
      }

      // Check if period has finished
      if (
        currentPeriodDuration -
          (matchDetails.preferences.timing!.timerInSecondsTotal +
            matchDetails.preferences.timing!.timerInSeconds) <=
        0
      ) {
        dispatch(
          matchDetailsUpdateGamePreferences({
            timing: {
              ...matchDetails.preferences.timing,
              timerStart: false,
            },
          })
        );
      }
    }
  };

  const reSubmitFailedEvents = () => {
    matchDetails.data.matchEventsFailed?.map((event) => {
      dispatch(matchDetailsResendGameEventRequest(event));
    });

    matchDetails.data.matchEventsRemoveFailed?.map((eventId) => {
      dispatch(matchDetailsResendRemoveGameEventRequest(eventId));
    });
  };

  // Return all game events including failed persisted ones
  const getGameEvents = () => {
    return [
      ...(matchDetails.data.match?.matchEvents || []),
      ...(matchDetails.data.matchEventsFailed || []),
    ];
  };

  const handleBlockedNavigation = (nextLocation: Location, action: Action) => {
    if (!confirmLeave.confirm) {
      setConfirmLeave({
        ...confirmLeave,
        boxVisible: true,
        locationNext: nextLocation.pathname,
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (
      matchDetails.preferences.timing.period === GamePeriod.FIRST_HALF ||
      matchDetails.preferences.timing.period === GamePeriod.SECOND_HALF
    ) {
      setCurrentPeriodDuration(gameDuration.normal);
    } else {
      setCurrentPeriodDuration(gameDuration.extra);
    }
  }, [matchDetails.preferences.timing.period]);

  useEffect(() => {
    const interval = setInterval(() => {
      ticTimer();
    }, 100);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (confirmLeave.confirm) {
      // Reset Stored data
      dispatch(matchDetailsResetData());
      history.push(confirmLeave.locationNext);
    }
  }, [confirmLeave]);

  return (
    <>
      <DigitalScoreboardConnect
        matchData={{
          teamA: {
            color: matchDetails.preferences.colorTeamA || '#177ddc',
            abbv: matchDetails.data.match?.homeTeam.name || '',
            logo: matchDetails.data.match?.homeTeam.logo || '',
          },
          teamB: {
            color: matchDetails.preferences.colorTeamB || '#f94144ff',
            abbv: matchDetails.data.match?.awayTeam.name || '',
            logo: matchDetails.data.match?.awayTeam.logo || '',
          },
        }}
      />

      {matchDetails.data.match && (
        <GameResult
          matchDetails={matchDetails.data.match}
          gamePreferences={matchDetails.preferences}
        />
      )}
      {(getGameEvents().filter((item) => item.sendFailed === true).length > 0 ||
        (matchDetails.data.matchEventsRemoveFailed?.length || 0) > 0) && (
        <ConnectionError>
          Some events of this match were not sent correctly to the server. Click{' '}
          <b onClick={reSubmitFailedEvents}>here</b> to try resend it
        </ConnectionError>
      )}
      <Timer
        gameDuration={currentPeriodDuration}
        editFinishedGame={editFinishedGame}
      />

      {(matchDetails.data.match?.status !== MatchStatus.FINISHED ||
        editFinishedGame) && <GameEventButtons />}

      {getGameEvents() &&
        getGameEvents().length > 0 &&
        matchDetails.data.match?.status !== MatchStatus.FINISHED && (
          <LastEvents
            matchDetails={matchDetails}
            gameEvents={getGameEvents()}
          />
        )}
      <LightBox
        // Show EndPeriodModal only if time is over AND editOpen === false
        isOpen={
          currentPeriodDuration -
            (matchDetails.preferences.timing!.timerInSecondsTotal +
              matchDetails.preferences.timing!.timerInSeconds) <=
            0 &&
          !matchDetails.preferences.timing.editOpen &&
          matchDetails.data.match?.status !== MatchStatus.FINISHED
        }
      >
        <EndPeriodModal />
      </LightBox>
      <Prompt
        when={matchDetails.data.match?.status !== MatchStatus.FINISHED}
        message={handleBlockedNavigation}
      />
      <LightBox
        isOpen={confirmLeave.boxVisible}
        onClose={() =>
          setConfirmLeave({
            ...confirmLeave,
            boxVisible: false,
            locationNext: '',
          })
        }
      >
        {confirmLeave.boxVisible && (
          <ConfirmLeave>
            <div className='content'>
              This game is not finished. If you leave you will lost the time
              details.
              <br />
              Are you sure want to leave?
              <div style={{ margin: '10px 0' }}>
                <div
                  className='bt'
                  onClick={() =>
                    setConfirmLeave({
                      ...confirmLeave,
                      boxVisible: false,
                      confirm: true,
                    })
                  }
                  onKeyDown={() =>
                    setConfirmLeave({
                      ...confirmLeave,
                      boxVisible: false,
                      confirm: true,
                    })
                  }
                  role='button'
                  tabIndex={0}
                  aria-label='Close'
                >
                  Leave
                </div>
                <div
                  className='bt save-leave'
                  onClick={() =>
                    setConfirmLeave({
                      ...confirmLeave,
                      boxVisible: false,
                      locationNext: '',
                    })
                  }
                  onKeyDown={() =>
                    setConfirmLeave({
                      ...confirmLeave,
                      boxVisible: false,
                      locationNext: '',
                    })
                  }
                  role='button'
                  tabIndex={0}
                  aria-label='Cancel'
                >
                  Cancel
                </div>
              </div>
            </div>
          </ConfirmLeave>
        )}
      </LightBox>
    </>
  );
};

const ConnectionError = styled.div`
  width: 100%;
  padding: 10px 20px;
  background-color: #9c9ea0;
  border-radius: 5px;
  margin: 5px 0;
  color: #22252a;
  font-weight: 400;
`;

const ConfirmLeave = styled.div`
  .content {
    padding: 20px;
    background-color: ${color.greyLight};
    color: #fff;
    border-radius: 5px;
    z-index: 10;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    margin: 0 auto;
    width: 400px;
    .bt {
      display: inline-block;
      font-weight: 600;
      margin: 10px 10px;
      cursor: pointer;
      padding: 5px 15px;
      border-radius: 5px;
      color: #fff;
      &.save-leave {
        background-color: #fff;
        color: ${color.greyLight};
      }
    }
  }
`;

export default Scoresheet;
