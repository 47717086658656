import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const CourtsWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  padding: 0 ${toRem(30)} ${toRem(24)};
`;

export const CourtsFormWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  border-bottom: ${toRem(1)} solid ${COLORS.grey800};
  margin-bottom: ${toRem(10)};
`;

export const CourtsTitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${toRem(16)};

  button {
    background-color: transparent;
    border: none;
    font-size: ${toRem(16)};
    cursor: pointer;
  }
`;

export const CourtsForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`;

export const CourtInputs = styled.div`
  display: grid;
  grid-template-columns: ${toRem(236)} ${toRem(136)} ${toRem(50)};
  grid-column-gap: ${toRem(16)};
  padding-bottom: ${toRem(16)};
`;

export const TrashButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: ${COLORS.grey800};
  transition: ease-in-out 0.4s;

  &:hover {
    background: ${COLORS.grey700};
  }
  > span {
    height: 24px;
  }
`;

export const CourtsFieldset = styled.fieldset`
  display: flex;
  justify-content: space-between;
  column-gap: 16px;

  .general-event-input-date {
    width: 100%;
    & + .general-event-input-date {
      margin-left: 1.5rem;
    }
  }
`;
