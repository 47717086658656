import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

import { COLORS } from 'styles/v3/variables';

const getPermissionColor = (permission: string) => {
  switch (permission) {
    case 'COACH':
      return COLORS.supportNeutral;
    case 'CLUB_DIRECTOR':
    case 'TOURNAMENT_DIRECTOR':
      return COLORS.brandPrimary;
    default:
      return COLORS.grey500;
  }
};

export const Container =
  styled.div <
  { $permission: string } >
  `
  display: flex;
  padding: ${toRem(4)} ${toRem(16)};
  justify-content: center;
  align-items: center;
  gap: ${toRem(4)};
  text-transform: capitalize;

  border-radius: ${toRem(33)};
  border: ${toRem(1)} solid;
  border-color: ${({ $permission }) => getPermissionColor($permission)};
`;
