import { useMutation } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';

import RosterService from 'services/Rosters/RosterService';

export const useSubmitRoster = () => {
  return useMutation({
    mutationFn: async (rosterId: string) =>
      RosterService.submitFinalRoster(rosterId),
    onSuccess: async () => {
      notification.success({ message: 'Roster was submitted successfully' });
    },
    onError: (error: any) => {
      notification.error({
        message: 'Failed to submit Roster',
        description: error.message,
      });
    },
  });
};
