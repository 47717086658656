import React from 'react';

import { CheckoutBoxProps } from './types';
import * as S from './styles';
import { ClubSelect } from './components/ClubSelect/ClubSelect';
import { DivisionItem } from './components/DivisionItem/DivisionItem';
import { DivisionSummary } from './components/DivisionSummary/DivisionSummary';
import { ClubCoupon } from './components/ClubCoupon/ClubCoupon';

export const CheckoutBox = ({ event, price, divisions }: CheckoutBoxProps) => {
  return (
    <S.Container>
      <S.Box>
        <ClubSelect />
        <S.DivisionList>
          {divisions.map((d) => (
            <DivisionItem key={d.divisionId} division={d} price={price} />
          ))}
        </S.DivisionList>
        <S.HR />
        <DivisionSummary divisions={divisions} price={price} />
      </S.Box>
      <ClubCoupon
        eventId={event.id}
        rosterFeeProductId={event.data?.paymentInfo?.rosterFeeProductId}
      />
    </S.Container>
  );
};
