import React from 'react';
import { notification as antdNotification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import { CheckOne, CloseOne, Info } from '@icon-park/react';
import { BodyM, BodyMBold, COLORS } from 'styles/v3/variables';

antdNotification.config({
  duration: 5,
  placement: 'topRight',
});

export const notification = {
  error: ({ message, description, duration, ...rest }: ArgsProps) => {
    antdNotification.error({
      message: <BodyMBold $color='supportError'>{message}</BodyMBold>,
      description: <BodyM $color='grey300'>{description}</BodyM>,
      icon: <CloseOne />,
      duration,
      style: {
        color: COLORS.supportError,
        border: `1px solid ${COLORS.supportError}`,
      },
      ...rest,
    });
  },
  info: ({ message, description, duration, ...rest }: ArgsProps) => {
    antdNotification.warning({
      message: <BodyMBold $color='diamondTier'>{message}</BodyMBold>,
      description: <BodyM $color='grey300'>{description}</BodyM>,
      icon: <Info />,
      duration,
      style: {
        color: COLORS.diamondTier,
        border: `1px solid ${COLORS.diamondTier}`,
      },
      ...rest,
    });
  },
  success: ({ message, description, duration, ...rest }: ArgsProps) => {
    antdNotification.success({
      message: <BodyMBold $color='brandPrimary'>{message}</BodyMBold>,
      description: <BodyM $color='grey300'>{description}</BodyM>,
      icon: <CheckOne />,
      duration,
      style: {
        color: COLORS.brandPrimary,
        border: `1px solid ${COLORS.brandPrimary}`,
      },
      ...rest,
    });
  },
};
