import * as React from 'react';
import Icon, { IconType } from '@icon-park/react/es/all';

import { COLORS } from 'styles/v3/variables';
import Text, { TextProps } from '../Text/Text';
import * as S from './styles';

interface TextIconProps extends TextProps {
  icon: IconType;
}

const TextIcon: React.FC<TextIconProps> = ({
  children,
  icon,
  color,
  className,
  ...props
}) => {
  const iconColor: string = color ? COLORS[color] : 'white';
  return (
    <S.Container className={className} $color={color}>
      <Icon type={icon} color={iconColor} size='16' className='reset' />
      <Text color={color} {...props}>
        {children}
      </Text>
    </S.Container>
  );
};

export default TextIcon;
