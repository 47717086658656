import React, { FC, useMemo, useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setSignupClub, resetSignUpClubId } from 'redux/v3/signupEvent/actions';

import { useGetMyClubsByUserType } from 'hooks/v3/clubs/useGetMyClubsByUserType/useGetMyClubsByUserType';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import FilledButton from 'components/v3/Buttons/FilledButton';

import { TitleH2 } from 'styles/v3/variables';

import { ClubIcon } from './components/ClubIcon/ClubIcon';

import * as S from './styles';
import { useGetClubApplyRoleAndPath } from './useGetClubApplyRoleAndPath';

const SignUpAsClubDirector: FC = () => {
  const params: { eventId: string } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const [quickSelectedClubId, setQuickSelectedClubId] = useState('');
  const userRoleAndPath = useGetClubApplyRoleAndPath();

  const { data: myClubs } = useGetMyClubsByUserType(userRoleAndPath.role);

  const mySelectedClubs = useMemo(() => {
    if (!myClubs) return [];

    return myClubs.map((club) => ({
      ...club,
      selected: quickSelectedClubId === club.id,
    }));
  }, [quickSelectedClubId, myClubs]);

  const enableContinue = useMemo<boolean>(() => {
    return (
      mySelectedClubs.filter((club) => club.selected).length > 0 &&
      !!myClubs?.find((club) => club.id === quickSelectedClubId)
    );
  }, [mySelectedClubs, quickSelectedClubId]);

  const handleBack = () => {
    dispatch(resetSignUpClubId());

    history.push(`/v3/event/${params.eventId}`);
  };

  const handleSelectClub = (id: string) => {
    setQuickSelectedClubId(id);
  };

  const handleNextPage = () => {
    dispatch(setSignupClub(quickSelectedClubId));

    history.push(`/v3/event/${params.eventId}/${userRoleAndPath.path}`);
  };

  return (
    <S.SignUpAsClubDirectorContainer>
      <S.Header>
        <TextButtonWithIcon
          color='primary'
          align='flex-end'
          reverse
          icon='back'
          onClick={handleBack}
        >
          Go back
        </TextButtonWithIcon>
        <TitleH2>SELECT THE CLUB JOINING THE TOURNAMENT</TitleH2>
      </S.Header>
      <S.ClubListWrapper>
        {mySelectedClubs?.map((club) => (
          <ClubIcon
            onClick={() => handleSelectClub(club.id)}
            key={club.id}
            selected={club.selected}
            name={club.name}
            image={club.logo}
          />
        ))}
      </S.ClubListWrapper>
      <FilledButton
        className='btn-continue'
        disabled={!enableContinue}
        onClick={handleNextPage}
        color='primary'
        isUpper
      >
        Continue
      </FilledButton>
    </S.SignUpAsClubDirectorContainer>
  );
};

export default withRouter(SignUpAsClubDirector);
