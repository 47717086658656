import { SorterResult, SortOrder } from 'antd/lib/table/interface';

import { User } from 'models/User/UserModel';

const getCorrectOrder = (order?: SortOrder) => {
  if (order === 'ascend') {
    return 'asc';
  }

  if (order === 'descend') {
    return 'desc';
  }

  return null;
};

export const getSortParamsByKey = (
  sorting?: SorterResult<User> | SorterResult<User>[]
) => {
  if (Array.isArray(sorting)) {
    const params: string[] = [];

    sorting.forEach((item) => {
      const order = getCorrectOrder(item.order);

      if (order) {
        params.push(`${item.columnKey}:${order}`);
      }
    });

    return params.join(',');
  }

  if (sorting) {
    const order = getCorrectOrder(sorting.order);

    if (order) {
      return `${sorting.columnKey}:${order}`;
    }
  }

  return '';
};
