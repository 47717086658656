import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EventListView } from '../../admin/models/event/Event';
import EventService from '../../services/Event/EventService';
import { color, font } from '../../styles/styleVariables';
import ConvertUtil from '../../util/ConvertUtil';
import EventCard from '../base/EventCard/EventCard';

const eventTypes = [
  {
    title: 'TOURNAMENTS',
    includes: ['TOURNAMENT'],
  },
  {
    title: 'CAMPS',
    includes: ['CAMP'],
  },
  {
    title: 'COURSES',
    includes: ['COACH_COURSE', 'REFEREE_COURSE'],
  },
];

const EventsBox: React.FunctionComponent = () => {
  const [listEvent, setListEvent] = useState<any>([]);

  useEffect(() => {
    EventService.getActive().then((response) => {
      if (response.success) {
        setListEvent(response.data.content);
      }
    });
  }, []);
  return (
    <div>
      <div>
        {eventTypes.map((eventType) => {
          return (
            listEvent.find((event: EventListView) =>
              eventType.includes.includes(event.eventType)
            ) && (
              <div key={`event_${eventType.title}`}>
                <h4>{eventType.title}</h4>
                {listEvent
                  .filter((event: EventListView) =>
                    eventType.includes.includes(event.eventType)
                  )
                  .map((event: EventListView) => {
                    return (
                      <a
                        key={event.id}
                        href={event.link}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <EventCard event={event} key={`event_${event.id}`} />
                      </a>
                    );
                  })}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default EventsBox;
