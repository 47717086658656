import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';

export const InfoIconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    color: ${COLORS.supportError};
  }
`;

export const ErrorList = styled.ol`
  padding-left: 18px;
  padding-right: 4px;
  margin-bottom: 0;
`;

export const ErrorListItem = styled.li`
  font-family: 'Boxed Medium';
  font-size: 16px;
  list-style-type: disc;
`;
