import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import usePrimaryClub from '../../hooks/usePrimaryClub/usePrimaryClub';
import { UserStats } from '../../models/Stats/UserStatsModel';
import { ApplicationState } from '../../redux/store';
import StatsService from '../../services/Stats/StatsService';
import { gradient } from '../../styles/styleVariables';
import ConvertUtil from '../../util/ConvertUtil';
import CardInfoProfile from '../base/CardInfoProfile/CardInfoProfile';
import ClubBox from '../base/ClubBox/ClubBox';
import LocationBox from '../base/LocationBox/LocationBox';
import StatisticsBox from '../base/StatisticsBox/StatisticsBox';
import XpBar from '../base/XpBar/XpBar';

const ProfileCard: React.FunctionComponent = () => {
  const { user } = useSelector((state: ApplicationState) => state);

  const [userStats, setUserStats] = useState<{
    loading: boolean;
    data: UserStats[];
  }>({ loading: true, data: [] });

  const primaryClub = usePrimaryClub();
  const memberships = useSelector(
    (state: ApplicationState) => state.memberships
  );

  useEffect(() => {
    if (user.data.id) {
      setUserStats({ loading: true, data: [] });
      StatsService.getUserStats({
        userIdList: [user.data.id],
        timeFrame: 'CURRENT_SEASON',
      }).then((res) => {
        if (res.success) {
          setUserStats({ loading: false, data: res.data.content });
        } else {
          setUserStats({ loading: false, data: [] });
        }
      });
    }
  }, [user.data.id]);

  return (
    <div className='card-box lg'>
      <div className='content'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ClubBox
            clubName={primaryClub?.name || 'FREE AGENT'}
            membership={
              memberships.data.find(
                (item) => item.id === user.data.membershipId
              )?.name
            }
          />
          <LocationBox
            city={user.data.address?.city}
            country={user.data.address?.country}
          />
        </div>
        <CardInfoProfile
          name={user.data.firstName}
          mainName={user.data.lastName}
          nickname={
            user.data.playerInfo?.preferredName === 'NICKNAME' &&
            user.data.playerInfo?.nickName
              ? user.data.playerInfo?.nickName
              : ''
          }
          avatar={
            user.data.avatar
              ? ConvertUtil.getMinioUrl(user.data.avatar)
              : ConvertUtil.getMinioUrl(user.data.photo)
          }
          // customAvatar='/assets/imgs/Card_ronaldinho.png'
          // customAvatar='/assets/imgs/Card_CR.png'
          icon='/assets/imgs/Vault_logo_white.svg'
          // place={data.rank!}
          // level={user.data.playerInfo?.preferredPosition}
          speciality={` ${
            user.data.playerInfo?.specialty
              ? user.data.playerInfo?.specialty
              : ''
          } ${
            user.data.playerInfo?.preferredPosition
              ? user.data.playerInfo?.preferredPosition
              : ''
          }`}
          // preferedNumber={data.playerInfo?.preferredShirtNumber?.toString()}
          place={1}
          level={userStats.data[0]?.level ? userStats.data[0].level : 0}
          preferedNumber={user.data.playerInfo?.preferredShirtNumber?.toString()}
          bgGradient={gradient.orange}
          loading={userStats.loading}
        />
        <XpBar
          minVal={userStats.data[0]?.currentLevelXP}
          maxVal={userStats.data[0]?.nextLevelXP}
          currentVal={userStats.data[0]?.xp}
          multiplier=''
        />
        <StatisticsBox />
      </div>
    </div>
  );
};

export default ProfileCard;
