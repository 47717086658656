import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  CoachRosterItem,
  PlayerRosterItem,
  RosterMemberpool,
} from '../../../models/Roster/RosterModel';
import { memberpoolRosterFetchRequest } from '../../../redux/memberpoolRoster/actions';
import { rosterClearErrors } from '../../../redux/roster/actions';
import { ApplicationState } from '../../../redux/store';
import { color } from '../../../styles/styleVariables';
import DateUtil from '../../../util/DateUtil';
import LightBox from '../../base/LightBox/LightBox';
import MemberPoolSideboxRow from './MemberPoolSideboxRow';

interface IMemberPoolSidebox {
  rosterId: string;
  open?: boolean;
  type: 'COACHES' | 'PLAYERS';
  staffUnlock?: boolean;
}

const MemberPoolSidebox: React.FunctionComponent<IMemberPoolSidebox> = (
  props
) => {
  const { rosterId, open, type, staffUnlock } = props;

  const [filter, setFilter] = useState({
    year: '',
    gender: '',
    query: '',
  });

  const roster = useSelector((state: ApplicationState) => state.roster);
  const memberpoolRoster = useSelector(
    (state: ApplicationState) => state.memberpoolRoster
  );

  const [memberPool, setMemberPool] = useState<RosterMemberpool[]>();
  const [memberPoolFiltered, setMemberPoolFiltered] = useState<
    RosterMemberpool[]
  >();

  const [errorMessage, setErrorMessage] = useState({
    open: false,
    message: 'string',
  });

  const dispatch = useDispatch();

  function getYearsFromPool() {
    if (memberPool) {
      return Array.from(
        new Set(
          memberPool.map((item) =>
            new Date(item.birthDate?.toString()).getUTCFullYear()
          )
        )
      ).sort((a: any, b: any) => a - b);
    }
    return null;
  }

  const handleFilterChange = (
    event: React.FormEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setFilter({
      ...filter,
      ...(event.currentTarget.name === 'year' && {
        year: event.currentTarget.value,
      }),
      ...(event.currentTarget.name === 'gender' && {
        gender: event.currentTarget.value,
      }),
      ...(event.currentTarget.name === 'query' && {
        query: event.currentTarget.value,
      }),
    });
  };

  function evaluateRequirements(user: RosterMemberpool): boolean {
    // If staffUnlock. all players will be available
    if (staffUnlock) {
      return true;
    }
    if (
      !user.rosterAvailability.age ||
      !user.rosterAvailability.ageProof ||
      !user.rosterAvailability.document ||
      !user.rosterAvailability.gender ||
      //! user.rosterAvailability.membership ||
      !user.rosterAvailability.photo
    ) {
      return false;
    }
    return true;
  }

  const filterMemberPool = useCallback(() => {
    let memberPoolFilteredParsed = memberpoolRoster.data[
      type === 'COACHES' ? 'coaches' : 'players'
    ].filter(
      (item) =>
        // Filter out players already added on roster
        !(roster.data[type === 'COACHES' ? 'coaches' : 'players'] as (
          | PlayerRosterItem
          | CoachRosterItem
        )[])
          .map((user) => user.id)
          .includes(item.id)
    );

    if (filter.year) {
      memberPoolFilteredParsed = memberpoolRoster.data[
        type === 'COACHES' ? 'coaches' : 'players'
      ].filter(
        (item) =>
          DateUtil.getYear(new Date(item.birthDate?.toString())) === filter.year
      );
    }
    if (filter.gender) {
      memberPoolFilteredParsed = memberPoolFilteredParsed?.filter(
        (item) => item.gender === filter.gender
      );
    }

    if (filter.query) {
      memberPoolFilteredParsed = memberPoolFilteredParsed?.filter((item) =>
        `${item.firstName} ${item.lastName}`
          .toLocaleLowerCase()
          .includes(filter.query.toLocaleLowerCase())
      );
    }

    return memberPoolFilteredParsed;
  }, [memberpoolRoster, roster, filter]);

  const closeErrorBox = () => {
    setErrorMessage({ open: false, message: '' });
    dispatch(rosterClearErrors());
  };

  useEffect(() => {
    setMemberPoolFiltered(filterMemberPool());
  }, [filter, filterMemberPool]);

  useEffect(() => {
    if (roster.errors) {
      setErrorMessage({ open: true, message: roster.errors || '' });
    }
  }, [roster.errors]);

  useEffect(() => {
    dispatch(memberpoolRosterFetchRequest(rosterId));
  }, [rosterId]);

  return (
    <Container>
      <div className={`content ${open ? 'open' : ''}`}>
        <div className='fixed'>
          {type === 'COACHES' && <h3>Coaches Pool</h3>}
          {type === 'PLAYERS' && <h3>Player Pool</h3>}
          <Filters>
            <div style={{ display: 'flex' }}>
              <div className='c-year'>
                <select
                  name='year'
                  value={filter.year}
                  onChange={handleFilterChange}
                >
                  <option value='-' disabled>
                    Year Born
                  </option>
                  <option value=''>All</option>
                  {getYearsFromPool()?.map((year) => (
                    <option key={year?.toString()} value={year?.toString()}>
                      {year?.toString()}
                    </option>
                  ))}
                </select>
              </div>
              <div className=' c-gender'>
                <select
                  name='gender'
                  value={filter.gender}
                  onChange={handleFilterChange}
                >
                  <option value='' disabled>
                    Gender
                  </option>
                  <option value=''>Both</option>
                  <option value='MALE'>MALE</option>
                  <option value='FEMALE'>FEMALE</option>
                </select>
              </div>
            </div>
            <div className='form-group'>
              <input
                name='query'
                value={filter.query}
                onChange={handleFilterChange}
                type='text'
                className=''
                placeholder='Search'
              />
            </div>
          </Filters>
          <List>
            <div className='title'>
              Available Users for this Roster{' '}
              {staffUnlock && <div>*All Users Available</div>}
            </div>
            {memberPoolFiltered?.filter((member) =>
              evaluateRequirements(member)
            ).length ? (
              memberPoolFiltered
                ?.filter((member) => evaluateRequirements(member))
                .map((member) => (
                  <MemberPoolSideboxRow key={member.id} user={member} />
                ))
            ) : (
              <div className='not-found'>No Users Found</div>
            )}

            <div className='title'>Unavailable Users for this Roster</div>
            {memberPoolFiltered?.filter(
              (member) => !evaluateRequirements(member)
            ).length ? (
              memberPoolFiltered
                ?.filter((member) => !evaluateRequirements(member))
                .map((member) => (
                  <MemberPoolSideboxRow
                    key={member.id}
                    user={member}
                    inactive
                  />
                ))
            ) : (
              <div className='not-found'>No Users Found</div>
            )}
          </List>
          <div style={{ opacity: 0.8 }}>
            * <b>PENDING</b> status: still waiting UnitedFutsal approval.
          </div>
          <div style={{ opacity: 0.8 }}>
            ** <b>DECLINED</b> status: needs to be re-submited by the user.
          </div>
        </div>
      </div>

      <LightBox
        timeout={3000}
        isOpen={errorMessage.open}
        onClose={() => closeErrorBox()}
      >
        {/* TODO: Handle with dispatch error */}
        <ConfirmBox style={{ display: 'flex' }}>
          <h3>{errorMessage.message}</h3>
        </ConfirmBox>
      </LightBox>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 20px;
  width: 100%;
  height: calc(100% - 80px);
  position: absolute;
  overflow: hidden;
  .content {
    position: absolute;
    width: calc(100% - 40px);
    right: -100%;
    transition: all 0.5s;

    &.open {
      right: 0;
      z-index: 10;
    }
    .fixed {
      position: fixed;
    }
  }
`;

const Filters = styled.div`
  .c-year {
    padding-right: 15px;
  }
  .c-year,
  .c-gender {
    width: 50%;
    select {
      width: 100%;
      padding: 10px 12px;
      border-radius: 5px;
      border: none;
      background-color: ${color.greyLight};
      &:focus {
        outline: none;
      }
    }
  }
`;

const List = styled.div`
  max-height: 600px;
  overflow-y: auto;
  padding-right: 10px;
  .title {
    color: ${color.silverLight};
    padding: 15px;
  }
  .not-found {
    text-align: center;
    background: ${color.greyLight};
    padding: 5px;
    border-radius: 5px;
  }
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #000000;
  }
  ::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #666666;
  }
  ::-webkit-scrollbar-track:active {
    background: #333333;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;
const ConfirmBox = styled.div`
  padding: 30px 50px;
  border-radius: 20px;
  background-color: ${color.orange};
  width: 100%;
`;
export default MemberPoolSidebox;
