import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import ProductsServices from 'services/Products/ProductsServices';
import {
  b2bDraftEventFetchProductError,
  b2bDraftEventFetchProductSuccess,
} from './actions';
import { B2bDraftEventActionTypes } from './types';

function* handleFetch(): any {
  try {
    const res = yield call(ProductsServices.getB2bEventProducts);
    if (!res.success) {
      yield put(b2bDraftEventFetchProductError(res.error));
    } else {
      yield put(b2bDraftEventFetchProductSuccess(res.data));
    }
  } catch (err) {
    yield put(b2bDraftEventFetchProductError('An unknown error occurred.'));
  }
}

function* watchFetchRequest() {
  yield takeEvery(B2bDraftEventActionTypes.FETCH_PRODUCT_REQUEST, handleFetch);
}

function* b2bDraftEventSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default b2bDraftEventSaga;
