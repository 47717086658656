import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Roster } from '../../../models/Roster/RosterModel';
import EventService from '../../../services/Event/EventService';
import RosterService from '../../../services/Rosters/RosterService';
import { color, font } from '../../../styles/styleVariables';
import ConvertUtil from '../../../util/ConvertUtil';
import { EventView } from '../../models/event/Event';

const RosterPrint: FC = () => {
  const { rosterId } = useParams<{ rosterId: string }>();

  const [roster, setRoster] = useState<Roster>();
  const [event, setEvent] = useState<EventView>();

  function mapInSlices(array: any, sliceSize: any, sliceFunc: any) {
    const out = [];

    for (let i = 0; i < array.length; i += sliceSize) {
      const slice = array.slice(i, i + sliceSize);

      out.push(sliceFunc(slice, i));
    }

    return out;
  }

  useEffect(() => {
    RosterService.getRoster(rosterId).then((res) => {
      if (res.success) {
        setRoster(res.data);

        if (res.data.event?.id) {
          EventService.find(res.data.event?.id).then((response) => {
            if (response.success) {
              setEvent(response.data);
            }
          });
        }
      }
    });
  }, []);
  return (
    <PrintWrapper>
      {roster && (
        <>
          <div className='top'>
            <div className='box-tournament'>
              <div className='wrapper'>
                <div className='top-box'>
                  <div className='vault-icon' />
                  <div className='title'>
                    <div className='type'>Tournament</div>
                    <div className='name'>{roster.event?.name}</div>
                  </div>
                </div>
                <div className='logo'>
                  <div className='img'>
                    <img
                      src={ConvertUtil.getMinioUrl(roster.event?.logo)}
                      alt='logo'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='box-info'>
              <h4>{roster.name}</h4>
              <div>
                {`${roster?.ageDivision?.gender} 
                  ${
                    roster?.ageDivision?.years[0] === 1900
                      ? 'ADULT'
                      : roster?.ageDivision?.years.sort().join(', ')
                  }
                `}
              </div>
              <div className='table'>
                <div className='bold'>Location: </div>
                <div>{/* TODO: insert club Location */}</div>
                <div className='bold'>Dates: </div>
                <div>
                  {event?.startDate} - {event?.endDate}
                </div>
                <div className='bold'>Club: </div>
                <div>{roster.club?.name}</div>
                <div className='bold'>Club Director: </div>
                <div>{/* TODO: insert club director's name */}</div>
              </div>
            </div>
            <div className='box-club'>
              <div className='wrapper'>
                <div className='top-box'>
                  <div className='vault-icon' />
                  <div className='title'>
                    <div className='type'>Club</div>
                    <div className='name'>{roster.club?.name}</div>
                  </div>
                </div>
                <div className='logo'>
                  <div className='img'>
                    <img
                      src={ConvertUtil.getMinioUrl(roster.club?.logo)}
                      alt='logo'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '20px' }}>
            <h3>Players</h3>
            {mapInSlices(
              roster.players.sort((a, b) => a.number - b.number),
              16,
              (slice: any) => (
                <>
                  <PlayersTable>
                    {slice.map((player: any) => (
                      <>
                        <PlayerCard>
                          <div className='number'>{player.number}</div>
                          <div>
                            <div className='image'>
                              <img
                                src={ConvertUtil.getMinioUrl(player.photo)}
                                alt='avatar'
                              />
                            </div>
                            <div className='name'>
                              {player.firstName} {player.lastName}
                            </div>
                          </div>
                        </PlayerCard>
                      </>
                    ))}
                  </PlayersTable>
                </>
              )
            )}
            <h3>Coaches</h3>
            <PlayersTable>
              {roster.coaches.map((coach) => (
                <PlayerCard>
                  <div>
                    <div className='image'>
                      <img
                        src={ConvertUtil.getMinioUrl(coach.photo)}
                        alt='avatar'
                      />
                    </div>
                    <div className='name'>
                      {coach.firstName} {coach.lastName}
                    </div>
                  </div>
                </PlayerCard>
              ))}
            </PlayersTable>
          </div>
        </>
      )}
    </PrintWrapper>
  );
};

const PrintWrapper = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-weight: 400;
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
  .top {
    width: 100%;
    display: flex;
    .box-tournament,
    .box-club {
      width: 200px;
      .wrapper {
        padding: 20px;
        border-radius: 10px;
        border: 2px solid ${color.greyDark};

        .top-box {
          .title {
            .type {
              font-size: 10px;
            }
            .name {
              font-family: ${font.druk};
              font-size: 12px;
            }
          }
        }
        .logo {
          width: 100px;
          height: 100px;
          border-radius: 80px;
          border: 1px solid #ccc;
          margin: 0 auto;
          margin-top: 20px;
          background-color: ${color.greyDark};
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          display: flex;
          align-items: center;
          .img {
            width: 70%;
            margin: 0 auto;

            img {
              width: 100%;
            }
          }
        }
      }
    }
    .box-info {
      text-align: center;
      padding: 10px;
      flex: 1;
      .table {
        text-align: left;
        display: grid;
        grid-column-gap: 5px;
        grid-template-columns: 1fr 2fr;
        width: 80%;
        margin: 20px auto;
      }
    }
  }
`;
const PlayersTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
`;
const PlayerCard = styled.div`
  padding: 10px;
  border-radius: 10px;
  display: flex;
  .number {
    font-weight: 600;
    font-size: 30px;
    padding: 0 10px;
  }
  .image {
    border-radius: 5px;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
    img {
      width: 100%;
    }
  }
`;
export default RosterPrint;
