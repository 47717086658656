import React from 'react';
import { Down } from '@icon-park/react';

import ConvertUtil from 'util/ConvertUtil';
import { BodyL } from 'styles/v3/variables';
import * as S from './styles';
import { ClubSelectProps } from './types';

export const ClubSelect = ({ club }: ClubSelectProps) => {
  return (
    <S.Container>
      <S.Select
        disabled
        bordered={false}
        suffixIcon={<Down color='transparent' size={24} />}
        key={club.id}
        defaultValue={club.id}
      >
        <S.Option key={club.id} value={club.id}>
          <S.OptionWrapper>
            <S.Image $src={ConvertUtil.getMinioUrl(club.logo)} />
            <BodyL>{club.name}</BodyL>
          </S.OptionWrapper>
        </S.Option>
      </S.Select>
    </S.Container>
  );
};
