import { action } from 'typesafe-actions';
import { UserActionTypes } from './types';
import { User } from '../../models/User/UserModel';

export const userFetchRequest = (id: string) =>
  action(UserActionTypes.FETCH_REQUEST, id);

export const userFetchSuccess = (data: User) =>
  action(UserActionTypes.FETCH_SUCCESS, data);

export const userFetchError = (message: string) =>
  action(UserActionTypes.FETCH_ERROR, message);

export const userUpdateActive = (message: User) =>
  action(UserActionTypes.UPDATE_ACTIVE, message);

export const userUpdate = (data: User) => action(UserActionTypes.UPDATE, data);

// export const selectTeam = (teamId: string) =>
//     action(TeamsActionTypes.SELECT_TEAM, teamId);

// export const teamSelected = (team: TeamSelectedPayload) =>
//   action(TeamsActionTypes.SELECTED, team);
