import { action } from 'typesafe-actions';
import { ClubActionTypes, Club } from './types';

export const myClubsFetchRequest = () => action(ClubActionTypes.FETCH_REQUEST);

export const myClubsFetchSuccess = (data: Club[]) =>
  action(ClubActionTypes.FETCH_SUCCESS, data);

export const myClubsFetchError = (message: string) =>
  action(ClubActionTypes.FETCH_ERROR, message);
