import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.grey900};

  padding: ${toRem(8)} ${toRem(8)} ${toRem(8)} ${toRem(16)};
  border-radius: ${toRem(12)};
  gap: ${toRem(8)};
`;

export const LogoTeam = styled.div`
  width: ${toRem(40)};
  height: ${toRem(40)};
  border-radius: ${toRem(40)};

  img {
    background-size: cover;
    width: ${toRem(40)};
    height: ${toRem(40)};
    border-radius: ${toRem(40)};
  }
`;
