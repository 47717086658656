import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { BodyS } from 'styles/v3/variables';
import DateUtil from 'util/DateUtil';
import { AllEventInvites } from 'services/v3/Event/types';
import { SimpleTag } from '../../../SimpleTag/SimpleTag';
import { ActionMenu } from './components';

export const columns: ColumnsType<AllEventInvites> = [
  {
    title: 'First Name',
    dataIndex: ['referee', 'firstName'],
    render(value) {
      return <BodyS>{value}</BodyS>;
    },
  },
  {
    title: 'Last Name',
    dataIndex: ['referee', 'lastName'],
    render(value) {
      return <BodyS>{value}</BodyS>;
    },
  },
  {
    title: 'Birth Year',
    dataIndex: ['referee', 'birthDate'],
    render(value) {
      return <BodyS>{DateUtil.getYear(new Date(value))}</BodyS>;
    },
  },
  {
    title: 'Phone',
    dataIndex: ['referee', 'phone'],
    render(value) {
      return <BodyS>{value}</BodyS>;
    },
  },
  {
    title: 'Email',
    dataIndex: ['referee', 'email'],
    render(value) {
      return <BodyS>{value}</BodyS>;
    },
  },
  {
    title: 'Status',
    dataIndex: ['invite', 'status'],
    render(value) {
      let type = 'info';

      if (value === 'DENIED') type = 'error';
      if (value === 'ACCEPTED') type = 'success';

      return <SimpleTag text={value} type={type as any} />;
    },
  },
  {
    title: '',
    render(_, record) {
      return <ActionMenu itemId={record.invite.receiverId} />;
    },
  },
];
