import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const SliderStyle = styled.div<{ $newStyle?: boolean }>`
  margin-top: ${(props) => (props.$newStyle ? '1.5rem' : 0)};

  .antd-img-crop-control {
    display: flex;
    align-items: center;
    width: ${(props) => (props.$newStyle ? '100%' : '60%')};
    margin-left: auto;
    margin-right: auto;

    & + .antd-img-crop-control {
      margin-top: ${(props) => (props.$newStyle ? '1rem' : 0)};
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;
      padding: 0;
      font-style: normal;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
    }

    .ant-slider {
      flex: 1;
      margin: ${(props) => (props.$newStyle ? '0 1rem' : '0 8px')};
    }
    .i-icon {
      display: flex;

      &.icon-return-revert {
        transform: rotate(178deg);
      }
    }
    .ant-slider-rail {
      background-color: ${(props) =>
        props.$newStyle ? COLORS.grey600 : '#262626'};
      border-radius: ${(props) => (props.$newStyle ? '25px' : '2px')};
    }
    .ant-slider-track {
      background-color: ${(props) =>
        props.$newStyle ? COLORS.brandPrimary : '#153450'};
    }
    .ant-slider-handle {
      background-color: ${(props) =>
        props.$newStyle ? COLORS.white : '#141414'};
      border-color: ${(props) => (props.$newStyle ? COLORS.white : '#153450')};
    }
    .ant-slider-rail,
    .ant-slider-track,
    .ant-slider-step {
      height: ${(props) => (props.$newStyle ? '6px' : '4px')};
    }
  }
`;
export const IconWrapper = styled.div<{ $disabled: boolean }>`
  display: flex;
  opacity: ${(props) => (props.$disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.$disabled ? 'none' : 'all')};
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${toRem(32)};
`;
