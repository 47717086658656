import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const FindWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(18)};

  flex: 1;
`;

export const FilterText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${toRem(8)};

  span {
    margin-top: ${toRem(6)};
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(18)};
`;

export const FilterButton = styled.div`
  display: flex;

  gap: ${toRem(8)};

  cursor: pointer;

  span {
    color: ${COLORS.grey400};
  }
`;

export const FallbackMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: ${toRem(32)};

  gap: ${toRem(4)};
`;
