import { message } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { ClubView } from '../../admin/models/club/Club';
import { RequestStatus } from '../../models/Request/RequestModel';
import ClubService from '../../services/Club/ClubService';
import { color, font, gradient } from '../../styles/styleVariables';
import ConvertUtil from '../../util/ConvertUtil';
import { getUserId } from '../../util/UsersUtil';
import ConfirmBox from '../base/ConfirmBox/ConfirmBox';
import LocationBox from '../base/LocationBox/LocationBox';
import Button from '../uielements/Button/Button';

interface IClubCardRequestProps {
  club: ClubView;
  teamIcon?: string;
  teamLogo?: string;
  clubName?: string;
  statusRequest?: RequestStatus;
  newButton?: boolean;
  onClick?: () => void;
}

const ClubCardRequest: React.FunctionComponent<IClubCardRequestProps> = (
  props
) => {
  const { teamIcon, statusRequest, club } = props;
  const [requested, setRequested] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isARemovedUser, setIsARemovedUser] = useState<boolean>(false);

  const sendRequest = (clubId: string) => {
    ClubService.requestJoin({
      clubId: club.id,
      userId: getUserId(),
    }).then((response) => {
      if (response.success) {
        setRequested(true);
        localStorage.setItem(
          'vault_preferences',
          JSON.stringify({ clubPopupHide: true })
        );
      } else {
        message.error(response.message, 3);
      }
    });
  };
  const cancelRequest = () => {
    setConfirmOpen(false);
  };
  const confirmRequest = () => {
    setConfirmOpen(false);
    sendRequest(club.id);
  };

  React.useEffect(() => {
    const userId = getUserId();
    if (userId) {
      const isACoach = club.coachIds?.includes(userId);
      const isAPlayer = club.playerIds?.includes(userId);

      if (!isACoach && !isAPlayer) {
        setIsARemovedUser(true);
      }
    }
  }, []);

  return (
    <BoxWrapper>
      <Box>
        <div className='top'>
          {/* <MembersBox members={3000} /> */}
          <LocationBox city={club?.city} country={club?.country} />
        </div>
        <div>
          <BoxClubLogo>
            <TopInfo>
              <div className='team-logo'>
                <img alt='' src={teamIcon} />
              </div>
              <div style={{ marginLeft: '15px' }}>
                <div className='box-first-name'>Club</div>
                <div className='box-last-name'>{club.name}</div>
              </div>
            </TopInfo>

            <PhotoBox>
              <div className='profile-image-wrapper'>
                <div
                  className='profile-image-img'
                  style={{
                    backgroundImage: `url(" ${ConvertUtil.getMinioUrl(
                      club.logo
                    )}")`,
                  }}
                />
              </div>
            </PhotoBox>
          </BoxClubLogo>
        </div>
        {((statusRequest === RequestStatus.PENDING || requested) && (
          <Button
            disabled={statusRequest === RequestStatus.PENDING || requested}
            onClick={() => setConfirmOpen(true)}
          >
            Request Sent
          </Button>
        )) ||
          (!isARemovedUser && statusRequest === RequestStatus.APPROVED && (
            <Button disabled>Approved</Button>
          )) ||
          (isARemovedUser && (
            <Button
              disabled={statusRequest === RequestStatus.PENDING || requested}
              onClick={() => setConfirmOpen(true)}
            >
              Send Request
            </Button>
          ))}
      </Box>

      <ConfirmBox
        isOpen={confirmOpen}
        onClose={cancelRequest}
        onConfirm={confirmRequest}
        onCancel={cancelRequest}
        title='Are you sure you want to request to be part of this Club?'
        labelConfirm='Yes'
        labelCancel='Cancel'
      />
    </BoxWrapper>
  );
};

const Box = styled.div`
  width: 320px;
  border-radius: 20px;
  padding: 20px;
  background-color: ${color.greyLight};
  @media (max-width: 1100px) {
    width: 250px;
    padding: 10px;
  }
  .top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const TopInfo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  .team-logo {
    width: 45px;
    height: 50px;
    padding-right: 15px;
    border-right: solid 1px ${color.silverLight};
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .box-first-name {
    line-height: 5px;
  }
  .box-last-name {
    font-family: ${font.druk};
    font-size: 18px;
    overflow: hidden;
  }
`;

const PhotoBox = styled.div`
  position: relative;
  .profile-image-wrapper {
    width: 170px;
    height: 170px;
    border-radius: 300px;
    background-color: ${color.silverLightShadow};
    padding: 10px;
    z-index: 10;
    margin: 0 auto;
    margin-top: 10px;
    @media (max-width: 1100px) {
      width: 140px;
      height: 140px;
    }
    .profile-image-img {
      width: 150px;
      height: 150px;
      border-radius: 300px;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-color: #fff;
      @media (max-width: 1100px) {
        width: 120px;
        height: 120px;
      }
      img {
        width: 100%;
      }
    }
  }
`;

const BoxWrapper = styled.div`
  width: 320px;
  margin: 10px;
  @media (max-width: 1100px) {
    width: 250px;
  }
`;

const BoxClubLogo = styled.div`
  height: 272px;
  border-radius: 20px;
  padding: 20px;
  background: ${gradient.orange};
  margin-top: 20px;
  @media (max-width: 1100px) {
    height: 240px;
  }
`;

export default ClubCardRequest;
