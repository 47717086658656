import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CameraOutlined,
  FileTextOutlined,
  IdcardOutlined,
} from '@ant-design/icons/lib';
import { Button, message, Space, Table, Tooltip } from 'antd';
import {
  ColumnsType,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { replace, debounce } from 'lodash';

import { RequestStatus } from 'models/Request/RequestModel';

import { ApplicationState } from 'redux/store';

import DocumentApprovalBox from '../../../components/base/DocumentApprovalBox/DocumentApprovalBox';
import PageContainer from '../../../components/base/PageContainer/PageContainer';
import GroupBox from '../../../components/uielements/GroupBox/GroupBox';
import { User } from '../../../models/User/UserModel';
import { membershipsFetchRequest } from '../../../redux/memberships/actions';
import DocumentsVerificationService from '../../../services/DocumentsVerification/DocumentsVerificationService';
import UserServices from '../../../services/User/UserApi';

import { getSortParamsByKey } from './utils';

import * as S from './styles';

import './styles.css';

const INITIAL_PAGINATION = {
  current: 1,
  pageSize: 10,
  total: 0,
  pageSizeOptions: ['10', '30', '50', '100'],
};

const DocumentsApprovalListPage: FC = () => {
  const dispatch = useDispatch();

  const memberships = useSelector(
    (state: ApplicationState) => state.memberships
  );

  const [searchValue, setSearchValue] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [sorting, setSorting] = useState<string>('');
  const [pagination, setPagination] = useState<TablePaginationConfig>(
    INITIAL_PAGINATION
  );

  const [documentBoxOpen, setDocumentBoxOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState<string>('');
  const [documentTypeState, setDocumentTypeState] = useState<
    'photo' | 'age' | 'documents'
  >('photo');
  const [currentUser, setCurrentUser] = useState<User>();
  const [feedbackText, setFeedbackText] = useState<string>('');

  const fetchUsers = useCallback(
    async (
      inputPagination: TablePaginationConfig,
      inputSorting: string,
      search?: string
    ) => {
      setIsLoading(true);

      const getCurrentPage = () => {
        if (typeof inputPagination.current === 'number') {
          return inputPagination.current - 1;
        }

        return 0;
      };

      const params = new URLSearchParams({
        page: getCurrentPage().toString(),
        size: inputPagination.pageSize?.toString() ?? '10',
      });

      if (inputSorting.length) {
        params.set('sort', inputSorting);
      }

      await UserServices.quickSearch({
        urlParams: params,
        body: {
          ...(search?.length && { quickSearch: search }),
          pendingDocumentation: true,
        },
      }).then((response) => {
        if (response.success) {
          setUsers(response.data.content);
          setIsLoading(false);

          setPagination((prevState) => ({
            ...prevState,
            current: response.data.page + 1,
            total: response.data.total,
          }));
        } else {
          setIsLoading(false);

          message.error({ content: response.message, duration: 5 });
        }
      });
    },
    []
  );

  const approveDocument = useCallback(() => {
    const key = 'documents';

    message.loading({ content: 'Loading...', key });

    if (currentUser) {
      const id = currentUser.baseUserId
        ? currentUser.baseUserId
        : currentUser.id;

      DocumentsVerificationService.approve(
        id,
        documentTypeState,
        !!currentUser.baseUserId
      ).then((response) => {
        if (response.success) {
          message.success({
            content: 'Document analysis performed.',
            key,
            duration: 3,
          });

          fetchUsers(pagination, sorting);
        } else {
          message.error({ content: response.message, key, duration: 3 });
        }
      });
    }
  }, [currentUser, documentTypeState, pagination, sorting, fetchUsers]);

  const rejectDocument = useCallback(() => {
    const key = 'documents';

    message.loading({ content: 'Loading...', key });

    if (currentUser) {
      const id = currentUser.baseUserId
        ? currentUser.baseUserId
        : currentUser.id;

      DocumentsVerificationService.decline(
        id,
        documentTypeState,
        feedbackText,
        !!currentUser.baseUserId
      ).then((response) => {
        if (response.success) {
          message.success({
            content: 'Document analysis performed.',
            key,
            duration: 3,
          });

          fetchUsers(pagination, sorting);
        } else {
          message.error({ content: response.message, key, duration: 3 });
        }
      });
    }
  }, [
    currentUser,
    documentTypeState,
    feedbackText,
    pagination,
    sorting,
    fetchUsers,
  ]);

  const handleTableChange = useCallback(
    (_, __, sorter?: SorterResult<User> | SorterResult<User>[]) => {
      const getSorting = getSortParamsByKey(sorter);

      setSorting(getSorting);

      fetchUsers(pagination, getSorting);
    },
    [pagination, fetchUsers]
  );

  const handlePaginationTableChange = useCallback(
    (page: number, size?: number) => {
      setPagination((prevState) => ({
        ...prevState,
        page,
      }));

      fetchUsers(
        {
          ...pagination,
          current: page,
          pageSize: size,
        },
        sorting
      );
    },
    [sorting, pagination]
  );

  const handleChangePaginationSize = useCallback(
    (_, size: number) => {
      setPagination((prevState) => ({
        ...prevState,
        current: 1,
        pageSize: size,
      }));

      fetchUsers(
        {
          ...pagination,
          current: 1,
          pageSize: size,
        },
        sorting
      );
    },
    [sorting, pagination]
  );

  const confirmRequest = useCallback(() => {
    approveDocument();
    setDocumentBoxOpen(false);
  }, [approveDocument]);

  const rejectRequest = useCallback(() => {
    rejectDocument();
    setDocumentBoxOpen(false);
  }, [rejectDocument]);

  const openDialog = useCallback(
    async (user: User, documentType: 'photo' | 'age' | 'documents') => {
      setFeedbackText('');
      setCurrentUser(user);
      setDocumentTypeState(documentType);

      if (documentType === 'photo') {
        setDocumentUrl(user.photo || '');
        setDocumentBoxOpen(true);
      }

      if (documentType === 'age') {
        setDocumentUrl(user.ageProof || '');
        setDocumentBoxOpen(true);
      }

      if (documentType === 'documents') {
        setDocumentUrl(user.extraInfo?.certificate || '');
        setDocumentBoxOpen(true);
      }
    },
    []
  );

  const photoStatus = useCallback(
    (user: User) => {
      let content;

      if (user.photoVerified === RequestStatus.PENDING) {
        content = (
          <Tooltip
            placement='topLeft'
            title={user?.photo ? 'Photo' : 'The user’s photo did not upload'}
          >
            <Button
              shape='circle'
              disabled={!user?.photo}
              icon={<CameraOutlined />}
              onClick={() => openDialog(user, 'photo')}
            />
          </Tooltip>
        );
      } else if (user.photoVerified === RequestStatus.APPROVED) {
        content = (
          <Tooltip placement='topLeft' title='Photo Approved'>
            {' '}
            <CameraOutlined
              style={{ color: '#00cc00', fontSize: '20px' }}
            />{' '}
          </Tooltip>
        );
      } else {
        content = (
          <Tooltip placement='topLeft' title='Photo Declined'>
            {' '}
            <CameraOutlined
              style={{ color: '#ff0000', fontSize: '20px' }}
            />{' '}
          </Tooltip>
        );
      }

      return content;
    },
    [openDialog]
  );

  const ageStatus = useCallback(
    (user: User) => {
      let content;

      if (user.ageVerified === RequestStatus.PENDING) {
        content = (
          <Tooltip
            placement='topLeft'
            title={
              user?.ageProof
                ? 'Age certificate'
                : 'The user’s age certificate did not upload'
            }
          >
            <Button
              shape='circle'
              disabled={!user?.ageProof}
              icon={<IdcardOutlined />}
              onClick={() => openDialog(user, 'age')}
            />
          </Tooltip>
        );
      } else if (user.ageVerified === RequestStatus.APPROVED) {
        content = (
          <Tooltip placement='topLeft' title='Age certificate Approved'>
            {' '}
            <IdcardOutlined
              style={{ color: '#00cc00', fontSize: '20px' }}
            />{' '}
          </Tooltip>
        );
      } else {
        content = (
          <Tooltip placement='topLeft' title='Age certificate Declined'>
            {' '}
            <IdcardOutlined
              style={{ color: '#ff0000', fontSize: '20px' }}
            />{' '}
          </Tooltip>
        );
      }

      return content;
    },
    [openDialog]
  );

  const documentStatus = useCallback(
    (user: User) => {
      let content;

      if (user.documentationVerified === RequestStatus.PENDING) {
        content = (
          <Tooltip
            placement='topLeft'
            title={
              user?.extraInfo?.certificate
                ? 'Extra Documents'
                : 'The user’s extra documents did not upload'
            }
          >
            <Button
              shape='circle'
              disabled={!user?.extraInfo?.certificate}
              icon={<FileTextOutlined />}
              onClick={() => openDialog(user, 'documents')}
            />
          </Tooltip>
        );
      } else if (user.documentationVerified === RequestStatus.APPROVED) {
        content = (
          <Tooltip placement='topLeft' title='Documents Approved'>
            {' '}
            <FileTextOutlined
              style={{ color: '#00cc00', fontSize: '20px' }}
            />{' '}
          </Tooltip>
        );
      } else {
        content = (
          <Tooltip placement='topLeft' title='Documents Declined'>
            {' '}
            <FileTextOutlined
              style={{ color: '#ff0000', fontSize: '20px' }}
            />{' '}
          </Tooltip>
        );
      }

      return content;
    },
    [openDialog]
  );

  const getMembershipLabel = useCallback(
    (userId?: string, membershipType?: string) => {
      const currentMembership = memberships.data.find(
        (membership) => membership.id === userId
      );

      if (currentMembership) {
        return currentMembership.name;
      }

      if (membershipType) {
        return membershipType;
      }

      return '';
    },
    [memberships]
  );

  const fetchUsersBySearch = useCallback(
    debounce(
      (
        inputPagination: TablePaginationConfig,
        inputSorting: string,
        search: string
      ) => {
        fetchUsers(inputPagination, inputSorting, search);
      },
      250
    ),
    [fetchUsers]
  );

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);

      fetchUsersBySearch(pagination, sorting, event.target.value);
    },
    [pagination, sorting]
  );

  const columns: ColumnsType<User> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'firstName',
        key: 'firstName',
        sorter: true,
        width: 135,
        render: (_: string, record: User) =>
          replace(
            `${record.firstName} ${record.middleName} ${record.lastName} ${record.suffix}`,
            /undefined/gi,
            ''
          ),
      },
      {
        title: 'Creation Date',
        dataIndex: 'creationDate',
        key: 'creationDate',
        sorter: true,
        width: 120,
        render: (date: string) => moment(date).format('MM/DD/YYYY'),
      },
      {
        title: 'Gender',
        dataIndex: 'gender',
        key: 'gender',
        width: 92,
        sorter: true,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        sorter: true,
        width: 140,
        render: (type: string) => type,
      },
      {
        title: 'Membership',
        key: 'membershipType',
        sorter: true,
        width: 160,
        render: (user: User) =>
          getMembershipLabel(user.membershipId, user.membershipType),
      },
      {
        title: 'Date of birth',
        dataIndex: 'birthDate',
        key: 'birthDate',
        sorter: true,
        width: 120,
        render: (date: string) => moment(date).format('MM/DD/YYYY'),
      },
      {
        title: 'Pending approval',
        key: 'action',
        width: 155,
        // eslint-disable-next-line react/display-name
        render: (_: string, user: User) => (
          <Space size='middle'>
            {photoStatus(user)}
            {ageStatus(user)}
            {documentStatus(user)}
          </Space>
        ),
      },
    ],
    [photoStatus, ageStatus, documentStatus, getMembershipLabel]
  );

  useEffect(() => {
    fetchUsers(INITIAL_PAGINATION, '');
  }, []);

  useEffect(() => {
    dispatch(membershipsFetchRequest());
  }, [dispatch]);

  return (
    <PageContainer title='Document Analysis'>
      <GroupBox title='List'>
        <div className='form-group'>
          <span className='icon icon-search' />
          <input
            name='search'
            value={searchValue}
            type='text'
            className='icon'
            placeholder='Search'
            autoComplete='off'
            onChange={handleSearchChange}
          />
        </div>
        <Table
          rowKey='id'
          dataSource={users}
          loading={isLoading}
          pagination={false}
          columns={columns}
          onChange={handleTableChange}
        />
        <S.TablePagination
          showSizeChanger
          current={pagination.current}
          defaultPageSize={10}
          total={pagination.total}
          onChange={handlePaginationTableChange}
          onShowSizeChange={handleChangePaginationSize}
        />
      </GroupBox>
      <DocumentApprovalBox
        isOpen={documentBoxOpen}
        onClose={() => setDocumentBoxOpen(false)}
        onConfirm={confirmRequest}
        onCancel={rejectRequest}
        documentType={documentTypeState}
        setFeedback={setFeedbackText}
        title='Check the uploaded file'
        documentUrl={documentUrl}
        user={currentUser}
        labelConfirm='Approve'
        labelCancel='Reject'
      />
    </PageContainer>
  );
};

export default DocumentsApprovalListPage;
