import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const Container =
  styled.div <
  { isSelected: boolean } >
  `
  background-color: ${COLORS.grey900};
  display: flex;
  align-items: center;
  gap: ${toRem(8)};

  border-radius: ${toRem(24)};
  padding: ${toRem(16)};

  border: ${({ isSelected }) =>
    isSelected ? `${toRem(1)} solid ${COLORS.brandPrimary}` : `none;`};

  cursor: pointer;
  transition: ease-in-out 0.5s;
  :hover {
    background-color: ${COLORS.grey800};
  }
`;

export const UserImage = styled.div`
  width: ${toRem(62)};
  height: ${toRem(62)};
  border-radius: ${toRem(100)};

  margin: 0;
  overflow: hidden;

  background-color: ${COLORS.grey50};

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(62)};
    height: ${toRem(62)};
  }
`;
export const UserInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(8)};
`;

export const UserBasicsContainer = styled.div`
  display: flex;
`;
