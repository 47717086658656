import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  column-gap: 24px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-end;
`;
