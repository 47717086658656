import { message } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LightBox from '../../../../../components/base/LightBox/LightBox';
import LoadingIcon from '../../../../../components/base/LoadingIcon/LoadingIcon';
import Button from '../../../../../components/uielements/Button/Button';
import { MatchStatus, Player } from '../../../../../models/Match/MatchModel';
import {
  matchDetailsFetchRequest,
  matchDetailsUpdate,
} from '../../../../../redux/matchDetails/actions';
import { ApplicationState } from '../../../../../redux/store';
import RosterService from '../../../../../services/Rosters/RosterService';
import ConvertUtil from '../../../../../util/ConvertUtil';
import {
  Card,
  PlayerCard,
  RosterWrapper,
  TeamBtWrapper,
} from './RosterCheck.style';

interface IRosterCheckProps {}

interface AttendanceList {
  homeTeam?: { userId: string; present?: boolean; loading?: boolean }[];
  awayTeam?: { userId: string; present?: boolean; loading?: boolean }[];
}
const RosterCheckIn: React.FunctionComponent<IRosterCheckProps> = (props) => {
  const dispatch = useDispatch();
  const matchDetails = useSelector(
    (state: ApplicationState) => state.matchDetails
  );

  const [selectedTeam, setSelectedTeam] = useState('');
  const [showMissingCheckIn, setShowMissingCheckIn] = useState(false);
  const [checkedRosters, setCheckedRosters] = useState({
    home: false,
    away: false,
  });

  const [editJerseyNumber, setEditJerseyNumber] = useState<{
    isOpen: boolean;
    newNumber: number | undefined;
    rosterId?: string;
    playerId?: string;
  }>({
    isOpen: false,
    newNumber: undefined,
  });
  const [editingJersey, setEditingJersey] = useState(false);

  const [jerseyNumberList, setJerseyNumberList] = useState<
    { playerId: string; jerseyNumber: number }[]
  >([]);

  const [attendanceList, setAttendanceList] = useState<AttendanceList>({
    homeTeam: [],
    awayTeam: [],
  });

  const getSelectedTeam = () => {
    if (selectedTeam === 'home') {
      return matchDetails.data.match?.homeTeam;
    }
    if (selectedTeam === 'away') {
      return matchDetails.data.match?.awayTeam;
    }
  };

  const checkUserIsPresent = (userId: string, team: string) => {
    if (team === 'home') {
      return !!attendanceList.homeTeam?.find(
        (player) => player.userId === userId
      )?.present;
    }
    return !!attendanceList.awayTeam?.find((player) => player.userId === userId)
      ?.present;
  };

  const checkUserIsAbsent = (userId: string, team: string) => {
    if (team === 'home') {
      if (
        attendanceList.homeTeam?.find((player) => player.userId === userId)
          ?.present === false
      ) {
        return !attendanceList.homeTeam?.find(
          (player) => player.userId === userId
        )?.present!;
      }
      // if user is not in the list
      return false;
    }

    if (
      attendanceList.awayTeam?.find((player) => player.userId === userId)
        ?.present === false
    ) {
      return !attendanceList.awayTeam?.find(
        (player) => player.userId === userId
      )?.present!;
    }
    // if user is not in the list
    return false;
  };

  const getAttendanceUser = (playerId: string) => {
    return (selectedTeam === 'home'
      ? attendanceList.homeTeam
      : attendanceList.awayTeam
    )?.find((item) => item.userId === playerId);
  };

  const updateAttendanceList = (
    userId: string,
    present: boolean,
    loading: boolean
  ) => {
    setAttendanceList((prevState) => {
      return {
        ...prevState,
        ...(selectedTeam === 'home' && {
          homeTeam: prevState.homeTeam?.map((item) => {
            if (item.userId !== userId) {
              return { ...item };
            }
            return {
              userId,
              present,
              loading,
            };
          }),
        }),
        ...(selectedTeam === 'away' && {
          awayTeam: prevState.awayTeam?.map((item) => {
            if (item.userId !== userId) {
              return { ...item };
            }
            return {
              userId,
              present,
              loading,
            };
          }),
        }),
      };
    });
  };

  const markUserPresence = (userId: string, present: boolean, team: string) => {
    updateAttendanceList(userId, present, true);

    RosterService.sendCheckIn({
      rosterId: getSelectedTeam()?.id || '',
      matchId: matchDetails.data.match?.matchId || '',
      userId,
      attend: present,
    }).then((res) => {
      if (res.success) {
        // Hide loading icon
        updateAttendanceList(userId, present, false);
        // if roster was checked already (MatchStatus !== SCHEDULED), when update some user, refresh listing
        if (
          matchDetails.data.match?.status !== MatchStatus.SCHEDULED &&
          matchDetails.data.match?.matchId
        ) {
          dispatch(matchDetailsFetchRequest(matchDetails.data.match?.matchId));
        }
      } else {
        console.log(res.message);
      }
    });
  };

  const markAllPresence = () => {
    setAttendanceList((prevState) => {
      return {
        ...prevState,
        ...(selectedTeam === 'home' && {
          homeTeam: prevState.homeTeam?.map((item) => {
            return {
              userId: item.userId,
              present: true,
              loading: true,
            };
          }),
        }),
        ...(selectedTeam === 'away' && {
          awayTeam: prevState.awayTeam?.map((item) => {
            return {
              userId: item.userId,
              present: true,
              loading: true,
            };
          }),
        }),
      };
    });

    (selectedTeam === 'away'
      ? attendanceList.awayTeam
      : attendanceList.homeTeam
    )?.map((attendance) => {
      RosterService.sendCheckIn({
        rosterId: getSelectedTeam()?.id || '',
        matchId: matchDetails.data.match?.matchId || '',
        userId: attendance.userId,
        attend: true,
      }).then((res) => {
        if (res.success) {
          // Hide Loading
          updateAttendanceList(attendance.userId, true, false);
          // if roster was checked already (MatchStatus !== SCHEDULED), when update some user, refresh listing
          if (
            matchDetails.data.match?.status !== MatchStatus.SCHEDULED &&
            matchDetails.data.match?.matchId
          ) {
            dispatch(
              matchDetailsFetchRequest(matchDetails.data.match?.matchId)
            );
          }
        } else {
          updateAttendanceList(attendance.userId, false, false);
        }
      });
    });
  };

  const AttendanceIsFinished = () => {
    // Check if length of teams is same of Attendance list flaged as present/not present
    // and verify if both rosters were clicked
    const homePlayersLenght =
      matchDetails.data.match?.homeTeam.players.length || 0;
    const homeCoachesLenght =
      matchDetails.data.match?.homeTeam.coaches.length || 0;
    const awayCoachesLenght =
      matchDetails.data.match?.awayTeam.coaches.length || 0;
    const awayPlayersLenght =
      matchDetails.data.match?.awayTeam.players.length || 0;

    if (
      homePlayersLenght + homeCoachesLenght ===
        attendanceList.homeTeam?.filter(
          (player) => player.present === true || player.present === false
        ).length &&
      awayCoachesLenght + awayPlayersLenght ===
        attendanceList.awayTeam?.filter(
          (player) => player.present === true || player.present === false
        ).length &&
      //check if no pending Loading
      !attendanceList.homeTeam?.find((item) => item.loading === true) &&
      !attendanceList.awayTeam?.find((item) => item.loading === true) &&
      checkedRosters.home &&
      checkedRosters.away
    ) {
      return true;
    }
    return false;
  };

  const updateJerseyNumber = () => {
    const max = 999;
    if (editJerseyNumber.newNumber && editJerseyNumber.newNumber > max) {
      alert('Max number is 999');
      return;
    }

    if (
      editJerseyNumber.newNumber &&
      editJerseyNumber.rosterId &&
      editJerseyNumber.playerId
    ) {
      RosterService.updatePlayerNumber({
        rosterId: editJerseyNumber.rosterId,
        playerId: editJerseyNumber.playerId,
        number: editJerseyNumber.newNumber,
      }).then((response) => {
        if (response.success) {
          if (matchDetails.data.match?.matchId) {
            setEditingJersey(true);
            dispatch(
              matchDetailsFetchRequest(matchDetails.data.match?.matchId)
            );
          }
          setEditJerseyNumber({ isOpen: false, newNumber: undefined });
        } else {
          // Error
          message.error(response.message);
        }
      });
    }
  };

  const finishAttendance = () => {
    if (matchDetails.data.match) {
      if (AttendanceIsFinished()) {
        dispatch(
          matchDetailsUpdate({
            ...matchDetails.data.match,
            status: MatchStatus.READY,
          })
        );
      } else {
        setShowMissingCheckIn(true);
      }
    }
  };

  useEffect(() => {
    setJerseyNumberList([
      ...(matchDetails.data.match?.homeTeam.players.map((player) => {
        return {
          playerId: player.id,
          jerseyNumber: player.number,
        };
      }) || []),
      ...(matchDetails.data.match?.awayTeam.players.map((player) => {
        return {
          playerId: player.id,
          jerseyNumber: player.number,
        };
      }) || []),
    ]);

    if (!editingJersey) {
      setAttendanceList({
        homeTeam: [
          ...(matchDetails.data.match?.homeTeam.players || []),
          ...(matchDetails.data.match?.homeTeam.coaches || []),
        ].map((user) => {
          return {
            userId: user.id,
            // Fill only if Match status is not SCHEDULED to force inital check
            ...(matchDetails.data.match?.status !== MatchStatus.SCHEDULED && {
              present: user.attend,
            }),
          };
        }),
        awayTeam: [
          ...(matchDetails.data.match?.awayTeam.players || []),
          ...(matchDetails.data.match?.awayTeam.coaches || []),
        ].map((player) => {
          return {
            userId: player.id,
            // Fill only if Match status is not SCHEDULED to force inital check
            ...(matchDetails.data.match?.status !== MatchStatus.SCHEDULED && {
              present: player.attend,
            }),
          };
        }),
      });
    }
  }, [matchDetails]);

  return (
    <div className='group-box'>
      <div>
        <h4>Select the team to do the Roster Check-in:</h4>
        <TeamBtWrapper>
          <Card
            onClick={() => {
              setSelectedTeam('home');
              setCheckedRosters({ ...checkedRosters, home: true });
            }}
            className={selectedTeam === 'home' ? 'active' : ''}
          >
            <div
              className='img'
              style={{
                backgroundImage: `url(${ConvertUtil.getMinioUrl(
                  matchDetails.data.match?.homeTeam.logo
                )})`,
              }}
            />
            <div className='text'>
              <div>{matchDetails.data.match?.homeTeam.clubName}</div>
              <h3 className=''>{matchDetails.data.match?.homeTeam.name}</h3>
            </div>
          </Card>

          <Card
            onClick={() => {
              setSelectedTeam('away');
              setCheckedRosters({ ...checkedRosters, away: true });
            }}
            className={selectedTeam === 'away' ? 'active' : ''}
          >
            <div
              className='img'
              style={{
                backgroundImage: `url(${ConvertUtil.getMinioUrl(
                  matchDetails.data.match?.awayTeam.logo
                )})`,
              }}
            />
            <div className='text'>
              <div>{matchDetails.data.match?.awayTeam.clubName}</div>
              <h3 className=''>{matchDetails.data.match?.awayTeam.name}</h3>
            </div>
          </Card>
        </TeamBtWrapper>

        <RosterWrapper>
          {selectedTeam && (
            <>
              {getSelectedTeam()?.coaches?.map((coach) => (
                <PlayerCard key={coach.id}>
                  <div style={{ display: 'flex' }}>
                    <div className='coach'>COACH</div>
                    <div className='picture'>
                      <div
                        className={`img ${
                          checkUserIsAbsent(coach.id, selectedTeam)
                            ? 'disabled'
                            : ''
                        }`}
                      >
                        <img src={ConvertUtil.getMinioUrl(coach.photo)} />
                      </div>
                      <div className='text'>{`${coach.firstName} ${coach.lastName}`}</div>
                      <div
                        style={{
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        {getAttendanceUser(coach.id)?.loading && (
                          <div
                            style={{
                              position: 'absolute',
                              width: '100%',
                              height: '100%',
                              backgroundColor: 'rgba(46,49,55,.85)',
                            }}
                          >
                            <LoadingIcon small />
                          </div>
                        )}

                        <div
                          className={`btn ${
                            checkUserIsAbsent(coach.id, selectedTeam)
                              ? 'active'
                              : ''
                          }`}
                          style={{ backgroundColor: '#982f2f' }}
                          onClick={() =>
                            markUserPresence(coach.id, false, selectedTeam)
                          }
                          data-testid='btn-absent'
                        >
                          Absent
                        </div>
                        <div
                          className={`btn ${
                            checkUserIsPresent(coach.id, selectedTeam)
                              ? 'active'
                              : ''
                          }`}
                          onClick={() => {
                            markUserPresence(coach.id, true, selectedTeam);
                          }}
                          style={{ backgroundColor: '#029e5d' }}
                          data-testid='btn-present'
                        >
                          Present
                        </div>
                      </div>
                    </div>
                  </div>
                </PlayerCard>
              ))}

              {getSelectedTeam()
                ?.players.sort((a: Player, b: Player) => a.number - b.number)
                .map((player) => (
                  <PlayerCard key={player.id}>
                    <div style={{ display: 'flex' }}>
                      <div className='number'>
                        {
                          jerseyNumberList.find(
                            (item) => item.playerId === player.id
                          )?.jerseyNumber
                        }
                        <div
                          className='edit'
                          onClick={() =>
                            setEditJerseyNumber({
                              isOpen: true,
                              newNumber: undefined,
                              rosterId: getSelectedTeam()?.id,
                              playerId: player.id,
                            })
                          }
                        >
                          EDIT
                        </div>
                      </div>
                      <div className='picture'>
                        <div
                          className={`img ${
                            checkUserIsAbsent(player.id, selectedTeam)
                              ? 'disabled'
                              : ''
                          }`}
                        >
                          <img src={ConvertUtil.getMinioUrl(player.photo)} />
                        </div>
                        <div className='text'>{`${player.firstName} ${player.lastName}`}</div>
                        <div
                          style={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          {getAttendanceUser(player.id)?.loading && (
                            <div
                              style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(46,49,55,.85)',
                              }}
                            >
                              <LoadingIcon small />
                            </div>
                          )}

                          <div
                            className={`btn ${
                              checkUserIsAbsent(player.id, selectedTeam)
                                ? 'active'
                                : ''
                            }`}
                            style={{ backgroundColor: '#982f2f' }}
                            onClick={() =>
                              markUserPresence(player.id, false, selectedTeam)
                            }
                            data-testid='btn-absent'
                          >
                            Absent
                          </div>
                          <div
                            className={`btn ${
                              checkUserIsPresent(player.id, selectedTeam)
                                ? 'active'
                                : ''
                            }`}
                            onClick={() => {
                              markUserPresence(player.id, true, selectedTeam);
                            }}
                            style={{ backgroundColor: '#029e5d' }}
                            data-testid='btn-present'
                          >
                            Present
                          </div>
                        </div>
                      </div>
                    </div>
                  </PlayerCard>
                ))}
              <Button className={`finish`} onClick={markAllPresence}>
                Set all as Present
              </Button>
            </>
          )}
          <div style={{ width: '100%' }}>
            {matchDetails.data.match?.status === MatchStatus.SCHEDULED && (
              <Button
                className={`finish ${
                  !AttendanceIsFinished() ? 'disabled' : ''
                }`}
                onClick={finishAttendance}
              >
                Finish Check-in
              </Button>
            )}
          </div>
        </RosterWrapper>
      </div>
      <LightBox
        isOpen={editJerseyNumber.isOpen}
        onClose={() =>
          setEditJerseyNumber({ isOpen: false, newNumber: undefined })
        }
      >
        <div className='card-popup'>
          <h3>New Jersey Number</h3>
          <div className='form-group'>
            <input
              type='number'
              onChange={(val) =>
                setEditJerseyNumber({
                  ...editJerseyNumber,
                  newNumber: Number(val.currentTarget.value),
                })
              }
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '10px',
            }}
          >
            <button
              className='btn sm white'
              onClick={() =>
                setEditJerseyNumber({ isOpen: false, newNumber: undefined })
              }
              style={{ width: '45%' }}
            >
              Cancel
            </button>
            <button
              className='btn sm'
              style={{ width: '45%' }}
              onClick={updateJerseyNumber}
            >
              Save
            </button>
          </div>
        </div>
      </LightBox>

      <LightBox
        isOpen={showMissingCheckIn}
        onClose={() => setShowMissingCheckIn(false)}
      >
        <div className='card-popup'>
          <div style={{ textAlign: 'center' }}>
            <h4>CHECK-IN IS NOT COMPLETED YET</h4>
            You need to check <b>ALL</b> users as{' '}
            <span style={{ color: '#982f2f', fontWeight: 600 }}>ABSENT</span> or
            <span style={{ color: '#029e5d', fontWeight: 600 }}> PRESENT</span>
            <Button onClick={() => setShowMissingCheckIn(false)}>
              Back to Check-In
            </Button>
          </div>
        </div>
      </LightBox>
    </div>
  );
};

export default RosterCheckIn;
