import { User } from 'models/User/UserModel';

export enum UsersActionTypes {
  FETCH_USERS_REQUEST = '@@users/FETCH_USERS_REQUEST',
  FETCH_USERS_SUCCESS = '@@users/FETCH_USERS_SUCCESS',
  FETCH_USERS_ERROR = '@@users/FETCH_USERS_ERROR',
}

export interface UsersState {
  readonly data: User[];
  readonly error: string;
  readonly loading: boolean;
}
