import * as React from 'react';
import { useEffect, useState } from 'react';
import usePrimaryClub from '../../hooks/usePrimaryClub/usePrimaryClub';
import { ClubStats } from '../../models/Stats/ClubStatsModel';
import StatsService from '../../services/Stats/StatsService';
import { gradient } from '../../styles/styleVariables';
import ConvertUtil from '../../util/ConvertUtil';
import CardInfoProfile from '../base/CardInfoProfile/CardInfoProfile';
import LocationBox from '../base/LocationBox/LocationBox';
import MembersGroup from '../base/MembersGroup/MemberGroup';
import XpBar from '../base/XpBar/XpBar';

const ClubCard: React.FunctionComponent = () => {
  const primaryClub = usePrimaryClub();
  const [clubStats, setClubStats] = useState<{
    loading: boolean;
    data: ClubStats[];
  }>({ loading: false, data: [] });

  useEffect(() => {
    if (primaryClub) {
      setClubStats({ loading: true, data: [] });
      StatsService.getClubStats(primaryClub.id).then((res) => {
        if (res.success) {
          setClubStats({ loading: false, data: res.data.content });
        } else {
          setClubStats({ loading: false, data: [] });
        }
      });
    }
  }, [primaryClub]);

  return (
    <div>
      {primaryClub && (
        <div className='card-box lg'>
          <div className='content'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* <FollowersBox /> */}
              <LocationBox
                city={primaryClub.city}
                country={primaryClub.country}
              />
            </div>
            <CardInfoProfile
              name='Main Club'
              mainName={primaryClub?.name}
              avatar={ConvertUtil.getMinioUrl(primaryClub?.logo)}
              icon='/assets/imgs/Vault_logo_white.svg'
              // place={22}
              level={clubStats.data[0]?.level}
              // Example of gradient for card
              bgGradient={gradient.orange}
              loading={clubStats.loading}
            />
            <XpBar
              minVal={clubStats.data[0]?.currentLevelXP}
              maxVal={clubStats.data[0]?.nextLevelXP}
              currentVal={clubStats.data[0]?.xp}
              multiplier=''
            />
            {primaryClub && <MembersGroup clubId={primaryClub.id} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClubCard;
