import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const DRAWER_WIDTH = toRem(504);

export const ModalWrapper = styled.div``;

export const ModalContentWrapper = styled.div`
  //height: 100%;
  min-height: 100vh;
  background-color: ${COLORS.grey950};
  max-width: ${DRAWER_WIDTH};
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: ${toRem(24)};

  padding: ${toRem(24)} ${toRem(30)};

  @media (max-width: ${DRAWER_WIDTH}) {
    width: 100%;
  }
`;

export const ModalContainer = styled.div`
  .react-responsive-modal-modal {
    width: ${DRAWER_WIDTH};
  }
`;
