import * as React from 'react';

import { TitleH4 } from 'styles/v3/variables';

import { Container } from './styles';

interface ModalDivisionHeaderProps {
  title: string;
}

const ModalHeader: React.FC<ModalDivisionHeaderProps> = ({ title }) => {
  return (
    <Container>
      <TitleH4 $isUpper $color='brandPrimary'>
        {title}
      </TitleH4>
    </Container>
  );
};

export default ModalHeader;
