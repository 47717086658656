import styled from 'styled-components';
import { color, gradient, font } from '../../styles/styleVariables';

const TypeUserSelectionWrapper = styled.div`
  padding: 50px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const SelectionItem = styled.div`
  width: 12vw;
  height: 28vw;
  position: relative;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
  }
  .bg-skew,
  .bg-skew-bg,
  .img-inactive-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.5s;
    width: 90%;
    height: 100%;
    transform: skew(-21deg);
    img {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .img-inactive-wrapper {
    overflow: hidden;
    .img-inactive {
      transform: skew(21deg);
      img {
        width: 134%;
        margin-left: 6px;
        filter: grayscale(90%);
      }
    }
  }
  .bg-skew {
    border: 1px solid ${color.silverLight};
    overflow: hidden;
    .bg-text {
      width: 215%;
      height: 100%;
      position: absolute;
      left: -57%;
      transform: skew(21deg);
      opacity: 0.1;
      background-size: 100%;
      background-position: center;
      @media (max-width: 768px) {
        background: none !important;
      }
    }
  }
  .bg-skew-bg {
    transition: all 0.5s;
    opacity: 0;
    background: ${gradient.orange};
    overflow: hidden;
    .bg-text {
      width: 215%;
      height: 100%;
      position: absolute;
      left: -57%;
      transform: skew(21deg);
      opacity: 0.3;
      background-size: 100%;
      background-position: center;
      @media (max-width: 768px) {
        background: none !important;
      }
    }
  }
  .item-img,
  .item-img-effect {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10;
    height: 100%;
    transition: all 0.5s;
    opacity: 0;
    img {
      transition: all 0.5s;
      height: 110%;
      filter: grayscale(80%);
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .item-img-effect {
    transition: none;
  }
  &:hover,
  &.selected {
    .bg-skew {
      top: -10px;
      left: -10px;
      @media (max-width: 768px) {
        left: 10px;
      }
    }
    .bg-skew-bg {
      opacity: 1;
    }
    .item-img,
    .item-img-effect {
      opacity: 1;
      img {
        filter: grayscale(0%);
      }
    }
    .img-inactive {
      opacity: 0;
    }
    .user-title {
      color: ${color.orange};
      @media (max-width: 768px) {
        color: ${color.greyLight};
      }
    }
  }
  .user-title {
    position: absolute;
    font-family: ${font.druk};
    bottom: -50px;
    left: -7vw;
    text-transform: uppercase;
    z-index: 10;
    width: 110%;
    text-align: center;
    color: ${color.silverLight};
    transition: color 0.5s;
    @media (max-width: 768px) {
      bottom: 12px;
      color: #fff;
    }
  }
`;

export { TypeUserSelectionWrapper, SelectionItem };
