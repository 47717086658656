import * as React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { color } from '../../../styles/styleVariables';
import { ApplicationState } from '../../../redux/store';
import ConvertUtil from '../../../util/ConvertUtil';

interface IMembersGroup {
  clubId: string;
}

const MembersGroup: React.FunctionComponent<IMembersGroup> = (props) => {
  const { clubId } = props;
  const members = useSelector(
    (state: ApplicationState) => state.club
  ).data.clubs.find((e) => e.id === clubId)?.members;
  return (
    <MembersGroupBox>
      {members?.map((item, index) => {
        return (
          <Avatar key={index}>
            <div
              className='avatar-img'
              style={{
                backgroundImage: `url(${ConvertUtil.getMinioUrl(
                  item.photo,
                  100
                )})`,
              }}
            />
            {/* <div className='notification-circle'>5</div> */}
          </Avatar>
        );
      })}
    </MembersGroupBox>
  );
};

const MembersGroupBox = styled.div`
  margin: 8px 0;
  display: flex;
  flex-wrap: wrap;
`;

const Avatar = styled.div`
  position: relative;
  margin-right: -3px;
  margin-bottom: 5px;
  .avatar-img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #fff;
    overflow: hidden;
    background-size: cover;
    transition: all 0.3s;
    box-shadow: 0px 0 0 0px ${color.orange};
    position: relative;
    z-index: 1;
    //cursor: pointer;
    //box-shadow: 0px 0 0 0px ${color.orange};
    z-index: 10;
    &:hover {
      box-shadow: 0px 0 0 3px ${color.orange};
      z-index: 10;
    }
    img {
      width: 100%;
    }
  }
  .notification-circle {
    position: absolute;
    top: 0px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    font-size: 8px;
    text-align: center;
    background-color: #000;
    color: #fff;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 20;
    font-weight: 700;
  }
`;
export default MembersGroup;
