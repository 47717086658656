import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const CONTENT_WIDTH = '822px';

export const DraftEventContentWrapper = styled.div`
  background-color: ${COLORS.grey1000};
  min-height: 100vh;
  color: ${COLORS.white};

  select {
    padding: ${toRem(10)};
    margin-right: ${toRem(10)};

    color: ${COLORS.grey1000};
    background-color: ${COLORS.brandPrimary};
  }
`;
