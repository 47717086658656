import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import OrderService from 'services/v3/Order/OrderService';
import { SimpleOrderView } from 'services/v3/Order/types';

export function useGetRosterEventOrdersForMyEvent(
  eventId?: string,
  status?: SimpleOrderView["paymentStatus"]
) {
  const { id: accountId } = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );
  const { data, isLoading, error } = useQuery(
    ['/get-roster-event-order-my-event', eventId, status, accountId],
    async () =>
      await OrderService.getRosterEventOrdersForMyEvent(eventId, status)
  );

  return {
    httpCode: data?.httpCode,
    ...data,
    data: data?.data ?? undefined,
    isLoading,
    error,
  };
}
