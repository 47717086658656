import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

interface TagWrapper {
  borderColor?: string;
}

export const TagWrapper = styled.div<TagWrapper>`
  width: min-content;
  white-space: nowrap;
  border-radius: ${toRem(33)};
  border: ${toRem(1)} solid ${({ color }) => color};
  padding: ${toRem(4)} ${toRem(16)} ${toRem(4)} ${toRem(16)};

  p {
    color: ${({ color }) => color};
  }
`;
