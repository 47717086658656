import { useRef, useState, MouseEvent } from 'react';

import { useUpdateMatchesEvent } from 'hooks/v3/matches/useUpdateMatchesEvent/useUpdateMatchesEvent';

import { TableProps, VariantTooltip } from '../types';
import {
    colorGroup,
  errorTooltipHeight,
  errorTooltipWidth,
  infoTooltipHeight,
  infoTooltipWidth,
} from '../lib';

type Params = Pick<TableProps, 'source'| 'grid' | 'scheduledMatch' | 'setEditModalOpen' | 'setSelectedMatch' | 'divisionColors' | 'errorElement'>;

const useScheduleTableConfig = (params: Params) => {
  const { grid, errorElement, source, scheduledMatch, setEditModalOpen, setSelectedMatch, divisionColors } = params;

  const { mutateAsync: updateSchedule } = useUpdateMatchesEvent();

  const tableRef = useRef<HTMLDivElement>(null);
  const infoTooltipRef = useRef<HTMLDivElement>(null);
  const errorTooltipRef = useRef<HTMLDivElement>(null);

  const [variant, setVariant] = useState<VariantTooltip>('arrow_down_left');
    const [info, setInfo] = useState<{
      home: string;
      away: string;
      time: string;
      message?: string;
    }>({ home: '', away: '', time: '' });

  const setTooltipCoordinates = (
    type: 'info' | 'error',
    element: HTMLElement
  ) => {
    const tableCoordinates = tableRef.current?.getBoundingClientRect();
    const elementCoordinates = element?.getBoundingClientRect();

    if (tableCoordinates && elementCoordinates && infoTooltipRef) {
      const tooltip =
        type === 'info' ? infoTooltipRef.current : errorTooltipRef.current;
      const tooltipHeight =
        type === 'info' ? infoTooltipHeight : errorTooltipHeight;
      const tooltipWidth =
        type === 'info' ? infoTooltipWidth : errorTooltipWidth;

      const left =
        elementCoordinates.x -
        tableCoordinates.x +
        (element?.clientWidth ?? 0) / 2;
      const top =
        (tableRef.current?.scrollTop ?? 0) +
        elementCoordinates.y -
        tableCoordinates.y -
        tooltipHeight -
        20; // ENG-62 - TT height

      const topVisible =
        top > (tableRef.current?.scrollTop ?? 0) + tooltipHeight;
      const rightVisible =
        (tableRef.current?.clientWidth ?? 0) > left + tooltipWidth;

      if (tooltip) {
        tooltip.style.display = 'block';

        if (topVisible && !rightVisible) {
          tooltip.style.top = `${top}px`;
          tooltip.style.left = `${left - tooltipWidth}px`;
          setVariant('arrow_down_rigth');
        } else if (!topVisible && rightVisible) {
          tooltip.style.top = `${top + 2 * tooltipHeight}px`;
          tooltip.style.left = `${left}px`;
          setVariant('arrow_up_left');
        } else if (!topVisible && !rightVisible) {
          tooltip.style.top = `${top + 2 * tooltipHeight}px`;
          tooltip.style.left = `${left - tooltipWidth}px`;
          setVariant('arrow_up_right');
        } else if (topVisible && rightVisible) {
          tooltip.style.top = `${top}px`;
          tooltip.style.left = `${left}px`;
          setVariant('arrow_down_left');
        }
      }
    }
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
      const widget = event.target as HTMLDivElement | HTMLParagraphElement;

      const id = widget.closest('.grid-stack-item')?.getElementsByClassName('card')?.[0]?.id;

      const match = scheduledMatch.find((match) => id && match.matchId === id);

      if (
        (match && widget.classList.contains('edit_white')) ||
        widget.classList.contains('edit_black') ||
        widget.classList.contains('edit_container')
      ) {
        if (!match?.gridLocked && match?.sourceType === source) {
          setEditModalOpen();
          match && setSelectedMatch(match);
        } else {
          setInfo({
            home: '',
            away: '',
            time: '',
            message:
              'For the edit game you should unlock item or chage source filter!',
          });
        }
      } else {
        const errorId = errorElement?.getAttribute('gs-id');

        if (id && errorId !== `match-${id}`) {
          const element = document.getElementById(id);

          if (match && match.sourceType === source && element) {
            element?.classList.remove(
              'locked_item_white',
              'unlocked_item_white',
              'locked_item_black',
              'unlocked_item_black'
            );
          }

          const gridWidget = grid?.getGridItems()?.find((item) => {
            const itemId =
              item.id === '' ? item.getAttribute('gs-id') : item.id;

            return itemId === `match-${id}`;
          });

          if (match && element && gridWidget && match.sourceType === source) {
            const lockedIco =
              colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
                ? 'locked_item_white'
                : 'locked_item_black';
            const unlockedIco =
              colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
                ? 'unlocked_item_white'
                : 'unlocked_item_black';
            const icon = !match.gridLocked ? lockedIco : unlockedIco;

            updateSchedule([
              {
                matchId: match.matchId,
                gridLocked: !match.gridLocked,
              },
            ]);

            element.classList.add(icon);

            grid?.update(gridWidget, {
              locked: !match.gridLocked,
              noMove: !match.gridLocked,
              noResize: !match.gridLocked,
            });
          }
        }
      }
  };

  return {
    tableRef,
    infoTooltipRef,
    errorTooltipRef,
    variant,
    info,
    setInfo,
    setTooltipCoordinates,
    handleClick,
  };
};

export default useScheduleTableConfig;
