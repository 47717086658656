import { Reducer } from 'redux';
import { produce } from 'immer';
import { ClubDashboardState, ClubDashboardActionTypes } from './types';

export const initialState: ClubDashboardState = {
  clubDirector: {
    memberPool: {
      selectedUserIds: [],
    },
  },
};

const reducer: Reducer<ClubDashboardState> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ClubDashboardActionTypes.SET_SELECTED_MEMBER_POOL_USER:
        console.log('setting users', action.payload);
        draft.clubDirector.memberPool.selectedUserIds = action.payload;
        break;
      case ClubDashboardActionTypes.TOGGLE_SELECTED_MEMBER_POOL_USER:
        if (
          state.clubDirector.memberPool.selectedUserIds.includes(action.payload)
        ) {
          draft.clubDirector.memberPool.selectedUserIds = state.clubDirector.memberPool.selectedUserIds.filter(
            (id) => id !== action.payload
          );
        } else {
          draft.clubDirector.memberPool.selectedUserIds.push(action.payload);
        }
        break;
      default:
        break;
    }
  });

export { reducer as ClubDashboardReducer };
