import React from 'react';
import { PeoplePlusOne, Plus } from '@icon-park/react';
import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';

type ExtraButtonsProps = {
  type: 'clubs' | 'referees',
};

export const ExtraButtons = ({ type }: ExtraButtonsProps) => {
  if (type === 'clubs') {
    return (
      <OutlinedButtonWithIcon isUpper customIcon={<Plus />}>
        Invite club
      </OutlinedButtonWithIcon>
    );
  }

  if (type === 'referees') {
    return (
      <OutlinedButtonWithIcon isUpper customIcon={<PeoplePlusOne />}>
        Invite referee
      </OutlinedButtonWithIcon>
    );
  }

  return null;
};
