import { NewAgeDivision } from 'admin/models/AgeDivision';
import { OrderDetailed } from 'services/v3/Event/types';
import { PaymentStatus } from 'services/v3/Order/types';

export interface IOrderedDivisionDetails {
  id: string;
  target: number;
  ordered: number;
}

export const getOrderedDivisionDetails = (
  divisions: NewAgeDivision[],
  orders: OrderDetailed[]
): IOrderedDivisionDetails[] => {
  const ageDivisionsMap = new Map();

  divisions.forEach((division) => {
    ageDivisionsMap.set(division.id, {
      id: division.id,
      target: division.targetNumberOfTeams,
      ordered: 0,
    });
  });

  orders.forEach((order) => {
    if (order.paymentStatus === PaymentStatus.PAID) {
      order.cart.forEach((cartItem) => {
        if (
          cartItem.b2bEventRosterFee &&
          cartItem.b2bEventRosterFee.divisions
        ) {
          cartItem.b2bEventRosterFee.divisions.forEach((division) => {
            if (ageDivisionsMap.has(division.id)) {
              const divisionData = ageDivisionsMap.get(division.id);
              divisionData.ordered += division.quantity || 0;
            }
          });
        }
      });
    }
  });

  return Array.from(ageDivisionsMap.values());
};
