import { AxiosRequestConfig } from 'axios';
import { ClubStats } from '../../models/Stats/ClubStatsModel';
import { UserStats } from '../../models/Stats/UserStatsModel';
import { ConfigApi } from '../config.api';
import APIServices from '../util/ApiServices';
import PageResponse from '../util/PageResponse';
import ServiceResponse from '../util/ServiceResponse';

class StatsService {
  public static async getUserStats(filter?: {
    userIdList?: string[];
    actionList?: string[];
    timeFrame?: string;
  }): Promise<ServiceResponse<PageResponse<UserStats>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig('stats'),
      url: '/rankings/users',
      method: 'POST',
      data: filter,
    };
    return APIServices.request<PageResponse<UserStats>>(axiosConfig);
  }

  public static async getClubStats(
    clubId: string
  ): Promise<ServiceResponse<PageResponse<ClubStats>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig('stats'),
      url: '/rankings/clubs',
      method: 'POST',
      data: { clubIdList: [clubId] },
    };
    return APIServices.request<PageResponse<ClubStats>>(axiosConfig);
  }

  public static async getUsersRankByEvent(
    eventId: string,
    urlParams?: URLSearchParams
  ): Promise<ServiceResponse<PageResponse<UserStats>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig('stats'),
      url: '/rankings/users',
      method: 'POST',
      params: urlParams,
      data: { eventIdList: [eventId], timeFrame: 'NONE' },
    };
    return APIServices.request<PageResponse<UserStats>>(axiosConfig);
  }

  public static async getUsersRank(
    urlParams?: URLSearchParams,
    filter?: {
      eventIdList?: string[];
      actionList?: string[];
      timeFrame?: string;
      season?: number;
    }
  ): Promise<ServiceResponse<PageResponse<UserStats>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig('stats'),
      url: '/rankings/users',
      method: 'POST',
      params: urlParams,
      data: filter,
    };
    return APIServices.request<PageResponse<UserStats>>(axiosConfig);
  }
}

export default StatsService;
