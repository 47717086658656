import Loading from 'components/v3/Loading/Loading';
import * as React from 'react';
import { withRouter } from 'react-router-dom';

const RedirectToHome: React.FunctionComponent = () => {
  window.location.href = '/v3/home-page';
  return <Loading />;
};

export default withRouter(RedirectToHome);
