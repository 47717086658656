import { message } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../../components/uielements/Button/Button';
import { User } from '../../../../models/User/UserModel';
import { ApplicationState } from '../../../../redux/store';
import { UserType } from '../../../../redux/user/types';
import UserServices from '../../../../services/User/UserApi';
import { color } from '../../../../styles/styleVariables';
import ConvertUtil from '../../../../util/ConvertUtil';

interface IMembershipUpgradeModalProps {
  user: User;
  onSuccess: () => void;
}

const MembershipUpgradeModal: React.FunctionComponent<IMembershipUpgradeModalProps> = (
  props
) => {
  const { user, onSuccess } = props;
  const memberships = useSelector(
    (state: ApplicationState) => state.memberships
  );
  const [newMembership, setNewMembership] = useState(user.membershipId);
  const [reason, setReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const upgradeMemmership = () => {
    if (newMembership && reason) {
      setIsLoading(true);
      UserServices.membershipUpgrade(user.id, newMembership, reason)
        .then((response) => {
          if (response.success) {
            onSuccess();
          } else {
            message.error({ content: response.message });
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <div
      style={{
        padding: '25px',
        borderRadius: '10px',
        backgroundColor: color.greyLight,
      }}
    >
      <h2>Upgrade User Membership</h2>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          style={{
            backgroundImage: `url(${ConvertUtil.getMinioUrl(user.photo)})`,
          }}
        />
        <div>{`${user.firstName} ${user.lastName}`}</div>
      </div>
      <div className='form-group'>
        <label style={{ fontWeight: 'bold' }}>Select Membership</label>
        <select
          value={newMembership}
          onChange={(item) => setNewMembership(item.target.value)}
        >
          {memberships.data
            .filter((item) => item.userType === UserType.PLAYER)
            .map((item) => {
              return <option value={item.id}>{item.name}</option>;
            })}
        </select>
      </div>

      <div className='form-group'>
        <label style={{ fontWeight: 'bold' }}>Upgrade reason</label>
        <textarea
          value={reason}
          onChange={(item) => setReason(item.target.value)}
        ></textarea>
      </div>
      <Button
        loading={isLoading}
        disabled={newMembership === '' || reason === '' || isLoading}
        onClick={() => upgradeMemmership()}
      >
        {!isLoading ? 'Save' : ''}
      </Button>
    </div>
  );
};

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 25px;
  background-color: #fff;
  margin-right: 10px;
  background-size: cover;
`;

export default MembershipUpgradeModal;
