export enum SideModalActionTypes {
  SET_TITLE = '@@sideModal/SET_TITLE',
  CLOSE_MODAL = '@@sideModal/CLOSE_MODAL',
  OPEN_MODAL = '@@sideModal/OPEN_MODAL',
  TOGGLE_MODAL = '@@sideModal/TOGGLE_MODAL',
  SET_SELECTED = '@@sideModal/SET_SELECTED',
  HAS_BACK_BUTTON = '@@sideModal/HAS_BACK_BUTTON',
  INC_BACK_BUTTON_CLICK_COUNT = '@@sideModal/INC_BACK_BUTTON_CLICK_COUNT',
  SET_STEP = '@@sideModal/SET_STEP',
}
export interface SideModalState {
  readonly title: string;
  readonly isOpen: boolean;
  readonly selected: SelectedSideModal;
  readonly hasBackButton: boolean;
  readonly backButtonClickCount: number;
  readonly step: number;
}
export type SelectedSideModal =
  | 'none'
  // Event Setup modals
  | 'eventInfo'
  | 'generalRules'
  | 'matchSettings'
  | 'divisions'
  | 'venues'
  | 'courts'
  | 'pricing'
  | 'multipliers'
  | 'tiebreakers';
