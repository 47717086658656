import { Pagination, Select } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { EventView } from '../../admin/models/event/Event';
import goalIcon from '../../assets/imgs/icons/goal_icon.png';
import playerIcon from '../../assets/imgs/icons/player_icon.png';
import winnerIcon from '../../assets/imgs/icons/winner_icon.png';
import LoadingIcon from '../../components/base/LoadingIcon/LoadingIcon';
import { UserStats } from '../../models/Stats/UserStatsModel';
import { User } from '../../models/User/UserModel';
import EventService from '../../services/Event/EventService';
import StatsService from '../../services/Stats/StatsService';
import UserServices from '../../services/User/UserApi';
import { color, font } from '../../styles/styleVariables';
import ConvertUtil from '../../util/ConvertUtil';

interface IRankingsPageProps {}

const RankingsPage: React.FunctionComponent<IRankingsPageProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [userStatsList, setUserStatsList] = useState<UserStats[]>([]);
  const [tournamentList, setTournamentList] = useState<EventView[]>([]);
  const [userList, setUserList] = useState<User[]>([]);
  const [selectedSeason, setSelectedSeason] = useState(3);
  const [pagination, setPagination] = useState({
    size: '100',
    page: '0',
    total: '0',
  });

  const [rankingOptions, setRankingOptions] = useState<{
    eventIdList: string[];
    actionList: string[];
    userTypeList: string[];
    ageDivisionList: {}[];
    specialDivision: string;
    timeFrame?: string;
    season?: number;
  }>({
    eventIdList: [],
    actionList: [],
    userTypeList: ['PLAYER'],
    ageDivisionList: [],
    specialDivision: '',
    timeFrame: 'SEASON',
    season: 2,
  });
  const location = useLocation();

  const handlePageChange = (page?: number, size?: number) => {
    setLoading(true);
    setPagination({
      ...pagination,
      page: page ? (page - 1).toString() : '0',
      ...(size && {
        size: size.toString(),
      }),
    });
  };

  // Initial fetch
  useEffect(() => {
    setLoading(true);
    StatsService.getUsersRank(new URLSearchParams(pagination), {
      ...rankingOptions,
      season: selectedSeason,
    }).then((res) => {
      if (res.success) {
        setPagination({
          ...pagination,
          page: '0',
          total: res.data.total.toString(10),
        });
        setUserStatsList(res.data.content);
        setLoading(false);
      } else {
        setLoading(false);
        setUserStatsList([]);
      }
    });
  }, [rankingOptions, selectedSeason]);

  useEffect(() => {
    StatsService.getUsersRank(new URLSearchParams(pagination), {
      ...rankingOptions,
      season: selectedSeason,
    }).then((res) => {
      if (res.success) {
        if (pagination.total !== '0') {
          handlePageChange(res.data.page + 1);
          setUserStatsList(res.data.content);
          setLoading(false);
        }
      } else {
        setLoading(false);
        setUserStatsList([]);
      }
    });
  }, [pagination.page, pagination.size]);

  useEffect(() => {
    if (userStatsList.length) {
      getUsersData();
    }
  }, [userStatsList]);

  useEffect(() => {
    // Load Torunament list
    EventService.list().then((res) => {
      if (res.success) {
        setTournamentList(
          res.data.content.filter((item) => item.eventType === 'TOURNAMENT')
        );
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true);

    if (location.hash === '#top-scores') {
      setRankingOptions({ ...rankingOptions, actionList: ['GOAL'] });
    }
    if (location.hash === '#top-winners') {
      setRankingOptions({ ...rankingOptions, actionList: ['WIN'] });
    }
    if (location.hash === '#top-players') {
      setRankingOptions({ ...rankingOptions, actionList: [] });
    }
  }, [location]);

  const getUsersData = () => {
    UserServices.search(
      new URLSearchParams({
        size: '100',
      }),
      {
        idList: userStatsList.map((item) => item.userId),
      }
    ).then((res) => {
      if (res.success) {
        setUserList(res.data.content);
      }
    });
  };

  return (
    <div style={{ padding: ' 20px 50px' }}>
      <h1>Rankings</h1>

      <Container>
        <div className='rank-list'>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <h2 style={{ margin: 0, marginRight: '15px' }}>
              Global Players Rank:{' '}
              <select
                className='select-season'
                onChange={(event: any) => {
                  setSelectedSeason(event.target.value);
                }}
              >
                <option value={4}>Season 2023/2024</option>
                <option value={3}>Season 2022/2023</option>
                <option value={2}>Season 2021/2022</option>
                <option value={1}>Season 2020/2021</option>
              </select>
            </h2>
            <div className='older-seasons-bt'>Select different season</div>
          </div>

          <div className='top-header'>
            <Link
              to='#top-players'
              className={`top-item ${
                location.hash === '#top-players' || location.hash === ''
                  ? 'active'
                  : ''
              }`}
            >
              <div
                style={{
                  backgroundImage: `url(${playerIcon})`,
                }}
              >
                Top Players
              </div>
            </Link>

            <Link
              to='#top-scores'
              className={`top-item ${
                location.hash === '#top-scores' ? 'active' : ''
              }`}
            >
              <div
                style={{
                  backgroundImage: `url(${goalIcon})`,
                }}
              >
                Top Scores
              </div>
            </Link>
            <Link
              to='#top-winners'
              className={`top-item ${
                location.hash === '#top-winners' ? 'active' : ''
              }`}
            >
              <div
                style={{
                  backgroundImage: `url(${winnerIcon})`,
                }}
              >
                Top Winners
              </div>
            </Link>
          </div>

          <div className='filter-box'>
            <div className='item-filter'>
              Tournament
              <Select
                placeholder='Select the tournament'
                onChange={(item) => {
                  setRankingOptions({
                    ...rankingOptions,
                    eventIdList: item ? [item.toString()] : [],
                    ageDivisionList: [],
                    specialDivision: '',
                  });
                }}
              >
                <Select.Option value=''>GLOBAL</Select.Option>
                {tournamentList.map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className='item-filter'>
              Category
              <Select
                placeholder='Select the category'
                value={JSON.stringify(rankingOptions.ageDivisionList[0])}
                onChange={(item) => {
                  const special = item.split('|');
                  if (special[0] === 'special') {
                    setRankingOptions({
                      ...rankingOptions,
                      ageDivisionList: [],
                      specialDivision: special[1],
                    });
                  } else {
                    setRankingOptions({
                      ...rankingOptions,
                      ageDivisionList: item
                        ? [JSON.parse(item.toString())]
                        : [],
                      specialDivision: '',
                    });
                  }
                }}
              >
                <Select.Option value=''>All</Select.Option>
                {tournamentList
                  .find((item) => item.id === rankingOptions.eventIdList[0])
                  ?.ageDivisions?.map((item, index) => (
                    <Select.Option
                      value={JSON.stringify(item)}
                      key={item.gender + index}
                    >
                      {`${item.gender} ${
                        item.years[0] === 1900
                          ? 'ADULT'
                          : item.years.sort().join(', ')
                      }`}
                      {item.rule === 'YOUNGER' && ' (and younger)'}
                    </Select.Option>
                  ))}

                {tournamentList
                  .find((item) => item.id === rankingOptions.eventIdList[0])
                  ?.specialDivisions?.map((item, index) => (
                    <Select.Option
                      value={`special|${item.name}`}
                      key={item.name + index}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </div>
          </div>

          {loading && <LoadingIcon />}
          {!loading &&
            userList.length > 0 &&
            userStatsList.map((userStats, index) => (
              <div className='item' key={userStats.userId}>
                <div className='position'>{`#${
                  parseInt(pagination.size) * parseInt(pagination.page) +
                  index +
                  1
                }`}</div>
                <div
                  className='avatar'
                  style={{
                    backgroundImage: `url(${
                      ConvertUtil.getMinioUrl(
                        userList.find((item) => item.id === userStats.userId)
                          ?.avatar
                      ) ||
                      ConvertUtil.getMinioUrl(
                        userList.find((item) => item.id === userStats.userId)
                          ?.photo
                      )
                    })`,
                  }}
                />
                <div className='name'>
                  <div className='title'>Name</div>
                  {
                    userList.find((item) => item.id === userStats.userId)
                      ?.firstName
                  }{' '}
                  {
                    userList.find((item) => item.id === userStats.userId)
                      ?.lastName
                  }
                </div>
                <div className='club'>
                  <div className='title'>Club</div>
                  {/* Get primary club if it is set, or first club in list */}
                  {userList.find((item) => item.id === userStats.userId)
                    ?.playerInfo?.primaryClubId
                    ? userList
                        .find((item) => item.id === userStats.userId)
                        ?.clubs?.find(
                          (item) =>
                            item.id ===
                            userList.find(
                              (item) => item.id === userStats.userId
                            )?.playerInfo?.primaryClubId
                        )?.name
                    : userList.find((item) => item.id === userStats.userId)
                        ?.clubs[0]?.name}
                </div>
                {(location.hash === '#top-players' || location.hash === '') && (
                  <div className='xp'>
                    <div className='title'>XP</div>
                    {userStats.xp}
                  </div>
                )}
                {location.hash === '#top-scores' && (
                  <div className='xp'>
                    <div className='title'>GOALS</div>
                    {userStats.summary.find((item) => item.action === 'GOAL')
                      ?.quantity || '0'}
                  </div>
                )}
                {location.hash === '#top-winners' && (
                  <div className='xp'>
                    <div className='title'>WINS</div>
                    {userStats.summary.find((item) => item.action === 'WIN')
                      ?.quantity || '0'}
                  </div>
                )}
                <div className='level'>
                  <div className='title'>Level</div>
                  {userStats.level}
                </div>
              </div>
            ))}
          {!loading && (
            <Pagination
              current={parseInt(pagination.page) + 1}
              pageSize={parseInt(pagination.size)}
              defaultPageSize={parseInt(pagination.size)}
              total={parseInt(pagination.total)}
              onChange={handlePageChange}
              showSizeChanger={false}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

const Container = styled.div`
  .rank-list {
    max-width: 1140px;
    margin: 0 auto;

    .older-seasons-bt {
      min-width: 100px;
      text-align: center;
      display: inline;
      border-radius: 5px;
      font-weight: bold;
      font-size: 10px;
      color: #7c7c7c;
    }

    select {
      background: none;
      border: none;
      color: ${color.orange};
      option {
        font-size: 18px;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        text-shadow: 0 1px 0 rgb(0 0 0 / 80%);
        padding: 10px 0;
        text-indent: 10px;
      }
    }
    .item {
      padding: 20px;
      margin: 10px 0;
      border-radius: 5px;
      background-color: ${color.greyLight};
      display: flex;
      align-items: center;
      font-family: ${font.druk};

      @media (max-width: 650px) {
        flex-wrap: wrap;
      }
      .title {
        font-family: ${font.montserrat};
        font-size: 12px;
        opacity: 0.5;
      }
      .avatar {
        min-width: 40px;
        height: 40px;
        border-radius: 40px;
        background-color: ${color.greyText};
        background-size: cover;
      }
      .name {
        padding-left: 15px;
        font-size: 16px;
        width: 40%;
        @media (max-width: 650px) {
          padding-left: 15px;
          width: calc(100% - 110px);
          font-size: 14px;
        }
      }
      .club {
        padding-left: 15px;
        width: 20%;
        @media (max-width: 650px) {
          padding-left: 0;
          width: 30%;
          order: 10;
          font-size: 12px;
        }
      }
      .position {
        padding: 0 15px;
        width: 70px;
      }
      .level {
        padding-left: 15px;
        width: 10%;
        margin-left: auto;
        @media (max-width: 650px) {
          width: 30%;
          font-size: 12px;
        }
      }
      .xp {
        font-size: 12px;
        @media (max-width: 650px) {
          width: 30%;
          font-size: 12px;
        }
      }
      &.myself {
        border: solid 1px #7d7c7b;
      }
    }
  }
  .top-header {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    @media (max-width: 890px) {
      flex-wrap: wrap;
    }
    .top-item {
      border-radius: 5px;
      border: solid 1px #7d7c7b;
      width: 30%;
      background-color: ${color.greyLight};
      font-family: ${font.druk};
      text-align: right;
      @media (max-width: 890px) {
        width: 100%;
        margin: 10px;
      }
      div {
        padding: 40px 20px;
        background-size: 60px;
        background-position: 20px;
        background-repeat: no-repeat;
        @media (max-width: 890px) {
          padding: 20px 20px;
          background-size: 40px;
        }
      }
      &.active {
        border: solid 1px ${color.orange};
        pointer-events: none;
      }
    }
  }

  .filter-box {
    display: flex;
    justify-content: space-between;
    .item-filter {
      width: 49%;
    }
  }
`;

export default RankingsPage;
