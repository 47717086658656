import * as React from 'react';
import './group-box.scss';

interface GroupBoxProps {
  title: string;
}

const GroupBox: React.FunctionComponent<GroupBoxProps> = (props) => {
  const { title, children } = props;
  return (
    <div className='group-box'>
      <div className='page-separator'>
        <div className='page-separator__text'>{title}</div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default GroupBox;
