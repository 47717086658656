import moment from 'moment';
import {
  GridItemHTMLElement,
  GridStack,
  GridStackNode,
  GridStackWidget,
} from 'gridstack';

import { MatchEventModel } from 'models/Match/MatchModel';

import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';

import { colorGroup, stepperIntervals } from '../lib';
import { CalendarCourt } from '../../../types';
import { mapTimeToPosition } from '../../../utils';

interface WidgetData {
  x: number;
  y: number;
  match: MatchEventModel;
  matchLenght: number;
}

export const useGetScheduleConfig = (
  eventId: string,
  divisionColors: { [key: string]: string }
) => {
  const pools = useGetEventById(eventId).data?.ageDivisions?.flatMap(
    (division) => {
      return division?.pools?.map((poolOfEvent) => ({
        id: poolOfEvent.id,
        label: `${poolOfEvent.name} ${poolOfEvent.order}`,
        value: poolOfEvent.id,
      }));
    }
  );

  const getPoolName = (poolMatches: { id: string, name?: string }[]) => {
    const poolsFromMatch = pools
      ? pools?.filter((pool) =>
          poolMatches.some((item) => pool?.id === item?.id)
        )
      : [];

    return poolsFromMatch.map((pool) => `${pool.label}`);
  };

  const getMatchTitle = (match: MatchEventModel) => {
    if (match.sourceType === 'POOL') {
      return getPoolName(match.pools);
    }

    return `Bracket: Game ${match.name}`;
  };

  const getHomeTeamName = (match: MatchEventModel) => {
    if (match?.homeTeam?.clubName) {
      return match.homeTeam.clubName;
    }

    if (match?.homePlaceHolder?.name) {
      return match?.homePlaceHolder?.name;
    }

    return 'TBA';
  };

  const getAwayTeamName = (match: MatchEventModel) => {
    if (match?.awayTeam?.clubName) {
      return match.awayTeam.clubName;
    }

    if (match?.awayPlaceHolder?.name) {
      return match.awayPlaceHolder.name;
    }

    return 'TBA';
  };

  const changeTimeInnerScheduledElem = (
    element: GridStackNode,
    title: string,
    time: string,
    homeTeam: string,
    awayClub: string,
    match: MatchEventModel
  ) => {
    const el = element.el?.querySelector('.grid-stack-item-content .card');
    const editBg =
      colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
        ? '#fff'
        : '#000';
    const editIcon =
      colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
        ? 'edit_black'
        : 'edit_white';

    if (el) {
      el.innerHTML = `
                  <div class='widget'>
                    <div class="title">${title} - ${time}</div>
                    <div class="team">${homeTeam ?? ''}</div>
                    <div class="team">${awayClub ?? ''}</div>
                  </div>
                    <div class='edit_container' style='background-color:${editBg}'>
                        <div class='${editIcon}' />
                    </div>
                  `;
    }
  };

  const createWidget = (data: WidgetData) => {
    const { match, matchLenght, x, y } = data;

    const lockedIco =
      colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
        ? 'locked_item_white'
        : 'locked_item_black';
    const unlockedIco =
      colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
        ? 'unlocked_item_white'
        : 'unlocked_item_black';
    const icon = match.gridLocked ? lockedIco : unlockedIco;
    const editBg =
      colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
        ? '#fff'
        : '#000';
    const editIcon =
      colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
        ? 'edit_black'
        : 'edit_white';

    const newWidget: GridStackWidget = {
      id: `match-${match.matchId}`,
      x,
      y,
      w: 1,
      locked: true,
      noResize: match.gridLocked,
      noMove: match.gridLocked,
      h: matchLenght / stepperIntervals,
      content: `
                  <div class='card ${icon}' id='${
        match.matchId
      }' style='background-color:${
        divisionColors[match.ageDivisionId] ?? '#fff'
      }; color:${colorGroup[divisionColors[match.ageDivisionId]]};'>
                    <div class='widget'>
                      <div class='title'>${getMatchTitle(match)} - ${moment
        .utc(match.date)
        .format('hh:mm A')}</div>
                    </div>
                       <div class='team'>${getHomeTeamName(match)}</div>
                       <div class='team'>${getAwayTeamName(match)}</div>
                    </div>
                    <div class='edit_container' style='background-color:${editBg}'>
                        <div class='${editIcon}' />
                    </div>
                  </div>
              `,
    };

    return newWidget;
  };

  const resetPreviousErrorWidget = (
    scheduledMatch: MatchEventModel[],
    visibleCourts: CalendarCourt[],
    errorElementId: string,
    grid: GridStack,
    currentDailyEventTime:
      | { day?: string, startTime?: string, endTime?: string }
      | undefined,
    widgets?: GridItemHTMLElement[]
  ) => {
    const errorMatch = scheduledMatch.find(
      (match) => `match-${match.matchId}` === errorElementId
    );
    const errorWidget = widgets?.find((widget) => {
      const widgetId =
        widget?.id === '' ? widget.getAttribute('gs-id') : widget?.id;

      return widgetId === errorElementId;
    });

    const x = visibleCourts?.findIndex(
      (court) => court.name === errorMatch?.subVenue
    );
    if (errorWidget && errorMatch) {
      const time = moment.utc(errorMatch?.date).format('HH:mm');
      const y = mapTimeToPosition(time, stepperIntervals);
      const difY = mapTimeToPosition(
        moment(currentDailyEventTime?.startTime ?? '', 'HH:mm:ss').format(
          'HH:mm'
        ),
        stepperIntervals
      );

      grid?.update(errorWidget, {
        x,
        y: y - difY,
      });

      changeTimeInnerScheduledElem(
        errorWidget,
        getMatchTitle(errorMatch)?.toString() || '',
        time,
        getHomeTeamName(errorMatch)?.toString() || '',
        getAwayTeamName(errorMatch)?.toString() || '',
        errorMatch
      );
    }
  };

  return {
    pools,
    getMatchTitle,
    getHomeTeamName,
    getAwayTeamName,
    changeTimeInnerScheduledElem,
    createWidget,
    resetPreviousErrorWidget,
  };
};
