import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { EventView } from '../../../admin/models/event/Event';
import { color, font } from '../../../styles/styleVariables';
import ConvertUtil from '../../../util/ConvertUtil';
import EventCardSelection from '../EventCardSelection/EventCardSelection';

interface IExpandableContainerProps {
  event?: EventView;
  isExpanded?: boolean;
}

const ExpandableContainer: React.FunctionComponent<IExpandableContainerProps> = (
  props
) => {
  const { event, children, isExpanded } = props;
  const [isExpandedState, setIsExpandedState] = useState(false);
  return (
    <Wrapper>
      <div
        onClick={() => {
          setIsExpandedState(!isExpandedState);
        }}
      >
        <CardEvent>
          <>
            <div className='image'>
              <img alt='' src={`${ConvertUtil.getMinioUrl(event?.logo)}`} />
            </div>
            <div className='text'>
              <div className='title'>{event?.name}</div>
              <div className='date'>{event?.startDate}</div>

              {event?.venues && event?.venues[0]?.address && (
                <div className='location'>{`${event?.venues[0]?.name} - ${event?.venues[0]?.address.city}, ${event?.venues[0]?.address.country}`}</div>
              )}
            </div>

            <div
              className='chevron'
              style={{
                position: 'absolute',
                right: '35px',
                top: 'calc(50% - 5px)',
              }}
            >
              <span
                className={`icon-block sm ${
                  isExpandedState ? 'i-icon-chevron-down' : 'i-icon-chevron-up'
                }`}
              />
            </div>
          </>
        </CardEvent>
        <Expandable className={`${isExpandedState ? 'opened' : ''}`}>
          {children}
        </Expandable>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 10px 0;
`;

const CardEvent = styled.div`
  padding: 10px 10px;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #444546;
  background-color: ${color.greyDark};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  transition: background-color 0.5s;
  position: relative;
  /* &:hover {
    box-shadow: 0px 0 0px 1px ${color.orange};
    .image {
      img {
        transform: scale(1.1);
      }
    }
  } */
  .image {
    width: 70px;
    height: 55px;
    padding-right: 25px;
    display: flex;
    align-items: center;
    img {
      transition: all 0.5s;
      width: 100%;
    }
  }
  .text {
    width: 50%;
    min-width: 120px;
    font-size: 12px;
    margin-right: 10px;
    .title {
      font-family: ${font.druk};
    }
    .location {
      font-weight: 600;
      margin: 10px 0;
      color: ${color.orange};
    }
    .description {
      padding-top: 10px;
      text-align: justify;
      font-size: 12px;
      color: ${color.silverLight};
    }
  }
  .chevron {
    margin-left: auto;
  }
`;

const Expandable = styled.div`
  height: 0;
  overflow: hidden;
  &.opened {
    height: auto;
    overflow: initial;
  }
`;

export default ExpandableContainer;
