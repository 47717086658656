import { Col, Row } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MemberPoolSidebox from '../../../components/ClubDirector/MemberPoolSidebox/MemberPoolSidebox';
import { rosterFetchRequest } from '../../../redux/roster/actions';
import { ApplicationState } from '../../../redux/store';
import RosterEdit from './RosterEdit';

const RosterEditPage: React.FunctionComponent = () => {
  const routeParams: { rosterId: string } = useParams();
  const dispatch = useDispatch();

  const [playerPoolListOpen, setPlayerPoolListOpen] = useState(false);
  const [coachPoolListOpen, setCoachPoolListOpen] = useState(false);

  const roster = useSelector((state: ApplicationState) => state.roster);

  useEffect(() => {
    dispatch(rosterFetchRequest(routeParams.rosterId));
  }, [dispatch, routeParams.rosterId]);

  useEffect(() => {
    if (roster.data.status === 'SUBMITTED') {
      setPlayerPoolListOpen(false);
      setCoachPoolListOpen(false);
    }
  }, [roster.data.status]);

  return (
    <Row>
      <h2>My Rosters</h2>
      <Col span={24}>
        <div>
          <Row>
            <Col md={12} lg={14}>
              <RosterEdit
                openCoachPool={() => {
                  setPlayerPoolListOpen(false);
                  setCoachPoolListOpen(true);
                }}
                openPlayerPool={() => {
                  setPlayerPoolListOpen(true);
                  setCoachPoolListOpen(false);
                }}
              />
            </Col>
            <Col md={12} lg={10}>
              {roster.data.rosterId && (
                <>
                  <MemberPoolSidebox
                    type='PLAYERS'
                    open={playerPoolListOpen}
                    rosterId={routeParams.rosterId}
                  />
                  <MemberPoolSidebox
                    type='COACHES'
                    open={coachPoolListOpen}
                    rosterId={routeParams.rosterId}
                  />
                </>
              )}
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default RosterEditPage;
