import { Col, Row, Select } from 'antd';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import Steps from '../../components/Steps/Steps';
import { newUserUpdateBasicInfo } from '../../redux/newUser/actions';
import { NewUser } from '../../redux/newUser/types';
import { ApplicationState } from '../../redux/store';
import {
  ErrorValidationBox,
  NextButtonsWrapper,
  TopHeader,
} from './WizardComponents.style';

const { Option } = Select;

const WizardPlayerMainInfo: React.FunctionComponent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control } = useForm<NewUser>();

  const { newUser } = useSelector(
    (state: ApplicationState) => state.newUser
  ).data;

  function onSubmit(data: any) {
    const submitData = data;
    submitData.birthDate = `${submitData.birthDateYear}-${submitData.birthDateMonth}-${submitData.birthDateDay}`;
    dispatch(newUserUpdateBasicInfo(submitData));
    history.push('/wizard/info2');
  }

  return (
    <div>
      <TopHeader>
        <h5 style={{ textTransform: 'uppercase' }}>
          COMPLETE YOUR HOUSEHOLD USER CREATION TO JOIN THE UNITED FUTSAL
          COMMUNITY!
        </h5>
        <h1>TELL US MORE ABOUT THIS USER</h1>
      </TopHeader>
      <Row justify='center'>
        <Col xxl={18} lg={18} md={24} className='column-wizard'>
          <Steps activeStep={1} />
          <div>
            <div style={{ maxWidth: '500px', margin: '0 auto' }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='form-group'>
                  <label htmlFor='firstName'>
                    First Name
                    <div>
                      <span className='icon icon-user' />
                      <input
                        id='firstName'
                        name='firstName'
                        ref={register({ required: true })}
                        className='icon'
                        type='text'
                        defaultValue={newUser?.firstName}
                      />
                      <ErrorValidationBox>
                        {errors.firstName && 'First name is required'}
                      </ErrorValidationBox>
                    </div>
                  </label>
                </div>
                <div className='form-group'>
                  <label htmlFor='middleName'>
                    Middle Name (optional)
                    <div>
                      <span className='icon icon-user' />
                      <input
                        id='middleName'
                        name='middleName'
                        ref={register}
                        className='icon'
                        type='text'
                        defaultValue={newUser?.middleName}
                      />
                    </div>
                  </label>
                </div>
                <div className='form-group'>
                  <label htmlFor='lastName'>
                    Last Name
                    <div>
                      <span className='icon icon-user' />
                      <input
                        id='lastName'
                        name='lastName'
                        ref={register({ required: true })}
                        className='icon'
                        type='text'
                        defaultValue={newUser?.lastName}
                      />
                      <ErrorValidationBox>
                        {errors.lastName && 'Last name is required'}
                      </ErrorValidationBox>
                    </div>
                  </label>
                </div>
                <div className='form-group'>
                  <label htmlFor='suffix'>
                    Suffix (optional)
                    <div>
                      <span className='icon icon-user' />
                      <input
                        id='suffix'
                        name='suffix'
                        ref={register}
                        className='icon'
                        type='text'
                        defaultValue={newUser?.suffix}
                      />
                    </div>
                  </label>
                </div>
                <div className='form-group'>
                  <div className='label'>Birthdate</div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ width: '40%' }}>
                      <Controller
                        as={
                          <Select placeholder='Month'>
                            <Option value='01'>January</Option>
                            <Option value='02'>February</Option>
                            <Option value='03'>March</Option>
                            <Option value='04'>April</Option>
                            <Option value='05'>May</Option>
                            <Option value='06'>June</Option>
                            <Option value='07'>July</Option>
                            <Option value='08'>August</Option>
                            <Option value='09'>September</Option>
                            <Option value='10'>October</Option>
                            <Option value='11'>November</Option>
                            <Option value='12'>December</Option>
                          </Select>
                        }
                        id='birthDateMonth'
                        name='birthDateMonth'
                        rules={{ required: true }}
                        control={control}
                        defaultValue={(
                          new Date(
                            newUser?.birthDate
                              ? newUser?.birthDate
                              : '2020-01-01'
                          ).getMonth() + 1
                        )
                          .toString()
                          .padStart(2, '0')}
                      />
                    </div>
                    <div style={{ width: '20%' }}>
                      <Controller
                        as={
                          <Select placeholder='Day'>
                            {Array.from({ length: 31 }, (_, i) => i + 1).map(
                              (x, i) => (
                                <Option
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`${x}_${i}`}
                                  value={x.toString().padStart(2, '0')}
                                >
                                  {x.toString().padStart(2, '0')}
                                </Option>
                              )
                            )}
                          </Select>
                        }
                        name='birthDateDay'
                        rules={{ required: true }}
                        control={control}
                        defaultValue={new Date(
                          newUser?.birthDate ? newUser?.birthDate : '2020-01-01'
                        )
                          .getUTCDate()
                          .toString()
                          .padStart(2, '0')}
                      />
                    </div>
                    <div style={{ width: '25%' }}>
                      <Controller
                        as={
                          <Select placeholder='Year'>
                            {Array.from({ length: 90 }, (_, i) => 2020 - i).map(
                              (x, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Option key={`year_${x}_${i}`} value={x}>
                                  {x}
                                </Option>
                              )
                            )}
                          </Select>
                        }
                        name='birthDateYear'
                        rules={{ required: true }}
                        control={control}
                        defaultValue={new Date(
                          newUser?.birthDate ? newUser?.birthDate : '2020-01-01'
                        )
                          .getUTCFullYear()
                          .toString()}
                      />
                    </div>
                  </div>

                  <ErrorValidationBox>
                    {errors.birthDate && 'Birthday is required'}
                  </ErrorValidationBox>
                </div>
                <div className='form-group'>
                  <label htmlFor='gender'>
                    Gender
                    <div>
                      <Controller
                        as={
                          <Select>
                            <Option value='MALE'>Male</Option>
                            <Option value='FEMALE'>Female</Option>
                            <Option value='OTHER'>Other</Option>
                          </Select>
                        }
                        id='gender'
                        name='gender'
                        rules={{ required: true }}
                        control={control}
                        defaultValue={newUser?.gender}
                      />
                      <ErrorValidationBox>
                        {errors.gender && 'Gender is required'}
                      </ErrorValidationBox>
                    </div>
                  </label>
                </div>
              </form>
            </div>
            <NextButtonsWrapper data-testid='wizard-nav-wrapper'>
              <Link to='/wizard'>
                <div style={{ margin: '0 10px' }}> ◄ PREVIOUS STEP</div>
              </Link>
              <div
                className='link'
                style={{ margin: '0 10px' }}
                onClick={handleSubmit(onSubmit)}
                onKeyDown={handleSubmit(onSubmit)}
                role='button'
                tabIndex={0}
                aria-label='Next Step'
              >
                NEXT STEP ►
              </div>
            </NextButtonsWrapper>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(WizardPlayerMainInfo);
