import { ExportOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Button, message, Table, Tooltip } from 'antd';
import FileSaver from 'file-saver';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import usePrimaryClub from '../../../hooks/usePrimaryClub/usePrimaryClub';
import { User } from '../../../models/User/UserModel';
import { ApplicationState } from '../../../redux/store';
import ClubService from '../../../services/Club/ClubService';
import { color } from '../../../styles/styleVariables';
import Chip from '../../base/Chip/Chip';

const MemberPool: React.FunctionComponent = () => {
  const memberPool = useSelector((state: ApplicationState) => state.memberpool);

  const [memberPoolFiltered, setMemberPoolFiltred] = useState<User[]>();
  const [filterQuery, setFilterQuery] = useState('');

  const memberships = useSelector(
    (state: ApplicationState) => state.memberships
  );

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'firstName',
      key: 'name',
      sorter: (a: any, b: any) => {
        const nameA = `${a.firstName} ${a.lastName}`;
        const nameB = `${b.firstName} ${b.lastName}`;
        return nameA.localeCompare(nameB);
      },
      render: (text: any, record: any) => (
        <>
          {record.firstName} {record.lastName}
        </>
      ),
    },
    {
      title: 'Gender',
      key: 'gender',
      dataIndex: 'gender',
      sorter: (a: any, b: any) => a.gender.localeCompare(b.gender),
    },
    {
      title: 'Birth Date',
      key: 'birthDate',
      dataIndex: 'birthDate',
      sorter: (a: any, b: any) => a.birthDate.localeCompare(b.birthDate),
    },
    {
      title: 'Account',
      key: 'membershipId',
      dataIndex: 'membershipId',
      sorter: (a: any, b: any) => a.membershipId.localeCompare(b.membershipId),
      render: (text: any, record: any) => (
        <>
          {memberships.data.find((e) => e.id === record.membershipId)?.name}{' '}
          <Link to={`/membership-upgrade/${record.id}`}>
            <Chip>Upgrade</Chip>
          </Link>
        </>
      ),
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      filters: [
        {
          text: 'Player',
          value: 'PLAYER',
        },
        {
          text: 'Coach',
          value: 'COACH',
        },
      ],
      filterMultiple: true,
      onFilter: (value: any, record: any) => {
        return record.type.indexOf(value) === 0;
      },
      // eslint-disable-next-line react/display-name
      render: (text: any, record: any) => (
        <span style={{ color: color.orange }}>{record.type}</span>
      ),
    },
    {
      title: 'Status',
      key: 'documentationVerified',
      dataIndex: 'documentationVerified',
      // eslint-disable-next-line react/display-name
      render: (text: any, record: any) =>
        record.enabled === true ? (
          <div style={{ display: 'flex' }}>
            <div
              style={{ width: '22px', height: '20px' }}
              className='i-icon-check-green'
            />
            <span>{record.documentationVerified}</span>
          </div>
        ) : (
          <Tooltip
            placement='top'
            data-html='true'
            title={() => (
              <>
                {record.documentationVerified === 'PENDING' && (
                  <>
                    Document approval is pending
                    <br />
                  </>
                )}
                {record.documentationVerified === 'DECLINED' && (
                  <>
                    Document approval is rejected
                    <br />
                  </>
                )}

                {record.ageVerified === 'PENDING' && (
                  <>
                    Age Verification is pending
                    <br />
                  </>
                )}

                {record.ageVerified === 'DECLINED' && (
                  <>
                    Age Verification is rejected
                    <br />
                  </>
                )}

                {record.photoVerified === 'PENDING' && (
                  <>
                    Photo Verification is pending
                    <br />
                  </>
                )}

                {record.photoVerified === 'DECLINED' && (
                  <>
                    Photo Verification is rejected
                    <br />
                  </>
                )}
              </>
            )}
          >
            <span style={{ color: color.silver }}>PENDING</span>
          </Tooltip>
        ),
    },
  ];

  useEffect(() => {
    setMemberPoolFiltred(memberPool.data);
  }, [memberPool]);

  const handleSearchChange = (
    event: React.FormEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setFilterQuery(event.currentTarget.value);
  };

  const filterPool = useCallback(() => {
    if (filterQuery) {
      let filtered: User[] = memberPool.data ? [...memberPool.data] : [];
      if (memberPool) {
        filtered = [
          ...memberPool.data.filter(
            (item) =>
              item.firstName
                ?.toLocaleLowerCase()
                .includes(filterQuery.toLocaleLowerCase()) ||
              item.lastName
                ?.toLocaleLowerCase()
                .includes(filterQuery.toLocaleLowerCase())
          ),
        ];
      }
      setMemberPoolFiltred(filtered);
    } else {
      setMemberPoolFiltred(memberPool.data);
    }
  }, [filterQuery, memberPool.data]);

  useEffect(() => {
    filterPool();
  }, [filterQuery, filterPool]);

  const [loadingExport, setLoadingExport] = useState(false);
  const primaryClub = usePrimaryClub();
  const exportRecords = () => {
    const clubId = primaryClub?.id || '';

    if (!clubId || clubId == '') {
      message.error({
        content: 'It was not possible to find club',
        duration: 5,
      });
      return;
    }

    setLoadingExport(true);
    ClubService.exportMemberpool(clubId)
      .then((response) =>
        FileSaver.saveAs(new Blob([response.data]), 'exported-memberpool.csv')
      )
      .then(() => setLoadingExport(false))
      .catch((error) => {
        setLoadingExport(false);
        Sentry.captureException(error, error.response?.data?.message);
        message.error({
          content: error.response?.data?.message,
          duration: 5,
        });
      });
  };

  return (
    <WrapperBox>
      <div className='top-header'>
        <div className='form-group'>
          <input
            type='text'
            className=''
            placeholder='Search'
            value={filterQuery}
            onChange={handleSearchChange}
          />
        </div>

        <Button
          loading={loadingExport}
          onClick={() => {
            exportRecords();
          }}
        >
          <ExportOutlined /> Export records
        </Button>
      </div>
      <div className='content'>
        <Table dataSource={memberPoolFiltered} columns={columns} />
      </div>
    </WrapperBox>
  );
};
const WrapperBox = styled.div`
  padding: 20px;
  background-color: ${color.greyLight};
  border-radius: 10px;
`;
export default MemberPool;
