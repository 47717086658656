import { User } from '../../models/User/UserModel';

export enum MemberpoolActionTypes {
  FETCH_REQUEST = '@@memberpool/FETCH_REQUEST',
  FETCH_SUCCESS = '@@memberpool/FETCH_SUCCESS',
  FETCH_ERROR = '@@memberpool/FETCH_ERROR',
}

export interface MemberpoolState {
  readonly loading: boolean;
  readonly data: User[];
  readonly errors?: string;
}
