import { User } from 'models/User/UserModel';
import { action } from 'typesafe-actions';
import {BaseUser, BaseUsersActionTypes, PatchBaseUser} from 'redux/v3/baseUsers/types';
import { UsersActionTypes } from './types';
import {DeepPartial} from "../../../util/types";

export const usersFetchRequest = () =>
  action(UsersActionTypes.FETCH_USERS_REQUEST);

export const usersFetchSuccess = (users: User[] | BaseUser[]) =>
  action(UsersActionTypes.FETCH_USERS_SUCCESS, users);

export const usersFetchError = (message: string) =>
  action(UsersActionTypes.FETCH_USERS_ERROR, message);

export const baseUserUpdateData = (data: DeepPartial<PatchBaseUser>) =>
    action(BaseUsersActionTypes.BASE_USERS_UPDATE_DATA, data);
