import UserType from 'models/User/UserTypeModel';

export const USER_TYPES_LABELS: Record<UserType, string> = {
  [UserType.PLAYER]: 'Player',
  [UserType.COACH]: 'Coach',
  [UserType.CLUB_DIRECTOR]: 'Club Director',
  [UserType.REFEREE]: 'Referee',
  [UserType.TOURNAMENT_DIRECTOR]: 'Tournament Director',
  [UserType.STAFF]: 'Staff',
  [UserType.TEAM_MANAGER]: 'Team Manager',
};
