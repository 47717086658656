import { GameEventModel } from '../../models/GameEvent/GameEventModel';
import {
  GamePreferences,
  MatchDetailsModel,
} from '../../models/Match/MatchModel';

export enum MatchDetailsActionTypes {
  FETCH_REQUEST = '@@match_detail/FETCH_REQUEST',
  FETCH_SUCCESS = '@@match_detail/FETCH_SUCCESS',
  FETCH_ERROR = '@@match_detail/FETCH_ERROR',

  ADD_GAME_EVENT_REQUEST = '@@match_detail/ADD_GAME_EVENT_REQUEST',
  ADD_GAME_EVENT_SUCCESS = '@@match_detail/ADD_GAME_EVENT_SUCCESS',
  ADD_GAME_EVENT_ERROR = '@@match_detail/ADD_GAME_EVENT_ERROR',
  RESEND_GAME_EVENT_REQUEST = '@@match_detail/RESEND_GAME_EVENT_REQUEST',
  RESEND_GAME_EVENT_SUCCESS = '@@match_detail/RESEND_GAME_EVENT_SUCCESS',
  RESEND_GAME_EVENT_ERROR = '@@match_detail/RESEND_GAME_EVENT_ERROR',

  REMOVE_GAME_EVENT_REQUEST = '@@match_detail/REMOVE_GAME_EVENT_REQUEST',
  REMOVE_GAME_EVENT_SUCCESS = '@@match_detail/REMOVE_GAME_EVENT_SUCCESS',
  REMOVE_GAME_EVENT_ERROR = '@@match_detail/REMOVE_GAME_EVENT_ERROR',
  RESEND_REMOVE_GAME_EVENT_REQUEST = '@@match_detail/RESEND_REMOVE_GAME_EVENT_REQUEST',
  RESEND_REMOVE_GAME_EVENT_SUCCESS = '@@match_detail/RESEND_REMOVE_GAME_EVENT_SUCCESS',
  RESEND_REMOVE_GAME_EVENT_ERROR = '@@match_detail/RESEND_REMOVE_GAME_EVENT_ERROR',

  REMOVE_GAME_EVENT_FROM_FAILED = '@@match_detail/REMOVE_GAME_EVENT_FROM_FAILED',

  UPDATE_GAME_PREFERENCES = '@@match_detail/UPDATE_GAME_PREFERENCES',
  UPDATE_MATCH_DETAILS = '@@match_detail/UPDATE_MATCH_DETAILS',
  UPDATE_MATCH_DETAILS_SUCCESS = '@@match_detail/UPDATE_MATCH_DETAILS_SUCCESS',

  UPDATE_MATCH_REPORT = '@@match_detail/UPDATE_MATCH_REPORT',

  RESET_DATA = '@@match_detail/RESET_DATA',
}

export interface MatchDetailsState {
  readonly loading: boolean;
  readonly errors: string;
  readonly preferences: GamePreferences;
  readonly data: {
    match: MatchDetailsModel | undefined;
    matchReport?: string;
    matchEventsFailed?: GameEventModel[];
    matchEventsRemoveFailed?: string[];
  };
}
