import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const PaginationWrapper = styled.div`
  margin-top: ${toRem(16)};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const WrapperNoItem = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;
`;
