import * as React from 'react';

const CovidTerms: React.FC = () => {
  return (
    <div className='terms-text'>
      <p>COVID-19 WAIVER, RELEASE AND ACKNOWLEDGEMENT AGREEMENT</p>
      <br />
      <p>
        1. COVID-19: During the pandemic that was globally declared in early
        2020, United Futsal, along with other businesses, temporarily ceased
        operating events so as to comply with CDC, federal, state and local
        guidelines.
      </p>
      <br />
      <p>
        United Futsal will now reopen the events operation division of its
        business by operating United Futsal sanctioned events (the “Events”) in
        various locations around the United States and in other countries.
      </p>
      <br />
      <p>
        In doing so, United Futsal will implement COVID-19 Event Guidelines to
        enable these events to be operated in accordance with guidelines from
        the agencies and entities mentioned above.
      </p>
      <br />
      <p>
        2. COVID-19 EVENT GUIDELINES: By electronically Accepting this COVID-19
        Waiver, Release and Acknowledgement (“Agreement”) below, you
        individually, and on behalf of any of your children under the age of 18
        years in attendance whether the child is a participant in or spectator
        at the Events, and on behalf of any spouse or other person or persons
        who accompany you, acknowledge and agree to follow the COVID-19 EVENT
        GUIDELINES that will be instituted by United Futsal for the Events.
      </p>
      <br />
      <p>
        Until otherwise removed by governmental authorities of the venue where
        an Event is taking place, such guidelines will include but will not be
        limited to individual temperature checks prior to admission, washing or
        sanitizing your hands frequently, maintaining a distance of 6 feet from
        each other, choosing not to gather in groups, and utilization of face
        coverings for all in attendance (except for players and referees during
        trainings and matches) among other guidelines.
      </p>
      <br />
      <p>
        If you are the parent or guardian of a participating or attending child
        under the age of 18 years, you also acknowledge and agree that you will
        monitor your child while on the Event premises and instruct him or her
        to adhere to all COVID-19 EVENT GUIDELINES, including any which may be
        hereafter be added, all of which shall be posted on the Events’
        websites.
      </p>
      <br />
      <p>
        3. WAIVER, RELEASE AND ACKNOWLEDGEMENT: You understand, individually and
        on behalf of your participating children and any other children of yours
        under the age of 18 years in attendance and any spouse or other person
        or persons who accompany you, that persons may contract the disease
        known as COVID-19 and that your choice to register your child to
        participate in and, if applicable, your choice to register yourself as a
        coach, as a team manager, as a referee, as a volunteer or as a staff
        member for any United Futsal Event may increase your, and their,
        likelihood of contracting COVID-19 by choosing to access the facilities
        in which the Events are being operated.
      </p>
      <br />
      <p>
        Despite this, you and those persons above mentioned on whose behalf you
        are electronically Accepting these terms, voluntarily assume the risks
        of contracting COVID-19 and any resulting medical complications that may
        arise therefrom by choosing to access the facilities in which the Events
        are being held and participating in the Events as a parent, guardian,
        coach, manager, referee, volunteer, staff member, spectator and/or
        player knowing full well this risk.
      </p>
      <br />
      <p>
        Further, you understand, individually and on behalf of your
        participating children and any other children of yours under the age of
        18 years in attendance, and any spouse or other persons who accompany
        you, that this Agreement prohibits you individually, you on behalf of
        any of your children under the age of 18 years, or any other of the
        aforementioned persons, individually or collectively, to take any legal
        action against United Futsal, A2Sports, LLC, any other entity assisting
        United Futsal in operating the Event such as, but not limited to,
        American Athletic Union (AAU), US Soccer Federation, FIFA, any Event
        designated hotels or resorts or their respective owners, shareholders,
        members, principals, agents, employees, volunteers, lessors,
        sub-lessors, lessees, sub-lessees, licensors, licensees, affiliated
        associations or any other entity or individual that may have legal
        liability through it (RELEASEES”), due to your or your participating
        children’s and any other children of yours under the age of 18 years in
        attendance, and any spouse’s or other person’s or persons’ who accompany
        you contraction or alleged contraction of the disease known as COVID-19
        and any resulting medical complications that may arise therefrom.
      </p>
      <br />
      <p>
        In the interest of clarity and in addition to the provisions set forth
        in this Agreement, you, individually and behalf of your participating
        children and any other children of yours under the age of 18 years in
        attendance and any spouse or other persons who accompany you, agree and
        acknowledge that RELEASEES shall not be responsible for any medical
        expenses incurred due to your or their contraction or alleged
        contraction of the disease known as COVID-19 and any resulting medical
        complications that may arise therefrom.
      </p>
      <br />
      <p>
        You further, individually and on behalf of your participating children
        and any other children of yours under the age of 18 years in attendance
        and any spouse or other person or persons who accompany you COVENANT NOT
        TO SUE AND AGREES TO INDEMNIFY AND HOLD HARMLESS THE RELEASEES, from any
        loss, liability, damage, or costs, including court costs and attorneys
        fees that the above referenced parties may incur due to your and their,
        individual or collective access to the Events’ facilities and
        participation in either or both Events as a parent, guardian, coach,
        manager, referee, volunteer, staff member, spectator and/or player,
        WHETHER CAUSED BY NEGLIGENCE OF RELEASEES or otherwise, to the fullest
        extent allowed by law.
      </p>
    </div>
  );
};

export default CovidTerms;
