import { Tooltip } from 'antd';
import * as React from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import XpBarWapper from './XpBar.style';

interface XpBarProps {
  minVal: number;
  maxVal: number;
  currentVal: number;
  multiplier?: string;
}

const XpBar: React.FunctionComponent<XpBarProps> = (props) => {
  const { minVal, maxVal, currentVal, multiplier } = props;
  const calculatePct = () => {
    return ((currentVal - minVal) * 100) / (maxVal - minVal);
  };

  return (
    <XpBarWapper>
      <div className='info-stats'>
        <div className='info-min'>
          <span>
            <CountUp duration={1} end={currentVal || 0} />
            XP
          </span>{' '}
          {multiplier}
        </div>
        <div className='info-max'>
          Next Level: <CountUp duration={1} end={maxVal || 16} />
          <Tooltip placement='topRight' title='How is XP calculated?'>
            <Link to='/xp-ratings/'>
              <span className='tag'>?</span>
            </Link>
          </Tooltip>
        </div>
      </div>
      <div className='progress-bar-wrapper'>
        <div
          className='progress'
          style={{ width: `${calculatePct() > 1 ? calculatePct() : 1}%` }}
        />
        <div className='bg' />
      </div>
    </XpBarWapper>
  );
};

export default XpBar;
