import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../components/base/Loading/Loading';
import UserSelectionBox from '../../components/UserSelectionBox/UserSelectionBox';
import { accountFetchAccounts } from '../../redux/account/actions';
import { membershipsFetchRequest } from '../../redux/memberships/actions';
import { ApplicationState } from '../../redux/store';
import { userUpdateActive } from '../../redux/user/actions';
import ConvertUtil from '../../util/ConvertUtil';
import { setUserId } from '../../util/UsersUtil';

const UserSelection: React.FunctionComponent = (props) => {
  const userData = useSelector((state: ApplicationState) => state.account);
  const memberships = useSelector(
    (state: ApplicationState) => state.memberships
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const selectUser = useCallback(
    (userId: string) => {
      const selectedUser = userData.data.accounts?.find(
        (account) => account.id === userId
      );
      if (selectedUser) {
        setUserId(userId);
        dispatch(userUpdateActive(selectedUser));
        history.push(location.pathname);
      }
    },
    [dispatch, location.pathname, userData.data.accounts, history]
  );

  useEffect(() => {
    // Try to auto select user if it came from URL (accountId)
    const accountId = ConvertUtil.queryParameter('accountId', location.search);
    if (accountId) {
      if (userData.data.accounts?.find((account) => account.id === accountId)) {
        selectUser(accountId);
      }
    }
  }, [userData, location.search, selectUser, userData.data.accounts]);

  useEffect(() => {
    dispatch(accountFetchAccounts());
    dispatch(membershipsFetchRequest());
  }, [dispatch]);

  useEffect(() => {
    if (userData.data.accounts?.length === 0) {
      history.push('/wizard');
    }
  }, [history, userData.data.accounts]);

  const goToWizard = () => {
    history.push('/wizard');
  };
  if (userData.loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  return (
    <>
      <div>
        <TopHeader>
          <h5 style={{ textTransform: 'uppercase' }}>
            MANAGE YOUR HOUSEHOLD ACCOUNT
          </h5>
          <h1>SELECT OR CREATE A NEW USER</h1>
        </TopHeader>

        <div
          style={{
            width: '80%',
            margin: '0 auto',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            marginTop: '60px',
          }}
        >
          {userData.data.accounts?.map((account) => {
            return (
              <UserSelectionBox
                key={account.id}
                firstName={account.firstName}
                lastName={account.lastName}
                teamIcon='/assets/imgs/Vault_logo_white.svg'
                membership={
                  memberships.data.find(
                    (element) => element.id === account.membershipId
                  )?.name
                }
                photo={
                  account.avatar
                    ? ConvertUtil.getMinioUrl(account.avatar)
                    : ConvertUtil.getMinioUrl(account.photo)
                }
                onClick={() => selectUser(account.id)}
              />
            );
          })}
          <UserSelectionBox newButton onClick={() => goToWizard()} />
        </div>
      </div>
    </>
  );
};
export const TopHeader = styled.div`
  text-align: center;
  padding: 0 15px;
  padding-top: 20px;
  h1 {
    font-size: 36px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
`;

export default UserSelection;
