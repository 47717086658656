import * as React from 'react'

interface IClickOutsideHandleProps {
  onClickOutside: () => void
  className?: string
}

const ClickOutsideHandle: React.FunctionComponent<IClickOutsideHandleProps> = (
  props
) => {
  const { onClickOutside } = props
  const [mouseIsOutside, setMouseIsOutside] = React.useState(true)
  const handleClickOuside = () => {
    if (mouseIsOutside) {
      onClickOutside.call(null)
    }
  }
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOuside)
    return () => {
      document.removeEventListener('mousedown', handleClickOuside)
    }
  }, [mouseIsOutside])

  const { children, className } = props
  return (
    <div
      className={className}
      onMouseEnter={() => {
        setMouseIsOutside(false)
      }}
      onMouseLeave={() => {
        setMouseIsOutside(true)
      }}
    >
      {children}
    </div>
  )
}

export default ClickOutsideHandle
