import { Checkbox, Table } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../../components/uielements/Button/Button';
import EventService from '../../../../services/Event/EventService';
import { color } from '../../../../styles/styleVariables';
import ConvertUtil from '../../../../util/ConvertUtil';
import { AgeDivision, SpecialDivision } from '../../../models/AgeDivision';
import { EventCreation, EventView } from '../../../models/event/Event';

interface ICreateDivisionModal {
  selectedRosters: string[];
  specialDivisions: SpecialDivision[];
  closeModal: () => void;
}

const CreateDivisionModal: React.FunctionComponent<ICreateDivisionModal> = (
  props
) => {
  const { specialDivisions, selectedRosters } = props;
  const { closeModal } = props;
  const { eventId } = useParams<{ eventId: string }>();
  const [divisionName, setDivisionName] = useState('');
  const [selectedSpecialDivision, setSelectedSpecialDivision] = useState('');

  const createSpecialDivision = () => {
    if (divisionName || selectedSpecialDivision) {
      EventService.find(eventId).then((res) => {
        if (res.success) {
          const newDivisionName = divisionName || selectedSpecialDivision;
          let eventUpdated: EventCreation = res.data as EventCreation;

          // If specialDivision name already exists replace
          if (
            eventUpdated.specialDivisions?.find(
              (item) => item.name === newDivisionName
            )
          ) {
            eventUpdated.specialDivisions = [
              ...(eventUpdated.specialDivisions.filter(
                (item) => item.name !== newDivisionName
              ) || []),
              {
                name: newDivisionName,
                rosterList: selectedRosters,
              },
            ];
          }
          // If not, add
          else {
            eventUpdated.specialDivisions = [
              ...(eventUpdated.specialDivisions || []),
              {
                name: newDivisionName,
                rosterList: selectedRosters,
              },
            ];
          }

          EventService.update(eventId, eventUpdated).then((res) => {
            if (res.success) {
              closeModal();
            }
          });
        }
      });
    }
  };

  return (
    <div
      style={{
        margin: '0 auto',
        padding: '50px',
        borderRadius: '10px',
        backgroundColor: color.greyLight,
      }}
    >
      <h2>
        Add{' '}
        <span style={{ color: color.orange }}>{selectedRosters.length}</span>{' '}
        selected rosters to:
      </h2>
      <form>
        {!divisionName && (
          <div className='form-group'>
            <select
              defaultValue=''
              value={selectedSpecialDivision}
              onChange={(event) =>
                setSelectedSpecialDivision(event.target.value)
              }
            >
              <option value='' disabled>
                Select Special Division
              </option>
              {specialDivisions
                .sort((a, b) =>
                  a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()
                    ? 1
                    : -1
                )
                .map((item) => {
                  return <option value={item.name}>{item.name}</option>;
                })}
            </select>
          </div>
        )}
        <h4>Or create new</h4>
        <div className='form-group'>
          <label>
            <input
              placeholder='Division name'
              type='text'
              value={divisionName}
              onChange={(event) => setDivisionName(event.target.value)}
            />
          </label>
        </div>
      </form>

      <Button
        disabled={!selectedSpecialDivision && !divisionName}
        onClick={() => createSpecialDivision()}
      >
        Add rosters to special division
      </Button>
    </div>
  );
};

const ItemDivision = styled.div`
  cursor: pointer;
  padding: 10px;
  background-color: ${color.greyText};
  border-radius: 5px;
  margin: 5px;
  font-weight: bold;
  &.selected {
    background-color: ${color.orange};
  }
`;

export default CreateDivisionModal;
