import styled from 'styled-components';
import { COLORS, ColorsInterface } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div<{ bgColor: keyof ColorsInterface }>`
  display: flex;
  padding: ${toRem(6)} ${toRem(10)};
  gap: ${toRem(2)};

  align-items: center;
  justify-content: start;
  border-radius: ${toRem(24)};
  background-color: ${({ bgColor }) =>  COLORS[bgColor]};

  span {
    display: flex;
  }
`;
