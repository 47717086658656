import styled from 'styled-components';

import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${toRem(128)};
`;

export const FindWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(18)};
  flex: 1;
`;

export const InviteListWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${toRem(14)};
  flex: 1;
`;

export const InviteListMain = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${toRem(450)};
  gap: ${toRem(8)};
  padding: ${toRem(24)};
  border-radius: ${toRem(24)};
  background-color: ${COLORS.grey900};
`;

export const FilterText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${toRem(8)};

  span {
    margin-top: ${toRem(6)};
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${toRem(18)};

  & > div {
    flex: auto;
  }

  .vault-select__control {
    min-width: 140px;
  }
`;

export const SimpleCardList = styled.div`
  height: ${toRem(328)};
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }

  ::-webkit-scrollbar-thumb:active {
    background: #000000;
  }

  ::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-track:hover {
    background: #666666;
  }

  ::-webkit-scrollbar-track:active {
    background: #333333;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const InviteEmailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${toRem(8)};

  span {
    margin-top: ${toRem(6)};
  }
`;

export const FallbackMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${toRem(32)};
  gap: ${toRem(4)};
`;
