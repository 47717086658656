import * as React from 'react';
import styled from 'styled-components';
import { color } from '../../../styles/styleVariables';

interface ClubBoxProps {
  clubName?: string;
  membership?: string;
}

const ClubBox: React.FunctionComponent<ClubBoxProps> = (props) => {
  const { clubName, membership } = props;
  return (
    <ClubBoxWrapper>
      <div className='icon-followers' />
      <span>{clubName}</span> {membership}
    </ClubBoxWrapper>
  );
};
const ClubBoxWrapper = styled.div`
  font-size: 12px;
  display: flex;
  text-transform: uppercase;
  span {
    color: ${color.orange};
    margin-right: 5px;
    font-weight: 700;
  }
  .icon-followers {
    height: 17px;
    width: 30px;
    background-image: url('/assets/imgs/icons/ico_clubs.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export default ClubBox;
