import { Col, DatePicker, message, Row, Select, Upload } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Controller, DeepPartial, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import V3UserService from 'services/v3/User/UserService';

import { PatchBaseUser } from 'redux/v3/baseUsers/types';
import { Gender } from 'admin/models/Enums';
import pdf_icon from 'assets/imgs/icons/pdf_file.svg';
import AddClubModal from 'components/AddClubModal/AddClubModal';
import EditPhoto from 'components/base/EditPhoto/EditPhoto';
import LoadingIcon from 'components/base/LoadingIcon/LoadingIcon';
import PageContainer from 'components/base/PageContainer/PageContainer';
import Button from 'components/uielements/Button/Button';
import { countries, states } from 'models/LocaleList';
import { User } from 'models/User/UserModel';
import UserType from 'models/User/UserTypeModel';
import { ErrorValidationBox } from 'pages/Wizard/WizardComponents.style';
import DownloadService from 'services/download/DownloadService';
import UploadService from 'services/upload/UploadService';
import UserServices from 'services/User/UserApi';
import { color } from 'styles/styleVariables';
import ConvertUtil from 'util/ConvertUtil';

const { Option } = Select;

const UserEditPage = () => {
  const [certificateImage, setCertificateImage] = useState<string>('');
  const [ageProofImage, setAgeProofImage] = useState<string>('');

  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [editPhotoOpen, setEditPhotoOpen] = useState(false);

  const copyIdToClipboard = () => {
    if (user) {
      const el = document.createElement('textarea');
      el.value = user.id;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      message.info({
        content: 'Account Number copied to your clipboard',
        duration: 3,
      });
    }
  };

  const [documents, setDocuments] = useState<{
    photo?: string;
    ageProof?: string;
    certificate?: string;
  }>({});

  const { userId } = useParams<{ userId: string }>();

  const [user, setUser] = useState<User>();
  const [isAddClubModalOpen, setIsAddClubModalOpen] = useState(false);
  const [editedImg, setEditedImg] = useState('');
  const [documentPaths, setDocumentPaths] = useState<{
    ageProof: string;
    certificate: string;
  }>({ ageProof: '', certificate: '' });

  const {
    errors,
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
  } = useForm<User>();
  const watchBirthDate = watch('birthDate');

  function onSubmit() {
    const data = getValues();

    if (user) {
      const updatedData: DeepPartial<PatchBaseUser> = {
        ...user,
        ...(data.address && {
          address: {
            city: data.address?.city ? data.address?.city : '',
            country: data.address?.country ? data.address?.country : '',
            line1: data.address?.line1 ? data.address?.line1 : '',
            line2: data.address?.line2 ? data.address?.line2 : '',
            state: data.address?.state ? data.address?.state : '',
            zipCode: data.address?.zipCode ? data.address?.zipCode : '',
          },
        }),
        ...(data.guardian1 && {
          guardian1: {
            firstName: data.guardian1?.firstName || '',
            lastName: data.guardian1?.lastName || '',
            middleName: data.guardian1?.middleName || '',
            address: {
              city: data.guardian1?.address?.city
                ? data.guardian1.address.city
                : '',
              country: data.guardian1?.address?.country
                ? data.guardian1.address.country
                : '',
              line1: data.guardian1?.address?.line1
                ? data.guardian1.address.line1
                : '',
              line2: data.guardian1?.address?.line2
                ? data.guardian1.address.line2
                : '',
              state: data.guardian1?.address?.state
                ? data.guardian1.address.state
                : '',
              zipCode: data.guardian1?.address?.zipCode
                ? data.guardian1.address.zipCode
                : '',
            },
            phoneNumber: data.guardian1?.phoneNumber || '',
            email: data.guardian1?.email || '',
          },
        }),
        ...(data.guardian2 && {
          guardian2: {
            address: {
              city: data.guardian2?.address?.city
                ? data.guardian2.address.city
                : '',
              country: data.guardian2?.address?.country
                ? data.guardian2.address.country
                : '',
              line1: data.guardian2?.address?.line1
                ? data.guardian2.address.line1
                : '',
              line2: data.guardian2?.address?.line2
                ? data.guardian2.address.line2
                : '',
              state: data.guardian2?.address?.state
                ? data.guardian2.address.state
                : '',
              zipCode: data.guardian2?.address?.zipCode
                ? data.guardian2.address.zipCode
                : '',
            },
          },
        }),
        ...(data.firstName && {
          firstName: data.firstName,
        }),
        ...(data.lastName && {
          lastName: data.lastName,
        }),
        ...(data.middleName && {
          middleName: data.middleName,
        }),
        ...(data.suffix && {
          suffix: data.suffix,
        }),
        phoneNumber: data.phoneNumber ?? '',
        email: data.email,
        photo: editedImg || (documents.photo ? documents.photo : user.photo),
        ...(documents.ageProof && {
          ageProof: documents.ageProof,
        }),
        birthDate: (data.birthDate as moment.Moment)?.format('YYYY-MM-DD'),
        extraInfo: {
          ...user.extraInfo,
          certificate: documents.certificate
            ? documents.certificate
            : user.extraInfo?.certificate,
        },
        gender: data.gender as Gender,
      };

      if (user.baseUserId) {
        V3UserService.updateUser(user.baseUserId, updatedData, true, user.type).then((res) => {
            if (res.success) {
              message.success('User data Updated');
            } else {
              message.error(`Error${res.data}`);
            }
          })
      } else {
        UserServices.updateUser(updatedData, userId).then((res) => {
          if (res.success) {
            message.success('User data Updated');
          } else {
            message.error(`Error${res.data}`);
          }
        });
      }
    }
  }

  useEffect(() => {
    if (user && user.id) {
      UserServices.getUserDocuments(user.id).then((res) => {
        if (res.success) {
          const ageProofPath = ConvertUtil.getPrivateMinioUrl(
            res.data.ageProof
          );
          const certificatePath = ConvertUtil.getPrivateMinioUrl(
            res.data.certificate
          );

          setDocumentPaths({
            ageProof: ageProofPath,
            certificate: certificatePath,
          });

          fetch(ageProofPath)
            .then((response) => response.blob())
            .then((blob) => {
              if (blob.type === 'application/pdf') {
                setAgeProofImage(pdf_icon);
              } else {
                setAgeProofImage(ageProofPath);
              }
            });

          fetch(certificatePath)
            .then((response) => response.blob())
            .then((blob) => {
              if (blob.type === 'application/pdf') {
                setCertificateImage(pdf_icon);
              } else {
                setCertificateImage(certificatePath);
              }
            });
        }
      });
    }
  }, [user, setValue]);

  const uploadAgeProof = (event: any) => {
    upload(event, 'ageProof');
  };

  const uploadPhoto = (event: any) => {
    upload(event, 'photo');
  };

  const uploadCertificate = (event: any) => {
    upload(event, 'certificate');
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png' ||
      file.type === 'application/pdf';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/PDF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('File must smaller than 20MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadEdited = (file: File) => {
    setIsLoadingImg(true);
    const type = 'photo';
    UploadService.upload({
      file,
      prefix: 'user',
      public: type === 'photo',
    }).then((response) => {
      if (!response.success) {
        message.error({ content: response.message, duration: 5 });
        return;
      }
      const url = response.data.id;

      if (type === 'photo') {
        setEditedImg(url);
      }
      setIsLoadingImg(false);
    });
  };

  const upload = (event: any, type: string) => {
    setIsLoadingImg(true);
    UploadService.upload({
      file: event.file,
      prefix: 'user',
      public: type === 'photo',
    }).then((response) => {
      if (!response.success) {
        message.error({ content: response.message, duration: 5 });
        return;
      }
      const url = response.data.id;

      if (type === 'ageProof') {
        setDocuments({ ...documents, ageProof: url });
        DownloadService.download(url).then((res) => {
          if (res.success) {
            // Verify if file is an IMAGE or PDF to correctly show icon on preview
            fetch(ConvertUtil.getPrivateMinioUrl(res.data))
              .then((blobResp) => blobResp.blob())
              .then((blob) => {
                if (blob.type === 'application/pdf') {
                  setAgeProofImage(pdf_icon);
                } else {
                  setAgeProofImage(ConvertUtil.getPrivateMinioUrl(res.data));
                }
              });
          }
        });
      }
      if (type === 'photo') {
        setEditedImg('');
        setDocuments({ ...documents, photo: url });
        setEditPhotoOpen(true);
      }
      if (type === 'certificate') {
        setDocuments({ ...documents, certificate: url });
        DownloadService.download(url).then((res) => {
          if (res.success) {
            fetch(ConvertUtil.getPrivateMinioUrl(res.data))
              .then((blobResp) => blobResp.blob())
              .then((blob) => {
                if (blob.type === 'application/pdf') {
                  setCertificateImage(pdf_icon);
                } else {
                  setCertificateImage(ConvertUtil.getPrivateMinioUrl(res.data));
                }
              });
          }
        });
      }
      setIsLoadingImg(false);
    });
  };

  const getUserData = () => {
    UserServices.search(undefined, { idList: [userId] }).then((res) => {
      if (res.success) {
        setUser(res.data.content[0]);
      }
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const isMinor = () => {
    if (watchBirthDate !== undefined) {
      const date: moment.Moment = moment(
        moment().subtract(18, 'years').format('YYYY-MM-DD')
      );
      const birth: moment.Moment = moment(
        moment(watchBirthDate).format('YYYY-MM-DD')
      );

      return birth.isAfter(date);
    }
    return false;
  };

  return (
    <>
      {user && (
        <PageContainer title='Edit User'>
          <BoxWrapper>
            <AccountNumberBox
              style={{ fontSize: '16px', margin: '10px', cursor: 'pointer' }}
              onClick={copyIdToClipboard}
            >
              User&apos;s Account Number: <b>{user.id}</b>{' '}
              <span
                style={{ marginLeft: '10px' }}
                className='icon clear icon-copy'
              />
            </AccountNumberBox>
            {(user.type === UserType.PLAYER ||
              user.type === UserType.COACH) && (
              <Row gutter={16} style={{ marginTop: '10px' }}>
                <Col xxl={24} lg={24} md={24} style={{ padding: '0 20px' }}>
                  <h3>Clubs</h3>
                  <BoxWrapper style={{ backgroundColor: color.greyDark }}>
                    {user.clubs.length > 0 && (
                      <ClubBox>
                        {user.clubs.map((club) => {
                          return (
                            <div className='club-item'>
                              <div
                                className='club-logo'
                                style={{
                                  backgroundImage: `url(${ConvertUtil.getMinioUrl(
                                    club.logo
                                  )})`,
                                }}
                              />
                              <div className='club-name'>{club.name}</div>
                            </div>
                          );
                        })}
                      </ClubBox>
                    )}
                    <Button
                      size='small'
                      onClick={() => {
                        setIsAddClubModalOpen(true);
                      }}
                      style={{ margin: 0 }}
                    >
                      Add club
                    </Button>
                  </BoxWrapper>
                </Col>
              </Row>
            )}
            <Row>
              <Col span={24} style={{ padding: '0 12px' }}>
                <div
                  style={{
                    margin: '10px 0',
                    color: '#878b93',
                    fontWeight: 500,
                  }}
                >
                  Official Documentation
                </div>
                <DocumentsWrapper>
                  <div className='box-picture'>
                    <div className='title'>Official Picture</div>
                    <div className='image'>
                      {isLoadingImg && <LoadingIcon text="Uploading..." />}
                      {!isLoadingImg && (
                        <>
                          <img
                            src={`${ConvertUtil.getMinioUrl(
                              documents.photo ? documents.photo : user.photo
                            )}`}
                            style={{
                              width: '100%',
                              position: 'absolute',
                              left: 0,
                              top: 0,
                            }}
                            alt="Avatar"
                          />
                          {editedImg && (
                            <div
                              className='image'
                              style={{ position: 'absolute' }}
                            >
                              <img
                                src={`${ConvertUtil.getMinioUrl(editedImg)}`}
                                style={{ width: '100%' }}
                                alt="Avatar"
                              />
                            </div>
                          )}
                          <div
                            role='presentation'
                            className='edit-image i-icon-edit'
                            onClick={() => setEditPhotoOpen(true)}
                          />
                          <EditPhoto
                            imgURL={`${ConvertUtil.getMinioUrl(
                              documents.photo ? documents.photo : user.photo,
                              1000
                            )}?t=rand`}
                            afterEdit={uploadEdited}
                            open={editPhotoOpen}
                            onClose={() => setEditPhotoOpen(false)}
                          />
                        </>
                      )}
                    </div>
                    <div className='status'>
                      {user.photoVerified === 'APPROVED' && (
                        <Approved className='i-icon-check-green'>
                          Approved
                        </Approved>
                      )}
                      {user.photoVerified === 'PENDING' &&
                        'Waiting for approval'}
                      {user.photoVerified === 'DECLINED' && 'Rejected'}
                    </div>
                    <EditPhoto
                      imgURL={`${ConvertUtil.getMinioUrl(
                        documents.photo ? documents.photo : user.photo,
                        1000
                      )}?t=rand`}
                      afterEdit={uploadEdited}
                      open={editPhotoOpen}
                      onClose={() => setEditPhotoOpen(false)}
                    />
                    <Upload
                      showUploadList={false}
                      accept='image/*'
                      customRequest={uploadPhoto}
                      beforeUpload={beforeUpload}
                    >
                      <Button
                        style={{
                          fontSize: '9px',
                        }}
                      >
                        Change Image
                      </Button>
                    </Upload>
                  </div>
                  {user.type === UserType.PLAYER && (
                    <div className='box-picture'>
                      <div className='title'>
                        Your Proof of Age Documentation
                      </div>
                      <div
                        role='presentation'
                        className='image'
                        onClick={() =>
                          window.open(documentPaths.ageProof, '_blank')
                        }
                        style={{
                          backgroundImage: `url(${ageProofImage})`,
                        }}
                      >
                        <div className='download-buttom'>Download file</div>
                      </div>
                      <div className='status'>
                        {user.ageVerified === 'APPROVED' && (
                          <Approved className='i-icon-check-green'>
                            Approved
                          </Approved>
                        )}
                        {user.ageVerified === 'PENDING' &&
                          'Waiting for approval'}
                        {user.ageVerified === 'DECLINED' && 'Rejected'}
                      </div>
                      <Upload
                        showUploadList={false}
                        accept='image/*,application/pdf'
                        customRequest={uploadAgeProof}
                        beforeUpload={beforeUpload}
                      >
                        <Button
                          style={{
                            fontSize: '9px',
                          }}
                        >
                          Change document
                        </Button>
                      </Upload>
                    </div>
                  )}
                  {user.type !== UserType.PLAYER && (
                    <>
                      <div className='box-picture'>
                        <div className='title'>SafeSport Certificate</div>
                        <div
                          role='presentation'
                          className='image'
                          onClick={() =>
                            window.open(documentPaths.certificate, '_blank')
                          }
                          style={{
                            backgroundImage: `url(${certificateImage})`,
                          }}
                        >
                          <div className='download-buttom'>Download file</div>
                        </div>
                        <div className='status'>
                          {user.documentationVerified === 'APPROVED' && (
                            <Approved className='i-icon-check-green'>
                              Approved
                            </Approved>
                          )}
                          {user.documentationVerified === 'PENDING' &&
                            'Waiting for approval'}
                          {user.documentationVerified === 'DECLINED' &&
                            'Rejected'}
                        </div>
                        <Upload
                          showUploadList={false}
                          accept='image/*,application/pdf'
                          customRequest={uploadCertificate}
                          beforeUpload={beforeUpload}
                        >
                          <Button
                            style={{
                              fontSize: '9px',
                            }}
                          >
                            Resend document
                          </Button>
                        </Upload>
                      </div>
                    </>
                  )}
                </DocumentsWrapper>
              </Col>
            </Row>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row gutter={16}>
                <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
                  <div className='form-group'>
                    <label htmlFor='firstName'>
                      Account Email
                      <div className='form-group-wrapper'>
                        <span className='icon icon-user' />
                        <input
                          disabled
                          id='firstName'
                          name='firstName'
                          className='icon'
                          type='text'
                          defaultValue={user.accountEmail || '-'}
                        />
                      </div>
                    </label>
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
                  <div className='form-group'>
                    <label htmlFor='firstName'>
                      First Name
                      <div className='form-group-wrapper'>
                        <span className='icon icon-user' />
                        {user.firstName && (
                          <input
                            id='firstName'
                            name='firstName'
                            ref={register({ required: true })}
                            className='icon'
                            type='text'
                            defaultValue={user.firstName}
                          />
                        )}
                        <ErrorValidationBox>
                          {errors.firstName && 'First Name is required'}
                        </ErrorValidationBox>
                      </div>
                    </label>
                  </div>
                </Col>
                <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
                  <div className='form-group'>
                    <label htmlFor='middleName'>
                      Middle Name
                      <div className='form-group-wrapper'>
                        <span className='icon icon-user' />
                        <input
                          id='middleName'
                          name='middleName'
                          ref={register()}
                          className='icon'
                          type='text'
                          defaultValue={user.middleName}
                        />
                      </div>
                    </label>
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
                  <div className='form-group'>
                    <label htmlFor='lastName'>
                      Last Name
                      <div className='form-group-wrapper'>
                        <span className='icon icon-user' />
                        {user.lastName && (
                          <input
                            id='lastName'
                            name='lastName'
                            ref={register({ required: true })}
                            className='icon'
                            type='text'
                            defaultValue={user.lastName}
                          />
                        )}
                      </div>
                    </label>
                  </div>
                </Col>
                <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
                  <div className='form-group'>
                    <label htmlFor='suffix'>
                      Suffix
                      <div className='form-group-wrapper'>
                        <span className='icon icon-user' />
                        <input
                          id='suffix'
                          name='suffix'
                          ref={register()}
                          className='icon'
                          type='text'
                          defaultValue={user.suffix}
                        />
                      </div>
                    </label>
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
                  <div className='form-group'>
                    <label htmlFor='birthDate'>
                      Birth Date
                      <div>
                        {user.birthDate && (
                          <Controller
                            render={(properties) => (
                              <DatePicker
                                {...properties}
                                onChange={(e) => {
                                  properties.onChange(e);
                                }}
                                format='YYYY/DD/MM'
                              />
                            )}
                            id='birthDate'
                            name='birthDate'
                            control={control}
                            defaultValue={moment(user.birthDate)}
                          />
                        )}
                      </div>
                    </label>
                  </div>
                </Col>
                <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
                  <div className='form-group'>
                    <label htmlFor='gender'>
                      Gender
                      <div>
                        <Controller
                          id='gender'
                          name='gender'
                          rules={{ required: true }}
                          control={control}
                          defaultValue={user?.gender}
                          render={(properties) => (
                            <Select
                              {...properties}
                              onChange={(e) => {
                                properties.onChange(e);
                              }}
                            >
                              <Option value='MALE'>Male</Option>
                              <Option value='FEMALE'>Female</Option>
                              <Option value='OTHER'>Other</Option>
                            </Select>
                          )}
                        />
                      </div>
                    </label>
                  </div>
                </Col>
              </Row>
              {!user.guardian1 && (
                <Row gutter={16}>
                  <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
                    <div className='form-group'>
                      <label htmlFor='address.line1'>
                        Address Line 1
                        <div className='form-group-wrapper'>
                          <span className='icon icon-house' />
                          <input
                            id='address.line1'
                            name='address.line1'
                            ref={register({ required: true })}
                            className='icon'
                            type='text'
                            defaultValue={user.address?.line1}
                          />
                          <ErrorValidationBox>
                            {errors.address?.line1 && 'Address is required'}
                          </ErrorValidationBox>
                        </div>
                      </label>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='address.line2'>
                        Address Line 2
                        <div className='form-group-wrapper'>
                          <span className='icon icon-house' />
                          <input
                            id='address.line2'
                            name='address.line2'
                            className='icon'
                            type='text'
                            defaultValue={user.address?.line2}
                          />
                        </div>
                      </label>
                    </div>
                    <div className='form-group'>
                      <div className='label'>Country</div>
                      <div className='form-group-wrapper'>
                        <span className='icon icon-flag' />
                        <Controller
                          id='address.country'
                          name='address.country'
                          control={control}
                          defaultValue={user.address?.country}
                          render={(properties) => (
                            <Select
                              {...properties}
                              onChange={(e) => {
                                properties.onChange(e);
                              }}
                              className='icon'
                              placeholder='Country'
                            >
                              {countries.map((item) => {
                                return (
                                  <Option key={item.code} value={item.code}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='label'>State</div>
                      <div className='form-group-wrapper'>
                        <span className='icon icon-flag' />
                        <Controller
                          id='address.state'
                          name='address.state'
                          control={control}
                          defaultValue={user.address?.state}
                          render={(properties) => (
                            <Select
                              {...properties}
                              onChange={(e) => {
                                properties.onChange(e);
                              }}
                              className='icon'
                              placeholder='State'
                            >
                              {states.map((item) => {
                                return (
                                  <Option key={item.code} value={item.code}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
                    <div className='form-group'>
                      <label htmlFor='address.city'>
                        City
                        <div className='form-group-wrapper'>
                          <span className='icon icon-city' />
                          <input
                            id='address.city'
                            name='address.city'
                            ref={register({ required: true })}
                            className='icon'
                            type='text'
                            defaultValue={user.address?.city}
                          />
                        </div>
                      </label>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='address.zipCode'>
                        Zipcode
                        <div className='form-group-wrapper'>
                          <span className='icon icon-zip' />
                          <input
                            id='address.zipCode'
                            name='address.zipCode'
                            ref={register({ required: true })}
                            className='icon'
                            type='text'
                            defaultValue={user.address?.zipCode}
                          />
                        </div>
                      </label>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='phoneNumber'>
                        Phone Number
                        <div className='form-group-wrapper'>
                          <span className='icon icon-phone' />
                          <input
                            id='phoneNumber'
                            name='phoneNumber'
                            className='icon'
                            type='text'
                            defaultValue={user.phoneNumber}
                          />
                        </div>
                      </label>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='email'>
                        Email
                        <div className='form-group-wrapper'>
                          <span className='icon icon-email' />
                          <input
                            id='email'
                            name='email'
                            ref={register({ required: true })}
                            className='icon'
                            type='text'
                            defaultValue={user.email}
                          />
                        </div>
                      </label>
                    </div>
                  </Col>
                </Row>
              )}
              {(user.guardian1 || isMinor()) && (
                <Row className='form-group-row'>
                  <Col span={24} style={{ padding: '10px 20px 20px 20px' }}>
                    <div className='bold text-orange'>
                      Guardian&apos;s Information:
                    </div>
                  </Col>
                  <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
                    <div className='form-group'>
                      <label htmlFor='guardian1.firstName'>
                        Parent/Guardian First Name
                        <div className='form-group-wrapper'>
                          <span className='icon icon-user' />
                          <input
                            id='guardian1.firstName'
                            name='guardian1.firstName'
                            ref={register({ required: true })}
                            defaultValue={user.guardian1?.firstName}
                            className='icon'
                            type='text'
                            autoComplete='stopAutocomplete'
                          />
                        </div>
                      </label>
                      <ErrorValidationBox>
                        {errors.guardian1?.firstName &&
                          'Parent/Guardian First Name is required'}
                      </ErrorValidationBox>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='guardian1.middleName'>
                        Parent/Guardian Middle Name (Optional)
                        <div className='form-group-wrapper'>
                          <span className='icon icon-user' />
                          <input
                            id='guardian1.middleName'
                            name='guardian1.middleName'
                            ref={register}
                            defaultValue={user?.guardian1?.middleName}
                            className='icon'
                            type='text'
                            autoComplete='stopAutocomplete'
                          />
                        </div>
                      </label>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='guardian1.lastName'>
                        Parent/Guardian Last Name
                        <div className='form-group-wrapper'>
                          <span className='icon icon-user' />
                          <input
                            id='guardian1.lastName'
                            name='guardian1.lastName'
                            ref={register({ required: true })}
                            defaultValue={user?.guardian1?.lastName}
                            className='icon'
                            type='text'
                            autoComplete='stopAutocomplete'
                          />
                        </div>
                      </label>
                      <ErrorValidationBox>
                        {errors.guardian1?.lastName &&
                          'Parent/Guardian Last Name is required'}
                      </ErrorValidationBox>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='guardian1.address.line1'>
                        Address Line 1
                        <div className='form-group-wrapper'>
                          <span className='icon icon-house' />
                          <input
                            id='guardian1.address.line1'
                            name='guardian1.address.line1'
                            ref={register({ required: true })}
                            className='icon'
                            type='text'
                            defaultValue={user.guardian1?.address?.line1}
                          />
                        </div>
                      </label>
                      <ErrorValidationBox>
                        {errors.guardian1?.address?.line1 &&
                          'Address is required'}
                      </ErrorValidationBox>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='guardian1.address.line2'>
                        Address Line 2
                        <div className='form-group-wrapper'>
                          <span className='icon icon-house' />
                          <input
                            id='guardian1.address.line2'
                            name='guardian1.address.line2'
                            className='icon'
                            type='text'
                            defaultValue={user.guardian1?.address?.line2 || ''}
                          />
                        </div>
                      </label>
                      <ErrorValidationBox>
                        {errors.guardian1?.address?.line2 &&
                          'Address is required'}
                      </ErrorValidationBox>
                    </div>
                  </Col>
                  <Col xxl={12} lg={12} md={24} style={{ padding: '0 20px' }}>
                    <div className='form-group'>
                      <label htmlFor='guardian1.address.city'>
                        City
                        <div className='form-group-wrapper'>
                          <span className='icon icon-city' />
                          <input
                            id='guardian1.address.city'
                            name='guardian1.address.city'
                            ref={register({ required: true })}
                            className='icon'
                            type='text'
                            defaultValue={user.guardian1?.address?.city || ''}
                          />
                        </div>
                      </label>
                      <ErrorValidationBox>
                        {errors.guardian1?.address?.city && 'City is required'}
                      </ErrorValidationBox>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='guardian1.address.zipCode'>
                        Zipcode
                        <div className='form-group-wrapper'>
                          <span className='icon icon-zip' />
                          <input
                            id='guardian1.address.zipCode'
                            name='guardian1.address.zipCode'
                            ref={register({ required: true })}
                            className='icon'
                            type='text'
                            defaultValue={
                              user.guardian1?.address?.zipCode || ''
                            }
                          />
                        </div>
                      </label>
                      <ErrorValidationBox>
                        {errors.guardian1?.address?.zipCode &&
                          'Zip code is required'}
                      </ErrorValidationBox>
                    </div>
                    <div className='form-group'>
                      <div className='label'>Country</div>
                      <div className='form-group-wrapper'>
                        <span className='icon icon-flag' />
                        <Controller
                          id='guardian1.address.country'
                          name='guardian1.address.country'
                          control={control}
                          defaultValue={user.guardian1?.address?.country || ''}
                          rules={{ required: true }}
                          as={
                            <Select className='icon' placeholder='Country'>
                              {countries.map((item) => {
                                return (
                                  <Option key={item.code} value={item.code}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          }
                        />
                      </div>
                      <ErrorValidationBox>
                        {errors.guardian1?.address?.country &&
                          'Country is required'}
                      </ErrorValidationBox>
                    </div>
                    <div className='form-group'>
                      <div className='label'>State</div>
                      <div className='form-group-wrapper'>
                        <span className='icon icon-flag' />
                        <Controller
                          id='guardian1.address.state'
                          name='guardian1.address.state'
                          control={control}
                          defaultValue={user.guardian1?.address?.state || ''}
                          rules={{ required: true }}
                          as={
                            <Select className='icon' placeholder='State'>
                              {states.map((item) => {
                                return (
                                  <Option key={item.code} value={item.code}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          }
                        />
                        <ErrorValidationBox>
                          {errors.guardian1?.address?.state &&
                            'State is required'}
                        </ErrorValidationBox>
                      </div>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='guardian1.phoneNumber'>
                        Phone Number
                        <div className='form-group-wrapper'>
                          <span className='icon icon-phone' />
                          <input
                            id='guardian1.phoneNumber'
                            name='guardian1.phoneNumber'
                            ref={register({ required: true })}
                            className='icon'
                            type='text'
                            defaultValue={user.guardian1?.phoneNumber || ''}
                          />
                        </div>
                      </label>
                      <ErrorValidationBox>
                        {errors.guardian1?.phoneNumber &&
                          'Phone Number is required'}
                      </ErrorValidationBox>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='guardian1.email'>
                        Email
                        <div className='form-group-wrapper'>
                          <span className='icon icon-email' />
                          <input
                            id='guardian1.email'
                            name='guardian1.email'
                            ref={register()}
                            className='icon'
                            type='text'
                            defaultValue={user.guardian1?.email || ''}
                          />
                        </div>
                      </label>
                    </div>
                  </Col>
                  {user.guardian2 && (
                    <>
                      <Col span={24} style={{ padding: '10px 20px 20px 20px' }}>
                        <div className='bold text-orange'>
                          Second Guardian&apos;s Information:
                        </div>
                      </Col>
                      <Col
                        xxl={12}
                        lg={12}
                        md={24}
                        style={{ padding: '0 20px' }}
                      >
                        <div className='form-group'>
                          <label htmlFor='guardian2.address.line1'>
                            Address Line 1
                            <div className='form-group-wrapper'>
                              <span className='icon icon-house' />
                              <input
                                id='guardian2.address.line1'
                                name='guardian2.address.line1'
                                ref={register({ required: true })}
                                className='icon'
                                type='text'
                                defaultValue={user.guardian2.address?.line1}
                              />
                            </div>
                          </label>
                        </div>
                        <div className='form-group'>
                          <label htmlFor='guardian2.address.line2'>
                            Address Line 2
                            <div className='form-group-wrapper'>
                              <span className='icon icon-house' />
                              <input
                                id='guardian2.address.line2'
                                name='guardian2.address.line2'
                                className='icon'
                                type='text'
                                defaultValue={user.guardian2.address?.line2}
                              />
                            </div>
                          </label>
                        </div>
                        <div className='form-group'>
                          <div className='label'>Country</div>
                          <div className='form-group-wrapper'>
                            <span className='icon icon-flag' />
                            <Controller
                              name='guardian2.address.country'
                              control={control}
                              defaultValue={user.guardian2.address?.country}
                              as={
                                <Select className='icon' placeholder='Country'>
                                  {countries.map((item) => {
                                    return (
                                      <Option key={item.code} value={item.code}>
                                        {item.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              }
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <div className='label'>State</div>
                          <div className='form-group-wrapper'>
                            <span className='icon icon-flag' />
                            <Controller
                              name='guardian2.address.state'
                              control={control}
                              defaultValue={user.guardian2.address?.state}
                              as={
                                <Select className='icon' placeholder='State'>
                                  {states.map((item) => {
                                    return (
                                      <Option key={item.code} value={item.code}>
                                        {item.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              }
                            />
                          </div>
                        </div>
                      </Col>
                      <Col
                        xxl={12}
                        lg={12}
                        md={24}
                        style={{ padding: '0 20px' }}
                      >
                        <div className='form-group'>
                          <label htmlFor='guardian2.address.city'>
                            City
                            <div className='form-group-wrapper'>
                              <span className='icon icon-city' />
                              <input
                                id='guardian2.address.city'
                                name='guardian2.address.city'
                                ref={register({ required: true })}
                                className='icon'
                                type='text'
                                defaultValue={user.guardian2.address?.city}
                              />
                            </div>
                          </label>
                        </div>
                        <div className='form-group'>
                          <label htmlFor='guardian2.address.zipCode'>
                            Zipcode
                            <div className='form-group-wrapper'>
                              <span className='icon icon-zip' />
                              <input
                                id='guardian2.address.zipCode'
                                name='guardian2.address.zipCode'
                                ref={register({ required: true })}
                                className='icon'
                                type='text'
                                defaultValue={user.guardian2.address?.zipCode}
                              />
                            </div>
                          </label>
                        </div>
                        <div className='form-group'>
                          <label htmlFor='guardian2.phoneNumber'>
                            Phone Number
                            <div className='form-group-wrapper'>
                              <span className='icon icon-phone' />
                              <input
                                id='guardian2.phoneNumber'
                                name='guardian2.phoneNumber'
                                ref={register({ required: true })}
                                className='icon'
                                type='text'
                                defaultValue={user.guardian2.phoneNumber}
                              />
                            </div>
                          </label>
                        </div>
                        <div className='form-group'>
                          <label htmlFor='guardian2.email'>
                            Email
                            <div className='form-group-wrapper'>
                              <span className='icon icon-email' />
                              <input
                                id='guardian2.email'
                                name='guardian2.email'
                                ref={register({ required: true })}
                                className='icon'
                                type='text'
                                defaultValue={user.guardian2.email}
                              />
                            </div>
                          </label>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              )}
              <Button
                onClick={() => {
                  handleSubmit(onSubmit);
                }}
              >
                Save Edits
              </Button>
            </form>
          </BoxWrapper>
          <AddClubModal
            isOpen={isAddClubModalOpen}
            user={user}
            onSuccessfulSubmit={() => {
              setIsAddClubModalOpen(false);
              getUserData();
            }}
            onClose={() => {
              setIsAddClubModalOpen(false);
            }}
          />
        </PageContainer>
      )}
    </>
  );
};

const Approved = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  &::before {
    margin-right: 5px;
    padding-top: 6px;
  }
`;

const BoxWrapper = styled.div`
  padding: 20px;
  border-radius: 20px;
  background-color: ${color.greyLight};
  width: 100%;
`;

const DocumentsWrapper = styled.div`
  padding: 20px;
  border-radius: 20px;
  background-color: ${color.greyDark};
  display: flex;
  justify-content: space-around;
  text-align: center;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  .box-picture {
    padding: 0 20px;

    .title {
      margin: 10px 0;
    }

    .image {
      width: 180px;
      height: 180px;
      border-radius: 10px;
      background-color: #202020;
      position: relative;
      overflow: hidden;
      align-items: center;
      display: flex;
      margin: 0 auto;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      .download-buttom {
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: #000;
        padding-top: 45%;
        cursor: pointer;
        font-weight: bold;
        &:hover {
          opacity: 0.7;
        }
      }

      .edit-image {
        width: 40px;
        height: 40px;
        padding: 12px;
        border-radius: 20px;
        background-color: #fff;
        position: absolute;
        bottom: 10px;
        right: 10px;
        box-shadow: 0 0 3px 3px #20202038;
      }
    }

    .status {
      color: ${color.silverLight};
      margin: 10px 0;
      font-weight: 600;
    }
  }
`;

const AccountNumberBox = styled.div`
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  border: solid 1px #e5502e;
  padding: 10px 15px;
  border-radius: 5px;
  opacity: 0.5;
  display: inline-block;

  & > span.icon.clear::before {
    top: -4px;
  }
`;

const ClubBox = styled.div`
  display: flex;
  margin-bottom: 10px;

  .club-item {
    display: flex;
    align-items: center;
    padding: 5px;

    .club-logo {
      width: 50px;
      height: 50px;
      margin: 0 10px;
      border-radius: 20px;
      background-size: cover;
    }
    .club-name {
    }
  }
`;

export default UserEditPage;
