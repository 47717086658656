import styled, { keyframes } from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

const rotateIcon = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  background-color: ${COLORS.grey900};
  padding: ${toRem(8)};
  border-radius: ${toRem(43)};
  border: 1px solid ${COLORS.grey800};
  display: flex;
  max-width: 268px;

  cursor: pointer;

  .avatar-wrapper {
    border: 1px solid ${COLORS.grey800};
  }

  .avatar-text {
    text-transform: capitalize;
    margin-right: 1rem;
  }
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: ${toRem(5)};
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.grey950};
    animation: ${rotateIcon} 0.5s ease-out;
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-left: ${toRem(8)};
    font-weight: 600;
    max-width: 142px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
