import React, { FunctionComponent } from 'react';
import { CloseSmall } from '@icon-park/react';
import { Divider } from 'antd';

import { BodyM, BodyMBold, BodyS } from 'styles/v3/variables';

import {
  StyledModal,
  Container,
  HeaderContainer,
  CloseWrapper,
  ContentContainer,
  TextInformation,
  InformationContainer,
} from './styles';

interface InfoModalProps {
  isOpen: boolean;
  handleIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  emailContact?: string;
  phoneContact?: string;
}

export const InfoModal: FunctionComponent<InfoModalProps> = ({
  isOpen,
  handleIsOpen,
  emailContact = 'contact@mail.com',
  phoneContact = '+99 99 99999 99',
}) => {
  return (
    <StyledModal
      centered
      footer={false}
      closable={false}
      visible={isOpen}
      onOk={() => handleIsOpen(false)}
      onCancel={() => handleIsOpen(false)}
    >
      <Container>
        <HeaderContainer>
          <BodyM $color='grey50'>Event Manager Contact</BodyM>
          <CloseWrapper onClick={() => handleIsOpen(false)}>
            <CloseSmall />
          </CloseWrapper>
        </HeaderContainer>

        <Divider style={{ margin: 0 }} />

        <ContentContainer>
          <TextInformation>
            <BodyM $color='grey200'>
              When contacting the event manager, make sure you identify yourself
              with information like: <BodyMBold $color='grey50'>name</BodyMBold>
              , <BodyMBold $color='grey50'>club</BodyMBold>,{' '}
              <BodyMBold $color='grey50'>age division</BodyMBold> and{' '}
              <BodyMBold $color='grey50'>
                any other that might be important
              </BodyMBold>
              .
            </BodyM>
          </TextInformation>

          <InformationContainer>
            <BodyMBold $color='grey50'>Email</BodyMBold>
            <BodyS $color='grey400'>{emailContact}</BodyS>
          </InformationContainer>
          <InformationContainer>
            <BodyMBold $color='grey50'>Phone</BodyMBold>
            <BodyS $color='grey400'>{phoneContact}</BodyS>
          </InformationContainer>
        </ContentContainer>
      </Container>
    </StyledModal>
  );
};
