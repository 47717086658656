import Axios, { AxiosRequestConfig } from 'axios';
import { EventCreation, EventView } from '../../admin/models/event/Event';
import { RosterListing } from '../../models/Roster/RosterModel';
import { ConfigApi } from '../config.api';
import APIServices from '../util/ApiServices';
import PageResponse from '../util/PageResponse';
import ServiceResponse from '../util/ServiceResponse';

class EventService {
  public static async list(
    urlParams?: URLSearchParams
  ): Promise<ServiceResponse<PageResponse<EventView>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/events',
      method: 'GET',
      params: urlParams,
    };
    return APIServices.request<PageResponse<EventView>>(axiosConfig);
  }

  public static async search(params: {
    urlParams?: URLSearchParams;
    body?: any;
  }): Promise<ServiceResponse<PageResponse<EventView>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/events/search',
      method: 'POST',
      params: params.urlParams,
      data: params.body,
    };
    return APIServices.request<PageResponse<EventView>>(axiosConfig);
  }

  public static async create(
    body: EventCreation
  ): Promise<ServiceResponse<EventView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/events',
      method: 'POST',
      data: body,
    };
    return APIServices.request<EventView>(axiosConfig);
  }

  public static async update(
    id: string,
    body: EventCreation
  ): Promise<ServiceResponse<EventView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/events/${id}`,
      method: 'PUT',
      data: body,
    };
    return APIServices.request<EventView>(axiosConfig);
  }

  public static async delete(id: string): Promise<ServiceResponse<EventView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/events/${id}`,
      method: 'DELETE',
    };
    return APIServices.request<EventView>(axiosConfig);
  }

  public static async publish(
    id: string,
    publish: boolean
  ): Promise<ServiceResponse<void>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/events/${id}?publish=${publish}`,
      method: 'POST',
    };
    return APIServices.request<void>(axiosConfig);
  }

  public static async find(id: string): Promise<ServiceResponse<EventView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/events/${id}`,
      method: 'GET',
    };
    return APIServices.request<EventView>(axiosConfig);
  }

  public static async detail(id: string): Promise<ServiceResponse<EventView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/events/${id}/detail`,
      method: 'GET',
    };
    return APIServices.request<EventView>(axiosConfig);
  }

  public static async getActive(): Promise<
    ServiceResponse<PageResponse<EventView>>
  > {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/events/active`,
      method: 'GET',
      params: new URLSearchParams({
        size: '100',
      }),
    };
    return APIServices.request<PageResponse<EventView>>(axiosConfig);
  }

  public static async getRosters(
    rosterId: string,
    urlParams?: URLSearchParams
  ): Promise<ServiceResponse<RosterListing[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/events/${rosterId}/rosters`,
      method: 'GET',
      params: urlParams,
    };
    return APIServices.request<RosterListing[]>(axiosConfig);
  }

  public static async exportRosters(eventId: string): Promise<any> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/events/${eventId}/rosters/export`,
      method: 'GET',
    };
    return Axios.request(axiosConfig);
  }

  public static async approveRoster(
    rosterId: string
  ): Promise<ServiceResponse<RosterListing[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/events/roster/${rosterId}/approval`,
      method: 'GET',
    };
    return APIServices.request<RosterListing[]>(axiosConfig);
  }

  public static async approveRosterPayment(
    rosterId: string
  ): Promise<ServiceResponse<RosterListing[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/events/roster/${rosterId}/payment-confirmation`,
      method: 'GET',
    };
    return APIServices.request<RosterListing[]>(axiosConfig);
  }

  public static async declineRoster(
    rosterId: string,
    reason: string
  ): Promise<ServiceResponse<RosterListing[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/events/roster/${rosterId}/decline`,
      method: 'POST',
      data: reason,
    };
    return APIServices.request<RosterListing[]>(axiosConfig);
  }
}

export default EventService;
