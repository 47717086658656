import * as React from 'react';
import styled from 'styled-components';
import { camelCase } from 'lodash';

import BasicButton, { ButtonProps } from './BasicButton';

export interface OutlinedButtonProps extends ButtonProps {
  color?: 'primary' | 'dark-primary' | 'dark-white' | 'white-dark';
}

const OutlinedButton: React.FC<OutlinedButtonProps> = ({
  color,
  children,
  ...props
}) => (
  <OutlinedButtonStyle $theme={color} {...props}>
    {children}
  </OutlinedButtonStyle>
);

const OutlinedButtonStyle = styled(BasicButton)<{
  $theme?: 'primary' | 'dark-primary' | 'dark-white' | 'white-dark';
}>`
  background-color: transparent;
  color: ${(props) => {
    if (props.$theme) {
      return props.theme.buttons.outlined[camelCase(props.$theme)].color;
    }
    return props.theme.buttons.default.bgColor;
  }};
  border-color: ${(props) => {
    if (props.$theme) {
      return props.theme.buttons.outlined[camelCase(props.$theme)].color;
    }
    return props.theme.buttons.default.bgColor;
  }};

  &:hover {
    color: ${(props) => {
      if (props.$theme) {
        return props.theme.buttons.outlined[camelCase(props.$theme)].bgColor;
      }
      return props.theme.buttons.default.color;
    }};
    background-color: ${(props) => {
      if (props.$theme) {
        return props.theme.buttons.outlined[camelCase(props.$theme)].color;
      }
      return props.theme.buttons.default.bgColor;
    }};
    border-color: ${(props) => {
      if (props.$theme) {
        return props.theme.buttons.outlined[camelCase(props.$theme)].bgColor;
      }
      return props.theme.buttons.default.color;
    }};
  }
`;

export default OutlinedButton;
