import styled from 'styled-components';
import { COLORS, ColorsInterface } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${toRem(24)};
  margin-top: ${toRem(-60)};
`;

export const UserDocumentsInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${toRem(16)};
  margin-bottom: ${toRem(16)};
  gap: ${toRem(16)};
`;

export const UserDocuments = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BreadInfo = styled.div < { color: keyof ColorsInterface } > `
  padding: ${toRem(4)} ${toRem(16)};
  border: ${toRem(1)} solid ${({ color}) => COLORS[color]};
  border-radius: ${toRem(33)};
`;
