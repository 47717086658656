import { User } from '../../models/User/UserModel';
import { UserType } from '../user/types';

export interface AccountBrief {
  id: string;
  type: UserType;
  firstName: string;
  lastName: string;
  photo: string;
}

export interface Account {
  id: string | undefined;
  email: string;
  activeUser?: string;
  accounts?: User[];
}

export enum AccountActionTypes {
  FETCH_REQUEST = '@@account/FETCH_REQUEST',
  FETCH_SUCCESS = '@@account/FETCH_SUCCESS',
  FETCH_ERROR = '@@account/FETCH_ERROR',

  SET_USER = '@@account/SET_USER',

  FETCH_ACCOUNTS = '@@account/FETCH_ACCOUNTS',
  SET_ACCOUNTS = '@@account/SET_ACCOUNTS',

  SET_ACTIVE_PROFILE = '@@account/SET_ACTIVE_PROFILE',
}

export interface AccountState {
  readonly loading: boolean;
  readonly data: Account;
}
