import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${toRem(8)};
  align-items: center;
`;

export const Image =
  styled.div <
  { $src: string } >
  `
  width: ${toRem(24)};
  height: ${toRem(24)};
  border-radius: 50%;
  background: url(${(props) => props.$src}), lightgray 50%;
  background-size: cover;
  background-position: center;
`;
