import { Reducer } from 'redux';
import { NewUserActionTypes, NewUserState } from './types';

export const initialState: NewUserState = {
  loading: false,
  data: {
    newUser: {
      type: undefined,
      gender: 'MALE',
      profileVisibility: 'PUBLIC',
      membershipPrice: 0,
      signedWaivers: true,
    },
  },
};

const reducer: Reducer<NewUserState> = (state = initialState, action) => {
  switch (action.type) {
    case NewUserActionTypes.UPDATE: {
      return { ...state, data: { newUser: action.payload } };
    }
    case NewUserActionTypes.UPDATE_TYPE: {
      return {
        ...state,
        data: {
          ...state.data,
          newUser: {
            ...state.data.newUser,
            type: action.payload,
          },
        },
      };
    }
    case NewUserActionTypes.UPDATE_BASIC_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          newUser: {
            ...state.data.newUser,
            firstName: action.payload.firstName,
            middleName: action.payload.middleName,
            lastName: action.payload.lastName,
            suffix: action.payload.suffix,
            birthDate: action.payload.birthDate,
            gender: action.payload.gender,
            profileVisibility: 'PUBLIC',
            signedWaivers: true,
          },
        },
      };
    }
    case NewUserActionTypes.UPDATE_BASIC_DATA2: {
      return {
        ...state,
        data: {
          ...state.data,
          newUser: {
            ...state.data.newUser,
            address: action.payload.address,
            phoneNumber: action.payload.phoneNumber,
            email: action.payload.email,
            guardian1: action.payload.guardian1,
            guardian2: action.payload.guardian2,
          },
        },
      };
    }
    case NewUserActionTypes.UPDATE_MEMBERSHIP: {
      return {
        ...state,
        data: {
          ...state.data,
          newUser: {
            ...state.data.newUser,
            membershipId: action.payload.id,
            membershipPrice: action.payload.price,
          },
        },
      };
    }
    case NewUserActionTypes.UPDATE_PHOTO: {
      return {
        ...state,
        data: {
          ...state.data,
          newUser: {
            ...state.data.newUser,
            photo: action.payload,
          },
        },
      };
    }
    case NewUserActionTypes.UPDATE_PROOF_AGE: {
      return {
        ...state,
        data: {
          ...state.data,
          newUser: {
            ...state.data.newUser,
            ageProof: action.payload,
          },
        },
      };
    }
    case NewUserActionTypes.UPDATE_EXTRA_INFO: {
      return {
        ...state,
        data: {
          ...state.data,
          newUser: {
            ...state.data.newUser,
            extraInfo: {
              ...state.data.newUser?.extraInfo,
              ...action.payload,
            },
          },
        },
      };
    }
    case NewUserActionTypes.RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as newUserReducer };
