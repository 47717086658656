import { Col, Row, Select } from 'antd';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams, withRouter } from 'react-router-dom';
import { newUserUpdateExtraInfo } from '../../redux/newUser/actions';
import { ExtraInfo } from '../../redux/newUser/types';
import { ApplicationState } from '../../redux/store';
import {
  ErrorValidationBox,
  NextButtonsWrapper,
  TopHeader,
} from '../Wizard/WizardComponents.style';

const { Option } = Select;

const MembershipUpgradeAdditionalInfo: React.FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const routeParams: { userId?: string } = useParams();

  const { register, handleSubmit, errors, control } = useForm<ExtraInfo>();

  const { newUser } = useSelector(
    (state: ApplicationState) => state.newUser
  ).data;

  function onSubmit(data: ExtraInfo) {
    dispatch(newUserUpdateExtraInfo(data));
    history.push(`/membership-upgrade-certificate/${routeParams.userId || ''}`);
  }
  return (
    <div>
      <TopHeader>
        <h5 style={{ textTransform: 'uppercase' }}>
          COMPLETE YOUR HOUSEHOLD USER CREATION TO JOIN THE UNITED FUTSAL
          COMMUNITY!
        </h5>
        <h1>PROVIDE SOME ADDITIONAL INFO</h1>
      </TopHeader>
      <Row justify='center'>
        <Col xxl={18} lg={18} md={24} className='column-wizard'>
          <div>
            <div style={{ maxWidth: '500px', margin: '0 auto' }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='form-group'>
                  <div className='label'>
                    Have you ever been convicted of a sex crime?
                  </div>
                  <div>
                    <Controller
                      as={
                        <Select>
                          <Option value='true'>YES</Option>
                          <Option value='false'>NO</Option>
                        </Select>
                      }
                      name='sexCrime'
                      rules={{ required: true }}
                      control={control}
                      defaultValue={newUser?.extraInfo?.sexCrime}
                    />
                    <ErrorValidationBox>
                      {errors.sexCrime && 'This information is required'}
                    </ErrorValidationBox>
                  </div>
                </div>

                <div className='form-group'>
                  <div className='label'>
                    Have you ever been convicted of a felony?
                  </div>
                  <div>
                    <Controller
                      as={
                        <Select>
                          <Option value='true'>YES</Option>
                          <Option value='false'>NO</Option>
                        </Select>
                      }
                      name='felony'
                      rules={{ required: true }}
                      control={control}
                      defaultValue={newUser?.extraInfo?.felony}
                    />
                    <ErrorValidationBox>
                      {errors.felony && 'This information is required'}
                    </ErrorValidationBox>
                  </div>
                </div>

                <div className='form-group'>
                  <div className='label'>Social Security Number</div>
                  <div>
                    <input
                      name='socialSecurityNumber'
                      ref={register({ required: true })}
                      type='number'
                      defaultValue={newUser?.extraInfo?.socialSecurityNumber}
                    />
                    <ErrorValidationBox>
                      {errors.sexCrime && 'This information is required'}
                    </ErrorValidationBox>
                  </div>
                </div>
              </form>
            </div>
            <NextButtonsWrapper>
              <Link to={`/membership-upgrade/${routeParams.userId || ''}`}>
                <div style={{ margin: '0 10px' }}> ◄ PREVIOUS STEP</div>
              </Link>
              <div
                className='link'
                onClick={handleSubmit(onSubmit)}
                onKeyDown={handleSubmit(onSubmit)}
                role='button'
                aria-label='Next Step'
                tabIndex={0}
                style={{ margin: '0 10px' }}
              >
                NEXT STEP ►
              </div>
            </NextButtonsWrapper>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(MembershipUpgradeAdditionalInfo);
