import React, { FC, useMemo, ReactNode } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Delete } from '@icon-park/react';

import UserType from 'models/User/UserTypeModel';

import IconButton from 'components/v3/Buttons/IconButton';
import { confirm } from 'components/v3/ConfirmModal/ConfirmModal';

import ConvertUtil from 'util/ConvertUtil';

import { BodyLBold, BodyM } from 'styles/v3/variables';

import * as S from './styles';

interface ISimpleCardProps {
  id?: string;
  name?: string;
  imgSrc?: string;
  address?: string;
  userType?: UserType;
  extraAddressInfo?: ReactNode;
  onRemoveCard: (id: string) => void;
}

export const SimpleCard: FC<ISimpleCardProps> = ({
  id,
  name,
  imgSrc,
  address,
  userType,
  extraAddressInfo = null,
  onRemoveCard,
}) => {
  const handleRemove = () => {
    confirm({
      message: 'Are you sure that you want to delete this member?',
      onOk: () => {
        if (id) onRemoveCard(id);
      },
    });
  };

  const readableUserType = useMemo(() => {
    if (userType) {
      return userType
        .split('_')
        .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
        .join(' ');
    }

    return '';
  }, [userType]);

  return (
    <S.Container>
      <S.Flex>
        <Avatar
          size={62}
          icon={<UserOutlined />}
          src={ConvertUtil.getMinioUrl(imgSrc)}
        />
      </S.Flex>
      <S.InfoWrapper>
        <S.BasicInfoWrapper>
          <BodyLBold>{name}</BodyLBold>
          {extraAddressInfo}
          <BodyM $color='grey400'>{address}</BodyM>
          {readableUserType && (
            <S.UserTypeText>{readableUserType}</S.UserTypeText>
          )}
        </S.BasicInfoWrapper>
        <IconButton onClick={handleRemove} icon={<Delete size={22} />} />
      </S.InfoWrapper>
    </S.Container>
  );
};
