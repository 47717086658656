import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const TiebreakersWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TiebreakersContainer = styled.div`
  width: 100%;
  padding: 0 ${toRem(30)} 1.5rem;
  border-bottom: 1px solid ${COLORS.grey900};

  .card-title {
    margin-bottom: 1rem;
    & + p,
    &:last-child {
      margin-bottom: 0;
    }
  }
  & + div {
    padding-top: 1.5rem;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: ${toRem(24)} ${toRem(30)};
`;

export const SpinnerWrapper = styled.div`
  width: 100%;

  margin-top: ${toRem(64)};

  display: flex;
  align-items: center;
  justify-content: center;
`;
