import { useMutation } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';

import RosterService from 'services/v3/Rosters/RosterService';

export const useUpdateEditedRosterStatus = () => {
  return useMutation({
    mutationFn: async (rosterId: string) =>
      RosterService.updateEditedRosterStatus(rosterId),
    onSuccess: async () => {
      notification.success({ message: 'Roster status has been edited' });
    },
    onError: (error: any) => {
      notification.error({
        message: 'Failed to change the roster status!',
        description: error.message,
      });
    },
  });
};
