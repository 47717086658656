import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PlayOne } from '@icon-park/react';

import UserType from 'models/User/UserTypeModel';

import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';
import { RedirectionName } from 'pages/V3/EventManager/enums/redirection-name.enum';

import ConvertUtil from 'util/ConvertUtil';

import { BodyS, TitleH4 } from 'styles/v3/variables';

import { RoundedCardProps } from '../RoundedCard/RoundedCard';
import { PermissionIcon } from './components/PermissionIcon/PermissionIcon';

import * as S from './styles';

interface IMyEventCardProps extends RoundedCardProps {
  title: string;
  description: string;
  eventStatus: 'draft' | 'published' | 'roaster locked' | 'done';
  id: string;
  link: string;
  logo?: string;
  startDate: Date | string;
  finalDate: Date | string;
  actionText?: string;
  currentUserType: Maybe<UserType>;
}

const MyEventCard: FC<IMyEventCardProps> = ({
  id,
  title,
  description,
  logo,
  link,
  startDate,
  finalDate,
  currentUserType,
  actionText = 'Manage',
}) => {
  const history = useHistory();

  const startAt = new Date(
    startDate.toString().replace('[UTC]', '')
  ).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });

  const endAt = new Date(
    finalDate.toString().replace('[UTC]', '')
  ).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });

  const startEvent = new Date(startDate).getTime();
  const today = new Date().getTime();
  const finalEvent = new Date(finalDate).getTime();

  const isHappening = today >= startEvent && today <= finalEvent;

  const handleRedirectToGamesList = useCallback(() => {
    history.push(
      `/v3/event/${id}/dashboard?tab=games&prev=${RedirectionName.MyEvents}&type=referee`
    );
  }, [id]);

  const handleActionClick = useCallback(() => {
    history.push(link);
  }, [link]);

  return (
    <S.EventCardWrapper isHappening={isHappening}>
      <S.EventContentWrapper>
        <S.EventIcon>
          <img src={ConvertUtil.getMinioUrl(logo)} alt='Event Logo' />
        </S.EventIcon>
        <S.EventContent>
          <S.EventDates>
            {isHappening && <S.IsTodayEvent>Today</S.IsTodayEvent>}
            <S.EventDateOccurrence>
              {startAt} - {endAt}
            </S.EventDateOccurrence>
          </S.EventDates>
          <TitleH4>{title}</TitleH4>
          <BodyS $color='grey300'>{description}</BodyS>
          {currentUserType && (
            <S.PermissionWrapper>
              <PermissionIcon userType={currentUserType} />
            </S.PermissionWrapper>
          )}
        </S.EventContent>
        <S.EventBtnActions>
          {currentUserType === UserType.REFEREE && (
            <S.GamesButton
              color='primary'
              customIcon={<PlayOne />}
              isBold={false}
              isUpper
              onClick={handleRedirectToGamesList}
            >
              Games
            </S.GamesButton>
          )}
          <OutlinedButtonWithIcon
            color='dark-white'
            isBold={false}
            isUpper
            onClick={handleActionClick}
          >
            {actionText}
          </OutlinedButtonWithIcon>
        </S.EventBtnActions>
      </S.EventContentWrapper>
    </S.EventCardWrapper>
  );
};

export default MyEventCard;
