import React, { FC, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';

import { Header } from './Header/Header';
import { Tabs, ITabsProps } from './Tabs/Tabs';
import Loading from '../Loading/Loading';

import * as S from './styles';

interface EventManagerDashboardProps extends ITabsProps {
  title?: string;
}

export const EventManagerDashboard: FC<EventManagerDashboardProps> = ({
  tabs,
  title,
  ...rest
}) => {
  const { eventId } = useParams<{ eventId: string }>();

  const location = useLocation();

  const { data: currentEvent } = useGetEventById(eventId);

  const params = new URLSearchParams(location.search);

  const isReferee = useMemo(() => params.get('type') === 'referee', [params]);

  if (!currentEvent) {
    return <Loading />;
  }

  return (
    <S.Container className={isReferee ? 'smaller-space' : ''}>
      <Header
        id={currentEvent.id}
        title={title || currentEvent.name}
        imgSrc={currentEvent.data?.eventInfo?.header}
        hasAccessedUserType={isReferee}
      />
      <Tabs hasAccessedUserType={isReferee} tabs={tabs} {...rest} />
    </S.Container>
  );
};
