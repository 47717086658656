import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Filter } from '@icon-park/react';
import { RadioChangeEvent } from 'antd/lib/radio';

import { User } from 'models/User/UserModel';
import { Address } from 'admin/models/event/Address';
import {
  IAdvancedFilters,
  IFilteredUserParameters,
} from 'services/v3/User/types';

import Loading from 'components/v3/Loading/Loading';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import InputText from 'components/v3/Forms/InputText/InputText';
import { RadioGroup } from 'components/v3/Forms/RadioGroup/RadioGroup';

import { formatUserName } from 'util/formatUserName';
import DateUtil from 'util/DateUtil';

import { BodyMBold, TitleH2, TitleH4 } from 'styles/v3/variables';

import { USER_TYPES_OPTIONS } from './configs';
import { FilterDrawer } from './components';
import { IAdvancedFilterOptions } from './components/FilterDrawer/types';
import { Card } from '../../../components';

import * as S from './styles';

interface IFindProps {
  users: Maybe<User[]>;
  selectedUserIds: string[];
  isUsersLoading: boolean;
  advancedFilters: Maybe<IAdvancedFilters>;
  onSelectCard: (id: string) => void;
  onFetchUsersByFilters: (filters: IFilteredUserParameters) => Promise<void>;
}

export const Find: FC<IFindProps> = ({
  users,
  isUsersLoading,
  advancedFilters,
  selectedUserIds,
  onSelectCard,
  onFetchUsersByFilters,
}) => {
  const history = useHistory();

  const searchTimeoutRef = useRef<number>();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [
    selectedAdvancedFilters,
    setSelectedAdvancedFilters,
  ] = useState<IFilteredUserParameters>({});

  const modifyFiltersToOptions = useCallback(
    (items?: string[]) =>
      items?.map((item) => ({
        label: item,
        value: item,
      })) || [],
    []
  );

  const advancedFilterOptions: IAdvancedFilterOptions = useMemo(
    () => ({
      birthYears: modifyFiltersToOptions(advancedFilters?.birthYears),
      countries: modifyFiltersToOptions(advancedFilters?.countries),
      cities: modifyFiltersToOptions(advancedFilters?.cities),
      states: modifyFiltersToOptions(advancedFilters?.states),
    }),
    [advancedFilters, modifyFiltersToOptions]
  );

  const handleCloseDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  const handleConfirmAdvancedFilter = useCallback(
    (filters: IFilteredUserParameters) => {
      onFetchUsersByFilters({
        ...selectedAdvancedFilters,
        ...filters
      });

      setSelectedAdvancedFilters({
        ...selectedAdvancedFilters,
        ...filters
      });
    },
    [selectedAdvancedFilters]
  );

  const handleChangeRole = useCallback((event: RadioChangeEvent) => {
    onFetchUsersByFilters({
      ...selectedAdvancedFilters,
      userType: event.target.value,
    });

    setSelectedAdvancedFilters((prevState) => ({
      ...prevState,
      userType: event.target.value,
    }))
  }, []);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      clearTimeout(searchTimeoutRef.current);

      const targetValue = typeof event.target?.value === 'string' ? event.target?.value : '';

      setSelectedAdvancedFilters((prevState) => ({
        ...prevState,
        name: targetValue,
      }));

      searchTimeoutRef.current = window.setTimeout(() => {
        onFetchUsersByFilters({
          ...selectedAdvancedFilters,
          name: targetValue,
        });
      }, 100);
    },
    [selectedAdvancedFilters, onFetchUsersByFilters]
  );

  const handleGetAddress = useCallback((address?: Address) => {
    if (address) {
      return `${address.city} - ${address.state}`;
    }

    return '';
  }, []);

  return (
    <S.FindWrapper>
      <TextButtonWithIcon
        reverse
        icon='back'
        color='light'
        align='flex-end'
        onClick={handleGoBack}
      >
        Go back
      </TextButtonWithIcon>
      <TitleH2 $color='brandPrimary'>Find members</TitleH2>
      <InputText
        id='search'
        icon='Search'
        value={selectedAdvancedFilters.name}
        placeholder='Search by name'
        onChange={handleSearch}
      />
      <RadioGroup
        value={selectedAdvancedFilters.userType}
        defaultValue=""
        options={USER_TYPES_OPTIONS}
        onChange={handleChangeRole}
      />
      <S.FilterWrapper>
        <S.FilterButton onClick={() => setIsDrawerOpen((prev) => !prev)}>
          <BodyMBold $isUpper $color='grey500'>
            Filters
          </BodyMBold>
          <Filter />
        </S.FilterButton>
        <FilterDrawer
          isOpen={isDrawerOpen}
          advancedFilterOptions={advancedFilterOptions}
          advancedFilter={selectedAdvancedFilters}
          onClose={handleCloseDrawer}
          handleChange={handleConfirmAdvancedFilter}
        />
      </S.FilterWrapper>
      {users?.map((user) => (
        <Card
          id={user.id}
          key={user.id}
          logoSrc={user.photo}
          onSelect={onSelectCard}
          selected={selectedUserIds.includes(user.id)}
          year={DateUtil.getYear(new Date(user.birthDate as string))}
          address={handleGetAddress(user?.address)}
          name={formatUserName({
            firstName: user.firstName,
            lastName: user.lastName,
            middleName: user.middleName,
          })}
        />
      ))}
      {!users?.length && !isUsersLoading && (
        <S.FallbackMessageWrapper>
          <TitleH4 $isUpper $color='grey700'>
            didn&apos;t find the member you were looking for?
          </TitleH4>
          <TitleH4
            $color='grey500'
            onClick={() => history.push('/v3/invites/email')}
            style={{ cursor: 'pointer' }}
          >
            Invite via email
          </TitleH4>
        </S.FallbackMessageWrapper>
      )}
      {isUsersLoading && (
        <Loading />
      )}
    </S.FindWrapper>
  );
};
