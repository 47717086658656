import React, {
  useCallback,
  FC,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CloseSmall } from '@icon-park/react';
import { Tooltip } from 'antd';

import UserType from 'models/User/UserTypeModel';

import { useGetEvent } from 'hooks/v3/event/useGetEvent/useGetEvent';
import { useProfile } from 'hooks/v3/profile/useProfile';

import { BodyXL, TitleH3 } from 'styles/v3/variables';

import { allowedRoles } from './allowedRoles';

import * as S from './styles';
import { AllowedApplyEventRoles } from './types';

interface IApplyModalProps {
  isOpen: boolean;
  handleIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const ApplyModal: FC<IApplyModalProps> = ({ isOpen, handleIsOpen }) => {
  const { eventId } = useParams<{ eventId: string }>();

  const history = useHistory();

  const { data: eventInfo } = useGetEvent(eventId);

  const { currentUser, isAnAdult } = useProfile();

  const signOptions = useMemo(
    (): Record<AllowedApplyEventRoles, string> => ({
      [UserType.CLUB_DIRECTOR]: 'signup-as-club-director-or-team-manager',
      [UserType.TEAM_MANAGER]: 'signup-as-club-director-or-team-manager',
      [UserType.REFEREE]: 'signup-as-referee',
    }),
    []
  );

  const isEnabledRole = useCallback(
    (role: UserType) => {
      if (role === UserType.REFEREE) {
        if (
          eventInfo?.data?.data?.generalRules?.refereeConditions
            ?.allowRefereesToSignUp &&
          isAnAdult
        ) {
          return true;
        }

        return false;
      }

      if (role === UserType.CLUB_DIRECTOR) {
        const isAllowedRolesFromList = currentUser?.types?.find((type) => {
          return (
            type === UserType.CLUB_DIRECTOR || type === UserType.TEAM_MANAGER
          );
        });

        const isAllowedByType =
          currentUser.type === UserType.CLUB_DIRECTOR ||
          currentUser.type === UserType.TEAM_MANAGER;

        return isAllowedRolesFromList || isAllowedByType;
      }

      return false;
    },
    [eventInfo, currentUser, isAnAdult]
  );

  const handleRedirect = useCallback(
    (role: AllowedApplyEventRoles) => () => {
      history.push(`/v3/event/${eventId}/${signOptions[role]}`);
    },
    [eventId, signOptions]
  );

  const getTooltipMessage = useCallback(
    (role: UserType) => {
      if (!isAnAdult && role === UserType.REFEREE) {
        return 'To join the club as a referee, you should be over 18 years old.';
      }
      if (role === UserType.CLUB_DIRECTOR) {
        return 'Please join a club as a club director or team manager before you can apply to a tournament';
      }

      if (role === UserType.REFEREE) {
        return 'Referee applications have not opened yet, or have closed. Please reach out to Event Manager if you have questions';
      }

      return '';
    },
    [isAnAdult]
  );

  return (
    <S.StyledModal
      centered
      footer={false}
      closable={false}
      visible={isOpen}
      onOk={() => handleIsOpen(false)}
      onCancel={() => handleIsOpen(false)}
    >
      <S.Container>
        <S.HeaderWrapper>
          <TitleH3>Apply as</TitleH3>
          <S.CloseButton onClick={() => handleIsOpen(false)}>
            <CloseSmall size='24px' />
          </S.CloseButton>
        </S.HeaderWrapper>
        <S.RolesWrapper>
          {allowedRoles.map((role) => {
            const Icon = role.icon;

            if (isEnabledRole(role.name)) {
              return (
                <S.RoleCard key={role.name} onClick={handleRedirect(role.name)}>
                  <Icon size={80} />
                  <BodyXL>{role.title}</BodyXL>
                </S.RoleCard>
              );
            }

            return (
              <Tooltip
                key={role.name}
                placement='top'
                className='tooltip-roles'
                title={getTooltipMessage(role.name)}
              >
                <S.RoleCard
                  className='disabled'
                  onClick={handleRedirect(role.name)}
                >
                  <Icon size={80} />
                  <BodyXL>{role.title}</BodyXL>
                </S.RoleCard>
              </Tooltip>
            );
          })}
        </S.RolesWrapper>
      </S.Container>
    </S.StyledModal>
  );
};
