import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

function createArrayWithNumbers(length: number) {
  return Array.from({ length }, (_, k) => k + 1);
}

const EventVenueForm: React.FunctionComponent<any> = (props) => {
  const {
    indexComponent,
    listSelected,
    setListSelected,
    removeItem,
    defaultValue,
    venueOptions,
  } = props;
  const { register, control } = useFormContext();

  const [selected, setSelected] = React.useState(defaultValue?.venueId);
  const getSelectedVenueIds = () => {
    return control.getValues()?.venues?.map((item: { venueId: any }) => {
      return item.venueId;
    });
  };

  const handleSelectChange = (event: any) => {
    setSelected(event.currentTarget.value);
    setListSelected(getSelectedVenueIds());
  };

  const removeAndRefresh = async () => {
    removeItem(
      getSelectedVenueIds().filter((item: string) => item !== selected)
    );
  };

  const removeSubVenue = (indexComponent: number, index: number) => {
    const i = index - 1;
    control.setValue(`venues[${indexComponent}].subVenues[${i}].name`, '');
    control.setValue(
      `venues[${indexComponent}].subVenues[${i}].abbreviation`,
      ''
    );
  };

  return (
    <>
      <div className='form-group' style={{ marginTop: '20px' }}>
        {props.selectedVenues}
        <label>Venue </label>
        <select
          name={`venues[${indexComponent}].venueId`}
          ref={register({ required: true })}
          onChange={handleSelectChange}
          value={selected || '-1'}
        >
          <option disabled value='-1'>
            Select a venue
          </option>

          {/* List only Venues not selected by other components */}
          {venueOptions
            .filter(
              (item: any) =>
                !listSelected
                  ?.filter((i: string) => i !== selected)
                  ?.includes(item.id)
            )
            .map((item: any) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
        </select>
      </div>
      {/* Create list of inputs with same size as count of selected id */}

      {selected && (
        <label>
          Check below the available courts for this venue. Fill out ONLY the
          court(s) you will use.
        </label>
      )}

      {createArrayWithNumbers(
        venueOptions.find((item: any) => item.id === selected)?.count || 0
      ).map((index, i) => {
        return (
          <div key={index}>
            <input
              style={{ width: '47%' }}
              type='text'
              name={`venues[${indexComponent}].subVenues[${i}].name`}
              defaultValue={defaultValue?.subVenues[i]?.name || ''}
              placeholder={`Court ${index} name`}
              ref={register()}
            />
            <input
              style={{ width: '47%' }}
              type='text'
              name={`venues[${indexComponent}].subVenues[${i}].abbreviation`}
              defaultValue={defaultValue?.subVenues[i]?.abbreviation || ''}
              placeholder={`Court ${index} abbreviation (eg: C1)`}
              ref={register()}
            />
            <Button
              type='link'
              size='small'
              icon={<DeleteOutlined />}
              onClick={() => removeSubVenue(indexComponent, index)}
            />
          </div>
        );
      })}
      <Button
        type='link'
        size='small'
        icon={<DeleteOutlined />}
        onClick={removeAndRefresh}
      >
        Remove
      </Button>
    </>
  );
};

export default EventVenueForm;
