import { AxiosRequestConfig } from 'axios';
import { ConfigApi } from '../config.api';
import APIServices from '../util/ApiServices';
import ServiceResponse from '../util/ServiceResponse';

export interface UploadFileRequest {
  file: File;
  prefix: string;
  public: boolean;
}

export interface UploadFileResponse {
  id: string;
  md5: string;
  prefix: string;
}

class UploadService {
  public static async upload(
    uploadFile: UploadFileRequest,
    noAccount = false
  ): Promise<ServiceResponse<UploadFileResponse>> {
    const formData = new FormData();
    formData.append('file', uploadFile.file);
    formData.append('name', uploadFile.file.name);
    formData.append('prefix', uploadFile.prefix);

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getUploadConfig(noAccount),
      url: `/uploads/${uploadFile.public ? 'public' : 'private'}`,
      method: 'POST',
      data: formData,
    };
    return APIServices.request<UploadFileResponse>(axiosConfig);
  }
}

export default UploadService;
