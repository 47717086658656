export enum GameAction {
  SHOW_UP = 'SHOW_UP',
  GOAL = 'GOAL',
  PK_GOAL = 'PK_GOAL',
  PK_MISS = 'PK_MISS',
  OWN_GOAL = 'OWN_GOAL',
  FOUL = 'FOUL',
  YELLOW_CARD = 'YELLOW_CARD',
  RED_CARD = 'RED_CARD',
}

export enum GamePeriod {
  FIRST_HALF = 'FIRST_HALF',
  SECOND_HALF = 'SECOND_HALF',
  FIRST_EXTRA_TIME = 'FIRST_EXTRA_TIME',
  SECOND_EXTRA_TIME = 'SECOND_EXTRA_TIME',
  PENALTY_SHOOTOUT = 'PENALTY_SHOOTOUT',
}

export interface GameResult {
  homeTeamScore: number;
  awayTeamScore: number;
  homeTeamPenaltiShootout: number;
  awayTeamPenaltiShootout: number;
}

export interface GameEventModel {
  id?: string;
  action: GameAction | undefined;
  period: GamePeriod | undefined;
  time: string;
  rosterId: string;
  userId: string;
  matchId: string;
  sendFailed?: boolean;
  result?: GameResult;
}

export interface GameEventReturn {
  action: GameAction;
  id: string;
  matchId: string;
  period: GamePeriod;
  result: GameResult;
  rosterId: string;
  time: string;
  user: {
    firstName: string;
    id: string;
    lastName: string;
    number: number;
    photo: string;
    type: string;
  };
}
