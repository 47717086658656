import styled from 'styled-components';

import { toRem } from 'styles/v3/functions';
import { COLORS, BodyS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(16)};

  padding: ${toRem(16)};
  border-radius: ${toRem(24)};
  background-color: ${COLORS.grey900};
`;

export const Flex = styled.div`
  display: flex;
`;

export const BasicInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddressWrapper = styled.div`
  display: flex;
`;

export const InfoWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const UserTypeText = styled(BodyS)`
  width: fit-content;
  margin-top: 6px;
  padding: 6px 10px;
  border-radius: 24px;
  border: 1px solid ${COLORS.grey800};
`;
