import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button, Image, message, Popconfirm, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Key, TablePaginationConfig } from 'antd/lib/table/interface';
import PageContainer from '../../../components/base/PageContainer/PageContainer';
import GroupBox from '../../../components/uielements/GroupBox/GroupBox';
import ClubService from '../../../services/Club/ClubService';
import { DOWNLOAD_STORAGE_ENDPOINT } from '../../../services/config.api';
import { ClubView } from '../../models/club/Club';
import ServiceResponse from '../../../services/util/ServiceResponse';
import PageResponse from '../../../services/util/PageResponse';
import { useRef } from 'react';
import { debounce } from 'lodash';

const ClubListPage: React.FunctionComponent = () => {
  const history = useHistory();

  const initialState = {
    data: [] as ClubView[],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      pageSizeOptions: ['10', '30', '50', '100'],
    },
    loading: false,
  };

  const [state, setState] = useState(initialState);
  const [filterQuery, setFilterQuery] = useState('');
  const [urlParams, setUrlParams] = useState<URLSearchParams>(
    new URLSearchParams({
      size: '10',
      page: '0',
      // sort: '_id',
      // direction: 'Descending',
    })
  );

  useEffect(() => {
    handleTableChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    findAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  const handleTableChange = (
    pagination?: TablePaginationConfig,
    filters?: Record<string, (Key | boolean)[] | null>,
    sorter?: any
  ) => {
    const urlParamsTmp = new URLSearchParams();
    urlParamsTmp.set(
      'page',
      pagination?.current ? (pagination.current - 1).toString() : '0'
    );
    urlParamsTmp.set(
      'size',
      pagination?.pageSize ? pagination.pageSize.toString() : '10'
    );
    setUrlParams(urlParamsTmp);
  };

  const findAll = () => {
    setState({ ...state, loading: true });

    ClubService.quickSearch(filterQuery, urlParams).then(
      (response: ServiceResponse<PageResponse<ClubView>>) => {
        if (response.success) {
          setState({
            ...state,
            loading: false,
            data: response.data.content,
            pagination: {
              ...state.pagination,
              current: response.data.page + 1,
              pageSize: response.data.pageSize,
              total: response.data.total,
            },
          });
        } else {
          setState({ ...state, loading: false });
          message.error({ content: response.message, duration: 5 });
        }
      }
    );
  };

  const columns = [
    {
      title: 'Logo',
      key: 'logo',
      // eslint-disable-next-line react/display-name
      render: (text: string, record: any, index: number) => (
        <Image width={50} src={DOWNLOAD_STORAGE_ENDPOINT + record.logo} />
      ),
      width: 100,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      // eslint-disable-next-line react/display-name
      render: (text: string, record: any, index: number) => (
        <Space size='middle'>
          <Button
            shape='circle'
            icon={<EditOutlined />}
            onClick={() =>
              history.push(`/admin-panel/clubs/create/${record.id}`)
            }
          />

          <Popconfirm
            title='Are you sure delete this club?'
            onConfirm={() => onDelete(record)}
            okText='Yes'
            cancelText='No'
          >
            <Button shape='circle' icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onDelete = async (event: any) => {
    await ClubService.delete(event.id).then((response) => {
      if (!response.success) {
        message.error({ content: response.message, duration: 5 });
        return;
      }

      findAll();
      message.success({ content: 'Club deleted', duration: 3 });
    });
  };

  const handleSearchChange = (
    event: React.FormEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setFilterQuery(event.currentTarget.value);
    //setFilterParams({ ...filterParams, query: event.currentTarget.value });
    doSearch(event.currentTarget.value);
  };

  const doSearch = useRef(
    debounce((filter) => {
      // On debounce search, always go to page 0
      const urlParamsTmp = new URLSearchParams(urlParams);
      urlParamsTmp.set('page', '0');

      // Sync parameter in URL
      history.replace(`/admin-panel/clubs?p=0${filter ? `&q=${filter}` : ''}`);
      setUrlParams(urlParamsTmp);
    }, 200)
  ).current;

  return (
    <PageContainer title='Club'>
      <Button
        type='link'
        icon={<PlusCircleOutlined />}
        onClick={() => history.push('/admin-panel/clubs/create')}
      >
        Create
      </Button>

      <GroupBox title='List'>
        <div className='form-group'>
          <span className='icon icon-search' />
          <input
            name='query'
            // onChange={(val) => {
            //   setFilterQuery(val.target.value);
            // }}
            defaultValue={filterQuery}
            onChange={handleSearchChange}
            type='text'
            className='icon'
            placeholder='Find a Club'
          />
        </div>
        <Table
          rowKey='id'
          dataSource={state.data.filter((club) =>
            club.name
              ?.toLocaleLowerCase()
              .includes(filterQuery.toLocaleLowerCase())
          )}
          loading={state.loading}
          pagination={state.pagination}
          columns={columns}
          onChange={handleTableChange}
        />
      </GroupBox>
    </PageContainer>
  );
};

export default ClubListPage;
