import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { message as MessageSnackbar } from 'antd';

import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import InputText from 'components/v3/Forms/InputText/InputText';
import InputPhone from 'components/v3/Forms/InputPhone/InputPhone';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';

import { useProfile } from 'hooks/v3/profile/useProfile';

import { PatchBaseUser } from 'redux/v3/baseUsers/types';
import UserServices from 'services/v3/User/UserService';
import { Gender } from 'admin/models/Enums';

import { BodyXL } from 'styles/v3/variables';
import CompleteUserTemplate from '../Template/Template';
import * as S from '../Template/styles';

type Fields = {
  firstName: { value: string; error: boolean; required: boolean };
  middleName: { value: string; error: boolean; required: boolean };
  lastName: { value: string; error: boolean; required: boolean };
  suffix: { value: string; error: boolean; required: boolean };
  selectPhone: { value: string; error: boolean; required: boolean };
  selectEmail: { value: string; error: boolean; required: boolean };
  newPhone?: { value: string; error: boolean; required: boolean };
  newEmail?: { value: string; error: boolean; required: boolean };
};

const LegalGuardian = () => {
  const history = useHistory();
  const params: { userId: string } = useParams();
  const formRef = useRef<HTMLFormElement>(null);
  const { currentUser, isBaseUser, updateCurrentAccount } = useProfile();
  const userType = currentUser.type ?? '';
  const guardianData = currentUser?.guardian || currentUser.guardian1;

  const stateFields: Fields = {
    firstName: {
      value: guardianData?.firstName ?? '',
      error: false,
      required: true,
    },
    middleName: {
      value: guardianData?.middleName ?? '',
      error: false,
      required: false,
    },
    lastName: {
      value: guardianData?.lastName ?? '',
      error: false,
      required: true,
    },
    suffix: {
      value: guardianData?.suffix ?? '',
      error: false,
      required: false,
    },
    selectEmail: {
      value: guardianData?.email ?? currentUser?.accountEmail ?? '',
      error: false,
      required: true,
    },
    selectPhone: {
      value: guardianData?.phoneNumber ?? currentUser?.phoneNumber ?? '',
      error: false,
      required: true,
    },
  };

  const emailOptions: OptionsType[] = [
    {
      label: stateFields.selectEmail.value,
      value: stateFields.selectEmail.value,
    },
    {
      label: 'New Email',
      value: 'new-email',
    },
  ];
  const phoneOptions: OptionsType[] = [
    {
      label: stateFields.selectPhone.value,
      value: stateFields.selectPhone.value,
    },
    {
      label: 'New Phone',
      value: 'new-phone',
    },
  ];

  const setLabelToEmail = emailOptions.find(
    (e) => e.value === stateFields.selectEmail.value
  );
  const setLabelToPhone = phoneOptions.find(
    (p) => p.value === stateFields.selectPhone.value
  );

  const [fields, setFields] = useState<Fields>(stateFields);
  const [validating, setValidating] = useState<boolean>(false);
  const [isNewEmail, setIsNewEmail] = useState<boolean>(false);
  const [isNewPhone, setIsNewPhone] = useState<boolean>(false);

  const handleChangeSelect = (field: keyof Fields, e: any) => {
    setFields({
      ...fields,
      [field]: {
        ...fields[field],
        value: e.value,
        error: false,
      },
    });
    setValidating(false);
  };

  const handleInputClear = (field: keyof Fields) => {
    if (field === 'newEmail') {
      setIsNewEmail(false);
    }
    setFields({
      ...fields,
      [field]: { ...fields[field], value: '' },
    });
    setValidating(false);
  };

  const handleChangeInputValue = (
    e: ChangeEvent<HTMLInputElement>,
    field: keyof Fields
  ) => {
    const { name, value } = e.target;

    if (name === 'newEmail' || name === 'newPhone') {
      if (value === '') {
        if (name === 'newEmail') {
          setIsNewEmail(false);
        } else {
          setIsNewPhone(false);
        }
      }
    }
    setFields({
      ...fields,
      [name]: {
        required: stateFields[field]?.required ?? true,
        error: false,
        value,
      },
    });
    setValidating(false);
  };

  const validate = () => {
    setValidating(true);
    for (const [key, values] of Object.entries(fields)) {
      if (values?.value === '' && values?.required) {
        setFields((newFields) => ({
          ...newFields,
          [key]: { value: values.value, error: true },
        }));
      }
    }
  };

  const saveUser = async () => {
    if (formRef.current) {
      const formData: FormData = new FormData(formRef.current);

      const payload: PatchBaseUser = {
        firstName: currentUser.firstName as string,
        lastName: currentUser.lastName as string,
        phoneNumber: currentUser.phoneNumber as string,
        birthDate: currentUser.birthDate as string,
        gender: currentUser.gender as Gender,
        [isBaseUser ? 'guardian' : 'guardian1']: {
          firstName: formData.get('firstName') as string,
          middleName: formData.get('middleName') as string,
          lastName: formData.get('lastName') as string,
          suffix: formData.get('suffix') as string,
          email: (isNewEmail
            ? formData.get('newEmail')
            : formData.get('selectEmail')) as string,
          phoneNumber: (isNewPhone
            ? formData.get('newPhone')
            : formData.get('selectPhone')) as string,
          address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            zipCode: '',
            country: guardianData?.address?.country ?? 'US',
          },
        },
      };

      await UserServices.updateUser(
        `${currentUser.id}`,
        payload,
        isBaseUser,
        userType
      )
        .then(async () => {
          updateCurrentAccount();
          history.push(`/v3/user/${params.userId}/address`);
        })
        .catch((error) => {
          setValidating(false);
          if (error instanceof Error) {
            MessageSnackbar.error({ content: error.message, duration: 5 });
          }
        });
    }
  };

  useEffect(() => {
    if (validating) {
      const getFields = Object.values(fields);
      const hasError = getFields.find((i) => i?.error);

      if (!hasError) {
        saveUser();
      } else {
        setValidating(false);
      }
    }
  }, [validating, fields]);

  useEffect(() => {
    if (fields.selectEmail.value === 'new-email') {
      setIsNewEmail(true);
    } else {
      setIsNewEmail(false);
    }
  }, [fields.selectEmail.value]);

  useEffect(() => {
    if (
      fields.selectPhone.value === 'new-phone' || !guardianData?.phoneNumber) {
      setIsNewPhone(true);
    } else {
      setIsNewPhone(false);
    }
  }, [fields.selectPhone.value, guardianData?.phoneNumber]);

  useEffect(() => {
    if (isNewEmail) {
      setFields({
        ...fields,
        newEmail: { value: '', error: false, required: true },
        selectEmail: { ...fields.selectEmail, required: false },
      });
    } else {
      setFields({
        ...fields,
        newEmail: { value: '', error: false, required: false },
        selectEmail: {
          ...fields.selectEmail,
          value: stateFields.selectEmail.value,
          required: true,
        },
      });
    }
  }, [isNewEmail]);

  useEffect(() => {
    if (isNewPhone) {
      setFields({
        ...fields,
        newPhone: { value: '', error: false, required: true },
        selectPhone: { ...fields.selectPhone, required: false },
      });
    } else {
      setFields({
        ...fields,
        newPhone: { value: '', error: false, required: false },
        selectPhone: {
          ...fields.selectPhone,
          value: stateFields.selectPhone.value,
          required: true,
        },
      });
    }
  }, [isNewPhone]);

  return (
    <CompleteUserTemplate>
      <S.UserForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
        <fieldset>
          <legend>
            <BodyXL $color='brandPrimary' $isUpper>
              Legal Guardian
            </BodyXL>
          </legend>

          <InputText
            id='firstName'
            placeholder='First Name'
            label='First Name'
            required
            defaultValue={fields.firstName.value}
            onClearInput={(id) => handleInputClear(id)}
            error={fields.firstName.error}
            onChange={(e) => handleChangeInputValue(e, 'firstName')}
            className='user-info-input'
          />
          <InputText
            id='middleName'
            placeholder='Middle Name (Optional)'
            label='Middle Name (Optional)'
            defaultValue={fields.middleName.value}
            onClearInput={(id) => handleInputClear(id)}
            error={fields.middleName.error}
            onChange={(e) => handleChangeInputValue(e, 'middleName')}
            className='user-info-input'
          />
          <InputText
            id='lastName'
            placeholder='Last Name'
            label='Last Name'
            required
            defaultValue={fields.lastName.value}
            onClearInput={(id) => handleInputClear(id)}
            error={fields.lastName.error}
            onChange={(e) => handleChangeInputValue(e, 'lastName')}
            className='user-info-input'
          />
          <InputText
            id='suffix'
            placeholder='Suffix (Optional)'
            label='Suffix (Optional)'
            defaultValue={fields.suffix.value}
            onClearInput={(id) => handleInputClear(id)}
            onChange={(e) => handleChangeInputValue(e, 'suffix')}
            className='user-info-input'
          />
        </fieldset>

        <S.FormFieldset>
          {isNewEmail ? (
            <InputText
              id='newEmail'
              placeholder='New Email'
              label='New Email'
              required
              onClearInput={(id) => handleInputClear(id)}
              error={fields.newEmail?.error}
              onChange={(e) => handleChangeInputValue(e, 'newEmail')}
              className='user-info-input'
            />
          ) : (
            <Select
              name='selectEmail'
              placeholder='Select Email'
              required
              options={emailOptions}
              defaultValue={setLabelToEmail}
              onChange={(e) => handleChangeSelect('selectEmail', e)}
              error={fields.selectEmail.error}
              className='user-info-input'
            />
          )}
          {isNewPhone ? (
            <InputPhone
              id='newPhone'
              placeholder='New Phone'
              label='New Phone'
              required
              error={fields.newPhone?.error}
              onChange={(e) => handleChangeInputValue(e, 'newPhone')}
              className='user-info-input'
            />
          ) : (
            <Select
              name='selectPhone'
              placeholder='Select Telephone'
              required
              options={phoneOptions}
              defaultValue={setLabelToPhone}
              onChange={(e) => handleChangeSelect('selectPhone', e)}
              error={fields.selectPhone.error}
              className='user-info-input'
            />
          )}
        </S.FormFieldset>

        <FilledButtonWithIcon onClick={validate} isUpper>
          {validating ? 'Saving' : 'Continue'}
        </FilledButtonWithIcon>
      </S.UserForm>
    </CompleteUserTemplate>
  );
};

export default withRouter(LegalGuardian);
