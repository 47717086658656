import { EventListView } from 'admin/models/event/Event';

export enum EventsActionTypes {
  FETCH_EVENTS_REQUEST = '@@events/FETCH_EVENTS_REQUEST',
  FETCH_EVENTS_SUCCESS = '@@events/FETCH_EVENTS_SUCCESS',
  FETCH_EVENTS_ERROR = '@@events/FETCH_EVENTS_ERROR',
}

export interface EventsState {
  readonly error: string;
  readonly loading: boolean;
  readonly data: EventListView[];
}
