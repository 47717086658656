import React, { FC, useMemo, useState } from 'react';
import { BodyM, BodyMBold, BodyS, BodyXL } from 'styles/v3/variables';
import { ScheduleMatch, ScheduleView } from 'models/Schedule/ScheduleModel';
import DateUtil from 'util/DateUtil';
import logoAlt from 'assets/imgs/Vault_logo_grey.svg';
import dates from 'assets/imgs/icons/publish-calendar.svg';
import venue from 'assets/imgs/icons/publish-venue.svg';
import ConvertUtil from 'util/ConvertUtil';
import useGetVenues from 'hooks/v3/event/useGetVenues/useGetVenues';
import { useParams } from 'react-router-dom';
import { timezones } from 'util/timezones';
import {
  GameItemContainer,
  PageWrapper,
  ScheduleDay,
  StyledBodyS,
  StyledCloseSmall,
  StyledContainer,
  StyledDivisionTitle,
  StyledFooter,
  StyledGameContainer,
  StyledGameEventInfoContainer,
  StyledModal,
  StyledModalBody,
  StyledModalHeader,
  StyledTextWrapper,
  StyledTitle,
  TeamItemEventInfo,
  TeamLogo,
  TeamName,
  TeamWrapperEventIfo,
} from './style';
import PagesControl from '../Scheduler/PagesControl';

type IProps = {
  schedule: ScheduleView,
  setOpened: (val: boolean) => void,
  setGame: (match: ScheduleMatch) => void,
  setDay: (day: string | Date) => void,
  openDetailModal: () => void,
};

const perPage = 1;

const ScheduleViewModal: FC<IProps> = ({
  schedule,
  setOpened,
  setGame,
  setDay,
  openDetailModal,
}) => {
  const { days } = schedule;
  const params: { eventId: string } = useParams();
  const [page, setPage] = useState<number>(0);
  const firstPageIndex = page * perPage;
  const lastPageIndex = firstPageIndex + perPage;
  const { data: venueData } = useGetVenues(params.eventId);

  // ENG-594 temporary show only timezone for the 1st venue
  const venueTimezone = useMemo(() => {
    return timezones.find(
      (timezone) =>
        venueData && timezone.value === (venueData[0]?.timezone ?? 'UTC')
    );
  }, [venueData]);

  const scheuleDays = useMemo(() => {
    return days.slice(firstPageIndex, lastPageIndex);
  }, [page]);

  const handleClose = () => {
    setOpened(false);
  };

  const selectMatch = (match: ScheduleMatch, day: Date | string) => {
    setGame(match);
    setDay(day);
    handleClose();
    openDetailModal();
  };
  console.log(new Date('UTC+3').getTimezoneOffset());
  console.log(new Date().getUTCDate());
  return (
    <StyledModal>
      <StyledModalHeader>
        <BodyXL $color='white'>Match Schedule</BodyXL>
        <StyledCloseSmall size='36px' onClick={handleClose} />
      </StyledModalHeader>
      <StyledModalBody>
        <StyledContainer>
          <>
            {scheuleDays.map((day) => (
              <ScheduleDay key={day.day.toString()}>
                <StyledTitle>
                  <BodyMBold>
                    {DateUtil.getDayYear(new Date(day.day))},
                  </BodyMBold>
                  <BodyMBold $color='grey400'>
                    {DateUtil.getYear(new Date(day.day))}
                  </BodyMBold>
                </StyledTitle>
                <BodyMBold>
                  Time configured in the {venueTimezone?.locations} timezone
                </BodyMBold>
                <GameItemContainer>
                  {day?.matches?.length ? (
                    day?.matches?.map((match, index) => (
                      <StyledGameContainer
                        key={match.matchId + match.divisionName}
                        onClick={() => selectMatch(match, day.day)}
                      >
                        <StyledDivisionTitle $color='white'>
                          {match?.divisionName ?? 'No Div'}
                        </StyledDivisionTitle>
                        <TeamWrapperEventIfo>
                          <TeamItemEventInfo>
                            <TeamName>
                              {match.opponent1?.clubName ?? 'Team 1'}
                            </TeamName>
                            <TeamLogo>
                              <img
                                src={
                                  match?.opponent1?.clubLogo
                                    ? ConvertUtil.getMinioUrl(
                                        match.opponent1?.clubLogo
                                      )
                                    : logoAlt
                                }
                                alt='club logo'
                              />
                            </TeamLogo>
                          </TeamItemEventInfo>
                          <BodyM>VS</BodyM>
                          <TeamItemEventInfo>
                            <TeamName>
                              {match.opponent2?.clubName ?? 'Team 1'}
                            </TeamName>
                            <TeamLogo>
                              <img
                                src={
                                  match?.opponent2?.clubLogo
                                    ? ConvertUtil.getMinioUrl(
                                        match.opponent2?.clubLogo
                                      )
                                    : logoAlt
                                }
                                alt='club logo'
                              />
                            </TeamLogo>
                          </TeamItemEventInfo>
                        </TeamWrapperEventIfo>
                        <StyledFooter>
                          <StyledGameEventInfoContainer>
                            <img
                              src={dates}
                              alt='Type'
                              width={24}
                              height={24}
                            />
                            <StyledTextWrapper>
                              <StyledBodyS $color='white'>
                                <BodyS
                                  $color='grey600'
                                  style={{ textTransform: 'uppercase' }}
                                >
                                  Starts
                                </BodyS>{' '}
                                {`${new Date(
                                  match?.startTime ?? ''
                                ).toLocaleString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                })}`}
                                <br />
                                <BodyS
                                  $color='grey600'
                                  style={{ textTransform: 'uppercase' }}
                                >
                                  Finishes
                                </BodyS>{' '}
                                {`${new Date(
                                  match?.endTime ?? ''
                                ).toLocaleString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                })}`}
                              </StyledBodyS>
                            </StyledTextWrapper>
                          </StyledGameEventInfoContainer>
                          <StyledGameEventInfoContainer>
                            <img
                              src={venue}
                              alt='Type'
                              width={24}
                              height={24}
                            />
                            <div>
                              <StyledBodyS>
                                <BodyS
                                  $color='grey600'
                                  style={{ textTransform: 'uppercase' }}
                                >
                                  Venue:
                                </BodyS>{' '}
                                {match?.venueName ?? 'No defined'}
                              </StyledBodyS>
                              <StyledBodyS>
                                <BodyS
                                  $color='grey600'
                                  style={{ textTransform: 'uppercase' }}
                                >
                                  Court:
                                </BodyS>{' '}
                                {match?.courtName ?? 'No defined'}
                              </StyledBodyS>
                            </div>
                          </StyledGameEventInfoContainer>
                        </StyledFooter>
                      </StyledGameContainer>
                    ))
                  ) : (
                    <BodyMBold>
                      There are no matches scheduled on this day.
                    </BodyMBold>
                  )}
                </GameItemContainer>
              </ScheduleDay>
            ))}
          </>
        </StyledContainer>
        <PageWrapper>
          <PagesControl
            page={page}
            disabled={lastPageIndex >= days.length}
            setPage={setPage}
          />
        </PageWrapper>
      </StyledModalBody>
    </StyledModal>
  );
};

export default ScheduleViewModal;
