import React from 'react';
import { BodyLBold } from 'styles/v3/variables';
import * as S from './styles';

type InfoCardProps = {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
};

export const InfoCard = ({ title, icon, children }: InfoCardProps) => {
  return (
    <S.Container>
      {icon}
      <S.TextsWrapper>
        <BodyLBold>{title}</BodyLBold>
        {children}
      </S.TextsWrapper>
    </S.Container>
  );
};
