import styled from 'styled-components';
import { color, font } from '../../../styles/styleVariables';

const InfoProfileItemWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  position: relative;
  height: 120px;
  .title {
    text-align: center;
    text-transform: uppercase;
    color: ${color.silver};
    width: 70px;
    position: absolute;
    left: -20px;
    top: 40%;
    div {
      font-size: 16px;
      transform: rotate(270deg);
    }
  }
  .info-container {
    padding: 10px 0;
    padding-left: 50px;
    line-height: 30px;
    .info-primary {
      font-size: 12px;
      text-transform: uppercase;
    }
    .info-main {
      font-family: ${font.druk};
      font-size: 36px;
      margin: 5px 0;
      @media (max-width: 1200px) {
        font-size: 32px;
      }
      @media (max-width: 1000px) {
        font-size: 26px;
      }
    }
    .info-secondary {
      font-weight: 700;
      font-size: 10px;
      color: ${color.silver};
      span {
        color: ${color.orange};
      }
    }
  }
`;

export default InfoProfileItemWrapper;
