import React, { FC, useRef, useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Select } from 'antd';
import { debounce } from 'lodash';

import UserType from 'models/User/UserTypeModel';

import UserServices from 'services/User/UserApi';

import ConvertUtil from 'util/ConvertUtil';

import avatar_icon from 'assets/imgs/avatar.svg';

import LoadingIcon from '../../LoadingIcon/LoadingIcon';

interface IUserInputSearchProps {
  name: string;
  filterTypes?: UserType[];
  defaultValue?: string[] | string;
  multiple?: boolean;
}

const UserInputSearch: FC<IUserInputSearchProps> = ({
  filterTypes,
  multiple,
  defaultValue,
  name,
}) => {
  const formContext = useFormContext();

  const [contentList, setContentList] = useState<{
    loading: boolean,
    data: any[],
  }>({ loading: false, data: [] });

  const searchInput = (query: string) => {
    setContentList({ ...contentList, loading: true });

    UserServices.search(undefined, {
      quickSearch: query,
      ...(filterTypes && { typeList: filterTypes }),
    }).then((res) => {
      if (res.success) {
        setContentList({
          ...contentList,
          loading: false,
          data: res.data.content,
        });
      } else {
        message.error(res.message);
        setContentList({ ...contentList, loading: false });
      }
    });
  };

  const doSearch = useRef(
    debounce((filter) => {
      if (filter) {
        searchInput(filter);
      } else {
        setContentList({
          loading: false,
          data: [],
        });
      }
    }, 200)
  ).current;

  const handleSearchChange = (query: string) => {
    doSearch(query);
  };

  const handleSearchFocus = () => {
    if (!contentList.data.length) {
      searchInput('');
    }
  };

  useEffect(() => {
    if (defaultValue) {
      if (Array.isArray(defaultValue)) {
        defaultValue.map((referee) => {
          UserServices.getUser(referee).then((res) => {
            if (res.success) {
              setContentList({
                ...contentList,
                loading: false,
                data: [res.data],
              });
            }
          });
        });
      } else {
        UserServices.getUser(defaultValue).then((res) => {
          if (res.success) {
            setContentList({
              ...contentList,
              loading: false,
              data: [res.data],
            });
          }
        });
      }
    }
  }, []);

  const watchedUserId = formContext.watch('userId');

  return (
    <>
      {contentList.loading && (
        <div
          style={{
            position: 'absolute',
            zIndex: 100,
            top: '40px',
            left: '20px',
          }}
        >
          <LoadingIcon small />
        </div>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Controller
          name={name}
          ref={formContext.register}
          defaultValue={defaultValue}
          as={
            <Select
              mode={multiple ? 'multiple' : undefined}
              style={{ width: '100%' }}
              showSearch
              filterOption={false}
              defaultActiveFirstOption={false}
              onSearch={(e) => handleSearchChange(e)}
              onFocus={handleSearchFocus}
              optionFilterProp='data-label'
              className={`fat user-select ${multiple ? 'select-multiple' : ''}`}
              placeholder={
                <div className='option-image'>
                  <div
                    className='img'
                    role='img'
                    aria-label='Photo'
                    style={{ backgroundColor: '#53575f' }}
                  >
                    <img alt='' src={avatar_icon} width='40px' height='40px' />
                  </div>
                  Search User
                </div>
              }
            >
              {contentList.data.map((item: any) => (
                <Select.Option
                  key={item.id}
                  value={item.id}
                  data-label={item.id}
                >
                  <div className='option-image'>
                    <div className='img' role='img' aria-label='Photo'>
                      <img
                        alt=''
                        src={ConvertUtil.getMinioUrl(item.photo)}
                        width='40px'
                        height='40px'
                      />
                    </div>
                    {item.firstName} {item.lastName}
                  </div>
                </Select.Option>
              ))}
            </Select>
          }
        />
        {watchedUserId && (
          <div style={{ padding: '10px' }}>
            <Button
              onClick={() => {
                formContext.setValue('userId', undefined);
              }}
              shape='circle'
              icon={<DeleteOutlined />}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default UserInputSearch;
