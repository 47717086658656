import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 20px;

  cursor: pointer;
`;

export const CardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 8px;
`;

export const CardHeader = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 8px;
`;

export const AccountTagWrapper = styled.div`
  display: grid;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  grid-template-columns: repeat(3, 1fr);

  gap: 4px;
`;

export const AvatarWrapper = styled.div``;
