import React, { FC, ReactNode, useCallback } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Check } from '@icon-park/react';

import ConvertUtil from 'util/ConvertUtil';
import { truncateText } from 'util/truncateText';

import { BodyLBold, BodyM } from 'styles/v3/variables';

import * as S from './styles';

interface ICardProps {
  id?: string;
  name?: string;
  year?: string;
  logoSrc?: string;
  address?: string;
  selected: boolean;
  extraBasicInfo?: ReactNode;
  extraAddressInfo?: ReactNode;
  onSelect: (userId: string) => void;
}

export const Card: FC<ICardProps> = ({
  id,
  name,
  year,
  address,
  logoSrc,
  selected,
  extraBasicInfo = null,
  extraAddressInfo = null,
  onSelect,
}) => {
  const handleClick = useCallback(() => {
    if (id) {
      onSelect(id);
    }
  }, [id, onSelect]);

  return (
    <S.Container isSelected={selected} onClick={handleClick}>
      <S.Flex gap={10} alignItems='center'>
        {selected && (
          <S.Flex>
            <Check size={20} />
          </S.Flex>
        )}
        <S.Flex>
          <Avatar
            size={62}
            icon={<UserOutlined />}
            src={ConvertUtil.getMinioUrl(logoSrc)}
          />
        </S.Flex>
      </S.Flex>
      <S.InfoWrapper>
        <S.BasicInfoWrapper>
          <S.NameWrapper>
            <BodyLBold>{name && truncateText(name, 20)}</BodyLBold>
            {year && <S.BirthDate>{year}</S.BirthDate>}
          </S.NameWrapper>
          {extraBasicInfo}
        </S.BasicInfoWrapper>
        <S.AddressWrapper>
          {extraAddressInfo}
          <BodyM $color='grey400'>{address}</BodyM>
        </S.AddressWrapper>
      </S.InfoWrapper>
    </S.Container>
  );
};
