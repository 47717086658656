import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const ContainerWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  max-width: ${toRem(966)};
  bottom: ${toRem(100)};
`;

export const Container = styled.div`
  z-index: 999999;
  position: relative;
  display: flex;
  width: ${toRem(378)};
  padding: ${toRem(24)};
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: ${toRem(16)};

  border-radius: 8px;
  border: 1px solid ${COLORS.brandPrimary};
  background: ${COLORS.grey950};
  box-shadow: 0px ${toRem(4)} ${toRem(15)} 0px rgba(0, 0, 0, 0.5);
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .i-icon svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    &::before {
      display: none;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${toRem(10)};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: ${toRem(16)};
`;
