import { Reducer } from 'redux';
import { AccountState, AccountActionTypes } from './types';

export const initialState: AccountState = {
  data: { id: undefined, activeUser: undefined, email: '' },
  loading: false,
};

const reducer: Reducer<AccountState> = (state = initialState, action) => {
  switch (action.type) {
    case AccountActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case AccountActionTypes.FETCH_ACCOUNTS: {
      return { ...state, loading: true };
    }

    case AccountActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }

    case AccountActionTypes.FETCH_ERROR: {
      return { ...state, loading: false };
    }

    case AccountActionTypes.SET_USER: {
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          id: action.payload.id,
          email: action.payload.email,
        },
      };
    }
    case AccountActionTypes.SET_ACCOUNTS: {
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          accounts: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as accountReducer };
